/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
import PouchDB from 'pouchdb';
import PouchFind from 'pouchdb-find';

(function(){
    var taskPratique = function(){

        /*****************************************************
        ENABLING POUCHDB WITHOUT SYNC TO REMOTE COUCHDB
        ******************************************************/
        //SET UP POUCHDB AND CREATE THE DATABASE 
        const db = new PouchDB('suclu');
        PouchDB.plugin(PouchFind);

        /***************
        crudpratiqueBtn
        *****************/
        /**nav previous page button*/
        document.getElementById('prevcrudpratiqueBtn_1').addEventListener('click', prevcrudpratique1BtnChange);
        async function prevcrudpratique1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'block';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            titre.innerHTML = 'Exercices et Tâches'; 
        }

        /***************************
        addpratiqueBtn
        *****************************/
        document.getElementById('addpratiqueBtn').addEventListener('click', addpratiqueBtnChange);
        async function addpratiqueBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutMatiereStr = 'Oui';
            var docsMatiere = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: ['matierestatut', 'inscritcode']
                    }
                });
                result = await db.find({
                    selector: {
                        matierestatut: statutMatiereStr,
                        inscritcode: userCodeStr
                    }
                });
                docsMatiere  = result.docs;
                var resultatMatiere = docsMatiere.length;
                if(resultatMatiere === 0){
                    alert("Matières Classe non encore enregistrées");
                }else{
                    var divClear;
                    var divModifier;
                    divClear = document.getElementById('addmatierepratique');
                    while(divClear.hasChildNodes()) {
                        divClear.removeChild(divClear.childNodes[0]);
                    }
                    divModifier = document.getElementById('addmatierepratique');
                    //Create and append select list
                    var selectList = document.createElement("select");
                    selectList.id = "seladdmatierepratique";
                    divModifier.appendChild(selectList);
                    //Create and append the options
                    var option_null = document.createElement("option");
                    option_null.value = '';
                    option_null.text = 'Sélectionner Matière';
                    selectList.appendChild(option_null);
                    //Create and append the options
                    for (var i = 0; i<docsMatiere.length; i++){
                        var option = document.createElement("option");
                        option.value = docsMatiere[i].matierenom;
                        option.text = docsMatiere[i].matierenom;
                        selectList.appendChild(option);
                    }

                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'none';
                    document.getElementById('configurer').style.display = 'none';
                    document.getElementById('emploidutemps').style.display = 'none';
                    document.getElementById('exercice').style.display = 'none';
                    document.getElementById('pratique').style.display = 'block';
                    document.getElementById('crudpratique').style.display = 'none';
                    document.getElementById('addpratique').style.display = 'block';
                    document.getElementById('editpratique').style.display = 'none';
                    document.getElementById('deletepratique').style.display = 'none';
                    document.getElementById('searchpratique').style.display = 'none';
                    document.getElementById('tache').style.display = 'none';
                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('savetextepratique').style.display = 'none';
                    document.getElementById('savedatepratique').style.display = 'none';
                    document.getElementById('savepratique').style.display = 'none';
                    titre.innerHTML = 'Enregistrer Exercices'; 

                    /***************************
                    seladdmatierepratique
                    *****************************/
                    document.getElementById('seladdmatierepratique').addEventListener('change', seladdmatierepratiqueChange);
                    async function seladdmatierepratiqueChange(e){
                        e.preventDefault();

                        var seladdmatierepratiqueStr = document.getElementById('seladdmatierepratique').value;
                        if(!seladdmatierepratiqueStr){
                            alert('Sélectionner une matière avant de continuer');
                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('savetextepratique').style.display = 'none';
                            document.getElementById('savedatepratique').style.display = 'none';
                            document.getElementById('savepratique').style.display = 'none';
                            titre.innerHTML = 'Enregistrer Exercices';
                        }else{
                            document.getElementById('addselectmatiere').value = seladdmatierepratiqueStr;
                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('savetextepratique').style.display = 'block';
                            document.getElementById('savedatepratique').style.display = 'block';
                            document.getElementById('savepratique').style.display = 'block';
                            titre.innerHTML = 'Enregistrer Exercices';
                        }
                    }
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        /**nav previous page button*/
        document.getElementById('prevaddpratiqueBtn_1').addEventListener('click', prevaddpratique1BtnChange);
        async function prevaddpratique1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'block';
            document.getElementById('crudpratique').style.display = 'block';
            document.getElementById('addpratique').style.display = 'none';
            document.getElementById('editpratique').style.display = 'none';
            document.getElementById('deletepratique').style.display = 'none';
            document.getElementById('searchpratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            titre.innerHTML = 'Exercices et Tâches'; 
        }
        /***************************
        enregistrersemaineBtn
        *****************************/
        document.getElementById('enregistrerpratiqueBtn').addEventListener('click', enregistrerpratiqueBtnChange);
        async function enregistrerpratiqueBtnChange(e){
            e.preventDefault();

            var usercodeStr = document.getElementById('userCode').innerText;
            var seladdmatierepratiqueStr = document.getElementById('addselectmatiere').value;
            var addtextepratiqueStr = document.getElementById('addtextepratique').value;
            var addecheancepratiqueStr = document.getElementById('adddatepratique').value;
            var addeffectuepratiqueStr = 'Non';
            var datejourStr = getCurrentDate();
            var addstatutpratiqueStr = 'Oui';

            if(!usercodeStr){
                alert("Identifiez-vous avant de continuer");
            }else if(!seladdmatierepratiqueStr){
                alert("Sélectionner Matière");
            }else if(!addtextepratiqueStr){
                alert("Saisir Enoncé Exercice");
            }else if(!addecheancepratiqueStr){
                alert("Saisir Date Echéance");
            }else{
                try {
                    //Create the document _id and _rev.
                    var now = Date.now();
                    var id = 'pratique_' + now;
                    // Add the document to the database.
                    var response = await db.put({
                        _id: id,
                        inscritcode: usercodeStr,
                        matierenom: seladdmatierepratiqueStr,
                        pratiquetexte: addtextepratiqueStr,
                        pratiqueecheance: addecheancepratiqueStr, //date
                        pratiqueeffectue: addeffectuepratiqueStr,
                        pratiquedate: datejourStr,
                        pratiquestatut: addstatutpratiqueStr,
                    });
                    // check if document inserted and synced
                    var doc = await db.get(response.id);
                    if(doc !== ''){
                        alert('Enregistré. Au Suivant');
                        document.getElementById('addselectmatiere').value = '';
                        document.getElementById('addtextepratique').value = '';
                        document.getElementById('adddatepratique').value = '';

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'block';
                        document.getElementById('crudpratique').style.display = 'block';
                        document.getElementById('addpratique').style.display = 'none';
                        document.getElementById('editpratique').style.display = 'none';
                        document.getElementById('deletepratique').style.display = 'none';
                        document.getElementById('searchpratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        titre.innerHTML = 'Exercices et Tâches'; 
                    }else{
                        alert('Echec Enregistrement Exercice Pratique. Reprendre');
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }

        /***************************
        editpratiqueBtn
        *****************************/
        document.getElementById('editpratiqueBtn').addEventListener('click', editpratiqueBtnChange);
        async function editpratiqueBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutPratiqueStr = 'Oui';
            var effectuePratiqueStr = 'Non';
            var docsEditPratique = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: [
                            'pratiquestatut', 
                            'inscritcode', 
                            'pratiqueeffectue'
                        ]
                    }
                });
                result = await db.find({
                    selector: {
                        pratiquestatut: statutPratiqueStr,
                        inscritcode: userCodeStr, 
                        pratiqueeffectue: effectuePratiqueStr
                    }
                });
                docsEditPratique  = result.docs;
                var resultatEditPratique = docsEditPratique.length;
                if(resultatEditPratique === 0){
                    alert("Exercices Pratiques non encore enregistrés");
                }else{
                    var divClear;
                    var divModifier;
                    divClear = document.getElementById('editmatierepratique');
                    while(divClear.hasChildNodes()) {
                        divClear.removeChild(divClear.childNodes[0]);
                    }
                    divModifier = document.getElementById('editmatierepratique');
                    //Create and append select list
                    var selectList = document.createElement("select");
                    selectList.id = "seleditmatierepratique";
                    divModifier.appendChild(selectList);
                    //Create and append the options
                    var option_null = document.createElement("option");
                    option_null.value = '';
                    option_null.text = 'Sélectionner matière';
                    selectList.appendChild(option_null);
                    //Create and append the options
                    for (var i = 0; i<docsEditPratique.length; i++){
                        var option = document.createElement("option");
                        option.value = docsEditPratique[i]._id;
                        option.text = docsEditPratique[i].matierenom;
                        selectList.appendChild(option);
                    }

                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'none';
                    document.getElementById('configurer').style.display = 'none';
                    document.getElementById('emploidutemps').style.display = 'none';
                    document.getElementById('exercice').style.display = 'none';
                    document.getElementById('pratique').style.display = 'block';
                    document.getElementById('crudpratique').style.display = 'none';
                    document.getElementById('addpratique').style.display = 'none';
                    document.getElementById('editpratique').style.display = 'block';
                    document.getElementById('deletepratique').style.display = 'none';
                    document.getElementById('searchpratique').style.display = 'none';
                    document.getElementById('tache').style.display = 'none';
                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('changetextepratique').style.display = 'none';
                    document.getElementById('changedatepratique').style.display = 'none';
                    document.getElementById('changepratique').style.display = 'none';
                    titre.innerHTML = 'Modifier Exercices'; 

                    /***************************
                    seleditmatierepratique
                    *****************************/
                    document.getElementById('seleditmatierepratique').addEventListener('change', seleditmatierepratiqueChange);
                    async function seleditmatierepratiqueChange(e){
                        e.preventDefault();

                        var userCodeStr = document.getElementById('userCode').innerText;
                        var pratiqueIdStr,inscritCodeStr,pratiqueMatiereStr,pratiqueTexteStr,pratiqueEcheanceStr,
                        pratiqueEffectueStr,pratiqueDateStr,pratiqueStatutStr = '';

                        var seleditmatierepratiqueStr = document.getElementById('seleditmatierepratique').value;
                        if(!seleditmatierepratiqueStr){
                            alert('Sélectionner une Matière avant de continuer');
                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('changetextepratique').style.display = 'none';
                            document.getElementById('changedatepratique').style.display = 'none';
                            document.getElementById('changepratique').style.display = 'none';
                            titre.innerHTML = 'Modifier Exercices'; 
                        }else{

                            var userCodeStr = document.getElementById('userCode').innerText;
                            var statutPratiqueStr = 'Oui';
                            var pratiqueEffectStr = 'Non';
                            var docsEdit = [];
                            try {
                                let result = await db.createIndex({
                                    index: {
                                        fields: [
                                            'pratiquestatut', 
                                            'inscritcode',
                                            'pratiqueeffectue', 
                                            '_id'
                                        ]
                                    }
                                });
                                result = await db.find({
                                    selector: {
                                        pratiquestatut: statutPratiqueStr,
                                        inscritcode: userCodeStr,
                                        pratiqueeffectue: pratiqueEffectStr,
                                        _id: seleditmatierepratiqueStr
                                    }
                                });
                                docsEdit  = result.docs;
                                var resultatEdit = docsEdit.length;
                                if(resultatEdit === 0){
                                    document.getElementById('editidpratique').value = '';
                                    document.getElementById('editcodeuserpratique').value = '';
                                    document.getElementById('editselectmatiere').value = '';
                                    document.getElementById('edittextepratique').value = '';
                                    document.getElementById('editecheancepratique').value = '';
                                    document.getElementById('editeffectuepratique').value = '';
                                    document.getElementById('editdatepratique').value = '';
                                    document.getElementById('editstatutpratique').value = '';

                                    document.getElementById('titre').style.display = 'block';
                                    var titre = document.getElementById('titre');
                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('changetextepratique').style.display = 'none';
                                    document.getElementById('changedatepratique').style.display = 'none';
                                    document.getElementById('changepratique').style.display = 'none';
                                    titre.innerHTML = 'Modifier Exercices'; 
                                }else{
                                    for(let j=0; j<docsEdit.length; j++){
                                        pratiqueIdStr = docsEdit[j]._id;
                                        inscritCodeStr = docsEdit[j].inscritcode;
                                        pratiqueMatiereStr = docsEdit[j].matierenom;
                                        pratiqueTexteStr = docsEdit[j].pratiquetexte;
                                        pratiqueEcheanceStr = docsEdit[j].pratiqueecheance;
                                        pratiqueEffectueStr = docsEdit[j].pratiqueeffectue;
                                        pratiqueDateStr = docsEdit[j].pratiquedate;
                                        pratiqueStatutStr = docsEdit[j].pratiquestatut;
                                    }

                                    document.getElementById('editmatierepratique').value = pratiqueMatiereStr;
                                    document.getElementById('edittextepratique').value = pratiqueTexteStr;
                                    document.getElementById('editecheancepratique').value = pratiqueEcheanceStr;

                                    document.getElementById('editidpratique').value = pratiqueIdStr;
                                    document.getElementById('editcodeuserpratique').value = inscritCodeStr;
                                    document.getElementById('editeffectuepratique').value = pratiqueEffectueStr;
                                    document.getElementById('editdatepratique').value = pratiqueDateStr;
                                    document.getElementById('editstatutpratique').value = pratiqueStatutStr;

                                    document.getElementById('titre').style.display = 'block';
                                    var titre = document.getElementById('titre');
                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('changetextepratique').style.display = 'block';
                                    document.getElementById('changedatepratique').style.display = 'block';
                                    document.getElementById('changepratique').style.display = 'block';
                                    titre.innerHTML = 'Modifier Exercices'; 
                                }
                            } catch (error) {
                                alert('Erreur Exécution Matières Classe');
                            }
                        }
                    }
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        /**nav previous page button*/
        document.getElementById('preveditpratiqueBtn_1').addEventListener('click', preveditpratique1BtnChange);
        async function preveditpratique1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'block';
            document.getElementById('crudpratique').style.display = 'block';
            document.getElementById('addpratique').style.display = 'none';
            document.getElementById('editpratique').style.display = 'none';
            document.getElementById('deletepratique').style.display = 'none';
            document.getElementById('searchpratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            titre.innerHTML = 'Exercices et Tâches'; 
        }
        /***************************
        modifierpratiqueBtn
        *****************************/
        document.getElementById('modifierpratiqueBtn').addEventListener('click', modifierpratiqueBtnChange);
        async function modifierpratiqueBtnChange(e){
            e.preventDefault();

            var pratiqueMatiereStr = document.getElementById('editmatierepratique').value;
            var pratiqueTexteStr = document.getElementById('edittextepratique').value;
            var pratiqueEcheanceStr = document.getElementById('editecheancepratique').value;

            var pratiqueIdStr = document.getElementById('editidpratique').value;
            var inscritCodeStr = document.getElementById('editcodeuserpratique').value;
            var pratiqueEffectueStr = document.getElementById('editeffectuepratique').value;
            var pratiqueDateStr = document.getElementById('editdatepratique').value;
            var pratiqueStatutStr = document.getElementById('editstatutpratique').value;

            if(!inscritCodeStr){
                alert("Identifiez-vous avant de continuer");
            }else if(!pratiqueTexteStr){
                alert("Saisir Enoncé Exercice");
            }else if(!pratiqueEcheanceStr){
                alert('Saisir Date Echéance');
            }else{
                try {
                    // Retrieve the document to get the latest revision
                    var id = pratiqueIdStr;
                    var doc = await db.get(id);
                    // Make changes
                    doc.inscritcode = inscritCodeStr;
                    doc.matierenom = pratiqueMatiereStr;
                    doc.pratiquetexte = pratiqueTexteStr;
                    doc.pratiqueecheance = pratiqueEcheanceStr;
                    doc.pratiqueeffectue = pratiqueEffectueStr;
                    doc.pratiquedate = pratiqueDateStr;
                    doc.pratiquestatut = pratiqueStatutStr;
                    // Put the document, saving it as a new revision
                    var response = await db.put(doc);
                    // Get the document we change and update the UI.
                    var docEdit = await db.get(response.id);
                    if(docEdit !== ''){
                        alert("Exercice Pratique Modifié avec Succès");
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'block';
                        document.getElementById('crudpratique').style.display = 'block';
                        document.getElementById('addpratique').style.display = 'none';
                        document.getElementById('editpratique').style.display = 'none';
                        document.getElementById('deletepratique').style.display = 'none';
                        document.getElementById('searchpratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        titre.innerHTML = 'Exercices et Tâches'; 
                    }else{
                        alert("Erreur Modification. Reprendre");
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'block';
                        document.getElementById('crudpratique').style.display = 'block';
                        document.getElementById('addpratique').style.display = 'none';
                        document.getElementById('editpratique').style.display = 'none';
                        document.getElementById('deletepratique').style.display = 'none';
                        document.getElementById('searchpratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        titre.innerHTML = 'Exercices et Tâches'; 
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }

        /***************************
        deletepratiqueBtn
        *****************************/
        document.getElementById('deletepratiqueBtn').addEventListener('click', deletepratiqueBtnChange);
        async function deletepratiqueBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutPratiqueStr = 'Oui';
            var effectuePratiqueStr = 'Non';
            var docsDeletePratique = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: [
                            'pratiquestatut', 
                            'inscritcode',
                            'pratiqueeffectue'
                        ]
                    }
                });
                result = await db.find({
                    selector: {
                        pratiquestatut: statutPratiqueStr,
                        inscritcode: userCodeStr,
                        pratiqueeffectue: effectuePratiqueStr
                    }
                });
                docsDeletePratique  = result.docs;
                var resultatDeletePratique = docsDeletePratique.length;
                if(resultatDeletePratique === 0){
                    alert("Exercices Pratiques non encore enregistrés");
                }else{
                    var divClear;
                    var divModifier;
                    divClear = document.getElementById('deletematierepratique');
                    while(divClear.hasChildNodes()) {
                        divClear.removeChild(divClear.childNodes[0]);
                    }
                    divModifier = document.getElementById('deletematierepratique');
                    //Create and append select list
                    var selectList = document.createElement("select");
                    selectList.id = "seldeletematierepratique";
                    divModifier.appendChild(selectList);
                    //Create and append the options
                    var option_null = document.createElement("option");
                    option_null.value = '';
                    option_null.text = 'Sélectionner matière';
                    selectList.appendChild(option_null);
                    //Create and append the options
                    for (var i = 0; i<docsDeletePratique.length; i++){
                        var option = document.createElement("option");
                        option.value = docsDeletePratique[i]._id;
                        option.text = docsDeletePratique[i].matierenom;
                        selectList.appendChild(option);
                    }

                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'none';
                    document.getElementById('configurer').style.display = 'none';
                    document.getElementById('emploidutemps').style.display = 'none';
                    document.getElementById('exercice').style.display = 'none';
                    document.getElementById('pratique').style.display = 'block';
                    document.getElementById('crudpratique').style.display = 'none';
                    document.getElementById('addpratique').style.display = 'none';
                    document.getElementById('editpratique').style.display = 'none';
                    document.getElementById('deletepratique').style.display = 'block';
                    document.getElementById('searchpratique').style.display = 'none';
                    document.getElementById('tache').style.display = 'none';
                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('canceltextepratique').style.display = 'none';
                    document.getElementById('canceldatepratique').style.display = 'none';
                    document.getElementById('cancelpratique').style.display = 'none';
                    titre.innerHTML = 'Supprimer Exercices'; 

                    /***************************
                    seldeletematierepratique
                    *****************************/
                    document.getElementById('seldeletematierepratique').addEventListener('change', seldeletematierepratiqueChange);
                    async function seldeletematierepratiqueChange(e){
                        e.preventDefault();

                        var userCodeStr = document.getElementById('userCode').innerText;
                        var pratiqueIdStr,pratiqueTexteStr,pratiqueEcheanceStr = '';
                        var seldeletematierepratiqueStr = document.getElementById('seldeletematierepratique').value;
                        if(!seldeletematierepratiqueStr){
                            alert('Sélectionner une Matière avant de continuer');
                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('canceletextepratique').style.display = 'none';
                            document.getElementById('canceldatepratique').style.display = 'none';
                            document.getElementById('cancelpratique').style.display = 'none';
                            titre.innerHTML = 'Supprimer Exercices'; 
                        }else{
                            var userCodeStr = document.getElementById('userCode').innerText;
                            var statutPratiqueStr = 'Oui';
                            var pratiqueEffectStr = 'Non';
                            var docsDelete = [];
                            try {
                                let result = await db.createIndex({
                                    index: {
                                        fields: [
                                            'pratiquestatut', 
                                            'inscritcode',
                                            'pratiqueeffectue', 
                                            '_id'
                                        ]
                                    }
                                });
                                result = await db.find({
                                    selector: {
                                        pratiquestatut: statutPratiqueStr,
                                        inscritcode: userCodeStr,
                                        pratiqueeffectue: pratiqueEffectStr,
                                        _id: seldeletematierepratiqueStr
                                    }
                                });
                                docsDelete  = result.docs;
                                var resultatDelete = docsDelete.length;
                                if(resultatDelete === 0){
                                    document.getElementById('deleteidpratique').value = '';

                                    document.getElementById('titre').style.display = 'block';
                                    var titre = document.getElementById('titre');
                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('canceltextepratique').style.display = 'none';
                                    document.getElementById('canceldatepratique').style.display = 'none';
                                    document.getElementById('cancelpratique').style.display = 'none';
                                    titre.innerHTML = 'Supprimer Exercices'; 
                                }else{
                                    for(let j=0; j<docsDelete.length; j++){
                                        pratiqueIdStr = docsDelete[j]._id;
                                        pratiqueTexteStr = docsDelete[j].pratiquetexte;
                                        pratiqueEcheanceStr = docsDelete[j].pratiqueecheance;
                                    }

                                    document.getElementById('deletetextepratique').value = pratiqueTexteStr;
                                    document.getElementById('deleteecheancepratique').value = pratiqueEcheanceStr;

                                    document.getElementById('deleteidpratique').value = pratiqueIdStr;

                                    document.getElementById('titre').style.display = 'block';
                                    var titre = document.getElementById('titre');
                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('canceltextepratique').style.display = 'block';
                                    document.getElementById('canceldatepratique').style.display = 'block';
                                    document.getElementById('cancelpratique').style.display = 'block';
                                    titre.innerHTML = 'Supprimer Exercices'; 
                                }
                            } catch (error) {
                                alert('Erreur Exécution Matières Classe');
                            }
                        }
                    }
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        /**nav previous page button*/
        document.getElementById('prevdeletepratiqueBtn_1').addEventListener('click', prevdeletepratique1BtnChange);
        async function prevdeletepratique1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'block';
            document.getElementById('crudpratique').style.display = 'block';
            document.getElementById('addpratique').style.display = 'none';
            document.getElementById('editpratique').style.display = 'none';
            document.getElementById('deletepratique').style.display = 'none';
            document.getElementById('searchpratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            titre.innerHTML = 'Exercices et Tâches'; 
        }
        /***************************
        supprimerpratiqueBtn
        *****************************/
        document.getElementById('supprimerpratiqueBtn').addEventListener('click', supprimerpratiqueBtnChange);
        async function supprimerpratiqueBtnChange(e){
            e.preventDefault();

            var divClearModal = document.getElementById('modaloutpratique');
            let divModal = document.createElement('div');
            divModal.innerHTML =
            `<div class="modal">
                <p>Êtes-vous sûr de Supprimer ?</p>
                <button class="btn btn--alt" id="ouiHandlerPratiqueBtn">Oui</button>
                <button class="btn" id="nonHandlerPratiqueBtn">Non</button>
            </div>`;
            divClearModal.appendChild(divModal);

            var divClearBackdrop = document.getElementById('backdropoutpratique');
            let divBack = document.createElement('div');
            divBack.innerHTML =
            `<div class="backdrop" id="backdropHandlerPratiqueBtn"></div>`;
            divClearBackdrop.appendChild(divBack);

            document.getElementById('ouiHandlerPratiqueBtn').addEventListener('click', ouiHandlerPratiqueBtnChange);
            async function ouiHandlerPratiqueBtnChange(e){
                e.preventDefault();

                var deleteidpratiqueStr = document.getElementById('deleteidpratique').value;

                try {
                    //Delete
                    var doc = await db.get(deleteidpratiqueStr);
                    var response = await db.remove(doc);
                    if(response.ok){
                        alert('Exercice Pratique Supprimé avec Succès');
                        var divClearModal = document.getElementById('modaloutpratique');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutpratique');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'block';
                        document.getElementById('crudpratique').style.display = 'block';
                        document.getElementById('addpratique').style.display = 'none';
                        document.getElementById('editpratique').style.display = 'none';
                        document.getElementById('deletepratique').style.display = 'none';
                        document.getElementById('searchpratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        titre.innerHTML = 'Exercices et Tâches'; 
                    }else{
                        alert("Impossible Supprimer Exercice Pratique");
                        var divClearModal = document.getElementById('modaloutpratique');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutpratique');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'block';
                        document.getElementById('crudpratique').style.display = 'block';
                        document.getElementById('addpratique').style.display = 'none';
                        document.getElementById('editpratique').style.display = 'none';
                        document.getElementById('deletepratique').style.display = 'none';
                        document.getElementById('searchpratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        titre.innerHTML = 'Exercices et Tâches'; 
                    }
                    
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
            document.getElementById('nonHandlerPratiqueBtn').addEventListener('click', nonHandlerPratiqueBtnChange);
            async function nonHandlerPratiqueBtnChange(e){
                e.preventDefault();

                var divClearModal = document.getElementById('modaloutpratique');
                while(divClearModal.hasChildNodes()) {
                    divClearModal.removeChild(divClearModal.childNodes[0]);
                }

                var divClearBackdrop = document.getElementById('backdropoutpratique');
                while(divClearBackdrop.hasChildNodes()) {
                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                }
            }
            document.getElementById('backdropHandlerPratiqueBtn').addEventListener('click', backdropHandlerPratiqueBtnChange);
            async function backdropHandlerPratiqueBtnChange(e){
                e.preventDefault();

                var divClearModal = document.getElementById('modaloutpratique');
                while(divClearModal.hasChildNodes()) {
                    divClearModal.removeChild(divClearModal.childNodes[0]);
                }

                var divClearBackdrop = document.getElementById('backdropoutpratique');
                while(divClearBackdrop.hasChildNodes()) {
                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                }
            }
        }

        /***************************
        searchpratiqueBtn
        *****************************/
        document.getElementById('searchpratiqueBtn').addEventListener('click', searchpratiqueBtnChange);
        async function searchpratiqueBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutPratiqueStr = 'Oui';
            var docsSearchPratique = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: ['pratiquestatut', 'inscritcode']
                    }
                });
                result = await db.find({
                    selector: {
                        pratiquestatut: statutPratiqueStr,
                        inscritcode: userCodeStr
                    }
                });
                docsSearchPratique  = result.docs;
                var resultatSearchPratique = docsSearchPratique.length;
                if(resultatSearchPratique === 0){
                    alert("Exercices Pratiques non encore enregistrés");
                }else{
                    var divClear;
                    var divModifier;
                    divClear = document.getElementById('searchmatierepratique');
                    while(divClear.hasChildNodes()) {
                        divClear.removeChild(divClear.childNodes[0]);
                    }
                    divModifier = document.getElementById('searchmatierepratique');
                    //Create and append select list
                    var selectList = document.createElement("select");
                    selectList.id = "selsearchmatierepratique";
                    divModifier.appendChild(selectList);
                    //Create and append the options
                    var option_null = document.createElement("option");
                    option_null.value = '';
                    option_null.text = 'Sélectionner matière';
                    selectList.appendChild(option_null);
                    //Create and append the options
                    for (var i = 0; i<docsSearchPratique.length; i++){
                        var option = document.createElement("option");
                        option.value = docsSearchPratique[i].matierenom;
                        option.text = docsSearchPratique[i].matierenom;
                        selectList.appendChild(option);
                    }

                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'none';
                    document.getElementById('configurer').style.display = 'none';
                    document.getElementById('emploidutemps').style.display = 'none';
                    document.getElementById('exercice').style.display = 'none';
                    document.getElementById('pratique').style.display = 'block';
                    document.getElementById('crudpratique').style.display = 'none';
                    document.getElementById('addpratique').style.display = 'none';
                    document.getElementById('editpratique').style.display = 'none';
                    document.getElementById('deletepratique').style.display = 'none';
                    document.getElementById('searchpratique').style.display = 'block';
                    document.getElementById('tache').style.display = 'none';
                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('vieweffectue').style.display = 'none';
                    document.getElementById('viewpratique').style.display = 'none';
                    document.getElementById('viewconsulterpratique').style.display = 'none';
                    titre.innerHTML = 'Consulter Exercices';
                    
                    /***************************
                    selsearchmatierepratique
                    *****************************/
                    document.getElementById('selsearchmatierepratique').addEventListener('change', selsearchmatierepratiqueChange);
                    async function selsearchmatierepratiqueChange(e){
                        e.preventDefault();

                        var selsearchmatierepratiqueStr = document.getElementById('selsearchmatierepratique').value;
                        if(!selsearchmatierepratiqueStr){
                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');

                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('vieweffectue').style.display = 'none';
                            document.getElementById('viewpratique').style.display = 'none';
                            document.getElementById('viewconsulterpratique').style.display = 'none';
                            titre.innerHTML = 'Consulter Exercices';
                        }else{
                            document.getElementById('searchmatierepratique').value = selsearchmatierepratiqueStr;
                            document.getElementById('viewmatierepratique').innerText = selsearchmatierepratiqueStr;
                            document.getElementById('searcheffectue').value = '';

                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');

                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('vieweffectue').style.display = 'block';
                            document.getElementById('viewpratique').style.display = 'none';
                            document.getElementById('viewconsulterpratique').style.display = 'none';
                            titre.innerHTML = 'Consulter Exercices';
                        }
                    }
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        /**nav previous page button*/
        document.getElementById('prevsearchpratiqueBtn_1').addEventListener('click', prevsearchpratique1BtnChange);
        async function prevsearchpratique1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'block';
            document.getElementById('crudpratique').style.display = 'block';
            document.getElementById('addpratique').style.display = 'none';
            document.getElementById('editpratique').style.display = 'none';
            document.getElementById('deletepratique').style.display = 'none';
            document.getElementById('searchpratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            titre.innerHTML = 'Exercices et Tâches'; 
        }
        /***************************
        searcheffectue
        *****************************/
        document.getElementById('searcheffectue').addEventListener('change', searcheffectueChange);
        async function searcheffectueChange(e){
            e.preventDefault();

            var searcheffectueStr = document.getElementById('searcheffectue').value;
            var searchmatierepratiqueStr = document.getElementById('searchmatierepratique').value;
            var userCodeStr = document.getElementById('userCode').innerText;
            var effectOuiStr = 'Oui';
            var effectNonStr = 'Non'; 
            var statutPratiqueStr = 'Oui';
            var docsSearchNon = [];
            var docsSearchOui = [];
            if(searcheffectueStr === 'Non'){
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: [
                                'pratiquestatut',
                                'pratiqueeffectue',
                                'matierenom',
                                'inscritcode'
                            ]
                        }
                    });
                    result = await db.find({
                        selector: {
                            pratiquestatut: statutPratiqueStr,
                            pratiqueeffectue: effectNonStr,
                            matierenom: searchmatierepratiqueStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsSearchNon  = result.docs;
                    var resultatSearchNon = docsSearchNon.length;
                    if(resultatSearchNon === 0){
                        alert("Plus d'Exercices Pratiques non Effectués Disponibles");
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('vieweffectue').style.display = 'block';
                        document.getElementById('viewpratique').style.display = 'none';
                        document.getElementById('viewconsulterpratique').style.display = 'none';
                        titre.innerHTML = 'Consulter Exercices';
                    }else{
                        updateSearchPratiqueUIWithDocs(docsSearchNon);
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('vieweffectue').style.display = 'block';
                        document.getElementById('viewpratique').style.display = 'block';
                        document.getElementById('viewconsulterpratique').style.display = 'block';
                        titre.innerHTML = 'Consulter Exercices';
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }else if(searcheffectueStr === 'Oui'){
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: [
                                'pratiquestatut',
                                'pratiqueeffectue',
                                'matierenom',
                                'inscritcode'
                            ]
                        }
                    });
                    result = await db.find({
                        selector: {
                            pratiquestatut: statutPratiqueStr,
                            pratiqueeffectue: effectOuiStr,
                            matierenom: searchmatierepratiqueStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsSearchOui  = result.docs;
                    var resultatSearchOui = docsSearchOui.length;
                    if(resultatSearchOui === 0){
                        alert("Plus d'Exercices Pratiques Effectués Disponibles");
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('vieweffectue').style.display = 'block';
                        document.getElementById('viewpratique').style.display = 'none';
                        document.getElementById('viewconsulterpratique').style.display = 'none';
                        titre.innerHTML = 'Consulter Exercices';
                    }else{
                        updateSearchPratiqueUIWithDocs(docsSearchOui);
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('vieweffectue').style.display = 'block';
                        document.getElementById('viewpratique').style.display = 'block';
                        document.getElementById('viewconsulterpratique').style.display = 'block';
                        titre.innerHTML = 'Consulter Exercices';
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }else{
                alert("Selectionner option Oui/Non");
                document.getElementById('titre').style.display = 'block';
                var titre = document.getElementById('titre');

                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('vieweffectue').style.display = 'block';
                document.getElementById('viewpratique').style.display = 'none';
                document.getElementById('viewconsulterpratique').style.display = 'none';
                titre.innerHTML = 'Consulter Exercices';
            }
        }
        // Adds the document to the HTML First table.
        // If the document already exists in the table, it will be updated.
        function viewSearchPratiueTableDocToTable(doc, viewSearchPratiueTableBody){
            // Each row of the table has an id attribute set to the doc._id field.
            var tr = viewSearchPratiueTableBody.querySelector(`#${doc._id}`);
            var searcheffectueStr = document.getElementById('searcheffectue').value;

            if( !tr ) {
                tr = document.createElement('tr');
                tr.id = doc._id;
            }
        
            // Remove any existing table cells.
            while(tr.hasChildNodes()) {
                tr.removeChild(tr.childNodes[0]);
            }

            // Create new table cells for text fields.
            var tdEcheance = document.createElement('td');
            var dateEcheance = new Date(doc.pratiqueecheance);
            tdEcheance.innerText = dateEcheance.toLocaleDateString();
            tr.appendChild(tdEcheance);

            // Create new table cells for text fields.
            var tdEnonce = document.createElement('td');
            tdEnonce.innerText = doc.pratiquetexte;
            var br = document.createElement("br");
            tdEnonce.appendChild(br);
            var idPratique = doc._id;
            if(searcheffectueStr === 'Non'){
                var button = document.createElement("button");
                button.id = "ouipratiqueBtn";
                button.type = 'button';
                button.innerHTML = 'Effectué';
                button.onclick = async function(){
                    var inscritCodeStr,pratiqueMatiereStr,pratiqueTexteStr,pratiqueEcheanceStr,
                    pratiqueDateStr,pratiqueStatutStr = '';
                    var pratiqueEffectueStr = 'Oui';
                    var docsPratique = [];
                    try {
                        let result = await db.createIndex({
                            index: {
                                fields: ['_id']
                            }
                        });
                        result = await db.find({
                            selector: {
                                _id: idPratique
                            }
                        });
                        docsPratique  = result.docs;
                        var resultatPratique = docsPratique.length;
                        if(resultatPratique === 0){
                            alert("Exercice Pratique n'existe pas");
                        }else{
                            for(let i=0; i<docsPratique.length; i++){
                                inscritCodeStr = docsPratique[i].inscritcode;
                                pratiqueMatiereStr = docsPratique[i].matierenom;
                                pratiqueTexteStr = docsPratique[i].pratiquetexte;
                                pratiqueEcheanceStr = docsPratique[i].pratiqueecheance;
                                pratiqueDateStr = docsPratique[i].pratiquedate;
                                pratiqueStatutStr = docsPratique[i].pratiquestatut;
                            }

                            // Retrieve the document to get the latest revision
                            var id = idPratique;
                            var docModifier = await db.get(id);
                            // Make changes
                            docModifier.inscritcode = inscritCodeStr;
                            docModifier.matierenom = pratiqueMatiereStr;
                            docModifier.pratiquetexte = pratiqueTexteStr;
                            docModifier.pratiqueecheance = pratiqueEcheanceStr;
                            docModifier.pratiqueeffectue = pratiqueEffectueStr;
                            docModifier.pratiquedate = pratiqueDateStr;
                            docModifier.pratiquestatut = pratiqueStatutStr;
                            // Put the document, saving it as a new revision
                            var response = await db.put(docModifier);
                            // Get the document we change and update the UI.
                            var docEdit = await db.get(response.id);
                            if(docEdit !== ''){
                                alert("Action Effectuée");
                                document.getElementById('titre').style.display = 'block';
                                var titre = document.getElementById('titre');
    
                                document.getElementById('demarrage').style.display = 'none';
                                document.getElementById('tableaubord').style.display = 'none';
                                document.getElementById('dashboard').style.display = 'none';
                                document.getElementById('configurations').style.display = 'none';
                                document.getElementById('configurer').style.display = 'none';
                                document.getElementById('emploidutemps').style.display = 'none';
                                document.getElementById('exercice').style.display = 'none';
                                document.getElementById('pratique').style.display = 'block';
                                document.getElementById('crudpratique').style.display = 'block';
                                document.getElementById('addpratique').style.display = 'none';
                                document.getElementById('editpratique').style.display = 'none';
                                document.getElementById('deletepratique').style.display = 'none';
                                document.getElementById('searchpratique').style.display = 'none';
                                document.getElementById('tache').style.display = 'none';
                                titre.innerHTML = 'Exercices et Tâches'; 
                            }else{
                                alert("Erreur Accomplissement Action. Reprendre");
                                document.getElementById('titre').style.display = 'block';
                                var titre = document.getElementById('titre');
    
                                document.getElementById('demarrage').style.display = 'none';
                                document.getElementById('tableaubord').style.display = 'none';
                                document.getElementById('dashboard').style.display = 'none';
                                document.getElementById('configurations').style.display = 'none';
                                document.getElementById('configurer').style.display = 'none';
                                document.getElementById('emploidutemps').style.display = 'none';
                                document.getElementById('exercice').style.display = 'none';
                                document.getElementById('pratique').style.display = 'block';
                                document.getElementById('crudpratique').style.display = 'block';
                                document.getElementById('addpratique').style.display = 'none';
                                document.getElementById('editpratique').style.display = 'none';
                                document.getElementById('deletepratique').style.display = 'none';
                                document.getElementById('searchpratique').style.display = 'none';
                                document.getElementById('tache').style.display = 'none';
                                titre.innerHTML = 'Exercices et Tâches'; 
                            }
                        }
                    } catch (error) {
                        alert('Erreur Exécution');
                    }
                }
            }else{
                var button = document.createElement("button");
                button.id = "nonpratiqueBtn";
                button.type = 'button';
                button.innerHTML = 'Supprimer';
                button.onclick = async function(){
                    var divClearModal = document.getElementById('modaloutsearchpratique');
                    let divModal = document.createElement('div');
                    divModal.innerHTML =
                    `<div class="modal">
                    <p>Êtes-vous sûr de Supprimer Cet Exercice ?</p>
                    <button class="btn btn--alt" id="ouiHandlerSearchPratiqueBtn">Oui</button>
                    <button class="btn" id="nonHandlerSearchPratiqueBtn">Non</button>
                    </div>`;
                    divClearModal.appendChild(divModal);

                    var divClearBackdrop = document.getElementById('backdropoutsearchpratique');
                    let divBack = document.createElement('div');
                    divBack.innerHTML =
                    `<div class="backdrop" id="backdropHandlerSearchPratiqueBtn"></div>`;
                    divClearBackdrop.appendChild(divBack);

                    document.getElementById('ouiHandlerSearchPratiqueBtn').addEventListener('click', ouiHandlerSearchPratiqueBtnChange);
                    async function ouiHandlerSearchPratiqueBtnChange(e){
                        e.preventDefault();

                        var deleteidpratiqueStr = idPratique;
                        try {
                            //Delete
                            var doc = await db.get(deleteidpratiqueStr);
                            var response = await db.remove(doc);
                            if(response.ok){
                                alert('Exercice Supprimé avec Succès');
                                var divClearModal = document.getElementById('modaloutsearchpratique');
                                while(divClearModal.hasChildNodes()) {
                                    divClearModal.removeChild(divClearModal.childNodes[0]);
                                }

                                var divClearBackdrop = document.getElementById('backdropoutsearchpratique');
                                while(divClearBackdrop.hasChildNodes()) {
                                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                }

                                document.getElementById('titre').style.display = 'block';
                                var titre = document.getElementById('titre');
    
                                document.getElementById('demarrage').style.display = 'none';
                                document.getElementById('tableaubord').style.display = 'none';
                                document.getElementById('dashboard').style.display = 'none';
                                document.getElementById('configurations').style.display = 'none';
                                document.getElementById('configurer').style.display = 'none';
                                document.getElementById('emploidutemps').style.display = 'none';
                                document.getElementById('exercice').style.display = 'none';
                                document.getElementById('pratique').style.display = 'block';
                                document.getElementById('crudpratique').style.display = 'block';
                                document.getElementById('addpratique').style.display = 'none';
                                document.getElementById('editpratique').style.display = 'none';
                                document.getElementById('deletepratique').style.display = 'none';
                                document.getElementById('searchpratique').style.display = 'none';
                                document.getElementById('tache').style.display = 'none';
                                titre.innerHTML = 'Exercices et Tâches';
                            }else{
                                alert("Impossible Supprimer Exercice");
                                document.getElementById('titre').style.display = 'block';
                                var titre = document.getElementById('titre');
    
                                document.getElementById('demarrage').style.display = 'none';
                                document.getElementById('tableaubord').style.display = 'none';
                                document.getElementById('dashboard').style.display = 'none';
                                document.getElementById('configurations').style.display = 'none';
                                document.getElementById('configurer').style.display = 'none';
                                document.getElementById('emploidutemps').style.display = 'none';
                                document.getElementById('exercice').style.display = 'none';
                                document.getElementById('pratique').style.display = 'block';
                                document.getElementById('crudpratique').style.display = 'block';
                                document.getElementById('addpratique').style.display = 'none';
                                document.getElementById('editpratique').style.display = 'none';
                                document.getElementById('deletepratique').style.display = 'none';
                                document.getElementById('searchpratique').style.display = 'none';
                                document.getElementById('tache').style.display = 'none';
                                titre.innerHTML = 'Exercices et Tâches';
                            }
                        } catch (error) {
                            alert('Erreur Exécution');
                        }
                    }
                    document.getElementById('nonHandlerSearchPratiqueBtn').addEventListener('click', nonHandlerSearchPratiqueBtnChange);
                    async function nonHandlerSearchPratiqueBtnChange(e){
                        e.preventDefault();

                        var divClearModal = document.getElementById('modaloutsearchpratique');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutsearchpratique');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }
                    }
                    document.getElementById('backdropHandlerSearchPratiqueBtn').addEventListener('click', backdropHandlerSearchPratiqueBtnChange);
                    async function backdropHandlerSearchPratiqueBtnChange(e){
                        e.preventDefault();

                        var divClearModal = document.getElementById('modaloutsearchpratique');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutsearchpratique');
                        while(divClearBackdrop.hasChildNodes()) {
                             divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }
                    }
                }
            }
            tdEnonce.appendChild(button);
            tr.appendChild(tdEnonce);

            // Add the row to the top of the table.
            viewSearchPratiueTableBody.insertBefore(tr, viewSearchPratiueTableBody.nextSibling);
        }
        /**Clear and update the UI to display the docs.*/
        function updateSearchPratiqueUIWithDocs(docs) {
            var viewSearchPratiueTableBody = document.getElementById('viewSearchPratiueTableBody');
            
            // Remove all child nodes from the table
            while(viewSearchPratiueTableBody.hasChildNodes()) {
                viewSearchPratiueTableBody.removeChild(viewSearchPratiueTableBody.childNodes[0]);
            }
            
            // Add the new documents to the table
            for(var i=0; i<docs.length; i++) {
                var doc = docs[i];
                viewSearchPratiueTableDocToTable(doc, viewSearchPratiueTableBody);
            }
        }
        /***************************
        consulterpratiqueBtn
        *****************************/
        document.getElementById('consulterpratiqueBtn').addEventListener('click', consulterpratiqueBtnChange);
        async function consulterpratiqueBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');
    
            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'block';
            document.getElementById('crudpratique').style.display = 'block';
            document.getElementById('addpratique').style.display = 'none';
            document.getElementById('editpratique').style.display = 'none';
            document.getElementById('deletepratique').style.display = 'none';
            document.getElementById('searchpratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            titre.innerHTML = 'Exercices et Tâches';
        }
    };

    /****************
    HELPER FUNCTION
    *****************/
    /**1: Get Current Date in JavaScript */
    function getCurrentDate(){
        // Date object
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1;
        var yyyy = today.getFullYear();
        if(dd<10){
            dd='0'+dd;
        }
        if(mm<10){
            mm='0'+mm;
        }
        // Current Date
        var date = yyyy+'-'+mm+'-'+dd;
        return date;
    }

    window.addEventListener('load', () =>{
        new taskPratique();
    })
}) ();