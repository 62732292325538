/* eslint-disable no-redeclare */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import PouchDB from 'pouchdb';
import PouchFind from 'pouchdb-find';

(function(){
    let taskDemarrer = function(){
        /*****************************************************
        ENABLING SYNCING WITH POUCHDB AND COUCHDB
        ******************************************************/
        //SET UP POUCHDB AND CREATE THE DATABASE 
        const db = new PouchDB('suclu');
        //const remoteCouch = new PouchDB('http://kouaskon:Mandidjouman1@127.0.0.1:5984/suclu');
        //const remoteCouch = new PouchDB('http://admin:1It8Nn1fkD5F@34.65.78.200:5984/bazali');
        //const remoteCouch = new PouchDB('http://admin:iRvMP4dozSun@34.65.19.157:5984/bazali');
        PouchDB.plugin(PouchFind);

        // Initialise a sync with the remote server
        /*function sync(){
            var opts = {live: true, retry: true, continuous: true, attachments: true,};
            //sync local PouchDB instance (db) with the remote CouchDB
            //use the retun value from db.sync to listen to the changes
            //on every change in the db, re-run updateArticles to display current list
            db.sync(remoteCouch, opts);
        }*/
        /*****************************************************
        ENABLING SYNCING WITH POUCHDB AND COUCHDB
        ******************************************************/

        /***************************
        demarrerBtn
        *****************************/
        document.getElementById('demarrerBtn').addEventListener('click', demarrerBtnChange);
        async function demarrerBtnChange(e){
            e.preventDefault();

            document.getElementById('app').style.display = 'none';
            //check if usercode !null
            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');
            var codeUtilisateur = document.getElementById('userCode').innerText;
            if(codeUtilisateur === ''){
                document.getElementById('detail').style.display = 'block';
                document.getElementById('detailuser').style.display = 'block';
                document.getElementById('userCode').style.display = 'none';
                document.getElementById('detailprenoms').style.display = 'none';
                document.getElementById('demarrage').style.display = 'block';
                document.getElementById('acceder').style.display = 'block';
                titre.innerHTML = 'Identifiez-vous';
            }else{
                document.getElementById('detail').style.display = 'block';
                document.getElementById('detailuser').style.display = 'block';
                document.getElementById('userCode').style.display = 'block';
                document.getElementById('detailprenoms').style.display = 'block';
                document.getElementById('demarrage').style.display = 'none';
                document.getElementById('acceder').style.display = 'none';
                document.getElementById('tableaubord').style.display = 'block';
                document.getElementById('dashboard').style.display = 'block';
                titre.innerHTML = 'Tableau de Bord'; 
            }
        }

        /***************************
        forgetpasswordBtn
        *****************************/
        document.getElementById('forgetpasswordBtn').addEventListener('click', forgetpasswordBtnChange);
        async function forgetpasswordBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('acceder').style.display = 'none';
            document.getElementById('inscription').style.display = 'block';
            document.getElementById('passwordoublie').style.display = 'block';
            document.getElementById('inscrire').style.display = 'none';
            titre.innerHTML = 'Password Oublié ?'; 
        }
        /**nav previous page button*/
        document.getElementById('prevpasswordoublieBtn_1').addEventListener('click', prevpasswordoublie1BtnChange);
        async function prevpasswordoublie1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'block';
            document.getElementById('acceder').style.display = 'block';
            document.getElementById('inscription').style.display = 'none';
            document.getElementById('passwordoublie').style.display = 'none';
            titre.innerHTML = 'Identifiez-vous'; 
        }

        /***************************
        inscrireBtn
        *****************************/
        document.getElementById('inscrireBtn').addEventListener('click', inscrireBtnChange);
        async function inscrireBtnChange(e){
            e.preventDefault();

            //check if user has already sign up
            var statutinscrireStr = 'Oui';
            var docsInscrire = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: ['inscritstatut']
                    }
                });
                result = await db.find({
                    selector: {
                        inscritstatut: statutinscrireStr
                    }
                });
                docsInscrire = result.docs;
                var resultatInscrire = docsInscrire.length;
                if(resultatInscrire === 0){
                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('acceder').style.display = 'none';
                    document.getElementById('inscription').style.display = 'block';
                    document.getElementById('passwordoublie').style.display = 'none';
                    document.getElementById('inscrire').style.display = 'block';
                    titre.innerHTML = 'Créer Votre Compte Gratuit'; 
                }else{
                    alert('Vous êtes déjà inscrit. Identifiez-vous');
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }

        /***************************
        inscrireoublieBtn
        *****************************/
        document.getElementById('inscrireoublieBtn').addEventListener('click', inscrireoublieBtnChange);
        async function inscrireoublieBtnChange(e){
            e.preventDefault();

            //check if user has already sign up
            var statutinscrireStr = 'Oui';
            var docsInscrire = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: ['inscritstatut']
                    }
                });
                result = await db.find({
                    selector: {
                        inscritstatut: statutinscrireStr
                    }
                });
                docsInscrire = result.docs;
                var resultatInscrire = docsInscrire.length;
                if(resultatInscrire === 0){
                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('acceder').style.display = 'none';
                    document.getElementById('inscription').style.display = 'block';
                    document.getElementById('passwordoublie').style.display = 'none';
                    document.getElementById('inscrire').style.display = 'block';
                    titre.innerHTML = 'Créer Votre Compte Gratuit'; 
                }else{
                    alert('Vous êtes déjà inscrit. Identifiez-vous');
                }
            } catch (error) {
                alert('Erreur Exécution');
            } 
        }
        /**nav previous page button*/
        document.getElementById('previnscrireBtn_1').addEventListener('click', previnscrire1BtnChange);
        async function previnscrire1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'block';
            document.getElementById('acceder').style.display = 'block';
            document.getElementById('inscription').style.display = 'none';
            document.getElementById('passwordoublie').style.display = 'none';
            document.getElementById('inscrire').style.display = 'none';
            titre.innerHTML = 'Identifiez-vous'; 
        }

        /***************************
        sinscrireBtn
        *****************************/
        var networkStatus = '';
        document.getElementById('sinscrireBtn').addEventListener('click', sinscrireBtnChange);
        async function sinscrireBtnChange(e){
            e.preventDefault();

            //only new user must be online before sign up

            var selcycleinscrireStr = document.getElementById('selcycleinscrire').value;
            var nominscrireStr = document.getElementById('nominscrire').value;
            var prenominscrireStr = document.getElementById('prenominscrire').value;
            var usernameinscrireStr = document.getElementById('usernameinscrire').value;
            var passwordinscrireStr = document.getElementById('passwordinscrire').value;
            var confirmpasswordinscrireStr = document.getElementById('confirmpasswordinscrire').value;
            var dateinscrireStr = getCurrentDate();
            var statutinscrireStr = 'Oui';
            var codeinscrireStr = '';

            if(!selcycleinscrireStr){
                alert('Sélectionner cycle scolaire');
            }else if(!nominscrireStr){
                alert("Entrer votre nom");
            }else if(!prenominscrireStr){
                alert("Entrer vos prenoms");
            }else if(!usernameinscrireStr){
                alert("Entrer votre username");
            }else if(!passwordinscrireStr){
                alert("Entrer votre password");
            }else if(!confirmpasswordinscrireStr){
                alert("Confirmer votre password");
            }else if(passwordinscrireStr !== confirmpasswordinscrireStr){
                alert('Les 2 passwords ne correspondent pas. Recommencer');
            }else{
                //Detecting is the user is Online/Offline
                function hasNetWork(online){
                    networkStatus = online;
                }
                
                hasNetWork(navigator.onLine);

                if(networkStatus === true){
                    //generate random code number
                    var cryptoRandom = window.crypto.getRandomValues(new Uint32Array(1))[0];
                    codeinscrireStr = cryptoRandom.toString();
                    //create remote database if not exists
                    const db = new PouchDB('suclu');
                    const remoteCouch = new PouchDB('http://kouaskon:Mandidjouman1@127.0.0.1:5984/suclu');
                    // Initialise a sync with the remote server
                    async function sync(){
                        var opts = {live: true, retry: true, continuous: true, attachments: true,};
                        db.sync(remoteCouch, opts);
                        try {
                            //Create the document _id and _rev.
                            var now = Date.now();
                            var id = 'inscrit_' + now;
                            // Add the document to the database.
                            var response = await db.put({
                                _id: id,
                                inscritcycle: selcycleinscrireStr,
                                inscritnom: nominscrireStr,
                                inscritprenom: prenominscrireStr,
                                inscritusername: usernameinscrireStr,
                                inscritpassword: passwordinscrireStr,
                                inscritcode: codeinscrireStr,
                                inscritdate: dateinscrireStr,
                                inscritstatut: statutinscrireStr,
                            });
                            // check if document inserted and synced
                            var doc = await db.get(response.id);
                            if(doc !== ''){
                                alert('Enregistrement Effectué avec succès. Identifiez-vous');
                                //reload the app afert reisgration completed to remote database
                                document.location.reload(true);
                                /*document.getElementById('detail').style.display = 'block';
                                document.getElementById('detailuser').style.display = 'block';
                                document.getElementById('userCode').style.display = 'none';
                                document.getElementById('detailprenoms').style.display = 'none';
                                document.getElementById('demarrage').style.display = 'block';
                                document.getElementById('acceder').style.display = 'block';
                                document.getElementById('inscription').style.display = 'none';
                                titre.innerHTML = 'Identifiez-vous';*/
                            }else{
                                alert('Echec Enregistrement. Reprendre');
                            }
                        } catch (error) {
                            alert('Erreur Exécution');
                        }
                    }
                    
                    //activate syncing local database to remote
                    if (remoteCouch) {
                        sync();
                    }else{
                        alert('FAILED');
                    }
                }else{
                    alert('Connectez-vous à internet avant de vous inscrire');
                }
            }
        }

        /***************************
        confirmeroublieBtn
        *****************************/
        document.getElementById('confirmeroublieBtn').addEventListener('click', confirmeroublieBtnChange);
        async function confirmeroublieBtnChange(e){
            e.preventDefault();

            var nomoublieStr = document.getElementById('nomoublie').value;
            var prenomoublieStr = document.getElementById('prenomoublie').value;
            var statutinscrireStr = 'Oui';

            var usernameStr,passwordStr = '';

            var docsOublie = [];

            try {
                let result = await db.createIndex({
                    index: {
                        fields: ['inscritstatut']
                    }
                });
                result = await db.find({
                    selector: {
                        inscritstatut: statutinscrireStr
                    }
                });
                docsOublie = result.docs;
                var resultatOublie = docsOublie.length;
                if(resultatOublie === 0){
                    alert("Vous n'avez pas encore créé votre compte gratuit. S'inscrire");
                }else{
                    if(nomoublieStr.length > 1 && prenomoublieStr.length > 1){
                        for(var i=0; i<docsOublie.length; i++){
                            if(docsOublie[i].inscritnom.toLowerCase().includes(nomoublieStr.toLowerCase()) ||
                            docsOublie[i].inscritprenom.toLowerCase().includes(prenomoublieStr.toLowerCase())){
                                usernameStr = docsOublie[i].inscritusername;
                                passwordStr = docsOublie[i].inscritpassword;
                            }
                        }

                        document.getElementById('username').value = usernameStr;
                        document.getElementById('password').value = passwordStr;

                        document.getElementById('detail').style.display = 'block';
                        document.getElementById('detailuser').style.display = 'block';
                        document.getElementById('userCode').style.display = 'none';
                        document.getElementById('detailprenoms').style.display = 'none';
                        document.getElementById('demarrage').style.display = 'block';
                        document.getElementById('acceder').style.display = 'block';
                        document.getElementById('inscription').style.display = 'none';
                        titre.innerHTML = 'Identifiez-vous';
                    }
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }

        /***************************
        accederBtn
        *****************************/
        document.getElementById('accederBtn').addEventListener('click', accederBtnChange);
        async function accederBtnChange(e){
            e.preventDefault();

            var useraccederStr = document.getElementById('username').value;
            var passwordaccederStr = document.getElementById('password').value;
            var statutaccederStr = 'Oui';
            var prenomStr,codeStr,cycleStr = '';

            var docsCheck = [];
            var docsAcceder = [];

            if(!useraccederStr){
                alert("Entrer votre Username");
            }else if(!passwordaccederStr){
                alert("Entrer votre Password");
            }else{
                try {
                    let resultCheck = await db.createIndex({
                        index: {
                            fields: ['inscritstatut']
                        }
                    });
                    resultCheck = await db.find({
                        selector: {
                            inscritstatut: statutaccederStr
                        }
                    });
                    docsCheck = resultCheck.docs;
                    var resultatCheck = docsCheck.length;
                    if(resultatCheck === 0){
                        alert("Vous n'avez pas encore créé votre compte gratuit. S'inscrire");
                    }else{
                        let resultAcceder = await db.createIndex({
                            index: {
                                fields: ['inscritusername', 'inscritpassword']
                            }
                        });
                        resultAcceder = await db.find({
                            selector: {
                                inscritusername: useraccederStr.trim(),
                                inscritpassword: passwordaccederStr.trim()
                            }
                        });
                        docsAcceder = resultAcceder.docs;
                        var resultatAcceder = docsAcceder.length;
                        if(resultatAcceder === 0){
                            alert("Vos Indentifiants sont incoorects. Reprendre");
                        }else{
                            for(let i=0; i<docsAcceder.length; i++){
                                prenomStr = docsAcceder[i].inscritprenom;
                                codeStr = docsAcceder[i].inscritcode;
                                cycleStr = docsAcceder[i].inscritcycle;
                            }
                            var userCodeTexte = document.getElementById('userCode');
                            userCodeTexte.innerText = codeStr;
                            var prenomTexte = document.getElementById('detailprenoms');
                            prenomTexte.innerText = prenomStr;

                            var cycleScolaireTexte = document.getElementById('cycleScolaire');
                            if(cycleStr === 'premier'){
                                cycleScolaireTexte.innerHTML = '<var>1<sup>er</sup></var> Cycle';
                            }else if(cycleStr === 'second'){
                                cycleScolaireTexte.innerHTML = '<var>2<sup>d</sup></var> Cycle';
                            }else{
                                cycleScolaireTexte.innerHTML = 'Tec Cycle'; 
                            }

                            document.getElementById('detail').style.display = 'block';
                            document.getElementById('detailuser').style.display = 'block';
                            document.getElementById('userCode').style.display = 'block';
                            document.getElementById('detailprenoms').style.display = 'block';
                            document.getElementById('demarrage').style.display = 'none';
                            document.getElementById('acceder').style.display = 'none';
                            document.getElementById('tableaubord').style.display = 'block';
                            document.getElementById('dashboard').style.display = 'block';
                            titre.innerHTML = 'Tableau de Bord'; 
                        }
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }

        /*****************************************************
        SYNCHRONIZATION COUCHDB AND POUCHDB ACTIVATION
        ******************************************************/
        /*if (remoteCouch) {
            sync();
        }else{
            alert('FAILED');
        }*/
    }

    /****************
    HELPER FUNCTION
    *****************/
    /**1: Get Current Date in JavaScript */
    function getCurrentDate(){
        // Date object
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1;
        var yyyy = today.getFullYear();
        if(dd<10){
            dd='0'+dd;
        }
        if(mm<10){
            mm='0'+mm;
        }
        // Current Date
        var date = yyyy+'-'+mm+'-'+dd;
        return date;
    }

    window.addEventListener('load', () => {
        new taskDemarrer();
    }, false);
}) ();