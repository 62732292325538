import '../configuration.css';
import './pouchconfigannee';
import DateInput from '../../components/DateInput';
import AjouterButton from '../../components/AjouterButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function AddAnnee() {
    return ( 
        <div id="addannee">
            <KeyboardBackspace style={prevArrows} id="prevaddanneeBtn_1" />
            <br/><br/>
            <form>
                <div id="formaddclasse" style={scroller}>
                    <br/>
                    <div id="addselectionannee">
                        <label style={labelStyle}>Subdivision Année Scolaire</label>
                        <select id="seladdannee">
                            <option value=''>Sélectionner</option>
                            <option value='Trimestre'>Trimestrielle</option>
                            <option value='Semestre'>Semestrielle</option>
                        </select>
                    </div>
                    <br/>
                    <div id="addselectiontrimestre">
                        <label style={labelStyle}>Calendrier Trimestriel</label>
                        <select id="seladdtrimannee">
                            <option value=''>Sélection Trimestre</option>
                            <option value='Trimestre 1'>Trimestre 1</option>
                            <option value='Trimestre 2'>Trimestre 2</option>
                            <option value='Trimestre 3'>Trimestre 3</option>
                        </select>
                    </div>
                    <div id="addselectionsemestre">
                        <label style={labelStyle}>Calendrier Semestriel</label>
                        <select id="seladdsemannee">
                            <option value=''>Sélection Semestre</option>
                            <option value='Semestre 1'>Semestre 1</option>
                            <option value='Semestre 2'>Semestre 2</option>
                        </select>
                    </div>
                    <br/>
                    <div id="adddebutannee">
                        <label style={labelStyle}>Date Début</label>
                        <DateInput idinput ="adddatedebutannee" date="date" /> 
                    </div>
                    <br/>
                    <div id="addfinannee">
                        <label style={labelStyle}>Date Fin</label>
                        <DateInput idinput ="adddatefinannee" date="date" /> 
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="addselectanneedivision" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="addselectanneescolaire" type="text" />
                    </div>
                    <br/>
                    <div id="saveannee">
                        <AjouterButton 
                        idajouterbutton="enregistreranneeBtn"
                        ajouter="Enregistrer"
                        />
                    </div>
                    <br/><br/>
                </div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default AddAnnee;