import CrudMoyGenerale from './CrudMoyGenerale';
import AddMoyGenerale from './AddMoyGenerale';
import EditMoyGenerale from './EditMoyGenerale';
import DeleteMoyGenerale from './DeleteMoyGenerale';
import SearchMoyGenerale from './SearchMoyGenerale';

function MoyGenerale() {
    return ( 
        <div id="moygenerale">
            <CrudMoyGenerale />
            <AddMoyGenerale />
            <EditMoyGenerale />
            <DeleteMoyGenerale />
            <SearchMoyGenerale />
        </div>
    );
}

export default MoyGenerale;