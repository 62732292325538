import './moyannuelle.css';
import './pouchmoyannuelle';
import ModifierButton from '../../components/ModifierButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function EditMoyAnnuelle() {
    return ( 
        <div id="editmoyannuelle">
            <KeyboardBackspace style={prevArrows} id="preveditmoyannuelleBtn_1" />
            <br/><br/>
            <form>
                <div id="formeditmoyannuelle" style={scroller}>
                    <br/>
                    <div id="changecalendriermoyannuelle">
                        <label style={labelStyle}>Sélection Calendrier Scolaire</label>
                        <div id="editcalendriermoyannuelle"></div>
                    </div>
                    <br/>
                    <div id="changeprogrammemoyannuelle">
                        <label id="editmatiereprogrammemoyannuelle" style={labelStyle}>Moyennne Annuelle</label>
                        <br/><br/>
                        <table className='thin_table_config'>
                            <thead>
                                <tr>
                                    <th>Calendrier</th>
                                    <th>Moyenne</th>
                                </tr>
                            </thead>
                            <tbody id='editProgrammeMoyAnnuelleTableBody'></tbody>
                        </table>
                    </div>
                    <br/>
                    <div style={{display:'none'}}>
                        <input id ="editidmoyannuelle" type="text" />
                    </div>
                    <div id="changetotalcumulmoyannuelle">
                        <label style={labelStyle}>Total Moyennes Générales</label>
                        <div id="edittotalcumulmoyannuelle"></div>
                    </div>
                    <div id="changetotalcoefficientmoyannuelle">
                        <label style={labelStyle}>Total Calendriers Cumulés</label>
                        <div id="edittotalcoefficientmoyannuelle"></div>
                    </div>
                    <div id="changeobtenumoyannuelle">
                        <label style={labelStyle}>Moyenne Annuelle</label>
                        <div style={{fontWeight:'bold',color:'#1a1aff'}} id="editmoyannuelleobtenue"></div>
                    </div>
                    <br/>
                    
                    <div style={{display:'none'}}>
                        <input id ="editselectcalendriermoyannuelle" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editmoyennecumulmoyannuelle" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editcoefficientcumulemoyannuelle" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editmoyennemoyannuelle" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editmoyannuelledate" type="date" />
                    </div>
                    <br/>
                    <div id="changemoyannuelle">
                        <ModifierButton 
                            idmodifierbutton="modifiermoyannuelleBtn"
                            modifier="Modifier"
                        />
                    </div>
                    <br/><br/>
                </div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default EditMoyAnnuelle;