import DetailUser from './DetailUser';

function Detail() {
    return ( 
        <div id="detail">
            <DetailUser />
        </div>
    );
}

export default Detail;