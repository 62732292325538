import '../configuration.css';
import './pouchconfigclasse';
import TextInput from '../../components/TextInput';
import ModifierButton from '../../components/ModifierButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function EditClasse() {
    return ( 
        <div id="editclasse">
            <KeyboardBackspace style={prevArrows} id="preveditclasseBtn_1" />
            <br/><br/>
            <form>
                <br/>
                <div id="formeditclasse" style={scroller}>
                    <br/>
                    <div id="seleditclassepremier">
                        <label style={labelStyle}>Classe</label>
                        <select id="seleditclasse1">
                            <option value=''>Sélection classe (Facultatif)</option>
                            <option value='6-ième'>6-ième</option>
                            <option value='5-ième'>5-ième</option>
                            <option value='4-ième'>4-ième</option>
                            <option value='3-ième'>3-ième</option>
                        </select>
                    </div>
                    <div id="seleditclassesecond">
                        <label style={labelStyle}>Classe</label>
                        <select id="seleditclasse2">
                            <option value=''>Sélection classe (Facultatif)</option>
                            <option value='2-de'>2-de</option>
                            <option value='1-re'>1-re</option>
                            <option value='T-le'>T-le</option>
                        </select>
                    </div>
                    <div id="seleditclassetechnique">
                        <label style={labelStyle}>Classe</label>
                        <TextInput idinput ="seleditclasse3" texte="text" place="Entrer Classe (Facultatif)" /> 
                    </div>
                    <br/>
                    <div>
                        <label style={labelStyle}>Numéro</label>
                        <TextInput idinput ="editnumeroclasse" texte="text" place="Entrer Numéro Classe" /> 
                    </div>
                    <br/>
                    <div>
                        <label style={labelStyle}>Nom Professeur Principal</label>
                        <TextInput idinput ="editprofprincipalclasse" texte="text" place="Entrer Nom Prof Principal" /> 
                    </div>
                    <br/>
                    <div style={{display:'none'}}>
                        <input id ="editdateclasse" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editnomclasse" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editidclasse" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editstatutclasse" type="text" />
                    </div>
                    <ModifierButton 
                    idmodifierbutton="modifierclasseBtn"
                    modifier="Modifier"
                    />
                    <br/><br/>
                </div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default EditClasse;