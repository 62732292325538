//import './demarrage.css';
import './pouchdemarrer';
import { Accessibility, } from '@material-ui/icons';

function Acceder() {
    return ( 
        <div>
            <div id="acceder">
                <form>
                    <div id="formacceder">
                        <div>
                            <input id ="username" type="text" placeholder="Username" />
                        </div>
                        <br/>
                        <div>
                            <input id ="password" type="text" placeholder="Password" />
                        </div>
                        <br/>
                        <label style={{color:'#1a1aff'}} id="forgetpasswordBtn">Password Oublié?</label>
                        <br/>
                        <div>
                            <button id="accederBtn" style={connectionFormButtonStyle}>
                                <Accessibility />
                                Accéder
                            </button>
                        </div>
                        <br/>
                        <label>Pas de compte? </label><label style={{color:'#1a1aff'}} id="inscrireBtn">S'inscrire</label>
                    </div>
                </form>
            </div>
        </div>
    );
}

/*const inputDataStyle = {
    width: '80%',
    height: 30,
    color:"#000000",
    fontSize: 18,
    fontWeight: "bold",
};*/

const connectionFormButtonStyle = {
    marginTop: '4%',
    flex: 1,
    width: 200,
    height: 50,
    textAlign: "center",
    alignContent: 'center',
    backgroundColor: "#008000", border: 1, solid: "#008000",
    fontSize: 18,
    borderRadius: 15,
    color: "#ffffff",
    /*textTransform: "uppercase",*/
};

export default Acceder;