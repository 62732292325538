import '../configuration.css';
import '../pouchconfiguration';
import TextInput from '../../components/TextInput';
import { KeyboardBackspace, Delete, } from '@material-ui/icons';

function DeleteEtablissement() {
    return ( 
        <div id="deleteetablissement">
            <KeyboardBackspace style={prevArrows} id="prevdeleteetablissementBtn_1" />
            <br/><br/>
            <form>
                <div id="formdeleteetablissement" style={scroller}>
                    <br/>
                    <div>
                        <label style={labelStyle}>Nom Etablissement</label>
                        <TextInput idinput ="deletenometablissement" texte="text" read="readOnly" />
                    </div>
                    <br/>
                    <div>
                        <label style={labelStyle}>Ville</label>
                        <TextInput idinput ="deletevilleetablissement" texte="text" read="readOnly" />
                    </div>
                    <br/>
                    <div>
                        <label style={labelStyle}>Adresse</label>
                        <TextInput idinput ="deleteadresseetablissement" texte="text" read="readOnly" />
                    </div>
                    <br/>
                    <div>
                        <label style={labelStyle}>Nom Principal/Proviseur</label>
                        <TextInput idinput ="deleteprincipaletablissement" texte="text" read="readOnly" />
                    </div>
                    <br/>
                    <div>
                        <label style={labelStyle}>Téléphone</label>
                        <TextInput idinput ="deletecontactetablissement" texte="text" read="readOnly" />
                    </div>
                    <br/>
                    <div>
                        <label style={labelStyle}>E-Mail</label>
                        <TextInput idinput ="deleteemailetablissement" texte="text" read="readOnly" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deletedateetablissement" type="text" readOnly />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deleteidetablissement" type="text" readOnly />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deletesattutetablissement" type="text" readOnly />
                    </div>
                    <br/>
                    <div>
                        <button id="supprimeretablissementBtn" style={deleteFormButtonStyle}>
                            <Delete />
                            Supprimer
                        </button>
                    </div>
                    <br/><br/>
                </div>
                <div id="modaloutetablissement"></div>
                <div id="backdropoutetablissement"></div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '90%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

const deleteFormButtonStyle = {
    marginTop: '4%',
    flex: 1,
    width: 200,
    height: 50,
    textAlign: "center",
    alignContent: 'center',
    backgroundColor: "#ff6600", border: 1, solid: "#ff6600",
    fontSize: 18,
    borderRadius: 15,
    color: "#ffffff",
    /*textTransform: "uppercase",*/
};

export default DeleteEtablissement;