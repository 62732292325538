import './recherche.css';
import './pouchrecherche';
import ConsulterButton from '../../components/ConsulterButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function SearchRecherche() {
    return ( 
        <div id="searchrecherche">
            <KeyboardBackspace style={prevArrows} id="prevsearchrechercheBtn_1" />
            <br/><br/>
            <form>
                <div id="formsearchrecherche" style={scroller}>
                    <br/>
                    <div>
                        <label style={labelStyle}>Sélection matière</label>
                        <div id="searchmatiererecherche"></div>
                    </div>
                    <br/>
                    <div id="vieweffectuerecherche">
                        <label style={labelStyle}>Recherche Effectuée</label>
                        <select id="searcheffectuerecherche">
                            <option value="">Sélectionner</option>
                            <option value='Oui'>Oui</option>
                            <option value="Non">Non</option>
                        </select>
                    </div>
                    <br/>
                    <div id="viewrecherche">
                        <label id="viewmatiererecherche" style={labelStyle}>Matière</label>
                        <br/><br/>
                        <table className='thin_table_config'>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Libellé</th>
                                </tr>
                            </thead>
                            <tbody id='viewSearchRechercheTableBody'></tbody>
                        </table>
                    </div>
                    <br/>
                    <div id="viewconsulterrecherche">
                        <ConsulterButton 
                        idconsulterbutton="consulterrechercheBtn"
                        consulter="OK"
                        />
                    </div>
                    <br/><br/>
                </div>
                <div id="modaloutsearchrecherche"></div>
                <div id="backdropoutsearchrecherche"></div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default SearchRecherche;