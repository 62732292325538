import './tache.css';
import './pouchtache';
import ConsulterButton from '../../components/ConsulterButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function SearchTache() {
    return ( 
        <div id="searchtache">
            <KeyboardBackspace style={prevArrows} id="prevsearchtacheBtn_1" />
            <br/><br/>
            <form>
                <div id="formsearchtache" style={scroller}>
                    <br/>
                    <div id="viewtypetache">
                        <label style={labelStyle}>Type Tâche</label>
                        <select id="selsearchtypetache">
                            <option value="">Sélectionner</option>
                            <option value='Classe'>Classe</option>
                            <option value="Ordinaire">Ordinaire</option>
                            <option value="Extraordinaire">Extraordinaire</option>
                        </select>
                    </div>
                    <br/>
                    <div id="viewtacheeffectue">
                        <label style={labelStyle}>Tâche Effectuée</label>
                        <select id="searchtacheeffectue">
                            <option value="">Sélectionner</option>
                            <option value='Oui'>Oui</option>
                            <option value="Non">Non</option>
                        </select>
                    </div>
                    <br/>
                    <div id="viewtabletache">
                        <label id="viewcontenutache" style={labelStyle}>Type Tâche</label>
                        <br/><br/>
                        <table className='thin_table_config'>
                            <thead>
                                <tr>
                                    <th>Echéance</th>
                                    <th>Contenu</th>
                                </tr>
                            </thead>
                            <tbody id='viewSearchtacheTableBody'></tbody>
                        </table>
                    </div>
                    <br/>
                    <div id="viewtache">
                        <ConsulterButton 
                        idconsulterbutton="consultertacheBtn"
                        consulter="OK"
                    />
                    </div>
                    <br/><br/>
                </div>
                <div id="modaloutsearchtache"></div>
                <div id="backdropoutsearchtache"></div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default SearchTache;