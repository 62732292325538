function DateInput(props) {
    return ( 
        <div>
            <input style={inputDateStyle} id={props.idinput} type={props.date} readOnly={props.read} />
        </div>
    );
}

const inputDateStyle = {
    width: '88%',
    height: 40,
    color:"#000000",
    fontSize: 18,
    //fontWeight: "bold",
};

export default DateInput;