import CrudRecherche from "./CrudRecherche";
import AddRecherche from "./AddRecherche";
import EditRecherche from "./EditRecherche";
import DeleteRecherche from "./DeleteRecherche";
import SearchRecherche from "./SearchRecherche";

function Recherche() {
    return ( 
        <div id="recherche">
            <CrudRecherche />
            <AddRecherche />
            <EditRecherche />
            <DeleteRecherche />
            <SearchRecherche />
        </div>
    );
}

export default Recherche;