/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import PouchDB from 'pouchdb';
import PouchFind from 'pouchdb-find';

(function(){
    var taskConfigMatiere = function(){
        /*****************************************************
        ENABLING POUCHDB WITHOUT SYNC TO REMOTE COUCHDB
        ******************************************************/
        //SET UP POUCHDB AND CREATE THE DATABASE 
        const db = new PouchDB('suclu');
        PouchDB.plugin(PouchFind);

        /***************
        crudmatiereBtn
        *****************/
        /**nav previous page button*/
        document.getElementById('prevcrudmatiereBtn_1').addEventListener('click', prevcrudmatiere1BtnChange);
        async function prevcrudmatiere1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'block';
            document.getElementById('configurer').style.display = 'block';
            document.getElementById('matiere').style.display = 'none';
            titre.innerHTML = 'Configuration App'; 
        }

        /***************************
        addmatiereBtn
        *****************************/
        document.getElementById('addmatiereBtn').addEventListener('click', addmatiereBtnChange);
        async function addmatiereBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            if(!userCodeStr){
                alert('Connectez-vous à votre Compte avant de continuer');
            }else{
                document.getElementById('titre').style.display = 'block';
                var titre = document.getElementById('titre');

                document.getElementById('demarrage').style.display = 'none';
                document.getElementById('tableaubord').style.display = 'none';
                document.getElementById('dashboard').style.display = 'none';
                document.getElementById('configurations').style.display = 'block';
                document.getElementById('configurer').style.display = 'none';
                document.getElementById('matiere').style.display = 'block';
                document.getElementById('crudmatiere').style.display = 'none';
                document.getElementById('addmatiere').style.display = 'block';
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('editmatiere').style.display = 'none';
                document.getElementById('deletematiere').style.display = 'none';
                document.getElementById('searchmatiere').style.display = 'none';
                titre.innerHTML = 'Enregistrer Matières Classe'; 
            }
        }
        /**nav previous page button*/
        document.getElementById('prevaddmatiereBtn_1').addEventListener('click', prevaddmatiere1BtnChange);
        async function prevaddmatiere1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('matiere').style.display = 'block';
            document.getElementById('crudmatiere').style.display = 'block';
            document.getElementById('addmatiere').style.display = 'none';
            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('editmatiere').style.display = 'none';
            document.getElementById('deletematiere').style.display = 'none';
            document.getElementById('searchmatiere').style.display = 'none';
            titre.innerHTML = 'Configuration Matières Classe';  
        }
        /***************************
        enregistrermatiereBtn
        *****************************/
        document.getElementById('enregistrermatiereBtn').addEventListener('click', enregistrermatiereBtnChange);
        async function enregistrermatiereBtnChange(e){
            e.preventDefault();

            var usercodeStr = document.getElementById('userCode').innerText;
            var addnommatiereStr = document.getElementById('addnommatiere').value;
            var addcoefficientmatiereStr = document.getElementById('addcoefficientmatiere').value;
            var addprofesseurmatiereStr = document.getElementById('addprofesseurmatiere').value;
            var datejourStr = getCurrentDate();
            var addstatutmatiereStr = 'Oui';

            var docsMatiere = [];

            if(!usercodeStr){
                alert("Identifiez-vous avant de continuer");
            }else if(!addnommatiereStr){
                alert("Entrer Nom Matière");
            }else if(!addcoefficientmatiereStr){
                alert("Entrer Coefficient Matière");
            }else if(!addprofesseurmatiereStr){
                alert("Entrer Nom Professeur Matière");
            }else{
                //check if this matiere has already been registered
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: ['matierestatut', 'inscritcode']
                        }
                    });
                    result = await db.find({
                        selector: {
                            matierestatut: addstatutmatiereStr,
                            inscritcode: usercodeStr
                        }
                    });
                    docsMatiere  = result.docs;
                    var resultatMatiere = docsMatiere.length;
                    if(resultatMatiere === 0){
                        //Create the document _id and _rev.
                        var now = Date.now();
                        var id = 'matiere_' + now;
                        // Add the document to the database.
                        var response = await db.put({
                            _id: id,
                            inscritcode: usercodeStr,
                            matierenom: addnommatiereStr,
                            matierecoefficient: addcoefficientmatiereStr,
                            matiereprofesseur: addprofesseurmatiereStr,
                            matieredate: datejourStr,
                            matierestatut: addstatutmatiereStr,
                        });
                        // check if document inserted and synced
                        var doc = await db.get(response.id);
                        if(doc !== ''){
                            alert('Enregistré. Entrer Matière Suivante');
                            document.getElementById('addnommatiere').value = '';
                            document.getElementById('addcoefficientmatiere').value = '';
                            document.getElementById('addprofesseurmatiere').value = '';
                        }else{
                            alert('Echec Enregistrement de ce Jour. Reprendre');
                        }
                    }else{
                        var nomMatiere = '';
                        for(let i=0; i<docsMatiere.length; i++){
                            if(docsMatiere[i].matierenom.toLowerCase().includes(addnommatiereStr.toLowerCase())){
                                nomMatiere = docsMatiere[i].matierenom;
                            }
                        }
                        if(nomMatiere === ''){
                            //Create the document _id and _rev.
                            var now = Date.now();
                            var id = 'matiere_' + now;
                            // Add the document to the database.
                            var response = await db.put({
                                _id: id,
                                inscritcode: usercodeStr,
                                matierenom: addnommatiereStr,
                                matierecoefficient: addcoefficientmatiereStr,
                                matiereprofesseur: addprofesseurmatiereStr,
                                matieredate: datejourStr,
                                matierestatut: addstatutmatiereStr,
                            });
                            // check if document inserted and synced
                            var doc = await db.get(response.id);
                            if(doc !== ''){
                                alert('Enregistré. Entrer Matière Suivante');
                                document.getElementById('addnommatiere').value = '';
                                document.getElementById('addcoefficientmatiere').value = '';
                                document.getElementById('addprofesseurmatiere').value = '';
                            }else{
                                alert('Echec Enregistrement de ce Jour. Reprendre');
                            }
                        }else{
                            alert("Cette Matière a été déja Enregistrée. Entrer Matière Suivante");
                            document.getElementById('addnommatiere').value = '';
                            document.getElementById('addcoefficientmatiere').value = '';
                            document.getElementById('addprofesseurmatiere').value = '';
                        }
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }

        /***************************
        editsemaineBtn
        *****************************/
        document.getElementById('editmatiereBtn').addEventListener('click', editmatiereBtnChange);
        async function editmatiereBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutMatiereStr = 'Oui';
            var docsEditMatiere = [];
            var matiereIdStr,inscritCodeStr,matiereNomStr,matiereCoefficientStr,matiereProfesseurStr,
            matiereDateStr,matiereStatutStr = '';
            var docsEdit = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: ['matierestatut', 'inscritcode']
                    }
                });
                result = await db.find({
                    selector: {
                        matierestatut: statutMatiereStr,
                        inscritcode: userCodeStr
                    }
                });
                docsEditMatiere  = result.docs;
                var resultatEditMatiere = docsEditMatiere.length;
                if(resultatEditMatiere === 0){
                    alert("matières Classe pas encore enregistrées");
                }else{
                    var divClear;
                    var divModifier;
                    divClear = document.getElementById('selectioneditmatiere');
                    while(divClear.hasChildNodes()) {
                        divClear.removeChild(divClear.childNodes[0]);
                    }
                    divModifier = document.getElementById('selectioneditmatiere');
                    //Create and append select list
                    var selectList = document.createElement("select");
                    selectList.id = "seleditmatiere";
                    divModifier.appendChild(selectList);
                    //Create and append the options
                    var option_null = document.createElement("option");
                    option_null.value = '';
                    option_null.text = 'Sélectionner Matière';
                    selectList.appendChild(option_null);
                    for (var i = 0; i <docsEditMatiere.length; i++){
                        var option = document.createElement("option");
                        option.value = docsEditMatiere[i]._id;
                        option.text = docsEditMatiere[i].matierenom;
                        selectList.appendChild(option);
                    }

                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'block';
                    document.getElementById('configurer').style.display = 'none';
                    document.getElementById('matiere').style.display = 'block';
                    document.getElementById('crudmatiere').style.display = 'none';
                    document.getElementById('addmatiere').style.display = 'none';
                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('changecoefficient').style.display = 'none';
                    document.getElementById('changeprofesseur').style.display = 'none';
                    document.getElementById('changematiere').style.display = 'none';
                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('editmatiere').style.display = 'block';
                    document.getElementById('deletematiere').style.display = 'none';
                    document.getElementById('searchmatiere').style.display = 'none';
                    titre.innerHTML = 'Modifier Matières Classe'; 

                    document.getElementById('seleditmatiere').addEventListener('change', seleditmatiereChange);
                    async function seleditmatiereChange(e){
                        e.preventDefault();

                        var seleditmatiereStr = document.getElementById('seleditmatiere').value;
                        try {
                            let resultEdit = await db.createIndex({
                                index: {
                                    fields: ['matierestatut', 'inscritcode','_id']
                                }
                            });
                            resultEdit = await db.find({
                                selector: {
                                    matierestatut: statutMatiereStr,
                                    inscritcode: userCodeStr,
                                    _id: seleditmatiereStr
                                }
                            });
                            docsEdit  = resultEdit.docs;
                            var resultatEdit = docsEdit.length;
                            if(resultatEdit === 0){
                                document.getElementById('editidmatiere').value = '';
                                document.getElementById('editcodeusermatiere').value = '';
                                document.getElementById('editnommatiere').value = '';
                                document.getElementById('editcoefficientmatiere').value = '';
                                document.getElementById('editprofesseurmatiere').value = '';
                                document.getElementById('editdatematiere').value = '';
                                document.getElementById('editstatutmatiere').value = '';
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('changecoefficient').style.display = 'none';
                                document.getElementById('changeprofesseur').style.display = 'none';
                                document.getElementById('changematiere').style.display = 'none';
                            }else{
                                for(let j=0; j<docsEdit.length; j++){
                                    matiereIdStr = docsEdit[j]._id;
                                    inscritCodeStr = docsEdit[j].inscritcode;
                                    matiereNomStr = docsEdit[j].matierenom;
                                    matiereCoefficientStr = docsEdit[j].matierecoefficient;
                                    matiereProfesseurStr = docsEdit[j].matiereprofesseur;
                                    matiereDateStr = docsEdit[j].matieredate;
                                    matiereStatutStr = docsEdit[j].matierestatut;
                                }

                                document.getElementById('editidmatiere').value = matiereIdStr;
                                document.getElementById('editcodeusermatiere').value = inscritCodeStr;
                                document.getElementById('editnommatiere').value = matiereNomStr;
                                document.getElementById('editcoefficientmatiere').value = matiereCoefficientStr;
                                document.getElementById('editprofesseurmatiere').value = matiereProfesseurStr;
                                document.getElementById('editdatematiere').value = matiereDateStr;
                                document.getElementById('editstatutmatiere').value = matiereStatutStr;
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('changecoefficient').style.display = 'block';
                                document.getElementById('changeprofesseur').style.display = 'block';
                                document.getElementById('changematiere').style.display = 'block';
                            }
                        } catch (error) {
                            alert('Erreur Exécution');
                        }
                    }
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        /**nav previous page button*/
        document.getElementById('preveditmatiereBtn_1').addEventListener('click', preveditmatiere1BtnChange);
        async function preveditmatiere1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('matiere').style.display = 'block';
            document.getElementById('crudmatiere').style.display = 'block';
            document.getElementById('addmatiere').style.display = 'none';
            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('editmatiere').style.display = 'none';
            document.getElementById('deletematiere').style.display = 'none';
            document.getElementById('searchmatiere').style.display = 'none';
            titre.innerHTML = 'Configuration Matières Classe';
        }
        /***************************
        modifiermatiereBtn
        *****************************/
        document.getElementById('modifiermatiereBtn').addEventListener('click', modifiermatiereBtnChange);
        async function modifiermatiereBtnChange(e){
            e.preventDefault();

            var editidmatiereStr = document.getElementById('editidmatiere').value;
            var editcodeusermatiereStr = document.getElementById('editcodeusermatiere').value;
            var editnommatiereStr = document.getElementById('editnommatiere').value;
            var editcoefficientmatiereStr = document.getElementById('editcoefficientmatiere').value;
            var editprofesseurmatiereStr = document.getElementById('editprofesseurmatiere').value;
            var editdatematiereStr = document.getElementById('editdatematiere').value;
            var editstatutmatiereStr = document.getElementById('editstatutmatiere').value;

            if(!editcodeusermatiereStr){
                alert("Identifiez-vous avant de continuer");
            }else if(!editcoefficientmatiereStr){
                alert("Entrer Coefficient Matière");
            }else if(!editprofesseurmatiereStr){
                alert("Entrer Nom Professeur");
            }else{
                try {
                    // Retrieve the document to get the latest revision
                    var id = editidmatiereStr;
                    var doc = await db.get(id);
                    // Make changes
                    doc.inscritcode = editcodeusermatiereStr;
                    doc.matierenom = editnommatiereStr;
                    doc.matierecoefficient = editcoefficientmatiereStr;
                    doc.matiereprofesseur = editprofesseurmatiereStr;
                    doc.matieredate = editdatematiereStr;
                    doc.matierestatut = editstatutmatiereStr;
                    // Put the document, saving it as a new revision
                    var response = await db.put(doc);
                    // Get the document we change and update the UI.
                    var docEdit = await db.get(response.id);
                    if(docEdit !== ''){
                        alert("Matière Classe Modifiée avec Succès");
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'block';
                        document.getElementById('configurer').style.display = 'block';
                        document.getElementById('matiere').style.display = 'none';
                        titre.innerHTML = 'Configuration App';
                    }else{
                        alert("Erreur Modification. Reprendre");
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'block';
                        document.getElementById('configurer').style.display = 'block';
                        document.getElementById('matiere').style.display = 'none';
                        titre.innerHTML = 'Configuration App';
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }

        /***************************
        deletematiereBtn
        *****************************/
        document.getElementById('deletematiereBtn').addEventListener('click', deletematiereBtnChange);
        async function deletematiereBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutMatiereStr = 'Oui';
            var docsDeleteMatiere = [];
            var docsDelete = [];
            var matiereIdStr,matiereCoefficientStr,matiereProfesseurStr = '';
            try {
                let result = await db.createIndex({
                    index: {
                        fields: ['matierestatut', 'inscritcode']
                    }
                });
                result = await db.find({
                    selector: {
                        matierestatut: statutMatiereStr,
                        inscritcode: userCodeStr
                    }
                });
                docsDeleteMatiere  = result.docs;
                var resultatDeleteMatiere = docsDeleteMatiere.length;
                if(resultatDeleteMatiere === 0){
                    alert("Matières Classe pas encore enregistrées");
                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'block';
                    document.getElementById('configurer').style.display = 'block';
                    document.getElementById('matiere').style.display = 'none';
                    titre.innerHTML = 'Configuration App';
                }else{
                    var divClear;
                    var divModifier;
                    divClear = document.getElementById('selectiondeletematiere');
                    while(divClear.hasChildNodes()) {
                        divClear.removeChild(divClear.childNodes[0]);
                    }
                    divModifier = document.getElementById('selectiondeletematiere');
                    //Create and append select list
                    var selectList = document.createElement("select");
                    selectList.id = "seldeletematiere";
                    divModifier.appendChild(selectList);
                    //Create and append the options
                    var option_null = document.createElement("option");
                    option_null.value = '';
                    option_null.text = 'Sélectionner Matière';
                    selectList.appendChild(option_null);
                    for (var i = 0; i <docsDeleteMatiere.length; i++){
                        var option = document.createElement("option");
                        option.value = docsDeleteMatiere[i]._id;
                        option.text = docsDeleteMatiere[i].matierenom;
                        selectList.appendChild(option);
                    }

                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'block';
                    document.getElementById('configurer').style.display = 'none';
                    document.getElementById('matiere').style.display = 'block';
                    document.getElementById('crudmatiere').style.display = 'none';
                    document.getElementById('addmatiere').style.display = 'none';
                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('cancelcoefficient').style.display = 'none';
                    document.getElementById('cancelprofesseur').style.display = 'none';
                    document.getElementById('cancelmatiere').style.display = 'none';
                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('editmatiere').style.display = 'none';
                    document.getElementById('deletematiere').style.display = 'block';
                    document.getElementById('searchmatiere').style.display = 'none';
                    titre.innerHTML = 'Supprimer Matières Classe'; 

                    document.getElementById('seldeletematiere').addEventListener('change', seldeletematiereChange);
                    async function seldeletematiereChange(e){
                        e.preventDefault();

                        var seldeletematiereStr = document.getElementById('seldeletematiere').value;
                        try {
                            //check if this day exists in document emploi_
                            var statutEmploiStr = 'Oui';
                            var docsEmploi = [];
                            try {
                                let resultEmploi = await db.createIndex({
                                    index: {
                                        fields: [
                                            'inscritcode', 
                                            'emploijour', 
                                            'emploistatut'
                                        ]
                                    }
                                });
                                resultEmploi = await db.find({
                                    selector: {
                                        inscritcode: userCodeStr,
                                        emploijour: seldeletematiereStr,
                                        emploistatut: statutEmploiStr,
                                    }
                                });
                                docsEmploi  = resultEmploi.docs;
                                var resultatEmploi = docsEmploi.length;
                                if(resultatEmploi === 0){
                                    let resultDelete = await db.createIndex({
                                        index: {
                                            fields: ['_id']
                                        }
                                    });
                                    resultDelete = await db.find({
                                        selector: {
                                            matierestatut: statutMatiereStr,
                                            inscritcode: userCodeStr,
                                            _id: seldeletematiereStr
                                        }
                                    });
                                    docsDelete  = resultDelete.docs;
                                    var resultatDelete = docsDelete.length;
                                    if(resultatDelete === 0){
                                        document.getElementById('deleteidmatiere').value = '';
                                        document.getElementById('deletecoefficientmatiere').value = '';
                                        document.getElementById('deleteprofesseurmatiere').value = '';
                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('cancelcoefficient').style.display = 'none';
                                        document.getElementById('cancelprofesseur').style.display = 'none';
                                        document.getElementById('cancelmatiere').style.display = 'none';
                                    }else{
                                        for(let j=0; j<docsDelete.length; j++){
                                            matiereIdStr = docsDelete[j]._id;
                                            matiereCoefficientStr = docsDelete[j].matierecoefficient;
                                            matiereProfesseurStr = docsDelete[j].matiereprofesseur;
                                        }
        
                                        document.getElementById('deleteidmatiere').value = matiereIdStr;
                                        document.getElementById('deletecoefficientmatiere').value = matiereCoefficientStr;
                                        document.getElementById('deleteprofesseurmatiere').value = matiereProfesseurStr;
                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('cancelcoefficient').style.display = 'block';
                                        document.getElementById('cancelprofesseur').style.display = 'block';
                                        document.getElementById('cancelmatiere').style.display = 'block';
                                    }
                                }else{
                                    alert("Suppression Impossible. Donnée en Exécution.");
                                }
                            } catch (error) {
                                alert('Erreur Exécution');
                            }
                        } catch (error) {
                            alert('Erreur Exécution');
                        }
                    }
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        /**nav previous page button*/
        document.getElementById('prevdeletematiereBtn_1').addEventListener('click', prevdeletematiere1BtnChange);
        async function prevdeletematiere1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('matiere').style.display = 'block';
            document.getElementById('crudmatiere').style.display = 'block';
            document.getElementById('addmatiere').style.display = 'none';
            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('editmatiere').style.display = 'none';
            document.getElementById('deletematiere').style.display = 'none';
            document.getElementById('searchmatiere').style.display = 'none';
            titre.innerHTML = 'Configuration Matières Classe'; 
        }

        /***************************
        supprimermatiereBtn
        *****************************/
        document.getElementById('supprimermatiereBtn').addEventListener('click', supprimermatiereBtnChange);
        async function supprimermatiereBtnChange(e){
            e.preventDefault();

            var divClearModal = document.getElementById('modaloutmatiere');
            let divModal = document.createElement('div');
            divModal.innerHTML =
            `<div class="modal">
                <p>Êtes-vous sûr de Supprimer ?</p>
                <button class="btn btn--alt" id="ouiHandlerMatiereBtn">Oui</button>
                <button class="btn" id="nonHandlerMatiereBtn">Non</button>
            </div>`;
            divClearModal.appendChild(divModal);

            var divClearBackdrop = document.getElementById('backdropoutmatiere');
            let divBack = document.createElement('div');
            divBack.innerHTML =
            `<div class="backdrop" id="backdropHandlerMatiereBtn"></div>`;
            divClearBackdrop.appendChild(divBack);

            document.getElementById('ouiHandlerMatiereBtn').addEventListener('click', ouiHandlerMatiereBtnChange);
            async function ouiHandlerMatiereBtnChange(e){
                e.preventDefault();

                var deleteidmatiereStr = document.getElementById('deleteidmatiere').value;

                try {
                    //Delete
                    var doc = await db.get(deleteidmatiereStr);
                    var response = await db.remove(doc);
                    if(response.ok){
                        alert('Matière Classe Supprimée avec Succès');
                        var divClearModal = document.getElementById('modaloutmatiere');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutmatiere');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'block';
                        document.getElementById('configurer').style.display = 'block';
                        document.getElementById('matiere').style.display = 'none';
                        titre.innerHTML = 'Configuration App';
                    }else{
                        alert("Impossible Supprimer Matière Classe");
                        var divClearModal = document.getElementById('modaloutmatiere');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutmatiere');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'block';
                        document.getElementById('configurer').style.display = 'block';
                        document.getElementById('matiere').style.display = 'none';
                        titre.innerHTML = 'Configuration App';
                    }

                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
            document.getElementById('nonHandlerMatiereBtn').addEventListener('click', nonHandlerMatiereBtnChange);
            async function nonHandlerMatiereBtnChange(e){
                e.preventDefault();

                var divClearModal = document.getElementById('modaloutmatiere');
                while(divClearModal.hasChildNodes()) {
                    divClearModal.removeChild(divClearModal.childNodes[0]);
                }

                var divClearBackdrop = document.getElementById('backdropoutmatiere');
                while(divClearBackdrop.hasChildNodes()) {
                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                }
            }
            document.getElementById('backdropHandlerMatiereBtn').addEventListener('click', backdropHandlerMatiereBtnChange);
            async function backdropHandlerMatiereBtnChange(e){
                e.preventDefault();

                var divClearModal = document.getElementById('modaloutmatiere');
                while(divClearModal.hasChildNodes()) {
                    divClearModal.removeChild(divClearModal.childNodes[0]);
                }

                var divClearBackdrop = document.getElementById('backdropoutmatiere');
                while(divClearBackdrop.hasChildNodes()) {
                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                }
            }
        }

        /***************************
        searchmatiereBtn
        *****************************/
        document.getElementById('searchmatiereBtn').addEventListener('click', searchmatiereBtnChange);
        async function searchmatiereBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutMatiereStr = 'Oui';
            var docsSearchMatiere = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: ['matierestatut', 'inscritcode']
                    }
                });
                result = await db.find({
                    selector: {
                        matierestatut: statutMatiereStr,
                        inscritcode: userCodeStr
                    }
                });
                docsSearchMatiere  = result.docs;
                var resultatSearchMatiere = docsSearchMatiere.length;
                if(resultatSearchMatiere === 0){
                    alert("Matières Classe pas encore enregistrées");
                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'block';
                    document.getElementById('configurer').style.display = 'block';
                    document.getElementById('matiere').style.display = 'none';
                    titre.innerHTML = 'Configuration App';
                }else{
                    updateSearchMatiereUIWithDocs(docsSearchMatiere);
                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'block';
                    document.getElementById('configurer').style.display = 'none';
                    document.getElementById('matiere').style.display = 'block';
                    document.getElementById('crudmatiere').style.display = 'none';
                    document.getElementById('addmatiere').style.display = 'none';
                    document.getElementById('editmatiere').style.display = 'none';
                    document.getElementById('deletematiere').style.display = 'none';
                    document.getElementById('searchmatiere').style.display = 'block';
                    titre.innerHTML = 'Liste Matières Classe'; 
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        // Adds the document to the HTML First table.
        // If the document already exists in the table, it will be updated.
        function viewSearchMatiereTableDocToTable(doc, viewSearchMatiereTableBody){
            // Each row of the table has an id attribute set to the doc._id field.
            var tr = viewSearchMatiereTableBody.querySelector(`#${doc._id}`);

            if( !tr ) {
                tr = document.createElement('tr');
                tr.id = doc._id;
            }
        
            // Remove any existing table cells.
            while(tr.hasChildNodes()) {
                tr.removeChild(tr.childNodes[0]);
            }

            // Create new table cells for text fields.
            var tdMatiere = document.createElement('td');
            tdMatiere.innerText = doc.matierenom;
            tr.appendChild(tdMatiere);

            // Create new table cells for text fields.
            var tdCoefficient = document.createElement('td');
            tdCoefficient.innerText = doc.matierecoefficient;
            tr.appendChild(tdCoefficient);

            // Create new table cells for text fields.
            var tdProfesseur = document.createElement('td');
            tdProfesseur.innerText = doc.matiereprofesseur;
            tr.appendChild(tdProfesseur);

            // Add the row to the top of the table.
            viewSearchMatiereTableBody.insertBefore(tr, viewSearchMatiereTableBody.nextSibling);
        }
        /**Clear and update the UI to display the docs.*/
        function updateSearchMatiereUIWithDocs(docs) {
            var viewSearchMatiereTableBody = document.getElementById('viewSearchMatiereTableBody');
            
            // Remove all child nodes from the table
            while(viewSearchMatiereTableBody.hasChildNodes()) {
                viewSearchMatiereTableBody.removeChild(viewSearchMatiereTableBody.childNodes[0]);
            }
            
            // Add the new documents to the table
            for(var i=0; i<docs.length; i++) {
                var doc = docs[i];
                viewSearchMatiereTableDocToTable(doc, viewSearchMatiereTableBody);
            }
        }

        /**nav previous page button*/
        document.getElementById('prevsearchmatiereBtn_1').addEventListener('click', prevsearchmatiere1BtnChange);
        async function prevsearchmatiere1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('matiere').style.display = 'block';
            document.getElementById('crudmatiere').style.display = 'block';
            document.getElementById('addmatiere').style.display = 'none';
            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('editmatiere').style.display = 'none';
            document.getElementById('deletematiere').style.display = 'none';
            document.getElementById('searchmatiere').style.display = 'none';
            titre.innerHTML = 'Configuration Matières Classe';
        }

        /***************************
        consultermatiereBtn
        *****************************/
        document.getElementById('consultermatiereBtn').addEventListener('click', consultermatiereBtnChange);
        async function consultermatiereBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'block';
            document.getElementById('configurer').style.display = 'block';
            document.getElementById('matiere').style.display = 'none';
            titre.innerHTML = 'Configuration App'; 
        }
    };

    /****************
    HELPER FUNCTION
    *****************/
    /**1: Get Current Date in JavaScript */
    function getCurrentDate(){
        // Date object
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1;
        var yyyy = today.getFullYear();
        if(dd<10){
            dd='0'+dd;
        }
        if(mm<10){
            mm='0'+mm;
        }
        // Current Date
        var date = yyyy+'-'+mm+'-'+dd;
        return date;
    }


    window.addEventListener('load', () =>{
        new taskConfigMatiere();
    })
}) ();