import './moteur.css';
import './pouchmoteur';
import { KeyboardBackspace, } from '@material-ui/icons';
import CompoWikipedia from '../components/CompoWikipedia';

function CrudMoteur() {
    return ( 
        <div id="crudmoteur">
            <KeyboardBackspace style={prevArrows} id="prevcrudmoteurBtn_1" />
            <br/><br/>
            <form>
                <div id="moteursetup" style={scroller}>
                    <div className='texte'>
                        <p>
                            Wikipedia est une encyclopédie en ligne gratuite, 
                            collaborative, multilingue et à contenu gratuit, fondée en 2001, 
                            soutenue et hébergée par la Wikimedia Foundation, une organisation américaine à but 
                            non lucratif.
                        </p>
                    </div>
                    <CompoWikipedia 
                    idaddwikipedia = "addwikipediaBtn" 
                    titre = "Wikipedia" />
                    <br/><br/>
                </div>
            </form>
        </div>
    );
}

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#008000',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default CrudMoteur;