import './moymatiere.css';
import './pouchmoymatiere';
import ModifierButton from '../../components/ModifierButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function EditMoyMatiere() {
    return ( 
        <div id="editmoymatiere">
            <KeyboardBackspace style={prevArrows} id="preveditmoymatiereBtn_1" />
            <br/><br/>
            <form>
                <div id="formeditmoymatiere" style={scroller}>
                    <br/>
                    <div id="changecalendriermoymatiere">
                        <label style={labelStyle}>Sélection Calendrier Scolaire</label>
                        <div id="editcalendriermoymatiere"></div>
                    </div>
                    <br/>
                    <div id="changematieremoymatiere">
                        <label style={labelStyle}>Sélection Matière</label>
                        <div id="editmatieremoymatiere"></div>
                    </div>
                    <br/>
                    <div id="changeprogrammemoymatiere">
                        <label id="editmatiereprogrammemoymatiere" style={labelStyle}>Trimestre+Matière</label>
                        <br/><br/>
                        <table className='thin_table_config'>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Type</th>
                                    <th>Note</th>
                                    <th>Coeff</th>
                                </tr>
                            </thead>
                            <tbody id='editProgrammeMoyMatiereTableBody'></tbody>
                        </table>
                    </div>
                    <br/>
                    <div id="changetotalcumulmoymatiere">
                        <label style={labelStyle}>Total Notes Cumulées</label>
                        <div id="edittotalcumulmoymatiere"></div>
                    </div>
                    <div id="changetotalcoefficientmoymatiere">
                        <label style={labelStyle}>Total Coefficients Cumulés</label>
                        <div id="edittotalcoefficientmoymatiere"></div>
                    </div>
                    <div id="changeobtenumoymatiere">
                        <label style={labelStyle}>Moyenne Obtenue</label>
                        <div style={{fontWeight:'bold',color:'#1a1aff'}} id="editobtenuemoymatiere"></div>
                    </div>
                    <br/>
                    <div style={{display:'none'}}>
                        <input id ="editidmoymatiere" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editselectcalendriermoymatiere" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editselectmatieremoymatiere" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editcoefficientmoymatiere" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editnotecumulmoymatiere" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editcoefficientcumulemoymatiere" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editmoyennemoymatiere" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editmoymatiereeffectue" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editmoymatieredate" type="date" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editmoymatierestatut" type="text" />
                    </div>
                    <br/>
                    <div id="changemoymatiere">
                        <ModifierButton 
                            idmodifierbutton="modifiermoymatiereBtn"
                            modifier="Modifier"
                        />
                    </div>
                    <br/><br/>
                </div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default EditMoyMatiere;