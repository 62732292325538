import Dashboard from "./Dashboard";

function TableauBord() {
    return ( 
        <div id="tableaubord">
            <Dashboard />
        </div>
     );
}

export default TableauBord;