import React from 'react';
import logo from './suclu-icon-192.png';
import './App.css';
import { PlayArrowRounded, AddToHomeScreen } from '@material-ui/icons';

function App() {
  return (
    <div className="App" id="app">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p style={{color:'#1a1aff'}}>Le Vade-Mecum Scolaire</p>
        <p>
          <button id="demarrerBtn" style={demarrerButton}>
            <PlayArrowRounded style={{float:'left'}} />
            Démarrer
          </button>
        </p>
        <a
          className="App-link"
          href="https://www.carions.grandbico.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          &copy; Carion's
        </a>
        <div id="installation">
          <button id="installerBtn" style={installerButtonStyle}>
            <AddToHomeScreen />
            Installer l'Application
          </button>
        </div>
      </header>
    </div>
  );
}

//Inline Styling
const demarrerButton = {
  flex: 1,
  width: 200,
  height: 50,
  textAlign: "center",
  backgroundColor: "#008000", border: 1, solid: "#008000",
  fontSize: 18,
  borderRadius: 15,
  color: "#ffffff",
  textTransform: "uppercase",
}

const installerButtonStyle = {
  marginTop: '4%',
  flex: 1,
  width: 250,
  height: 50,
  textAlign: "center",
  alignContent: 'center',
  backgroundColor: "#3366ff", border: 1, solid: "#3366ff",
  fontSize: 18,
  borderRadius: 15,
  color: "#ffffff",
  /*textTransform: "uppercase",*/
};

export default App;
