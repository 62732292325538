(function(){
    var taskMoyenne = function(){

        /***************
        calculmoyennesBtn
        *****************/
        /**nav previous page button*/
        document.getElementById('prevcalculBtn_1').addEventListener('click', prevcalcul1BtnChange);
        async function prevcalcul1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'block';
            document.getElementById('dashboard').style.display = 'block';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('moyenne').style.display = 'none';
            titre.innerHTML = 'Tableau de Bord'; 
        }

        /***************************
        moyennematiereBtn
        *****************************/
        document.getElementById('moyennematiereBtn').addEventListener('click', moyennematiereBtnChange);
        async function moyennematiereBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('moyenne').style.display = 'none';
            document.getElementById('moymatiere').style.display = 'block';
            document.getElementById('crudmoymatiere').style.display = 'block';
            document.getElementById('addmoymatiere').style.display = 'none';
            document.getElementById('editmoymatiere').style.display = 'none';
            document.getElementById('deletemoymatiere').style.display = 'none';
            document.getElementById('searchmoymatiere').style.display = 'none';
            document.getElementById('moygenerale').style.display = 'none';
            document.getElementById('moyannuelle').style.display = 'none';
            titre.innerHTML = 'Calcul Moyennes Par Matière'; 
        }

        /***************************
        moyennegeneraleBtn
        *****************************/
        document.getElementById('moyennegeneraleBtn').addEventListener('click', moyennegeneraleBtnChange);
        async function moyennegeneraleBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('moyenne').style.display = 'none';
            document.getElementById('moymatiere').style.display = 'none';
            document.getElementById('moygenerale').style.display = 'block';
            document.getElementById('crudmoygenerale').style.display = 'block';
            document.getElementById('addmoygenerale').style.display = 'none';
            document.getElementById('editmoygenerale').style.display = 'none';
            document.getElementById('deletemoygenerale').style.display = 'none';
            document.getElementById('searchmoygenerale').style.display = 'none';
            document.getElementById('moyannuelle').style.display = 'none';
            titre.innerHTML = 'Calcul Moyenne Générale'; 
        }

        /***************************
        moyenneannuelleBtn
        *****************************/
        document.getElementById('moyenneannuelleBtn').addEventListener('click', moyenneannuelleBtnChange);
        async function moyenneannuelleBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('moyenne').style.display = 'none';
            document.getElementById('moymatiere').style.display = 'none';
            document.getElementById('moygenerale').style.display = 'none';
            document.getElementById('moyannuelle').style.display = 'block';
            document.getElementById('crudmoyannuelle').style.display = 'block';
            /*document.getElementById('addmoyannuelle').style.display = 'none';
            document.getElementById('editmoyannuelle').style.display = 'none';
            document.getElementById('deletemoyannuelle').style.display = 'none';
            document.getElementById('searchmoyannuelle').style.display = 'none';*/
            titre.innerHTML = 'Calcul Moyenne Annuelle'; 
        }
    };

    window.addEventListener('load', () =>{
        new taskMoyenne();
    })
}) ();