import './recherche.css';
import './pouchrecherche';
import TextAreaInput from '../../components/TextAreaInput';
import DateInput from '../../components/DateInput';
import SupprimerButton from '../../components/SupprimerButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function DeleteRecherche() {
    return ( 
        <div id="deleterecherche">
            <KeyboardBackspace style={prevArrows} id="prevdeleterechercheBtn_1" />
            <br/><br/>
            <form>
                <div id="formdeleterecherche" style={scroller}>
                    <br/>
                    <div id="cancelmatiererecherche">
                        <label style={labelStyle}>Sélection Matière</label>
                        <div id="deletematiererecherche"></div>
                    </div>
                    <br/>
                    <div id="canceltexterecherche">
                        <label style={labelStyle}>Libellé Recherche</label>
                        <TextAreaInput rowlength="6" idinput ="deletetexterecherche" texte="text" read="readOnly" />
                    </div>
                    <br/>
                    <div id="canceldaterecherche">
                        <label style={labelStyle}>Date Devoir</label>
                        <DateInput idinput ="deleteecheancerecherche" date="date" read="readOnly" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deleteidrecherche" type="text" />
                    </div>
                    <br/>
                    <div id="cancelrecherche">
                        <SupprimerButton 
                            idsupprimerbutton="supprimerrechercheBtn"
                            supprimer="Supprimer"
                        />
                    </div>
                    <br/><br/>
                </div>
                <div id="modaloutrecherche"></div>
                <div id="backdropoutrecherche"></div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default DeleteRecherche;