/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import './moteur.css';
import './pouchmoteur';
import { KeyboardBackspace, } from '@material-ui/icons';

import { useState } from 'react';

function AddWikipedia() {
    const [search, setSearch] = useState("");
    const [results, setResults] = useState([]);
    const [searchInfo, setSearchInfo] = useState({});

    const handleSearch = async e => {
        e.preventDefault();

        if(search === '') return;

        const endpoint = `https://fr.wikipedia.org/w/api.php?action=query&list=search&prop=info&inprop=url&utf8=&format=json&origin=*&srlimit=30&srsearch=${search}`;

        const response = await fetch(endpoint);

        if(!response.ok){
            throw Error(response.statusText);
        }

        const json = await response.json();
        
        setResults(json.query.search);
        setSearchInfo(json.query.searchinfo);
    }

    return ( 
        <div id="addwikipedia">
            <KeyboardBackspace style={prevArrows} id="prevaddwikipediaBtn_1" />
            <br/><br/>
            <div id="formaddwikipedia" style={scroller}>
                <br/>
                <form onSubmit={handleSearch}>
                    <input 
                        style={inputSearchStyle} 
                        type="search" 
                        placeholder="Que Cherchez-vous ?" 
                        value={search} 
                        onChange={e => setSearch(e.target.value)}
                    />
                </form>
                { (searchInfo.totalhits) ? <p>Resultats Recherche: {searchInfo.totalhits}</p> : '' }
                <br/>
                <div className='results'>
                    {results.map((result, i) => {
                        const url = `https://fr.wikipedia.org/?curid=${result.pageid}`;

                        return(
                            <div className='result' key={i}>
                                <h3>{result.title}</h3>
                                <p dangerouslySetInnerHTML={{ __html: result.snippet}}></p>
                                <a href={url} target="_blank" rel="noreferrer">Lire la suite...</a>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}

const inputSearchStyle = {
    width: '85%',
    height: 40,
    color:"#000000",
    fontSize: 18,
    borderRadius:'16px',
    overFlow:'hidden',
    transition:'0.4s',
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default AddWikipedia;