import './tache.css';
import './pouchtache';
import TextAreaInput from '../../components/TextAreaInput';
import DateInput from '../../components/DateInput';
import AjouterButton from '../../components/AjouterButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function AddTache() {
    return ( 
        <div id="addtache">
            <KeyboardBackspace style={prevArrows} id="prevaddtacheBtn_1" />
            <br/><br/>
            <form>
                <div id="formaddtache" style={scroller}>
                    <br/>
                    <div id="savetypetache">
                        <label style={labelStyle}>Type Tâche</label>
                        <select id="seladdtypetache">
                            <option value="">Sélectionner</option>
                            <option value='Classe'>Classe</option>
                            <option value="Ordinaire">Ordinaire</option>
                            <option value="Extraordinaire">Extraordinaire</option>
                        </select>
                    </div>
                    <br/>
                    <div id="savedatetache">
                        <label style={labelStyle}>Date Echéance</label>
                        <DateInput idinput ="adddatetache" date="date" />
                    </div>
                    <br/>
                    <div id="savetextetache">
                        <label style={labelStyle}>Saisir Tâche</label>
                        <TextAreaInput rowlength="6" idinput ="addtextetache" texte="text" />
                    </div>
                    <br/>
                    <div id="savetache">
                        <AjouterButton 
                        idajouterbutton="enregistrertacheBtn"
                        ajouter="Enregistrer"
                    />
                    </div>
                    <br/><br/>
                </div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default AddTache;