import '../configuration.css';
import './pouchconfigannee';
import DateInput from '../../components/DateInput';
import ModifierButton from '../../components/ModifierButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function EditAnnee() {
    return ( 
        <div id="editannee">
            <KeyboardBackspace style={prevArrows} id="preveditanneeBtn_1" />
            <br/><br/>
            <form>
                <div id="formeditclasse" style={scroller}>
                    <br/>
                    <div id="editselectionannee">
                        <select id="seleditannee">
                            <option value=''>Sélection Année Scolaire</option>
                            <option value='Trimestre'>Trimestrielle</option>
                            <option value='Semestre'>Semestrielle</option>
                        </select>
                    </div>
                    <div id="editselectiontrimestre">
                        <select id="seledittrimannee">
                            <option value=''>Sélection Trimestre</option>
                            <option value='Trimestre 1'>Trimestre 1</option>
                            <option value='Trimestre 2'>Trimestre 2</option>
                            <option value='Trimestre 3'>Trimestre 3</option>
                        </select>
                    </div>
                    <div id="editselectionsemestre">
                        <select id="seleditsemannee">
                            <option value=''>Sélection Semestre</option>
                            <option value='Semestre 1'>Semestre 1</option>
                            <option value='Semestre 2'>Semestre 2</option>
                        </select>
                    </div>
                    <br/>
                    <div id="editdebutannee">
                        <label style={labelStyle}>Date Début</label>
                        <DateInput idinput ="editdatedebutannee" date="date" /> 
                    </div>
                    <br/>
                    <br/>
                    <div id="editfinannee">
                        <label style={labelStyle}>Date Fin</label>
                        <DateInput idinput ="editdatefinannee" date="date" /> 
                    </div>
                    <br/>
                    <div style={{display:'none'}}>
                        <input id ="editidannee" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editcodeanneeuser" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editanneedivision" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editanneedate" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editanneestatut" type="text" />
                    </div>
                    <div id="changeannee">
                        <ModifierButton 
                        idmodifierbutton="modifieranneeBtn"
                        modifier="Modifier"
                        />
                    </div>
                    <br/><br/>
                </div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default EditAnnee;