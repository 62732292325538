import './inscription.css';

import '../demarrage/pouchdemarrer';
import { KeyboardBackspace, Add, } from '@material-ui/icons';

function Inscrire() {
    return ( 
        <div id="inscrire">
            <KeyboardBackspace style={prevArrows} id="previnscrireBtn_1" />
            <br/><br/>
            <form>
                <div id="forminscrire" style={scroller}>
                    <div>
                        <select id="selcycleinscrire">
                            <option value=''>Sélection cycle scolaire</option>
                            <option value='premier'>Premier Cycle</option>
                            <option value='second'>Second Cycle</option>
                            <option value='technique'>Technique</option>
                        </select>
                    </div>
                    <div>
                        <input id ="nominscrire" type="text" placeholder="Entrer votre nom" />
                    </div>
                    <br/>
                    <div>
                        <input id ="prenominscrire" type="text" placeholder="Entrer vos prenoms" />
                    </div>
                    <br/>
                    <div>
                        <input id ="usernameinscrire" type="text" placeholder="Entrer username" />
                    </div>
                    <br/>
                    <div>
                        <input id ="passwordinscrire" type="text" placeholder="Entrer password" />
                    </div>
                    <br/>
                    <div>
                        <input id ="confirmpasswordinscrire" type="text" placeholder="Confirmer password" />
                    </div>
                    <br/>
                    <div>
                        <button id="sinscrireBtn" style={connectionFormButtonStyle}>
                            <Add />
                            S'inscrire
                        </button>
                    </div>
                    <br/><br/>
                </div>
            </form>
        </div>
     );
}

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

const connectionFormButtonStyle = {
    marginTop: '4%',
    flex: 1,
    width: 200,
    height: 50,
    textAlign: "center",
    alignContent: 'center',
    backgroundColor: "#008000", border: 1, solid: "#008000",
    fontSize: 18,
    borderRadius: 15,
    color: "#ffffff",
    /*textTransform: "uppercase",*/
};

export default Inscrire;