import { Delete, NavigateNext, } from '@material-ui/icons';

function DeleteButton(props) {
    return ( 
        <div>
            <button id={props.iddeletebutton} style={deleteButton}>
                <Delete style={{float:'left',color:'#ff6600'}} />
                Supprimer {props.titre}
                <NavigateNext style={{float:'right',color:'#ff6600'}} />
            </button>
        </div>
    );
}

const deleteButton = {
    marginTop: '4%',
    flex: 1,
    width: '90%',
    height: 50,
    textAlign: "center",
    alignContent: 'center',
    border: "2px solid #ff6600",
    fontSize: 18,
    borderRadius: 10,
    color: "#ff6600",
    //textTransform: "uppercase",
}

export default DeleteButton;