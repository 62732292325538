import '../configuration.css';
import '../pouchconfiguration';
import TextInput from '../../components/TextInput';
import { KeyboardBackspace, Edit, } from '@material-ui/icons';

function EditEtablissement() {
    return ( 
        <div id="editetablissement">
            <KeyboardBackspace style={prevArrows} id="preveditetablissementBtn_1" />
            <br/><br/>
            <form>
                <div id="formeditetablissement" style={scroller}>
                    <br/>
                    <div>
                        <label style={labelStyle}>Nom Etablissement</label>
                        <TextInput idinput ="editnometablissement" texte="text" />
                    </div>
                    <br/>
                    <div>
                        <label style={labelStyle}>Ville</label>
                        <TextInput idinput ="editvilleetablissement" texte="text" />
                    </div>
                    <br/>
                    <div>
                        <label style={labelStyle}>Adresse</label>
                        <TextInput idinput ="editadresseetablissement" texte="text" />
                    </div>
                    <br/>
                    <div>
                        <label style={labelStyle}>Nom Principal/Proviseur</label>
                        <TextInput idinput ="editprincipaletablissement" texte="text" />
                    </div>
                    <br/>
                    <div>
                        <label style={labelStyle}>Téléphone</label>
                        <TextInput idinput ="editcontactetablissement" texte="text" />
                    </div>
                    <br/>
                    <div>
                        <label style={labelStyle}>E-mail</label>
                        <TextInput idinput ="editemailetablissement" texte="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editdateetablissement" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editidetablissement" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editsattutetablissement" type="text" />
                    </div>
                    <br/>
                    <div>
                        <button id="modifieretablissementBtn" style={editFormButtonStyle}>
                            <Edit />
                            Modifier
                        </button>
                    </div>
                    <br/><br/>
                </div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '90%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

const editFormButtonStyle = {
    marginTop: '4%',
    flex: 1,
    width: 200,
    height: 50,
    textAlign: "center",
    alignContent: 'center',
    backgroundColor: "#1a1aff", border: 1, solid: "#1a1aff",
    fontSize: 18,
    borderRadius: 15,
    color: "#ffffff",
    /*textTransform: "uppercase",*/
};

export default EditEtablissement;