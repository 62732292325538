import './emploidutemps.css';
import './pouchemploidutemps';
import { KeyboardBackspace, } from '@material-ui/icons';
import AddButton from "../components/AddButton";
import EditButton from "../components/EditButton";
import DeleteButton from "../components/DeleteButton";
import SearchButton from "../components/SearchButton";

function CrudEmploiDuTemps() {
    return ( 
        <div id="crudemploidutemps">
            <KeyboardBackspace style={prevArrows} id="prevcrudemploidutempsBtn_1" />
            <br/><br/>
            <div id="emploidutempssetup" style={scroller}>
                <AddButton 
                idaddbutton = "addemploidutempsBtn" 
                titre = "Emploi du Temps" />

                <EditButton 
                ideditbutton = "editemploidutempsBtn" 
                titre = "Emploi du Temps" />

                <DeleteButton 
                iddeletebutton = "deleteemploidutempsBtn" 
                titre = "Emploi du Temps" />

                <SearchButton 
                idsearchbutton = "searchemploidutempsBtn" 
                titre = "Emploi du Temps" />
            </div>
        </div>
    );
}

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#008000',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default CrudEmploiDuTemps;