import './pratique.css';
import './pouchpratique';
import TextAreaInput from '../../components/TextAreaInput';
import DateInput from '../../components/DateInput';
import SupprimerButton from '../../components/SupprimerButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function DeletePratique() {
    return ( 
        <div id="deletepratique">
            <KeyboardBackspace style={prevArrows} id="prevdeletepratiqueBtn_1" />
            <br/><br/>
            <form>
                <div id="formdeletepratique" style={scroller}>
                    <br/>
                    <div id="cancelmatierepratique">
                        <label style={labelStyle}>Sélection Matière</label>
                        <div id="deletematierepratique"></div>
                    </div>
                    <br/>
                    <div id="canceltextepratique">
                        <label style={labelStyle}>Enoncé Exercice</label>
                        <TextAreaInput rowlength="6" idinput ="deletetextepratique" texte="text" read="readOnly" />
                    </div>
                    <br/>
                    <div id="canceldatepratique">
                        <label style={labelStyle}>Date Echéance</label>
                        <DateInput idinput ="deleteecheancepratique" date="date" read="readOnly" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deleteidpratique" type="text" />
                    </div>
                    <br/>
                    <div id="cancelpratique">
                        <SupprimerButton 
                        idsupprimerbutton="supprimerpratiqueBtn"
                        supprimer="Supprimer"
                    />
                    </div>
                    <br/><br/>
                </div>
                <div id="modaloutpratique"></div>
                <div id="backdropoutpratique"></div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default DeletePratique;