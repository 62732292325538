window.addEventListener('load', () =>{

    document.getElementById('detail').style.display = 'none';
    document.getElementById('demarrage').style.display = 'none';

    //Incription folder
    document.getElementById('inscription').style.display = 'none';

    //TableauBord folder
    document.getElementById('tableaubord').style.display = 'none';

    //Configuration folder
    document.getElementById('configurations').style.display = 'none';

    //folder configuration/etablissement/
    document.getElementById('etablissement').style.display = 'none';

    //folder configuration/classe/
    document.getElementById('classe').style.display = 'none';

    //folder emploidutemps
    document.getElementById('emploidutemps').style.display = 'none';

    //folder exercice
    document.getElementById('exercice').style.display = 'none';

    //folder exercice/pratique
    document.getElementById('pratique').style.display = 'none';

    //folder exercice/tache
    document.getElementById('tache').style.display = 'none';

    //folder exercice/revision
    document.getElementById('revision').style.display = 'none';

    //folder exercice/recherche
    document.getElementById('recherche').style.display = 'none';

    //folder programme
    document.getElementById('programme').style.display = 'none';

    //folder NOTES
    document.getElementById('notes').style.display = 'none';

    //folder moyenne
    document.getElementById('moyenne').style.display = 'none';

    //folder moteur
    document.getElementById('moteur').style.display = 'none';
});