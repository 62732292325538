import './moygenerale.css';
import './pouchmoygenerale';
import SupprimerButton from '../../components/SupprimerButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function DeleteMoyGenerale() {
    return ( 
        <div id="deletemoygenerale">
            <KeyboardBackspace style={prevArrows} id="prevdeletemoygeneraleBtn_1" />
            <br/><br/>
            <form>
                <div id="formdeletemoygenerale" style={scroller}>
                    <br/>
                    <div id="cancelcalendriermoygenerale">
                        <label style={labelStyle}>Sélection Calendrier Scolaire</label>
                        <div id="deletecalendriermoygenerale"></div>
                    </div>
                    <br/>
                    <div id="cancelprogrammemoygenerale">
                        <label id="deletematiereprogrammemoygenerale" style={labelStyle}>Trimestre</label>
                        <br/><br/>
                        <table className='thin_table_config'>
                            <thead>
                                <tr>
                                    <th>Matière</th>
                                    <th>Moyenne</th>
                                    <th>Coeff.</th>
                                </tr>
                            </thead>
                            <tbody id='deleteProgrammeMoyGeneraleTableBody'></tbody>
                        </table>
                    </div>
                    <br/>
                    <div id="canceltotalcumulmoygenerale">
                        <label style={labelStyle}>Total Moyennes Cumulées</label>
                        <div id="deletetotalcumulmoygenerale"></div>
                    </div>
                    <div id="canceltotalcoefficientmoygenerale">
                        <label style={labelStyle}>Total Coefficients Cumulés</label>
                        <div id="deletetotalcoefficientmoygenerale"></div>
                    </div>
                    <div id="cancelobtenumoygenerale">
                        <label style={labelStyle}>Moyenne Générale</label>
                        <div style={{fontWeight:'bold',color:'#1a1aff'}} id="deletemoygeneraleobtenue"></div>
                    </div>
                    <br/>
                    <div style={{display:'none'}}>
                        <input id ="deleteidmoygenerale" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deleteselectcalendriermoygenerale" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deletemoyennecumulmoygenerale" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deletecoefficientcumulemoygenerale" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deletemoyennemoygenerale" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deletemoygeneraleeffectue" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deletemoygeneraledate" type="date" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deletemoygeneralestatut" type="text" />
                    </div>
                    <br/>
                    <div id="cancelmoygenerale">
                        <SupprimerButton 
                            idsupprimerbutton="supprimermoygeneraleBtn"
                            supprimer="Supprimer"
                        />
                    </div>
                    <br/><br/>
                </div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default DeleteMoyGenerale;