import { Delete, } from '@material-ui/icons';

function SupprimerButton(props) {
    return ( 
        <div>
            <button id={props.idsupprimerbutton} style={supprimerFormButtonStyle}>
                <Delete/>
                {props.supprimer}
            </button>
        </div>
    );
}

const supprimerFormButtonStyle = {
    marginTop: '4%',
    flex: 1,
    width: 200,
    height: 50,
    textAlign: "center",
    alignContent: 'center',
    backgroundColor: "#ff6600", border: 1, solid: "#ff6600",
    fontSize: 18,
    borderRadius: 15,
    color: "#ffffff",
    /*textTransform: "uppercase",*/
};

export default SupprimerButton;