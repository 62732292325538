import { Add, } from '@material-ui/icons';

function AjouterButton(props) {
    return ( 
        <div>
            <button id={props.idajouterbutton} style={ajouterFormButtonStyle}>
                <Add/>
                {props.ajouter}
            </button>
        </div>
    );
}

const ajouterFormButtonStyle = {
    marginTop: '4%',
    flex: 1,
    width: 200,
    height: 50,
    textAlign: "center",
    alignContent: 'center',
    backgroundColor: "#008000", border: 1, solid: "#008000",
    fontSize: 18,
    borderRadius: 15,
    color: "#ffffff",
    /*textTransform: "uppercase",*/
};

export default AjouterButton;