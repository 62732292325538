import { Edit, NavigateNext, } from '@material-ui/icons';

function EditButton(props) {
    return ( 
        <div>
            <button id={props.ideditbutton} style={editButton}>
                <Edit style={{float:'left',color:'1a1aff'}} />
                Modifier {props.titre}
                <NavigateNext style={{float:'right',color:'#1a1aff'}} />
            </button>
        </div>
    );
}

const editButton = {
    marginTop: '4%',
    flex: 1,
    width: '90%',
    height: 50,
    textAlign: "center",
    alignContent: 'center',
    border: "2px solid #1a1aff",
    fontSize: 18,
    borderRadius: 10,
    color: "#1a1aff",
    //textTransform: "uppercase",
}

export default EditButton;