(function(){
    let taskTableauBord = function(){
        /***************************
        prevdashbordBtn_1
        *****************************/
        /**nav previous page button*/
        document.getElementById('prevdashbordBtn_1').addEventListener('click', prevdashbord1BtnChange);
        async function prevdashbord1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'block';
            document.getElementById('acceder').style.display = 'block';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            titre.innerHTML = 'Identifiez-vous'; 
        }

        /***************************
        configurationBtn
        *****************************/
        document.getElementById('configurationBtn').addEventListener('click', configurationBtnChange);
        async function configurationBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'block';
            document.getElementById('configurer').style.display = 'block';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'none';
            document.getElementById('moyenne').style.display = 'none';
            document.getElementById('moteur').style.display = 'none';
            titre.innerHTML = 'Configuration App'; 
        }
        /**nav previous page button*/
        document.getElementById('prevconfigurerBtn_1').addEventListener('click', prevconfigurer1BtnChange);
        async function prevconfigurer1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'block';
            document.getElementById('dashboard').style.display = 'block';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'none';
            document.getElementById('moyenne').style.display = 'none';
            document.getElementById('moteur').style.display = 'none';
            titre.innerHTML = 'Tableau de Bord'; 
        }

        /***************************
        emploitempsBtn
        *****************************/
        document.getElementById('emploitempsBtn').addEventListener('click', emploitempsBtnChange);
        async function emploitempsBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'none';
            document.getElementById('moyenne').style.display = 'none';
            document.getElementById('moteur').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'block';
            document.getElementById('crudemploidutemps').style.display = 'block';
            document.getElementById('addemploidutemps').style.display = 'none';
            document.getElementById('editemploidutemps').style.display = 'none';
            document.getElementById('deleteemploidutemps').style.display = 'none';
            document.getElementById('searchemploidutemps').style.display = 'none';
            titre.innerHTML = 'Emploi du Temps'; 
        }

        /***************************
        exercicestachesBtn
        *****************************/
        document.getElementById('exercicestachesBtn').addEventListener('click', exercicestachesBtnChange);
        async function exercicestachesBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'block';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'none';
            document.getElementById('moyenne').style.display = 'none';
            document.getElementById('moteur').style.display = 'none';
            titre.innerHTML = 'Exercices et Tâches'; 
        }

        /***************************
        programmedevoirsBtn
        *****************************/
        document.getElementById('programmedevoirsBtn').addEventListener('click', programmedevoirsBtnChange);
        async function programmedevoirsBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('programme').style.display = 'block';
            document.getElementById('notes').style.display = 'none';
            document.getElementById('moyenne').style.display = 'none';
            document.getElementById('moteur').style.display = 'none';
            document.getElementById('crudprogramme').style.display = 'block';
            document.getElementById('addprogramme').style.display = 'none';
            document.getElementById('editprogramme').style.display = 'none';
            document.getElementById('deleteprogramme').style.display = 'none';
            document.getElementById('searchprogramme').style.display = 'none';
            titre.innerHTML = 'Programme des Devoirs'; 
        }

        /***************************
        noteclasseBtn
        *****************************/
        document.getElementById('noteclasseBtn').addEventListener('click', noteclasseBtnChange);
        async function noteclasseBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'block';
            document.getElementById('moyenne').style.display = 'none';
            document.getElementById('moteur').style.display = 'none';
            document.getElementById('crudnotes').style.display = 'block';
            document.getElementById('addnotes').style.display = 'none';
            document.getElementById('editnotes').style.display = 'none';
            document.getElementById('deletenotes').style.display = 'none';
            document.getElementById('searchnotes').style.display = 'none';
            titre.innerHTML = 'Notes de Classe'; 
        }

        /***************************
        calculmoyennesBtn
        *****************************/
        document.getElementById('calculmoyennesBtn').addEventListener('click', calculmoyennesBtnChange);
        async function calculmoyennesBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'none';
            document.getElementById('moteur').style.display = 'none';
            document.getElementById('moyenne').style.display = 'block';
            titre.innerHTML = 'Calcul des Moyennes';  
        }

        /***************************
        moteurBtn
        *****************************/
        document.getElementById('moteurBtn').addEventListener('click', moteurBtnChange);
        async function moteurBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'none';
            document.getElementById('moyenne').style.display = 'none';
            document.getElementById('moteur').style.display = 'block';
            document.getElementById('crudmoteur').style.display = 'block';
            /*document.getElementById('addgoogle').style.display = 'none';
            document.getElementById('addwikipedia').style.display = 'none';
            document.getElementById('addbing').style.display = 'none';
            document.getElementById('addyahoo').style.display = 'none';*/
            titre.innerHTML = 'Moteurs de Recherches';  
        }
    }

    window.addEventListener('load', () => {
        new taskTableauBord();
    }, false);
}) ();