function TextAreaInput(props) {
    return ( 
        <div>
            <textarea style={inputDateStyle} rows={props.rowlength} id={props.idinput} type={props.texte} placeholder={props.place} readOnly={props.read}></textarea>
        </div>
    );
}

const inputDateStyle = {
    width: '88%',
    color:"#000000",
    fontSize: 16,
    border: "1px solid #ccc",
    borderRadius: "4px",
    backgroundColor: "#f8f8f8",
    resize: "none",
};

export default TextAreaInput;