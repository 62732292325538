/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import './accueil.css';
function Nav() {
    function anneeDebut(){
        var currentYear = new Date().getFullYear();
        return currentYear;
    }
    function anneeFin(){
        var finYear = new Date().getFullYear()+1;
        return finYear;
    }
    function monthYear(){
        var currentMonth = new Date().getMonth();
        return currentMonth;
    }
    function anneeScolaireDebut(){
        var anneeDebutYear = anneeDebut();
        var anneeFinYear = anneeFin();
        var monthDebutYear = monthYear();
        var anneeCoursDebut = '';
        if(anneeDebutYear === anneeFinYear){
            if(monthDebutYear <= 9){
                anneeCoursDebut = anneeDebutYear;
            }else{
                anneeCoursDebut = anneeDebutYear+1;
            }
        }else{
            if(monthDebutYear <= 9){
                anneeCoursDebut = anneeDebutYear-1;
            }else{
                anneeCoursDebut = anneeDebutYear;
            }
        }
        return anneeCoursDebut;
    }
    function anneeScolaireFin(){
        var anneeDebutYear = anneeDebut();
        var anneeFinYear = anneeFin();
        var monthFinYear = monthYear();
        var anneeCoursFin = '';
        if(anneeDebutYear === anneeFinYear){
            if(monthFinYear <= 9){
                anneeCoursFin = anneeDebutYear+1;
            }else{
                anneeCoursFin = anneeDebutYear+1;
            }
        }else{
            if(monthFinYear <= 9){
                anneeCoursFin = anneeDebutYear;
            }else{
                anneeCoursFin = anneeDebutYear+1;
            }
        }
        return anneeCoursFin;
    }

    return ( 
        <div className="nav">
            <h3>Suclu</h3>
            <h5>{anneeScolaireDebut()}-{anneeScolaireFin()}</h5> 
            <label id="cycleScolaire"></label>
            <label id="userCode"></label>
        </div>
    );
}

export default Nav;