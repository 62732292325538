import '../configuration.css';
import './pouchconfigsemaine';
import ConsulterButton from '../../components/ConsulterButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function SearchSemaine() {
    return ( 
        <div id="searchsemaine">
            <KeyboardBackspace style={prevArrows} id="prevsearchsemaineBtn_1" />
            <br/><br/>
            <form>
                <div id="formsearchsemaine" style={scroller}>
                    <br/>
                    <div>
                    <label style={labelStyle}>Sélection Jours</label>
                        <select id="selsearchsemaine">
                            <option value="">Sélectionner</option>
                            <option value='Oui'>Jours de Classe</option>
                            <option value="Non">Non Jours de Classe</option>
                        </select>
                    </div>
                    <br/>
                    <div id="viewsearchsemaine">
                    <label id="viewjoursemaine" style={labelStyle}>Jour de Classe</label>
                        <br/><br/>
                        <table className='thin_table_config'>
                            <thead>
                                <tr>
                                    <th>Jour</th>
                                    <th>Début</th>
                                    <th>Fin</th>
                                </tr>
                            </thead>
                            <tbody id='viewSearchSemaineTableBody'></tbody>
                        </table>
                    </div>
                    <br/>
                    <div style={{display:'none'}}>
                        <input id ="searchjoursemaine" type="text" />
                    </div>
                    <div id="viewconsultersemaine">
                        <ConsulterButton 
                        idconsulterbutton="consultersemaineBtn"
                        consulter="OK"
                        />
                    </div>
                    <br/><br/>
                </div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default SearchSemaine;