import '../configuration.css';
import './pouchconfigmatiere';
import TextInput from '../../components/TextInput';
import NumberInput from '../../components/NumberInput';
import SupprimerButton from '../../components/SupprimerButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function DeleteMatiere() {
    return ( 
        <div id="deletematiere">
            <KeyboardBackspace style={prevArrows} id="prevdeletematiereBtn_1" />
            <br/><br/>
            <form>
                <div id="formdeletematiere" style={scroller}>
                    <br/>
                    <div>
                        <label style={labelStyle}>Sélection Matière</label>
                        <div id="selectiondeletematiere"></div>
                    </div>
                    <br/>
                    <div id="cancelcoefficient">
                        <label style={labelStyle}>Coefficient Matière</label>
                        <NumberInput idinput ="deletecoefficientmatiere" number="number" read="readOnly" /> 
                    </div>
                    <br/>
                    <div id="cancelprofesseur">
                        <label style={labelStyle}>Nom Professeur</label>
                        <TextInput idinput ="deleteprofesseurmatiere" texte="text" read="readOnly" />
                    </div>
                    <br/>
                    <div style={{display:'none'}}>
                        <input id ="deleteidmatiere" type="text" />
                    </div>
                    <div id="cancelmatiere">
                        <SupprimerButton 
                        idsupprimerbutton="supprimermatiereBtn"
                        supprimer="Supprimer"
                    />
                    </div>
                    <br/><br/>
                </div>
                <div id="modaloutmatiere"></div>
                <div id="backdropoutmatiere"></div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default DeleteMatiere;