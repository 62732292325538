import './programme.css';
import './pouchprogramme';
import ConsulterButton from '../components/ConsulterButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function SearchProgramme() {
    return ( 
        <div id="searchprogramme">
            <KeyboardBackspace style={prevArrows} id="prevsearchprogrammeBtn_1" />
            <br/><br/>
            <form>
                <div id="formsearchprogramme" style={scroller}>
                    <br/>
                    <div id="viewcalendrierprogramme">
                        <label style={labelStyle}>Sélection Calendrier Scolaire</label>
                        <div id="searchcalendrierprogramme"></div>
                    </div>
                    <br/>
                    <div id="viewmatiereprogramme">
                        <label style={labelStyle}>Sélection Matière</label>
                        <div id="searchmatiereprogramme"></div>
                    </div>
                    <br/>
                    <div id="viewtypeprogramme">
                        <label style={labelStyle}>Type Devoir</label>
                        <select id="selsearchtypeprogramme">
                            <option value="">Sélectionner</option>
                            <option value='Devoir'>Devoir</option>
                            <option value="Interrogation">Interrogation</option>
                            <option value="Tous">Tous Types</option>
                        </select>
                    </div>
                    <br/>
                    <div id="vieweffectueprogramme">
                        <label style={labelStyle}>Devoir Effectuée</label>
                        <select id="searcheffectueprogramme">
                            <option value="">Sélectionner</option>
                            <option value='Oui'>Oui</option>
                            <option value="Non">Non</option>
                            <option value="Tout">Tous</option>
                        </select>
                    </div>
                    <br/>
                    <div id="viewprogramme">
                        <label id="viewsearchmatiereprogramme" style={labelStyle}>Trimestre+Matière</label>
                        <br/><br/>
                        <table className='thin_table_config'>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Type</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody id='viewSearchProgrammeTableBody'></tbody>
                        </table>
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="searchselectcalendrierprogramme" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="searchselectmatiereprogramme" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="searchselecttypeprogramme" type="text" />
                    </div>
                    <br/>
                    <div id="viewconsulterprogramme">
                        <ConsulterButton 
                            idconsulterbutton="consulterprogrammeBtn"
                            consulter="OK"
                        />
                    </div>
                    <br/><br/>
                </div>
                <div id="modaloutsearchprogramme"></div>
                <div id="backdropoutsearchprogramme"></div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default SearchProgramme;