import './moymatiere.css';
import './pouchmoymatiere';
import { KeyboardBackspace, } from '@material-ui/icons';
import AddButton from "../../components/AddButton";
import EditButton from "../../components/EditButton";
import DeleteButton from "../../components/DeleteButton";
import SearchButton from "../../components/SearchButton";

function CrudMoyMatiere() {
    return ( 
        <div id="crudmoymatiere">
            <KeyboardBackspace style={prevArrows} id="prevcrudmoymatiereBtn_1" />
            <br/><br/>
            <div id="moymatieresetup" style={scroller}>
                <AddButton 
                idaddbutton = "addmoymatiereBtn" 
                titre = "Moyenne Matière" />

                <EditButton 
                ideditbutton = "editmoymatiereBtn" 
                titre = "Moyenne Matière" />

                <DeleteButton 
                iddeletebutton = "deletemoymatiereBtn" 
                titre = "Moyenne Matière" />

                <SearchButton 
                idsearchbutton = "searchmoymatiereBtn" 
                titre = "Moyenne Matière" />
            </div>
        </div>
    );
}

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#008000',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default CrudMoyMatiere;