import CrudProgramme from "./CrudProgramme";
import AddProgramme from "./AddProgramme";
import EditProgramme from "./EditProgramme";
import DeleteProgramme from "./DeleteProgramme";
import SearchProgramme from "./SearchProgramme";

function Programme() {
    return ( 
        <div id="programme">
            <CrudProgramme />
            <AddProgramme />
            <EditProgramme />
            <DeleteProgramme />
            <SearchProgramme />
        </div>
    );
}

export default Programme;