import './programme.css';
import './pouchprogramme';
import DateInput from '../components/DateInput';
import SupprimerButton from '../components/SupprimerButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function DeleteProgramme() {
    return ( 
        <div id="deleteprogramme">
            <KeyboardBackspace style={prevArrows} id="prevdeleteprogrammeBtn_1" />
            <br/><br/>
            <form>
                <div id="formdeleteprogramme" style={scroller}>
                    <br/>
                    <div id="cancelcalendrierprogramme">
                        <label style={labelStyle}>Sélection Calendrier Scolaire</label>
                        <div id="deletecalendrierprogramme"></div>
                    </div>
                    <br/>
                    <div id="cancelmatiereprogramme">
                        <label style={labelStyle}>Sélection Matière</label>
                        <div id="deletematiereprogramme"></div>
                    </div>
                    <br/>
                    <div id="canceltypeprogramme">
                        <label style={labelStyle}>Type Devoir</label>
                        <select id="seldeletetypeprogramme">
                            <option value="">Sélectionner</option>
                            <option value='Devoir'>Devoir</option>
                            <option value="Interrogation">Interrogation</option>
                        </select>
                    </div>
                    <br/>
                    <div id="cancelseldateprogramme">
                        <label style={labelStyle}>Sélection Date</label>
                        <div id="deleteseldateprogramme"></div>
                    </div>
                    <br/>
                    <div id="canceldateprogramme">
                        <label style={labelStyle}>Date Exécution</label>
                        <DateInput idinput ="deletedateecheanceprogramme" date="date" read="readOnly" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deleteselectcalendrierprogramme" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deleteselectmatiereprogramme" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deleteidprogramme" type="text" />
                    </div>
                    <br/>
                    <div id="cancelprogramme">
                        <SupprimerButton 
                            idsupprimerbutton="supprimerprogrammeBtn"
                            supprimer="Supprimer"
                        />
                    </div>
                    <br/><br/>
                </div>
                <div id="modaloutprogramme"></div>
                <div id="backdropoutprogramme"></div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default DeleteProgramme;