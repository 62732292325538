import CrudPratique from './CrudPratique';
import AddPratique from './AddPratique';
import EditPratique from './EditPratique';
import DeletePratique from './DeletePratique';
import SearchPratique from './SearchPratique';

function Pratique() {
    return ( 
        <div id="pratique">
            <CrudPratique />
            <AddPratique />
            <EditPratique />
            <DeletePratique />
            <SearchPratique />
        </div>
    );
}

export default Pratique;