import '../configuration.css';
import './pouchconfigclasse';
import TextInput from '../../components/TextInput';
import AjouterButton from '../../components/AjouterButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function AddClasse() {
    return ( 
        <div id="addclasse">
            <KeyboardBackspace style={prevArrows} id="prevaddclasseBtn_1" />
            <br/><br/>
            <form>
                <div id="formaddclasse" style={scroller}>
                    <br/>
                    <div id="seladdclassepremier">
                        <select id="seladdclasse1">
                            <option value=''>Sélection classe</option>
                            <option value='6-ième'>6-ième</option>
                            <option value='5-ième'>5-ième</option>
                            <option value='4-ième'>4-ième</option>
                            <option value='3-ième'>3-ième</option>
                        </select>
                    </div>
                    <div id="seladdclassesecond">
                        <select id="seladdclasse2">
                            <option value=''>Sélection classe</option>
                            <option value='2-de'>2-de</option>
                            <option value='1-re'>1-re</option>
                            <option value='T-le'>T-le</option>
                        </select>
                    </div>
                    <div id="seladdclassetechnique">
                        <TextInput idinput ="seladdclasse3" texte="text" place="Entrer Classe" /> 
                    </div>
                    <br/>
                    <TextInput idinput ="addnumeroclasse" texte="text" place="Entrer Numéro Classe" />
                    <br/>
                    <TextInput idinput ="addprofprincipalclasse" texte="text" place="Entrer Nom Prof Principal" />
                    <br/>
                    <AjouterButton 
                    idajouterbutton="enregistrerclasseBtn"
                    ajouter="Enregistrer"
                    />
                    <br/><br/>
                </div>
            </form>
        </div>
    );
}

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default AddClasse;