import Calcul from "./Calcul";

function Moyenne() {
    return ( 
        <div id="moyenne">
            <Calcul />
        </div>
    );
}

export default Moyenne;