import './emploidutemps.css';
import './pouchemploidutemps';
import TimeInput from '../components/TimeInput';
import SupprimerButton from '../components/SupprimerButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function DeleteEmploiDuTemps() {
    return ( 
        <div id="deleteemploidutemps">
            <KeyboardBackspace style={prevArrows} id="prevdeleteemploidutempsBtn_1" />
            <br/><br/>
            <form>
                <div id="formdeleteemploidutemps" style={scroller}>
                <br/>
                    <div>
                        <label style={labelStyle}>Sélection Jour</label>
                        <div id="deletejouremploidutemps"></div>
                    </div>
                    <br/>
                    <div id="cancelmatieres">
                        <label style={labelStyle}>Sélection Matière</label>
                        <div id="deletematiereemploidutemps"></div>
                    </div>
                    <br/>
                    <div id="cancelheure">
                        <label style={labelStyle}>Heure</label>
                        <TimeInput idinput ="deleteheureemploidutemps" time="time" read="readOnly" />
                    </div>
                    <br/>
                    <div style={{display:'none'}}>
                        <input id ="deleteidemploi" type="text" />
                    </div>
                    <div id="cancelemploidutemps">
                        <SupprimerButton 
                        idsupprimerbutton="supprimeremploidutempsBtn"
                        supprimer="Supprimer"
                    />
                    </div>
                    <br/><br/>
                </div>
                <div id="modaloutemploi"></div>
                <div id="backdropoutemploi"></div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default DeleteEmploiDuTemps;