import CrudEtablissement from './CrudEtablissement';
import AddEtablissement from './AddEtablissement';
import EditEtablissement from './EditEtablissement';
import DeleteEtablissement from './DeleteEtablissement';
import SearchEtablissement from './SearchEtablissement';

function Etablissement() {
    return ( 
        <div id="etablissement">
            <CrudEtablissement />
            <AddEtablissement />
            <EditEtablissement />
            <DeleteEtablissement />
            <SearchEtablissement />
        </div>
    );
}

export default Etablissement;