import './tache.css';
import './pouchtache';
import TextAreaInput from '../../components/TextAreaInput';
import DateInput from '../../components/DateInput';
import SupprimerButton from '../../components/SupprimerButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function DeleteTache() {
    return ( 
        <div id="deletetache">
            <KeyboardBackspace style={prevArrows} id="prevdeletetacheBtn_1" />
            <br/><br/>
            <form>
                <div id="formdeletetache" style={scroller}>
                    <br/>
                    <div id="canceltypetache">
                        <label style={labelStyle}>Type Tâche</label>
                        <select id="seldeletetypetache">
                            <option value="">Sélectionner</option>
                            <option value='Classe'>Classe</option>
                            <option value="Ordinaire">Ordinaire</option>
                            <option value="Extraordinaire">Extraordinaire</option>
                        </select>
                    </div>
                    <br/>
                    <div id="cancelecheancetache">
                        <label style={labelStyle}>Sélection Tâche</label>
                        <div id="deleteselecheance"></div>
                    </div>
                    <div id="canceldatetache">
                        <label style={labelStyle}>Date Echéance</label>
                        <DateInput idinput ="deleteecheancetache" date="date" read="readOnly" />
                    </div>
                    <br/>
                    <div id="canceltextetache">
                        <label style={labelStyle}>Contenu Tâche</label>
                        <TextAreaInput rowlength="6" idinput ="deletetextetache" texte="text" read="readOnly" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deleteidtache" type="text" />
                    </div>
                    <br/>
                    <div id="canceltache">
                        <SupprimerButton 
                        idsupprimerbutton="supprimertacheBtn"
                        supprimer="Supprimer"
                    />
                    </div>
                    <br/><br/>
                </div>
                <div id="modalouttache"></div>
                <div id="backdropouttache"></div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default DeleteTache;