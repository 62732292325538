import '../configuration.css';
import './pouchconfigmatiere';
import ConsulterButton from '../../components/ConsulterButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function SearchMatiere() {
    return ( 
        <div id="searchmatiere">
            <KeyboardBackspace style={prevArrows} id="prevsearchmatiereBtn_1" />
            <br/><br/>
            <form>
            <div id="formsearchmatiere" style={scroller}>
                <br/>
                <div>
                    <label style={labelStyle}>Consulter Matières</label>
                    <br/><br/>
                    <table className='thin_table_config'>
                        <thead>
                            <tr>
                                <th>Matière</th>
                                <th>Coeff</th>
                                <th>Professeur</th>
                            </tr>
                        </thead>
                        <tbody id='viewSearchMatiereTableBody'></tbody>
                    </table>
                </div>
                <br/>
                <div id="viewmatiere">
                    <ConsulterButton 
                    idconsulterbutton="consultermatiereBtn"
                    consulter="OK"
                />
                </div>
                <br/><br/>
            </div>
            </form>
        </div>
     );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default SearchMatiere;