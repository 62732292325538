import CrudMoteur from "./CrudMoteur";
import AddWikipedia from "./AddWikipedia";

function Moteur() {
    return ( 
        <div id="moteur">
            <CrudMoteur />
            <AddWikipedia />
        </div>
    );
}

export default Moteur;