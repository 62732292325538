import Acceder from "./Acceder";

function Demarrage() {
    return ( 
        <div id="demarrage">
            <Acceder />
        </div>
     );
}

export default Demarrage;