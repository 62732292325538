/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
import PouchDB from 'pouchdb';
import PouchFind from 'pouchdb-find';

(function(){
    var taskConfigClasse = function(){
        /*****************************************************
        ENABLING POUCHDB WITHOUT SYNC TO REMOTE COUCHDB
        ******************************************************/
        //SET UP POUCHDB AND CREATE THE DATABASE 
        const db = new PouchDB('suclu');
        PouchDB.plugin(PouchFind);

        /***************
        crudclasseBtn
        *****************/
        /**nav previous page button*/
        document.getElementById('prevcrudclasseBtn_1').addEventListener('click', prevcrudclasse1BtnChange);
        async function prevcrudclasse1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'block';
            document.getElementById('configurer').style.display = 'block';
            document.getElementById('classe').style.display = 'none';
            document.getElementById('crudclasse').style.display = 'none';
            titre.innerHTML = 'Configuration App'; 
        }

        /***************************
        addclasseBtn
        *****************************/
        var niveauClasse = ''; //global to assign classe
        document.getElementById('addclasseBtn').addEventListener('click', addclasseBtnChange);
        async function addclasseBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutInscritStr = 'Oui';
            var cycleStr = '';

            var docsCheck = [];

            //display select box based on cylce scolaire
            try {
                let result = await db.createIndex({
                    index: {
                        fields: ['inscritstatut', 'inscritcode']
                    }
                });
                result = await db.find({
                    selector: {
                        inscritstatut: statutInscritStr,
                        inscritcode: userCodeStr
                    }
                });
                docsCheck  = result.docs;
                var resultatCheck = docsCheck.length;
                if(resultatCheck === 0){
                    alert("Votre Compte Gratuit n'existe pas. Créer");
                }else{
                    for(let i=0; i<docsCheck.length; i++){
                        cycleStr = docsCheck[i].inscritcycle;
                    }
                    if(cycleStr === 'premier'){
                        niveauClasse = 'cycle1';
                        //display seladdclassepremier
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'block';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('classe').style.display = 'block';
                        document.getElementById('crudclasse').style.display = 'none';
                        document.getElementById('addclasse').style.display = 'block';
                        document.getElementById('seladdclassepremier').style.display = 'block';
                        document.getElementById('seladdclassesecond').style.display = 'none';
                        document.getElementById('seladdclassetechnique').style.display = 'none';
                        document.getElementById('editclasse').style.display = 'none';
                        document.getElementById('deleteclasse').style.display = 'none';
                        document.getElementById('searchclasse').style.display = 'none';
                        titre.innerHTML = 'Enregistrer Classe';
                    }else if(cycleStr === 'second'){
                        niveauClasse = 'cycle2';
                        //display seladdclassesecond
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'block';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('classe').style.display = 'block';
                        document.getElementById('crudclasse').style.display = 'none';
                        document.getElementById('addclasse').style.display = 'block';
                        document.getElementById('seladdclassepremier').style.display = 'none';
                        document.getElementById('seladdclassesecond').style.display = 'block';
                        document.getElementById('seladdclassetechnique').style.display = 'none';
                        document.getElementById('editclasse').style.display = 'none';
                        document.getElementById('deleteclasse').style.display = 'none';
                        document.getElementById('searchclasse').style.display = 'none';
                        titre.innerHTML = 'Enregistrer Classe';
                    }else{
                        niveauClasse = 'cycle3';
                        //display seladdclassetechnique
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'block';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('classe').style.display = 'block';
                        document.getElementById('crudclasse').style.display = 'none';
                        document.getElementById('addclasse').style.display = 'block';
                        document.getElementById('seladdclassepremier').style.display = 'none';
                        document.getElementById('seladdclassesecond').style.display = 'none';
                        document.getElementById('seladdclassetechnique').style.display = 'block';
                        document.getElementById('editclasse').style.display = 'none';
                        document.getElementById('deleteclasse').style.display = 'none';
                        document.getElementById('searchclasse').style.display = 'none';
                        titre.innerHTML = 'Enregistrer Classe';
                    }
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        /**nav previous page button*/
        document.getElementById('prevaddclasseBtn_1').addEventListener('click', prevaddclasse1BtnChange);
        async function prevaddclasse1BtnChange(e){
            e.preventDefault();
            
            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'block';
            document.getElementById('configurer').style.display = 'block';
            document.getElementById('classe').style.display = 'none';
            titre.innerHTML = 'Configuration App';
        }
        /***************************
        enregistrerclasseBtn
        *****************************/
        document.getElementById('enregistrerclasseBtn').addEventListener('click', enregistrerclasseBtnChange);
        async function enregistrerclasseBtnChange(e){
            e.preventDefault();

            var niveauClasseStr = niveauClasse;

            var usercodeStr = document.getElementById('userCode').innerText;
            if(niveauClasseStr === 'cycle1'){
                var seladdclasseStr = document.getElementById('seladdclasse1').value;
            }else if(niveauClasseStr === 'cycle2'){
                var seladdclasseStr = document.getElementById('seladdclasse2').value;
            }else{
                var seladdclasseStr = document.getElementById('seladdclasse3').value;
            }
            var addnumeroclasseStr = document.getElementById('addnumeroclasse').value;
            var addprofprincipalclasseStr = document.getElementById('addprofprincipalclasse').value;
            var datejourStr = getCurrentDate();
            var addstatutclasseStr = 'Oui';

            var docsClasse = [];

            if(!usercodeStr){
                alert("Identifiez-vous avant de continuer");
            }else if(!seladdclasseStr){
                alert("Sélectionner votre classe");
            }else if(!addnumeroclasseStr){
                alert("Entrer Numéro Classe");
            }else if(!addprofprincipalclasseStr){
                alert("Entrer Nom Prof Principal");
            }else{
                //check if classe has already been registered
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: ['classestatut', 'inscritcode']
                        }
                    });
                    result = await db.find({
                        selector: {
                            classestatut: addstatutclasseStr,
                            inscritcode: usercodeStr
                        }
                    });
                    docsClasse  = result.docs;
                    var resultatClasse = docsClasse.length;
                    if(resultatClasse === 0){
                        //Create the document _id and _rev.
                        var now = Date.now();
                        var id = 'classe_' + now;
                        // Add the document to the database.
                        var response = await db.put({
                            _id: id,
                            inscritcode: usercodeStr,
                            classenom: seladdclasseStr,
                            classenumero: addnumeroclasseStr,
                            classeprincipal: addprofprincipalclasseStr,
                            classedate: datejourStr,
                            classestatut: addstatutclasseStr,
                        });
                        // check if document inserted and synced
                        var doc = await db.get(response.id);
                        if(doc !== ''){
                            alert('Enregistrement Effectué avec succès.');
                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');

                            document.getElementById('demarrage').style.display = 'none';
                            document.getElementById('tableaubord').style.display = 'none';
                            document.getElementById('dashboard').style.display = 'none';
                            document.getElementById('configurations').style.display = 'block';
                            document.getElementById('configurer').style.display = 'block';
                            document.getElementById('classe').style.display = 'none';
                            titre.innerHTML = 'Configuration App';
                        }else{
                            alert('Echec Enregistrement. Reprendre');
                        }
                    }else{
                        alert("Classe déjà enregistré");
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }

        /***************************
        editclasseBtn
        *****************************/
        document.getElementById('editclasseBtn').addEventListener('click', editclasseBtnChange);
        async function editclasseBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutClasseStr = 'Oui';
            var docsEditClasse = [];

            var statutInscritStr = 'Oui';
            var cycleStr = '';

            var docsCheck = [];

            //initialise variables
            var classeIdStr,classeNomStr,classeNumeroStr,classePrincipalStr,classeDateStr,
            classeStatutStr = '';
            var titre = document.getElementById('titre');
            try {
                let result = await db.createIndex({
                    index: {
                        fields: ['classestatut', 'inscritcode']
                    }
                });
                result = await db.find({
                    selector: {
                        classestatut: statutClasseStr,
                        inscritcode: userCodeStr
                    }
                });
                docsEditClasse  = result.docs;
                var resultatEditClasse = docsEditClasse.length;
                if(resultatEditClasse === 0){
                    alert("Classe pas enregistrée");
                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'block';
                    document.getElementById('configurer').style.display = 'block';
                    document.getElementById('classe').style.display = 'none';
                    titre.innerHTML = 'Configuration App';
                }else{
                    let resultInscrit = await db.createIndex({
                        index: {
                            fields: ['inscritstatut', 'inscritcode']
                        }
                    });
                    resultInscrit = await db.find({
                        selector: {
                            inscritstatut: statutInscritStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsCheck  = resultInscrit.docs;
                    var resultatCheck = docsCheck.length;
                    if(resultatCheck === 0){
                        alert("Votre Compte Gratuit n'existe pas. Créer");
                    }else{
                        for(let i=0; i<docsCheck.length; i++){
                            cycleStr = docsCheck[i].inscritcycle;
                        }
                        if(cycleStr === 'premier'){
                            niveauClasse = 'cycle1';
                            //display seleditclassepremier
                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');

                            document.getElementById('demarrage').style.display = 'none';
                            document.getElementById('tableaubord').style.display = 'none';
                            document.getElementById('dashboard').style.display = 'none';
                            document.getElementById('configurations').style.display = 'block';
                            document.getElementById('configurer').style.display = 'none';
                            document.getElementById('classe').style.display = 'block';
                            document.getElementById('crudclasse').style.display = 'none';
                            document.getElementById('addclasse').style.display = 'none';
                            document.getElementById('seleditclassepremier').style.display = 'block';
                            document.getElementById('seleditclassesecond').style.display = 'none';
                            document.getElementById('seleditclassetechnique').style.display = 'none';
                            document.getElementById('editclasse').style.display = 'block';
                            document.getElementById('deleteclasse').style.display = 'none';
                            document.getElementById('searchclasse').style.display = 'none';
                            titre.innerHTML = 'Modifier Classe';
                        }else if(cycleStr === 'second'){
                            niveauClasse = 'cycle2';
                            //display seleditclassesecond
                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');

                            document.getElementById('demarrage').style.display = 'none';
                            document.getElementById('tableaubord').style.display = 'none';
                            document.getElementById('dashboard').style.display = 'none';
                            document.getElementById('configurations').style.display = 'block';
                            document.getElementById('configurer').style.display = 'none';
                            document.getElementById('classe').style.display = 'block';
                            document.getElementById('crudclasse').style.display = 'none';
                            document.getElementById('addclasse').style.display = 'none';
                            document.getElementById('seleditclassepremier').style.display = 'none';
                            document.getElementById('seleditclassesecond').style.display = 'block';
                            document.getElementById('seleditclassetechnique').style.display = 'none';
                            document.getElementById('editclasse').style.display = 'block';
                            document.getElementById('deleteclasse').style.display = 'none';
                            document.getElementById('searchclasse').style.display = 'none';
                            titre.innerHTML = 'Modifier Classe';
                        }else{
                            niveauClasse = 'cycle3';
                            //display seleditclassetechnique
                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');

                            document.getElementById('demarrage').style.display = 'none';
                            document.getElementById('tableaubord').style.display = 'none';
                            document.getElementById('dashboard').style.display = 'none';
                            document.getElementById('configurations').style.display = 'block';
                            document.getElementById('configurer').style.display = 'none';
                            document.getElementById('classe').style.display = 'block';
                            document.getElementById('crudclasse').style.display = 'none';
                            document.getElementById('addclasse').style.display = 'none';
                            document.getElementById('seleditclassepremier').style.display = 'none';
                            document.getElementById('seleditclassesecond').style.display = 'none';
                            document.getElementById('seleditclassetechnique').style.display = 'block';
                            document.getElementById('editclasse').style.display = 'block';
                            document.getElementById('deleteclasse').style.display = 'none';
                            document.getElementById('searchclasse').style.display = 'none';
                            titre.innerHTML = 'Modifier Classe';
                        }

                        //retrieve classe data
                        for(let i=0; i<docsEditClasse.length; i++){
                            classeIdStr = docsEditClasse[i]._id;
                            classeNomStr = docsEditClasse[i].classenom;
                            classeNumeroStr = docsEditClasse[i].classenumero;
                            classePrincipalStr = docsEditClasse[i].classeprincipal;
                            classeDateStr = docsEditClasse[i].classedate;
                            classeStatutStr = docsEditClasse[i].classestatut;
                        }
                        document.getElementById('editidclasse').value = classeIdStr;
                        document.getElementById('editnomclasse').value = classeNomStr;
                        document.getElementById('editnumeroclasse').value = classeNumeroStr;
                        document.getElementById('editprofprincipalclasse').value = classePrincipalStr;
                        document.getElementById('editdateclasse').value = classeDateStr;
                        document.getElementById('editstatutclasse').value = classeStatutStr;
                    }
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        /**nav previous page button*/
        document.getElementById('preveditclasseBtn_1').addEventListener('click', preveditclasse1BtnChange);
        async function preveditclasse1BtnChange(e){
            e.preventDefault();
            
            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'block';
            document.getElementById('configurer').style.display = 'block';
            document.getElementById('classe').style.display = 'none';
            titre.innerHTML = 'Configuration App';
        }
        /***************************
        modifierclasseBtn
        *****************************/
        document.getElementById('modifierclasseBtn').addEventListener('click', modifierclasseBtnChange);
        async function modifierclasseBtnChange(e){
            e.preventDefault();

            var usercodeStr = document.getElementById('userCode').innerText;
            var classeIdStr = document.getElementById('editidclasse').value;
            var classeNomStr = document.getElementById('editnomclasse').value;
            var classeNumeroStr = document.getElementById('editnumeroclasse').value;
            var classePrincipalStr = document.getElementById('editprofprincipalclasse').value;
            var classeDateStr = document.getElementById('editdateclasse').value;
            var classeStatutStr = document.getElementById('editstatutclasse').value;

            if(!usercodeStr){
                alert("Identifiez-vous avant de continuer");
            }else if(!classeNumeroStr){
                alert("Entrer Numéro Classe");
            }else if(!classePrincipalStr){
                alert("Entrer Nom Prof Principal");
            }else{
                //check if select box empty
                var niveauClasseStr = niveauClasse;
                var classeStr = '';
                if(niveauClasseStr === 'cycle1'){
                    var selEditClasse1Str = document.getElementById('seleditclasse1').value;
                    if(!selEditClasse1Str){
                        classeStr = classeNomStr;
                    }else{
                        classeStr = selEditClasse1Str;
                    }
                }else if(niveauClasseStr === 'cycle2'){
                    var seleditclasse2Str = document.getElementById('seleditclasse2').value;
                    if(!seleditclasse2Str){
                        classeStr = classeNomStr;
                    }else{
                        classeStr = seleditclasse2Str;
                    }
                }else{
                    var seleditclasse3Str = document.getElementById('seleditclasse3').value;
                    if(!seleditclasse3Str){
                        classeStr = classeNomStr;
                    }else{
                        classeStr = seleditclasse3Str;
                    }
                }

                try {
                    // Retrieve the document to get the latest revision
                    var id = classeIdStr;
                    var doc = await db.get(id);
                    // Make changes
                    doc.inscritcode = usercodeStr;
                    doc.classenom = classeStr;
                    doc.classenumero = classeNumeroStr;
                    doc.classeprincipal = classePrincipalStr;
                    doc.classedate = classeDateStr;
                    doc.classestatut = classeStatutStr;
                    // Put the document, saving it as a new revision
                    var response = await db.put(doc);
                    // Get the document we change and update the UI.
                    var docEdit = await db.get(response.id);
                    if(docEdit !== ''){
                        alert("Classe Modifiée avec Succès");
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'block';
                        document.getElementById('configurer').style.display = 'block';
                        document.getElementById('classe').style.display = 'none';
                        titre.innerHTML = 'Configuration App';
                    }else{
                        alert("Erreur Modification. Reprendre");
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'block';
                        document.getElementById('configurer').style.display = 'block';
                        document.getElementById('classe').style.display = 'none';
                        titre.innerHTML = 'Configuration App';
                    }
                } catch (error) {
                   alert('Erreur Exécution'); 
                }
            }
        }

        /***************************
        deleteclasseBtn
        *****************************/
        document.getElementById('deleteclasseBtn').addEventListener('click', deleteclasseBtnChange);
        async function deleteclasseBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutClasseStr = 'Oui';
            var docsDeleteClasse = [];
            //initialise variables
            var classeIdStr,classeNomStr,classeNumeroStr,classePrincipalStr = '';
            //var titre = document.getElementById('titre');
            try {
                let result = await db.createIndex({
                    index: {
                        fields: ['classestatut', 'inscritcode']
                    }
                });
                result = await db.find({
                    selector: {
                        classestatut: statutClasseStr,
                        inscritcode: userCodeStr
                    }
                });
                docsDeleteClasse  = result.docs;
                var resultatDeleteClasse = docsDeleteClasse.length;
                if(resultatDeleteClasse === 0){
                    alert("Classe pas enregistré");
                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'block';
                    document.getElementById('configurer').style.display = 'block';
                    document.getElementById('classe').style.display = 'none';
                    titre.innerHTML = 'Configuration App';
                }else{
                    //retrieve classe data
                    for(let i=0; i<docsDeleteClasse.length; i++){
                        classeIdStr = docsDeleteClasse[i]._id;
                        classeNomStr = docsDeleteClasse[i].classenom;
                        classeNumeroStr = docsDeleteClasse[i].classenumero;
                        classePrincipalStr = docsDeleteClasse[i].classeprincipal;
                    }
                    document.getElementById('deleteidclasse').value = classeIdStr;
                    document.getElementById('deletenomclasse').value = classeNomStr;
                    document.getElementById('deletenumeroclasse').value = classeNumeroStr;
                    document.getElementById('deleteprofprincipalclasse').value = classePrincipalStr;

                    //display seladdclassepremier
                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'block';
                    document.getElementById('configurer').style.display = 'none';
                    document.getElementById('classe').style.display = 'block';
                    document.getElementById('crudclasse').style.display = 'none';
                    document.getElementById('addclasse').style.display = 'none';
                    document.getElementById('editclasse').style.display = 'none';
                    document.getElementById('deleteclasse').style.display = 'block';
                    document.getElementById('searchclasse').style.display = 'none';
                    titre.innerHTML = 'Supprimer Classe';
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        /**nav previous page button*/
        document.getElementById('prevdeleteclasseBtn_1').addEventListener('click', prevdeleteclasse1BtnChange);
        async function prevdeleteclasse1BtnChange(e){
            e.preventDefault();
            
            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'block';
            document.getElementById('configurer').style.display = 'block';
            document.getElementById('classe').style.display = 'none';
            titre.innerHTML = 'Configuration App';
        }
        /***************************
        supprimerclasseBtn
        *****************************/
        document.getElementById('supprimerclasseBtn').addEventListener('click', supprimerclasseBtnChange);
        async function supprimerclasseBtnChange(e){
            e.preventDefault();

            var divClearModal = document.getElementById('modaloutclasse');
            let divModal = document.createElement('div');
            divModal.innerHTML =
            `<div class="modal">
                <p>Êtes-vous sûr de Supprimer ?</p>
                <button class="btn btn--alt" id="ouiHandlerClasseBtn">Oui</button>
                <button class="btn" id="nonHandlerClasseBtn">Non</button>
            </div>`;
            divClearModal.appendChild(divModal);

            var divClearBackdrop = document.getElementById('backdropoutclasse');
            let divBack = document.createElement('div');
            divBack.innerHTML =
            `<div class="backdrop" id="backdropHandlerClasseBtn"></div>`;
            divClearBackdrop.appendChild(divBack);

            document.getElementById('ouiHandlerClasseBtn').addEventListener('click', ouiHandlerClasseBtnChange);
            async function ouiHandlerClasseBtnChange(e){
                e.preventDefault();

                var deleteidclasseStr = document.getElementById('deleteidclasse').value;

                try {
                    //Delete
                    var doc = await db.get(deleteidclasseStr);
                    var response = await db.remove(doc);
                    if(response.ok){
                        alert('Classe Supprimée avec Succès');
                        var divClearModal = document.getElementById('modaloutclasse');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutclasse');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'block';
                        document.getElementById('configurer').style.display = 'block';
                        document.getElementById('classe').style.display = 'none';
                        titre.innerHTML = 'Configuration App';
                    }else{
                        alert("Impossible Supprimer Classe");
                        var divClearModal = document.getElementById('modaloutclasse');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutclasse');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'block';
                        document.getElementById('configurer').style.display = 'block';
                        document.getElementById('classe').style.display = 'none';
                        titre.innerHTML = 'Configuration App';
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
            document.getElementById('nonHandlerClasseBtn').addEventListener('click', nonHandlerClasseBtnChange);
            async function nonHandlerClasseBtnChange(e){
                e.preventDefault();

                var divClearModal = document.getElementById('modaloutclasse');
                while(divClearModal.hasChildNodes()) {
                    divClearModal.removeChild(divClearModal.childNodes[0]);
                }

                var divClearBackdrop = document.getElementById('backdropoutclasse');
                while(divClearBackdrop.hasChildNodes()) {
                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                }
            }
            document.getElementById('backdropHandlerClasseBtn').addEventListener('click', backdropHandlerClasseBtnChange);
            async function backdropHandlerClasseBtnChange(e){
                e.preventDefault();

                var divClearModal = document.getElementById('modaloutclasse');
                while(divClearModal.hasChildNodes()) {
                    divClearModal.removeChild(divClearModal.childNodes[0]);
                }

                var divClearBackdrop = document.getElementById('backdropoutclasse');
                while(divClearBackdrop.hasChildNodes()) {
                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                }
            }
        }

        /***************************
        searchclasseBtn
        *****************************/
        document.getElementById('searchclasseBtn').addEventListener('click', searchclasseBtnChange);
        async function searchclasseBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutClasseStr = 'Oui';
            var docsSearchClasse = [];
            //initialise variables
            var classeNomStr,classeNumeroStr,classePrincipalStr = '';
            try {
                let result = await db.createIndex({
                    index: {
                        fields: ['classestatut', 'inscritcode']
                    }
                });
                result = await db.find({
                    selector: {
                        classestatut: statutClasseStr,
                        inscritcode: userCodeStr
                    }
                });
                docsSearchClasse  = result.docs;
                var resultatSearchClasse = docsSearchClasse.length;
                if(resultatSearchClasse === 0){
                    alert("Classe pas enregistré");
                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'block';
                    document.getElementById('configurer').style.display = 'block';
                    document.getElementById('classe').style.display = 'none';
                    titre.innerHTML = 'Configuration App';
                }else{
                    //retrieve classe data
                    for(let i=0; i<docsSearchClasse.length; i++){
                        classeNomStr = docsSearchClasse[i].classenom;
                        classeNumeroStr = docsSearchClasse[i].classenumero;
                        classePrincipalStr = docsSearchClasse[i].classeprincipal;
                    }
                    document.getElementById('searchnomclasse').value = classeNomStr;
                    document.getElementById('searchnumeroclasse').value = classeNumeroStr;
                    document.getElementById('searchprofprincipalclasse').value = classePrincipalStr;

                    //display seladdclassepremier
                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'block';
                    document.getElementById('configurer').style.display = 'none';
                    document.getElementById('classe').style.display = 'block';
                    document.getElementById('crudclasse').style.display = 'none';
                    document.getElementById('addclasse').style.display = 'none';
                    document.getElementById('editclasse').style.display = 'none';
                    document.getElementById('deleteclasse').style.display = 'none';
                    document.getElementById('searchclasse').style.display = 'block';
                    titre.innerHTML = 'Supprimer Classe';
                }
            } catch (error) {
                alert('Erreur Exécution'); 
            }
        }
        /**nav previous page button*/
        document.getElementById('prevsearchclasseBtn_1').addEventListener('click', prevsearchclasse1BtnChange);
        async function prevsearchclasse1BtnChange(e){
            e.preventDefault();
            
            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'block';
            document.getElementById('configurer').style.display = 'block';
            document.getElementById('classe').style.display = 'none';
            titre.innerHTML = 'Configuration App';
        }
    };

    /****************
    HELPER FUNCTION
    *****************/
    /**1: Get Current Date in JavaScript */
    function getCurrentDate(){
        // Date object
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1;
        var yyyy = today.getFullYear();
        if(dd<10){
            dd='0'+dd;
        }
        if(mm<10){
            mm='0'+mm;
        }
        // Current Date
        var date = yyyy+'-'+mm+'-'+dd;
        return date;
    }

    window.addEventListener('load', () =>{
        new taskConfigClasse();
    })
}) ();