import '../configuration.css';
import './pouchconfigmatiere';
import TextInput from '../../components/TextInput';
import NumberInput from '../../components/NumberInput';
import AjouterButton from '../../components/AjouterButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function AddMatiere() {
    return ( 
        <div id="addmatiere">
            <KeyboardBackspace style={prevArrows} id="prevaddmatiereBtn_1" />
            <br/><br/>
            <form>
                <div id="formaddmatiere" style={scroller}>
                    <br/>
                    <TextInput idinput ="addnommatiere" texte="text" place="Entrer Nom Matière" /> 
                    <br/>
                    <NumberInput idinput ="addcoefficientmatiere" number="number" place="Entrer Coefficient Matière" />
                    <br/>
                    <TextInput idinput ="addprofesseurmatiere" texte="text" place="Entrer Nom Professeur" />
                    <br/>
                    <AjouterButton 
                    idajouterbutton="enregistrermatiereBtn"
                    ajouter="Enregistrer"
                    />
                    <br/><br/>
                </div>
            </form>
        </div>
    );
}

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default AddMatiere;