import ExoTache from "./ExoTache";

function Exercice() {
    return ( 
        <div id="exercice">
            <ExoTache />
        </div>
    );
}

export default Exercice;