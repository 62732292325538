/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import PouchDB from 'pouchdb';
import PouchFind from 'pouchdb-find';

(function(){
    var taskEmploiDuTemps = function(){
        /*****************************************************
        ENABLING POUCHDB WITHOUT SYNC TO REMOTE COUCHDB
        ******************************************************/
        //SET UP POUCHDB AND CREATE THE DATABASE 
        const db = new PouchDB('suclu');
        PouchDB.plugin(PouchFind);

        /***************
        crudemploidutempsBtn
        *****************/
        /**nav previous page button*/
        document.getElementById('prevcrudemploidutempsBtn_1').addEventListener('click', prevcrudemploidutemps1BtnChange);
        async function prevcrudemploidutemps1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'block';
            document.getElementById('dashboard').style.display = 'block';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            titre.innerHTML = 'Tableau de Bord';
        }

        /***************************
        addemploidutempsBtn
        *****************************/
        document.getElementById('addemploidutempsBtn').addEventListener('click', addemploidutempsBtnChange);
        async function addemploidutempsBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutSemaineStr = 'Oui';
            var docsAddSemaine = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: ['semainestatut', 'inscritcode']
                    }
                });
                result = await db.find({
                    selector: {
                        semainestatut: statutSemaineStr,
                        inscritcode: userCodeStr
                    }
                });
                docsAddSemaine  = result.docs;
                var resultatAddSemaine = docsAddSemaine.length;
                if(resultatAddSemaine === 0){
                    alert("Jour Semaine pas encore enregistré");
                }else{
                    var divClear;
                    var divModifier;
                    divClear = document.getElementById('addjouremploidutemps');
                    while(divClear.hasChildNodes()) {
                        divClear.removeChild(divClear.childNodes[0]);
                    }
                    divModifier = document.getElementById('addjouremploidutemps');
                    //Create and append select list
                    var selectList = document.createElement("select");
                    selectList.id = "seladdjouremploidutemps";
                    divModifier.appendChild(selectList);
                    //Create and append the options
                    var option_null = document.createElement("option");
                    option_null.value = '';
                    option_null.text = 'Sélectionner Jour';
                    selectList.appendChild(option_null);
                    //Create and append the options
                    for (var i = 0; i<docsAddSemaine.length; i++){
                        var option = document.createElement("option");
                        option.value = docsAddSemaine[i].semainejour;
                        option.text = docsAddSemaine[i].semainejour;
                        selectList.appendChild(option);
                    }

                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'none';
                    document.getElementById('configurer').style.display = 'none';
                    document.getElementById('emploidutemps').style.display = 'block';
                    document.getElementById('crudemploidutemps').style.display = 'none';
                    document.getElementById('addemploidutemps').style.display = 'block';
                    document.getElementById('editemploidutemps').style.display = 'none';
                    document.getElementById('deleteemploidutemps').style.display = 'none';
                    document.getElementById('searchemploidutemps').style.display = 'none';
                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('savematiere').style.display = 'none';
                    document.getElementById('saveheure').style.display = 'none';
                    document.getElementById('saveemploidutemps').style.display = 'none';
                    titre.innerHTML = 'Enregistrer Emploi du Temps'; 

                    /***************************
                    seladdjouremploidutemps
                    *****************************/
                    document.getElementById('seladdjouremploidutemps').addEventListener('change', seladdjouremploidutempsChange);
                    async function seladdjouremploidutempsChange(e){
                        e.preventDefault();

                        var addjourStr = document.getElementById('seladdjouremploidutemps').value;
                        if(!addjourStr){
                            alert('Sélectionner un Jour avant de continuer');
                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('savematiere').style.display = 'none';
                            document.getElementById('saveheure').style.display = 'none';
                            document.getElementById('saveemploidutemps').style.display = 'none';
                            titre.innerHTML = 'Enregistrer Emploi du Temps'; 
                        }else{
                            document.getElementById('addjour').value = addjourStr;

                            var userCodeStr = document.getElementById('userCode').innerText;
                            var statutMatiereStr = 'Oui';
                            var docsAddMatiere = [];
                            
                            try {
                                let result = await db.createIndex({
                                    index: {
                                        fields: ['matierestatut', 'inscritcode']
                                    }
                                });
                                result = await db.find({
                                    selector: {
                                        matierestatut: statutMatiereStr,
                                        inscritcode: userCodeStr
                                    }
                                });
                                docsAddMatiere  = result.docs;
                                var resultatAddMatiere = docsAddMatiere.length;
                                if(resultatAddMatiere === 0){
                                    alert("matières Classe pas encore enregistrées");
                                }else{
                                    var divClear;
                                    var divModifier;
                                    divClear = document.getElementById('addmatiereemploidutemps');
                                    while(divClear.hasChildNodes()) {
                                        divClear.removeChild(divClear.childNodes[0]);
                                    }
                                    divModifier = document.getElementById('addmatiereemploidutemps');
                                    //Create and append select list
                                    var selectList = document.createElement("select");
                                    selectList.id = "seladdmatiereemploidutemps";
                                    divModifier.appendChild(selectList);
                                    //Create and append the options
                                    var option_null = document.createElement("option");
                                    option_null.value = '';
                                    option_null.text = 'Sélectionner Matière';
                                    selectList.appendChild(option_null);
                                    for (var i = 0; i <docsAddMatiere.length; i++){
                                        var option = document.createElement("option");
                                        option.value = docsAddMatiere[i].matierenom;
                                        option.text = docsAddMatiere[i].matierenom;
                                        selectList.appendChild(option);
                                    }
    
                                    document.getElementById('titre').style.display = 'block';
                                    var titre = document.getElementById('titre');
                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('savematiere').style.display = 'block';
                                    document.getElementById('saveheure').style.display = 'none';
                                    document.getElementById('saveemploidutemps').style.display = 'none';
                                    titre.innerHTML = 'Enregistrer Emploi du Temps'; 
    
                                    /***************************
                                    seladdmatiereemploidutemps
                                    *****************************/
                                    document.getElementById('seladdmatiereemploidutemps').addEventListener('change', seladdmatiereemploidutempsChange);
                                    async function seladdmatiereemploidutempsChange(e){
                                        e.preventDefault();
    
                                        var addmatiereStr = document.getElementById('seladdmatiereemploidutemps').value;
                                        if(!addmatiereStr){
                                            alert('Sélectionner une Matière avant de continuer');
                                            document.getElementById('titre').style.display = 'block';
                                            var titre = document.getElementById('titre');
                                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                            document.getElementById('savematiere').style.display = 'block';
                                            document.getElementById('saveheure').style.display = 'none';
                                            document.getElementById('saveemploidutemps').style.display = 'none';
                                            titre.innerHTML = 'Enregistrer Emploi du Temps'; 
                                        }else{
                                            document.getElementById('addmatiere').value = addmatiereStr;
    
                                            document.getElementById('titre').style.display = 'block';
                                            var titre = document.getElementById('titre');
                                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                            document.getElementById('savematiere').style.display = 'block';
                                            document.getElementById('saveheure').style.display = 'block';
                                            document.getElementById('saveemploidutemps').style.display = 'block';
                                            titre.innerHTML = 'Enregistrer Emploi du Temps'; 
                                        }
                                    }
                                }
                            } catch (error) {
                                alert('Erreur Exécution Matières Classe');
                            }
                        }
                    }
                }
            } catch (error) {
                alert('Erreur Exécution Jours Semaine Scolaire');
            }
        }
        /**nav previous page button*/
        document.getElementById('prevaddemploidutempsBtn_1').addEventListener('click', prevaddemploidutemps1BtnChange);
        async function prevaddemploidutemps1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'block';
            document.getElementById('crudemploidutemps').style.display = 'block';
            document.getElementById('addemploidutemps').style.display = 'none';
            document.getElementById('editemploidutemps').style.display = 'none';
            document.getElementById('deleteemploidutemps').style.display = 'none';
            document.getElementById('searchemploidutemps').style.display = 'none';
            titre.innerHTML = 'Emploi du Temps'; 
        }
        /***************************
        enregistrersemaineBtn
        *****************************/
        document.getElementById('enregistreremploidutempsBtn').addEventListener('click', enregistreremploidutempsBtnChange);
        async function enregistreremploidutempsBtnChange(e){
            e.preventDefault();

            var usercodeStr = document.getElementById('userCode').innerText;
            var seladdjouremploiStr = document.getElementById('addjour').value;
            var seladdmatiereemploiStr = document.getElementById('addmatiere').value;
            var addheureemploiStr = document.getElementById('addheureemploidutemps').value;
            var datejourStr = getCurrentDate();
            var addstatutemploiStr = 'Oui';

            var docsEmploi = [];

            if(!usercodeStr){
                alert("Identifiez-vous avant de continuer");
            }else if(!seladdjouremploiStr){
                alert("Sélectionner Jour Semaine");
            }else if(!seladdmatiereemploiStr){
                alert("Sélectionner Matière");
            }else if(!addheureemploiStr){
                alert("Saisir Heure Cours");
            }else{
                //check if this emploi du temps has already been registered
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: [
                                'emploistatut', 
                                'inscritcode', 
                                'emploijour', 
                                'emploiheure'
                            ]
                        }
                    });
                    result = await db.find({
                        selector: {
                            emploistatut: addstatutemploiStr,
                            inscritcode: usercodeStr,
                            emploijour: seladdjouremploiStr,
                            emploiheure: addheureemploiStr
                        }
                    });
                    docsEmploi  = result.docs;
                    var resultatEmploi = docsEmploi.length;
                    if(resultatEmploi === 0){
                        //Create the document _id and _rev.
                        var now = Date.now();
                        var id = 'emploi_' + now;
                        // Add the document to the database.
                        var response = await db.put({
                            _id: id,
                            inscritcode: usercodeStr,
                            emploijour: seladdjouremploiStr,
                            emploimatiere: seladdmatiereemploiStr,
                            emploiheure: addheureemploiStr,
                            emploidate: datejourStr,
                            emploistatut: addstatutemploiStr,
                        });
                        // check if document inserted and synced
                        var doc = await db.get(response.id);
                        if(doc !== ''){
                            alert('Enregistré. Au Suivant');
                        }else{
                            alert('Echec Enregistrement Emploi du Temps. Reprendre');
                        }
                    }else{
                        alert("Cet Emploi du Temps est déjà enregistré. Au Suivant");
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }

        /***************************
        editemploiBtn
        *****************************/
        document.getElementById('editemploidutempsBtn').addEventListener('click', editemploidutempsBtnChange);
        async function editemploidutempsBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutEmploiStr = 'Oui';
            var docsEditEmploi = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: ['semainestatut', 'inscritcode']
                    }
                });
                result = await db.find({
                    selector: {
                        semainestatut: statutEmploiStr,
                        inscritcode: userCodeStr
                    }
                });
                docsEditEmploi  = result.docs;
                var resultatEditEmploi = docsEditEmploi.length;
                if(resultatEditEmploi === 0){
                    alert("Emploi du Temps pas encore enregistré");
                }else{
                    var divClear;
                    var divModifier;
                    divClear = document.getElementById('editjouremploidutemps');
                    while(divClear.hasChildNodes()) {
                        divClear.removeChild(divClear.childNodes[0]);
                    }
                    divModifier = document.getElementById('editjouremploidutemps');
                    //Create and append select list
                    var selectList = document.createElement("select");
                    selectList.id = "seleditjouremploi";
                    divModifier.appendChild(selectList);
                    //Create and append the options
                    var option_null = document.createElement("option");
                    option_null.value = '';
                    option_null.text = 'Sélectionner Jour';
                    selectList.appendChild(option_null);
                    //Create and append the options
                    for (var i = 0; i<docsEditEmploi.length; i++){
                        var option = document.createElement("option");
                        option.value = docsEditEmploi[i].semainejour;
                        option.text = docsEditEmploi[i].semainejour;
                        selectList.appendChild(option);
                    }

                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'none';
                    document.getElementById('configurer').style.display = 'none';
                    document.getElementById('emploidutemps').style.display = 'block';
                    document.getElementById('crudemploidutemps').style.display = 'none';
                    document.getElementById('addemploidutemps').style.display = 'none';
                    document.getElementById('editemploidutemps').style.display = 'block';
                    document.getElementById('deleteemploidutemps').style.display = 'none';
                    document.getElementById('searchemploidutemps').style.display = 'none';
                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('changematieres').style.display = 'none';
                    document.getElementById('changeheure').style.display = 'none';
                    document.getElementById('changeemploidutemps').style.display = 'none';
                    titre.innerHTML = 'Modifier Emploi du Temps'; 

                    /***************************
                    seleditjouremploi
                    *****************************/
                    document.getElementById('seleditjouremploi').addEventListener('change', seleditjouremploiChange);
                    async function seleditjouremploiChange(e){
                        e.preventDefault();

                        var userCodeStr = document.getElementById('userCode').innerText;

                        var emploiIdStr,inscritCodeStr,emploiJourStr,emploiMatiereStr,emploiHeureStr,emploiDateStr,emploiStatutStr = '';

                        var editjouremploiStr = document.getElementById('seleditjouremploi').value;
                        if(!editjouremploiStr){
                            alert('Sélectionner un Jour avant de continuer');
                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('changematieres').style.display = 'none';
                            document.getElementById('changeheure').style.display = 'none';
                            document.getElementById('changeemploidutemps').style.display = 'none';
                            titre.innerHTML = 'Modifier Emploi du Temps'; 
                        }else{
                            document.getElementById('editjouremploi').value = editjouremploiStr;

                            var userCodeStr = document.getElementById('userCode').innerText;
                            var statutEmploiStr = 'Oui';
                            var docsEditMatiere = [];
                            
                            try {
                                let result = await db.createIndex({
                                    index: {
                                        fields: [
                                            'emploistatut', 
                                            'inscritcode', 
                                            'emploijour'
                                        ]
                                    }
                                });
                                result = await db.find({
                                    selector: {
                                        emploistatut: statutEmploiStr,
                                        inscritcode: userCodeStr,
                                        emploijour: editjouremploiStr
                                    }
                                });
                                docsEditMatiere  = result.docs;
                                var resultatEditMatiere = docsEditMatiere.length;
                                if(resultatEditMatiere === 0){
                                    alert("Emploi du Temps du "+editjouremploiStr+" non encore enregistré");
                                    document.getElementById('seleditjouremploi').value = '';
                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('changematieres').style.display = 'none';
                                    document.getElementById('changeheure').style.display = 'none';
                                    document.getElementById('changeemploidutemps').style.display = 'none'; 
                                }else{
                                    var divClear;
                                    var divModifier;
                                    divClear = document.getElementById('editmatiereemploidutemps');
                                    while(divClear.hasChildNodes()) {
                                        divClear.removeChild(divClear.childNodes[0]);
                                    }
                                    divModifier = document.getElementById('editmatiereemploidutemps');
                                    //Create and append select list
                                    var selectList = document.createElement("select");
                                    selectList.id = "seleditmatiereemploi";
                                    divModifier.appendChild(selectList);
                                    //Create and append the options
                                    var option_null = document.createElement("option");
                                    option_null.value = '';
                                    option_null.text = 'Sélectionner Matière';
                                    selectList.appendChild(option_null);
                                    for (var i = 0; i <docsEditMatiere.length; i++){
                                        var option = document.createElement("option");
                                        option.value = docsEditMatiere[i]._id;
                                        option.text = docsEditMatiere[i].emploimatiere;
                                        selectList.appendChild(option);
                                    }
    
                                    document.getElementById('titre').style.display = 'block';
                                    var titre = document.getElementById('titre');
                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('changematieres').style.display = 'block';
                                    document.getElementById('changeheure').style.display = 'none';
                                    document.getElementById('changeemploidutemps').style.display = 'none';
                                    titre.innerHTML = 'Modifier Emploi du Temps'; 
    
                                    /***************************
                                    seleditmatiereemploi
                                    *****************************/
                                    document.getElementById('seleditmatiereemploi').addEventListener('change', seleditmatiereemploiChange);
                                    async function seleditmatiereemploiChange(e){
                                        e.preventDefault();
    
                                        var editmatiereStr = document.getElementById('seleditmatiereemploi').value;
                                        if(!editmatiereStr){
                                            alert('Sélectionner une Matière avant de continuer');
                                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                            document.getElementById('changematieres').style.display = 'block';
                                            document.getElementById('changeheure').style.display = 'none';
                                            document.getElementById('changeemploidutemps').style.display = 'none'; 
                                        }else{
                                            var userCodeStr = document.getElementById('userCode').innerText;
                                            var statutEmploiStr = 'Oui';
                                            var docsEdit = [];
                                            try {
                                                let result = await db.createIndex({
                                                    index: {
                                                        fields: [
                                                            'emploistatut', 
                                                            'inscritcode', 
                                                            '_id'
                                                        ]
                                                    }
                                                });
                                                result = await db.find({
                                                    selector: {
                                                        emploistatut: statutEmploiStr,
                                                        inscritcode: userCodeStr,
                                                        _id: editmatiereStr
                                                    }
                                                });
                                                docsEdit  = result.docs;
                                                var resultatEdit = docsEdit.length;
                                                if(resultatEdit === 0){
                                                    document.getElementById('editidemploi').value = '';
                                                    document.getElementById('editcodeuseremploi').value = '';
                                                    document.getElementById('editjouremploi').value = '';
                                                    document.getElementById('editmatiereemploi').value = '';
                                                    document.getElementById('editheureemploi').value = '';
                                                    document.getElementById('editdateemploi').value = '';
                                                    document.getElementById('editstatutemploi').value = '';
                                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                                    document.getElementById('changematieres').style.display = 'block';
                                                    document.getElementById('changeheure').style.display = 'none';
                                                    document.getElementById('changeemploidutemps').style.display = 'none';
                                                }else{
                                                    for(let j=0; j<docsEdit.length; j++){
                                                        emploiIdStr = docsEdit[j]._id;
                                                        inscritCodeStr = docsEdit[j].inscritcode;
                                                        emploiJourStr = docsEdit[j].emploijour;
                                                        emploiMatiereStr = docsEdit[j].emploimatiere;
                                                        emploiHeureStr = docsEdit[j].emploiheure;
                                                        emploiDateStr = docsEdit[j].emploidate;
                                                        emploiStatutStr = docsEdit[j].emploistatut;
                                                    }

                                                    document.getElementById('editheureemploidutemps').value = emploiHeureStr;

                                                    document.getElementById('editidemploi').value = emploiIdStr;
                                                    document.getElementById('editcodeuseremploi').value = inscritCodeStr;
                                                    document.getElementById('editjouremploi').value = emploiJourStr;
                                                    document.getElementById('editmatiereemploi').value = emploiMatiereStr;
                                                    document.getElementById('editdateemploi').value = emploiDateStr;
                                                    document.getElementById('editstatutemploi').value = emploiStatutStr;

                                                    document.getElementById('titre').style.display = 'block';
                                                    var titre = document.getElementById('titre');
                                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                                    document.getElementById('changematieres').style.display = 'block';
                                                    document.getElementById('changeheure').style.display = 'block';
                                                    document.getElementById('changeemploidutemps').style.display = 'block';
                                                    titre.innerHTML = 'Modifier Emploi du Temps'; 
                                                }
                                            } catch (error) {
                                                alert('Erreur Exécution matières');
                                            }
                                        }
                                    }
                                }
                            } catch (error) {
                                alert('Erreur Exécution Matières Classe');
                            }
                        }
                    }
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        /**nav previous page button*/
        document.getElementById('preveditemploidutempsBtn_1').addEventListener('click', preveditemploidutemps1BtnChange);
        async function preveditemploidutemps1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'block';
            document.getElementById('crudemploidutemps').style.display = 'block';
            document.getElementById('addemploidutemps').style.display = 'none';
            document.getElementById('editemploidutemps').style.display = 'none';
            document.getElementById('deleteemploidutemps').style.display = 'none';
            document.getElementById('searchemploidutemps').style.display = 'none';
            titre.innerHTML = 'Emploi du Temps'; 
        }

        /***************************
        modifieremploidutempsBtn
        *****************************/
        document.getElementById('modifieremploidutempsBtn').addEventListener('click', modifieremploidutempsBtnChange);
        async function modifieremploidutempsBtnChange(e){
            e.preventDefault();

            var editidemploiStr = document.getElementById('editidemploi').value;
            var editcodeuseremploiStr = document.getElementById('editcodeuseremploi').value;
            var editjouremploiStr = document.getElementById('editjouremploi').value;
            var editmatiereemploiStr = document.getElementById('editmatiereemploi').value;
            var editheureemploidutempsStr = document.getElementById('editheureemploidutemps').value;
            var editdateemploiStr = document.getElementById('editdateemploi').value;
            var editstatutemploiStr = document.getElementById('editstatutemploi').value;

            if(!editcodeuseremploiStr){
                alert("Identifiez-vous avant de continuer");
            }else if(!editheureemploidutempsStr){
                alert("Entrer Heure Cours Matière");
            }else{
                try {
                    // Retrieve the document to get the latest revision
                    var id = editidemploiStr;
                    var doc = await db.get(id);
                    // Make changes
                    doc.inscritcode = editcodeuseremploiStr;
                    doc.emploijour = editjouremploiStr;
                    doc.emploimatiere = editmatiereemploiStr;
                    doc.emploiheure = editheureemploidutempsStr;
                    doc.emploidate = editdateemploiStr;
                    doc.emploistatut = editstatutemploiStr;
                    // Put the document, saving it as a new revision
                    var response = await db.put(doc);
                    // Get the document we change and update the UI.
                    var docEdit = await db.get(response.id);
                    if(docEdit !== ''){
                        alert("Emploi du temps Modifié avec Succès");
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'block';
                        document.getElementById('dashboard').style.display = 'block';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        titre.innerHTML = 'Tableau de Bord';
                    }else{
                        alert("Erreur Modification. Reprendre");
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'block';
                        document.getElementById('dashboard').style.display = 'block';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        titre.innerHTML = 'Tableau de Bord';
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }

        /***************************
        deleteemploidutempsBtn
        *****************************/
        document.getElementById('deleteemploidutempsBtn').addEventListener('click', deleteemploidutempsBtnChange);
        async function deleteemploidutempsBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutEmploiStr = 'Oui';
            var docsDeleteEmploi = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: ['semainestatut', 'inscritcode']
                    }
                });
                result = await db.find({
                    selector: {
                        semainestatut: statutEmploiStr,
                        inscritcode: userCodeStr
                    }
                });
                docsDeleteEmploi  = result.docs;
                var resultatDeleteEmploi = docsDeleteEmploi.length;
                if(resultatDeleteEmploi === 0){
                    alert("Emploi du Temps non encore enregistré");
                }else{
                    var divClear;
                    var divModifier;
                    divClear = document.getElementById('deletejouremploidutemps');
                    while(divClear.hasChildNodes()) {
                        divClear.removeChild(divClear.childNodes[0]);
                    }
                    divModifier = document.getElementById('deletejouremploidutemps');
                    //Create and append select list
                    var selectList = document.createElement("select");
                    selectList.id = "seldeletejouremploi";
                    divModifier.appendChild(selectList);
                    //Create and append the options
                    var option_null = document.createElement("option");
                    option_null.value = '';
                    option_null.text = 'Sélectionner Jour';
                    selectList.appendChild(option_null);
                    //Create and append the options
                    for (var i = 0; i<docsDeleteEmploi.length; i++){
                        var option = document.createElement("option");
                        option.value = docsDeleteEmploi[i].semainejour;
                        option.text = docsDeleteEmploi[i].semainejour;
                        selectList.appendChild(option);
                    }

                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'none';
                    document.getElementById('configurer').style.display = 'none';
                    document.getElementById('emploidutemps').style.display = 'block';
                    document.getElementById('crudemploidutemps').style.display = 'none';
                    document.getElementById('addemploidutemps').style.display = 'none';
                    document.getElementById('editemploidutemps').style.display = 'none';
                    document.getElementById('deleteemploidutemps').style.display = 'block';
                    document.getElementById('searchemploidutemps').style.display = 'none';
                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('cancelmatieres').style.display = 'none';
                    document.getElementById('cancelheure').style.display = 'none';
                    document.getElementById('cancelemploidutemps').style.display = 'none';
                    titre.innerHTML = 'Supprimer Emploi du Temps'; 

                    /***************************
                    seleditjouremploi
                    *****************************/
                    document.getElementById('seldeletejouremploi').addEventListener('change', seldeletejouremploiChange);
                    async function seldeletejouremploiChange(e){
                        e.preventDefault();

                        var userCodeStr = document.getElementById('userCode').innerText;
                        var emploiIdStr,emploiHeureStr = '';
                        var deletejouremploiStr = document.getElementById('seldeletejouremploi').value;
                        if(!deletejouremploiStr){
                            alert('Sélectionner un Jour avant de continuer');
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('cancelmatieres').style.display = 'none';
                            document.getElementById('cancelheure').style.display = 'none';
                            document.getElementById('cancelemploidutemps').style.display = 'none'; 
                        }else{
                            document.getElementById('deletejouremploidutemps').value = deletejouremploiStr;

                            var userCodeStr = document.getElementById('userCode').innerText;
                            var statutEmploiStr = 'Oui';
                            var docsDeleteMatiere = [];
                            try {
                                let result = await db.createIndex({
                                    index: {
                                        fields: [
                                            'emploistatut', 
                                            'inscritcode', 
                                            'emploijour'
                                        ]
                                    }
                                });
                                result = await db.find({
                                    selector: {
                                        emploistatut: statutEmploiStr,
                                        inscritcode: userCodeStr,
                                        emploijour: deletejouremploiStr
                                    }
                                });
                                docsDeleteMatiere  = result.docs;
                                var resultatDeleteMatiere = docsDeleteMatiere.length;
                                if(resultatDeleteMatiere === 0){
                                    alert("Emploi du Temps du "+deletejouremploiStr+" non encore enregistré");
                                    document.getElementById('seldeletejouremploi').value = '';
                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('cancelmatieres').style.display = 'none';
                                    document.getElementById('cancelheure').style.display = 'none';
                                    document.getElementById('cancelemploidutemps').style.display = 'none'; 
                                }else{
                                    var divClear;
                                    var divModifier;
                                    divClear = document.getElementById('deletematiereemploidutemps');
                                    while(divClear.hasChildNodes()) {
                                        divClear.removeChild(divClear.childNodes[0]);
                                    }
                                    divModifier = document.getElementById('deletematiereemploidutemps');
                                    //Create and append select list
                                    var selectList = document.createElement("select");
                                    selectList.id = "seldeletematiereemploi";
                                    divModifier.appendChild(selectList);
                                    //Create and append the options
                                    var option_null = document.createElement("option");
                                    option_null.value = '';
                                    option_null.text = 'Sélectionner Matière';
                                    selectList.appendChild(option_null);
                                    for (var i = 0; i <docsDeleteMatiere.length; i++){
                                        var option = document.createElement("option");
                                        option.value = docsDeleteMatiere[i]._id;
                                        option.text = docsDeleteMatiere[i].emploimatiere;
                                        selectList.appendChild(option);
                                    }

                                    document.getElementById('titre').style.display = 'block';
                                    var titre = document.getElementById('titre');
                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('cancelmatieres').style.display = 'block';
                                    document.getElementById('cancelheure').style.display = 'none';
                                    document.getElementById('cancelemploidutemps').style.display = 'none';
                                    titre.innerHTML = 'Supprimer Emploi du Temps'; 

                                    /***************************
                                    seldeletematiereemploi
                                    *****************************/
                                    document.getElementById('seldeletematiereemploi').addEventListener('change', seldeletematiereemploiChange);
                                    async function seldeletematiereemploiChange(e){
                                        e.preventDefault();

                                        var deletematiereStr = document.getElementById('seldeletematiereemploi').value;
                                        if(!deletematiereStr){
                                            alert('Sélectionner une Matière avant de continuer');
                                            document.getElementById('titre').style.display = 'block';
                                            var titre = document.getElementById('titre');
                                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                            document.getElementById('cancelmatieres').style.display = 'block';
                                            document.getElementById('cancelheure').style.display = 'none';
                                            document.getElementById('cancelemploidutemps').style.display = 'none';
                                            titre.innerHTML = 'Supprimer Emploi du Temps'; 
                                        }else{
                                            var userCodeStr = document.getElementById('userCode').innerText;
                                            var statutEmploiStr = 'Oui';
                                            var docsDelete = [];
                                            try {
                                                let result = await db.createIndex({
                                                    index: {
                                                        fields: [
                                                            'emploistatut', 
                                                            'inscritcode', 
                                                            '_id'
                                                        ]
                                                    }
                                                });
                                                result = await db.find({
                                                    selector: {
                                                        emploistatut: statutEmploiStr,
                                                        inscritcode: userCodeStr,
                                                        _id: deletematiereStr
                                                    }
                                                });
                                                docsDelete = result.docs;
                                                var resultatDelete = docsDelete.length;
                                                if(resultatDelete === 0){
                                                    document.getElementById('deleteidemploi').value = '';
                                                    document.getElementById('deleteheureemploidutemps').value = '';
                                                    document.getElementById('titre').style.display = 'block';
                                                    var titre = document.getElementById('titre');
                                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                                    document.getElementById('cancelmatieres').style.display = 'block';
                                                    document.getElementById('cancelheure').style.display = 'none';
                                                    document.getElementById('cancelemploidutemps').style.display = 'none';
                                                    titre.innerHTML = 'Supprimer Emploi du Temps'; 
                                                }else{
                                                    for(let j=0; j<docsDelete.length; j++){
                                                        emploiIdStr = docsDelete[j]._id;
                                                        emploiHeureStr = docsDelete[j].emploiheure;
                                                    }

                                                    document.getElementById('deleteheureemploidutemps').value = emploiHeureStr;

                                                    document.getElementById('deleteidemploi').value = emploiIdStr;

                                                    document.getElementById('titre').style.display = 'block';
                                                    var titre = document.getElementById('titre');
                                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                                    document.getElementById('cancelmatieres').style.display = 'block';
                                                    document.getElementById('cancelheure').style.display = 'block';
                                                    document.getElementById('cancelemploidutemps').style.display = 'block';
                                                    titre.innerHTML = 'Supprimer Emploi du Temps'; 
                                                }
                                            } catch (error) {
                                                alert('Erreur Exécution matières');
                                            }
                                        }
                                    }
                                }
                            } catch (error) {
                                alert('Erreur Exécution Matières Classe');
                            }
                        }
                    }
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        /**nav previous page button*/
        document.getElementById('prevdeleteemploidutempsBtn_1').addEventListener('click', prevdeleteemploidutemps1BtnChange);
        async function prevdeleteemploidutemps1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'block';
            document.getElementById('crudemploidutemps').style.display = 'block';
            document.getElementById('addemploidutemps').style.display = 'none';
            document.getElementById('editemploidutemps').style.display = 'none';
            document.getElementById('deleteemploidutemps').style.display = 'none';
            document.getElementById('searchemploidutemps').style.display = 'none';
            titre.innerHTML = 'Emploi du Temps'; 
        }
        /***************************
        supprimeremploidutempsBtn
        *****************************/
        document.getElementById('supprimeremploidutempsBtn').addEventListener('click', supprimeremploidutempsBtnChange);
        async function supprimeremploidutempsBtnChange(e){
            e.preventDefault();

            var divClearModal = document.getElementById('modaloutemploi');
            let divModal = document.createElement('div');
            divModal.innerHTML =
            `<div class="modal">
                <p>Êtes-vous sûr de Supprimer ?</p>
                <button class="btn btn--alt" id="ouiHandlerEmploiBtn">Oui</button>
                <button class="btn" id="nonHandlerEmploiBtn">Non</button>
            </div>`;
            divClearModal.appendChild(divModal);

            var divClearBackdrop = document.getElementById('backdropoutemploi');
            let divBack = document.createElement('div');
            divBack.innerHTML =
            `<div class="backdrop" id="backdropHandlerEmploiBtn"></div>`;
            divClearBackdrop.appendChild(divBack);

            document.getElementById('ouiHandlerEmploiBtn').addEventListener('click', ouiHandlerEmploiBtnChange);
            async function ouiHandlerEmploiBtnChange(e){
                e.preventDefault();

                var deleteidemploiStr = document.getElementById('deleteidemploi').value;

                try {
                    //Delete
                    var doc = await db.get(deleteidemploiStr);
                    var response = await db.remove(doc);
                    if(response.ok){
                        alert('Emploi du Temps Supprimé avec Succès');
                        var divClearModal = document.getElementById('modaloutemploi');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutemploi');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'block';
                        document.getElementById('dashboard').style.display = 'block';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        titre.innerHTML = 'Tableau de Bord';
                    }else{
                        alert("Impossible Supprimer Emploi du Temps");
                        var divClearModal = document.getElementById('modaloutemploi');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutemploi');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'block';
                        document.getElementById('dashboard').style.display = 'block';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        titre.innerHTML = 'Tableau de Bord';
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
            document.getElementById('nonHandlerEmploiBtn').addEventListener('click', nonHandlerEmploiBtnChange);
            async function nonHandlerEmploiBtnChange(e){
                e.preventDefault();

                var divClearModal = document.getElementById('modaloutemploi');
                while(divClearModal.hasChildNodes()) {
                    divClearModal.removeChild(divClearModal.childNodes[0]);
                }

                var divClearBackdrop = document.getElementById('backdropoutemploi');
                while(divClearBackdrop.hasChildNodes()) {
                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                }
            }
            document.getElementById('backdropHandlerEmploiBtn').addEventListener('click', backdropHandlerEmploiBtnChange);
            async function backdropHandlerEmploiBtnChange(e){
                e.preventDefault();

                var divClearModal = document.getElementById('modaloutemploi');
                while(divClearModal.hasChildNodes()) {
                    divClearModal.removeChild(divClearModal.childNodes[0]);
                }

                var divClearBackdrop = document.getElementById('backdropoutemploi');
                while(divClearBackdrop.hasChildNodes()) {
                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                }
            }
        }

        /***************************
        searchemploidutempsBtn
        *****************************/
        document.getElementById('searchemploidutempsBtn').addEventListener('click', searchemploidutempsBtnChange);
        async function searchemploidutempsBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutEmploiStr = 'Oui';
            var docsSearchEmploi = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: ['semainestatut', 'inscritcode']
                    }
                });
                result = await db.find({
                    selector: {
                        semainestatut: statutEmploiStr,
                        inscritcode: userCodeStr
                    }
                });
                docsSearchEmploi  = result.docs;
                var resultatSearchEmploi = docsSearchEmploi.length;
                if(resultatSearchEmploi === 0){
                    alert("Emploi du Temps pas encore enregistré");
                }else{
                    var divClear;
                    var divModifier;
                    divClear = document.getElementById('searchjouremploidutemps');
                    while(divClear.hasChildNodes()) {
                        divClear.removeChild(divClear.childNodes[0]);
                    }
                    divModifier = document.getElementById('searchjouremploidutemps');
                    //Create and append select list
                    var selectList = document.createElement("select");
                    selectList.id = "selsearchjouremploi";
                    divModifier.appendChild(selectList);
                    //Create and append the options
                    var option_null = document.createElement("option");
                    option_null.value = '';
                    option_null.text = 'Sélectionner Jour';
                    selectList.appendChild(option_null);
                    //Create and append the options
                    for (var i = 0; i<docsSearchEmploi.length; i++){
                        var option = document.createElement("option");
                        option.value = docsSearchEmploi[i].semainejour;
                        option.text = docsSearchEmploi[i].semainejour;
                        selectList.appendChild(option);
                    }

                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'none';
                    document.getElementById('configurer').style.display = 'none';
                    document.getElementById('emploidutemps').style.display = 'block';
                    document.getElementById('crudemploidutemps').style.display = 'none';
                    document.getElementById('addemploidutemps').style.display = 'none';
                    document.getElementById('editemploidutemps').style.display = 'none';
                    document.getElementById('deleteemploidutemps').style.display = 'none';
                    document.getElementById('searchemploidutemps').style.display = 'block';
                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('viewemploi').style.display = 'none';
                    document.getElementById('viewconsulteremploi').style.display = 'none';
                    titre.innerHTML = 'Consulter Emploi du Temps'; 

                    /***************************
                    selsearchjouremploi
                    *****************************/
                    document.getElementById('selsearchjouremploi').addEventListener('change', selsearchjouremploiChange);
                    async function selsearchjouremploiChange(e){
                        e.preventDefault();

                        var userCodeStr = document.getElementById('userCode').innerText;
                        var statutEmploiStr = 'Oui';
                        var docsSearch = [];

                        var searchjouremploiStr = document.getElementById('selsearchjouremploi').value;
                        if(!searchjouremploiStr){
                            alert('Sélectionner un Jour avant de continuer');
                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('viewemploi').style.display = 'none';
                            document.getElementById('viewconsulteremploi').style.display = 'none';
                            titre.innerHTML = 'Consulter Emploi du Temps'; 
                        }else{
                            try {
                                let result = await db.createIndex({
                                    index: {
                                        fields: [
                                            'emploistatut', 
                                            'inscritcode', 
                                            'emploijour'
                                        ]
                                    }
                                });
                                result = await db.find({
                                    selector: {
                                        emploistatut: statutEmploiStr,
                                        inscritcode: userCodeStr,
                                        emploijour: searchjouremploiStr
                                    }
                                });
                                docsSearch  = result.docs;
                                var resultatSearch = docsSearch.length;
                                if(resultatSearch === 0){
                                    alert("Matières Classe non encore enregistrées");
                                    document.getElementById('titre').style.display = 'block';
                                    var titre = document.getElementById('titre');
                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('viewemploi').style.display = 'none';
                                    document.getElementById('viewconsulteremploi').style.display = 'none';
                                    titre.innerHTML = 'Consulter Emploi du Temps'; 
                                }else{
                                    updateSearchEmploiUIWithDocs(docsSearch);
                                    document.getElementById('titre').style.display = 'block';
                                    var titre = document.getElementById('titre');
                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('viewemploi').style.display = 'block';
                                    document.getElementById('viewconsulteremploi').style.display = 'block';
                                    titre.innerHTML = 'Consulter Emploi du Temps'; 
                                    var viewjouremploiStr = document.getElementById('viewjouremploi');
                                    viewjouremploiStr.innerHTML = searchjouremploiStr;
                                }
                            } catch (error) {
                                alert('Erreur Exécution');
                            }
                        }
                    }
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        // Adds the document to the HTML First table.
        // If the document already exists in the table, it will be updated.
        function viewSearchEmploiDuTempsDocToTable(doc, viewSearchEmploiDuTempsTableBody){
            // Each row of the table has an id attribute set to the doc._id field.
            var tr = viewSearchEmploiDuTempsTableBody.querySelector(`#${doc._id}`);

            if( !tr ) {
                tr = document.createElement('tr');
                tr.id = doc._id;
            }
        
            // Remove any existing table cells.
            while(tr.hasChildNodes()) {
                tr.removeChild(tr.childNodes[0]);
            }

            // Create new table cells for text fields.
            var tdHeure = document.createElement('td');
            tdHeure.innerText = doc.emploiheure;
            tr.appendChild(tdHeure);

            // Create new table cells for text fields.
            var tdMatiere = document.createElement('td');
            tdMatiere.innerText = doc.emploimatiere;
            tr.appendChild(tdMatiere);

            // Add the row to the top of the table.
            viewSearchEmploiDuTempsTableBody.insertBefore(tr, viewSearchEmploiDuTempsTableBody.nextSibling);
        }
        /**Clear and update the UI to display the docs.*/
        function updateSearchEmploiUIWithDocs(docs) {
            var viewSearchEmploiDuTempsTableBody = document.getElementById('viewSearchEmploiDuTempsTableBody');
            
            // Remove all child nodes from the table
            while(viewSearchEmploiDuTempsTableBody.hasChildNodes()) {
                viewSearchEmploiDuTempsTableBody.removeChild(viewSearchEmploiDuTempsTableBody.childNodes[0]);
            }
            
            // Add the new documents to the table
            for(var i=0; i<docs.length; i++) {
                var doc = docs[i];
                viewSearchEmploiDuTempsDocToTable(doc, viewSearchEmploiDuTempsTableBody);
            }
        }
        /**nav previous page button*/
        document.getElementById('prevsearchemploidutempsBtn_1').addEventListener('click', prevsearchemploidutemps1BtnChange);
        async function prevsearchemploidutemps1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'block';
            document.getElementById('crudemploidutemps').style.display = 'block';
            document.getElementById('addemploidutemps').style.display = 'none';
            document.getElementById('editemploidutemps').style.display = 'none';
            document.getElementById('deleteemploidutemps').style.display = 'none';
            document.getElementById('searchemploidutemps').style.display = 'none';
            titre.innerHTML = 'Emploi du Temps'; 
        }
        /***************************
        consulteremploidutempsBtn
        *****************************/
        document.getElementById('consulteremploidutempsBtn').addEventListener('click', consulteremploidutempsBtnChange);
        async function consulteremploidutempsBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'block';
            document.getElementById('crudemploidutemps').style.display = 'block';
            document.getElementById('addemploidutemps').style.display = 'none';
            document.getElementById('editemploidutemps').style.display = 'none';
            document.getElementById('deleteemploidutemps').style.display = 'none';
            document.getElementById('searchemploidutemps').style.display = 'none';
            titre.innerHTML = 'Emploi du Temps'; 
        }
    };

    /****************
    HELPER FUNCTION
    *****************/
    /**1: Get Current Date in JavaScript */
    function getCurrentDate(){
        // Date object
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1;
        var yyyy = today.getFullYear();
        if(dd<10){
            dd='0'+dd;
        }
        if(mm<10){
            mm='0'+mm;
        }
        // Current Date
        var date = yyyy+'-'+mm+'-'+dd;
        return date;
    }


    window.addEventListener('load', () =>{
        new taskEmploiDuTemps();
    })
}) ();