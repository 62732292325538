import './revision.css';
import './pouchrevision';
import ConsulterButton from '../../components/ConsulterButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function SearchRevision() {
    return ( 
        <div id="searchrevision">
            <KeyboardBackspace style={prevArrows} id="prevsearchrevisionBtn_1" />
            <br/><br/>
            <form>
                <div id="formsearchrevision" style={scroller}>
                    <br/>
                    <div>
                        <label style={labelStyle}>Sélection matière</label>
                        <div id="searchmatiererevision"></div>
                    </div>
                    <br/>
                    <div id="vieweffectuerevision">
                        <label style={labelStyle}>Révision Effectuée</label>
                        <select id="searcheffectuerevision">
                            <option value="">Sélectionner</option>
                            <option value='Oui'>Oui</option>
                            <option value="Non">Non</option>
                        </select>
                    </div>
                    <br/>
                    <div id="viewrevision">
                        <label id="viewmatiererevision" style={labelStyle}>Matière</label>
                        <br/><br/>
                        <table className='thin_table_config'>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Libellé</th>
                                </tr>
                            </thead>
                            <tbody id='viewSearchRevisionTableBody'></tbody>
                        </table>
                    </div>
                    <br/>
                    <div id="viewconsulterrevision">
                        <ConsulterButton 
                        idconsulterbutton="consulterrevisionBtn"
                        consulter="OK"
                        />
                    </div>
                    <br/><br/>
                </div>
                <div id="modaloutsearchrevision"></div>
                <div id="backdropoutsearchrevision"></div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default SearchRevision;