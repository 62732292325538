/* eslint-disable no-redeclare */
import PouchDB from 'pouchdb';
import PouchFind from 'pouchdb-find';

(function(){
    var taskNotes = function(){
        /*****************************************************
        ENABLING POUCHDB WITHOUT SYNC TO REMOTE COUCHDB
        ******************************************************/
        //SET UP POUCHDB AND CREATE THE DATABASE 
        const db = new PouchDB('suclu');
        PouchDB.plugin(PouchFind);

        /***************
        crudnotesBtn
        *****************/
        /**nav previous page button*/
        document.getElementById('prevcrudnotesBtn_1').addEventListener('click', prevcrudnotes1BtnChange);
        async function prevcrudnotes1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'block';
            document.getElementById('dashboard').style.display = 'block';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'none';
            titre.innerHTML = 'Tableau de Bord';
        }

        /***************************
        addnotesBtn
        *****************************/
        document.getElementById('addnotesBtn').addEventListener('click', addnotesBtnChange);
        async function addnotesBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutanneeStr = 'Oui';
            var statutmatiereStr = 'Oui';

            var docsAnnee = [];
            var docsMatiere = [];

            if(!userCodeStr){
                alert('Connectez-vous à votre Compte avant de continuer');
            }else{
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: ['anneestatut', 'inscritcode']
                        }
                    });
                    result = await db.find({
                        selector: {
                            anneestatut: statutanneeStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsAnnee  = result.docs;
                    var resultatAnnee = docsAnnee.length;
                    if(resultatAnnee === 0){
                        alert('Aucun Calendrier Année Scolaire Enregistré');
                    }else{
                        var divClear;
                        var divModifier;
                        divClear = document.getElementById('addcalendriernotes');
                        while(divClear.hasChildNodes()) {
                            divClear.removeChild(divClear.childNodes[0]);
                        }
                        divModifier = document.getElementById('addcalendriernotes');
                        //Create and append select list
                        var selectList = document.createElement("select");
                        selectList.id = "seladdcalendriernotes";
                        divModifier.appendChild(selectList);
                        //Create and append the options
                        var option_null = document.createElement("option");
                        option_null.value = '';
                        option_null.text = 'Sélectionner Calendrier';
                        selectList.appendChild(option_null);
                        //Create and append the options
                        for (var i = 0; i<docsAnnee.length; i++){
                            var option = document.createElement("option");
                            option.value = docsAnnee[i].anneescolaire;
                            option.text = docsAnnee[i].anneescolaire;
                            selectList.appendChild(option);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'none';
                        document.getElementById('programme').style.display = 'none';
                        document.getElementById('notes').style.display = 'block';
                        document.getElementById('crudnotes').style.display = 'none';
                        document.getElementById('addnotes').style.display = 'block';
                        document.getElementById('editnotes').style.display = 'none';
                        document.getElementById('deletenotes').style.display = 'none';
                        document.getElementById('searchnotes').style.display = 'none';
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('savecalendriernotes').style.display = 'block';
                        document.getElementById('savematierenotes').style.display = 'none';
                        document.getElementById('savetypenotes').style.display = 'none';
                        document.getElementById('saveprogrammenotes').style.display = 'none';
                        document.getElementById('savedatenotes').style.display = 'none';
                        document.getElementById('saveobtenunotes').style.display = 'none';
                        document.getElementById('savecoefficientnotes').style.display = 'none';
                        document.getElementById('savenotes').style.display = 'none';
                        titre.innerHTML = 'Enregistrer Note de Classe';

                        /***************************
                        seladdcalendriernotes
                        *****************************/
                        document.getElementById('seladdcalendriernotes').addEventListener('change', seladdcalendriernotesChange);
                        async function seladdcalendriernotesChange(e){
                            e.preventDefault();

                            var seladdcalendriernotesStr = document.getElementById('seladdcalendriernotes').value;
                            if(!seladdcalendriernotesStr){
                                alert('Sélectionner un calendrier scolaire avant de continuer');
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('savecalendriernotes').style.display = 'block';
                                document.getElementById('savematierenotes').style.display = 'none';
                                document.getElementById('savetypenotes').style.display = 'none';
                                document.getElementById('saveprogrammenotes').style.display = 'none';
                                document.getElementById('savedatenotes').style.display = 'none';
                                document.getElementById('saveobtenunotes').style.display = 'none';
                                document.getElementById('savecoefficientnotes').style.display = 'none';
                                document.getElementById('savenotes').style.display = 'none';
                            }else{
                                document.getElementById('addselectcalendriernotes').value = seladdcalendriernotesStr;
                                try {
                                    let result = await db.createIndex({
                                        index: {
                                            fields: ['matierestatut', 'inscritcode']
                                        }
                                    });
                                    result = await db.find({
                                        selector: {
                                            matierestatut: statutmatiereStr,
                                            inscritcode: userCodeStr
                                        }
                                    });
                                    docsMatiere  = result.docs;
                                    var resultatMatiere = docsMatiere.length;
                                    if(resultatMatiere === 0){
                                        alert("Matières Classe non encore enregistrées");
                                    }else{
                                        var divClear;
                                        var divModifier;
                                        divClear = document.getElementById('addmatierenotes');
                                        while(divClear.hasChildNodes()) {
                                            divClear.removeChild(divClear.childNodes[0]);
                                        }
                                        divModifier = document.getElementById('addmatierenotes');
                                        //Create and append select list
                                        var selectList = document.createElement("select");
                                        selectList.id = "seladdmatierenotes";
                                        divModifier.appendChild(selectList);
                                        //Create and append the options
                                        var option_null = document.createElement("option");
                                        option_null.value = '';
                                        option_null.text = 'Sélectionner Matière';
                                        selectList.appendChild(option_null);
                                        //Create and append the options
                                        for (var i = 0; i<docsMatiere.length; i++){
                                            var option = document.createElement("option");
                                            option.value = docsMatiere[i].matierenom;
                                            option.text = docsMatiere[i].matierenom;
                                            selectList.appendChild(option);
                                        }

                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('savecalendriernotes').style.display = 'block';
                                        document.getElementById('savematierenotes').style.display = 'block';
                                        document.getElementById('savetypenotes').style.display = 'none';
                                        document.getElementById('saveprogrammenotes').style.display = 'none';
                                        document.getElementById('savedatenotes').style.display = 'none';
                                        document.getElementById('saveobtenunotes').style.display = 'none';
                                        document.getElementById('savecoefficientnotes').style.display = 'none';
                                        document.getElementById('savenotes').style.display = 'none';

                                        /***************************
                                        seladdmatierenotes
                                        *****************************/
                                        document.getElementById('seladdmatierenotes').addEventListener('change', seladdmatierenotesChange);
                                        async function seladdmatierenotesChange(e){
                                            e.preventDefault();

                                            document.getElementById('seladdtypenotes').value = '';

                                            var seladdmatierenotesStr = document.getElementById('seladdmatierenotes').value;
                                            if(!seladdmatierenotesStr){
                                                alert('Sélectionner une matière avant de continuer');
                                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                                document.getElementById('savecalendriernotes').style.display = 'block';
                                                document.getElementById('savematierenotes').style.display = 'block';
                                                document.getElementById('savetypenotes').style.display = 'none';
                                                document.getElementById('saveprogrammenotes').style.display = 'none';
                                                document.getElementById('savedatenotes').style.display = 'none';
                                                document.getElementById('saveobtenunotes').style.display = 'none';
                                                document.getElementById('savecoefficientnotes').style.display = 'none';
                                                document.getElementById('savenotes').style.display = 'none';
                                            }else{
                                                document.getElementById('addselectmatierenotes').value = seladdmatierenotesStr;
                                                document.getElementById('addmatiereprogrammenotes').innerText =seladdcalendriernotesStr +' '+seladdmatierenotesStr;
                                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                                document.getElementById('savecalendriernotes').style.display = 'block';
                                                document.getElementById('savematierenotes').style.display = 'block';
                                                document.getElementById('savetypenotes').style.display = 'block';
                                                document.getElementById('saveprogrammenotes').style.display = 'none';
                                                document.getElementById('savedatenotes').style.display = 'none';
                                                document.getElementById('saveobtenunotes').style.display = 'none';
                                                document.getElementById('savecoefficientnotes').style.display = 'none';
                                                document.getElementById('savenotes').style.display = 'none';
                                            }
                                        }
                                    }
                                } catch (error) {
                                    alert('Erreur Exécution Matières');
                                }
                            }
                        }
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }
        /**nav previous page button*/
        document.getElementById('prevaddnotesBtn_1').addEventListener('click', prevaddnotes1BtnChange);
        async function prevaddnotes1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'block';
            document.getElementById('crudnotes').style.display = 'block';
            document.getElementById('addnotes').style.display = 'none';
            document.getElementById('editnotes').style.display = 'none';
            document.getElementById('deletenotes').style.display = 'none';
            document.getElementById('searchnotes').style.display = 'none';
            titre.innerHTML = 'Notes de Classe'; 
        }
        /***************************
        seladdtypenotes
        *****************************/
        document.getElementById('seladdtypenotes').addEventListener('change', seladdtypenotesChange);
        async function seladdtypenotesChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var addselectcalendriernotesStr = document.getElementById('addselectcalendriernotes').value;
            var addselectmatierenotesStr = document.getElementById('addselectmatierenotes').value;
            var seladdtypenotesStr = document.getElementById('seladdtypenotes').value;
            var statutProgrammeStr = 'Oui';
            var docsDevoir = [];
            if(!seladdtypenotesStr){
                alert('Sélectionner un type de Devoir Effectué avant de continuer');
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('savecalendriernotes').style.display = 'block';
                document.getElementById('savematierenotes').style.display = 'block';
                document.getElementById('savetypenotes').style.display = 'block';
                document.getElementById('saveprogrammenotes').style.display = 'none';
                document.getElementById('savedatenotes').style.display = 'none';
                document.getElementById('saveobtenunotes').style.display = 'none';
                document.getElementById('savecoefficientnotes').style.display = 'none';
                document.getElementById('savenotes').style.display = 'none';
            }else{
                try {
                    let resultDevoir = await db.createIndex({
                        index: {
                            fields: [
                                'anneescolaire',
                                'matierenom',
                                'programmedevoir',
                                'programmestatut',
                                'inscritcode'
                            ]
                        }
                    });
                    resultDevoir = await db.find({
                        selector: {
                            anneescolaire: addselectcalendriernotesStr,
                            matierenom: addselectmatierenotesStr,
                            programmedevoir: seladdtypenotesStr,
                            programmestatut: statutProgrammeStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsDevoir = resultDevoir.docs;
                    var resultatDevoir = docsDevoir.length;
                    if(resultatDevoir === 0){
                        alert('Aucun Résultat Disponible. Date Devoir non encore Enregistrée');
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('savecalendriernotes').style.display = 'block';
                        document.getElementById('savematierenotes').style.display = 'block';
                        document.getElementById('savetypenotes').style.display = 'block';
                        document.getElementById('saveprogrammenotes').style.display = 'none';
                        document.getElementById('savedatenotes').style.display = 'none';
                        document.getElementById('saveobtenunotes').style.display = 'none';
                        document.getElementById('savecoefficientnotes').style.display = 'none';
                        document.getElementById('savenotes').style.display = 'none';
                    }else{
                        updateProgrammeNotesUIWithDocs(docsDevoir);
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('savecalendriernotes').style.display = 'block';
                        document.getElementById('savematierenotes').style.display = 'block';
                        document.getElementById('savetypenotes').style.display = 'block';
                        document.getElementById('saveprogrammenotes').style.display = 'block';
                        document.getElementById('savedatenotes').style.display = 'none';
                        document.getElementById('saveobtenunotes').style.display = 'none';
                        document.getElementById('savecoefficientnotes').style.display = 'none';
                        document.getElementById('savenotes').style.display = 'none';
                    }
                } catch (error) {
                    alert('Erreur Exécution'); 
                }
            }
        }
        // Adds the document to the HTML First table.
        // If the document already exists in the table, it will be updated.
        function addProgrammeNotesTableDocToTable(doc, addProgrammeNotesTableBody){
            // Each row of the table has an id attribute set to the doc._id field.
            var tr = addProgrammeNotesTableBody.querySelector(`#${doc._id}`);

            if( !tr ) {
                tr = document.createElement('tr');
                tr.id = doc._id;
            }
        
            // Remove any existing table cells.
            while(tr.hasChildNodes()) {
                tr.removeChild(tr.childNodes[0]);
            }

            // Create new table cells for text fields.
            var tdEcheance = document.createElement('td');
            var dateEcheance = new Date(doc.programmeecheance);
            tdEcheance.innerText = dateEcheance.toLocaleDateString();
            tr.appendChild(tdEcheance);

            // Create new table cells for text fields.
            var tdType = document.createElement('td');
            tdType.innerText = doc.programmedevoir;
            tr.appendChild(tdType);

            // Create new table cells for text fields.
            var tdAction = document.createElement('td');
            var idProgramme = doc._id;
            var button = document.createElement("button");
            button.id = "ouinotesBtn";
            button.type = 'button';
            button.innerHTML = 'Afficher';
            button.onclick = async function(){
                var idreferencenotesStr,inscritCodeStr,programmeEcheanceStr = '';
                var statutnotes = 'Oui';
                var docsNotes = [];
                var statutprogrammeStr = 'Oui';
                var docsProgramme = [];
                try {
                    let resultNotes = await db.createIndex({
                        index: {
                            fields: ['notereference', 'notestatut']
                        }
                    });
                    resultNotes = await db.find({
                        selector: {
                            notereference: idProgramme,
                            notestatut: statutnotes
                        }
                    });
                    docsNotes = resultNotes.docs;
                    var resultatNotes = docsNotes.length;
                    if(resultatNotes === 0){
                        let resultProgramme = await db.createIndex({
                            index: {
                                fields: ['_id', 'programmestatut']
                            }
                        });
                        resultProgramme = await db.find({
                            selector: {
                                _id: idProgramme,
                                programmestatut: statutprogrammeStr
                            }
                        });
                        docsProgramme = resultProgramme.docs;
                        var resultatProgramme = docsProgramme.length;
                        if(resultatProgramme === 0){
                            alert("Résultat Indisponible");
                        }else{
                            for(let i=0; i<docsProgramme.length; i++){
                                idreferencenotesStr = docsProgramme[i]._id;
                                inscritCodeStr = docsProgramme[i].inscritcode;
                                programmeEcheanceStr = docsProgramme[i].programmeecheance;
                            }

                            document.getElementById('addidreferencenotes').value = idreferencenotesStr;
                            document.getElementById('addcodeusernotes').value = inscritCodeStr;
                            document.getElementById('adddatenotes').value = programmeEcheanceStr;

                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('savecalendriernotes').style.display = 'block';
                            document.getElementById('savematierenotes').style.display = 'block';
                            document.getElementById('savetypenotes').style.display = 'block';
                            document.getElementById('saveprogrammenotes').style.display = 'block';
                            document.getElementById('savedatenotes').style.display = 'block';
                            document.getElementById('saveobtenunotes').style.display = 'block';
                            document.getElementById('savecoefficientnotes').style.display = 'block';
                            document.getElementById('savenotes').style.display = 'block';
                        }
                    }else{
                        alert("Note déjà enregistré pour ce devoir");
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('savecalendriernotes').style.display = 'block';
                        document.getElementById('savematierenotes').style.display = 'block';
                        document.getElementById('savetypenotes').style.display = 'block';
                        document.getElementById('saveprogrammenotes').style.display = 'block';
                        document.getElementById('savedatenotes').style.display = 'none';
                        document.getElementById('saveobtenunotes').style.display = 'none';
                        document.getElementById('savecoefficientnotes').style.display = 'none';
                        document.getElementById('savenotes').style.display = 'none';
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
            tdAction.appendChild(button);
            tr.appendChild(tdAction);

            // Add the row to the top of the table.
            addProgrammeNotesTableBody.insertBefore(tr, addProgrammeNotesTableBody.nextSibling);
        }
        /**Clear and update the UI to display the docs.*/
        function updateProgrammeNotesUIWithDocs(docs) {
            var addProgrammeNotesTableBody = document.getElementById('addProgrammeNotesTableBody');
            
            // Remove all child nodes from the table
            while(addProgrammeNotesTableBody.hasChildNodes()) {
                addProgrammeNotesTableBody.removeChild(addProgrammeNotesTableBody.childNodes[0]);
            }
            
            // Add the new documents to the table
            for(var i=0; i<docs.length; i++) {
                var doc = docs[i];
                addProgrammeNotesTableDocToTable(doc, addProgrammeNotesTableBody);
            }
        }
        /***************************
        enregistrernotesBtn
        *****************************/
        document.getElementById('enregistrernotesBtn').addEventListener('click', enregistrernotesBtnChange);
        async function enregistrernotesBtnChange(e){
            e.preventDefault();

            var usercodeStr = document.getElementById('userCode').innerText;
            var addselectcalendriernotesStr = document.getElementById('addselectcalendriernotes').value;
            var addselectmatierenotesStr = document.getElementById('addselectmatierenotes').value;
            var seladdtypenotesStr = document.getElementById('seladdtypenotes').value;
            var adddatenotesStr = document.getElementById('adddatenotes').value;
            var addobtenuenotesStr = document.getElementById('addobtenuenotes').value;
            var addcoefficientnotesStr = document.getElementById('addcoefficientnotes').value;
            var addidreferencenotesStr = document.getElementById('addidreferencenotes').value;
            var addeffectuenotesStr = 'Oui';
            var datejourStr = getCurrentDate();
            var addstatutnotesStr = 'Oui';

            if(!usercodeStr){
                alert("Identifiez-vous avant de continuer");
            }else if(!addobtenuenotesStr){
                alert("Saisir Note Obtenue");
            }else if(!addcoefficientnotesStr){
                alert("Saisir Coefficient");
            }else{
                try {
                    //Create the document _id and _rev.
                    var now = Date.now();
                    var id = 'note_' + now;
                    // Add the document to the database.
                    var response = await db.put({
                        _id: id,
                        inscritcode: usercodeStr,
                        anneescolaire: addselectcalendriernotesStr,
                        matierenom: addselectmatierenotesStr,
                        notedevoir: seladdtypenotesStr,
                        noteecheance: adddatenotesStr, //date
                        noteobtenue: addobtenuenotesStr.toString(),
                        notecoefficient: addcoefficientnotesStr.toString(),
                        noteeffectue: addeffectuenotesStr,
                        notedate: datejourStr,
                        notestatut: addstatutnotesStr,
                        notereference: addidreferencenotesStr,
                    });
                    // check if document inserted and synced
                    var doc = await db.get(response.id);
                    if(doc !== ''){
                        alert('Enregistrement Note Devoir avec Succès');
                        document.getElementById('addselectcalendriernotes').value = '';
                        document.getElementById('addselectmatierenotes').value = '';
                        document.getElementById('seladdtypenotes').value = '';
                        document.getElementById('adddatenotes').value = '';
                        document.getElementById('addobtenuenotes').value = '';
                        document.getElementById('addcoefficientnotes').value = '';
                        document.getElementById('addidreferencenotes').value = '';

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'none';
                        document.getElementById('programme').style.display = 'none';
                        document.getElementById('notes').style.display = 'block';
                        document.getElementById('crudnotes').style.display = 'block';
                        document.getElementById('addnotes').style.display = 'none';
                        document.getElementById('editnotes').style.display = 'none';
                        document.getElementById('deletenotes').style.display = 'none';
                        document.getElementById('searchnotes').style.display = 'none';
                        titre.innerHTML = 'Notes de Classe'; 
                    }else{
                        alert('Echec Enregistrement Note Devoir. Reprendre');
                        document.getElementById('addselectcalendriernotes').value = '';
                        document.getElementById('addselectmatierenotes').value = '';
                        document.getElementById('seladdtypenotes').value = '';
                        document.getElementById('adddatenotes').value = '';
                        document.getElementById('addobtenuenotes').value = '';
                        document.getElementById('addcoefficientnotes').value = '';
                        document.getElementById('addidreferencenotes').value = '';

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'none';
                        document.getElementById('programme').style.display = 'none';
                        document.getElementById('notes').style.display = 'block';
                        document.getElementById('crudnotes').style.display = 'block';
                        document.getElementById('addnotes').style.display = 'none';
                        document.getElementById('editnotes').style.display = 'none';
                        document.getElementById('deletenotes').style.display = 'none';
                        document.getElementById('searchnotes').style.display = 'none';
                        titre.innerHTML = 'Notes de Classe'; 
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }

        /***************************
        editnotesBtn
        *****************************/
        document.getElementById('editnotesBtn').addEventListener('click', editnotesBtnChange);
        async function editnotesBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutanneeStr = 'Oui';
            var statutmatiereStr = 'Oui';

            var docsAnnee = [];
            var docsMatiere = [];

            if(!userCodeStr){
                alert('Connectez-vous à votre Compte avant de continuer');
            }else{
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: ['anneestatut', 'inscritcode']
                        }
                    });
                    result = await db.find({
                        selector: {
                            anneestatut: statutanneeStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsAnnee  = result.docs;
                    var resultatAnnee = docsAnnee.length;
                    if(resultatAnnee === 0){
                        alert('Aucun Calendrier Année Scolaire Enregistré');
                    }else{
                        var divClear;
                        var divModifier;
                        divClear = document.getElementById('editcalendriernotes');
                        while(divClear.hasChildNodes()) {
                            divClear.removeChild(divClear.childNodes[0]);
                        }
                        divModifier = document.getElementById('editcalendriernotes');
                        //Create and append select list
                        var selectList = document.createElement("select");
                        selectList.id = "seleditcalendriernotes";
                        divModifier.appendChild(selectList);
                        //Create and append the options
                        var option_null = document.createElement("option");
                        option_null.value = '';
                        option_null.text = 'Sélectionner Calendrier';
                        selectList.appendChild(option_null);
                        //Create and append the options
                        for (var i = 0; i<docsAnnee.length; i++){
                            var option = document.createElement("option");
                            option.value = docsAnnee[i].anneescolaire;
                            option.text = docsAnnee[i].anneescolaire;
                            selectList.appendChild(option);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'none';
                        document.getElementById('programme').style.display = 'none';
                        document.getElementById('notes').style.display = 'block';
                        document.getElementById('crudnotes').style.display = 'none';
                        document.getElementById('addnotes').style.display = 'none';
                        document.getElementById('editnotes').style.display = 'block';
                        document.getElementById('deletenotes').style.display = 'none';
                        document.getElementById('searchnotes').style.display = 'none';
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('changecalendriernotes').style.display = 'block';
                        document.getElementById('changematierenotes').style.display = 'none';
                        document.getElementById('changetypenotes').style.display = 'none';
                        document.getElementById('changeprogrammenotes').style.display = 'none';
                        document.getElementById('changedatenotes').style.display = 'none';
                        document.getElementById('changeobtenunotes').style.display = 'none';
                        document.getElementById('changecoefficientnotes').style.display = 'none';
                        document.getElementById('changenotes').style.display = 'none';
                        titre.innerHTML = 'Modifier Note de Classe';

                        /***************************
                        seleditcalendriernotes
                        *****************************/
                        document.getElementById('seleditcalendriernotes').addEventListener('change', seleditcalendriernotesChange);
                        async function seleditcalendriernotesChange(e){
                            e.preventDefault();

                            var seleditcalendriernotesStr = document.getElementById('seleditcalendriernotes').value;
                            if(!seleditcalendriernotesStr){
                                alert('Sélectionner un calendrier scolaire avant de continuer');
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('changecalendriernotes').style.display = 'block';
                                document.getElementById('changematierenotes').style.display = 'none';
                                document.getElementById('changetypenotes').style.display = 'none';
                                document.getElementById('changeprogrammenotes').style.display = 'none';
                                document.getElementById('changedatenotes').style.display = 'none';
                                document.getElementById('changeobtenunotes').style.display = 'none';
                                document.getElementById('changecoefficientnotes').style.display = 'none';
                                document.getElementById('changenotes').style.display = 'none';
                            }else{
                                document.getElementById('editselectcalendriernotes').value = seleditcalendriernotesStr;
                                try {
                                    let result = await db.createIndex({
                                        index: {
                                            fields: ['matierestatut', 'inscritcode']
                                        }
                                    });
                                    result = await db.find({
                                        selector: {
                                            matierestatut: statutmatiereStr,
                                            inscritcode: userCodeStr
                                        }
                                    });
                                    docsMatiere  = result.docs;
                                    var resultatMatiere = docsMatiere.length;
                                    if(resultatMatiere === 0){
                                        alert("Matières Classe non encore enregistrées");
                                    }else{
                                        var divClear;
                                        var divModifier;
                                        divClear = document.getElementById('editmatierenotes');
                                        while(divClear.hasChildNodes()) {
                                            divClear.removeChild(divClear.childNodes[0]);
                                        }
                                        divModifier = document.getElementById('editmatierenotes');
                                        //Create and append select list
                                        var selectList = document.createElement("select");
                                        selectList.id = "seleditmatierenotes";
                                        divModifier.appendChild(selectList);
                                        //Create and append the options
                                        var option_null = document.createElement("option");
                                        option_null.value = '';
                                        option_null.text = 'Sélectionner Matière';
                                        selectList.appendChild(option_null);
                                        //Create and append the options
                                        for (var i = 0; i<docsMatiere.length; i++){
                                            var option = document.createElement("option");
                                            option.value = docsMatiere[i].matierenom;
                                            option.text = docsMatiere[i].matierenom;
                                            selectList.appendChild(option);
                                        }

                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('changecalendriernotes').style.display = 'block';
                                        document.getElementById('changematierenotes').style.display = 'block';
                                        document.getElementById('changetypenotes').style.display = 'none';
                                        document.getElementById('changeprogrammenotes').style.display = 'none';
                                        document.getElementById('changedatenotes').style.display = 'none';
                                        document.getElementById('changeobtenunotes').style.display = 'none';
                                        document.getElementById('changecoefficientnotes').style.display = 'none';
                                        document.getElementById('changenotes').style.display = 'none';

                                        /***************************
                                        seleditmatierenotes
                                        *****************************/
                                        document.getElementById('seleditmatierenotes').addEventListener('change', seleditmatierenotesChange);
                                        async function seleditmatierenotesChange(e){
                                            e.preventDefault();

                                            document.getElementById('seledittypenotes').value = '';

                                            var seleditmatierenotesStr = document.getElementById('seleditmatierenotes').value;
                                            if(!seleditmatierenotesStr){
                                                alert('Sélectionner une matière avant de continuer');
                                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                                document.getElementById('changecalendriernotes').style.display = 'block';
                                                document.getElementById('changematierenotes').style.display = 'block';
                                                document.getElementById('changetypenotes').style.display = 'none';
                                                document.getElementById('changeprogrammenotes').style.display = 'none';
                                                document.getElementById('changedatenotes').style.display = 'none';
                                                document.getElementById('changeobtenunotes').style.display = 'none';
                                                document.getElementById('changecoefficientnotes').style.display = 'none';
                                                document.getElementById('changenotes').style.display = 'none';
                                            }else{
                                                document.getElementById('editselectmatierenotes').value = seleditmatierenotesStr;
                                                document.getElementById('editmatiereprogrammenotes').innerText =seleditcalendriernotesStr +' '+seleditmatierenotesStr;
                                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                                document.getElementById('changecalendriernotes').style.display = 'block';
                                                document.getElementById('changematierenotes').style.display = 'block';
                                                document.getElementById('changetypenotes').style.display = 'block';
                                                document.getElementById('changeprogrammenotes').style.display = 'none';
                                                document.getElementById('changedatenotes').style.display = 'none';
                                                document.getElementById('changeobtenunotes').style.display = 'none';
                                                document.getElementById('changecoefficientnotes').style.display = 'none';
                                                document.getElementById('changenotes').style.display = 'none'; 
                                            }
                                        }
                                    }
                                } catch (error) {
                                    alert('Erreur Exécution Matières');
                                }
                            }
                        }
                    }
                } catch (error) {
                    alert('Erreur Exécution'); 
                }
            }
        }
        /**nav previous page button*/
        document.getElementById('preveditnotesBtn_1').addEventListener('click', preveditnotes1BtnChange);
        async function preveditnotes1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'block';
            document.getElementById('crudnotes').style.display = 'block';
            document.getElementById('addnotes').style.display = 'none';
            document.getElementById('editnotes').style.display = 'none';
            document.getElementById('deletenotes').style.display = 'none';
            document.getElementById('searchnotes').style.display = 'none';
            titre.innerHTML = 'Notes de Classe'; 
        }
        /***************************
        seledittypenotes
        *****************************/
        document.getElementById('seledittypenotes').addEventListener('change', seledittypenotesChange);
        async function seledittypenotesChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var editselectcalendriernotesStr = document.getElementById('editselectcalendriernotes').value;
            var editselectmatierenotesStr = document.getElementById('editselectmatierenotes').value;
            var seledittypenotesStr = document.getElementById('seledittypenotes').value;
            var statutNotesStr = 'Oui';
            var docsNotes = [];
            if(!seledittypenotesStr){
                alert('Sélectionner un type de Devoir Effectué avant de continuer');
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('changecalendriernotes').style.display = 'block';
                document.getElementById('changematierenotes').style.display = 'block';
                document.getElementById('changetypenotes').style.display = 'block';
                document.getElementById('changeprogrammenotes').style.display = 'none';
                document.getElementById('changedatenotes').style.display = 'none';
                document.getElementById('changeobtenunotes').style.display = 'none';
                document.getElementById('changecoefficientnotes').style.display = 'none';
                document.getElementById('changenotes').style.display = 'none'; 
            }else{
                try {
                    let resultNotes = await db.createIndex({
                        index: {
                            fields: [
                                'anneescolaire',
                                'matierenom',
                                'notedevoir',
                                'notestatut',
                                'inscritcode'
                            ]
                        }
                    });
                    resultNotes = await db.find({
                        selector: {
                            anneescolaire: editselectcalendriernotesStr,
                            matierenom: editselectmatierenotesStr,
                            notedevoir: seledittypenotesStr,
                            notestatut: statutNotesStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsNotes = resultNotes.docs;
                    var resultatNotes = docsNotes.length;
                    if(resultatNotes === 0){
                        alert('Aucun Résultat Disponible');
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('changecalendriernotes').style.display = 'block';
                        document.getElementById('changematierenotes').style.display = 'block';
                        document.getElementById('changetypenotes').style.display = 'block';
                        document.getElementById('changeprogrammenotes').style.display = 'none';
                        document.getElementById('changedatenotes').style.display = 'none';
                        document.getElementById('changeobtenunotes').style.display = 'none';
                        document.getElementById('changecoefficientnotes').style.display = 'none';
                        document.getElementById('changenotes').style.display = 'none'; 
                    }else{
                        updateEditNotesUIWithDocs(docsNotes);
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('changecalendriernotes').style.display = 'block';
                        document.getElementById('changematierenotes').style.display = 'block';
                        document.getElementById('changetypenotes').style.display = 'block';
                        document.getElementById('changeprogrammenotes').style.display = 'block';
                        document.getElementById('changedatenotes').style.display = 'none';
                        document.getElementById('changeobtenunotes').style.display = 'none';
                        document.getElementById('changecoefficientnotes').style.display = 'none';
                        document.getElementById('changenotes').style.display = 'none'; 
                    }
                } catch (error) {
                    alert('Erreur Exécution'); 
                }
            }
        }
        // Adds the document to the HTML First table.
        // If the document already exists in the table, it will be updated.
        function editProgrammeNotesTableDocToTable(doc, editProgrammeNotesTableBody){
            // Each row of the table has an id attribute set to the doc._id field.
            var tr = editProgrammeNotesTableBody.querySelector(`#${doc._id}`);

            if( !tr ) {
                tr = document.createElement('tr');
                tr.id = doc._id;
            }
        
            // Remove any existing table cells.
            while(tr.hasChildNodes()) {
                tr.removeChild(tr.childNodes[0]);
            }

            // Create new table cells for text fields.
            var tdEcheance = document.createElement('td');
            var dateEcheance = new Date(doc.noteecheance);
            tdEcheance.innerText = dateEcheance.toLocaleDateString();
            tr.appendChild(tdEcheance);

            // Create new table cells for text fields.
            var tdType = document.createElement('td');
            tdType.innerText = doc.notedevoir;
            tr.appendChild(tdType);

            // Create new table cells for text fields.
            var tdAction = document.createElement('td');
            var idNotes = doc._id;
            var button = document.createElement("button");
            button.id = "modifnotesBtn";
            button.type = 'button';
            button.innerHTML = 'Modification';
            button.onclick = async function(){
                var idNotesStr,inscritCodeStr,anneeScolaireStr,matiereNomStr,noteEcheanceStr,noteObtenueStr,
                noteCoefficientStr,noteEffectueStr,noteDateStr,noteStatutStr,noteReferenceStr = '';
                var effectueNotesStr = 'Oui';
                var statutNotesStr = 'Oui';
                var docsNotes = [];
                try {
                    let resultNotes = await db.createIndex({
                        index: {
                            fields: ['_id', 'noteeffectue', 'notestatut']
                        }
                    });
                    resultNotes = await db.find({
                        selector: {
                            _id: idNotes,
                            noteeffectue: effectueNotesStr,
                            notestatut: statutNotesStr
                        }
                    });
                    docsNotes = resultNotes.docs;
                    var resultatNotes = docsNotes.length;
                    if(resultatNotes === 0){
                        alert("Résultat Indisponible");
                    }else{
                        for(let i=0; i<docsNotes.length; i++){
                            idNotesStr = docsNotes[i]._id;
                            inscritCodeStr = docsNotes[i].inscritcode;
                            anneeScolaireStr = docsNotes[i].anneescolaire;
                            matiereNomStr = docsNotes[i].matierenom;
                            noteEcheanceStr = docsNotes[i].noteecheance;
                            noteObtenueStr = docsNotes[i].noteobtenue;
                            noteCoefficientStr = docsNotes[i].notecoefficient;
                            noteEffectueStr = docsNotes[i].noteeffectue;
                            noteDateStr = docsNotes[i].notedate;
                            noteStatutStr = docsNotes[i].notestatut;
                            noteReferenceStr = docsNotes[i].notereference;
                        }
                        
                        document.getElementById('editidnotes').value = idNotesStr;
                        document.getElementById('editcodeusernotes').value = inscritCodeStr;
                        document.getElementById('editselectcalendriernotes').value = anneeScolaireStr;
                        document.getElementById('editselectmatierenotes').value = matiereNomStr;
                        document.getElementById('editecheancenotes').value = noteEcheanceStr;
                        document.getElementById('editobtenuenotes').value = noteObtenueStr;
                        document.getElementById('editcoefficientnotes').value = noteCoefficientStr;
                        document.getElementById('editeffectuenotes').value = noteEffectueStr;
                        document.getElementById('editdatenotes').value = noteDateStr;
                        document.getElementById('editstatutnotes').value = noteStatutStr;
                        document.getElementById('editidreferencenotes').value = noteReferenceStr;

                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('changecalendriernotes').style.display = 'block';
                        document.getElementById('changematierenotes').style.display = 'block';
                        document.getElementById('changetypenotes').style.display = 'block';
                        document.getElementById('changeprogrammenotes').style.display = 'block';
                        document.getElementById('changedatenotes').style.display = 'block';
                        document.getElementById('changeobtenunotes').style.display = 'block';
                        document.getElementById('changecoefficientnotes').style.display = 'block';
                        document.getElementById('changenotes').style.display = 'block';
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
            tdAction.appendChild(button);
            tr.appendChild(tdAction);

            // Add the row to the top of the table.
            editProgrammeNotesTableBody.insertBefore(tr, editProgrammeNotesTableBody.nextSibling);
        }
        /**Clear and update the UI to display the docs.*/
        function updateEditNotesUIWithDocs(docs) {
            var editProgrammeNotesTableBody = document.getElementById('editProgrammeNotesTableBody');
            
            // Remove all child nodes from the table
            while(editProgrammeNotesTableBody.hasChildNodes()) {
                editProgrammeNotesTableBody.removeChild(editProgrammeNotesTableBody.childNodes[0]);
            }
            
            // Add the new documents to the table
            for(var i=0; i<docs.length; i++) {
                var doc = docs[i];
                editProgrammeNotesTableDocToTable(doc, editProgrammeNotesTableBody);
            }
        }
        /***************************
        modifiernotesBtn
        *****************************/
        document.getElementById('modifiernotesBtn').addEventListener('click', modifiernotesBtnChange);
        async function modifiernotesBtnChange(e){
            e.preventDefault();

            var editidnotesStr = document.getElementById('editidnotes').value;
            var editcodeusernotesStr = document.getElementById('editcodeusernotes').value;
            var editselectcalendriernotesStr = document.getElementById('editselectcalendriernotes').value;
            var editselectmatierenotesStr = document.getElementById('editselectmatierenotes').value;
            var seledittypenotesStr = document.getElementById('seledittypenotes').value;
            var editecheancenotesStr = document.getElementById('editecheancenotes').value;
            var editobtenuenotesStr = document.getElementById('editobtenuenotes').value;
            var editcoefficientnotesStr = document.getElementById('editcoefficientnotes').value;
            var editeffectuenotesStr = document.getElementById('editeffectuenotes').value;
            var editdatenotesStr = document.getElementById('editdatenotes').value;
            var editstatutnotesStr = document.getElementById('editstatutnotes').value;
            var editidreferencenotesStr = document.getElementById('editidreferencenotes').value;

            if(!editcodeusernotesStr){
                alert("Identifiez-vous avant de continuer");
            }else if(!editobtenuenotesStr){
                alert('Saisir Note Obtenue');
            }else if(!editcoefficientnotesStr){
                alert('Saisir Coefficient Note');
            }else{
                try {
                    // Retrieve the document to get the latest revision
                    var id = editidnotesStr;
                    var doc = await db.get(id);
                    // Make changes
                    doc.inscritcode = editcodeusernotesStr;
                    doc.anneescolaire = editselectcalendriernotesStr;
                    doc.matierenom = editselectmatierenotesStr;
                    doc.notedevoir = seledittypenotesStr;
                    doc.noteecheance = editecheancenotesStr;
                    doc.noteobtenue = editobtenuenotesStr;
                    doc.notecoefficient = editcoefficientnotesStr;
                    doc.noteeffectue = editeffectuenotesStr;
                    doc.notedate = editdatenotesStr;
                    doc.notestatut = editstatutnotesStr;
                    doc.notereference = editidreferencenotesStr;
                    // Put the document, saving it as a new revision
                    var response = await db.put(doc);
                    // Get the document we change and update the UI.
                    var docEdit = await db.get(response.id);
                    if(docEdit !== ''){
                        alert("Note Modifiée avec Succès. Mettre à jour Calcul Moyenne Matière");
                        document.getElementById('editidnotes').value = '';
                        document.getElementById('editcodeusernotes').value = '';
                        document.getElementById('editselectcalendriernotes').value = '';
                        document.getElementById('editselectmatierenotes').value = '';
                        document.getElementById('seledittypenotes').value = '';
                        document.getElementById('editecheancenotes').value = '';
                        document.getElementById('editobtenuenotes').value = '';
                        document.getElementById('editcoefficientnotes').value = '';
                        document.getElementById('editeffectuenotes').value = '';
                        document.getElementById('editdatenotes').value = '';
                        document.getElementById('editstatutnotes').value = '';
                        document.getElementById('editidreferencenotes').value = '';

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'none';
                        document.getElementById('programme').style.display = 'none';
                        document.getElementById('notes').style.display = 'block';
                        document.getElementById('crudnotes').style.display = 'block';
                        document.getElementById('addnotes').style.display = 'none';
                        document.getElementById('editnotes').style.display = 'none';
                        document.getElementById('deletenotes').style.display = 'none';
                        document.getElementById('searchnotes').style.display = 'none';
                        titre.innerHTML = 'Notes de Classe'; 
                    }else{
                        alert("Erreur Modification. Reprendre");
                        document.getElementById('editidnotes').value = '';
                        document.getElementById('editcodeusernotes').value = '';
                        document.getElementById('editselectcalendriernotes').value = '';
                        document.getElementById('editselectmatierenotes').value = '';
                        document.getElementById('seledittypenotes').value = '';
                        document.getElementById('editecheancenotes').value = '';
                        document.getElementById('editobtenuenotes').value = '';
                        document.getElementById('editcoefficientnotes').value = '';
                        document.getElementById('editeffectuenotes').value = '';
                        document.getElementById('editdatenotes').value = '';
                        document.getElementById('editstatutnotes').value = '';
                        document.getElementById('editidreferencenotes').value = '';

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'none';
                        document.getElementById('programme').style.display = 'none';
                        document.getElementById('notes').style.display = 'block';
                        document.getElementById('crudnotes').style.display = 'block';
                        document.getElementById('addnotes').style.display = 'none';
                        document.getElementById('editnotes').style.display = 'none';
                        document.getElementById('deletenotes').style.display = 'none';
                        document.getElementById('searchnotes').style.display = 'none';
                        titre.innerHTML = 'Notes de Classe'; 
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }

        /***************************
        deletenotesBtn
        *****************************/
        document.getElementById('deletenotesBtn').addEventListener('click', deletenotesBtnChange);
        async function deletenotesBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutanneeStr = 'Oui';
            var statutmatiereStr = 'Oui';

            var docsAnnee = [];
            var docsMatiere = [];

            if(!userCodeStr){
                alert('Connectez-vous à votre Compte avant de continuer');
            }else{
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: ['anneestatut', 'inscritcode']
                        }
                    });
                    result = await db.find({
                        selector: {
                            anneestatut: statutanneeStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsAnnee  = result.docs;
                    var resultatAnnee = docsAnnee.length;
                    if(resultatAnnee === 0){
                        alert('Aucun Calendrier Année Scolaire Enregistré');
                    }else{
                        var divClear;
                        var divModifier;
                        divClear = document.getElementById('deletecalendriernotes');
                        while(divClear.hasChildNodes()) {
                            divClear.removeChild(divClear.childNodes[0]);
                        }
                        divModifier = document.getElementById('deletecalendriernotes');
                        //Create and append select list
                        var selectList = document.createElement("select");
                        selectList.id = "seldeletecalendriernotes";
                        divModifier.appendChild(selectList);
                        //Create and append the options
                        var option_null = document.createElement("option");
                        option_null.value = '';
                        option_null.text = 'Sélectionner Calendrier';
                        selectList.appendChild(option_null);
                        //Create and append the options
                        for (var i = 0; i<docsAnnee.length; i++){
                            var option = document.createElement("option");
                            option.value = docsAnnee[i].anneescolaire;
                            option.text = docsAnnee[i].anneescolaire;
                            selectList.appendChild(option);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'none';
                        document.getElementById('programme').style.display = 'none';
                        document.getElementById('notes').style.display = 'block';
                        document.getElementById('crudnotes').style.display = 'none';
                        document.getElementById('addnotes').style.display = 'none';
                        document.getElementById('editnotes').style.display = 'none';
                        document.getElementById('deletenotes').style.display = 'block';
                        document.getElementById('searchnotes').style.display = 'none';
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('cancelcalendriernotes').style.display = 'block';
                        document.getElementById('cancelmatierenotes').style.display = 'none';
                        document.getElementById('canceltypenotes').style.display = 'none';
                        document.getElementById('cancelprogrammenotes').style.display = 'none';
                        document.getElementById('canceldatenotes').style.display = 'none';
                        document.getElementById('cancelobtenunotes').style.display = 'none';
                        document.getElementById('cancelcoefficientnotes').style.display = 'none';
                        document.getElementById('cancelnotes').style.display = 'none';
                        titre.innerHTML = 'Supprimer Note de Classe';

                        /***************************
                        seldeletecalendriernotes
                        *****************************/
                        document.getElementById('seldeletecalendriernotes').addEventListener('change', seldeletecalendriernotesChange);
                        async function seldeletecalendriernotesChange(e){
                            e.preventDefault();

                            var seldeletecalendriernotesStr = document.getElementById('seldeletecalendriernotes').value;
                            if(!seldeletecalendriernotesStr){
                                alert('Sélectionner un calendrier scolaire avant de continuer');
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('cancelcalendriernotes').style.display = 'block';
                                document.getElementById('cancelmatierenotes').style.display = 'none';
                                document.getElementById('canceltypenotes').style.display = 'none';
                                document.getElementById('cancelprogrammenotes').style.display = 'none';
                                document.getElementById('canceldatenotes').style.display = 'none';
                                document.getElementById('cancelobtenunotes').style.display = 'none';
                                document.getElementById('cancelcoefficientnotes').style.display = 'none';
                                document.getElementById('cancelnotes').style.display = 'none';
                            }else{
                                document.getElementById('deleteselectcalendriernotes').value = seldeletecalendriernotesStr;
                                try {
                                    let result = await db.createIndex({
                                        index: {
                                            fields: ['matierestatut', 'inscritcode']
                                        }
                                    });
                                    result = await db.find({
                                        selector: {
                                            matierestatut: statutmatiereStr,
                                            inscritcode: userCodeStr
                                        }
                                    });
                                    docsMatiere  = result.docs;
                                    var resultatMatiere = docsMatiere.length;
                                    if(resultatMatiere === 0){
                                        alert("Matières Classe non encore enregistrées");
                                    }else{
                                        var divClear;
                                        var divModifier;
                                        divClear = document.getElementById('deletematierenotes');
                                        while(divClear.hasChildNodes()) {
                                            divClear.removeChild(divClear.childNodes[0]);
                                        }
                                        divModifier = document.getElementById('deletematierenotes');
                                        //Create and append select list
                                        var selectList = document.createElement("select");
                                        selectList.id = "seldeletematierenotes";
                                        divModifier.appendChild(selectList);
                                        //Create and append the options
                                        var option_null = document.createElement("option");
                                        option_null.value = '';
                                        option_null.text = 'Sélectionner Matière';
                                        selectList.appendChild(option_null);
                                        //Create and append the options
                                        for (var i = 0; i<docsMatiere.length; i++){
                                            var option = document.createElement("option");
                                            option.value = docsMatiere[i].matierenom;
                                            option.text = docsMatiere[i].matierenom;
                                            selectList.appendChild(option);
                                        }

                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('cancelcalendriernotes').style.display = 'block';
                                        document.getElementById('cancelmatierenotes').style.display = 'block';
                                        document.getElementById('canceltypenotes').style.display = 'none';
                                        document.getElementById('cancelprogrammenotes').style.display = 'none';
                                        document.getElementById('canceldatenotes').style.display = 'none';
                                        document.getElementById('cancelobtenunotes').style.display = 'none';
                                        document.getElementById('cancelcoefficientnotes').style.display = 'none';
                                        document.getElementById('cancelnotes').style.display = 'none';

                                        /***************************
                                        seldeletematierenotes
                                        *****************************/
                                        document.getElementById('seldeletematierenotes').addEventListener('change', seldeletematierenotesChange);
                                        async function seldeletematierenotesChange(e){
                                            e.preventDefault();

                                            document.getElementById('seldeletetypenotes').value = '';

                                            var seldeletematierenotesStr = document.getElementById('seldeletematierenotes').value;
                                            if(!seldeletematierenotesStr){
                                                alert('Sélectionner une matière avant de continuer');
                                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                                document.getElementById('cancelcalendriernotes').style.display = 'block';
                                                document.getElementById('cancelmatierenotes').style.display = 'block';
                                                document.getElementById('canceltypenotes').style.display = 'none';
                                                document.getElementById('cancelprogrammenotes').style.display = 'none';
                                                document.getElementById('canceldatenotes').style.display = 'none';
                                                document.getElementById('cancelobtenunotes').style.display = 'none';
                                                document.getElementById('cancelcoefficientnotes').style.display = 'none';
                                                document.getElementById('cancelnotes').style.display = 'none';
                                            }else{
                                                document.getElementById('deleteselectmatierenotes').value = seldeletematierenotesStr;
                                                document.getElementById('deletematiereprogrammenotes').innerText =seldeletecalendriernotesStr +' '+seldeletematierenotesStr;
                                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                                document.getElementById('cancelcalendriernotes').style.display = 'block';
                                                document.getElementById('cancelmatierenotes').style.display = 'block';
                                                document.getElementById('canceltypenotes').style.display = 'block';
                                                document.getElementById('cancelprogrammenotes').style.display = 'none';
                                                document.getElementById('canceldatenotes').style.display = 'none';
                                                document.getElementById('cancelobtenunotes').style.display = 'none';
                                                document.getElementById('cancelcoefficientnotes').style.display = 'none';
                                                document.getElementById('cancelnotes').style.display = 'none';
                                            }
                                        }
                                    }
                                } catch (error) {
                                    alert('Erreur Exécution');
                                }
                            }
                        }
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }
        /**nav previous page button*/
        document.getElementById('prevdeletenotesBtn_1').addEventListener('click', prevdeletenotes1BtnChange);
        async function prevdeletenotes1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'block';
            document.getElementById('crudnotes').style.display = 'block';
            document.getElementById('addnotes').style.display = 'none';
            document.getElementById('editnotes').style.display = 'none';
            document.getElementById('deletenotes').style.display = 'none';
            document.getElementById('searchnotes').style.display = 'none';
            titre.innerHTML = 'Notes de Classe'; 
        }
        /***************************
        seldeletetypenotes
        *****************************/
        document.getElementById('seldeletetypenotes').addEventListener('change', seldeletetypenotesChange);
        async function seldeletetypenotesChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var deleteselectcalendriernotesStr = document.getElementById('deleteselectcalendriernotes').value;
            var deleteselectmatierenotesStr = document.getElementById('deleteselectmatierenotes').value;
            var seldeletetypenotesStr = document.getElementById('seldeletetypenotes').value;
            var statutNotesStr = 'Oui';
            var docsNotes = [];
            if(!seldeletetypenotesStr){
                alert('Sélectionner un type de Devoir Effectué avant de continuer');
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('cancelcalendriernotes').style.display = 'block';
                document.getElementById('cancelmatierenotes').style.display = 'block';
                document.getElementById('canceltypenotes').style.display = 'block';
                document.getElementById('cancelprogrammenotes').style.display = 'none';
                document.getElementById('canceldatenotes').style.display = 'none';
                document.getElementById('cancelobtenunotes').style.display = 'none';
                document.getElementById('cancelcoefficientnotes').style.display = 'none';
                document.getElementById('cancelnotes').style.display = 'none';
            }else{
                try {
                    let resultNotes = await db.createIndex({
                        index: {
                            fields: [
                                'anneescolaire',
                                'matierenom',
                                'notedevoir',
                                'notestatut',
                                'inscritcode'
                            ]
                        }
                    });
                    resultNotes = await db.find({
                        selector: {
                            anneescolaire: deleteselectcalendriernotesStr,
                            matierenom: deleteselectmatierenotesStr,
                            notedevoir: seldeletetypenotesStr,
                            notestatut: statutNotesStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsNotes = resultNotes.docs;
                    var resultatNotes = docsNotes.length;
                    if(resultatNotes === 0){
                        alert('Aucun Résultat Disponible');
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('cancelcalendriernotes').style.display = 'block';
                        document.getElementById('cancelmatierenotes').style.display = 'block';
                        document.getElementById('canceltypenotes').style.display = 'block';
                        document.getElementById('cancelprogrammenotes').style.display = 'none';
                        document.getElementById('canceldatenotes').style.display = 'none';
                        document.getElementById('cancelobtenunotes').style.display = 'none';
                        document.getElementById('cancelcoefficientnotes').style.display = 'none';
                        document.getElementById('cancelnotes').style.display = 'none';
                    }else{
                        updateDeleteNotesUIWithDocs(docsNotes);
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('cancelcalendriernotes').style.display = 'block';
                        document.getElementById('cancelmatierenotes').style.display = 'block';
                        document.getElementById('canceltypenotes').style.display = 'block';
                        document.getElementById('cancelprogrammenotes').style.display = 'block';
                        document.getElementById('canceldatenotes').style.display = 'none';
                        document.getElementById('cancelobtenunotes').style.display = 'none';
                        document.getElementById('cancelcoefficientnotes').style.display = 'none';
                        document.getElementById('cancelnotes').style.display = 'none';
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }
        // Adds the document to the HTML First table.
        // If the document already exists in the table, it will be updated.
        function deleteProgrammeNotesTableDocToTable(doc, deleteProgrammeNotesTableBody){
            // Each row of the table has an id attribute set to the doc._id field.
            var tr = deleteProgrammeNotesTableBody.querySelector(`#${doc._id}`);

            if( !tr ) {
                tr = document.createElement('tr');
                tr.id = doc._id;
            }
        
            // Remove any existing table cells.
            while(tr.hasChildNodes()) {
                tr.removeChild(tr.childNodes[0]);
            }

            // Create new table cells for text fields.
            var tdEcheance = document.createElement('td');
            var dateEcheance = new Date(doc.noteecheance);
            tdEcheance.innerText = dateEcheance.toLocaleDateString();
            tr.appendChild(tdEcheance);

            // Create new table cells for text fields.
            var tdType = document.createElement('td');
            tdType.innerText = doc.notedevoir;
            tr.appendChild(tdType);

            // Create new table cells for text fields.
            var tdAction = document.createElement('td');
            var idNotes = doc._id;
            var button = document.createElement("button");
            button.id = "nonnotesBtn";
            button.type = 'button';
            button.innerHTML = 'Suppression';
            button.onclick = async function(){
                var idNotesStr,inscritCodeStr,anneeScolaireStr,matiereNomStr,noteEcheanceStr,noteObtenueStr,
                noteCoefficientStr,noteEffectueStr,noteDateStr,noteStatutStr,noteReferenceStr = '';
                var effectueNotesStr = 'Oui';
                var statutNotesStr = 'Oui';
                var docsNotes = [];
                try {
                    let resultNotes = await db.createIndex({
                        index: {
                            fields: ['_id', 'noteeffectue', 'notestatut']
                        }
                    });
                    resultNotes = await db.find({
                        selector: {
                            _id: idNotes,
                            noteeffectue: effectueNotesStr,
                            notestatut: statutNotesStr
                        }
                    });
                    docsNotes = resultNotes.docs;
                    var resultatNotes = docsNotes.length;
                    if(resultatNotes === 0){
                        alert("Résultat Indisponible");
                    }else{
                        for(let i=0; i<docsNotes.length; i++){
                            idNotesStr = docsNotes[i]._id;
                            inscritCodeStr = docsNotes[i].inscritcode;
                            anneeScolaireStr = docsNotes[i].anneescolaire;
                            matiereNomStr = docsNotes[i].matierenom;
                            noteEcheanceStr = docsNotes[i].noteecheance;
                            noteObtenueStr = docsNotes[i].noteobtenue;
                            noteCoefficientStr = docsNotes[i].notecoefficient;
                            noteEffectueStr = docsNotes[i].noteeffectue;
                            noteDateStr = docsNotes[i].notedate;
                            noteStatutStr = docsNotes[i].notestatut;
                            noteReferenceStr = docsNotes[i].notereference;
                        }

                        document.getElementById('deleteidnotes').value = idNotesStr;
                        document.getElementById('deletecodeusernotes').value = inscritCodeStr;
                        document.getElementById('deleteselectcalendriernotes').value = anneeScolaireStr;
                        document.getElementById('deleteselectmatierenotes').value = matiereNomStr;
                        document.getElementById('deleteecheancenotes').value = noteEcheanceStr;
                        document.getElementById('deleteobtenuenotes').value = noteObtenueStr;
                        document.getElementById('deletecoefficientnotes').value = noteCoefficientStr;
                        document.getElementById('deleteeffectuenotes').value = noteEffectueStr;
                        document.getElementById('deletedatenotes').value = noteDateStr;
                        document.getElementById('deletestatutnotes').value = noteStatutStr;
                        document.getElementById('deleteidreferencenotes').value = noteReferenceStr;

                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('cancelcalendriernotes').style.display = 'block';
                        document.getElementById('cancelmatierenotes').style.display = 'block';
                        document.getElementById('canceltypenotes').style.display = 'block';
                        document.getElementById('cancelprogrammenotes').style.display = 'block';
                        document.getElementById('canceldatenotes').style.display = 'block';
                        document.getElementById('cancelobtenunotes').style.display = 'block';
                        document.getElementById('cancelcoefficientnotes').style.display = 'block';
                        document.getElementById('cancelnotes').style.display = 'block';
                    }
                } catch (error) {
                   alert('Erreur Exécution'); 
                }
            }
            tdAction.appendChild(button);
            tr.appendChild(tdAction);

            // Add the row to the top of the table.
            deleteProgrammeNotesTableBody.insertBefore(tr, deleteProgrammeNotesTableBody.nextSibling);
        }
        /**Clear and update the UI to display the docs.*/
        function updateDeleteNotesUIWithDocs(docs) {
            var deleteProgrammeNotesTableBody = document.getElementById('deleteProgrammeNotesTableBody');
            
            // Remove all child nodes from the table
            while(deleteProgrammeNotesTableBody.hasChildNodes()) {
                deleteProgrammeNotesTableBody.removeChild(deleteProgrammeNotesTableBody.childNodes[0]);
            }
            
            // Add the new documents to the table
            for(var i=0; i<docs.length; i++) {
                var doc = docs[i];
                deleteProgrammeNotesTableDocToTable(doc, deleteProgrammeNotesTableBody);
            }
        }
        /***************************
        supprimernotesBtn
        *****************************/
        document.getElementById('supprimernotesBtn').addEventListener('click', supprimernotesBtnChange);
        async function supprimernotesBtnChange(e){
            e.preventDefault();

            var divClearModal = document.getElementById('modaloutnotes');
            let divModal = document.createElement('div');
            divModal.innerHTML =
            `<div class="modal">
                <p>Êtes-vous sûr de Supprimer ?</p>
                <button class="btn btn--alt" id="ouiHandlerNotesBtn">Oui</button>
                <button class="btn" id="nonHandlerNotesBtn">Non</button>
            </div>`;
            divClearModal.appendChild(divModal);

            var divClearBackdrop = document.getElementById('backdropoutnotes');
            let divBack = document.createElement('div');
            divBack.innerHTML =
            `<div class="backdrop" id="backdropHandlerNotesBtn"></div>`;
            divClearBackdrop.appendChild(divBack);

            document.getElementById('ouiHandlerNotesBtn').addEventListener('click', ouiHandlerNotesBtnChange);
            async function ouiHandlerNotesBtnChange(e){
                e.preventDefault();

                var deleteidnotesStr = document.getElementById('deleteidnotes').value;
                var deletecodeusernotesStr = document.getElementById('deletecodeusernotes').value;
                var deleteselectcalendriernotesStr = document.getElementById('deleteselectcalendriernotes').value;
                var deleteselectmatierenotesStr = document.getElementById('deleteselectmatierenotes').value;
                var seldeletetypenotesStr = document.getElementById('seldeletetypenotes').value;
                var deleteecheancenotesStr = document.getElementById('deleteecheancenotes').value;
                var deleteobtenuenotesStr = document.getElementById('deleteobtenuenotes').value;
                var deletecoefficientnotesStr = document.getElementById('deletecoefficientnotes').value;
                var deleteeffectuenotesStr = document.getElementById('deleteeffectuenotes').value;
                var deletedatenotesStr = document.getElementById('deletedatenotes').value;
                var deletestatutnotesStr = 'Non';
                var deleteidreferencenotesStr = document.getElementById('deleteidreferencenotes').value;

                if(!deletecodeusernotesStr){
                    alert("Identifiez-vous avant de continuer");
                }else if(!deleteobtenuenotesStr){
                    alert('Saisir Note Obtenue');
                }else if(!deletecoefficientnotesStr){
                    alert('Saisir Coefficient Note');
                }else{
                    try {
                        // Retrieve the document to get the latest revision
                        var id = deleteidnotesStr;
                        var doc = await db.get(id);
                        // Make changes
                        doc.inscritcode = deletecodeusernotesStr;
                        doc.anneescolaire = deleteselectcalendriernotesStr;
                        doc.matierenom = deleteselectmatierenotesStr;
                        doc.notedevoir = seldeletetypenotesStr;
                        doc.noteecheance = deleteecheancenotesStr;
                        doc.noteobtenue = deleteobtenuenotesStr;
                        doc.notecoefficient = deletecoefficientnotesStr;
                        doc.noteeffectue = deleteeffectuenotesStr;
                        doc.notedate = deletedatenotesStr;
                        doc.notestatut = deletestatutnotesStr;
                        doc.notereference = deleteidreferencenotesStr;
                        // Put the document, saving it as a new revision
                        var response = await db.put(doc);
                        // Get the document we change and update the UI.
                        var docEdit = await db.get(response.id);
                        if(docEdit !== ''){
                            alert("Note Supprimée avec Succès. Récupération Possible dans Consulter Note Classe");
                            var divClearModal = document.getElementById('modaloutnotes');
                            while(divClearModal.hasChildNodes()) {
                                divClearModal.removeChild(divClearModal.childNodes[0]);
                            }

                            var divClearBackdrop = document.getElementById('backdropoutnotes');
                            while(divClearBackdrop.hasChildNodes()) {
                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                            }

                            document.getElementById('deleteidnotes').value = '';
                            document.getElementById('deletecodeusernotes').value = '';
                            document.getElementById('deleteselectcalendriernotes').value = '';
                            document.getElementById('deleteselectmatierenotes').value = '';
                            document.getElementById('seldeletetypenotes').value = '';
                            document.getElementById('deleteecheancenotes').value = '';
                            document.getElementById('deleteobtenuenotes').value = '';
                            document.getElementById('deletecoefficientnotes').value = '';
                            document.getElementById('deleteeffectuenotes').value = '';
                            document.getElementById('deletedatenotes').value = '';
                            document.getElementById('deletestatutnotes').value = '';
                            document.getElementById('deleteidreferencenotes').value = '';

                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');

                            document.getElementById('demarrage').style.display = 'none';
                            document.getElementById('tableaubord').style.display = 'none';
                            document.getElementById('dashboard').style.display = 'none';
                            document.getElementById('configurations').style.display = 'none';
                            document.getElementById('configurer').style.display = 'none';
                            document.getElementById('emploidutemps').style.display = 'none';
                            document.getElementById('exercice').style.display = 'none';
                            document.getElementById('pratique').style.display = 'none';
                            document.getElementById('tache').style.display = 'none';
                            document.getElementById('revision').style.display = 'none';
                            document.getElementById('recherche').style.display = 'none';
                            document.getElementById('programme').style.display = 'none';
                            document.getElementById('notes').style.display = 'block';
                            document.getElementById('crudnotes').style.display = 'block';
                            document.getElementById('addnotes').style.display = 'none';
                            document.getElementById('editnotes').style.display = 'none';
                            document.getElementById('deletenotes').style.display = 'none';
                            document.getElementById('searchnotes').style.display = 'none';
                            titre.innerHTML = 'Notes de Classe'; 
                        }else{
                            alert("Impossible Supprimer Note");
                            var divClearModal = document.getElementById('modaloutnotes');
                            while(divClearModal.hasChildNodes()) {
                                divClearModal.removeChild(divClearModal.childNodes[0]);
                            }

                            var divClearBackdrop = document.getElementById('backdropoutnotes');
                            while(divClearBackdrop.hasChildNodes()) {
                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                            }

                            document.getElementById('deleteidnotes').value = '';
                            document.getElementById('deletecodeusernotes').value = '';
                            document.getElementById('deleteselectcalendriernotes').value = '';
                            document.getElementById('deleteselectmatierenotes').value = '';
                            document.getElementById('seldeletetypenotes').value = '';
                            document.getElementById('deleteecheancenotes').value = '';
                            document.getElementById('deleteobtenuenotes').value = '';
                            document.getElementById('deletecoefficientnotes').value = '';
                            document.getElementById('deleteeffectuenotes').value = '';
                            document.getElementById('deletedatenotes').value = '';
                            document.getElementById('deletestatutnotes').value = '';
                            document.getElementById('deleteidreferencenotes').value = '';

                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');

                            document.getElementById('demarrage').style.display = 'none';
                            document.getElementById('tableaubord').style.display = 'none';
                            document.getElementById('dashboard').style.display = 'none';
                            document.getElementById('configurations').style.display = 'none';
                            document.getElementById('configurer').style.display = 'none';
                            document.getElementById('emploidutemps').style.display = 'none';
                            document.getElementById('exercice').style.display = 'none';
                            document.getElementById('pratique').style.display = 'none';
                            document.getElementById('tache').style.display = 'none';
                            document.getElementById('revision').style.display = 'none';
                            document.getElementById('recherche').style.display = 'none';
                            document.getElementById('programme').style.display = 'none';
                            document.getElementById('notes').style.display = 'block';
                            document.getElementById('crudnotes').style.display = 'block';
                            document.getElementById('addnotes').style.display = 'none';
                            document.getElementById('editnotes').style.display = 'none';
                            document.getElementById('deletenotes').style.display = 'none';
                            document.getElementById('searchnotes').style.display = 'none';
                            titre.innerHTML = 'Notes de Classe'; 
                        }
                    } catch (error) {
                        alert('Erreur Exécution');
                    }
                }
            }
            document.getElementById('nonHandlerNotesBtn').addEventListener('click', nonHandlerNotesBtnChange);
            async function nonHandlerNotesBtnChange(e){
                e.preventDefault();

                var divClearModal = document.getElementById('modaloutnotes');
                while(divClearModal.hasChildNodes()) {
                    divClearModal.removeChild(divClearModal.childNodes[0]);
                }

                var divClearBackdrop = document.getElementById('backdropoutnotes');
                while(divClearBackdrop.hasChildNodes()) {
                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                }
            }
            document.getElementById('backdropHandlerNotesBtn').addEventListener('click', backdropHandlerNotesBtnChange);
            async function backdropHandlerNotesBtnChange(e){
                e.preventDefault();

                var divClearModal = document.getElementById('modaloutnotes');
                while(divClearModal.hasChildNodes()) {
                    divClearModal.removeChild(divClearModal.childNodes[0]);
                }

                var divClearBackdrop = document.getElementById('backdropoutnotes');
                while(divClearBackdrop.hasChildNodes()) {
                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                }
            }
        }

        /***************************
        searchnotesBtn
        *****************************/
        document.getElementById('searchnotesBtn').addEventListener('click', searchnotesBtnChange);
        async function searchnotesBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutanneeStr = 'Oui';
            var statutmatiereStr = 'Oui';

            var docsAnnee = [];
            var docsMatiere = [];

            if(!userCodeStr){
                alert('Connectez-vous à votre Compte avant de continuer');
            }else{
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: ['anneestatut', 'inscritcode']
                        }
                    });
                    result = await db.find({
                        selector: {
                            anneestatut: statutanneeStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsAnnee  = result.docs;
                    var resultatAnnee = docsAnnee.length;
                    if(resultatAnnee === 0){
                        alert('Aucun Calendrier Année Scolaire Enregistré');
                    }else{
                        var divClear;
                        var divModifier;
                        divClear = document.getElementById('searchcalendriernotes');
                        while(divClear.hasChildNodes()) {
                            divClear.removeChild(divClear.childNodes[0]);
                        }
                        divModifier = document.getElementById('searchcalendriernotes');
                        //Create and append select list
                        var selectList = document.createElement("select");
                        selectList.id = "selsearchcalendriernotes";
                        divModifier.appendChild(selectList);
                        //Create and append the options
                        var option_null = document.createElement("option");
                        option_null.value = '';
                        option_null.text = 'Sélectionner Calendrier';
                        selectList.appendChild(option_null);
                        //Create and append the options
                        for (var i = 0; i<docsAnnee.length; i++){
                            var option = document.createElement("option");
                            option.value = docsAnnee[i].anneescolaire;
                            option.text = docsAnnee[i].anneescolaire;
                            selectList.appendChild(option);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'none';
                        document.getElementById('programme').style.display = 'none';
                        document.getElementById('notes').style.display = 'block';
                        document.getElementById('crudnotes').style.display = 'none';
                        document.getElementById('addnotes').style.display = 'none';
                        document.getElementById('editnotes').style.display = 'none';
                        document.getElementById('deletenotes').style.display = 'none';
                        document.getElementById('searchnotes').style.display = 'block';
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('viewcalendriernotes').style.display = 'block';
                        document.getElementById('viewmatierenotes').style.display = 'none';
                        document.getElementById('viewtypenotes').style.display = 'none';
                        document.getElementById('viewstatutnotes').style.display = 'none';
                        document.getElementById('viewprogrammenotes').style.display = 'none';
                        document.getElementById('viewconsulternotes').style.display = 'none';
                        titre.innerHTML = 'Consulter Note de Classe';

                        /***************************
                        selsearchcalendriernotes
                        *****************************/
                        document.getElementById('selsearchcalendriernotes').addEventListener('change', selsearchcalendriernotesChange);
                        async function selsearchcalendriernotesChange(e){
                            e.preventDefault();

                            var selsearchcalendriernotesStr = document.getElementById('selsearchcalendriernotes').value;
                            if(!selsearchcalendriernotesStr){
                                alert('Sélectionner un calendrier scolaire avant de continuer');
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendriernotes').style.display = 'block';
                                document.getElementById('viewmatierenotes').style.display = 'none';
                                document.getElementById('viewtypenotes').style.display = 'none';
                                document.getElementById('viewstatutnotes').style.display = 'none';
                                document.getElementById('viewprogrammenotes').style.display = 'none';
                                document.getElementById('viewconsulternotes').style.display = 'none';
                            }else{
                                document.getElementById('searchselectcalendriernotes').value = selsearchcalendriernotesStr;
                                try {
                                    let result = await db.createIndex({
                                        index: {
                                            fields: ['matierestatut', 'inscritcode']
                                        }
                                    });
                                    result = await db.find({
                                        selector: {
                                            matierestatut: statutmatiereStr,
                                            inscritcode: userCodeStr
                                        }
                                    });
                                    docsMatiere  = result.docs;
                                    var resultatMatiere = docsMatiere.length;
                                    if(resultatMatiere === 0){
                                        alert("Matières Classe non encore enregistrées");
                                    }else{
                                        var divClear;
                                        var divModifier;
                                        divClear = document.getElementById('searchmatierenotes');
                                        while(divClear.hasChildNodes()) {
                                            divClear.removeChild(divClear.childNodes[0]);
                                        }
                                        divModifier = document.getElementById('searchmatierenotes');
                                        //Create and append select list
                                        var selectList = document.createElement("select");
                                        selectList.id = "selsearchmatierenotes";
                                        divModifier.appendChild(selectList);
                                        //Create and append the options
                                        var option_null = document.createElement("option");
                                        option_null.value = '';
                                        option_null.text = 'Sélectionner Matière';
                                        selectList.appendChild(option_null);
                                        //Create and append the options
                                        for (var i = 0; i<docsMatiere.length; i++){
                                            var option = document.createElement("option");
                                            option.value = docsMatiere[i].matierenom;
                                            option.text = docsMatiere[i].matierenom;
                                            selectList.appendChild(option);
                                        }

                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('viewcalendriernotes').style.display = 'block';
                                        document.getElementById('viewmatierenotes').style.display = 'block';
                                        document.getElementById('viewtypenotes').style.display = 'none';
                                        document.getElementById('viewstatutnotes').style.display = 'none';
                                        document.getElementById('viewprogrammenotes').style.display = 'none';
                                        document.getElementById('viewconsulternotes').style.display = 'none';

                                        /***************************
                                        seleditmatierenotes
                                        *****************************/
                                        document.getElementById('selsearchmatierenotes').addEventListener('change', selsearchmatierenotesChange);
                                        async function selsearchmatierenotesChange(e){
                                            e.preventDefault();

                                            document.getElementById('selsearchtypenotes').value = '';

                                            var selsearchmatierenotesStr = document.getElementById('selsearchmatierenotes').value;
                                            if(!selsearchmatierenotesStr){
                                                alert('Sélectionner une matière avant de continuer');
                                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                                document.getElementById('viewcalendriernotes').style.display = 'block';
                                                document.getElementById('viewmatierenotes').style.display = 'block';
                                                document.getElementById('viewtypenotes').style.display = 'none';
                                                document.getElementById('viewstatutnotes').style.display = 'none';
                                                document.getElementById('viewprogrammenotes').style.display = 'none';
                                                document.getElementById('viewconsulternotes').style.display = 'none';
                                            }else{
                                                document.getElementById('searchselectmatierenotes').value = selsearchmatierenotesStr;
                                                document.getElementById('searchmatiereprogrammenotes').innerText =selsearchcalendriernotesStr +' '+selsearchmatierenotesStr;
                                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                                document.getElementById('viewcalendriernotes').style.display = 'block';
                                                document.getElementById('viewmatierenotes').style.display = 'block';
                                                document.getElementById('viewtypenotes').style.display = 'block';
                                                document.getElementById('viewstatutnotes').style.display = 'none';
                                                document.getElementById('viewprogrammenotes').style.display = 'none';
                                                document.getElementById('viewconsulternotes').style.display = 'none';
                                            }
                                        }
                                    }
                                } catch (error) {
                                    alert('Erreur Exécution');
                                }
                            }
                        }
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }
        /**nav previous page button*/
        document.getElementById('prevsearchnotesBtn_1').addEventListener('click', prevsearchnotes1BtnChange);
        async function prevsearchnotes1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'block';
            document.getElementById('crudnotes').style.display = 'block';
            document.getElementById('addnotes').style.display = 'none';
            document.getElementById('editnotes').style.display = 'none';
            document.getElementById('deletenotes').style.display = 'none';
            document.getElementById('searchnotes').style.display = 'none';
            titre.innerHTML = 'Notes de Classe'; 
        }
        /***************************
        selsearchtypenotes
        *****************************/
        document.getElementById('selsearchtypenotes').addEventListener('change', selsearchtypenotesChange);
        async function selsearchtypenotesChange(e){
            e.preventDefault();

            document.getElementById('searchstatutnotes').value = '';

            var selsearchtypenotesStr = document.getElementById('selsearchtypenotes').value;
            if(!selsearchtypenotesStr){
                alert('Sélectionner un type de Devoir Effectué avant de continuer');
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('viewcalendriernotes').style.display = 'block';
                document.getElementById('viewmatierenotes').style.display = 'block';
                document.getElementById('viewtypenotes').style.display = 'block';
                document.getElementById('viewstatutnotes').style.display = 'none';
                document.getElementById('viewprogrammenotes').style.display = 'none';
                document.getElementById('viewconsulternotes').style.display = 'none';
            }else{
                document.getElementById('searchselecttypenotes').value = selsearchtypenotesStr;
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('viewcalendriernotes').style.display = 'block';
                document.getElementById('viewmatierenotes').style.display = 'block';
                document.getElementById('viewtypenotes').style.display = 'block';
                document.getElementById('viewstatutnotes').style.display = 'block';
                document.getElementById('viewprogrammenotes').style.display = 'none';
                document.getElementById('viewconsulternotes').style.display = 'none';
            }
        }
        /***************************
        searchstatutnotes
        *****************************/
        document.getElementById('searchstatutnotes').addEventListener('change', searchstatutnotesChange);
        async function searchstatutnotesChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var searchselectcalendriernotesStr = document.getElementById('searchselectcalendriernotes').value;
            var searchselectmatierenotesStr = document.getElementById('searchselectmatierenotes').value;
            var selsearchtypenotesStr = document.getElementById('selsearchtypenotes').value;
            var searchstatutnotesStr = document.getElementById('searchstatutnotes').value;
            var typeDevoirStr = 'Devoir';
            var typeInterroStr = 'Interrogation';
            var effectOuiStr = 'Oui';
            var statutOuiStr = 'Oui';
            var statutNonStr = 'Non'
            var docsSearchDevoirOui = [];
            var docsSearchDevoirNon = [];
            var docsSearchInterroOui = [];
            var docsSearchInterroNon = [];
            var docsSearchTousOui = [];
            var docsSearchTousNon = [];
            if(!searchstatutnotesStr){
                alert('Sélectionner un Statut Note avant de continuer');
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('viewcalendriernotes').style.display = 'block';
                document.getElementById('viewmatierenotes').style.display = 'block';
                document.getElementById('viewtypenotes').style.display = 'block';
                document.getElementById('viewstatutnotes').style.display = 'block';
                document.getElementById('viewprogrammenotes').style.display = 'none';
                document.getElementById('viewconsulternotes').style.display = 'none';
            }else{
                if(selsearchtypenotesStr === 'Devoir'){
                    if(searchstatutnotesStr === 'Oui'){
                        try {
                            let resultDevoirOui = await db.createIndex({
                                index: {
                                    fields: [
                                        'anneescolaire',
                                        'matierenom',
                                        'notedevoir',
                                        'noteeffectue',
                                        'notestatut',
                                        'inscritcode'
                                    ]
                                }
                            });
                            resultDevoirOui = await db.find({
                                selector: {
                                    anneescolaire: searchselectcalendriernotesStr,
                                    matierenom: searchselectmatierenotesStr,
                                    notedevoir: typeDevoirStr,
                                    noteeffectue: effectOuiStr,
                                    notestatut: statutOuiStr,
                                    inscritcode: userCodeStr
                                }
                            });
                            docsSearchDevoirOui = resultDevoirOui.docs;
                            var resultatSearchDevoirOui = docsSearchDevoirOui.length;
                            if(resultatSearchDevoirOui === 0){
                                alert('Aucun Résultat Disponible');
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendriernotes').style.display = 'block';
                                document.getElementById('viewmatierenotes').style.display = 'block';
                                document.getElementById('viewtypenotes').style.display = 'block';
                                document.getElementById('viewstatutnotes').style.display = 'block';
                                document.getElementById('viewprogrammenotes').style.display = 'none';
                                document.getElementById('viewconsulternotes').style.display = 'none';
                            }else{
                                updateSearchNotesUIWithDocs(docsSearchDevoirOui);
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendriernotes').style.display = 'block';
                                document.getElementById('viewmatierenotes').style.display = 'block';
                                document.getElementById('viewtypenotes').style.display = 'block';
                                document.getElementById('viewstatutnotes').style.display = 'block';
                                document.getElementById('viewprogrammenotes').style.display = 'block';
                                document.getElementById('viewconsulternotes').style.display = 'block';
                            }
                        } catch (error) {
                            alert('Erreur Exécution'); 
                        }
                    }else if(searchstatutnotesStr === 'Non'){
                        try {
                            let resultDevoirNon = await db.createIndex({
                                index: {
                                    fields: [
                                        'anneescolaire',
                                        'matierenom',
                                        'notedevoir',
                                        'noteeffectue',
                                        'notestatut',
                                        'inscritcode'
                                    ]
                                }
                            });
                            resultDevoirNon = await db.find({
                                selector: {
                                    anneescolaire: searchselectcalendriernotesStr,
                                    matierenom: searchselectmatierenotesStr,
                                    notedevoir: typeDevoirStr,
                                    noteeffectue: effectOuiStr,
                                    notestatut: statutNonStr,
                                    inscritcode: userCodeStr
                                }
                            });
                            docsSearchDevoirNon = resultDevoirNon.docs;
                            var resultatSearchDevoirNon = docsSearchDevoirNon.length;
                            if(resultatSearchDevoirNon === 0){
                                alert('Aucun Résultat Disponible');
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendriernotes').style.display = 'block';
                                document.getElementById('viewmatierenotes').style.display = 'block';
                                document.getElementById('viewtypenotes').style.display = 'block';
                                document.getElementById('viewstatutnotes').style.display = 'block';
                                document.getElementById('viewprogrammenotes').style.display = 'none';
                                document.getElementById('viewconsulternotes').style.display = 'none';
                            }else{
                                updateSearchNotesUIWithDocs(docsSearchDevoirNon);
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendriernotes').style.display = 'block';
                                document.getElementById('viewmatierenotes').style.display = 'block';
                                document.getElementById('viewtypenotes').style.display = 'block';
                                document.getElementById('viewstatutnotes').style.display = 'block';
                                document.getElementById('viewprogrammenotes').style.display = 'block';
                                document.getElementById('viewconsulternotes').style.display = 'block';
                            }
                        } catch (error) {
                            alert('Erreur Exécution'); 
                        }
                    }
                }else if(selsearchtypenotesStr === 'Interrogation'){
                    if(searchstatutnotesStr === 'Oui'){
                        try {
                            let resultInterroOui = await db.createIndex({
                                index: {
                                    fields: [
                                        'anneescolaire',
                                        'matierenom',
                                        'notedevoir',
                                        'noteeffectue',
                                        'notestatut',
                                        'inscritcode'
                                    ]
                                }
                            });
                            resultInterroOui = await db.find({
                                selector: {
                                    anneescolaire: searchselectcalendriernotesStr,
                                    matierenom: searchselectmatierenotesStr,
                                    notedevoir: typeInterroStr,
                                    noteeffectue: effectOuiStr,
                                    notestatut: statutOuiStr,
                                    inscritcode: userCodeStr
                                }
                            });
                            docsSearchInterroOui = resultInterroOui.docs;
                            var resultatSearchInterroOui = docsSearchInterroOui.length;
                            if(resultatSearchInterroOui === 0){
                                alert('Aucun Résultat Disponible');
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendriernotes').style.display = 'block';
                                document.getElementById('viewmatierenotes').style.display = 'block';
                                document.getElementById('viewtypenotes').style.display = 'block';
                                document.getElementById('viewstatutnotes').style.display = 'block';
                                document.getElementById('viewprogrammenotes').style.display = 'none';
                                document.getElementById('viewconsulternotes').style.display = 'none';
                            }else{
                                updateSearchNotesUIWithDocs(docsSearchInterroOui);
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendriernotes').style.display = 'block';
                                document.getElementById('viewmatierenotes').style.display = 'block';
                                document.getElementById('viewtypenotes').style.display = 'block';
                                document.getElementById('viewstatutnotes').style.display = 'block';
                                document.getElementById('viewprogrammenotes').style.display = 'block';
                                document.getElementById('viewconsulternotes').style.display = 'block';
                            }
                        } catch (error) {
                            alert('Erreur Exécution'); 
                        }
                    }else if(searchstatutnotesStr === 'Non'){
                        try {
                            let resultInterroNon = await db.createIndex({
                                index: {
                                    fields: [
                                        'anneescolaire',
                                        'matierenom',
                                        'notedevoir',
                                        'noteeffectue',
                                        'notestatut',
                                        'inscritcode'
                                    ]
                                }
                            });
                            resultInterroNon = await db.find({
                                selector: {
                                    anneescolaire: searchselectcalendriernotesStr,
                                    matierenom: searchselectmatierenotesStr,
                                    notedevoir: typeInterroStr,
                                    noteeffectue: effectOuiStr,
                                    notestatut: statutNonStr,
                                    inscritcode: userCodeStr
                                }
                            });
                            docsSearchInterroNon = resultInterroNon.docs;
                            var resultatSearchInterroNon = docsSearchInterroNon.length;
                            if(resultatSearchInterroNon === 0){
                                alert('Aucun Résultat Disponible');
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendriernotes').style.display = 'block';
                                document.getElementById('viewmatierenotes').style.display = 'block';
                                document.getElementById('viewtypenotes').style.display = 'block';
                                document.getElementById('viewstatutnotes').style.display = 'block';
                                document.getElementById('viewprogrammenotes').style.display = 'none';
                                document.getElementById('viewconsulternotes').style.display = 'none';
                            }else{
                                updateSearchNotesUIWithDocs(docsSearchInterroNon);
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendriernotes').style.display = 'block';
                                document.getElementById('viewmatierenotes').style.display = 'block';
                                document.getElementById('viewtypenotes').style.display = 'block';
                                document.getElementById('viewstatutnotes').style.display = 'block';
                                document.getElementById('viewprogrammenotes').style.display = 'block';
                                document.getElementById('viewconsulternotes').style.display = 'block';
                            }
                        } catch (error) {
                            alert('Erreur Exécution'); 
                        }
                    }
                }else if(selsearchtypenotesStr === 'Tous'){
                    if(searchstatutnotesStr === 'Oui'){
                        try {
                            let resultTousOui = await db.createIndex({
                                index: {
                                    fields: [
                                        'anneescolaire',
                                        'matierenom',
                                        'noteeffectue',
                                        'notestatut',
                                        'inscritcode'
                                    ]
                                }
                            });
                            resultTousOui = await db.find({
                                selector: {
                                    anneescolaire: searchselectcalendriernotesStr,
                                    matierenom: searchselectmatierenotesStr,
                                    noteeffectue: effectOuiStr,
                                    notestatut: statutOuiStr,
                                    inscritcode: userCodeStr
                                }
                            });
                            docsSearchTousOui = resultTousOui.docs;
                            var resultatSearchTousOui = docsSearchTousOui.length;
                            if(resultatSearchTousOui === 0){
                                alert('Aucun Résultat Disponible');
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendriernotes').style.display = 'block';
                                document.getElementById('viewmatierenotes').style.display = 'block';
                                document.getElementById('viewtypenotes').style.display = 'block';
                                document.getElementById('viewstatutnotes').style.display = 'block';
                                document.getElementById('viewprogrammenotes').style.display = 'none';
                                document.getElementById('viewconsulternotes').style.display = 'none';
                            }else{
                                updateSearchNotesUIWithDocs(docsSearchTousOui);
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendriernotes').style.display = 'block';
                                document.getElementById('viewmatierenotes').style.display = 'block';
                                document.getElementById('viewtypenotes').style.display = 'block';
                                document.getElementById('viewstatutnotes').style.display = 'block';
                                document.getElementById('viewprogrammenotes').style.display = 'block';
                                document.getElementById('viewconsulternotes').style.display = 'block';
                            }
                        } catch (error) {
                            alert('Erreur Exécution'); 
                        }
                    }else if(searchstatutnotesStr === 'Non'){
                        try {
                            let resultTousNon = await db.createIndex({
                                index: {
                                    fields: [
                                        'anneescolaire',
                                        'matierenom',
                                        'noteeffectue',
                                        'notestatut',
                                        'inscritcode'
                                    ]
                                }
                            });
                            resultTousNon = await db.find({
                                selector: {
                                    anneescolaire: searchselectcalendriernotesStr,
                                    matierenom: searchselectmatierenotesStr,
                                    noteeffectue: effectOuiStr,
                                    notestatut: statutNonStr,
                                    inscritcode: userCodeStr
                                }
                            });
                            docsSearchTousNon = resultTousNon.docs;
                            var resultatSearchTousNon = docsSearchTousNon.length;
                            if(resultatSearchTousNon === 0){
                                alert('Aucun Résultat Disponible');
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendriernotes').style.display = 'block';
                                document.getElementById('viewmatierenotes').style.display = 'block';
                                document.getElementById('viewtypenotes').style.display = 'block';
                                document.getElementById('viewstatutnotes').style.display = 'block';
                                document.getElementById('viewprogrammenotes').style.display = 'none';
                                document.getElementById('viewconsulternotes').style.display = 'none';
                            }else{
                                updateSearchNotesUIWithDocs(docsSearchTousNon);
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendriernotes').style.display = 'block';
                                document.getElementById('viewmatierenotes').style.display = 'block';
                                document.getElementById('viewtypenotes').style.display = 'block';
                                document.getElementById('viewstatutnotes').style.display = 'block';
                                document.getElementById('viewprogrammenotes').style.display = 'block';
                                document.getElementById('viewconsulternotes').style.display = 'block';
                            }
                        } catch (error) {
                            alert('Erreur Exécution'); 
                        }
                    }
                }
            }
        }
        // Adds the document to the HTML First table.
        // If the document already exists in the table, it will be updated.
        function viewSearchProgrammeNotesTableDocToTable(doc, viewSearchProgrammeNotesTableBody){
            // Each row of the table has an id attribute set to the doc._id field.
            var tr = viewSearchProgrammeNotesTableBody.querySelector(`#${doc._id}`);

            var idNotesStr,inscritCodeStr,anneeScolaireStr,matiereNomStr,noteDevoirStr,noteEcheanceStr,noteObtenueStr,
            noteCoefficientStr,noteEffectueStr,noteDateStr,noteReferenceStr = '';
            var effectueNotesStr = 'Oui';
            var statutNotesStr = 'Non';
            var noteStatutStr = 'Oui'; //use it for this update process
            var docsNotes = [];

            if( !tr ) {
                tr = document.createElement('tr');
                tr.id = doc._id;
            }
        
            // Remove any existing table cells.
            while(tr.hasChildNodes()) {
                tr.removeChild(tr.childNodes[0]);
            }

            // Create new table cells for text fields.
            var tdEcheance = document.createElement('td');
            var dateEcheance = new Date(doc.noteecheance);
            tdEcheance.innerText = dateEcheance.toLocaleDateString();
            tr.appendChild(tdEcheance);

            // Create new table cells for text fields.
            var tdType = document.createElement('td');
            tdType.innerText = doc.notedevoir;
            tr.appendChild(tdType);

            // Create new table cells for text fields.
            var tdNote = document.createElement('td');
            tdNote.innerText = doc.noteobtenue;
            var statut = doc.notestatut;
            var idNote = doc._id
            if(statut === 'Non'){
                var br = document.createElement("br");
                tdNote.appendChild(br);
                var button = document.createElement("button");
                button.id = "ouinotesBtn";
                button.type = 'button';
                button.innerHTML = 'Ajouter';
                button.onclick = async function(){
                    try {
                        let resultNotes = await db.createIndex({
                            index: {
                                fields: ['_id', 'noteeffectue', 'notestatut']
                            }
                        });
                        resultNotes = await db.find({
                            selector: {
                                _id: idNote,
                                noteeffectue: effectueNotesStr,
                                notestatut: statutNotesStr
                            }
                        });
                        docsNotes = resultNotes.docs;
                        var resultatNotes = docsNotes.length;
                        if(resultatNotes === 0){
                            alert("Résultat Indisponible");
                        }else{
                            for(let i=0; i<docsNotes.length; i++){
                                idNotesStr = docsNotes[i]._id;
                                inscritCodeStr = docsNotes[i].inscritcode;
                                anneeScolaireStr = docsNotes[i].anneescolaire;
                                matiereNomStr = docsNotes[i].matierenom;
                                noteDevoirStr = docsNotes[i].notedevoir;
                                noteEcheanceStr = docsNotes[i].noteecheance;
                                noteObtenueStr = docsNotes[i].noteobtenue;
                                noteCoefficientStr = docsNotes[i].notecoefficient;
                                noteEffectueStr = docsNotes[i].noteeffectue;
                                noteDateStr = docsNotes[i].notedate;
                                noteReferenceStr = docsNotes[i].notereference;
                            }

                            // Retrieve the document to get the latest revision
                            var id = idNotesStr;
                            var doc = await db.get(id);
                            // Make changes
                            doc.inscritcode = inscritCodeStr;
                            doc.anneescolaire = anneeScolaireStr;
                            doc.matierenom = matiereNomStr;
                            doc.notedevoir = noteDevoirStr;
                            doc.noteecheance = noteEcheanceStr;
                            doc.noteobtenue = noteObtenueStr;
                            doc.notecoefficient = noteCoefficientStr;
                            doc.noteeffectue = noteEffectueStr;
                            doc.notedate = noteDateStr;
                            doc.notestatut = noteStatutStr;
                            doc.notereference = noteReferenceStr;
                            // Put the document, saving it as a new revision
                            var response = await db.put(doc);
                            // Get the document we change and update the UI.
                            var docEdit = await db.get(response.id);
                            if(docEdit !== ''){
                                alert("Note Ajoutée avec Succès.");
                                document.getElementById('titre').style.display = 'block';
                                var titre = document.getElementById('titre');

                                document.getElementById('demarrage').style.display = 'none';
                                document.getElementById('tableaubord').style.display = 'none';
                                document.getElementById('dashboard').style.display = 'none';
                                document.getElementById('configurations').style.display = 'none';
                                document.getElementById('configurer').style.display = 'none';
                                document.getElementById('emploidutemps').style.display = 'none';
                                document.getElementById('exercice').style.display = 'none';
                                document.getElementById('pratique').style.display = 'none';
                                document.getElementById('tache').style.display = 'none';
                                document.getElementById('revision').style.display = 'none';
                                document.getElementById('recherche').style.display = 'none';
                                document.getElementById('programme').style.display = 'none';
                                document.getElementById('notes').style.display = 'block';
                                document.getElementById('crudnotes').style.display = 'block';
                                document.getElementById('addnotes').style.display = 'none';
                                document.getElementById('editnotes').style.display = 'none';
                                document.getElementById('deletenotes').style.display = 'none';
                                document.getElementById('searchnotes').style.display = 'none';
                                titre.innerHTML = 'Notes de Classe'; 
                            }else{
                                alert("Impossible Ajouter cette Note.");
                                document.getElementById('titre').style.display = 'block';
                                var titre = document.getElementById('titre');

                                document.getElementById('demarrage').style.display = 'none';
                                document.getElementById('tableaubord').style.display = 'none';
                                document.getElementById('dashboard').style.display = 'none';
                                document.getElementById('configurations').style.display = 'none';
                                document.getElementById('configurer').style.display = 'none';
                                document.getElementById('emploidutemps').style.display = 'none';
                                document.getElementById('exercice').style.display = 'none';
                                document.getElementById('pratique').style.display = 'none';
                                document.getElementById('tache').style.display = 'none';
                                document.getElementById('revision').style.display = 'none';
                                document.getElementById('recherche').style.display = 'none';
                                document.getElementById('programme').style.display = 'none';
                                document.getElementById('notes').style.display = 'block';
                                document.getElementById('crudnotes').style.display = 'block';
                                document.getElementById('addnotes').style.display = 'none';
                                document.getElementById('editnotes').style.display = 'none';
                                document.getElementById('deletenotes').style.display = 'none';
                                document.getElementById('searchnotes').style.display = 'none';
                                titre.innerHTML = 'Notes de Classe'; 
                            }
                        }
                    } catch (error) {
                        alert('Erreur Exécution');
                    }
                }
                tdNote.appendChild(button);
            }
            tr.appendChild(tdNote);

            // Add the row to the top of the table.
            viewSearchProgrammeNotesTableBody.insertBefore(tr, viewSearchProgrammeNotesTableBody.nextSibling);
        }
        /**Clear and update the UI to display the docs.*/
        function updateSearchNotesUIWithDocs(docs) {
            var viewSearchProgrammeNotesTableBody = document.getElementById('viewSearchProgrammeNotesTableBody');
            
            // Remove all child nodes from the table
            while(viewSearchProgrammeNotesTableBody.hasChildNodes()) {
                viewSearchProgrammeNotesTableBody.removeChild(viewSearchProgrammeNotesTableBody.childNodes[0]);
            }
            
            // Add the new documents to the table
            for(var i=0; i<docs.length; i++) {
                var doc = docs[i];
                viewSearchProgrammeNotesTableDocToTable(doc, viewSearchProgrammeNotesTableBody);
            }
        }
        /***************************
        consulternotesBtn
        *****************************/
        document.getElementById('consulternotesBtn').addEventListener('click', consulternotesBtnChange);
        async function consulternotesBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'block';
            document.getElementById('crudnotes').style.display = 'block';
            document.getElementById('addnotes').style.display = 'none';
            document.getElementById('editnotes').style.display = 'none';
            document.getElementById('deletenotes').style.display = 'none';
            document.getElementById('searchnotes').style.display = 'none';
            titre.innerHTML = 'Notes de Classe';
        }
    };

    /****************
    HELPER FUNCTION
    *****************/
    /**1: Get Current Date in JavaScript */
    function getCurrentDate(){
        // Date object
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1;
        var yyyy = today.getFullYear();
        if(dd<10){
            dd='0'+dd;
        }
        if(mm<10){
            mm='0'+mm;
        }
        // Current Date
        var date = yyyy+'-'+mm+'-'+dd;
        return date;
    }

    window.addEventListener('load', () =>{
        new taskNotes();
    })
}) ();