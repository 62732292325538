import Configurer from "./Configurer";

function Configuration() {
    return ( 
        <div id="configurations">
            <Configurer />
        </div>
    );
}

export default Configuration;