import { Add, NavigateNext, } from '@material-ui/icons';

function AddButton(props) {
    return ( 
        <div>
            <button id={props.idaddbutton} style={addButton}>
                <Add style={{float:'left',color:'#008000'}} />
                Ajouter {props.titre}
                <NavigateNext style={{float:'right',color:'#008000'}} />
            </button>
        </div>
    );
}

const addButton = {
    marginTop: '4%',
    flex: 1,
    width: '90%',
    height: 50,
    textAlign: "center",
    alignContent: 'center',
    border: "2px solid #008000",
    fontSize: 18,
    borderRadius: 10,
    color: "#008000",
    //textTransform: "uppercase",
}

export default AddButton;