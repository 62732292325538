/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
import PouchDB from 'pouchdb';
import PouchFind from 'pouchdb-find';

(function(){
    var taskTache = function(){

        /*****************************************************
        ENABLING POUCHDB WITHOUT SYNC TO REMOTE COUCHDB
        ******************************************************/
        //SET UP POUCHDB AND CREATE THE DATABASE 
        const db = new PouchDB('suclu');
        PouchDB.plugin(PouchFind);

        /***************
        crudpratiqueBtn
        *****************/
        /**nav previous page button*/
        document.getElementById('prevcrudtacheBtn_1').addEventListener('click', prevcrudtache1BtnChange);
        async function prevcrudtache1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'block';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            titre.innerHTML = 'Exercices et Tâches'; 
        }

        /***************************
        addtacheBtn
        *****************************/
        document.getElementById('addtacheBtn').addEventListener('click', addtacheBtnChange);
        async function addtacheBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'block';
            document.getElementById('crudtache').style.display = 'none';
            document.getElementById('addtache').style.display = 'block';
            document.getElementById('edittache').style.display = 'none';
            document.getElementById('deletetache').style.display = 'none';
            document.getElementById('searchtache').style.display = 'none';
            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('savetypetache').style.display = 'block';
            document.getElementById('savedatetache').style.display = 'none';
            document.getElementById('savetextetache').style.display = 'none';
            document.getElementById('savetache').style.display = 'none';
            titre.innerHTML = 'Enregistrer Tâche'; 
        }
        /**nav previous page button*/
        document.getElementById('prevaddtacheBtn_1').addEventListener('click', prevaddtache1BtnChange);
        async function prevaddtache1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'block';
            document.getElementById('crudtache').style.display = 'block';
            document.getElementById('addtache').style.display = 'none';
            document.getElementById('edittache').style.display = 'none';
            document.getElementById('deletetache').style.display = 'none';
            document.getElementById('searchtache').style.display = 'none';
            titre.innerHTML = 'Tâches Personnelles'; 
        }
        /***************************
        seladdtypetache
        *****************************/
        document.getElementById('seladdtypetache').addEventListener('change', seladdtypetacheChange);
        async function seladdtypetacheChange(e){
            e.preventDefault();

            var seladdtypetacheStr = document.getElementById('seladdtypetache').value;
            if(seladdtypetacheStr === ''){
                alert('Sélectionner une Tâche');
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('savetypetache').style.display = 'block';
                document.getElementById('savedatetache').style.display = 'none';
                document.getElementById('savetextetache').style.display = 'none';
                document.getElementById('savetache').style.display = 'none';
            }else{
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('savetypetache').style.display = 'block';
                document.getElementById('savedatetache').style.display = 'block';
                document.getElementById('savetextetache').style.display = 'block';
                document.getElementById('savetache').style.display = 'block';
            }
        }
        /***************************
        enregistrertacheBtn
        *****************************/
        document.getElementById('enregistrertacheBtn').addEventListener('click', enregistrertacheBtnChange);
        async function enregistrertacheBtnChange(e){
            e.preventDefault();

            var usercodeStr = document.getElementById('userCode').innerText;
            var seladdtypetacheStr = document.getElementById('seladdtypetache').value;
            var adddatetacheStr = document.getElementById('adddatetache').value;
            var addtextetacheStr = document.getElementById('addtextetache').value;
            var addeffectuetacheStr = 'Non';
            var datejourStr = getCurrentDate();
            var addstatuttacheStr = 'Oui';

            if(!usercodeStr){
                alert("Identifiez-vous avant de continuer");
            }else if(!seladdtypetacheStr){
                alert("Sélectionner une Tâche");
            }else if(!adddatetacheStr){
                alert("Saisir Date Echéance");
            }else if(!addtextetacheStr){
                alert("Saisir Contenu Tâche ");
            }else{
                try {
                    //Create the document _id and _rev.
                    var now = Date.now();
                    var id = 'tache_' + now;
                    // Add the document to the database.
                    var response = await db.put({
                        _id: id,
                        inscritcode: usercodeStr,
                        tachetype: seladdtypetacheStr,
                        tacheecheance: adddatetacheStr,
                        tachetexte: addtextetacheStr,
                        tacheeffectue: addeffectuetacheStr,
                        tachedate: datejourStr,
                        tachestatut: addstatuttacheStr,
                    });
                    // check if document inserted and synced
                    var doc = await db.get(response.id);
                    if(doc !== ''){
                        alert('Enregistré. Au Suivant');
                        document.getElementById('seladdtypetache').value = '';
                        document.getElementById('adddatetache').value = '';
                        document.getElementById('addtextetache').value = '';

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'block';
                        document.getElementById('crudtache').style.display = 'block';
                        document.getElementById('addtache').style.display = 'none';
                        document.getElementById('edittache').style.display = 'none';
                        document.getElementById('deletetache').style.display = 'none';
                        document.getElementById('searchtache').style.display = 'none';
                        titre.innerHTML = 'Tâches Personnelles'; 
                    }else{
                        alert('Echec Enregistrement de cette Tâche. Reprendre');
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }

        /***************************
        edittacheBtn
        *****************************/
        document.getElementById('edittacheBtn').addEventListener('click', edittacheBtnChange);
        async function edittacheBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'block';
            document.getElementById('crudtache').style.display = 'none';
            document.getElementById('addtache').style.display = 'none';
            document.getElementById('edittache').style.display = 'block';
            document.getElementById('deletetache').style.display = 'none';
            document.getElementById('searchtache').style.display = 'none';
            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('changetypetache').style.display = 'block';
            document.getElementById('changeecheancetache').style.display = 'none';
            document.getElementById('changedatetache').style.display = 'none';
            document.getElementById('changetextetache').style.display = 'none';
            document.getElementById('changetache').style.display = 'none';
            titre.innerHTML = 'Modifier Tâche'; 
        }
        /**nav previous page button*/
        document.getElementById('prevedittacheBtn_1').addEventListener('click', prevedittache1BtnChange);
        async function prevedittache1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'block';
            document.getElementById('crudtache').style.display = 'block';
            document.getElementById('addtache').style.display = 'none';
            document.getElementById('edittache').style.display = 'none';
            document.getElementById('deletetache').style.display = 'none';
            document.getElementById('searchtache').style.display = 'none';
            titre.innerHTML = 'Tâches Personnelles'; 
        }
        /***************************
        seledittypetache
        *****************************/
        document.getElementById('seledittypetache').addEventListener('change', seledittypetacheChange);
        async function seledittypetacheChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var seledittypetacheStr = document.getElementById('seledittypetache').value;
            var statutTacheStr = 'Oui';
            var effectueTacheStr = 'Non';
            var docsEditTache = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: [
                            'tachestatut', 
                            'inscritcode', 
                            'tacheeffectue',
                            'tachetype'
                        ]
                    }
                });
                result = await db.find({
                    selector: {
                        tachestatut: statutTacheStr,
                        inscritcode: userCodeStr, 
                        tacheeffectue: effectueTacheStr,
                        tachetype: seledittypetacheStr
                    }
                });
                docsEditTache  = result.docs;
                var resultatEditTache = docsEditTache.length;
                if(resultatEditTache === 0){
                    alert("Tâches "+seledittypetacheStr+" indisponibles.");
                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('changetypetache').style.display = 'block';
                    document.getElementById('changeecheancetache').style.display = 'none';
                    document.getElementById('changedatetache').style.display = 'none';
                    document.getElementById('changetextetache').style.display = 'none';
                    document.getElementById('changetache').style.display = 'none';
                }else{
                    var divClear;
                    var divModifier;
                    divClear = document.getElementById('editselecheance');
                    while(divClear.hasChildNodes()) {
                        divClear.removeChild(divClear.childNodes[0]);
                    }
                    divModifier = document.getElementById('editselecheance');
                    //Create and append select list
                    var selectList = document.createElement("select");
                    selectList.id = "seleditselecheance";
                    divModifier.appendChild(selectList);
                    //Create and append the options
                    var option_null = document.createElement("option");
                    option_null.value = '';
                    option_null.text = 'Sélectionner une tâche';
                    selectList.appendChild(option_null);
                    //Create and append the options
                    for (var i = 0; i<docsEditTache.length; i++){
                        var option = document.createElement("option");
                        option.value = docsEditTache[i]._id;
                        option.text = i+1;
                        selectList.appendChild(option);
                    }

                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('changetypetache').style.display = 'block';
                    document.getElementById('changeecheancetache').style.display = 'block';
                    document.getElementById('changedatetache').style.display = 'none';
                    document.getElementById('changetextetache').style.display = 'none';
                    document.getElementById('changetache').style.display = 'none';

                    /***************************
                    seleditselecheance
                    *****************************/
                    document.getElementById('seleditselecheance').addEventListener('change', seleditselecheanceChange);
                    async function seleditselecheanceChange(e){
                        e.preventDefault();

                        var userCodeStr = document.getElementById('userCode').innerText;
                        var tacheIdStr,inscritCodeStr,tacheTypeStr,tacheEcheanceStr,tacheTexteStr,
                        tacheEffectueStr,tacheDateStr,tacheStatutStr = '';

                        var seleditselecheanceStr = document.getElementById('seleditselecheance').value;
                        if(!seleditselecheanceStr){
                            alert('Sélectionner une Tâche avant de continuer');
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('changetypetache').style.display = 'block';
                            document.getElementById('changeecheancetache').style.display = 'block';
                            document.getElementById('changedatetache').style.display = 'none';
                            document.getElementById('changetextetache').style.display = 'none';
                            document.getElementById('changetache').style.display = 'none';
                        }else{
                            var statutTacheStr = 'Oui';
                            var tacheEffectStr = 'Non';
                            var docsEdit = [];
                            try {
                                let result = await db.createIndex({
                                    index: {
                                        fields: [
                                            'tachestatut', 
                                            'inscritcode',
                                            'tacheeffectue', 
                                            '_id'
                                        ]
                                    }
                                });
                                result = await db.find({
                                    selector: {
                                        tachestatut: statutTacheStr,
                                        inscritcode: userCodeStr,
                                        tacheeffectue: tacheEffectStr,
                                        _id: seleditselecheanceStr
                                    }
                                });
                                docsEdit  = result.docs;
                                var resultatEdit = docsEdit.length;
                                if(resultatEdit === 0){
                                    alert("Tâche sélectionnée n'existe pas");
                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('changetypetache').style.display = 'block';
                                    document.getElementById('changeecheancetache').style.display = 'none';
                                    document.getElementById('changedatetache').style.display = 'none';
                                    document.getElementById('changetextetache').style.display = 'none';
                                    document.getElementById('changetache').style.display = 'none';
                                }else{
                                    for(let j=0; j<docsEdit.length; j++){
                                        tacheIdStr = docsEdit[j]._id;
                                        inscritCodeStr = docsEdit[j].inscritcode;
                                        tacheTypeStr = docsEdit[j].tachetype;
                                        tacheEcheanceStr = docsEdit[j].tacheecheance;
                                        tacheTexteStr = docsEdit[j].tachetexte;
                                        tacheEffectueStr = docsEdit[j].tacheeffectue;
                                        tacheDateStr = docsEdit[j].tachedate;
                                        tacheStatutStr = docsEdit[j].tachestatut;
                                    }

                                    document.getElementById('editecheancetache').value = tacheEcheanceStr;
                                    document.getElementById('edittextetache').value = tacheTexteStr;

                                    document.getElementById('editidtache').value = tacheIdStr;
                                    document.getElementById('edittypetache').value = tacheTypeStr;
                                    document.getElementById('editcodeusertache').value = inscritCodeStr;
                                    document.getElementById('editeffectuetache').value = tacheEffectueStr;
                                    document.getElementById('editdatetache').value = tacheDateStr;
                                    document.getElementById('editstatuttache').value = tacheStatutStr;

                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('changetypetache').style.display = 'block';
                                    document.getElementById('changeecheancetache').style.display = 'none';
                                    document.getElementById('changedatetache').style.display = 'block';
                                    document.getElementById('changetextetache').style.display = 'block';
                                    document.getElementById('changetache').style.display = 'block';
                                }
                            } catch (error) {
                                alert('Erreur Exécution Tâche');
                            }
                        }
                    }
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        /***************************
        modifiertacheBtn
        *****************************/
        document.getElementById('modifiertacheBtn').addEventListener('click', modifiertacheBtnChange);
        async function modifiertacheBtnChange(e){
            e.preventDefault();

            var seleditTypeTacheStr = document.getElementById('seledittypetache').value;
            var editEcheanceTacheStr = document.getElementById('editecheancetache').value;
            var editTexteTacheStr = document.getElementById('edittextetache').value;

            var editIdTacheStr = document.getElementById('editidtache').value;
            var editCodeUserTacheStr = document.getElementById('editcodeusertache').value;
            var editEffectueTacheStr = document.getElementById('editeffectuetache').value;
            var editDateTacheStr = document.getElementById('editdatetache').value;
            var editStatutTacheStr = document.getElementById('editstatuttache').value;

            if(!editCodeUserTacheStr){
                alert("Identifiez-vous avant de continuer");
            }else if(!editEcheanceTacheStr){
                alert("Saisir Date Echéance");
            }else if(!editTexteTacheStr){
                alert('Saisir Contenu Tâche');
            }else{
                try {
                    // Retrieve the document to get the latest revision
                    var id = editIdTacheStr;
                    var doc = await db.get(id);
                    // Make changes
                    doc.inscritcode = editCodeUserTacheStr;
                    doc.tachetype = seleditTypeTacheStr;
                    doc.tacheecheance = editEcheanceTacheStr;
                    doc.tachetexte = editTexteTacheStr;
                    doc.tacheeffectue = editEffectueTacheStr;
                    doc.tachedate = editDateTacheStr;
                    doc.tachestatut = editStatutTacheStr;
                    // Put the document, saving it as a new revision
                    var response = await db.put(doc);
                    // Get the document we change and update the UI.
                    var docEdit = await db.get(response.id);
                    if(docEdit !== ''){
                        alert("Tâche Modifiée avec Succès");
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('seledittypetache').value = '';

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'block';
                        document.getElementById('crudtache').style.display = 'block';
                        document.getElementById('addtache').style.display = 'none';
                        document.getElementById('edittache').style.display = 'none';
                        document.getElementById('deletetache').style.display = 'none';
                        document.getElementById('searchtache').style.display = 'none';
                        titre.innerHTML = 'Tâches Personnelles'; 
                    }else{
                        alert("Erreur Modification. Reprendre");
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('seledittypetache').value = '';

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'block';
                        document.getElementById('crudtache').style.display = 'block';
                        document.getElementById('addtache').style.display = 'none';
                        document.getElementById('edittache').style.display = 'none';
                        document.getElementById('deletetache').style.display = 'none';
                        document.getElementById('searchtache').style.display = 'none';
                        titre.innerHTML = 'Tâches Personnelles'; 
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }

        /***************************
        deletetacheBtn
        *****************************/
        document.getElementById('deletetacheBtn').addEventListener('click', deletetacheBtnChange);
        async function deletetacheBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'block';
            document.getElementById('crudtache').style.display = 'none';
            document.getElementById('addtache').style.display = 'none';
            document.getElementById('edittache').style.display = 'none';
            document.getElementById('deletetache').style.display = 'block';
            document.getElementById('searchtache').style.display = 'none';
            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('canceltypetache').style.display = 'block';
            document.getElementById('cancelecheancetache').style.display = 'none';
            document.getElementById('canceldatetache').style.display = 'none';
            document.getElementById('canceltextetache').style.display = 'none';
            document.getElementById('canceltache').style.display = 'none';
            titre.innerHTML = 'Supprimer Tâche'; 
        }
        /**nav previous page button*/
        document.getElementById('prevdeletetacheBtn_1').addEventListener('click', prevdeletetache1BtnChange);
        async function prevdeletetache1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'block';
            document.getElementById('crudtache').style.display = 'block';
            document.getElementById('addtache').style.display = 'none';
            document.getElementById('edittache').style.display = 'none';
            document.getElementById('deletetache').style.display = 'none';
            document.getElementById('searchtache').style.display = 'none';
            titre.innerHTML = 'Tâches Personnelles'; 
        }
        /***************************
        seldeletetypetache
        *****************************/
        document.getElementById('seldeletetypetache').addEventListener('change', seldeletetypetacheChange);
        async function seldeletetypetacheChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var seldeletetypetacheStr = document.getElementById('seldeletetypetache').value;
            var statutTacheStr = 'Oui';
            var effectueTacheStr = 'Non';
            var docsDeleteTache = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: [
                            'tachestatut', 
                            'inscritcode', 
                            'tacheeffectue',
                            'tachetype'
                        ]
                    }
                });
                result = await db.find({
                    selector: {
                        tachestatut: statutTacheStr,
                        inscritcode: userCodeStr, 
                        tacheeffectue: effectueTacheStr,
                        tachetype: seldeletetypetacheStr
                    }
                });
                docsDeleteTache  = result.docs;
                var resultatDeleteTache = docsDeleteTache.length;
                if(resultatDeleteTache === 0){
                    alert("Tâches "+seldeletetypetacheStr+" indisponibles.");
                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('canceltypetache').style.display = 'block';
                    document.getElementById('cancelecheancetache').style.display = 'none';
                    document.getElementById('canceldatetache').style.display = 'none';
                    document.getElementById('canceltextetache').style.display = 'none';
                    document.getElementById('canceltache').style.display = 'none';
                }else{
                    var divClear;
                    var divModifier;
                    divClear = document.getElementById('deleteselecheance');
                    while(divClear.hasChildNodes()) {
                        divClear.removeChild(divClear.childNodes[0]);
                    }
                    divModifier = document.getElementById('deleteselecheance');
                    //Create and append select list
                    var selectList = document.createElement("select");
                    selectList.id = "seldeleteselecheance";
                    divModifier.appendChild(selectList);
                    //Create and append the options
                    var option_null = document.createElement("option");
                    option_null.value = '';
                    option_null.text = 'Sélectionner une tâche';
                    selectList.appendChild(option_null);
                    //Create and append the options
                    for (var i = 0; i<docsDeleteTache.length; i++){
                        var option = document.createElement("option");
                        option.value = docsDeleteTache[i]._id;
                        option.text = i+1;
                        selectList.appendChild(option);
                    }

                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('canceltypetache').style.display = 'block';
                    document.getElementById('cancelecheancetache').style.display = 'block';
                    document.getElementById('canceldatetache').style.display = 'none';
                    document.getElementById('canceltextetache').style.display = 'none';
                    document.getElementById('canceltache').style.display = 'none';

                    /***************************
                    seldeleteselecheance
                    *****************************/
                    document.getElementById('seldeleteselecheance').addEventListener('change', seldeleteselecheanceChange);
                    async function seldeleteselecheanceChange(e){
                        e.preventDefault();

                        var userCodeStr = document.getElementById('userCode').innerText;
                        var tacheIdStr,tacheEcheanceStr,tacheTexteStr = '';

                        var seldeleteselecheanceStr = document.getElementById('seldeleteselecheance').value;
                        if(!seldeleteselecheanceStr){
                            alert('Sélectionner une Tâche avant de continuer');
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('canceltypetache').style.display = 'block';
                            document.getElementById('cancelecheancetache').style.display = 'block';
                            document.getElementById('canceldatetache').style.display = 'none';
                            document.getElementById('canceltextetache').style.display = 'none';
                            document.getElementById('canceltache').style.display = 'none';
                        }else{
                            var statutTacheStr = 'Oui';
                            var tacheEffectStr = 'Non';
                            var docsDelete = [];
                            try {
                                let result = await db.createIndex({
                                    index: {
                                        fields: [
                                            'tachestatut', 
                                            'inscritcode',
                                            'tacheeffectue', 
                                            '_id'
                                        ]
                                    }
                                });
                                result = await db.find({
                                    selector: {
                                        tachestatut: statutTacheStr,
                                        inscritcode: userCodeStr,
                                        tacheeffectue: tacheEffectStr,
                                        _id: seldeleteselecheanceStr
                                    }
                                });
                                docsDelete  = result.docs;
                                var resultatDelete = docsDelete.length;
                                if(resultatDelete === 0){
                                    alert("Tâche sélectionnée n'existe pas");
                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('canceltypetache').style.display = 'block';
                                    document.getElementById('cancelecheancetache').style.display = 'none';
                                    document.getElementById('canceldatetache').style.display = 'none';
                                    document.getElementById('canceltextetache').style.display = 'none';
                                    document.getElementById('canceltache').style.display = 'none';
                                }else{
                                    for(let j=0; j<docsDelete.length; j++){
                                        tacheIdStr = docsDelete[j]._id;
                                        tacheEcheanceStr = docsDelete[j].tacheecheance;
                                        tacheTexteStr = docsDelete[j].tachetexte;
                                    }

                                    document.getElementById('deleteecheancetache').value = tacheEcheanceStr;
                                    document.getElementById('deletetextetache').value = tacheTexteStr;

                                    document.getElementById('deleteidtache').value = tacheIdStr;

                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('canceltypetache').style.display = 'block';
                                    document.getElementById('cancelecheancetache').style.display = 'none';
                                    document.getElementById('canceldatetache').style.display = 'block';
                                    document.getElementById('canceltextetache').style.display = 'block';
                                    document.getElementById('canceltache').style.display = 'block';
                                }
                            } catch (error) {
                                alert('Erreur Exécution Tâche');
                            }
                        }
                    }
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        /***************************
        supprimertacheBtn
        *****************************/
        document.getElementById('supprimertacheBtn').addEventListener('click', supprimertacheBtnChange);
        async function supprimertacheBtnChange(e){
            e.preventDefault();

            var divClearModal = document.getElementById('modalouttache');
            let divModal = document.createElement('div');
            divModal.innerHTML =
            `<div class="modal">
                <p>Êtes-vous sûr de Supprimer ?</p>
                <button class="btn btn--alt" id="ouiHandlerTacheBtn">Oui</button>
                <button class="btn" id="nonHandlerTacheBtn">Non</button>
            </div>`;
            divClearModal.appendChild(divModal);

            var divClearBackdrop = document.getElementById('backdropouttache');
            let divBack = document.createElement('div');
            divBack.innerHTML =
            `<div class="backdrop" id="backdropHandlerTacheBtn"></div>`;
            divClearBackdrop.appendChild(divBack);

            document.getElementById('ouiHandlerTacheBtn').addEventListener('click', ouiHandlerTacheBtnChange);
            async function ouiHandlerTacheBtnChange(e){
                e.preventDefault();

                var deleteidtacheStr = document.getElementById('deleteidtache').value;

                try {
                    //Delete
                    var doc = await db.get(deleteidtacheStr);
                    var response = await db.remove(doc);
                    if(response.ok){
                        alert('Tâche Supprimée avec Succès');
                        var divClearModal = document.getElementById('modalouttache');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropouttache');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }

                        document.getElementById('seldeletetypetache').value = '';

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'block';
                        document.getElementById('crudtache').style.display = 'block';
                        document.getElementById('addtache').style.display = 'none';
                        document.getElementById('edittache').style.display = 'none';
                        document.getElementById('deletetache').style.display = 'none';
                        document.getElementById('searchtache').style.display = 'none';
                        titre.innerHTML = 'Tâches Personnelles'; 
                    }else{
                        alert("Impossible Supprimer Tâche");
                        var divClearModal = document.getElementById('modalouttache');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        document.getElementById('seldeletetypetache').value = '';

                        var divClearBackdrop = document.getElementById('backdropouttache');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'block';
                        document.getElementById('crudtache').style.display = 'block';
                        document.getElementById('addtache').style.display = 'none';
                        document.getElementById('edittache').style.display = 'none';
                        document.getElementById('deletetache').style.display = 'none';
                        document.getElementById('searchtache').style.display = 'none';
                        titre.innerHTML = 'Tâches Personnelles'; 
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
            document.getElementById('nonHandlerTacheBtn').addEventListener('click', nonHandlerTacheBtnChange);
            async function nonHandlerTacheBtnChange(e){
                e.preventDefault();

                var divClearModal = document.getElementById('modalouttache');
                while(divClearModal.hasChildNodes()) {
                    divClearModal.removeChild(divClearModal.childNodes[0]);
                }

                var divClearBackdrop = document.getElementById('backdropouttache');
                while(divClearBackdrop.hasChildNodes()) {
                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                }
            }
            document.getElementById('backdropHandlerTacheBtn').addEventListener('click', backdropHandlerTacheBtnChange);
            async function backdropHandlerTacheBtnChange(e){
                e.preventDefault();

                var divClearModal = document.getElementById('modalouttache');
                while(divClearModal.hasChildNodes()) {
                    divClearModal.removeChild(divClearModal.childNodes[0]);
                }

                var divClearBackdrop = document.getElementById('backdropouttache');
                while(divClearBackdrop.hasChildNodes()) {
                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                }
            }
        }

        /***************************
        searchtacheBtn
        *****************************/
        document.getElementById('searchtacheBtn').addEventListener('click', searchtacheBtnChange);
        async function searchtacheBtnChange(e){
            e.preventDefault();

            document.getElementById('selsearchtypetache').value = '';
            document.getElementById('searchtacheeffectue').value = '';

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'block';
            document.getElementById('crudtache').style.display = 'none';
            document.getElementById('addtache').style.display = 'none';
            document.getElementById('edittache').style.display = 'none';
            document.getElementById('deletetache').style.display = 'none';
            document.getElementById('searchtache').style.display = 'block';
            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('viewtypetache').style.display = 'block';
            document.getElementById('viewtacheeffectue').style.display = 'none';
            document.getElementById('viewtabletache').style.display = 'none';
            document.getElementById('viewtache').style.display = 'none';
            titre.innerHTML = 'Consulter Tâches';
        }
        /**nav previous page button*/
        document.getElementById('prevsearchtacheBtn_1').addEventListener('click', prevsearchtache1BtnChange);
        async function prevsearchtache1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'block';
            document.getElementById('crudtache').style.display = 'block';
            document.getElementById('addtache').style.display = 'none';
            document.getElementById('edittache').style.display = 'none';
            document.getElementById('deletetache').style.display = 'none';
            document.getElementById('searchtache').style.display = 'none';
            titre.innerHTML = 'Tâches Personnelles'; 
        }
        /***************************
        selsearchtypetache
        *****************************/
        document.getElementById('selsearchtypetache').addEventListener('change', selsearchtypetacheChange);
        async function selsearchtypetacheChange(e){
            e.preventDefault();


            var selsearchtypetacheStr = document.getElementById('selsearchtypetache').value;
            if(selsearchtypetacheStr === ''){
                alert('Sélectionner un Type');
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('viewtypetache').style.display = 'block';
                document.getElementById('viewtacheeffectue').style.display = 'none';
                document.getElementById('viewtabletache').style.display = 'none';
                document.getElementById('viewtache').style.display = 'none';
            }else{
                document.getElementById('searchtacheeffectue').value = '';
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('viewtypetache').style.display = 'block';
                document.getElementById('viewtacheeffectue').style.display = 'block';
                document.getElementById('viewtabletache').style.display = 'none';
                document.getElementById('viewtache').style.display = 'none';
            }
        }
        /***************************
        searchtacheeffectue
        *****************************/
        document.getElementById('searchtacheeffectue').addEventListener('change', searchtacheeffectueChange);
        async function searchtacheeffectueChange(e){
            e.preventDefault();

            var selsearchtypetacheStr = document.getElementById('selsearchtypetache').value;
            var searchtacheeffectueStr = document.getElementById('searchtacheeffectue').value;
            var userCodeStr = document.getElementById('userCode').innerText;
            var effectOuiStr = 'Oui';
            var effectNonStr = 'Non'; 
            var statutTacheStr = 'Oui';
            var docsSearchNon = [];
            var docsSearchOui = [];
            if(searchtacheeffectueStr === 'Non'){
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: [
                                'tachestatut',
                                'tachetype',
                                'tacheeffectue',
                                'inscritcode'
                            ]
                        }
                    });
                    result = await db.find({
                        selector: {
                            tachestatut: statutTacheStr,
                            tachetype: selsearchtypetacheStr,
                            tacheeffectue: effectNonStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsSearchNon  = result.docs;
                    var resultatSearchNon = docsSearchNon.length;
                    if(resultatSearchNon === 0){
                        alert("Pas de Tâches non Effectuées Disponibles");
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('viewtypetache').style.display = 'block';
                        document.getElementById('viewtacheeffectue').style.display = 'block';
                        document.getElementById('viewtabletache').style.display = 'none';
                        document.getElementById('viewtache').style.display = 'none';
                    }else{
                        updateSearchTacheUIWithDocs(docsSearchNon);
                        var viewcontenutacheStr = document.getElementById('viewcontenutache');
                        viewcontenutacheStr.innerText = "Tâche "+selsearchtypetacheStr;
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('viewtypetache').style.display = 'block';
                        document.getElementById('viewtacheeffectue').style.display = 'block';
                        document.getElementById('viewtabletache').style.display = 'block';
                        document.getElementById('viewtache').style.display = 'block';
                    }
                } catch (error) {
                    alert('Erreur Exécution'); 
                }
            }else if(searchtacheeffectueStr === 'Oui'){
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: [
                                'tachestatut',
                                'tachetype',
                                'tacheeffectue',
                                'inscritcode'
                            ]
                        }
                    });
                    result = await db.find({
                        selector: {
                            tachestatut: statutTacheStr,
                            tachetype: selsearchtypetacheStr,
                            tacheeffectue: effectOuiStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsSearchOui  = result.docs;
                    var resultatSearchOui = docsSearchOui.length;
                    if(resultatSearchOui === 0){
                        alert("Pas de Tâches non Effectuées Disponibles");
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('viewtypetache').style.display = 'block';
                        document.getElementById('viewtacheeffectue').style.display = 'block';
                        document.getElementById('viewtabletache').style.display = 'none';
                        document.getElementById('viewtache').style.display = 'none';
                    }else{
                        updateSearchTacheUIWithDocs(docsSearchOui);
                        var viewcontenutacheStr = document.getElementById('viewcontenutache');
                        viewcontenutacheStr.innerText = "Tâche "+selsearchtypetacheStr;
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('viewtypetache').style.display = 'block';
                        document.getElementById('viewtacheeffectue').style.display = 'block';
                        document.getElementById('viewtabletache').style.display = 'block';
                        document.getElementById('viewtache').style.display = 'block';
                    }
                } catch (error) {
                   alert('Erreur Exécution'); 
                }
            }else{
                alert("Selectionner option Oui/Non");
                document.getElementById('selsearchtypetache').value = '';
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('viewtypetache').style.display = 'block';
                document.getElementById('viewtacheeffectue').style.display = 'none';
                document.getElementById('viewtabletache').style.display = 'none';
                document.getElementById('viewtache').style.display = 'none';
            }
        }
        // Adds the document to the HTML First table.
        // If the document already exists in the table, it will be updated.
        function viewSearchtacheTableDocToTable(doc, viewSearchtacheTableBody){
            // Each row of the table has an id attribute set to the doc._id field.
            var tr = viewSearchtacheTableBody.querySelector(`#${doc._id}`);
            var searchtacheeffectueStr = document.getElementById('searchtacheeffectue').value;

            if( !tr ) {
                tr = document.createElement('tr');
                tr.id = doc._id;
            }
        
            // Remove any existing table cells.
            while(tr.hasChildNodes()) {
                tr.removeChild(tr.childNodes[0]);
            }

            // Create new table cells for text fields.
            var tdEcheance = document.createElement('td');
            var dateEcheance = new Date(doc.tacheecheance);
            tdEcheance.innerText = dateEcheance.toLocaleDateString();
            tr.appendChild(tdEcheance);

            // Create new table cells for text fields.
            var tdEnonce = document.createElement('td');
            tdEnonce.innerText = doc.tachetexte;
            var br = document.createElement("br");
            tdEnonce.appendChild(br);
            var idTache = doc._id;
            if(searchtacheeffectueStr === 'Non'){
                var button = document.createElement("button");
                button.id = "ouitacheBtn";
                button.type = 'button';
                button.innerHTML = 'Effectuée';
                button.onclick = async function(){
                    var inscritCodeStr,tacheTypeStr,tacheEcheanceStr,tacheTexteStr,
                    tacheDateStr,tacheStatutStr = '';
                    var tacheEffectueStr = 'Oui';
                    var docsTache = [];
                    try {
                        let result = await db.createIndex({
                            index: {
                                fields: ['_id']
                            }
                        });
                        result = await db.find({
                            selector: {
                                _id: idTache
                            }
                        });
                        docsTache  = result.docs;
                        var resultatTache = docsTache.length;
                        if(resultatTache === 0){
                            alert("Tâche n'existe pas");
                        }else{
                            for(let i=0; i<docsTache.length; i++){
                                inscritCodeStr = docsTache[i].inscritcode;
                                tacheTypeStr = docsTache[i].tachetype;
                                tacheEcheanceStr = docsTache[i].tacheecheance;
                                tacheTexteStr = docsTache[i].tachetexte;
                                tacheDateStr = docsTache[i].tachedate;
                                tacheStatutStr = docsTache[i].tachestatut;
                            }

                            // Retrieve the document to get the latest revision
                            var id = idTache;
                            var docModifier = await db.get(id);
                            // Make changes
                            docModifier.inscritcode = inscritCodeStr;
                            docModifier.tachetype = tacheTypeStr;
                            docModifier.tacheecheance = tacheEcheanceStr;
                            docModifier.tachetexte = tacheTexteStr;
                            docModifier.tacheeffectue = tacheEffectueStr;
                            docModifier.tachedate = tacheDateStr;
                            docModifier.tachestatut = tacheStatutStr;
                            // Put the document, saving it as a new revision
                            var response = await db.put(docModifier);
                            // Get the document we change and update the UI.
                            var docEdit = await db.get(response.id);
                            if(docEdit !== ''){
                                alert("Action Effectuée");
                                document.getElementById('selsearchtypetache').value = '';
                                document.getElementById('titre').style.display = 'block';
                                var titre = document.getElementById('titre');

                                document.getElementById('demarrage').style.display = 'none';
                                document.getElementById('tableaubord').style.display = 'none';
                                document.getElementById('dashboard').style.display = 'none';
                                document.getElementById('configurations').style.display = 'none';
                                document.getElementById('configurer').style.display = 'none';
                                document.getElementById('emploidutemps').style.display = 'none';
                                document.getElementById('exercice').style.display = 'none';
                                document.getElementById('pratique').style.display = 'none';
                                document.getElementById('tache').style.display = 'block';
                                document.getElementById('crudtache').style.display = 'block';
                                document.getElementById('addtache').style.display = 'none';
                                document.getElementById('edittache').style.display = 'none';
                                document.getElementById('deletetache').style.display = 'none';
                                document.getElementById('searchtache').style.display = 'none';
                                titre.innerHTML = 'Tâches Personnelles'; 
                            }else{
                                alert("Erreur Accomplissement Action. Reprendre");
                                document.getElementById('selsearchtypetache').value = '';
                                document.getElementById('titre').style.display = 'block';
                                var titre = document.getElementById('titre');

                                document.getElementById('demarrage').style.display = 'none';
                                document.getElementById('tableaubord').style.display = 'none';
                                document.getElementById('dashboard').style.display = 'none';
                                document.getElementById('configurations').style.display = 'none';
                                document.getElementById('configurer').style.display = 'none';
                                document.getElementById('emploidutemps').style.display = 'none';
                                document.getElementById('exercice').style.display = 'none';
                                document.getElementById('pratique').style.display = 'none';
                                document.getElementById('tache').style.display = 'block';
                                document.getElementById('crudtache').style.display = 'block';
                                document.getElementById('addtache').style.display = 'none';
                                document.getElementById('edittache').style.display = 'none';
                                document.getElementById('deletetache').style.display = 'none';
                                document.getElementById('searchtache').style.display = 'none';
                                titre.innerHTML = 'Tâches Personnelles'; 
                            }
                        }
                    } catch (error) {
                        alert('Erreur Exécution'); 
                    }
                }
            }else{
                var button = document.createElement("button");
                button.id = "nontacheBtn";
                button.type = 'button';
                button.innerHTML = 'Supprimer';
                button.onclick = async function(){
                    var divClearModal = document.getElementById('modaloutsearchtache');
                    let divModal = document.createElement('div');
                    divModal.innerHTML =
                    `<div class="modal">
                    <p>Êtes-vous sûr de Supprimer Cette Tâche ?</p>
                    <button class="btn btn--alt" id="ouiHandlerSearchTacheBtn">Oui</button>
                    <button class="btn" id="nonHandlerSearchTacheBtn">Non</button>
                    </div>`;
                    divClearModal.appendChild(divModal);

                    var divClearBackdrop = document.getElementById('backdropoutsearchtache');
                    let divBack = document.createElement('div');
                    divBack.innerHTML =
                    `<div class="backdrop" id="backdropHandlerSearchTacheBtn"></div>`;
                    divClearBackdrop.appendChild(divBack);

                    document.getElementById('ouiHandlerSearchTacheBtn').addEventListener('click', ouiHandlerSearchTacheBtnChange);
                    async function ouiHandlerSearchTacheBtnChange(e){
                        e.preventDefault();

                        var deleteidtacheStr = idTache;
                        try {
                            //Delete
                            var doc = await db.get(deleteidtacheStr);
                            var response = await db.remove(doc);
                            if(response.ok){
                                alert('Tâche Supprimée avec Succès');
                                var divClearModal = document.getElementById('modaloutsearchtache');
                                while(divClearModal.hasChildNodes()) {
                                    divClearModal.removeChild(divClearModal.childNodes[0]);
                                }

                                var divClearBackdrop = document.getElementById('backdropoutsearchtache');
                                while(divClearBackdrop.hasChildNodes()) {
                                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                }

                                document.getElementById('selsearchtypetache').value = '';
                                document.getElementById('titre').style.display = 'block';
                                var titre = document.getElementById('titre');

                                document.getElementById('demarrage').style.display = 'none';
                                document.getElementById('tableaubord').style.display = 'none';
                                document.getElementById('dashboard').style.display = 'none';
                                document.getElementById('configurations').style.display = 'none';
                                document.getElementById('configurer').style.display = 'none';
                                document.getElementById('emploidutemps').style.display = 'none';
                                document.getElementById('exercice').style.display = 'none';
                                document.getElementById('pratique').style.display = 'none';
                                document.getElementById('tache').style.display = 'block';
                                document.getElementById('crudtache').style.display = 'block';
                                document.getElementById('addtache').style.display = 'none';
                                document.getElementById('edittache').style.display = 'none';
                                document.getElementById('deletetache').style.display = 'none';
                                document.getElementById('searchtache').style.display = 'none';
                                titre.innerHTML = 'Tâches Personnelles'; 
                            }else{
                                alert("Impossible Supprimer cette Tâche");
                                document.getElementById('selsearchtypetache').value = '';
                                document.getElementById('titre').style.display = 'block';
                                var titre = document.getElementById('titre');

                                document.getElementById('demarrage').style.display = 'none';
                                document.getElementById('tableaubord').style.display = 'none';
                                document.getElementById('dashboard').style.display = 'none';
                                document.getElementById('configurations').style.display = 'none';
                                document.getElementById('configurer').style.display = 'none';
                                document.getElementById('emploidutemps').style.display = 'none';
                                document.getElementById('exercice').style.display = 'none';
                                document.getElementById('pratique').style.display = 'none';
                                document.getElementById('tache').style.display = 'block';
                                document.getElementById('crudtache').style.display = 'block';
                                document.getElementById('addtache').style.display = 'none';
                                document.getElementById('edittache').style.display = 'none';
                                document.getElementById('deletetache').style.display = 'none';
                                document.getElementById('searchtache').style.display = 'none';
                                titre.innerHTML = 'Tâches Personnelles'; 
                            }
                        } catch (error) {
                            alert('Erreur Exécution');
                        }
                    }
                    document.getElementById('nonHandlerSearchTacheBtn').addEventListener('click', nonHandlerSearchTacheBtnChange);
                    async function nonHandlerSearchTacheBtnChange(e){
                        e.preventDefault();

                        var divClearModal = document.getElementById('modaloutsearchtache');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutsearchtache');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }
                    }
                    document.getElementById('backdropHandlerSearchTacheBtn').addEventListener('click', backdropHandlerSearchTacheBtnChange);
                    async function backdropHandlerSearchTacheBtnChange(e){
                        e.preventDefault();

                        var divClearModal = document.getElementById('modaloutsearchtache');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutsearchtache');
                        while(divClearBackdrop.hasChildNodes()) {
                             divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }
                    }
                }
            }
            tdEnonce.appendChild(button);
            tr.appendChild(tdEnonce);

            // Add the row to the top of the table.
            viewSearchtacheTableBody.insertBefore(tr, viewSearchtacheTableBody.nextSibling);
        }
        /**Clear and update the UI to display the docs.*/
        function updateSearchTacheUIWithDocs(docs) {
            var viewSearchtacheTableBody = document.getElementById('viewSearchtacheTableBody');
            
            // Remove all child nodes from the table
            while(viewSearchtacheTableBody.hasChildNodes()) {
                viewSearchtacheTableBody.removeChild(viewSearchtacheTableBody.childNodes[0]);
            }
            
            // Add the new documents to the table
            for(var i=0; i<docs.length; i++) {
                var doc = docs[i];
                viewSearchtacheTableDocToTable(doc, viewSearchtacheTableBody);
            }
        }
        /***************************
        consultertacheBtn
        *****************************/
        document.getElementById('consultertacheBtn').addEventListener('click', consultertacheBtnChange);
        async function consultertacheBtnChange(e){
            e.preventDefault();

            document.getElementById('selsearchtypetache').value = '';
            document.getElementById('searchtacheeffectue').value = '';
            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'block';
            document.getElementById('crudtache').style.display = 'block';
            document.getElementById('addtache').style.display = 'none';
            document.getElementById('edittache').style.display = 'none';
            document.getElementById('deletetache').style.display = 'none';
            document.getElementById('searchtache').style.display = 'none';
            titre.innerHTML = 'Tâches Personnelles'; 
        }
    };

    /****************
    HELPER FUNCTION
    *****************/
    /**1: Get Current Date in JavaScript */
    function getCurrentDate(){
        // Date object
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1;
        var yyyy = today.getFullYear();
        if(dd<10){
            dd='0'+dd;
        }
        if(mm<10){
            mm='0'+mm;
        }
        // Current Date
        var date = yyyy+'-'+mm+'-'+dd;
        return date;
    }

    window.addEventListener('load', () =>{
        new taskTache();
    })
}) ();