import CrudMoyMatiere from './CrudMoyMatiere';
import AddMoyMatiere from './AddMoyMatiere';
import EditMoyMatiere from './EditMoyMatiere';
import DeleteMoyMatiere from './DeleteMoyMatiere';
import SearchMoyMatiere from './SearchMoyMatiere';

function MoyMatiere() {
    return ( 
        <div id="moymatiere">
            <CrudMoyMatiere />
            <AddMoyMatiere />
            <EditMoyMatiere />
            <DeleteMoyMatiere />
            <SearchMoyMatiere />
        </div>
    );
}

export default MoyMatiere;