(function(){
    var taskMoteur = function(){
        /***************
        crudmoyannuelleBtn
        *****************/
        /**nav previous page button*/
        document.getElementById('prevcrudmoteurBtn_1').addEventListener('click', prevcrudmoteur1BtnChange);
        async function prevcrudmoteur1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'block';
            document.getElementById('dashboard').style.display = 'block';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'none';
            document.getElementById('moyenne').style.display = 'none';
            document.getElementById('moteur').style.display = 'none';
            titre.innerHTML = 'Tableau de Bord';
        }


        /***************************
        addwikipediaBtn
        *****************************/
        document.getElementById('addwikipediaBtn').addEventListener('click', addwikipediaBtnChange);
        async function addwikipediaBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'none';
            document.getElementById('moyenne').style.display = 'none';
            document.getElementById('moteur').style.display = 'block';
            document.getElementById('crudmoteur').style.display = 'none';
            document.getElementById('addwikipedia').style.display = 'block';
            titre.innerHTML = 'Recherches sur Wikipedia';
        }
        /**nav previous page button*/
        document.getElementById('prevaddwikipediaBtn_1').addEventListener('click', prevaddwikipedia1BtnChange);
        async function prevaddwikipedia1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'none';
            document.getElementById('moyenne').style.display = 'none';
            document.getElementById('moteur').style.display = 'block';
            document.getElementById('crudmoteur').style.display = 'block';
            document.getElementById('addwikipedia').style.display = 'none';
            titre.innerHTML = 'Moteur de Recherche';
        }
    }

    window.addEventListener('load', () =>{
        new taskMoteur();
    })
}) ();