import './pouchtableaubord';

import { 
    KeyboardBackspace,
    Settings,
    List,
    Assignment,
    Sort,
    Subject,
    Storage,
    Search,
    NavigateNext, 
} from '@material-ui/icons';

function Dashboard() {
    return ( 
        <div id="dashboard">
            <KeyboardBackspace style={prevArrows} id="prevdashbordBtn_1" />
            <br/><br/>
            <div id="configdashboard" style={scroller}>
                <div>
                    <button id="configurationBtn" style={configurationButton}>
                        <Settings style={{float:'left',color:'1a1aff'}} />
                        Configuration Suclu
                        <NavigateNext style={{float:'right',color:'#1a1aff'}} />
                    </button>
                </div>
                <div>
                    <button id="emploitempsBtn" style={configurationButton}>
                        <List style={{float:'left',color:'1a1aff'}} />
                        Emploi du Temps
                        <NavigateNext style={{float:'right',color:'#1a1aff'}} />
                    </button>
                </div>
                <div>
                    <button id="exercicestachesBtn" style={configurationButton}>
                        <Assignment style={{float:'left',color:'1a1aff'}} />
                        Exercices et Tâches
                        <NavigateNext style={{float:'right',color:'#1a1aff'}} />
                    </button>
                </div>
                <div>
                    <button id="programmedevoirsBtn" style={configurationButton}>
                        <Sort style={{float:'left',color:'1a1aff'}} />
                        Programme des Devoirs
                        <NavigateNext style={{float:'right',color:'#1a1aff'}} />
                    </button>
                </div>
                <div>
                    <button id="noteclasseBtn" style={configurationButton}>
                        <Subject style={{float:'left',color:'1a1aff'}} />
                        Notes de Classe
                        <NavigateNext style={{float:'right',color:'#1a1aff'}} />
                    </button>
                </div>
                <div>
                    <button id="calculmoyennesBtn" style={configurationButton}>
                        <Storage style={{float:'left',color:'1a1aff'}} />
                        Calcul Moyennes
                        <NavigateNext style={{float:'right',color:'#1a1aff'}} />
                    </button>
                </div>
                <div>
                    <button id="moteurBtn" style={configurationButton}>
                        <Search style={{float:'left',color:'1a1aff'}} />
                        Moteurs de Recherches
                        <NavigateNext style={{float:'right',color:'#1a1aff'}} />
                    </button>
                </div>
                <br/><br/>
            </div>
        </div>
    );
}

//Inline Styling
const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '400px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

const configurationButton = {
    marginTop: '4%',
    flex: 1,
    width: '90%',
    height: 50,
    textAlign: "center",
    alignContent: 'center',
    border: "2px solid #1a1aff",
    fontSize: 18,
    borderRadius: 10,
    color: "#1a1aff",
    //textTransform: "uppercase",
}

export default Dashboard;