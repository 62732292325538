/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable no-redeclare */
import PouchDB from 'pouchdb';
import PouchFind from 'pouchdb-find';

(function(){
    var taskConfigSemaine = function(){
        /*****************************************************
        ENABLING POUCHDB WITHOUT SYNC TO REMOTE COUCHDB
        ******************************************************/
        //SET UP POUCHDB AND CREATE THE DATABASE 
        const db = new PouchDB('suclu');
        PouchDB.plugin(PouchFind);

        /***************
        crudsemaineBtn
        *****************/
        /**nav previous page button*/
        document.getElementById('prevcrudsemaineBtn_1').addEventListener('click', prevcrudsemaine1BtnChange);
        async function prevcrudsemaine1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'block';
            document.getElementById('configurer').style.display = 'block';
            document.getElementById('semaine').style.display = 'none';
            document.getElementById('crudsemaine').style.display = 'none';
            document.getElementById('addsemaine').style.display = 'none';
            document.getElementById('editsemaine').style.display = 'none';
            document.getElementById('deletesemaine').style.display = 'none';
            document.getElementById('searchsemaine').style.display = 'none';
            titre.innerHTML = 'Configuration App'; 
        }

        /***************************
        addsemaineBtn
        *****************************/
        document.getElementById('addsemaineBtn').addEventListener('click', addsemaineBtnChange);
        async function addsemaineBtnChange(e){
            e.preventDefault();

            document.getElementById('seladdjoursemaine').value = '';

            var userCodeStr = document.getElementById('userCode').innerText;
            if(!userCodeStr){
                alert('Connectez-vous à votre Compte avant de continuer');
            }else{
                document.getElementById('titre').style.display = 'block';
                var titre = document.getElementById('titre');

                document.getElementById('demarrage').style.display = 'none';
                document.getElementById('tableaubord').style.display = 'none';
                document.getElementById('dashboard').style.display = 'none';
                document.getElementById('configurations').style.display = 'block';
                document.getElementById('configurer').style.display = 'none';
                document.getElementById('semaine').style.display = 'block';
                document.getElementById('crudsemaine').style.display = 'none';
                document.getElementById('addsemaine').style.display = 'block';
                document.getElementById('editsemaine').style.display = 'none';
                document.getElementById('deletesemaine').style.display = 'none';
                document.getElementById('searchsemaine').style.display = 'none';
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('saveheuredebutsemaine').style.display = 'none';
                document.getElementById('saveheurefinsemaine').style.display = 'none';
                document.getElementById('savesemaine').style.display = 'none';
                titre.innerHTML = 'Enregistrer Jour de Classe'; 
            }
        }
        document.getElementById('seladdjoursemaine').addEventListener('change', seladdjoursemaineChange);
        async function seladdjoursemaineChange(e){
            e.preventDefault();

            var numberDay = 0;
            var seladdjoursemaineStr = document.getElementById('seladdjoursemaine').value;
            if(!seladdjoursemaineStr){
                alert('Sélectionner Jour de Classe');
                document.getElementById('seladdjoursemaine').value = '';
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('saveheuredebutsemaine').style.display = 'none';
                document.getElementById('saveheurefinsemaine').style.display = 'none';
                document.getElementById('savesemaine').style.display = 'none';
            }else{
                if(seladdjoursemaineStr === 'Lundi'){
                    numberDay = 1;
                }
                if(seladdjoursemaineStr === 'Mardi'){
                    numberDay = 2;
                }
                if(seladdjoursemaineStr === 'Mercredi'){
                    numberDay = 3;
                }
                if(seladdjoursemaineStr === 'Jeudi'){
                    numberDay = 4;
                }
                if(seladdjoursemaineStr === 'Vendredi'){
                    numberDay = 5;
                }
                if(seladdjoursemaineStr === 'Samedi'){
                    numberDay = 6;
                }

                var divClearModal = document.getElementById('modaloutsavesemaine');
                let divModal = document.createElement('div');
                divModal.innerHTML =
                `<div class="modal">
                    <p>Avez-vous classe le ${seladdjoursemaineStr} ?</p>
                    <button class="btn btn--alt" id="ouiHandlerSaveSemaineBtn">Oui</button>
                    <button class="btn" id="nonHandlerSaveSemaineBtn">Non</button>
                </div>`;
                divClearModal.appendChild(divModal);

                var divClearBackdrop = document.getElementById('backdropoutsavesemaine');
                let divBack = document.createElement('div');
                divBack.innerHTML =
                `<div class="backdrop" id="backdropHandlerSaveSemaineBtn"></div>`;
                divClearBackdrop.appendChild(divBack);

                document.getElementById('ouiHandlerSaveSemaineBtn').addEventListener('click',ouiHandlerSaveSemaineBtnChange);
                async function ouiHandlerSaveSemaineBtnChange(e){
                    e.preventDefault();

                    var userCodeStr = document.getElementById('userCode').innerText;
                    var semaineStatutStr = '';
                    var semaineNewStatutStr = '';
                    var docsNumero = [];
                    var docsPreviousNumero = [];
                    var docsNewNumero = [];
                    var substractNumero = 0;

                    if(numberDay === 1){
                        //check if already inserted
                        try {
                            let resultNumero = await db.createIndex({
                                index: {
                                    fields: ['inscritcode', 'semainenumero']
                                }
                            });
                            resultNumero = await db.find({
                                selector: {
                                    inscritcode: userCodeStr,
                                    semainenumero: numberDay
                                }
                            });
                            docsNumero = resultNumero.docs;
                            var resultatNumero = docsNumero.length;
                            if(resultatNumero === 1){
                                //retireve semainestatut
                                for(let i=0; i<docsNumero.length; i++){
                                    semaineStatutStr = docsNumero[i].semainestatut;
                                }
                                if(semaineStatutStr === 'Oui'){
                                    alert("Jour de Classe "+seladdjoursemaineStr+" déjà Enregistré.");
                                    document.getElementById('seladdjoursemaine').value = '';
                                    document.getElementById('addnumerosemaine').value = '';
                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('saveheuredebutsemaine').style.display = 'none';
                                    document.getElementById('saveheurefinsemaine').style.display = 'none';
                                    document.getElementById('savesemaine').style.display = 'none';
                                }else if(semaineStatutStr === 'Non'){
                                    alert("Clic Modifier Jour de Classe pour Changer "+seladdjoursemaineStr+" comme Jour de Classe");
                                    document.getElementById('addnumerosemaine').value = '';

                                    document.getElementById('titre').style.display = 'block';
                                    var titre = document.getElementById('titre');

                                    document.getElementById('demarrage').style.display = 'none';
                                    document.getElementById('tableaubord').style.display = 'none';
                                    document.getElementById('dashboard').style.display = 'none';
                                    document.getElementById('configurations').style.display = 'none';
                                    document.getElementById('configurer').style.display = 'none';
                                    document.getElementById('semaine').style.display = 'block';
                                    document.getElementById('crudsemaine').style.display = 'block';
                                    document.getElementById('addsemaine').style.display = 'none';
                                    document.getElementById('editsemaine').style.display = 'none';
                                    document.getElementById('deletesemaine').style.display = 'none';
                                    document.getElementById('searchsemaine').style.display = 'none';
                                    titre.innerHTML = 'Configuration Jours de Classe';
                                }
                            }else if(resultatNumero === 0){
                                //go to insert
                                document.getElementById('addnumerosemaine').value = numberDay;
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('saveheuredebutsemaine').style.display = 'block';
                                document.getElementById('saveheurefinsemaine').style.display = 'block';
                                document.getElementById('savesemaine').style.display = 'block';
                            }
                        } catch (error) {
                            alert('Erreur Exécution');
                        }
                    }else if(numberDay > 1){
                        substractNumero = numberDay - 1;
                        try {
                            let resultPreviousNumero = await db.createIndex({
                                index: {
                                    fields: ['inscritcode', 'semainenumero']
                                }
                            });
                            resultPreviousNumero = await db.find({
                                selector: {
                                    inscritcode: userCodeStr,
                                    semainenumero: substractNumero
                                }
                            });
                            docsPreviousNumero = resultPreviousNumero.docs;
                            var resultatPreviousNumero = docsPreviousNumero.length;
                            if(resultatPreviousNumero === 1){
                                //check if new semainenumero exists
                                let resultNewNumero = await db.createIndex({
                                    index: {
                                        fields: ['inscritcode', 'semainenumero']
                                    }
                                });
                                resultNewNumero = await db.find({
                                    selector: {
                                        inscritcode: userCodeStr,
                                        semainenumero: numberDay
                                    }
                                });
                                docsNewNumero = resultNewNumero.docs;
                                var resultatNewNumero = docsNewNumero.length;
                                if(resultatNewNumero === 0){
                                    //go to insert
                                    document.getElementById('addnumerosemaine').value = numberDay;
                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('saveheuredebutsemaine').style.display = 'block';
                                    document.getElementById('saveheurefinsemaine').style.display = 'block';
                                    document.getElementById('savesemaine').style.display = 'block';
                                }else if(resultatNewNumero === 1){
                                    //retireve semainestatut
                                    for(let j=0; j<docsNewNumero.length; j++){
                                        semaineNewStatutStr = docsNewNumero[j].semainestatut;
                                    }
                                    if(semaineNewStatutStr === 'Oui'){
                                        alert("Jour de Classe "+seladdjoursemaineStr+" déjà Enregistré.");
                                        document.getElementById('seladdjoursemaine').value = '';
                                        document.getElementById('addnumerosemaine').value = '';
                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('saveheuredebutsemaine').style.display = 'none';
                                        document.getElementById('saveheurefinsemaine').style.display = 'none';
                                        document.getElementById('savesemaine').style.display = 'none';
                                    }else if(semaineNewStatutStr === 'Non'){
                                        alert("Clic Modifier Jour de Classe pour Changer "+seladdjoursemaineStr+" comme Jour de Classe");
                                        document.getElementById('addnumerosemaine').value = '';

                                        document.getElementById('titre').style.display = 'block';
                                        var titre = document.getElementById('titre');

                                        document.getElementById('demarrage').style.display = 'none';
                                        document.getElementById('tableaubord').style.display = 'none';
                                        document.getElementById('dashboard').style.display = 'none';
                                        document.getElementById('configurations').style.display = 'none';
                                        document.getElementById('configurer').style.display = 'none';
                                        document.getElementById('semaine').style.display = 'block';
                                        document.getElementById('crudsemaine').style.display = 'block';
                                        document.getElementById('addsemaine').style.display = 'none';
                                        document.getElementById('editsemaine').style.display = 'none';
                                        document.getElementById('deletesemaine').style.display = 'none';
                                        document.getElementById('searchsemaine').style.display = 'none';
                                        titre.innerHTML = 'Configuration Jours de Classe';
                                    }
                                }
                            }else if(resultatPreviousNumero === 0){
                                alert("Valider Oui/Non Jour de Classe Précédent à "+seladdjoursemaineStr+" avant");
                                document.getElementById('seladdjoursemaine').value = '';
                                document.getElementById('addnumerosemaine').value = '';
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('saveheuredebutsemaine').style.display = 'none';
                                document.getElementById('saveheurefinsemaine').style.display = 'none';
                                document.getElementById('savesemaine').style.display = 'none';
                            }
                        } catch (error) {
                            alert('Erreur Exécution');
                        }
                    }

                    var divClearModal = document.getElementById('modaloutsavesemaine');
                    while(divClearModal.hasChildNodes()) {
                        divClearModal.removeChild(divClearModal.childNodes[0]);
                    }

                    var divClearBackdrop = document.getElementById('backdropoutsavesemaine');
                    while(divClearBackdrop.hasChildNodes()) {
                        divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                    }
                }

                document.getElementById('nonHandlerSaveSemaineBtn').addEventListener('click', nonHandlerSaveSemaineBtnChange);
                async function nonHandlerSaveSemaineBtnChange(e){
                    e.preventDefault();

                    var seladdjoursemaineStr = document.getElementById('seladdjoursemaine').value;
                    var userCodeStr = document.getElementById('userCode').innerText;
                    var semaineStatutStr = '';
                    var semaineNewStatutStr = '';
                    var docsNumero = [];
                    var docsPreviousNumero = [];
                    var docsNewNumero = [];
                    var substractNumero = 0;

                    if(numberDay === 1){
                        //check if already inserted
                        try {
                            let resultNumero = await db.createIndex({
                                index: {
                                    fields: ['inscritcode', 'semainenumero']
                                }
                            });
                            resultNumero = await db.find({
                                selector: {
                                    inscritcode: userCodeStr,
                                    semainenumero: numberDay
                                }
                            });
                            docsNumero = resultNumero.docs;
                            var resultatNumero = docsNumero.length;
                            if(resultatNumero === 1){
                                //retireve semainestatut
                                for(let i=0; i<docsNumero.length; i++){
                                    semaineStatutStr = docsNumero[i].semainestatut;
                                }
                                if(semaineStatutStr === 'Oui'){
                                    alert("Clic Modifier Jour de Classe pour Changer "+seladdjoursemaineStr+" comme Non Jour de Classe");
                                    document.getElementById('addnumerosemaine').value = '';

                                    document.getElementById('titre').style.display = 'block';
                                    var titre = document.getElementById('titre');

                                    document.getElementById('demarrage').style.display = 'none';
                                    document.getElementById('tableaubord').style.display = 'none';
                                    document.getElementById('dashboard').style.display = 'none';
                                    document.getElementById('configurations').style.display = 'none';
                                    document.getElementById('configurer').style.display = 'none';
                                    document.getElementById('semaine').style.display = 'block';
                                    document.getElementById('crudsemaine').style.display = 'block';
                                    document.getElementById('addsemaine').style.display = 'none';
                                    document.getElementById('editsemaine').style.display = 'none';
                                    document.getElementById('deletesemaine').style.display = 'none';
                                    document.getElementById('searchsemaine').style.display = 'none';
                                    titre.innerHTML = 'Configuration Jours de Classe';
                                }else if(semaineStatutStr === 'Non'){
                                    alert("Clic Modifier Jour de Classe pour Changer "+seladdjoursemaineStr+" comme Jour de Classe");
                                    document.getElementById('addnumerosemaine').value = '';

                                    document.getElementById('titre').style.display = 'block';
                                    var titre = document.getElementById('titre');

                                    document.getElementById('demarrage').style.display = 'none';
                                    document.getElementById('tableaubord').style.display = 'none';
                                    document.getElementById('dashboard').style.display = 'none';
                                    document.getElementById('configurations').style.display = 'none';
                                    document.getElementById('configurer').style.display = 'none';
                                    document.getElementById('semaine').style.display = 'block';
                                    document.getElementById('crudsemaine').style.display = 'block';
                                    document.getElementById('addsemaine').style.display = 'none';
                                    document.getElementById('editsemaine').style.display = 'none';
                                    document.getElementById('deletesemaine').style.display = 'none';
                                    document.getElementById('searchsemaine').style.display = 'none';
                                    titre.innerHTML = 'Configuration Jours de Classe';
                                }
                            }else if(resultatNumero === 0){
                                //insert this days with heuredebut and heurefin = 00:00 and semainestatut = Non
                                var usercodeStr = document.getElementById('userCode').innerText;
                                var addnumerosemaineStr = numberDay;
                                var seladdjoursemaineStr = document.getElementById('seladdjoursemaine').value;
                                var addheuredebutsemaineStr = '00:00';
                                var addheurefinsemaineStr = '00:00';
                                var datejourStr = getCurrentDate();
                                var addstatutsemaineStr = 'Non';

                                //Create the document _id and _rev.
                                var now = Date.now();
                                var id = 'semaine_' + now;
                                // Add the document to the database.
                                var response = await db.put({
                                    _id: id,
                                    inscritcode: usercodeStr,
                                    semainenumero: parseFloat(addnumerosemaineStr),
                                    semainejour: seladdjoursemaineStr,
                                    semainedebut: addheuredebutsemaineStr,
                                    semainefin: addheurefinsemaineStr,
                                    semainedate: datejourStr,
                                    semainestatut: addstatutsemaineStr,
                                });
                                // check if document inserted and synced
                                var doc = await db.get(response.id);
                                if(doc !== ''){
                                    alert("Jour "+seladdjoursemaineStr+" Validé Non Jour de Classe");
                                    document.getElementById('seladdjoursemaine').value = '';
                                    document.getElementById('addheuredebutsemaine').value = '';
                                    document.getElementById('addheurefinsemaine').value = '';
                                    document.getElementById('addnumerosemaine').value = '';
                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('saveheuredebutsemaine').style.display = 'none';
                                    document.getElementById('saveheurefinsemaine').style.display = 'none';
                                    document.getElementById('savesemaine').style.display = 'none';
                                }else{
                                    alert(" Echec Validation Jour "+seladdjoursemaineStr+" Non Jour de Classe");
                                    document.getElementById('seladdjoursemaine').value = '';
                                    document.getElementById('addheuredebutsemaine').value = '';
                                    document.getElementById('addheurefinsemaine').value = '';
                                    document.getElementById('addnumerosemaine').value = '';
                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('saveheuredebutsemaine').style.display = 'none';
                                    document.getElementById('saveheurefinsemaine').style.display = 'none';
                                    document.getElementById('savesemaine').style.display = 'none';
                                }
                            }
                        } catch (error) {
                            alert('Erreur Exécution');
                        }
                    }else if(numberDay > 1){
                        substractNumero = numberDay - 1;
                        try {
                            let resultPreviousNumero = await db.createIndex({
                                index: {
                                    fields: ['inscritcode', 'semainenumero']
                                }
                            });
                            resultPreviousNumero = await db.find({
                                selector: {
                                    inscritcode: userCodeStr,
                                    semainenumero: substractNumero
                                }
                            });
                            docsPreviousNumero = resultPreviousNumero.docs;
                            var resultatPreviousNumero = docsPreviousNumero.length;
                            if(resultatPreviousNumero === 1){
                                //check if new semainenumero exists
                                let resultNewNumero = await db.createIndex({
                                    index: {
                                        fields: ['inscritcode', 'semainenumero']
                                    }
                                });
                                resultNewNumero = await db.find({
                                    selector: {
                                        inscritcode: userCodeStr,
                                        semainenumero: numberDay
                                    }
                                });
                                docsNewNumero = resultNewNumero.docs;
                                var resultatNewNumero = docsNewNumero.length;
                                if(resultatNewNumero === 0){
                                    //insert this days with heuredebut and heurefin = 00:00 and semainestatut = Non
                                    var usercodeStr = document.getElementById('userCode').innerText;
                                    var addnumerosemaineStr = numberDay;
                                    var seladdjoursemaineStr = document.getElementById('seladdjoursemaine').value;
                                    var addheuredebutsemaineStr = '00:00';
                                    var addheurefinsemaineStr = '00:00';
                                    var datejourStr = getCurrentDate();
                                    var addstatutsemaineStr = 'Non';

                                    //Create the document _id and _rev.
                                    var now = Date.now();
                                    var id = 'semaine_' + now;
                                    // Add the document to the database.
                                    var response = await db.put({
                                        _id: id,
                                        inscritcode: usercodeStr,
                                        semainenumero: parseFloat(addnumerosemaineStr),
                                        semainejour: seladdjoursemaineStr,
                                        semainedebut: addheuredebutsemaineStr,
                                        semainefin: addheurefinsemaineStr,
                                        semainedate: datejourStr,
                                        semainestatut: addstatutsemaineStr,
                                    });
                                    // check if document inserted and synced
                                    var doc = await db.get(response.id);
                                    if(doc !== ''){
                                        alert("Jour "+seladdjoursemaineStr+" Validé Non Jour de Classe");
                                        document.getElementById('seladdjoursemaine').value = '';
                                        document.getElementById('addheuredebutsemaine').value = '';
                                        document.getElementById('addheurefinsemaine').value = '';
                                        document.getElementById('addnumerosemaine').value = '';
                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('saveheuredebutsemaine').style.display = 'none';
                                        document.getElementById('saveheurefinsemaine').style.display = 'none';
                                        document.getElementById('savesemaine').style.display = 'none';
                                    }else{
                                        alert(" Echec Validation Jour "+seladdjoursemaineStr+" Non Jour de Classe");
                                        document.getElementById('seladdjoursemaine').value = '';
                                        document.getElementById('addheuredebutsemaine').value = '';
                                        document.getElementById('addheurefinsemaine').value = '';
                                        document.getElementById('addnumerosemaine').value = '';
                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('saveheuredebutsemaine').style.display = 'none';
                                        document.getElementById('saveheurefinsemaine').style.display = 'none';
                                        document.getElementById('savesemaine').style.display = 'none';
                                    }
                                }else if(resultatNewNumero === 1){
                                    //retireve semainestatut
                                    for(let j=0; j<docsNewNumero.length; j++){
                                        semaineNewStatutStr = docsNewNumero[j].semainestatut;
                                    }
                                    if(semaineNewStatutStr === 'Oui'){
                                        alert("Clic Modifier Jour de Classe pour Changer "+seladdjoursemaineStr+" comme Non Jour de Classe");
                                        document.getElementById('addnumerosemaine').value = '';

                                        document.getElementById('titre').style.display = 'block';
                                        var titre = document.getElementById('titre');

                                        document.getElementById('demarrage').style.display = 'none';
                                        document.getElementById('tableaubord').style.display = 'none';
                                        document.getElementById('dashboard').style.display = 'none';
                                        document.getElementById('configurations').style.display = 'none';
                                        document.getElementById('configurer').style.display = 'none';
                                        document.getElementById('semaine').style.display = 'block';
                                        document.getElementById('crudsemaine').style.display = 'block';
                                        document.getElementById('addsemaine').style.display = 'none';
                                        document.getElementById('editsemaine').style.display = 'none';
                                        document.getElementById('deletesemaine').style.display = 'none';
                                        document.getElementById('searchsemaine').style.display = 'none';
                                        titre.innerHTML = 'Configuration Jours de Classe';
                                    }else if(semaineNewStatutStr === 'Non'){
                                        alert("Clic Modifier Jour de Classe pour Changer "+seladdjoursemaineStr+" comme Jour de Classe");
                                        document.getElementById('addnumerosemaine').value = '';

                                        document.getElementById('titre').style.display = 'block';
                                        var titre = document.getElementById('titre');

                                        document.getElementById('demarrage').style.display = 'none';
                                        document.getElementById('tableaubord').style.display = 'none';
                                        document.getElementById('dashboard').style.display = 'none';
                                        document.getElementById('configurations').style.display = 'none';
                                        document.getElementById('configurer').style.display = 'none';
                                        document.getElementById('semaine').style.display = 'block';
                                        document.getElementById('crudsemaine').style.display = 'block';
                                        document.getElementById('addsemaine').style.display = 'none';
                                        document.getElementById('editsemaine').style.display = 'none';
                                        document.getElementById('deletesemaine').style.display = 'none';
                                        document.getElementById('searchsemaine').style.display = 'none';
                                        titre.innerHTML = 'Configuration Jours de Classe';
                                    }
                                }
                            }else if(resultatPreviousNumero === 0){
                                alert("Valider Oui/Non Jour de Classe Précédent à "+seladdjoursemaineStr+" avant");
                                document.getElementById('seladdjoursemaine').value = '';
                                document.getElementById('addnumerosemaine').value = '';
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('saveheuredebutsemaine').style.display = 'none';
                                document.getElementById('saveheurefinsemaine').style.display = 'none';
                                document.getElementById('savesemaine').style.display = 'none';
                            }
                        } catch (error) {
                            alert('Erreur Exécution');
                        }
                    }

                    var divClearModal = document.getElementById('modaloutsavesemaine');
                    while(divClearModal.hasChildNodes()) {
                        divClearModal.removeChild(divClearModal.childNodes[0]);
                    }

                    var divClearBackdrop = document.getElementById('backdropoutsavesemaine');
                    while(divClearBackdrop.hasChildNodes()) {
                        divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                    }
                }
                document.getElementById('backdropHandlerSaveSemaineBtn').addEventListener('click', backdropHandlerSaveSemaineBtnChange);
                async function backdropHandlerSaveSemaineBtnChange(e){
                    e.preventDefault();

                    var divClearModal = document.getElementById('modaloutsavesemaine');
                    while(divClearModal.hasChildNodes()) {
                        divClearModal.removeChild(divClearModal.childNodes[0]);
                    }

                    var divClearBackdrop = document.getElementById('backdropoutsavesemaine');
                    while(divClearBackdrop.hasChildNodes()) {
                        divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                    }

                    //document.getElementById('addnumerosemaine').value = numberDayStr;
                    document.getElementById('seladdjoursemaine').value = '';
                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('saveheuredebutsemaine').style.display = 'none';
                    document.getElementById('saveheurefinsemaine').style.display = 'none';
                    document.getElementById('savesemaine').style.display = 'none';
                }
            }
        }
        /**nav previous page button*/
        document.getElementById('prevaddsemaineBtn_1').addEventListener('click', prevaddsemaine1BtnChange);
        async function prevaddsemaine1BtnChange(e){
            e.preventDefault();
            
            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('semaine').style.display = 'block';
            document.getElementById('crudsemaine').style.display = 'block';
            document.getElementById('addsemaine').style.display = 'none';
            document.getElementById('editsemaine').style.display = 'none';
            document.getElementById('deletesemaine').style.display = 'none';
            document.getElementById('searchsemaine').style.display = 'none';
            titre.innerHTML = 'Configuration Jours de Classe';
        }
        /***************************
        enregistrersemaineBtn
        *****************************/
        document.getElementById('enregistrersemaineBtn').addEventListener('click', enregistrersemaineBtnChange);
        async function enregistrersemaineBtnChange(e){
            e.preventDefault();

            var usercodeStr = document.getElementById('userCode').innerText;
            var addnumerosemaineStr = document.getElementById('addnumerosemaine').value;
            var seladdjoursemaineStr = document.getElementById('seladdjoursemaine').value;
            var addheuredebutsemaineStr = document.getElementById('addheuredebutsemaine').value;
            var addheurefinsemaineStr = document.getElementById('addheurefinsemaine').value;
            var datejourStr = getCurrentDate();
            var addstatutsemaineStr = 'Oui';

            var docsSemaine = [];

            if(!usercodeStr){
                alert("Identifiez-vous avant de continuer");
            }else if(!seladdjoursemaineStr){
                alert("Sélectionner Jour Semaine");
            }else if(!addheuredebutsemaineStr){
                alert("Sélectionner Heure Début Cours");
            }else if(!addheurefinsemaineStr){
                alert("Sélectionner Heure Fin Cours");
            }else{
                //check if this day has already been registered
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: ['semainestatut', 'inscritcode', 'semainejour']
                        }
                    });
                    result = await db.find({
                        selector: {
                            semainestatut: addstatutsemaineStr,
                            inscritcode: usercodeStr,
                            semainejour: seladdjoursemaineStr
                        }
                    });
                    docsSemaine  = result.docs;
                    var resultatSemaine = docsSemaine.length;
                    if(resultatSemaine === 0){
                        //Create the document _id and _rev.
                        var now = Date.now();
                        var id = 'semaine_' + now;
                        // Add the document to the database.
                        var response = await db.put({
                            _id: id,
                            inscritcode: usercodeStr,
                            semainenumero: parseFloat(addnumerosemaineStr),
                            semainejour: seladdjoursemaineStr,
                            semainedebut: addheuredebutsemaineStr,
                            semainefin: addheurefinsemaineStr,
                            semainedate: datejourStr,
                            semainestatut: addstatutsemaineStr,
                        });
                        // check if document inserted and synced
                        var doc = await db.get(response.id);
                        if(doc !== ''){
                            alert('Enregistré. Sélectionner Jour Suivant');
                            document.getElementById('seladdjoursemaine').value = '';
                            document.getElementById('addheuredebutsemaine').value = '';
                            document.getElementById('addheurefinsemaine').value = '';
                            document.getElementById('addnumerosemaine').value = '';
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('saveheuredebutsemaine').style.display = 'none';
                            document.getElementById('saveheurefinsemaine').style.display = 'none';
                            document.getElementById('savesemaine').style.display = 'none';
                        }else{
                            alert('Echec Enregistrement de ce Jour. Reprendre');
                        }
                    }else{
                        alert("Ce Jour est déjà enregistré");
                        document.getElementById('seladdjoursemaine').value = '';
                        document.getElementById('addheuredebutsemaine').value = '';
                        document.getElementById('addheurefinsemaine').value = '';
                        document.getElementById('addnumerosemaine').value = '';
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('saveheuredebutsemaine').style.display = 'none';
                        document.getElementById('saveheurefinsemaine').style.display = 'none';
                        document.getElementById('savesemaine').style.display = 'none';
                    }
                } catch (error) {
                   alert('Erreur Exécution'); 
                }
            }
        }

        /***************************
        editsemaineBtn
        *****************************/
        document.getElementById('editsemaineBtn').addEventListener('click', editsemaineBtnChange);
        async function editsemaineBtnChange(e){
            e.preventDefault();

            document.getElementById('seleditjoursemaine').value = '';

            var userCodeStr = document.getElementById('userCode').innerText;
            if(!userCodeStr){
                alert('Connectez-vous à votre Compte avant de continuer');
            }else{
                document.getElementById('titre').style.display = 'block';
                var titre = document.getElementById('titre');

                document.getElementById('demarrage').style.display = 'none';
                document.getElementById('tableaubord').style.display = 'none';
                document.getElementById('dashboard').style.display = 'none';
                document.getElementById('configurations').style.display = 'block';
                document.getElementById('configurer').style.display = 'none';
                document.getElementById('semaine').style.display = 'block';
                document.getElementById('crudsemaine').style.display = 'none';
                document.getElementById('addsemaine').style.display = 'none';
                document.getElementById('editsemaine').style.display = 'block';
                document.getElementById('deletesemaine').style.display = 'none';
                document.getElementById('searchsemaine').style.display = 'none';
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('changeheuredebutsemaine').style.display = 'none';
                document.getElementById('changeheurefinsemaine').style.display = 'none';
                document.getElementById('changeouisemaine').style.display = 'none';
                document.getElementById('changenonsemaine').style.display = 'none';
                document.getElementById('changeheuresemaine').style.display = 'none';
                titre.innerHTML = 'Modifier Jour Semaine'; 
            }
        }
        document.getElementById('seleditjoursemaine').addEventListener('change', seleditjoursemaineChange);
        async function seleditjoursemaineChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var docsEdit = [];

            var numberDay = 0;
            var seleditjoursemaineStr = document.getElementById('seleditjoursemaine').value;
            if(!seleditjoursemaineStr){
                alert('Sélectionner Jour de Classe');
                document.getElementById('seleditjoursemaine').value = '';
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('changeheuredebutsemaine').style.display = 'none';
                document.getElementById('changeheurefinsemaine').style.display = 'none';
                document.getElementById('changeouisemaine').style.display = 'none';
                document.getElementById('changenonsemaine').style.display = 'none';
                document.getElementById('changeheuresemaine').style.display = 'none';
            }else{
                if(seleditjoursemaineStr === 'Lundi'){
                    numberDay = 1;
                }
                if(seleditjoursemaineStr === 'Mardi'){
                    numberDay = 2;
                }
                if(seleditjoursemaineStr === 'Mercredi'){
                    numberDay = 3;
                }
                if(seleditjoursemaineStr === 'Jeudi'){
                    numberDay = 4;
                }
                if(seleditjoursemaineStr === 'Vendredi'){
                    numberDay = 5;
                }
                if(seleditjoursemaineStr === 'Samedi'){
                    numberDay = 6;
                }

                //check if this day has been inserted and exists
                try {
                    let resultEdit = await db.createIndex({
                        index: {
                            fields: ['inscritcode', 'semainenumero']
                        }
                    });
                    resultEdit = await db.find({
                        selector: {
                            inscritcode: userCodeStr,
                            semainenumero: numberDay
                        }
                    });
                    docsEdit = resultEdit.docs;
                    var resultatEdit = docsEdit.length;
                    if(resultatEdit === 0){
                        alert("Jour "+seleditjoursemaineStr+" n'est pas encore enregistré");
                        document.getElementById('seleditjoursemaine').value = '';
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('changeheuredebutsemaine').style.display = 'none';
                        document.getElementById('changeheurefinsemaine').style.display = 'none';
                        document.getElementById('changeouisemaine').style.display = 'none';
                        document.getElementById('changenonsemaine').style.display = 'none';
                        document.getElementById('changeheuresemaine').style.display = 'none';
                    }else{
                        var divClearModal = document.getElementById('modaloutchangesemaine');
                        let divModal = document.createElement('div');
                        divModal.innerHTML =
                        `<div class="modal">
                            <p>Modifier Jour de classe du ${seleditjoursemaineStr} ?</p>
                            <button class="btn btn--alt" id="ouiHandlerChangeSemaineBtn">Oui</button>
                            <button class="btn" id="nonHandlerChangeSemaineBtn">Non</button>
                            <br/><br/>
                            <button class="btn--heure" id="nonHandlerChangeHeureSemaineBtn">Heures</button>
                        </div>`;
                        divClearModal.appendChild(divModal);

                        var divClearBackdrop = document.getElementById('backdropoutchangesemaine');
                        let divBack = document.createElement('div');
                        divBack.innerHTML =
                        `<div class="backdrop" id="backdropHandlerChangeSemaineBtn"></div>`;
                        divClearBackdrop.appendChild(divBack);

                        document.getElementById('ouiHandlerChangeSemaineBtn').addEventListener('click',ouiHandlerChangeSemaineBtnChange);
                        async function ouiHandlerChangeSemaineBtnChange(e){
                            e.preventDefault();

                            var userCodeStr = document.getElementById('userCode').innerText;
                            var seleditjoursemaineStr = document.getElementById('seleditjoursemaine').value;
                            var semaineStatutStr = '';
                            var emploiStatutStr = 'Oui';
                            var docsNumero = [];
                            var docsEmploi = [];
                            var semaineIdStr,inscritCodeStr,semaineJourStr,heureDebutStr,heureFinStr,semaineDateStr,
                            semaineStatutStr,semaineNumeroStr = '';
                            try {
                                let resultNumero = await db.createIndex({
                                    index: {
                                        fields: ['inscritcode', 'semainenumero']
                                    }
                                });
                                resultNumero = await db.find({
                                    selector: {
                                        inscritcode: userCodeStr,
                                        semainenumero: numberDay
                                    }
                                });
                                docsNumero = resultNumero.docs;
                                //retireve semainestatut
                                for(let i=0; i<docsNumero.length; i++){
                                    semaineStatutStr = docsNumero[i].semainestatut;
                                }
                                if(semaineStatutStr === 'Oui'){
                                    //check if this day is already used in emploi_
                                    let resultEmploi = await db.createIndex({
                                        index: {
                                            fields: ['emploistatut', 'inscritcode', 'emploijour']
                                        }
                                    });
                                    resultEmploi = await db.find({
                                        selector: {
                                            emploistatut: emploiStatutStr,
                                            inscritcode: userCodeStr,
                                            emploijour: seleditjoursemaineStr
                                        }
                                    });
                                    docsEmploi = resultEmploi.docs;
                                    var resultatEmploi = docsEmploi.length;
                                    if(resultatEmploi === 0 || resultatEmploi > 0){
                                        //update possible
                                        for(let j=0; j<docsNumero.length; j++){
                                            semaineIdStr = docsNumero[j]._id;
                                            inscritCodeStr = docsNumero[j].inscritcode;
                                            semaineJourStr = docsNumero[j].semainejour;
                                            heureDebutStr = docsNumero[j].semainedebut;
                                            heureFinStr = docsNumero[j].semainefin;
                                            semaineDateStr = docsNumero[j].semainedate;
                                            semaineStatutStr = docsNumero[j].semainestatut;
                                            semaineNumeroStr = docsNumero[j].semainenumero;
                                        }
                                        document.getElementById('editidsemaine').value = semaineIdStr;
                                        document.getElementById('editcodeusersemaine').value = inscritCodeStr;
                                        document.getElementById('editjoursemaine').value = semaineJourStr;
                                        document.getElementById('editheuredebutsemaine').value = heureDebutStr;
                                        document.getElementById('editheurefinsemaine').value = heureFinStr;
                                        document.getElementById('editdatesemaine').value = semaineDateStr;
                                        document.getElementById('editsemainestatut').value = semaineStatutStr;
                                        document.getElementById('editnumerosemaine').value = semaineNumeroStr;

                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('changeheuredebutsemaine').style.display = 'block';
                                        document.getElementById('changeheurefinsemaine').style.display = 'block';
                                        document.getElementById('changeouisemaine').style.display = 'none';
                                        document.getElementById('changenonsemaine').style.display = 'none';
                                        document.getElementById('changeheuresemaine').style.display = 'block';
                                    }
                                }else if(semaineStatutStr === 'Non'){
                                    //update statut to Oui
                                    for(let k=0; k<docsNumero.length; k++){
                                        semaineIdStr = docsNumero[k]._id;
                                        inscritCodeStr = docsNumero[k].inscritcode;
                                        semaineJourStr = docsNumero[k].semainejour;
                                        semaineDateStr = docsNumero[k].semainedate;
                                        semaineNumeroStr = docsNumero[k].semainenumero;
                                    }
                                    document.getElementById('editidsemaine').value = semaineIdStr;
                                    document.getElementById('editcodeusersemaine').value = inscritCodeStr;
                                    document.getElementById('editjoursemaine').value = semaineJourStr;
                                    document.getElementById('editheuredebutsemaine').value = '';
                                    document.getElementById('editheurefinsemaine').value = '';
                                    document.getElementById('editdatesemaine').value = semaineDateStr;
                                    document.getElementById('editnumerosemaine').value = semaineNumeroStr;

                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('changeheuredebutsemaine').style.display = 'block';
                                    document.getElementById('changeheurefinsemaine').style.display = 'block';
                                    document.getElementById('changeouisemaine').style.display = 'block';
                                    document.getElementById('changenonsemaine').style.display = 'none';
                                    document.getElementById('changeheuresemaine').style.display = 'none';
                                }
                            } catch (error) {
                                alert('Erreur Exécution'); 
                            }

                            var divClearModal = document.getElementById('modaloutchangesemaine');
                            while(divClearModal.hasChildNodes()) {
                                divClearModal.removeChild(divClearModal.childNodes[0]);
                            }

                            var divClearBackdrop = document.getElementById('backdropoutchangesemaine');
                            while(divClearBackdrop.hasChildNodes()) {
                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                            }
                        }

                        document.getElementById('nonHandlerChangeSemaineBtn').addEventListener('click', nonHandlerChangeSemaineBtnChange);
                        async function nonHandlerChangeSemaineBtnChange(e){
                            e.preventDefault();

                            var userCodeStr = document.getElementById('userCode').innerText;
                            var seleditjoursemaineStr = document.getElementById('seleditjoursemaine').value;
                            var semaineStatutStr = '';
                            var emploiStatutStr = 'Oui';
                            var docsNumero = [];
                            var docsEmploi = [];
                            var semaineIdStr,inscritCodeStr,semaineJourStr,heureDebutStr,heureFinStr,semaineDateStr,
                            semaineStatutStr,semaineNumeroStr = '';
                            try {
                                let resultNumero = await db.createIndex({
                                    index: {
                                        fields: ['inscritcode', 'semainenumero']
                                    }
                                });
                                resultNumero = await db.find({
                                    selector: {
                                        inscritcode: userCodeStr,
                                        semainenumero: numberDay
                                    }
                                });
                                docsNumero = resultNumero.docs;
                                //retireve semainestatut
                                for(let i=0; i<docsNumero.length; i++){
                                    semaineStatutStr = docsNumero[i].semainestatut;
                                }
                                if(semaineStatutStr === 'Oui'){
                                    //check if this day is already used in emploi_
                                    let resultEmploi = await db.createIndex({
                                        index: {
                                            fields: ['emploistatut', 'inscritcode', 'emploijour']
                                        }
                                    });
                                    resultEmploi = await db.find({
                                        selector: {
                                            emploistatut: emploiStatutStr,
                                            inscritcode: userCodeStr,
                                            emploijour: seleditjoursemaineStr
                                        }
                                    });
                                    docsEmploi = resultEmploi.docs;
                                    var resultatEmploi = docsEmploi.length;
                                    if(resultatEmploi > 0){
                                        alert("Modification Impossible. Le "+seleditjoursemaineStr+" est Utilisé dans un autre document.");
                                        document.getElementById('seleditjoursemaine').value = '';
                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('changeheuredebutsemaine').style.display = 'none';
                                        document.getElementById('changeheurefinsemaine').style.display = 'none';
                                        document.getElementById('changeouisemaine').style.display = 'none';
                                        document.getElementById('changenonsemaine').style.display = 'none';
                                        document.getElementById('changeheuresemaine').style.display = 'none';
                                    }else if(resultatEmploi === 0){
                                        //update statut to Non
                                        for(let j=0; j<docsNumero.length; j++){
                                            semaineIdStr = docsNumero[j]._id;
                                            inscritCodeStr = docsNumero[j].inscritcode;
                                            semaineJourStr = docsNumero[j].semainejour;
                                            heureDebutStr = docsNumero[j].semainedebut;
                                            heureFinStr = docsNumero[j].semainefin;
                                            semaineDateStr = docsNumero[j].semainedate;
                                            semaineNumeroStr = docsNumero[j].semainenumero;
                                        }
                                        document.getElementById('editidsemaine').value = semaineIdStr;
                                        document.getElementById('editcodeusersemaine').value = inscritCodeStr;
                                        document.getElementById('editjoursemaine').value = semaineJourStr;
                                        document.getElementById('editheuredebutsemaine').value = heureDebutStr;
                                        document.getElementById('editheurefinsemaine').value = heureFinStr;
                                        document.getElementById('editdatesemaine').value = semaineDateStr;
                                        document.getElementById('editnumerosemaine').value = semaineNumeroStr;

                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('changeheuredebutsemaine').style.display = 'block';
                                        document.getElementById('changeheurefinsemaine').style.display = 'block';
                                        document.getElementById('changeouisemaine').style.display = 'none';
                                        document.getElementById('changenonsemaine').style.display = 'block';
                                        document.getElementById('changeheuresemaine').style.display = 'none';
                                    }
                                }else if(semaineStatutStr === 'Non'){
                                    alert("Jour "+seleditjoursemaineStr+" Non Jour de Classe.");
                                    document.getElementById('seleditjoursemaine').value = '';
                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('changeheuredebutsemaine').style.display = 'none';
                                    document.getElementById('changeheurefinsemaine').style.display = 'none';
                                    document.getElementById('changeouisemaine').style.display = 'none';
                                    document.getElementById('changenonsemaine').style.display = 'none';
                                    document.getElementById('changeheuresemaine').style.display = 'none';
                                }
                            } catch (error) {
                                alert('Erreur Exécution');
                            }

                            var divClearModal = document.getElementById('modaloutchangesemaine');
                            while(divClearModal.hasChildNodes()) {
                                divClearModal.removeChild(divClearModal.childNodes[0]);
                            }

                            var divClearBackdrop = document.getElementById('backdropoutchangesemaine');
                            while(divClearBackdrop.hasChildNodes()) {
                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                            }
                        }
                        document.getElementById('nonHandlerChangeHeureSemaineBtn').addEventListener('click', nonHandlerChangeHeureSemaineBtnChange);
                        async function nonHandlerChangeHeureSemaineBtnChange(e){
                            e.preventDefault();

                            var userCodeStr = document.getElementById('userCode').innerText;
                            var seleditjoursemaineStr = document.getElementById('seleditjoursemaine').value;
                            var semaineStatutStr = '';
                            var emploiStatutStr = 'Oui';
                            var docsNumero = [];
                            var docsEmploi = [];
                            var semaineIdStr,inscritCodeStr,semaineJourStr,heureDebutStr,heureFinStr,semaineDateStr,
                            semaineStatutStr,semaineNumeroStr = '';
                            try {
                                let resultNumero = await db.createIndex({
                                    index: {
                                        fields: ['inscritcode', 'semainenumero']
                                    }
                                });
                                resultNumero = await db.find({
                                    selector: {
                                        inscritcode: userCodeStr,
                                        semainenumero: numberDay
                                    }
                                });
                                docsNumero = resultNumero.docs;
                                //retireve semainestatut
                                for(let i=0; i<docsNumero.length; i++){
                                    semaineStatutStr = docsNumero[i].semainestatut;
                                }
                                if(semaineStatutStr === 'Oui'){
                                    //check if this day is already used in emploi_
                                    let resultEmploi = await db.createIndex({
                                        index: {
                                            fields: ['emploistatut', 'inscritcode', 'emploijour']
                                        }
                                    });
                                    resultEmploi = await db.find({
                                        selector: {
                                            emploistatut: emploiStatutStr,
                                            inscritcode: userCodeStr,
                                            emploijour: seleditjoursemaineStr
                                        }
                                    });
                                    docsEmploi = resultEmploi.docs;
                                    var resultatEmploi = docsEmploi.length;
                                    if(resultatEmploi === 0 || resultatEmploi > 0){
                                        //update possible
                                        for(let j=0; j<docsNumero.length; j++){
                                            semaineIdStr = docsNumero[j]._id;
                                            inscritCodeStr = docsNumero[j].inscritcode;
                                            semaineJourStr = docsNumero[j].semainejour;
                                            heureDebutStr = docsNumero[j].semainedebut;
                                            heureFinStr = docsNumero[j].semainefin;
                                            semaineDateStr = docsNumero[j].semainedate;
                                            semaineStatutStr = docsNumero[j].semainestatut;
                                            semaineNumeroStr = docsNumero[j].semainenumero;
                                        }
                                        document.getElementById('editidsemaine').value = semaineIdStr;
                                        document.getElementById('editcodeusersemaine').value = inscritCodeStr;
                                        document.getElementById('editjoursemaine').value = semaineJourStr;
                                        document.getElementById('editheuredebutsemaine').value = heureDebutStr;
                                        document.getElementById('editheurefinsemaine').value = heureFinStr;
                                        document.getElementById('editdatesemaine').value = semaineDateStr;
                                        document.getElementById('editsemainestatut').value = semaineStatutStr;
                                        document.getElementById('editnumerosemaine').value = semaineNumeroStr;

                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('changeheuredebutsemaine').style.display = 'block';
                                        document.getElementById('changeheurefinsemaine').style.display = 'block';
                                        document.getElementById('changeouisemaine').style.display = 'none';
                                        document.getElementById('changenonsemaine').style.display = 'none';
                                        document.getElementById('changeheuresemaine').style.display = 'block';
                                    }
                                }else if(semaineStatutStr === 'Non'){
                                    alert("Jour "+seleditjoursemaineStr+" Non Jour de Classe.");
                                    document.getElementById('seleditjoursemaine').value = '';
                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('changeheuredebutsemaine').style.display = 'none';
                                    document.getElementById('changeheurefinsemaine').style.display = 'none';
                                    document.getElementById('changeouisemaine').style.display = 'none';
                                    document.getElementById('changenonsemaine').style.display = 'none';
                                    document.getElementById('changeheuresemaine').style.display = 'none';
                                }
                            } catch (error) {
                                alert('Erreur Exécution');
                            }

                            var divClearModal = document.getElementById('modaloutchangesemaine');
                            while(divClearModal.hasChildNodes()) {
                                divClearModal.removeChild(divClearModal.childNodes[0]);
                            }

                            var divClearBackdrop = document.getElementById('backdropoutchangesemaine');
                            while(divClearBackdrop.hasChildNodes()) {
                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                            }
                        }

                        document.getElementById('backdropHandlerChangeSemaineBtn').addEventListener('click', backdropHandlerChangeSemaineBtnChange);
                        async function backdropHandlerChangeSemaineBtnChange(e){
                            e.preventDefault();

                            var divClearModal = document.getElementById('modaloutchangesemaine');
                            while(divClearModal.hasChildNodes()) {
                                divClearModal.removeChild(divClearModal.childNodes[0]);
                            }

                            var divClearBackdrop = document.getElementById('backdropoutchangesemaine');
                            while(divClearBackdrop.hasChildNodes()) {
                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                            }

                            document.getElementById('seleditjoursemaine').value = '';
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('changeheuredebutsemaine').style.display = 'none';
                            document.getElementById('changeheurefinsemaine').style.display = 'none';
                            document.getElementById('changeouisemaine').style.display = 'none';
                            document.getElementById('changenonsemaine').style.display = 'none';
                            document.getElementById('changeheuresemaine').style.display = 'none';
                        }
                    }
                } catch (error) {
                   alert('Erreur Exécution Recherche'); 
                }
            }
        }

        /**nav previous page button*/
        document.getElementById('preveditsemaineBtn_1').addEventListener('click', preveditsemaine1BtnChange);
        async function preveditsemaine1BtnChange(e){
            e.preventDefault();
            
            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('semaine').style.display = 'block';
            document.getElementById('crudsemaine').style.display = 'block';
            document.getElementById('addsemaine').style.display = 'none';
            document.getElementById('editsemaine').style.display = 'none';
            document.getElementById('deletesemaine').style.display = 'none';
            document.getElementById('searchsemaine').style.display = 'none';
            titre.innerHTML = 'Configuration Jours de Classe';
        }
        /***************************
        modifierouisemaineBtn
        Event handler to update statut to Oui
        *****************************/
        document.getElementById('modifierouisemaineBtn').addEventListener('click', modifierouisemaineBtnChange);
        async function modifierouisemaineBtnChange(e){
            e.preventDefault();

            var editidsemaineStr = document.getElementById('editidsemaine').value;
            var editcodeusersemaineStr = document.getElementById('userCode').innerText;
            var editnumerosemaineStr = document.getElementById('editnumerosemaine').value;
            var editjoursemaineStr = document.getElementById('editjoursemaine').value;
            var editheuredebutsemaineStr = document.getElementById('editheuredebutsemaine').value;
            var editheurefinsemaineStr = document.getElementById('editheurefinsemaine').value;
            var editdatesemaineStr = document.getElementById('editdatesemaine').value;
            var editsemainestatutStr = 'Oui';

            if(!editcodeusersemaineStr){
                alert("Identifiez-vous avant de continuer");
            }else if(!editheuredebutsemaineStr){
                alert("Entrer Heure Début Cours");
            }else if(!editheurefinsemaineStr){
                alert("Entrer Heure Fin Cours");
            }else{

                try {
                    // Retrieve the document to get the latest revision
                    var id = editidsemaineStr;
                    var doc = await db.get(id);
                    // Make changes
                    doc.inscritcode = editcodeusersemaineStr;
                    doc.semainenumero = parseFloat(editnumerosemaineStr);
                    doc.semainejour = editjoursemaineStr;
                    doc.semainedebut = editheuredebutsemaineStr;
                    doc.semainefin = editheurefinsemaineStr;
                    doc.semainedate = editdatesemaineStr;
                    doc.semainestatut = editsemainestatutStr;
                    // Put the document, saving it as a new revision
                    var response = await db.put(doc);
                    // Get the document we change and update the UI.
                    var docEdit = await db.get(response.id);
                    if(docEdit !== ''){
                        alert(editjoursemaineStr+" Modifié en Jour de Classe avec Succès");
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'block';
                        document.getElementById('configurer').style.display = 'block';
                        document.getElementById('semaine').style.display = 'none';
                        titre.innerHTML = 'Configuration App';
                    }else{
                        alert("Erreur Modification. Reprendre");
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'block';
                        document.getElementById('configurer').style.display = 'block';
                        document.getElementById('semaine').style.display = 'none';
                        titre.innerHTML = 'Configuration App';
                    }
                } catch (error) {
                   alert('Erreur Exécution'); 
                }
            }
        }
        /***************************
        modifiernonsemaineBtn
        Event handler to update start and end hours with statut = Oui
        *****************************/
        document.getElementById('modifiernonsemaineBtn').addEventListener('click', modifiernonsemaineBtnChange);
        async function modifiernonsemaineBtnChange(e){
            e.preventDefault();

            var editidsemaineStr = document.getElementById('editidsemaine').value;
            var editcodeusersemaineStr = document.getElementById('userCode').innerText;
            var editnumerosemaineStr = document.getElementById('editnumerosemaine').value;
            var editjoursemaineStr = document.getElementById('editjoursemaine').value;
            var editheuredebutsemaineStr = document.getElementById('editheuredebutsemaine').value;
            var editheurefinsemaineStr = document.getElementById('editheurefinsemaine').value;
            var editdatesemaineStr = document.getElementById('editdatesemaine').value;
            var editsemainestatutStr = 'Non';

            if(!editcodeusersemaineStr){
                alert("Identifiez-vous avant de continuer");
            }else if(!editheuredebutsemaineStr){
                alert("Entrer Heure Début Cours");
            }else if(!editheurefinsemaineStr){
                alert("Entrer Heure Fin Cours");
            }else{

                try {
                    // Retrieve the document to get the latest revision
                    var id = editidsemaineStr;
                    var doc = await db.get(id);
                    // Make changes
                    doc.inscritcode = editcodeusersemaineStr;
                    doc.semainenumero = parseFloat(editnumerosemaineStr);
                    doc.semainejour = editjoursemaineStr;
                    doc.semainedebut = editheuredebutsemaineStr;
                    doc.semainefin = editheurefinsemaineStr;
                    doc.semainedate = editdatesemaineStr;
                    doc.semainestatut = editsemainestatutStr;
                    // Put the document, saving it as a new revision
                    var response = await db.put(doc);
                    // Get the document we change and update the UI.
                    var docEdit = await db.get(response.id);
                    if(docEdit !== ''){
                        alert(editjoursemaineStr+" Modifié en Non Jour de Classe avec Succès");
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'block';
                        document.getElementById('configurer').style.display = 'block';
                        document.getElementById('semaine').style.display = 'none';
                        titre.innerHTML = 'Configuration App';
                    }else{
                        alert("Erreur Modification. Reprendre");
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'block';
                        document.getElementById('configurer').style.display = 'block';
                        document.getElementById('semaine').style.display = 'none';
                        titre.innerHTML = 'Configuration App';
                    }
                } catch (error) {
                   alert('Erreur Exécution'); 
                }
            }
        }
        /***************************
        modifierheuresemaineBtn
        Event handler to update start and end hours with statut = Oui
        *****************************/
        document.getElementById('modifierheuresemaineBtn').addEventListener('click', modifierheuresemaineBtnChange);
        async function modifierheuresemaineBtnChange(e){
            e.preventDefault();

            var editidsemaineStr = document.getElementById('editidsemaine').value;
            var editcodeusersemaineStr = document.getElementById('userCode').innerText;
            var editnumerosemaineStr = document.getElementById('editnumerosemaine').value;
            var editjoursemaineStr = document.getElementById('editjoursemaine').value;
            var editheuredebutsemaineStr = document.getElementById('editheuredebutsemaine').value;
            var editheurefinsemaineStr = document.getElementById('editheurefinsemaine').value;
            var editdatesemaineStr = document.getElementById('editdatesemaine').value;
            var editsemainestatutStr = document.getElementById('editsemainestatut').value;

            if(!editcodeusersemaineStr){
                alert("Identifiez-vous avant de continuer");
            }else if(!editheuredebutsemaineStr){
                alert("Entrer Heure Début Cours");
            }else if(!editheurefinsemaineStr){
                alert("Entrer Heure Fin Cours");
            }else{
                try {
                    // Retrieve the document to get the latest revision
                    var id = editidsemaineStr;
                    var doc = await db.get(id);
                    // Make changes
                    doc.inscritcode = editcodeusersemaineStr;
                    doc.semainenumero = parseFloat(editnumerosemaineStr);
                    doc.semainejour = editjoursemaineStr;
                    doc.semainedebut = editheuredebutsemaineStr;
                    doc.semainefin = editheurefinsemaineStr;
                    doc.semainedate = editdatesemaineStr;
                    doc.semainestatut = editsemainestatutStr;
                    // Put the document, saving it as a new revision
                    var response = await db.put(doc);
                    // Get the document we change and update the UI.
                    var docEdit = await db.get(response.id);
                    if(docEdit !== ''){
                        alert(editjoursemaineStr+" Modifié en Jour de Classe avec Succès");
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'block';
                        document.getElementById('configurer').style.display = 'block';
                        document.getElementById('semaine').style.display = 'none';
                        titre.innerHTML = 'Configuration App';
                    }else{
                        alert("Erreur Modification. Reprendre");
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'block';
                        document.getElementById('configurer').style.display = 'block';
                        document.getElementById('semaine').style.display = 'none';
                        titre.innerHTML = 'Configuration App';
                    }
                } catch (error) {
                   alert('Erreur Exécution'); 
                }
            }
        }

        /***************************
        deletesemaineBtn
        *****************************/
        document.getElementById('deletesemaineBtn').addEventListener('click', deletesemaineBtnChange);
        async function deletesemaineBtnChange(e){
            e.preventDefault();

            document.getElementById('seldeletejoursemaine').value = '';

            var userCodeStr = document.getElementById('userCode').innerText;
            if(!userCodeStr){
                alert('Connectez-vous à votre Compte avant de continuer');
            }else{
                document.getElementById('titre').style.display = 'block';
                var titre = document.getElementById('titre');

                document.getElementById('demarrage').style.display = 'none';
                document.getElementById('tableaubord').style.display = 'none';
                document.getElementById('dashboard').style.display = 'none';
                document.getElementById('configurations').style.display = 'block';
                document.getElementById('configurer').style.display = 'none';
                document.getElementById('semaine').style.display = 'block';
                document.getElementById('crudsemaine').style.display = 'none';
                document.getElementById('addsemaine').style.display = 'none';
                document.getElementById('editsemaine').style.display = 'none';
                document.getElementById('deletesemaine').style.display = 'block';
                document.getElementById('searchsemaine').style.display = 'none';
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('cancelheuredebutsemaine').style.display = 'none';
                document.getElementById('cancelheurefinsemaine').style.display = 'none';
                document.getElementById('cancelsemaine').style.display = 'none';
                titre.innerHTML = 'Supprimer Jour Semaine'; 
            }
        }
        document.getElementById('seldeletejoursemaine').addEventListener('change', seldeletejoursemaineChange);
        async function seldeletejoursemaineChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var docsDelete = [];

            var numberDay = 0;
            var seldeletejoursemaineStr = document.getElementById('seldeletejoursemaine').value;
            if(!seldeletejoursemaineStr){
                alert('Sélectionner Jour de Classe');
                document.getElementById('seldeletejoursemaine').value = '';
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('cancelheuredebutsemaine').style.display = 'none';
                document.getElementById('cancelheurefinsemaine').style.display = 'none';
                document.getElementById('cancelsemaine').style.display = 'none';
            }else{
                if(seldeletejoursemaineStr === 'Lundi'){
                    numberDay = 1;
                }
                if(seldeletejoursemaineStr === 'Mardi'){
                    numberDay = 2;
                }
                if(seldeletejoursemaineStr === 'Mercredi'){
                    numberDay = 3;
                }
                if(seldeletejoursemaineStr === 'Jeudi'){
                    numberDay = 4;
                }
                if(seldeletejoursemaineStr === 'Vendredi'){
                    numberDay = 5;
                }
                if(seldeletejoursemaineStr === 'Samedi'){
                    numberDay = 6;
                }

                //check if this day has been inserted and exists
                try {
                    let resultDelete = await db.createIndex({
                        index: {
                            fields: ['inscritcode', 'semainenumero']
                        }
                    });
                    resultDelete = await db.find({
                        selector: {
                            inscritcode: userCodeStr,
                            semainenumero: numberDay
                        }
                    });
                    docsDelete = resultDelete.docs;
                    var resultatDelete = docsDelete.length;
                    if(resultatDelete === 0){
                        alert("Jour "+seldeletejoursemaineStr+" n'est pas encore enregistré");
                        document.getElementById('seldeletejoursemaine').value = '';
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('cancelheuredebutsemaine').style.display = 'none';
                        document.getElementById('cancelheurefinsemaine').style.display = 'none';
                        document.getElementById('cancelsemaine').style.display = 'none';
                    }else{
                        var divClearModal = document.getElementById('modaloutcancelsemaine');
                        let divModal = document.createElement('div');
                        divModal.innerHTML =
                        `<div class="modal">
                            <p>Supprimer Jour de classe du ${seldeletejoursemaineStr} ?</p>
                            <button class="btn btn--alt" id="ouiHandlerCancelSemaineBtn">Oui</button>
                            <button class="btn" id="nonHandlerCancelSemaineBtn">Non</button>
                        </div>`;
                        divClearModal.appendChild(divModal);

                        var divClearBackdrop = document.getElementById('backdropoutcancelsemaine');
                        let divBack = document.createElement('div');
                        divBack.innerHTML =
                        `<div class="backdrop" id="backdropHandlerCancelSemaineBtn"></div>`;
                        divClearBackdrop.appendChild(divBack);

                        document.getElementById('ouiHandlerCancelSemaineBtn').addEventListener('click',ouiHandlerCancelSemaineBtnChange);
                        async function ouiHandlerCancelSemaineBtnChange(e){
                            e.preventDefault();

                            var userCodeStr = document.getElementById('userCode').innerText;
                            var seldeletejoursemaineStr = document.getElementById('seldeletejoursemaine').value;
                            var semaineStatutStr = '';
                            var emploiStatutStr = 'Oui';
                            var docsNumero = [];
                            var docsEmploi = [];
                            var semaineIdStr,inscritCodeStr,semaineJourStr,heureDebutStr,heureFinStr,semaineDateStr,
                            semaineStatutStr,semaineNumeroStr = '';
                            try {
                                let resultNumero = await db.createIndex({
                                    index: {
                                        fields: ['inscritcode', 'semainenumero']
                                    }
                                });
                                resultNumero = await db.find({
                                    selector: {
                                        inscritcode: userCodeStr,
                                        semainenumero: numberDay
                                    }
                                });
                                docsNumero = resultNumero.docs;
                                //retireve semainestatut
                                for(let i=0; i<docsNumero.length; i++){
                                    semaineStatutStr = docsNumero[i].semainestatut;
                                }
                                if(semaineStatutStr === 'Oui'){
                                    //check if this day is already used in emploi_
                                    let resultEmploi = await db.createIndex({
                                        index: {
                                            fields: ['emploistatut', 'inscritcode', 'emploijour']
                                        }
                                    });
                                    resultEmploi = await db.find({
                                        selector: {
                                            emploistatut: emploiStatutStr,
                                            inscritcode: userCodeStr,
                                            emploijour: seldeletejoursemaineStr
                                        }
                                    });
                                    docsEmploi = resultEmploi.docs;
                                    var resultatEmploi = docsEmploi.length;
                                    if(resultatEmploi === 0){
                                        //update possible
                                        for(let j=0; j<docsNumero.length; j++){
                                            semaineIdStr = docsNumero[j]._id;
                                            inscritCodeStr = docsNumero[j].inscritcode;
                                            semaineJourStr = docsNumero[j].semainejour;
                                            heureDebutStr = docsNumero[j].semainedebut;
                                            heureFinStr = docsNumero[j].semainefin;
                                            semaineDateStr = docsNumero[j].semainedate;
                                            semaineStatutStr = docsNumero[j].semainestatut;
                                            semaineNumeroStr = docsNumero[j].semainenumero;
                                        }
                                        document.getElementById('deleteidsemaine').value = semaineIdStr;
                                        document.getElementById('deletecodeusersemaine').value = inscritCodeStr;
                                        document.getElementById('deletejoursemaine').value = semaineJourStr;
                                        document.getElementById('deleteheuredebutsemaine').value = heureDebutStr;
                                        document.getElementById('deleteheurefinsemaine').value = heureFinStr;
                                        document.getElementById('deletedatesemaine').value = semaineDateStr;
                                        document.getElementById('deletesemainestatut').value = semaineStatutStr;
                                        document.getElementById('deletenumerosemaine').value = semaineNumeroStr;

                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('cancelheuredebutsemaine').style.display = 'block';
                                        document.getElementById('cancelheurefinsemaine').style.display = 'block';
                                        document.getElementById('cancelsemaine').style.display = 'block';
                                    }else if(resultatEmploi > 0){
                                        alert("Suppression Impossible. Le "+seldeletejoursemaineStr+" est Utilisé dans un autre document.");
                                        document.getElementById('seldeletejoursemaine').value = '';
                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('cancelheuredebutsemaine').style.display = 'none';
                                        document.getElementById('cancelheurefinsemaine').style.display = 'none';
                                        document.getElementById('cancelsemaine').style.display = 'none';
                                    }
                                }else if(semaineStatutStr === 'Non'){
                                    alert("Jour "+seldeletejoursemaineStr+" déjà Non Jour de Classe.");
                                    document.getElementById('seldeletejoursemaine').value = '';
                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('cancelheuredebutsemaine').style.display = 'none';
                                    document.getElementById('cancelheurefinsemaine').style.display = 'none';
                                    document.getElementById('cancelsemaine').style.display = 'none';
                                }
                            } catch (error) {
                                alert('Erreur Exécution');
                            }

                            var divClearModal = document.getElementById('modaloutcancelsemaine');
                            while(divClearModal.hasChildNodes()) {
                                divClearModal.removeChild(divClearModal.childNodes[0]);
                            }

                            var divClearBackdrop = document.getElementById('backdropoutcancelsemaine');
                            while(divClearBackdrop.hasChildNodes()) {
                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                            }
                        }
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }

        /**nav previous page button*/
        document.getElementById('prevdeletesemaineBtn_1').addEventListener('click', prevdeletesemaine1BtnChange);
        async function prevdeletesemaine1BtnChange(e){
            e.preventDefault();
            
            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('semaine').style.display = 'block';
            document.getElementById('crudsemaine').style.display = 'block';
            document.getElementById('addsemaine').style.display = 'none';
            document.getElementById('editsemaine').style.display = 'none';
            document.getElementById('deletesemaine').style.display = 'none';
            document.getElementById('searchsemaine').style.display = 'none';
            titre.innerHTML = 'Configuration Jours de Classe';
        }
        /***************************
        supprimersemaineBtn
        *****************************/
        document.getElementById('supprimersemaineBtn').addEventListener('click', supprimersemaineBtnChange);
        async function supprimersemaineBtnChange(e){
            e.preventDefault();

            var deleteidsemaineStr = document.getElementById('deleteidsemaine').value;
            var deletecodeusersemaineStr = document.getElementById('userCode').innerText;
            var deletenumerosemaineStr = document.getElementById('deletenumerosemaine').value;
            var deletejoursemaineStr = document.getElementById('deletejoursemaine').value;
            var deleteheuredebutsemaineStr = document.getElementById('deleteheuredebutsemaine').value;
            var deleteheurefinsemaineStr = document.getElementById('deleteheurefinsemaine').value;
            var deletedatesemaineStr = document.getElementById('deletedatesemaine').value;
            var deletesemainestatutStr = 'Non';

            if(!deletecodeusersemaineStr){
                alert("Identifiez-vous avant de continuer");
            }else if(!deleteheuredebutsemaineStr){
                alert("Entrer Heure Début Cours");
            }else if(!deleteheurefinsemaineStr){
                alert("Entrer Heure Fin Cours");
            }else{

                try {
                    // Retrieve the document to get the latest revision
                    var id = deleteidsemaineStr;
                    var doc = await db.get(id);
                    // Make changes
                    doc.inscritcode = deletecodeusersemaineStr;
                    doc.semainenumero = parseFloat(deletenumerosemaineStr);
                    doc.semainejour = deletejoursemaineStr;
                    doc.semainedebut = deleteheuredebutsemaineStr;
                    doc.semainefin = deleteheurefinsemaineStr;
                    doc.semainedate = deletedatesemaineStr;
                    doc.semainestatut = deletesemainestatutStr;
                    // Put the document, saving it as a new revision
                    var response = await db.put(doc);
                    // Get the document we change and update the UI.
                    var docEdit = await db.get(response.id);
                    if(docEdit !== ''){
                        alert(deletejoursemaineStr+" Changé en Non Jour de Classe avec Succès");
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'block';
                        document.getElementById('configurer').style.display = 'block';
                        document.getElementById('semaine').style.display = 'none';
                        titre.innerHTML = 'Configuration App';
                    }else{
                        alert("Erreur Suppression. Reprendre");
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'block';
                        document.getElementById('configurer').style.display = 'block';
                        document.getElementById('semaine').style.display = 'none';
                        titre.innerHTML = 'Configuration App';
                    }
                } catch (error) {
                   alert('Erreur Exécution'); 
                }
            }
        }

        /***************************
        searchsemaineBtn
        *****************************/
        document.getElementById('searchsemaineBtn').addEventListener('click', searchsemaineBtnChange);
        async function searchsemaineBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'block';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('semaine').style.display = 'block';
            document.getElementById('crudsemaine').style.display = 'none';
            document.getElementById('addsemaine').style.display = 'none';
            document.getElementById('editsemaine').style.display = 'none';
            document.getElementById('deletesemaine').style.display = 'none';
            document.getElementById('searchsemaine').style.display = 'block';
            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('viewsearchsemaine').style.display = 'none';
            document.getElementById('viewconsultersemaine').style.display = 'none';
            titre.innerHTML = 'Consulter Jour Semaine'; 
        }
        document.getElementById('selsearchsemaine').addEventListener('change', selsearchsemaineChange);
        async function selsearchsemaineChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutOuiSemaineStr = 'Oui';
            var statutNonSemaineStr = 'Non';
            var docsSearchOui = [];
            var docsSearchNon = [];

            var selsearchsemaineStr = document.getElementById('selsearchsemaine').value;
            document.getElementById('searchjoursemaine').value = selsearchsemaineStr;
            if(!selsearchsemaineStr){
                alert('Sélectionner Jours');
                document.getElementById('selsearchsemaine').value = '';
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('viewsearchsemaine').style.display = 'none';
                document.getElementById('viewconsultersemaine').style.display = 'none';
            }else{
                if(selsearchsemaineStr === 'Oui'){
                    try {
                        let resultOui = await db.createIndex({
                            index: {
                                fields: ['semainestatut', 'inscritcode']
                            }
                        });
                        resultOui = await db.find({
                            selector: {
                                semainestatut: statutOuiSemaineStr,
                                inscritcode: userCodeStr
                            }
                        });
                        docsSearchOui  = resultOui.docs;
                        var resultatSearchOui = docsSearchOui.length;
                        if(resultatSearchOui === 0){
                            alert('Aucun Jour de Classe Disponible');
                            document.getElementById('selsearchsemaine').value = '';
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('viewsearchsemaine').style.display = 'none';
                            document.getElementById('viewconsultersemaine').style.display = 'none';
                        }else{
                            updateSearchSemaineUIWithDocs(docsSearchOui);
                            document.getElementById('viewjoursemaine').innerText = 'Liste Jours de Classe';
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('viewsearchsemaine').style.display = 'block';
                            document.getElementById('viewconsultersemaine').style.display = 'block';
                        }
                    } catch (error) {
                        alert('Erreur Exécution');
                    }
                }else if(selsearchsemaineStr === 'Non'){
                    try {
                        let resultNon = await db.createIndex({
                            index: {
                                fields: ['semainestatut', 'inscritcode']
                            }
                        });
                        resultNon = await db.find({
                            selector: {
                                semainestatut: statutNonSemaineStr,
                                inscritcode: userCodeStr
                            }
                        });
                        docsSearchNon  = resultNon.docs;
                        var resultatSearchNon = docsSearchNon.length;
                        if(resultatSearchNon === 0){
                            alert('Aucun Non Jour de Classe Disponible');
                            document.getElementById('selsearchsemaine').value = '';
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('viewsearchsemaine').style.display = 'none';
                            document.getElementById('viewconsultersemaine').style.display = 'none';
                        }else{
                            updateSearchSemaineUIWithDocs(docsSearchNon);
                            document.getElementById('viewjoursemaine').innerText = 'Liste Non Jours de Classe';
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('viewsearchsemaine').style.display = 'block';
                            document.getElementById('viewconsultersemaine').style.display = 'block';
                        }
                    } catch (error) {
                        alert('Erreur Exécution');
                    }
                }
            }
        }
        // Adds the document to the HTML First table.
        // If the document already exists in the table, it will be updated.
        function viewSearchSemaineTableDocToTable(doc, viewSearchSemaineTableBody){
            // Each row of the table has an id attribute set to the doc._id field.
            var tr = viewSearchSemaineTableBody.querySelector(`#${doc._id}`);
            var searchjoursemaineStr = document.getElementById('searchjoursemaine').value;

            if( !tr ) {
                tr = document.createElement('tr');
                tr.id = doc._id;
            }
        
            // Remove any existing table cells.
            while(tr.hasChildNodes()) {
                tr.removeChild(tr.childNodes[0]);
            }

            if(searchjoursemaineStr === 'Oui'){
                // Create new table cells for text fields.
                var tdJour = document.createElement('td');
                tdJour.innerText = doc.semainejour;
                tr.appendChild(tdJour);

                // Create new table cells for text fields.
                var tdDebut = document.createElement('td');
                tdDebut.innerText = doc.semainedebut;
                tr.appendChild(tdDebut);

                // Create new table cells for text fields.
                var tdFin = document.createElement('td');
                tdFin.innerText = doc.semainefin;
                tr.appendChild(tdFin);
            }else if(searchjoursemaineStr === 'Non'){
                // Create new table cells for text fields.
                var tdJour = document.createElement('td');
                tdJour.innerText = doc.semainejour;
                tr.appendChild(tdJour);

                // Create new table cells for text fields.
                var tdDebut = document.createElement('td');
                tdDebut.innerText = '00:00';
                tr.appendChild(tdDebut);

                // Create new table cells for text fields.
                var tdFin = document.createElement('td');
                tdFin.innerText = '00:00';
                tr.appendChild(tdFin);
            }

            // Add the row to the top of the table.
            viewSearchSemaineTableBody.insertBefore(tr, viewSearchSemaineTableBody.nextSibling);
        }
        /**Clear and update the UI to display the docs.*/
        function updateSearchSemaineUIWithDocs(docs) {
            var viewSearchSemaineTableBody = document.getElementById('viewSearchSemaineTableBody');
            
            // Remove all child nodes from the table
            while(viewSearchSemaineTableBody.hasChildNodes()) {
                viewSearchSemaineTableBody.removeChild(viewSearchSemaineTableBody.childNodes[0]);
            }
            
            // Add the new documents to the table
            for(var i=0; i<docs.length; i++) {
                var doc = docs[i];
                viewSearchSemaineTableDocToTable(doc, viewSearchSemaineTableBody);
            }
        }
        /***************************
        consultersemaineBtn
        *****************************/
        document.getElementById('consultersemaineBtn').addEventListener('click', consultersemaineBtnChange);
        async function consultersemaineBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('semaine').style.display = 'block';
            document.getElementById('crudsemaine').style.display = 'block';
            document.getElementById('addsemaine').style.display = 'none';
            document.getElementById('editsemaine').style.display = 'none';
            document.getElementById('deletesemaine').style.display = 'none';
            document.getElementById('searchsemaine').style.display = 'none';
            titre.innerHTML = 'Configuration Jours de Classe';
        }

        /**nav previous page button*/
        document.getElementById('prevsearchsemaineBtn_1').addEventListener('click', prevsearchsemaine1BtnChange);
        async function prevsearchsemaine1BtnChange(e){
            e.preventDefault();
            
            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('semaine').style.display = 'block';
            document.getElementById('crudsemaine').style.display = 'block';
            document.getElementById('addsemaine').style.display = 'none';
            document.getElementById('editsemaine').style.display = 'none';
            document.getElementById('deletesemaine').style.display = 'none';
            document.getElementById('searchsemaine').style.display = 'none';
            titre.innerHTML = 'Configuration Jours de Classe';
        }
    };

    /****************
    HELPER FUNCTION
    *****************/
    /**1: Get Current Date in JavaScript */
    function getCurrentDate(){
        // Date object
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1;
        var yyyy = today.getFullYear();
        if(dd<10){
            dd='0'+dd;
        }
        if(mm<10){
            mm='0'+mm;
        }
        // Current Date
        var date = yyyy+'-'+mm+'-'+dd;
        return date;
    }

    //2: selects element passed in
    /*function selectElement(selector){
        return document.querySelector(selector);
    }*/

    window.addEventListener('load', () =>{
        new taskConfigSemaine();
    })
}) ();