(function(){
    var taskExercice = function(){

        /***************
        exotacheBtn
        *****************/
        /**nav previous page button*/
        document.getElementById('prevexotacheBtn_1').addEventListener('click', prevexotache1BtnChange);
        async function prevexotache1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'block';
            document.getElementById('dashboard').style.display = 'block';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            titre.innerHTML = 'Tableau de Bord'; 
        }

        /***************************
        exercicepratiqueBtn
        *****************************/
        document.getElementById('exercicepratiqueBtn').addEventListener('click', exercicepratiqueBtnChange);
        async function exercicepratiqueBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'block';
            document.getElementById('crudpratique').style.display = 'block';
            document.getElementById('addpratique').style.display = 'none';
            document.getElementById('editpratique').style.display = 'none';
            document.getElementById('deletepratique').style.display = 'none';
            document.getElementById('searchpratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'none';
            titre.innerHTML = 'Exercices Pratiques'; 
        }

        /***************************
        tachepersonnelleBtn
        *****************************/
        document.getElementById('tachepersonnelleBtn').addEventListener('click', tachepersonnelleBtnChange);
        async function tachepersonnelleBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'block';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'none';
            document.getElementById('crudtache').style.display = 'block';
            document.getElementById('addtache').style.display = 'none';
            document.getElementById('edittache').style.display = 'none';
            document.getElementById('deletetache').style.display = 'none';
            document.getElementById('searchtache').style.display = 'none';
            titre.innerHTML = 'Tâches Personnelles'; 
        }

        /***************************
        revisiondevoirBtn
        *****************************/
        document.getElementById('revisiondevoirBtn').addEventListener('click', revisiondevoirBtnChange);
        async function revisiondevoirBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'block';
            document.getElementById('recherche').style.display = 'none';
            document.getElementById('crudrevision').style.display = 'block';
            document.getElementById('addrevision').style.display = 'none';
            document.getElementById('editrevision').style.display = 'none';
            document.getElementById('deleterevision').style.display = 'none';
            document.getElementById('searchrevision').style.display = 'none';
            titre.innerHTML = 'Révisions avant Devoirs'; 
        }

        /***************************
        recherchetacheBtn
        *****************************/
        document.getElementById('recherchetacheBtn').addEventListener('click', recherchetacheBtnChange);
        async function recherchetacheBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'block';
            document.getElementById('crudrecherche').style.display = 'block';
            /*document.getElementById('addrecherche').style.display = 'none';
            document.getElementById('editrecherche').style.display = 'none';
            document.getElementById('deleterecherche').style.display = 'none';
            document.getElementById('searchrecherche').style.display = 'none';*/
            titre.innerHTML = 'Tâches Recherches'; 
        }
    };

    window.addEventListener('load', () =>{
        new taskExercice();
    })
}) ();