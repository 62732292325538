import { Search, NavigateNext, } from '@material-ui/icons';

function SearchButton(props) {
    return ( 
        <div>
            <button id={props.idsearchbutton} style={searchButton}>
                <Search style={{float:'left',color:'#808080'}} />
                Consulter {props.titre}
                <NavigateNext style={{float:'right',color:'#808080'}} />
            </button>
        </div>
    );
}

const searchButton = {
    marginTop: '4%',
    flex: 1,
    width: '90%',
    height: 50,
    textAlign: "center",
    alignContent: 'center',
    border: "2px solid #808080",
    fontSize: 18,
    borderRadius: 10,
    color: "#808080",
    //textTransform: "uppercase",
}

export default SearchButton;