import './tache.css';
import './pouchtache';
import TextAreaInput from '../../components/TextAreaInput';
import DateInput from '../../components/DateInput';
import ModifierButton from '../../components/ModifierButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function EditTache() {
    return ( 
        <div id="edittache">
            <KeyboardBackspace style={prevArrows} id="prevedittacheBtn_1" />
            <br/><br/>
            <form>
                <div id="formedittache" style={scroller}>
                    <br/>
                    <div id="changetypetache">
                        <label style={labelStyle}>Type Tâche</label>
                        <select id="seledittypetache">
                            <option value="">Sélectionner</option>
                            <option value='Classe'>Classe</option>
                            <option value="Ordinaire">Ordinaire</option>
                            <option value="Extraordinaire">Extraordinaire</option>
                        </select>
                    </div>
                    <br/>
                    <div id="changeecheancetache">
                        <label style={labelStyle}>Sélection Tâche</label>
                        <div id="editselecheance"></div>
                    </div>
                    <div id="changedatetache">
                        <label style={labelStyle}>Modifier Date Echéance</label>
                        <DateInput idinput ="editecheancetache" date="date" />
                    </div>
                    <br/>
                    <div id="changetextetache">
                        <label style={labelStyle}>Modifier Tâche</label>
                        <TextAreaInput rowlength="6" idinput ="edittextetache" texte="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editidtache" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="edittypetache" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editcodeusertache" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editeffectuetache" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editdatetache" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editstatuttache" type="text" />
                    </div>
                    <br/>
                    <div id="changetache">
                        <ModifierButton 
                        idmodifierbutton="modifiertacheBtn"
                        modifier="Modifier"
                    />
                    </div>
                    <br/><br/>
                </div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default EditTache;