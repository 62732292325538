import '../configuration.css';
import '../pouchconfiguration';
import { KeyboardBackspace, Add, } from '@material-ui/icons';

function AddEtablissement() {
    return ( 
        <div id="addetablissement">
            <KeyboardBackspace style={prevArrows} id="prevaddetablissementBtn_1" />
            <br/><br/>
            <form>
                <div id="formaddetablissement" style={scroller}>
                    <div>
                        <input id ="addnometablissement" type="text" placeholder="Entrer Nom Etablissement" />
                    </div>
                    <br/>
                    <div>
                        <input id ="addvilleetablissement" type="text" placeholder="Entrer Nom Ville" />
                    </div>
                    <br/>
                    <div>
                        <input id ="addadresseetablissement" type="text" placeholder="Entrer Adresse" />
                    </div>
                    <br/>
                    <div>
                        <input id ="addprincipaletablissement" type="text" placeholder="Entrer Nom Principal/Proviseur" />
                    </div>
                    <br/>
                    <div>
                        <input id ="addcontactetablissement" type="text" placeholder="Contact Téléphone (facultatif)" />
                    </div>
                    <br/>
                    <div>
                        <input id ="addemailetablissement" type="text" placeholder="Email (Facultatif)" />
                    </div>
                    <br/>
                    <div>
                        <button id="enregistreretablissementBtn" style={addFormButtonStyle}>
                            <Add />
                            Enregistrer
                        </button>
                    </div>
                    <br/><br/>
                </div>
            </form>
        </div>
    );
}

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

const addFormButtonStyle = {
    marginTop: '4%',
    flex: 1,
    width: 200,
    height: 50,
    textAlign: "center",
    alignContent: 'center',
    backgroundColor: "#008000", border: 1, solid: "#008000",
    fontSize: 18,
    borderRadius: 15,
    color: "#ffffff",
    /*textTransform: "uppercase",*/
};

export default AddEtablissement;