/* eslint-disable no-redeclare */
import PouchDB from 'pouchdb';
import PouchFind from 'pouchdb-find';

(function(){
    var taskMoyAnnuelle = function(){
        /*****************************************************
        ENABLING POUCHDB WITHOUT SYNC TO REMOTE COUCHDB
        ******************************************************/
        //SET UP POUCHDB AND CREATE THE DATABASE 
        const db = new PouchDB('suclu');
        PouchDB.plugin(PouchFind);

        /***************
        crudmoyannuelleBtn
        *****************/
        /**nav previous page button*/
        document.getElementById('prevcrudmoyannuelleBtn_1').addEventListener('click', prevcrudmoyannuelle1BtnChange);
        async function prevcrudmoyannuelle1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('moyenne').style.display = 'block';
            document.getElementById('moymatiere').style.display = 'none';
            document.getElementById('moygenerale').style.display = 'none';
            document.getElementById('moyannuelle').style.display = 'none';
            titre.innerHTML = 'Calcul des Moyennes'; 
        }

        /***************************
        addmoyannuelleBtn
        *****************************/
        document.getElementById('addmoyannuelleBtn').addEventListener('click',addmoyannuelleBtnChange);
        async function addmoyannuelleBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutanneeStr = 'Oui';

            var docsAnnee = [];

            if(!userCodeStr){
                alert('Connectez-vous à votre Compte avant de continuer');
            }else{
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: ['anneestatut', 'inscritcode']
                        }
                    });
                    result = await db.find({
                        selector: {
                            anneestatut: statutanneeStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsAnnee  = result.docs;
                    var resultatAnnee = docsAnnee.length;
                    if(resultatAnnee === 0){
                        alert('Aucun Calendrier Année Scolaire Enregistré');
                    }else{
                        var divClear;
                        var divModifier;
                        divClear = document.getElementById('addcalendriermoyannuelle');
                        while(divClear.hasChildNodes()) {
                            divClear.removeChild(divClear.childNodes[0]);
                        }
                        divModifier = document.getElementById('addcalendriermoyannuelle');
                        //Create and append select list
                        var selectList = document.createElement("select");
                        selectList.id = "seladdcalendriermoyannuelle";
                        divModifier.appendChild(selectList);
                        //Create and append the options
                        var option_null = document.createElement("option");
                        option_null.value = '';
                        option_null.text = 'Sélectionner Division Année';
                        selectList.appendChild(option_null);
                        //Create and append the options
                        for (var i = 0; i<1; i++){
                            var option = document.createElement("option");
                            option.value = docsAnnee[i].anneedivision;
                            option.text = docsAnnee[i].anneedivision;
                            selectList.appendChild(option);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'none';
                        document.getElementById('programme').style.display = 'none';
                        document.getElementById('notes').style.display = 'none';
                        document.getElementById('moyenne').style.display = 'none';
                        document.getElementById('moyannuelle').style.display = 'block';
                        document.getElementById('crudmoyannuelle').style.display = 'none';
                        document.getElementById('addmoyannuelle').style.display = 'block';
                        document.getElementById('editmoyannuelle').style.display = 'none';
                        document.getElementById('deletemoyannuelle').style.display = 'none';
                        document.getElementById('searchmoyannuelle').style.display = 'none';
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('savecalendriermoyannuelle').style.display = 'block';
                        document.getElementById('saveprogrammemoyannuelle').style.display = 'none';
                        document.getElementById('savetotalcumulmoyannuelle').style.display = 'none';
                        document.getElementById('savetotalcoefficientmoyannuelle').style.display = 'none';
                        document.getElementById('saveobtenumoyannuelle').style.display = 'none';
                        document.getElementById('savemoyannuelle').style.display = 'none';
                        titre.innerHTML = 'Enregistrer Moyenne Annuelle';

                        /***************************
                        seladdcalendriermoyannuelle
                        *****************************/
                        document.getElementById('seladdcalendriermoyannuelle').addEventListener('change', seladdcalendriermoyannuelleChange);
                        async function seladdcalendriermoyannuelleChange(e){
                            e.preventDefault();

                            var userCodeStr = document.getElementById('userCode').innerText;
                            var effectueMoyGeneraleStr = 'Oui';
                            var statutMoyGeneraleStr = 'Oui';
                            var docsMoyGenerale = [];

                            var seladdcalendriermoyannuelleStr = document.getElementById('seladdcalendriermoyannuelle').value;
                            if(!seladdcalendriermoyannuelleStr){
                                alert('Sélectionner Division Année Scolaire avant de continuer');
                                document.getElementById('seladdcalendriermoyannuelle').value = '';
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('savecalendriermoyannuelle').style.display = 'block';
                                document.getElementById('saveprogrammemoyannuelle').style.display = 'none';
                                document.getElementById('savetotalcumulmoyannuelle').style.display = 'none';
                                document.getElementById('savetotalcoefficientmoyannuelle').style.display = 'none';
                                document.getElementById('saveobtenumoyannuelle').style.display = 'none';
                                document.getElementById('savemoyannuelle').style.display = 'none';
                            }else{
                                try {
                                    let resultMoyenne = await db.createIndex({
                                        index: {
                                            fields: [
                                                'moygeneraleeffectue',
                                                'moygeneralestatut',
                                                'inscritcode'
                                            ]
                                        }
                                    });
                                    resultMoyenne = await db.find({
                                        selector: {
                                            moygeneraleeffectue: effectueMoyGeneraleStr,
                                            moygeneralestatut: statutMoyGeneraleStr,
                                            inscritcode: userCodeStr
                                        }
                                    });
                                    docsMoyGenerale = resultMoyenne.docs;
                                    var resultatMoyGenerale = docsMoyGenerale.length;
                                    if(resultatMoyGenerale === 0){
                                        alert('Aucun Résultat Moyennes Générales Disponibles');
                                        document.getElementById('seladdcalendriermoyannuelleStr').value = '';
                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('savecalendriermoyannuelle').style.display = 'block';
                                        document.getElementById('saveprogrammemoyannuelle').style.display = 'none';
                                        document.getElementById('savetotalcumulmoyannuelle').style.display = 'none';
                                        document.getElementById('savetotalcoefficientmoyannuelle').style.display = 'none';
                                        document.getElementById('saveobtenumoyannuelle').style.display = 'none';
                                        document.getElementById('savemoyannuelle').style.display = 'none';
                                    }else{
                                        //calcul moyenne annuelle
                                        var totalCalendrier = docsMoyGenerale.length;
                                        var totalMoyenneCumule = 0;
                                        var moyenneAnnuelle = 0;
                                        for(let i=0; i<docsMoyGenerale.length; i++){
                                            totalMoyenneCumule += parseFloat(docsMoyGenerale[i].moygeneraleobtenue);
                                        }
                                        //Moyenne Annuelle
                                        moyenneAnnuelle = (totalMoyenneCumule / totalCalendrier).toFixed(2);
                                        document.getElementById('addtotalcumulmoyannuelle').innerText = totalMoyenneCumule;
                                        document.getElementById('addtotalcoefficientmoyannuelle').innerText = totalCalendrier;
                                        document.getElementById('addmoyannuelleobtenue').innerText = moyenneAnnuelle;
                                        //assign data to inputs
                                        document.getElementById('addselectcalendriermoyannuelle').value = seladdcalendriermoyannuelleStr;
                                        document.getElementById('addmoyennecumulmoyannuelle').value = totalMoyenneCumule.toString();
                                        document.getElementById('addcoefficientcumulemoyannuelle').value = totalCalendrier.toString();
                                        document.getElementById('addmoyennemoyannuelle').value = moyenneAnnuelle.toString();

                                        updateAddMoyAnnuelleUIWithDocs(docsMoyGenerale);
                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('savecalendriermoyannuelle').style.display = 'block';
                                        document.getElementById('saveprogrammemoyannuelle').style.display = 'block';
                                        document.getElementById('savetotalcumulmoyannuelle').style.display = 'block';
                                        document.getElementById('savetotalcoefficientmoyannuelle').style.display = 'block';
                                        document.getElementById('saveobtenumoyannuelle').style.display = 'block';
                                        document.getElementById('savemoyannuelle').style.display = 'block';
                                    }
                               } catch (error) {
                                   alert('Erreur Exécution');
                               } 
                            }
                        }
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }
        /**nav previous page button*/
        document.getElementById('prevaddmoyannuelleBtn_1').addEventListener('click', prevaddmoyannuelle1BtnChange);
        async function prevaddmoyannuelle1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'none';
            document.getElementById('moyenne').style.display = 'none';
            document.getElementById('moyannuelle').style.display = 'block';
            document.getElementById('crudmoyannuelle').style.display = 'block';
            document.getElementById('addmoyannuelle').style.display = 'none';
            document.getElementById('editmoyannuelle').style.display = 'none';
            document.getElementById('deletemoyannuelle').style.display = 'none';
            document.getElementById('searchmoyannuelle').style.display = 'none';
            titre.innerHTML = 'Calcul Moyenne Annuelle'; 
        }
        // Adds the document to the HTML First table.
        // If the document already exists in the table, it will be updated.
        function addProgrammeMoyAnnuelleTableDocToTable(doc, addProgrammeMoyAnnuelleTableBody){
            // Each row of the table has an id attribute set to the doc._id field.
            var tr = addProgrammeMoyAnnuelleTableBody.querySelector(`#${doc._id}`);

            

            if( !tr ) {
                tr = document.createElement('tr');
                tr.id = doc._id;
            }
        
            // Remove any existing table cells.
            while(tr.hasChildNodes()) {
                tr.removeChild(tr.childNodes[0]);
            }

            // Create new table cells for text fields.
            var tdTrimestre = document.createElement('td');
            tdTrimestre.innerText = doc.anneescolaire;
            tr.appendChild(tdTrimestre);

            // Create new table cells for text fields.
            var tdMoyenne = document.createElement('td');
            tdMoyenne.innerText = doc.moygeneraleobtenue;
            tr.appendChild(tdMoyenne);


            // Add the row to the top of the table.
            addProgrammeMoyAnnuelleTableBody.insertBefore(tr, addProgrammeMoyAnnuelleTableBody.nextSibling);
        }
        /**Clear and update the UI to display the docs.*/
        function updateAddMoyAnnuelleUIWithDocs(docs) {
            var addProgrammeMoyAnnuelleTableBody = document.getElementById('addProgrammeMoyAnnuelleTableBody');
            
            // Remove all child nodes from the table
            while(addProgrammeMoyAnnuelleTableBody.hasChildNodes()) {
                addProgrammeMoyAnnuelleTableBody.removeChild(addProgrammeMoyAnnuelleTableBody.childNodes[0]);
            }
            
            // Add the new documents to the table
            for(var i=0; i<docs.length; i++) {
                var doc = docs[i];
                addProgrammeMoyAnnuelleTableDocToTable(doc, addProgrammeMoyAnnuelleTableBody);
            }
        }
        /***************************
        enregistrermoyannuelleBtn
        *****************************/
        document.getElementById('enregistrermoyannuelleBtn').addEventListener('click', enregistrermoyannuelleBtnChange);
        async function enregistrermoyannuelleBtnChange(e){
            e.preventDefault();

            var usercodeStr = document.getElementById('userCode').innerText;
            var addselectcalendriermoyannuelleStr = document.getElementById('addselectcalendriermoyannuelle').value;
            var addmoyennecumulmoyannuelleStr = document.getElementById('addmoyennecumulmoyannuelle').value;
            var addcoefficientcumulemoyannuelleStr = document.getElementById('addcoefficientcumulemoyannuelle').value;
            var addmoyennemoyannuelleStr = document.getElementById('addmoyennemoyannuelle').value;
            var addeffectuemoyannuelleStr = 'Oui';
            var datejourStr = getCurrentDate();
            var addstatutmoyannuelleStr = 'Oui';
            var docsMoyAnnuelle = [];

            if(!usercodeStr){
                alert("Identifiez-vous à votre Compte avant de continuer");
            }else if(!addselectcalendriermoyannuelleStr){
                alert("Sélectionner Division Année Scolaire");
            }else if(!addmoyennecumulmoyannuelleStr){
                alert("Reprendre le Calcul Moyenne Annuelle");
            }else if(!addcoefficientcumulemoyannuelleStr){
                alert("Reprendre le Calcul Moyenne Annuelle");
            }else if(!addmoyennemoyannuelleStr){
                alert("Reprendre le Calcul Moyenne Annuelle");
            }else{
                //check if this moyenne annuelle already exists
                try {
                    let resultMoyAnnuelle = await db.createIndex({
                        index: {
                            fields: [
                                'inscritcode', 
                                'anneedivision', 
                                'moyannuelleeffectue',
                                'moyannuellestatut'
                            ]
                        }
                    });
                    resultMoyAnnuelle = await db.find({
                        selector: {
                            inscritcode: usercodeStr,
                            anneedivision: addselectcalendriermoyannuelleStr,
                            moyannuelleeffectue: addeffectuemoyannuelleStr,
                            moyannuellestatut: addstatutmoyannuelleStr
                        }
                    });
                    docsMoyAnnuelle = resultMoyAnnuelle.docs;
                    var resultatMoyAnnuelle = docsMoyAnnuelle.length;
                    if(resultatMoyAnnuelle === 0){
                        //Insert
                        var now = Date.now();
                        var id = 'moyannuelle_' + now;
                        // Add the document to the database.
                        var response = await db.put({
                            _id: id,
                            inscritcode: usercodeStr,
                            anneedivision: addselectcalendriermoyannuelleStr,
                            moyannuellecumule: addmoyennecumulmoyannuelleStr.toString(),
                            moyannuellecoefficientcumule: addcoefficientcumulemoyannuelleStr.toString(),
                            moyannuelleobtenue: addmoyennemoyannuelleStr.toString(),
                            moyannuelleeffectue: addeffectuemoyannuelleStr,
                            moyannuelledate: datejourStr,
                            moyannuellestatut: addstatutmoyannuelleStr,
                        });
                        // check if document inserted
                        var doc = await db.get(response.id);
                        if(doc !== ''){
                            alert("Enregistrement Moyenne Annuelle effectué avec Succès");
                            document.getElementById('addselectcalendriermoyannuelle').value = '';
                            document.getElementById('addmoyennecumulmoyannuelle').value = '';
                            document.getElementById('addcoefficientcumulemoyannuelle').value = '';
                            document.getElementById('addmoyennemoyannuelle').value = '';

                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');

                            document.getElementById('demarrage').style.display = 'none';
                            document.getElementById('tableaubord').style.display = 'none';
                            document.getElementById('dashboard').style.display = 'none';
                            document.getElementById('configurations').style.display = 'none';
                            document.getElementById('configurer').style.display = 'none';
                            document.getElementById('emploidutemps').style.display = 'none';
                            document.getElementById('exercice').style.display = 'none';
                            document.getElementById('pratique').style.display = 'none';
                            document.getElementById('tache').style.display = 'none';
                            document.getElementById('revision').style.display = 'none';
                            document.getElementById('recherche').style.display = 'none';
                            document.getElementById('programme').style.display = 'none';
                            document.getElementById('notes').style.display = 'none';
                            document.getElementById('moyenne').style.display = 'none';
                            document.getElementById('moyannuelle').style.display = 'block';
                            document.getElementById('crudmoyannuelle').style.display = 'block';
                            document.getElementById('addmoyannuelle').style.display = 'none';
                            document.getElementById('editmoyannuelle').style.display = 'none';
                            document.getElementById('deletemoyannuelle').style.display = 'none';
                            document.getElementById('searchmoyannuelle').style.display = 'none';
                            titre.innerHTML = 'Calcul Moyenne Annuelle'; 
                        }else{
                            alert("Echec Enregistrement Moyenne Annuelle. Reprendre");
                            document.getElementById('addselectcalendriermoyannuelle').value = '';
                            document.getElementById('addmoyennecumulmoyannuelle').value = '';
                            document.getElementById('addcoefficientcumulemoyannuelle').value = '';
                            document.getElementById('addmoyennemoyannuelle').value = '';

                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');

                            document.getElementById('demarrage').style.display = 'none';
                            document.getElementById('tableaubord').style.display = 'none';
                            document.getElementById('dashboard').style.display = 'none';
                            document.getElementById('configurations').style.display = 'none';
                            document.getElementById('configurer').style.display = 'none';
                            document.getElementById('emploidutemps').style.display = 'none';
                            document.getElementById('exercice').style.display = 'none';
                            document.getElementById('pratique').style.display = 'none';
                            document.getElementById('tache').style.display = 'none';
                            document.getElementById('revision').style.display = 'none';
                            document.getElementById('recherche').style.display = 'none';
                            document.getElementById('programme').style.display = 'none';
                            document.getElementById('notes').style.display = 'none';
                            document.getElementById('moyenne').style.display = 'none';
                            document.getElementById('moyannuelle').style.display = 'block';
                            document.getElementById('crudmoyannuelle').style.display = 'block';
                            document.getElementById('addmoyannuelle').style.display = 'none';
                            document.getElementById('editmoyannuelle').style.display = 'none';
                            document.getElementById('deletemoyannuelle').style.display = 'none';
                            document.getElementById('searchmoyannuelle').style.display = 'none';
                            titre.innerHTML = 'Calcul Moyenne Annuelle'; 
                        }
                    }else{
                        alert("Moyenne Annuelle déjà Enregistrée. Modification Possible");
                        document.getElementById('addselectcalendriermoyannuelle').value = '';
                        document.getElementById('addmoyennecumulmoyannuelle').value = '';
                        document.getElementById('addcoefficientcumulemoyannuelle').value = '';
                        document.getElementById('addmoyennemoyannuelle').value = '';

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'none';
                        document.getElementById('programme').style.display = 'none';
                        document.getElementById('notes').style.display = 'none';
                        document.getElementById('moyenne').style.display = 'none';
                        document.getElementById('moyannuelle').style.display = 'block';
                        document.getElementById('crudmoyannuelle').style.display = 'block';
                        document.getElementById('addmoyannuelle').style.display = 'none';
                        document.getElementById('editmoyannuelle').style.display = 'none';
                        document.getElementById('deletemoyannuelle').style.display = 'none';
                        document.getElementById('searchmoyannuelle').style.display = 'none';
                        titre.innerHTML = 'Calcul Moyenne Annuelle'; 
                    }
                } catch (error) {
                   alert('Erreur Exécution'); 
                }
            }
        }

        /***************************
        editmoyannuelleBtn
        *****************************/
        document.getElementById('editmoyannuelleBtn').addEventListener('click', editmoyannuelleBtnChange);
        async function editmoyannuelleBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutanneeStr = 'Oui';

            var docsAnnee = [];

            if(!userCodeStr){
                alert('Connectez-vous à votre Compte avant de continuer');
            }else{
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: ['anneestatut', 'inscritcode']
                        }
                    });
                    result = await db.find({
                        selector: {
                            anneestatut: statutanneeStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsAnnee  = result.docs;
                    var resultatAnnee = docsAnnee.length;
                    if(resultatAnnee === 0){
                        alert('Aucune Moyenne Annuelle Enregistrée');
                    }else{
                        var divClear;
                        var divModifier;
                        divClear = document.getElementById('editcalendriermoyannuelle');
                        while(divClear.hasChildNodes()) {
                            divClear.removeChild(divClear.childNodes[0]);
                        }
                        divModifier = document.getElementById('editcalendriermoyannuelle');
                        //Create and append select list
                        var selectList = document.createElement("select");
                        selectList.id = "seleditcalendriermoyannuelle";
                        divModifier.appendChild(selectList);
                        //Create and append the options
                        var option_null = document.createElement("option");
                        option_null.value = '';
                        option_null.text = 'Sélectionner Division Année';
                        selectList.appendChild(option_null);
                        //Create and append the options
                        for (var i = 0; i<1; i++){
                            var option = document.createElement("option");
                            option.value = docsAnnee[i].anneedivision;
                            option.text = docsAnnee[i].anneedivision;
                            selectList.appendChild(option);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'none';
                        document.getElementById('programme').style.display = 'none';
                        document.getElementById('notes').style.display = 'none';
                        document.getElementById('moyenne').style.display = 'none';
                        document.getElementById('moyannuelle').style.display = 'block';
                        document.getElementById('crudmoyannuelle').style.display = 'none';
                        document.getElementById('addmoyannuelle').style.display = 'none';
                        document.getElementById('editmoyannuelle').style.display = 'block';
                        document.getElementById('deletemoyannuelle').style.display = 'none';
                        document.getElementById('searchmoyannuelle').style.display = 'none';
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('changecalendriermoyannuelle').style.display = 'block';
                        document.getElementById('changeprogrammemoyannuelle').style.display = 'none';
                        document.getElementById('changetotalcumulmoyannuelle').style.display = 'none';
                        document.getElementById('changetotalcoefficientmoyannuelle').style.display = 'none';
                        document.getElementById('changeobtenumoyannuelle').style.display = 'none';
                        document.getElementById('changemoyannuelle').style.display = 'none';
                        titre.innerHTML = 'Modifier Moyenne Annuelle';

                        /***************************
                        seleditcalendriermoyannuelle
                        *****************************/
                        document.getElementById('seleditcalendriermoyannuelle').addEventListener('change', seleditcalendriermoyannuelleChange);
                        async function seleditcalendriermoyannuelleChange(e){
                            e.preventDefault();

                            var userCodeStr = document.getElementById('userCode').innerText;
                            var effectueMoyGeneraleStr = 'Oui';
                            var statutMoyGeneraleStr = 'Oui';
                            var docsMoyGenerale = [];

                            var seleditcalendriermoyannuelleStr = document.getElementById('seleditcalendriermoyannuelle').value;
                            if(!seleditcalendriermoyannuelleStr){
                                alert('Sélectionner Division Année Scolaire avant de continuer');
                                document.getElementById('seleditcalendriermoyannuelle').value = '';
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('changecalendriermoyannuelle').style.display = 'block';
                                document.getElementById('changeprogrammemoyannuelle').style.display = 'none';
                                document.getElementById('changetotalcumulmoyannuelle').style.display = 'none';
                                document.getElementById('changetotalcoefficientmoyannuelle').style.display = 'none';
                                document.getElementById('changeobtenumoyannuelle').style.display = 'none';
                                document.getElementById('changemoyannuelle').style.display = 'none';
                            }else{
                                try {
                                    let resultAnnuelle = await db.createIndex({
                                        index: {
                                            fields: [
                                                'moygeneraleeffectue',
                                                'moygeneralestatut',
                                                'inscritcode'
                                            ]
                                        }
                                    });
                                    resultAnnuelle = await db.find({
                                        selector: {
                                            moygeneraleeffectue: effectueMoyGeneraleStr,
                                            moygeneralestatut: statutMoyGeneraleStr,
                                            inscritcode: userCodeStr
                                        }
                                    });
                                    docsMoyGenerale = resultAnnuelle.docs;
                                    var resultatMoyAnnuelle = docsMoyGenerale.length;
                                    if(resultatMoyAnnuelle === 0){
                                        alert('Aucun Résultat Moyenne Annuelle Disponible');
                                        document.getElementById('seleditcalendriermoyannuelleStr').value = '';
                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('changecalendriermoyannuelle').style.display = 'block';
                                        document.getElementById('changeprogrammemoyannuelle').style.display = 'none';
                                        document.getElementById('changetotalcumulmoyannuelle').style.display = 'none';
                                        document.getElementById('changetotalcoefficientmoyannuelle').style.display = 'none';
                                        document.getElementById('changeobtenumoyannuelle').style.display = 'none';
                                        document.getElementById('changemoyannuelle').style.display = 'none';
                                    }else{
                                        var statutannuelleStr = 'Oui';
                                        var effectueannuelleStr = 'Oui';
                                        var docsAnnuelle = [];
                                        var idAnnuelle,moyAnnuelleDateStr = '';
                                        let resultMoyenne = await db.createIndex({
                                            index: {
                                                fields: [
                                                    'inscritcode',
                                                    'moyannuellestatut',
                                                    'moyannuelleeffectue'
                                                ]
                                            }
                                        });
                                        resultMoyenne = await db.find({
                                            selector: {
                                                inscritcode: userCodeStr,
                                                moyannuellestatut: statutannuelleStr,
                                                moyannuelleeffectue: effectueannuelleStr
                                            }
                                        });
                                        docsAnnuelle = resultMoyenne.docs;
                                        for(let k=0; k<docsAnnuelle.length; k++){
                                            idAnnuelle = docsAnnuelle[k]._id;
                                            moyAnnuelleDateStr = docsAnnuelle[k].moyannuelledate;
                                        }
                                        document.getElementById('editidmoyannuelle').value = idAnnuelle;
                                        document.getElementById('editmoyannuelledate').value = moyAnnuelleDateStr;

                                        //calcul moyenne annuelle
                                        var totalCalendrier = docsMoyGenerale.length;
                                        var totalMoyenneCumule = 0;
                                        var moyenneAnnuelle = 0;
                                        for(let i=0; i<docsMoyGenerale.length; i++){
                                            totalMoyenneCumule += parseFloat(docsMoyGenerale[i].moygeneraleobtenue);
                                        }
                                        //Moyenne Annuelle
                                        moyenneAnnuelle = (totalMoyenneCumule / totalCalendrier).toFixed(2);
                                        document.getElementById('edittotalcumulmoyannuelle').innerText = totalMoyenneCumule;
                                        document.getElementById('edittotalcoefficientmoyannuelle').innerText = totalCalendrier;
                                        document.getElementById('editmoyannuelleobtenue').innerText = moyenneAnnuelle;
                                        //assign data to inputs
                                        document.getElementById('editselectcalendriermoyannuelle').value = seleditcalendriermoyannuelleStr;
                                        document.getElementById('editmoyennecumulmoyannuelle').value = totalMoyenneCumule.toString();
                                        document.getElementById('editcoefficientcumulemoyannuelle').value = totalCalendrier.toString();
                                        document.getElementById('editmoyennemoyannuelle').value = moyenneAnnuelle.toString();

                                        updateEditMoyAnnuelleUIWithDocs(docsMoyGenerale);
                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('changecalendriermoyannuelle').style.display = 'block';
                                        document.getElementById('changeprogrammemoyannuelle').style.display = 'block';
                                        document.getElementById('changetotalcumulmoyannuelle').style.display = 'block';
                                        document.getElementById('changetotalcoefficientmoyannuelle').style.display = 'block';
                                        document.getElementById('changeobtenumoyannuelle').style.display = 'block';
                                        document.getElementById('changemoyannuelle').style.display = 'block';
                                    }
                                } catch (error) {
                                    alert('Erreur Exécution');
                                }
                            }
                        }
                    }
                } catch (error) {
                   alert('Erreur Exécution'); 
                }
            }
        }
        /**nav previous page button*/
        document.getElementById('preveditmoyannuelleBtn_1').addEventListener('click', preveditmoyannuelle1BtnChange);
        async function preveditmoyannuelle1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'none';
            document.getElementById('moyenne').style.display = 'none';
            document.getElementById('moyannuelle').style.display = 'block';
            document.getElementById('crudmoyannuelle').style.display = 'block';
            document.getElementById('addmoyannuelle').style.display = 'none';
            document.getElementById('editmoyannuelle').style.display = 'none';
            document.getElementById('deletemoyannuelle').style.display = 'none';
            document.getElementById('searchmoyannuelle').style.display = 'none';
            titre.innerHTML = 'Calcul Moyenne Annuelle'; 
        }
        // Adds the document to the HTML First table.
        // If the document already exists in the table, it will be updated.
        function editProgrammeMoyAnnuelleTableDocToTable(doc, editProgrammeMoyAnnuelleTableBody){
            // Each row of the table has an id attribute set to the doc._id field.
            var tr = editProgrammeMoyAnnuelleTableBody.querySelector(`#${doc._id}`);

            

            if( !tr ) {
                tr = document.createElement('tr');
                tr.id = doc._id;
            }
        
            // Remove any existing table cells.
            while(tr.hasChildNodes()) {
                tr.removeChild(tr.childNodes[0]);
            }

            // Create new table cells for text fields.
            var tdTrimestre = document.createElement('td');
            tdTrimestre.innerText = doc.anneescolaire;
            tr.appendChild(tdTrimestre);

            // Create new table cells for text fields.
            var tdMoyenne = document.createElement('td');
            tdMoyenne.innerText = doc.moygeneraleobtenue;
            tr.appendChild(tdMoyenne);


            // Add the row to the top of the table.
            editProgrammeMoyAnnuelleTableBody.insertBefore(tr, editProgrammeMoyAnnuelleTableBody.nextSibling);
        }
        /**Clear and update the UI to display the docs.*/
        function updateEditMoyAnnuelleUIWithDocs(docs) {
            var editProgrammeMoyAnnuelleTableBody = document.getElementById('editProgrammeMoyAnnuelleTableBody');
            
            // Remove all child nodes from the table
            while(editProgrammeMoyAnnuelleTableBody.hasChildNodes()) {
                editProgrammeMoyAnnuelleTableBody.removeChild(editProgrammeMoyAnnuelleTableBody.childNodes[0]);
            }
            
            // Add the new documents to the table
            for(var i=0; i<docs.length; i++) {
                var doc = docs[i];
                editProgrammeMoyAnnuelleTableDocToTable(doc, editProgrammeMoyAnnuelleTableBody);
            }
        }
        /***************************
        modifiermoyannuelleBtn
        *****************************/
        document.getElementById('modifiermoyannuelleBtn').addEventListener('click', modifiermoyannuelleBtnChange);
        async function modifiermoyannuelleBtnChange(e){
            e.preventDefault();

            var usercodeStr = document.getElementById('userCode').innerText;
            var editidmoyannuelleStr = document.getElementById('editidmoyannuelle').value;
            var editselectcalendriermoyannuelleStr = document.getElementById('editselectcalendriermoyannuelle').value;
            var editmoyennecumulmoyannuelleStr = document.getElementById('editmoyennecumulmoyannuelle').value;
            var editcoefficientcumulemoyannuelleStr = document.getElementById('editcoefficientcumulemoyannuelle').value;
            var editmoyennemoyannuelleStr = document.getElementById('editmoyennemoyannuelle').value;
            var editeffectuemoyannuelleStr = 'Oui';
            var datejourStr = document.getElementById('editmoyannuelledate').value;;
            var editstatutmoyannuelleStr = 'Oui';
            var docsMoyAnnuelle = [];

            if(!usercodeStr){
                alert("Identifiez-vous avant de continuer");
            }else{
                try {
                    //check if this moyenne annuelle already exists
                    let resultMoyAnnuelle = await db.createIndex({
                        index: {
                            fields: [
                                '_id',
                                'inscritcode',
                                'anneedivision',
                                'moyannuellestatut'
                            ]
                        }
                    });
                    resultMoyAnnuelle = await db.find({
                        selector: {
                            _id: editidmoyannuelleStr,
                            inscritcode: usercodeStr,
                            anneedivision: editselectcalendriermoyannuelleStr,
                            moyannuellestatut: editstatutmoyannuelleStr
                        }
                    });
                    docsMoyAnnuelle = resultMoyAnnuelle.docs;
                    var resultatMoyAnnuelle = docsMoyAnnuelle.length;
                    if(resultatMoyAnnuelle === 0){
                        alert("Moyenne Annuelle non encore enregistrée");
                    }else{
                        var moyenneObtenue = 0;
                        for(let i=0; i<docsMoyAnnuelle.length; i++){
                            moyenneObtenue = parseFloat(docsMoyAnnuelle[i].moyannuelleobtenue);
                        }
                        if(moyenneObtenue === parseFloat(editmoyennemoyannuelleStr)){
                            alert("Moyenne Annuelle Modifiée avec Succès");
                            document.getElementById('editidmoyannuelle').value = '';
                            document.getElementById('editselectcalendriermoyannuelle').value = '';
                            document.getElementById('editmoyennecumulmoyannuelle').value = '';
                            document.getElementById('editcoefficientcumulemoyannuelle').value = '';
                            document.getElementById('editmoyennemoyannuelle').value = '';
                            document.getElementById('editmoyannuelledate').value = '';

                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');

                            document.getElementById('demarrage').style.display = 'none';
                            document.getElementById('tableaubord').style.display = 'none';
                            document.getElementById('dashboard').style.display = 'none';
                            document.getElementById('configurations').style.display = 'none';
                            document.getElementById('configurer').style.display = 'none';
                            document.getElementById('emploidutemps').style.display = 'none';
                            document.getElementById('exercice').style.display = 'none';
                            document.getElementById('pratique').style.display = 'none';
                            document.getElementById('tache').style.display = 'none';
                            document.getElementById('revision').style.display = 'none';
                            document.getElementById('recherche').style.display = 'none';
                            document.getElementById('programme').style.display = 'none';
                            document.getElementById('notes').style.display = 'none';
                            document.getElementById('moyenne').style.display = 'none';
                            document.getElementById('moyannuelle').style.display = 'block';
                            document.getElementById('crudmoyannuelle').style.display = 'block';
                            document.getElementById('addmoyannuelle').style.display = 'none';
                            document.getElementById('editmoyannuelle').style.display = 'none';
                            document.getElementById('deletemoyannuelle').style.display = 'none';
                            document.getElementById('searchmoyannuelle').style.display = 'none';
                            titre.innerHTML = 'Calcul Moyenne Annuelle'; 
                        }else{
                            // Retrieve the document to get the latest revision
                            var idModifier = editidmoyannuelleStr;
                            var docModifier = await db.get(idModifier);
                            // Make changes
                            docModifier.inscritcode = usercodeStr;
                            docModifier.anneedivision = editselectcalendriermoyannuelleStr;
                            docModifier.moyannuellecumule = editmoyennecumulmoyannuelleStr;
                            docModifier.moyannuellecoefficientcumule = editcoefficientcumulemoyannuelleStr;
                            docModifier.moyannuelleobtenue = editmoyennemoyannuelleStr;
                            docModifier.moyannuelleeffectue = editeffectuemoyannuelleStr;
                            docModifier.moyannuelledate = datejourStr;
                            docModifier.moyannuellestatut = editstatutmoyannuelleStr;

                            // Put the document, saving it as a new revision
                            var responseModifier = await db.put(docModifier);
                            // Get the document we change and update the UI.
                            var docEdit = await db.get(responseModifier.id);
                            if(docEdit !== ''){
                                alert("Moyenne Annuelle Modifiée avec Succès");
                                document.getElementById('editidmoyannuelle').value = '';
                                document.getElementById('editselectcalendriermoyannuelle').value = '';
                                document.getElementById('editmoyennecumulmoyannuelle').value = '';
                                document.getElementById('editcoefficientcumulemoyannuelle').value = '';
                                document.getElementById('editmoyennemoyannuelle').value = '';
                                document.getElementById('editmoyannuelledate').value = '';

                                document.getElementById('titre').style.display = 'block';
                                var titre = document.getElementById('titre');

                                document.getElementById('demarrage').style.display = 'none';
                                document.getElementById('tableaubord').style.display = 'none';
                                document.getElementById('dashboard').style.display = 'none';
                                document.getElementById('configurations').style.display = 'none';
                                document.getElementById('configurer').style.display = 'none';
                                document.getElementById('emploidutemps').style.display = 'none';
                                document.getElementById('exercice').style.display = 'none';
                                document.getElementById('pratique').style.display = 'none';
                                document.getElementById('tache').style.display = 'none';
                                document.getElementById('revision').style.display = 'none';
                                document.getElementById('recherche').style.display = 'none';
                                document.getElementById('programme').style.display = 'none';
                                document.getElementById('notes').style.display = 'none';
                                document.getElementById('moyenne').style.display = 'none';
                                document.getElementById('moyannuelle').style.display = 'block';
                                document.getElementById('crudmoyannuelle').style.display = 'block';
                                document.getElementById('addmoyannuelle').style.display = 'none';
                                document.getElementById('editmoyannuelle').style.display = 'none';
                                document.getElementById('deletemoyannuelle').style.display = 'none';
                                document.getElementById('searchmoyannuelle').style.display = 'none';
                                titre.innerHTML = 'Calcul Moyenne Annuelle'; 
                            }else{
                                alert("Echec Modification Moyenne Annuelle. Reprendre");
                                document.getElementById('editidmoyannuelle').value = '';
                                document.getElementById('editselectcalendriermoyannuelle').value = '';
                                document.getElementById('editmoyennecumulmoyannuelle').value = '';
                                document.getElementById('editcoefficientcumulemoyannuelle').value = '';
                                document.getElementById('editmoyennemoyannuelle').value = '';
                                document.getElementById('editmoyannuelledate').value = '';

                                document.getElementById('titre').style.display = 'block';
                                var titre = document.getElementById('titre');

                                document.getElementById('demarrage').style.display = 'none';
                                document.getElementById('tableaubord').style.display = 'none';
                                document.getElementById('dashboard').style.display = 'none';
                                document.getElementById('configurations').style.display = 'none';
                                document.getElementById('configurer').style.display = 'none';
                                document.getElementById('emploidutemps').style.display = 'none';
                                document.getElementById('exercice').style.display = 'none';
                                document.getElementById('pratique').style.display = 'none';
                                document.getElementById('tache').style.display = 'none';
                                document.getElementById('revision').style.display = 'none';
                                document.getElementById('recherche').style.display = 'none';
                                document.getElementById('programme').style.display = 'none';
                                document.getElementById('notes').style.display = 'none';
                                document.getElementById('moyenne').style.display = 'none';
                                document.getElementById('moyannuelle').style.display = 'block';
                                document.getElementById('crudmoyannuelle').style.display = 'block';
                                document.getElementById('addmoyannuelle').style.display = 'none';
                                document.getElementById('editmoyannuelle').style.display = 'none';
                                document.getElementById('deletemoyannuelle').style.display = 'none';
                                document.getElementById('searchmoyannuelle').style.display = 'none';
                                titre.innerHTML = 'Calcul Moyenne Annuelle'; 
                            }
                        }
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }

        /***************************
        deletemoyannuelleBtn
        *****************************/
        document.getElementById('deletemoyannuelleBtn').addEventListener('click', deletemoyannuelleBtnChange);
        async function deletemoyannuelleBtnChange(e){
            e.preventDefault();

            alert("Impossible Supprimer une Moyenne Annuelle une fois Enregistrée");
        }

        /***************************
        searchmoyannuelleBtn
        *****************************/
        document.getElementById('searchmoyannuelleBtn').addEventListener('click', searchmoyannuelleBtnChange);
        async function searchmoyannuelleBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var moyannuelleeffectueStr = 'Oui';
            var statutmoyannuelleStr = 'Oui';

            var docsMoyAnnuelle = [];

            if(!userCodeStr){
                alert('Connectez-vous à votre Compte avant de continuer');
            }else{
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: ['inscritcode', 'moyannuelleeffectue', 'moyannuellestatut']
                        }
                    });
                    result = await db.find({
                        selector: {
                            inscritcode: userCodeStr,
                            moyannuelleeffectue: moyannuelleeffectueStr,
                            moyannuellestatut: statutmoyannuelleStr
                        }
                    });
                    docsMoyAnnuelle  = result.docs;
                    var resultatMoyAnnuelle = docsMoyAnnuelle.length;
                    if(resultatMoyAnnuelle === 0){
                        alert('Aucune Moyenne Annuelle Enregistrée');
                    }else{
                        var divClear;
                        var divModifier;
                        divClear = document.getElementById('searchcalendriermoyannuelle');
                        while(divClear.hasChildNodes()) {
                            divClear.removeChild(divClear.childNodes[0]);
                        }
                        divModifier = document.getElementById('searchcalendriermoyannuelle');
                        //Create and append select list
                        var selectList = document.createElement("select");
                        selectList.id = "selsearchcalendriermoyannuelle";
                        divModifier.appendChild(selectList);
                        //Create and append the options
                        var option_null = document.createElement("option");
                        option_null.value = '';
                        option_null.text = 'Sélectionner Division Année';
                        selectList.appendChild(option_null);
                        //Create and append the options
                        for (var i = 0; i<1; i++){
                            var option = document.createElement("option");
                            option.value = docsMoyAnnuelle[i].anneedivision;
                            option.text = docsMoyAnnuelle[i].anneedivision;
                            selectList.appendChild(option);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'none';
                        document.getElementById('programme').style.display = 'none';
                        document.getElementById('notes').style.display = 'none';
                        document.getElementById('moyenne').style.display = 'none';
                        document.getElementById('moyannuelle').style.display = 'block';
                        document.getElementById('crudmoyannuelle').style.display = 'none';
                        document.getElementById('addmoyannuelle').style.display = 'none';
                        document.getElementById('editmoyannuelle').style.display = 'none';
                        document.getElementById('deletemoyannuelle').style.display = 'none';
                        document.getElementById('searchmoyannuelle').style.display = 'block';
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('viewcalendriermoyannuelle').style.display = 'block';
                        document.getElementById('viewprogrammemoyannuelle').style.display = 'none';
                        document.getElementById('viewconsultermoyannuelle').style.display = 'none';
                        titre.innerHTML = 'Consulter Moyenne Annuelle';

                        /***************************
                        selsearchcalendriermoyannuelle
                        *****************************/
                        document.getElementById('selsearchcalendriermoyannuelle').addEventListener('change', selsearchcalendriermoyannuelleChange);
                        async function selsearchcalendriermoyannuelleChange(e){
                            e.preventDefault();

                            var userCodeStr = document.getElementById('userCode').innerText;
                            var statutMoyAnnuelleStr = 'Oui';
                            var effectueMoyAnnuelleStr = 'Oui';
                            var docsMoyAnnuelle = [];

                            var selsearchcalendriermoyannuelleStr = document.getElementById('selsearchcalendriermoyannuelle').value;
                            if(!selsearchcalendriermoyannuelleStr){
                                alert('Sélectionner Division Calendrier avant de continuer');
                                document.getElementById('selsearchcalendriermoyannuelle').value = '';
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendriermoyannuelle').style.display = 'block';
                                document.getElementById('viewprogrammemoyannuelle').style.display = 'none';
                                document.getElementById('viewconsultermoyannuelle').style.display = 'none';
                            }else{
                                try {
                                    let result = await db.createIndex({
                                        index: {
                                            fields: [
                                                'moyannuelleeffectue',
                                                'moyannuellestatut',
                                                'inscritcode'
                                            ]
                                        }
                                    });
                                    result = await db.find({
                                        selector: {
                                            moyannuelleeffectue: effectueMoyAnnuelleStr,
                                            moyannuellestatut: statutMoyAnnuelleStr,
                                            inscritcode: userCodeStr
                                        }
                                    });
                                    docsMoyAnnuelle = result.docs;
                                    var resultatMoyAnnuelle = docsMoyAnnuelle.length;
                                    if(resultatMoyAnnuelle === 0){
                                        alert('Aucun Résultat Moyenne Annuelle Disponible');
                                        document.getElementById('selsearchcalendriermoyannuelle').value = '';
                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('viewcalendriermoyannuelle').style.display = 'block';
                                        document.getElementById('viewprogrammemoyannuelle').style.display = 'none';
                                        document.getElementById('viewconsultermoyannuelle').style.display = 'none';
                                    }else{
                                        updateSearchMoyAnnuelleUIWithDocs(docsMoyAnnuelle);
                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('viewcalendriermoyannuelle').style.display = 'block';
                                        document.getElementById('viewprogrammemoyannuelle').style.display = 'block';
                                        document.getElementById('viewconsultermoyannuelle').style.display = 'block';
                                    }
                                } catch (error) {
                                   alert('Erreur Exécution'); 
                                }
                            }
                        }
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }
        /**nav previous page button*/
        document.getElementById('prevsearchmoyannuelleBtn_1').addEventListener('click', prevsearchmoyannuelle1BtnChange);
        async function prevsearchmoyannuelle1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'none';
            document.getElementById('moyenne').style.display = 'none';
            document.getElementById('moyannuelle').style.display = 'block';
            document.getElementById('crudmoyannuelle').style.display = 'block';
            document.getElementById('addmoyannuelle').style.display = 'none';
            document.getElementById('editmoyannuelle').style.display = 'none';
            document.getElementById('deletemoyannuelle').style.display = 'none';
            document.getElementById('searchmoyannuelle').style.display = 'none';
            titre.innerHTML = 'Calcul Moyenne Annuelle'; 
        }
        // Adds the document to the HTML First table.
        // If the document already exists in the table, it will be updated.
        function searchProgrammeMoyAnnuelleTableDocToTable(doc, searchProgrammeMoyAnnuelleTableBody){
            // Each row of the table has an id attribute set to the doc._id field.
            var tr = searchProgrammeMoyAnnuelleTableBody.querySelector(`#${doc._id}`);

            

            if( !tr ) {
                tr = document.createElement('tr');
                tr.id = doc._id;
            }
        
            // Remove any existing table cells.
            while(tr.hasChildNodes()) {
                tr.removeChild(tr.childNodes[0]);
            }

            // Create new table cells for text fields.
            var tdTrimestre = document.createElement('td');
            tdTrimestre.innerText = doc.anneedivision;
            tr.appendChild(tdTrimestre);

            // Create new table cells for text fields.
            var tdMoyenne = document.createElement('td');
            tdMoyenne.innerText = doc.moyannuelleobtenue;
            tr.appendChild(tdMoyenne);


            // Add the row to the top of the table.
            searchProgrammeMoyAnnuelleTableBody.insertBefore(tr, searchProgrammeMoyAnnuelleTableBody.nextSibling);
        }
        /**Clear and update the UI to display the docs.*/
        function updateSearchMoyAnnuelleUIWithDocs(docs) {
            var searchProgrammeMoyAnnuelleTableBody = document.getElementById('searchProgrammeMoyAnnuelleTableBody');
            
            // Remove all child nodes from the table
            while(searchProgrammeMoyAnnuelleTableBody.hasChildNodes()) {
                searchProgrammeMoyAnnuelleTableBody.removeChild(searchProgrammeMoyAnnuelleTableBody.childNodes[0]);
            }
            
            // Add the new documents to the table
            for(var i=0; i<docs.length; i++) {
                var doc = docs[i];
                searchProgrammeMoyAnnuelleTableDocToTable(doc, searchProgrammeMoyAnnuelleTableBody);
            }
        }
        document.getElementById('consultermoyannuelleBtn').addEventListener('click', consultermoyannuelleBtnChange);
        async function consultermoyannuelleBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'none';
            document.getElementById('moyenne').style.display = 'none';
            document.getElementById('moyannuelle').style.display = 'block';
            document.getElementById('crudmoyannuelle').style.display = 'block';
            document.getElementById('addmoyannuelle').style.display = 'none';
            document.getElementById('editmoyannuelle').style.display = 'none';
            document.getElementById('deletemoyannuelle').style.display = 'none';
            document.getElementById('searchmoyannuelle').style.display = 'none';
            titre.innerHTML = 'Calcul Moyenne Annuelle'; 
        }
    }

    /****************
    HELPER FUNCTION
    *****************/
    /**1: Get Current Date in JavaScript */
    function getCurrentDate(){
        // Date object
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1;
        var yyyy = today.getFullYear();
        if(dd<10){
            dd='0'+dd;
        }
        if(mm<10){
            mm='0'+mm;
        }
        // Current Date
        var date = yyyy+'-'+mm+'-'+dd;
        return date;
    }

    window.addEventListener('load', () =>{
        new taskMoyAnnuelle();
    })
}) ();