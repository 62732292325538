import './moyannuelle.css';
import './pouchmoyannuelle';
import SupprimerButton from '../../components/SupprimerButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function DeleteMoyAnnuelle() {
    return ( 
        <div id="deletemoyannuelle">
            <KeyboardBackspace style={prevArrows} id="prevdeletemoyannuelleBtn_1" />
            <br/><br/>
            <form>
                <div id="formdeletemoyannuelle" style={scroller}>
                    <br/>
                    <div id="cancelcalendriermoyannuelle">
                        <label style={labelStyle}>Sélection Calendrier Scolaire</label>
                        <div id="deletecalendriermoyannuelle"></div>
                    </div>
                    <br/>
                    <div id="cancelprogrammemoyannuelle">
                        <label id="deletematiereprogrammemoyannuelle" style={labelStyle}>Moyennne Annuelle</label>
                        <br/><br/>
                        <table className='thin_table_config'>
                            <thead>
                                <tr>
                                    <th>Calendrier</th>
                                    <th>Moyenne</th>
                                </tr>
                            </thead>
                            <tbody id='deleteProgrammeMoyAnnuelleTableBody'></tbody>
                        </table>
                    </div>
                    <br/>
                    <div style={{display:'none'}}>
                        <input id ="deleteidmoyannuelle" type="text" />
                    </div>
                    <div id="canceltotalcumulmoyannuelle">
                        <label style={labelStyle}>Total Moyennes Générales</label>
                        <div id="deletetotalcumulmoyannuelle"></div>
                    </div>
                    <div id="canceltotalcoefficientmoyannuelle">
                        <label style={labelStyle}>Total Calendriers Cumulés</label>
                        <div id="deletetotalcoefficientmoyannuelle"></div>
                    </div>
                    <div id="cancelobtenumoyannuelle">
                        <label style={labelStyle}>Moyenne Annuelle</label>
                        <div style={{fontWeight:'bold',color:'#1a1aff'}} id="deletemoyannuelleobtenue"></div>
                    </div>
                    <br/>
                    
                    <div style={{display:'none'}}>
                        <input id ="deleteselectcalendriermoyannuelle" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deletemoyennecumulmoyannuelle" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deletecoefficientcumulemoyannuelle" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deletemoyennemoyannuelle" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deletemoyannuelledate" type="date" />
                    </div>
                    <br/>
                    <div id="cancelmoyannuelle">
                        <SupprimerButton 
                            idsupprimerbutton="supprimermoyannuelleBtn"
                            supprimer="Supprimer"
                        />
                    </div>
                    <br/><br/>
                </div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default DeleteMoyAnnuelle;