import '../configuration.css';
import './pouchconfigannee';
import DateInput from '../../components/DateInput';
import ConsulterButton from '../../components/ConsulterButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function SearchAnnee() {
    return ( 
        <div id="searchannee">
            <KeyboardBackspace style={prevArrows} id="prevsearchanneeBtn_1" />
            <br/><br/>
            <form>
                <div id="formsearchannee" style={scroller}>
                    <br/>
                    <div id="searchselectionannee">
                        <select id="selsearchannee">
                            <option value=''>Sélection Année Scolaire</option>
                            <option value='Trimestre'>Trimestrielle</option>
                            <option value='Semestre'>Semestrielle</option>
                        </select>
                    </div>
                    <div id="searchselectiontrimestre">
                        <select id="selsearchtrimannee">
                            <option value=''>Sélection Trimestre</option>
                            <option value='Trimestre 1'>Trimestre 1</option>
                            <option value='Trimestre 2'>Trimestre 2</option>
                            <option value='Trimestre 3'>Trimestre 3</option>
                        </select>
                    </div>
                    <div id="searchselectionsemestre">
                        <select id="selsearchsemannee">
                            <option value=''>Sélection Semestre</option>
                            <option value='Semestre 1'>Semestre 1</option>
                            <option value='Semestre 2'>Semestre 2</option>
                        </select>
                    </div>
                    <br/>
                    <div id="searchdebutannee">
                        <label style={labelStyle}>Date Début</label>
                        <DateInput idinput ="searchdatedebutannee" date="date" read="readOnly" /> 
                    </div>
                    <br/>
                    <br/>
                    <div id="searchfinannee">
                        <label style={labelStyle}>Date Fin</label>
                        <DateInput idinput ="searchdatefinannee" date="date" read="readOnly" /> 
                    </div>
                    <br/>
                    <div id="viewconsulterannee">
                        <ConsulterButton 
                        idconsulterbutton="consulteranneeBtn"
                        consulter="OK"
                        />
                    </div>
                </div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default SearchAnnee;