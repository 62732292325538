import { Edit, } from '@material-ui/icons';

function ModifierButton(props) {
    return ( 
        <div>
            <button id={props.idmodifierbutton} style={modifierFormButtonStyle}>
                <Edit/>
                {props.modifier}
            </button>
        </div>
    );
}

const modifierFormButtonStyle = {
    marginTop: '4%',
    flex: 1,
    width: 200,
    height: 50,
    textAlign: "center",
    alignContent: 'center',
    backgroundColor: "#1a1aff", border: 1, solid: "#1a1aff",
    fontSize: 18,
    borderRadius: 15,
    color: "#ffffff",
    /*textTransform: "uppercase",*/
};

export default ModifierButton;