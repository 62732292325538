import './pratique.css';
import './pouchpratique';
import { KeyboardBackspace, } from '@material-ui/icons';
import AddButton from "../../components/AddButton";
import EditButton from "../../components/EditButton";
import DeleteButton from "../../components/DeleteButton";
import SearchButton from "../../components/SearchButton";

function CrudPratique() {
    return ( 
        <div id="crudpratique">
            <KeyboardBackspace style={prevArrows} id="prevcrudpratiqueBtn_1" />
            <br/><br/>
            <div id="pratiquesetup" style={scroller}>
                <AddButton 
                idaddbutton = "addpratiqueBtn" 
                titre = "Exercices Pratiques" />

                <EditButton 
                ideditbutton = "editpratiqueBtn" 
                titre = "Exercices Pratiques" />

                <DeleteButton 
                iddeletebutton = "deletepratiqueBtn" 
                titre = "Exercices Pratiques" />

                <SearchButton 
                idsearchbutton = "searchpratiqueBtn" 
                titre = "Exercices Pratiques" />
            </div>
        </div>
    );
}

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#008000',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default CrudPratique;