import CrudClasse from "./CrudClasse";
import AddClasse from "./AddClasse";
import EditClasse from "./EditClasse";
import DeleteClasse from "./DeleteClasse";
import SearchClasse from "./SearchClasse";

function Classe() {
    return ( 
        <div id="classe">
            <CrudClasse />
            <AddClasse />
            <EditClasse />
            <DeleteClasse />
            <SearchClasse />
        </div>
    );
}

export default Classe;