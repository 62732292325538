import { Search, } from '@material-ui/icons';

function ConsulterButton(props) {
    return ( 
        <div>
            <button id={props.idconsulterbutton} style={consulterFormButtonStyle}>
                <Search/>
                {props.consulter}
            </button>
        </div>
    );
}

const consulterFormButtonStyle = {
    marginTop: '4%',
    flex: 1,
    width: 200,
    height: 50,
    textAlign: "center",
    alignContent: 'center',
    backgroundColor: "#808080", border: 1, solid: "#808080",
    fontSize: 18,
    borderRadius: 15,
    color: "#ffffff",
    /*textTransform: "uppercase",*/
};

export default ConsulterButton;