/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
import PouchDB from 'pouchdb';
import PouchFind from 'pouchdb-find';

(function(){
    var taskMoyMatiere = function(){

        /*****************************************************
        ENABLING POUCHDB WITHOUT SYNC TO REMOTE COUCHDB
        ******************************************************/
        //SET UP POUCHDB AND CREATE THE DATABASE 
        const db = new PouchDB('suclu');
        PouchDB.plugin(PouchFind);

        /***************
        crudmoymatiereBtn
        *****************/
        /**nav previous page button*/
        document.getElementById('prevcrudmoymatiereBtn_1').addEventListener('click', prevcrudmoymatiere1BtnChange);
        async function prevcrudmoymatiere1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('moyenne').style.display = 'block';
            document.getElementById('moymatiere').style.display = 'none';
            document.getElementById('moygenerale').style.display = 'none';
            document.getElementById('moyannuelle').style.display = 'none';
            titre.innerHTML = 'Calcul des Moyennes'; 
        }

        /***************************
        addmoymatiereBtn
        *****************************/
        document.getElementById('addmoymatiereBtn').addEventListener('click',addmoymatiereBtnChange);
        async function addmoymatiereBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutanneeStr = 'Oui';
            var statutmatiereStr = 'Oui';

            var docsAnnee = [];
            var docsMatiere = [];

            if(!userCodeStr){
                alert('Connectez-vous à votre Compte avant de continuer');
            }else{
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: ['anneestatut', 'inscritcode']
                        }
                    });
                    result = await db.find({
                        selector: {
                            anneestatut: statutanneeStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsAnnee  = result.docs;
                    var resultatAnnee = docsAnnee.length;
                    if(resultatAnnee === 0){
                        alert('Aucun Calendrier Année Scolaire Enregistré');
                    }else{
                        var divClear;
                        var divModifier;
                        divClear = document.getElementById('addcalendriermoymatiere');
                        while(divClear.hasChildNodes()) {
                            divClear.removeChild(divClear.childNodes[0]);
                        }
                        divModifier = document.getElementById('addcalendriermoymatiere');
                        //Create and append select list
                        var selectList = document.createElement("select");
                        selectList.id = "seladdcalendriermoymatiere";
                        divModifier.appendChild(selectList);
                        //Create and append the options
                        var option_null = document.createElement("option");
                        option_null.value = '';
                        option_null.text = 'Sélectionner Calendrier';
                        selectList.appendChild(option_null);
                        //Create and append the options
                        for (var i = 0; i<docsAnnee.length; i++){
                            var option = document.createElement("option");
                            option.value = docsAnnee[i].anneescolaire;
                            option.text = docsAnnee[i].anneescolaire;
                            selectList.appendChild(option);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'none';
                        document.getElementById('programme').style.display = 'none';
                        document.getElementById('notes').style.display = 'none';
                        document.getElementById('moyenne').style.display = 'none';
                        document.getElementById('moymatiere').style.display = 'block';
                        document.getElementById('crudmoymatiere').style.display = 'none';
                        document.getElementById('addmoymatiere').style.display = 'block';
                        document.getElementById('editmoymatiere').style.display = 'none';
                        document.getElementById('deletemoymatiere').style.display = 'none';
                        document.getElementById('searchmoymatiere').style.display = 'none';
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('savecalendriermoymatiere').style.display = 'block';
                        document.getElementById('savematieremoymatiere').style.display = 'none';
                        document.getElementById('saveprogrammemoymatiere').style.display = 'none';
                        document.getElementById('savetotalcumulmoymatiere').style.display = 'none';
                        document.getElementById('savetotalcoefficientmoymatiere').style.display = 'none';
                        document.getElementById('saveobtenumoymatiere').style.display = 'none';
                        document.getElementById('savemoymatiere').style.display = 'none';
                        titre.innerHTML = 'Enregistrer Moyenne Matière';

                        /***************************
                        seladdcalendriermoymatiere
                        *****************************/
                        document.getElementById('seladdcalendriermoymatiere').addEventListener('change', seladdcalendriermoymatiereChange);
                        async function seladdcalendriermoymatiereChange(e){
                            e.preventDefault();

                            var seladdcalendriermoymatiereStr = document.getElementById('seladdcalendriermoymatiere').value;
                            if(!seladdcalendriermoymatiereStr){
                                alert('Sélectionner un calendrier scolaire avant de continuer');
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('savecalendriermoymatiere').style.display = 'block';
                                document.getElementById('savematieremoymatiere').style.display = 'none';
                                document.getElementById('saveprogrammemoymatiere').style.display = 'none';
                                document.getElementById('savetotalcumulmoymatiere').style.display = 'none';
                                document.getElementById('savetotalcoefficientmoymatiere').style.display = 'none';
                                document.getElementById('saveobtenumoymatiere').style.display = 'none';
                                document.getElementById('savemoymatiere').style.display = 'none';
                            }else{
                                document.getElementById('addselectcalendriermoymatiere').value = seladdcalendriermoymatiereStr;
                                try {
                                    let result = await db.createIndex({
                                        index: {
                                            fields: ['matierestatut', 'inscritcode']
                                        }
                                    });
                                    result = await db.find({
                                        selector: {
                                            matierestatut: statutmatiereStr,
                                            inscritcode: userCodeStr
                                        }
                                    });
                                    docsMatiere  = result.docs;
                                    var resultatMatiere = docsMatiere.length;
                                    if(resultatMatiere === 0){
                                        alert("Matières Classe non encore enregistrées");
                                    }else{
                                        var divClear;
                                        var divModifier;
                                        divClear = document.getElementById('addmatieremoymatiere');
                                        while(divClear.hasChildNodes()) {
                                            divClear.removeChild(divClear.childNodes[0]);
                                        }
                                        divModifier = document.getElementById('addmatieremoymatiere');
                                        //Create and append select list
                                        var selectList = document.createElement("select");
                                        selectList.id = "seladdmatieremoymatiere";
                                        divModifier.appendChild(selectList);
                                        //Create and append the options
                                        var option_null = document.createElement("option");
                                        option_null.value = '';
                                        option_null.text = 'Sélectionner Matière';
                                        selectList.appendChild(option_null);
                                        //Create and append the options
                                        for (var i = 0; i<docsMatiere.length; i++){
                                            var option = document.createElement("option");
                                            option.value = docsMatiere[i].matierenom;
                                            option.text = docsMatiere[i].matierenom;
                                            selectList.appendChild(option);
                                        }

                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('savecalendriermoymatiere').style.display = 'block';
                                        document.getElementById('savematieremoymatiere').style.display = 'block';
                                        document.getElementById('saveprogrammemoymatiere').style.display = 'none';
                                        document.getElementById('savetotalcumulmoymatiere').style.display = 'none';
                                        document.getElementById('savetotalcoefficientmoymatiere').style.display = 'none';
                                        document.getElementById('saveobtenumoymatiere').style.display = 'none';
                                        document.getElementById('savemoymatiere').style.display = 'none';

                                        /***************************
                                        seladdmatieremoymatiere
                                        *****************************/
                                        document.getElementById('seladdmatieremoymatiere').addEventListener('change', seladdmatieremoymatiereChange);
                                        async function seladdmatieremoymatiereChange(e){
                                            e.preventDefault();

                                            var userCodeStr = document.getElementById('userCode').innerText;
                                            var statutNotesStr = 'Oui';
                                            var statutmatiereStr = 'Oui';
                                            var docsNotes = [];
                                            var docsCoefficient = [];

                                            var seladdmatieremoymatiereStr = document.getElementById('seladdmatieremoymatiere').value;
                                            if(!seladdmatieremoymatiereStr){
                                                alert('Sélectionner une matière avant de continuer');
                                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                                document.getElementById('savecalendriermoymatiere').style.display = 'block';
                                                document.getElementById('savematieremoymatiere').style.display = 'block';
                                                document.getElementById('saveprogrammemoymatiere').style.display = 'none';
                                                document.getElementById('savetotalcumulmoymatiere').style.display = 'none';
                                                document.getElementById('savetotalcoefficientmoymatiere').style.display = 'none';
                                                document.getElementById('saveobtenumoymatiere').style.display = 'none';
                                                document.getElementById('savemoymatiere').style.display = 'none';
                                            }else{
                                                document.getElementById('addselectmatieremoymatiere').value = seladdmatieremoymatiereStr;
                                                document.getElementById('addmatiereprogrammemoymatiere').innerText = seladdcalendriermoymatiereStr+' '+seladdmatieremoymatiereStr;

                                                try {
                                                    let resultNotes = await db.createIndex({
                                                        index: {
                                                            fields: [
                                                                'anneescolaire',
                                                                'matierenom',
                                                                'notestatut',
                                                                'inscritcode'
                                                            ]
                                                        }
                                                    });
                                                    resultNotes = await db.find({
                                                        selector: {
                                                            anneescolaire: seladdcalendriermoymatiereStr,
                                                            matierenom: seladdmatieremoymatiereStr,
                                                            notestatut: statutNotesStr,
                                                            inscritcode: userCodeStr
                                                        }
                                                    });
                                                    docsNotes = resultNotes.docs;
                                                    var resultatNotes = docsNotes.length;
                                                    if(resultatNotes === 0){
                                                        alert('Aucun Résultat Notes Disponibles');
                                                        document.getElementById('seladdmatieremoymatiere').value = '';
                                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                                        document.getElementById('savecalendriermoymatiere').style.display = 'block';
                                                        document.getElementById('savematieremoymatiere').style.display = 'block';
                                                        document.getElementById('saveprogrammemoymatiere').style.display = 'none';
                                                        document.getElementById('savetotalcumulmoymatiere').style.display = 'none';
                                                        document.getElementById('savetotalcoefficientmoymatiere').style.display = 'none';
                                                        document.getElementById('saveobtenumoymatiere').style.display = 'none';
                                                        document.getElementById('savemoymatiere').style.display = 'none';
                                                    }else{
                                                        //retireve coefficient of this matiere
                                                        var coefficientStr = '';
                                                        let resultCoefficient = await db.createIndex({
                                                            index: {
                                                                fields: [
                                                                    'matierenom',
                                                                    'matierestatut',
                                                                    'inscritcode'
                                                                ]
                                                            }
                                                        });
                                                        resultCoefficient = await db.find({
                                                            selector: {
                                                                matierenom: seladdmatieremoymatiereStr,
                                                                matierestatut: statutmatiereStr,
                                                                inscritcode: userCodeStr
                                                            }
                                                        });
                                                        docsCoefficient = resultCoefficient.docs;
                                                        for(let j=0; j<docsCoefficient.length; j++){
                                                            coefficientStr = docsCoefficient[j].matierecoefficient;
                                                        }
                                                        document.getElementById('addcoefficientmoymatiere').value = coefficientStr;

                                                        //calcul Total Notes Cumulées
                                                        var totalCumule = 0;
                                                        var totalCoefficient = 0;
                                                        var moyenne = 0;
                                                        for(let i=0; i<docsNotes.length; i++){
                                                            totalCumule += parseFloat(docsNotes[i].noteobtenue * docsNotes[i].notecoefficient);
                                                            totalCoefficient += parseFloat(docsNotes[i].notecoefficient);
                                                        }
                                                        //calcul Moyenne matière
                                                        moyenne = (totalCumule / totalCoefficient).toFixed(2);
                                                        document.getElementById('addtotalcumulmoymatiere').innerText = totalCumule;
                                                        document.getElementById('addtotalcoefficientmoymatiere').innerText = totalCoefficient;
                                                        document.getElementById('addobtenuemoymatiere').innerText = moyenne;
                                                        //assign data to inputs
                                                        document.getElementById('addnotecumulmoymatiere').value = totalCumule.toString();
                                                        document.getElementById('addcoefficientcumulemoymatiere').value = totalCoefficient.toString();
                                                        document.getElementById('addmoyennemoymatiere').value = moyenne.toString();

                                                        updateAddMoyMatiereUIWithDocs(docsNotes);
                                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                                        document.getElementById('savecalendriermoymatiere').style.display = 'block';
                                                        document.getElementById('savematieremoymatiere').style.display = 'block';
                                                        document.getElementById('saveprogrammemoymatiere').style.display = 'block';
                                                        document.getElementById('savetotalcumulmoymatiere').style.display = 'block';
                                                        document.getElementById('savetotalcoefficientmoymatiere').style.display = 'block';
                                                        document.getElementById('saveobtenumoymatiere').style.display = 'block';
                                                        document.getElementById('savemoymatiere').style.display = 'block';
                                                    }
                                                } catch (error) {
                                                   alert('Erreur Exécution'); 
                                                }
                                            }
                                        }
                                    }
                                } catch (error) {
                                    alert('Erreur Exécution');
                                }
                            }
                        }
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }
        /**nav previous page button*/
        document.getElementById('prevaddmoymatiereBtn_1').addEventListener('click', prevaddmoymatiere1BtnChange);
        async function prevaddmoymatiere1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'none';
            document.getElementById('moyenne').style.display = 'none';
            document.getElementById('moymatiere').style.display = 'block';
            document.getElementById('crudmoymatiere').style.display = 'block';
            document.getElementById('addmoymatiere').style.display = 'none';
            document.getElementById('editmoymatiere').style.display = 'none';
            document.getElementById('deletemoymatiere').style.display = 'none';
            document.getElementById('searchmoymatiere').style.display = 'none';
            titre.innerHTML = 'Calcul Moyennes Par Matière'; 
        }
        // Adds the document to the HTML First table.
        // If the document already exists in the table, it will be updated.
        function addProgrammeMoyMatiereTableDocToTable(doc, addProgrammeMoyMatiereTableBody){
            // Each row of the table has an id attribute set to the doc._id field.
            var tr = addProgrammeMoyMatiereTableBody.querySelector(`#${doc._id}`);

            

            if( !tr ) {
                tr = document.createElement('tr');
                tr.id = doc._id;
            }
        
            // Remove any existing table cells.
            while(tr.hasChildNodes()) {
                tr.removeChild(tr.childNodes[0]);
            }

            // Create new table cells for text fields.
            var tdEcheance = document.createElement('td');
            var dateEcheance = new Date(doc.noteecheance);
            tdEcheance.innerText = dateEcheance.toLocaleDateString();
            tr.appendChild(tdEcheance);

            // Create new table cells for text fields.
            var tdType = document.createElement('td');
            tdType.innerText = doc.notedevoir;
            tr.appendChild(tdType);

            // Create new table cells for text fields.
            var tdNotes = document.createElement('td');
            tdNotes.innerText = doc.noteobtenue;
            tr.appendChild(tdNotes);

            // Create new table cells for text fields.
            var tdCoefficient = document.createElement('td');
            tdCoefficient.innerText = doc.notecoefficient;
            tr.appendChild(tdCoefficient);

            // Add the row to the top of the table.
            addProgrammeMoyMatiereTableBody.insertBefore(tr, addProgrammeMoyMatiereTableBody.nextSibling);
        }
        /**Clear and update the UI to display the docs.*/
        function updateAddMoyMatiereUIWithDocs(docs) {
            var addProgrammeMoyMatiereTableBody = document.getElementById('addProgrammeMoyMatiereTableBody');
            
            // Remove all child nodes from the table
            while(addProgrammeMoyMatiereTableBody.hasChildNodes()) {
                addProgrammeMoyMatiereTableBody.removeChild(addProgrammeMoyMatiereTableBody.childNodes[0]);
            }
            
            // Add the new documents to the table
            for(var i=0; i<docs.length; i++) {
                var doc = docs[i];
                addProgrammeMoyMatiereTableDocToTable(doc, addProgrammeMoyMatiereTableBody);
            }
        }
        /***************************
        enregistrermoymatiereBtn
        *****************************/
        document.getElementById('enregistrermoymatiereBtn').addEventListener('click', enregistrermoymatiereBtnChange);
        async function enregistrermoymatiereBtnChange(e){
            e.preventDefault();

            var usercodeStr = document.getElementById('userCode').innerText;
            var addselectcalendriermoymatiereStr = document.getElementById('addselectcalendriermoymatiere').value;
            var addselectmatieremoymatiereStr = document.getElementById('addselectmatieremoymatiere').value;
            var addcoefficientmoymatiereStr = document.getElementById('addcoefficientmoymatiere').value;
            var addnotecumulmoymatiereStr = document.getElementById('addnotecumulmoymatiere').value;
            var addcoefficientcumulemoymatiereStr = document.getElementById('addcoefficientcumulemoymatiere').value;
            var addmoyennemoymatiereStr = document.getElementById('addmoyennemoymatiere').value;
            var addeffectuemoymatiereStr = 'Oui';
            var datejourStr = getCurrentDate();
            var addstatutmoymatiereStr = 'Oui';
            var docsMoyMatiere = [];

            if(!usercodeStr){
                alert("Identifiez-vous à votre Compte avant de continuer");
            }else if(!addselectcalendriermoymatiereStr){
                alert("Sélectionner Calendrier Scolaire");
            }else if(!addselectmatieremoymatiereStr){
                alert("Sélectionner Matière");
            }else if(!addnotecumulmoymatiereStr){
                alert("Reprendre le Calcul Moyenne Matière");
            }else if(!addcoefficientcumulemoymatiereStr){
                alert("Reprendre le Calcul Moyenne Matière");
            }else if(!addmoyennemoymatiereStr){
                alert("Reprendre le Calcul Moyenne Matière");
            }else{
                //check if this moyenne matiere already exists
                try {
                    let resultMoyMatiere = await db.createIndex({
                        index: {
                            fields: [
                                'inscritcode', 
                                'anneescolaire', 
                                'matierenom',
                                'moymatiereeffectue',
                                'moymatierestatut'
                            ]
                        }
                    });
                    resultMoyMatiere = await db.find({
                        selector: {
                            inscritcode: usercodeStr,
                            anneescolaire: addselectcalendriermoymatiereStr,
                            matierenom: addselectmatieremoymatiereStr,
                            moymatiereeffectue: addeffectuemoymatiereStr,
                            moymatierestatut: addstatutmoymatiereStr
                        }
                    });
                    docsMoyMatiere = resultMoyMatiere.docs;
                    var resultatMoyMatiere = docsMoyMatiere.length;
                    if(resultatMoyMatiere === 0){
                        //Insert
                        var now = Date.now();
                        var id = 'moymatiere_' + now;
                        // Add the document to the database.
                        var response = await db.put({
                            _id: id,
                            inscritcode: usercodeStr,
                            anneescolaire: addselectcalendriermoymatiereStr,
                            matierenom: addselectmatieremoymatiereStr,
                            matierecoefficient: addcoefficientmoymatiereStr,
                            notecumule: addnotecumulmoymatiereStr.toString(),
                            coefficientcumule: addcoefficientcumulemoymatiereStr.toString(),
                            moyenneobtenue: addmoyennemoymatiereStr.toString(),
                            moymatiereeffectue: addeffectuemoymatiereStr,
                            moymatieredate: datejourStr,
                            moymatierestatut: addstatutmoymatiereStr,
                        });
                        // check if document inserted
                        var doc = await db.get(response.id);
                        if(doc !== ''){
                            alert("Enregistrement Moyenne "+addselectmatieremoymatiereStr+" effectué avec Succès");
                            document.getElementById('addselectcalendriermoymatiere').value = '';
                            document.getElementById('addselectmatieremoymatiere').value = '';
                            document.getElementById('addnotecumulmoymatiere').value = '';
                            document.getElementById('addcoefficientcumulemoymatiere').value = '';
                            document.getElementById('addmoyennemoymatiere').value = '';

                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');

                            document.getElementById('demarrage').style.display = 'none';
                            document.getElementById('tableaubord').style.display = 'none';
                            document.getElementById('dashboard').style.display = 'none';
                            document.getElementById('configurations').style.display = 'none';
                            document.getElementById('configurer').style.display = 'none';
                            document.getElementById('emploidutemps').style.display = 'none';
                            document.getElementById('exercice').style.display = 'none';
                            document.getElementById('pratique').style.display = 'none';
                            document.getElementById('tache').style.display = 'none';
                            document.getElementById('revision').style.display = 'none';
                            document.getElementById('recherche').style.display = 'none';
                            document.getElementById('programme').style.display = 'none';
                            document.getElementById('notes').style.display = 'none';
                            document.getElementById('moyenne').style.display = 'none';
                            document.getElementById('moymatiere').style.display = 'block';
                            document.getElementById('crudmoymatiere').style.display = 'block';
                            document.getElementById('addmoymatiere').style.display = 'none';
                            document.getElementById('editmoymatiere').style.display = 'none';
                            document.getElementById('deletemoymatiere').style.display = 'none';
                            document.getElementById('searchmoymatiere').style.display = 'none';
                            titre.innerHTML = 'Calcul Moyennes Par Matière'; 
                        }else{
                            alert("Echec Enregistrement Moyenne "+addselectmatieremoymatiereStr+". Reprendre");
                            document.getElementById('addselectcalendriermoymatiere').value = '';
                            document.getElementById('addselectmatieremoymatiere').value = '';
                            document.getElementById('addnotecumulmoymatiere').value = '';
                            document.getElementById('addcoefficientcumulemoymatiere').value = '';
                            document.getElementById('addmoyennemoymatiere').value = '';

                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');

                            document.getElementById('demarrage').style.display = 'none';
                            document.getElementById('tableaubord').style.display = 'none';
                            document.getElementById('dashboard').style.display = 'none';
                            document.getElementById('configurations').style.display = 'none';
                            document.getElementById('configurer').style.display = 'none';
                            document.getElementById('emploidutemps').style.display = 'none';
                            document.getElementById('exercice').style.display = 'none';
                            document.getElementById('pratique').style.display = 'none';
                            document.getElementById('tache').style.display = 'none';
                            document.getElementById('revision').style.display = 'none';
                            document.getElementById('recherche').style.display = 'none';
                            document.getElementById('programme').style.display = 'none';
                            document.getElementById('notes').style.display = 'none';
                            document.getElementById('moyenne').style.display = 'none';
                            document.getElementById('moymatiere').style.display = 'block';
                            document.getElementById('crudmoymatiere').style.display = 'block';
                            document.getElementById('addmoymatiere').style.display = 'none';
                            document.getElementById('editmoymatiere').style.display = 'none';
                            document.getElementById('deletemoymatiere').style.display = 'none';
                            document.getElementById('searchmoymatiere').style.display = 'none';
                            titre.innerHTML = 'Calcul Moyennes Par Matière'; 
                        }
                    }else{
                        alert("Moyenne "+addselectmatieremoymatiereStr+" déjà Enregistrée. Modification Possible");
                        document.getElementById('addselectcalendriermoymatiere').value = '';
                        document.getElementById('addselectmatieremoymatiere').value = '';
                        document.getElementById('addnotecumulmoymatiere').value = '';
                        document.getElementById('addcoefficientcumulemoymatiere').value = '';
                        document.getElementById('addmoyennemoymatiere').value = '';

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'none';
                        document.getElementById('programme').style.display = 'none';
                        document.getElementById('notes').style.display = 'none';
                        document.getElementById('moyenne').style.display = 'none';
                        document.getElementById('moymatiere').style.display = 'block';
                        document.getElementById('crudmoymatiere').style.display = 'block';
                        document.getElementById('addmoymatiere').style.display = 'none';
                        document.getElementById('editmoymatiere').style.display = 'none';
                        document.getElementById('deletemoymatiere').style.display = 'none';
                        document.getElementById('searchmoymatiere').style.display = 'none';
                        titre.innerHTML = 'Calcul Moyennes Par Matière';  
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }

        /***************************
        editmoymatiereBtn
        *****************************/
        document.getElementById('editmoymatiereBtn').addEventListener('click',editmoymatiereBtnChange);
        async function editmoymatiereBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutanneeStr = 'Oui';
            //var statutmatiereStr = 'Oui';

            var docsAnnee = [];
            //var docsMatiere = [];

            if(!userCodeStr){
                alert('Connectez-vous à votre Compte avant de continuer');
            }else{
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: ['anneestatut', 'inscritcode']
                        }
                    });
                    result = await db.find({
                        selector: {
                            anneestatut: statutanneeStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsAnnee  = result.docs;
                    var resultatAnnee = docsAnnee.length;
                    if(resultatAnnee === 0){
                        alert('Aucun Calendrier Année Scolaire Enregistré');
                    }else{
                        var divClear;
                        var divModifier;
                        divClear = document.getElementById('editcalendriermoymatiere');
                        while(divClear.hasChildNodes()) {
                            divClear.removeChild(divClear.childNodes[0]);
                        }
                        divModifier = document.getElementById('editcalendriermoymatiere');
                        //Create and append select list
                        var selectList = document.createElement("select");
                        selectList.id = "seleditcalendriermoymatiere";
                        divModifier.appendChild(selectList);
                        //Create and append the options
                        var option_null = document.createElement("option");
                        option_null.value = '';
                        option_null.text = 'Sélectionner Calendrier';
                        selectList.appendChild(option_null);
                        //Create and append the options
                        for (var i = 0; i<docsAnnee.length; i++){
                            var option = document.createElement("option");
                            option.value = docsAnnee[i].anneescolaire;
                            option.text = docsAnnee[i].anneescolaire;
                            selectList.appendChild(option);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'none';
                        document.getElementById('programme').style.display = 'none';
                        document.getElementById('notes').style.display = 'none';
                        document.getElementById('moyenne').style.display = 'none';
                        document.getElementById('moymatiere').style.display = 'block';
                        document.getElementById('crudmoymatiere').style.display = 'none';
                        document.getElementById('addmoymatiere').style.display = 'none';
                        document.getElementById('editmoymatiere').style.display = 'block';
                        document.getElementById('deletemoymatiere').style.display = 'none';
                        document.getElementById('searchmoymatiere').style.display = 'none';
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('changecalendriermoymatiere').style.display = 'block';
                        document.getElementById('changematieremoymatiere').style.display = 'none';
                        document.getElementById('changeprogrammemoymatiere').style.display = 'none';
                        document.getElementById('changetotalcumulmoymatiere').style.display = 'none';
                        document.getElementById('changetotalcoefficientmoymatiere').style.display = 'none';
                        document.getElementById('changeobtenumoymatiere').style.display = 'none';
                        document.getElementById('changemoymatiere').style.display = 'none';
                        titre.innerHTML = 'Modifier Moyenne Matière';

                        /***************************
                        seleditcalendriermoymatiere
                        *****************************/
                        document.getElementById('seleditcalendriermoymatiere').addEventListener('change', seleditcalendriermoymatiereChange);
                        async function seleditcalendriermoymatiereChange(e){
                            e.preventDefault();

                            var seleditcalendriermoymatiereStr = document.getElementById('seleditcalendriermoymatiere').value;
                            if(!seleditcalendriermoymatiereStr){
                                alert('Sélectionner un calendrier scolaire avant de continuer');
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('changecalendriermoymatiere').style.display = 'block';
                                document.getElementById('changematieremoymatiere').style.display = 'none';
                                document.getElementById('changeprogrammemoymatiere').style.display = 'none';
                                document.getElementById('changetotalcumulmoymatiere').style.display = 'none';
                                document.getElementById('changetotalcoefficientmoymatiere').style.display = 'none';
                                document.getElementById('changeobtenumoymatiere').style.display = 'none';
                                document.getElementById('changemoymatiere').style.display = 'none';
                            }else{
                                document.getElementById('editselectcalendriermoymatiere').value = seleditcalendriermoymatiereStr;
                                var statutmoymatiereStr = 'Oui';
                                var effectuemoymatiereStr = 'Oui';
                                var docsMoyMatiere = [];
                                try {
                                    let result = await db.createIndex({
                                        index: {
                                            fields: [
                                                'inscritcode',
                                                'moymatierestatut',
                                                'moymatiereeffectue', 
                                                'anneescolaire'
                                            ]
                                        }
                                    });
                                    result = await db.find({
                                        selector: {
                                            inscritcode: userCodeStr,
                                            moymatierestatut: statutmoymatiereStr,
                                            moymatiereeffectue: effectuemoymatiereStr,
                                            anneescolaire: seleditcalendriermoymatiereStr
                                        }
                                    });
                                    docsMoyMatiere  = result.docs;
                                    var resultatMoyMatiere = docsMoyMatiere.length;
                                    if(resultatMoyMatiere === 0){
                                        alert("Moyennes par Matière "+seleditcalendriermoymatiereStr+" non encore enregistrées");
                                    }else{
                                        var divClear;
                                        var divModifier;
                                        divClear = document.getElementById('editmatieremoymatiere');
                                        while(divClear.hasChildNodes()) {
                                            divClear.removeChild(divClear.childNodes[0]);
                                        }
                                        divModifier = document.getElementById('editmatieremoymatiere');
                                        //Create and append select list
                                        var selectList = document.createElement("select");
                                        selectList.id = "seleditmatieremoymatiere";
                                        divModifier.appendChild(selectList);
                                        //Create and append the options
                                        var option_null = document.createElement("option");
                                        option_null.value = '';
                                        option_null.text = 'Sélectionner Matière';
                                        selectList.appendChild(option_null);
                                        //Create and append the options
                                        for (var i = 0; i<docsMoyMatiere.length; i++){
                                            var option = document.createElement("option");
                                            option.value = docsMoyMatiere[i].matierenom;
                                            option.text = docsMoyMatiere[i].matierenom;
                                            selectList.appendChild(option);
                                        }

                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('changecalendriermoymatiere').style.display = 'block';
                                        document.getElementById('changematieremoymatiere').style.display = 'block';
                                        document.getElementById('changeprogrammemoymatiere').style.display = 'none';
                                        document.getElementById('changetotalcumulmoymatiere').style.display = 'none';
                                        document.getElementById('changetotalcoefficientmoymatiere').style.display = 'none';
                                        document.getElementById('changeobtenumoymatiere').style.display = 'none';
                                        document.getElementById('changemoymatiere').style.display = 'none';

                                        /***************************
                                        seleditmatieremoymatiere
                                        *****************************/
                                        document.getElementById('seleditmatieremoymatiere').addEventListener('change', seleditmatieremoymatiereChange);
                                        async function seleditmatieremoymatiereChange(e){
                                            e.preventDefault();

                                            var userCodeStr = document.getElementById('userCode').innerText;
                                            var statutNotesStr = 'Oui';
                                            var docsNotes = [];

                                            var seleditmatieremoymatiereStr = document.getElementById('seleditmatieremoymatiere').value;
                                            if(!seleditmatieremoymatiereStr){
                                                alert('Sélectionner une matière avant de continuer');
                                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                                document.getElementById('changecalendriermoymatiere').style.display = 'block';
                                                document.getElementById('changematieremoymatiere').style.display = 'block';
                                                document.getElementById('changeprogrammemoymatiere').style.display = 'none';
                                                document.getElementById('changetotalcumulmoymatiere').style.display = 'none';
                                                document.getElementById('changetotalcoefficientmoymatiere').style.display = 'none';
                                                document.getElementById('changeobtenumoymatiere').style.display = 'none';
                                                document.getElementById('changemoymatiere').style.display = 'none';
                                            }else{
                                                document.getElementById('editselectmatieremoymatiere').value = seleditmatieremoymatiereStr;
                                                document.getElementById('editmatiereprogrammemoymatiere').innerText = seleditcalendriermoymatiereStr+' '+seleditmatieremoymatiereStr;

                                                try {
                                                    let resultNotes = await db.createIndex({
                                                        index: {
                                                            fields: [
                                                                'anneescolaire',
                                                                'matierenom',
                                                                'notestatut',
                                                                'inscritcode'
                                                            ]
                                                        }
                                                    });
                                                    resultNotes = await db.find({
                                                        selector: {
                                                            anneescolaire: seleditcalendriermoymatiereStr,
                                                            matierenom: seleditmatieremoymatiereStr,
                                                            notestatut: statutNotesStr,
                                                            inscritcode: userCodeStr
                                                        }
                                                    });
                                                    docsNotes = resultNotes.docs;
                                                    var resultatNotes = docsNotes.length;
                                                    if(resultatNotes === 0){
                                                        alert('Aucun Résultat Notes Disponibles');
                                                        document.getElementById('seleditmatieremoymatiere').value = '';
                                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                                        document.getElementById('changecalendriermoymatiere').style.display = 'block';
                                                        document.getElementById('changematieremoymatiere').style.display = 'block';
                                                        document.getElementById('changeprogrammemoymatiere').style.display = 'none';
                                                        document.getElementById('changetotalcumulmoymatiere').style.display = 'none';
                                                        document.getElementById('changetotalcoefficientmoymatiere').style.display = 'none';
                                                        document.getElementById('changeobtenumoymatiere').style.display = 'none';
                                                        document.getElementById('changemoymatiere').style.display = 'none';
                                                    }else{
                                                        var statutmoyenneStr = 'Oui';
                                                        var effectuemoyenneStr = 'Oui';
                                                        var docsMoyenne = [];
                                                        var idMoyenne,anneeScolaireStr,matiereNomStr,matiereCoefficientStr,moyMatiereEffectueStr,
                                                        moyMatiereDateStr,moyMatiereStatutStr = '';
                                                        let resultMoyenne = await db.createIndex({
                                                            index: {
                                                                fields: [
                                                                    'inscritcode',
                                                                    'matierenom',
                                                                    'moymatierestatut',
                                                                    'moymatiereeffectue', 
                                                                    'anneescolaire'
                                                                ]
                                                            }
                                                        });
                                                        resultMoyenne = await db.find({
                                                            selector: {
                                                                inscritcode: userCodeStr,
                                                                matierenom: seleditmatieremoymatiereStr,
                                                                moymatierestatut: statutmoyenneStr,
                                                                moymatiereeffectue: effectuemoyenneStr,
                                                                anneescolaire: seleditcalendriermoymatiereStr
                                                            }
                                                        });
                                                        docsMoyenne = resultMoyenne.docs;
                                                        for(let k=0; k<docsMoyenne.length; k++){
                                                            idMoyenne = docsMoyenne[k]._id;
                                                            anneeScolaireStr = docsMoyMatiere[k].anneescolaire;
                                                            matiereNomStr = docsMoyMatiere[k].matierenom;
                                                            matiereCoefficientStr = docsMoyMatiere[k].matierecoefficient;
                                                            moyMatiereEffectueStr = docsMoyMatiere[k].moymatiereeffectue;
                                                            moyMatiereDateStr = docsMoyMatiere[k].moymatieredate;
                                                            moyMatiereStatutStr = docsMoyMatiere[k].moymatierestatut;
                                                        }
                                                        document.getElementById('editidmoymatiere').value = idMoyenne;
                                                        document.getElementById('editselectcalendriermoymatiere').value = anneeScolaireStr;
                                                        document.getElementById('editselectmatieremoymatiere').value = matiereNomStr;
                                                        document.getElementById('editcoefficientmoymatiere').value = matiereCoefficientStr;
                                                        document.getElementById('editmoymatiereeffectue').value = moyMatiereEffectueStr;
                                                        document.getElementById('editmoymatieredate').value = moyMatiereDateStr;
                                                        document.getElementById('editmoymatierestatut').value = moyMatiereStatutStr;
                                                        //calcul Total Notes Cumulées
                                                        var totalCumule = 0;
                                                        var totalCoefficient = 0;
                                                        var moyenne = 0;
                                                        for(let i=0; i<docsNotes.length; i++){
                                                            totalCumule += parseFloat(docsNotes[i].noteobtenue * docsNotes[i].notecoefficient);
                                                            totalCoefficient += parseFloat(docsNotes[i].notecoefficient);
                                                        }
                                                        //calcul Moyenne matière
                                                        moyenne = (totalCumule / totalCoefficient).toFixed(2);
                                                        document.getElementById('edittotalcumulmoymatiere').innerText = totalCumule;
                                                        document.getElementById('edittotalcoefficientmoymatiere').innerText = totalCoefficient;
                                                        document.getElementById('editobtenuemoymatiere').innerText = moyenne;
                                                        //assign data to inputs
                                                        document.getElementById('editnotecumulmoymatiere').value = totalCumule.toString();
                                                        document.getElementById('editcoefficientcumulemoymatiere').value = totalCoefficient.toString();
                                                        document.getElementById('editmoyennemoymatiere').value = moyenne.toString();

                                                        updateEditMoyMatiereUIWithDocs(docsNotes);
                                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                                        document.getElementById('changecalendriermoymatiere').style.display = 'block';
                                                        document.getElementById('changematieremoymatiere').style.display = 'block';
                                                        document.getElementById('changeprogrammemoymatiere').style.display = 'block';
                                                        document.getElementById('changetotalcumulmoymatiere').style.display = 'block';
                                                        document.getElementById('changetotalcoefficientmoymatiere').style.display = 'block';
                                                        document.getElementById('changeobtenumoymatiere').style.display = 'block';
                                                        document.getElementById('changemoymatiere').style.display = 'block';
                                                    }
                                                } catch (error) {
                                                    alert('Erreur Exécution');
                                                }
                                            }
                                        }
                                    }
                                } catch (error) {
                                    alert('Erreur Exécution');
                                }
                            }
                        }
                    }
                } catch (error) {
                   alert('Erreur Exécution'); 
                }
            }
        }
        /**nav previous page button*/
        document.getElementById('preveditmoymatiereBtn_1').addEventListener('click', preveditmoymatiere1BtnChange);
        async function preveditmoymatiere1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'none';
            document.getElementById('moyenne').style.display = 'none';
            document.getElementById('moymatiere').style.display = 'block';
            document.getElementById('crudmoymatiere').style.display = 'block';
            document.getElementById('addmoymatiere').style.display = 'none';
            document.getElementById('editmoymatiere').style.display = 'none';
            document.getElementById('deletemoymatiere').style.display = 'none';
            document.getElementById('searchmoymatiere').style.display = 'none';
            titre.innerHTML = 'Calcul Moyennes Par Matière'; 
        }
        // Adds the document to the HTML First table.
        // If the document already exists in the table, it will be updated.
        function editProgrammeMoyMatiereTableDocToTable(doc, editProgrammeMoyMatiereTableBody){
            // Each row of the table has an id attribute set to the doc._id field.
            var tr = editProgrammeMoyMatiereTableBody.querySelector(`#${doc._id}`);

            

            if( !tr ) {
                tr = document.createElement('tr');
                tr.id = doc._id;
            }
        
            // Remove any existing table cells.
            while(tr.hasChildNodes()) {
                tr.removeChild(tr.childNodes[0]);
            }

            // Create new table cells for text fields.
            var tdEcheance = document.createElement('td');
            var dateEcheance = new Date(doc.noteecheance);
            tdEcheance.innerText = dateEcheance.toLocaleDateString();
            tr.appendChild(tdEcheance);

            // Create new table cells for text fields.
            var tdType = document.createElement('td');
            tdType.innerText = doc.notedevoir;
            tr.appendChild(tdType);

            // Create new table cells for text fields.
            var tdNotes = document.createElement('td');
            tdNotes.innerText = doc.noteobtenue;
            tr.appendChild(tdNotes);

            // Create new table cells for text fields.
            var tdCoefficient = document.createElement('td');
            tdCoefficient.innerText = doc.notecoefficient;
            tr.appendChild(tdCoefficient);

            // Add the row to the top of the table.
            editProgrammeMoyMatiereTableBody.insertBefore(tr, editProgrammeMoyMatiereTableBody.nextSibling);
        }
        /**Clear and update the UI to display the docs.*/
        function updateEditMoyMatiereUIWithDocs(docs) {
            var editProgrammeMoyMatiereTableBody = document.getElementById('editProgrammeMoyMatiereTableBody');
            
            // Remove all child nodes from the table
            while(editProgrammeMoyMatiereTableBody.hasChildNodes()) {
                editProgrammeMoyMatiereTableBody.removeChild(editProgrammeMoyMatiereTableBody.childNodes[0]);
            }
            
            // Add the new documents to the table
            for(var i=0; i<docs.length; i++) {
                var doc = docs[i];
                editProgrammeMoyMatiereTableDocToTable(doc, editProgrammeMoyMatiereTableBody);
            }
        }
        /***************************
        modifiermoymatiereBtn
        *****************************/
        document.getElementById('modifiermoymatiereBtn').addEventListener('click', modifiermoymatiereBtnChange);
        async function modifiermoymatiereBtnChange(e){
            e.preventDefault();

            var usercodeStr = document.getElementById('userCode').innerText;
            var editidmoymatiereStr = document.getElementById('editidmoymatiere').value;
            var editselectcalendriermoymatiereStr = document.getElementById('editselectcalendriermoymatiere').value;
            var editselectmatieremoymatiereStr = document.getElementById('editselectmatieremoymatiere').value;
            var editcoefficientmoymatiereStr = document.getElementById('editcoefficientmoymatiere').value;
            var editnotecumulmoymatiereStr = document.getElementById('editnotecumulmoymatiere').value;
            var editcoefficientcumulemoymatiereStr = document.getElementById('editcoefficientcumulemoymatiere').value;
            var editmoyennemoymatiereStr = document.getElementById('editmoyennemoymatiere').value;
            var editeffectuemoymatiereStr = document.getElementById('editmoymatiereeffectue').value;
            var datejourStr = document.getElementById('editmoymatieredate').value;
            var editstatutmoymatiereStr = document.getElementById('editmoymatierestatut').value;
            var docsMoyMatiere = [];

            if(!usercodeStr){
                alert("Identifiez-vous avant de continuer");
            }else{
                try {
                    //check if this moyenne matiere already exists
                    let resultMoyMatiere = await db.createIndex({
                        index: {
                            fields: [
                                '_id',
                                'inscritcode',
                                'moymatierestatut'
                            ]
                        }
                    });
                    resultMoyMatiere = await db.find({
                        selector: {
                            _id: editidmoymatiereStr,
                            inscritcode: usercodeStr,
                            moymatierestatut: editstatutmoymatiereStr
                        }
                    });
                    docsMoyMatiere = resultMoyMatiere.docs;
                    var resultatMoyMatiere = docsMoyMatiere.length;
                    if(resultatMoyMatiere === 0){
                        alert("Moyenne "+editselectcalendriermoymatiereStr+" "+editselectmatieremoymatiereStr+" non encore enregistrée");
                    }else{
                        var moyenneObtenue = 0;
                        for(let i=0; i<docsMoyMatiere.length; i++){
                            moyenneObtenue = parseFloat(docsMoyMatiere[i].moyenneobtenue);
                        }
                        if(moyenneObtenue === parseFloat(editmoyennemoymatiereStr)){
                            alert("Moyenne "+editselectcalendriermoymatiereStr+" "+editselectmatieremoymatiereStr+" Modifiée avec Succès. Mettre à jour Moyenne Trimestrielle/Semestrielle si déjà Enregistrée.");
                            document.getElementById('editidmoymatiere').value = '';
                            document.getElementById('editselectcalendriermoymatiere').value = '';
                            document.getElementById('editselectmatieremoymatiere').value = '';
                            document.getElementById('editcoefficientmoymatiere').value = '';
                            document.getElementById('editnotecumulmoymatiere').value = '';
                            document.getElementById('editcoefficientcumulemoymatiere').value = '';
                            document.getElementById('editmoyennemoymatiere').value = '';
                            document.getElementById('editmoymatiereeffectue').value = '';
                            document.getElementById('editmoymatieredate').value = '';
                            document.getElementById('editmoymatierestatut').value = '';

                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');

                            document.getElementById('demarrage').style.display = 'none';
                            document.getElementById('tableaubord').style.display = 'none';
                            document.getElementById('dashboard').style.display = 'none';
                            document.getElementById('configurations').style.display = 'none';
                            document.getElementById('configurer').style.display = 'none';
                            document.getElementById('emploidutemps').style.display = 'none';
                            document.getElementById('exercice').style.display = 'none';
                            document.getElementById('pratique').style.display = 'none';
                            document.getElementById('tache').style.display = 'none';
                            document.getElementById('revision').style.display = 'none';
                            document.getElementById('recherche').style.display = 'none';
                            document.getElementById('programme').style.display = 'none';
                            document.getElementById('notes').style.display = 'none';
                            document.getElementById('moyenne').style.display = 'none';
                            document.getElementById('moymatiere').style.display = 'block';
                            document.getElementById('crudmoymatiere').style.display = 'block';
                            document.getElementById('addmoymatiere').style.display = 'none';
                            document.getElementById('editmoymatiere').style.display = 'none';
                            document.getElementById('deletemoymatiere').style.display = 'none';
                            document.getElementById('searchmoymatiere').style.display = 'none';
                            titre.innerHTML = 'Calcul Moyennes Par Matière'; 
                        }else{
                            // Retrieve the document to get the latest revision
                            var idModifier = editidmoymatiereStr;
                            var docModifier = await db.get(idModifier);
                            // Make changes
                            docModifier.inscritcode = usercodeStr;
                            docModifier.anneescolaire = editselectcalendriermoymatiereStr;
                            docModifier.matierenom = editselectmatieremoymatiereStr;
                            docModifier.matierecoefficient = editcoefficientmoymatiereStr;
                            docModifier.notecumule = editnotecumulmoymatiereStr;
                            docModifier.coefficientcumule = editcoefficientcumulemoymatiereStr;
                            docModifier.moyenneobtenue = editmoyennemoymatiereStr;
                            docModifier.moymatiereeffectue = editeffectuemoymatiereStr;
                            docModifier.moymatieredate = datejourStr;
                            docModifier.moymatierestatut = editstatutmoymatiereStr;

                            // Put the document, saving it as a new revision
                            var responseModifier = await db.put(docModifier);
                            // Get the document we change and update the UI.
                            var docEdit = await db.get(responseModifier.id);
                            if(docEdit !== ''){
                                alert("Moyenne "+editselectcalendriermoymatiereStr+" "+editselectmatieremoymatiereStr+" Modifiée avec Succès. Mettre à jour Moyenne Gènérale Trimestrielle/Semestrielle si déjà Enregistrée.");
                                document.getElementById('editidmoymatiere').value = '';
                                document.getElementById('editselectcalendriermoymatiere').value = '';
                                document.getElementById('editselectmatieremoymatiere').value = '';
                                document.getElementById('editcoefficientmoymatiere').value = '';
                                document.getElementById('editnotecumulmoymatiere').value = '';
                                document.getElementById('editcoefficientcumulemoymatiere').value = '';
                                document.getElementById('editmoyennemoymatiere').value = '';
                                document.getElementById('editmoymatiereeffectue').value = '';
                                document.getElementById('editmoymatieredate').value = '';
                                document.getElementById('editmoymatierestatut').value = '';

                                document.getElementById('titre').style.display = 'block';
                                var titre = document.getElementById('titre');

                                document.getElementById('demarrage').style.display = 'none';
                                document.getElementById('tableaubord').style.display = 'none';
                                document.getElementById('dashboard').style.display = 'none';
                                document.getElementById('configurations').style.display = 'none';
                                document.getElementById('configurer').style.display = 'none';
                                document.getElementById('emploidutemps').style.display = 'none';
                                document.getElementById('exercice').style.display = 'none';
                                document.getElementById('pratique').style.display = 'none';
                                document.getElementById('tache').style.display = 'none';
                                document.getElementById('revision').style.display = 'none';
                                document.getElementById('recherche').style.display = 'none';
                                document.getElementById('programme').style.display = 'none';
                                document.getElementById('notes').style.display = 'none';
                                document.getElementById('moyenne').style.display = 'none';
                                document.getElementById('moymatiere').style.display = 'block';
                                document.getElementById('crudmoymatiere').style.display = 'block';
                                document.getElementById('addmoymatiere').style.display = 'none';
                                document.getElementById('editmoymatiere').style.display = 'none';
                                document.getElementById('deletemoymatiere').style.display = 'none';
                                document.getElementById('searchmoymatiere').style.display = 'none';
                                titre.innerHTML = 'Calcul Moyennes Par Matière'; 
                            }else{
                                alert("Echec Modification Moyenne "+editselectcalendriermoymatiereStr+" "+editselectmatieremoymatiereStr+". Reprendre");
                                document.getElementById('editidmoymatiere').value = '';
                                document.getElementById('editselectcalendriermoymatiere').value = '';
                                document.getElementById('editselectmatieremoymatiere').value = '';
                                document.getElementById('editcoefficientmoymatiere').value = '';
                                document.getElementById('editnotecumulmoymatiere').value = '';
                                document.getElementById('editcoefficientcumulemoymatiere').value = '';
                                document.getElementById('editmoyennemoymatiere').value = '';
                                document.getElementById('editmoymatiereeffectue').value = '';
                                document.getElementById('editmoymatieredate').value = '';
                                document.getElementById('editmoymatierestatut').value = '';

                                document.getElementById('titre').style.display = 'block';
                                var titre = document.getElementById('titre');

                                document.getElementById('demarrage').style.display = 'none';
                                document.getElementById('tableaubord').style.display = 'none';
                                document.getElementById('dashboard').style.display = 'none';
                                document.getElementById('configurations').style.display = 'none';
                                document.getElementById('configurer').style.display = 'none';
                                document.getElementById('emploidutemps').style.display = 'none';
                                document.getElementById('exercice').style.display = 'none';
                                document.getElementById('pratique').style.display = 'none';
                                document.getElementById('tache').style.display = 'none';
                                document.getElementById('revision').style.display = 'none';
                                document.getElementById('recherche').style.display = 'none';
                                document.getElementById('programme').style.display = 'none';
                                document.getElementById('notes').style.display = 'none';
                                document.getElementById('moyenne').style.display = 'none';
                                document.getElementById('moymatiere').style.display = 'block';
                                document.getElementById('crudmoymatiere').style.display = 'block';
                                document.getElementById('addmoymatiere').style.display = 'none';
                                document.getElementById('editmoymatiere').style.display = 'none';
                                document.getElementById('deletemoymatiere').style.display = 'none';
                                document.getElementById('searchmoymatiere').style.display = 'none';
                                titre.innerHTML = 'Calcul Moyennes Par Matière'; 
                            }
                        }
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }

        /***************************
        deletemoymatiereBtn
        *****************************/
        document.getElementById('deletemoymatiereBtn').addEventListener('click',deletemoymatiereBtnChange);
        async function deletemoymatiereBtnChange(e){
            e.preventDefault();

            alert("Impossible Supprimer une Moyenne par Matière une fois Enregistrée");
        }

        /***************************
        searchmoymatiereBtn
        *****************************/
        document.getElementById('searchmoymatiereBtn').addEventListener('click',searchmoymatiereBtnChange);
        async function searchmoymatiereBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutanneeStr = 'Oui';

            var docsAnnee = [];
            var docsMatiere = [];

            if(!userCodeStr){
                alert('Connectez-vous à votre Compte avant de continuer');
            }else{
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: ['anneestatut', 'inscritcode']
                        }
                    });
                    result = await db.find({
                        selector: {
                            anneestatut: statutanneeStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsAnnee  = result.docs;
                    var resultatAnnee = docsAnnee.length;
                    if(resultatAnnee === 0){
                        alert('Aucun Calendrier Année Scolaire Enregistré');
                    }else{
                        var divClear;
                        var divModifier;
                        divClear = document.getElementById('searchcalendriermoymatiere');
                        while(divClear.hasChildNodes()) {
                            divClear.removeChild(divClear.childNodes[0]);
                        }
                        divModifier = document.getElementById('searchcalendriermoymatiere');
                        //Create and append select list
                        var selectList = document.createElement("select");
                        selectList.id = "seldsearchcalendriermoymatiere";
                        divModifier.appendChild(selectList);
                        //Create and append the options
                        var option_null = document.createElement("option");
                        option_null.value = '';
                        option_null.text = 'Sélectionner Calendrier';
                        selectList.appendChild(option_null);
                        //Create and append the options
                        for (var i = 0; i<docsAnnee.length; i++){
                            var option = document.createElement("option");
                            option.value = docsAnnee[i].anneescolaire;
                            option.text = docsAnnee[i].anneescolaire;
                            selectList.appendChild(option);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'none';
                        document.getElementById('programme').style.display = 'none';
                        document.getElementById('notes').style.display = 'none';
                        document.getElementById('moyenne').style.display = 'none';
                        document.getElementById('moymatiere').style.display = 'block';
                        document.getElementById('crudmoymatiere').style.display = 'none';
                        document.getElementById('addmoymatiere').style.display = 'none';
                        document.getElementById('editmoymatiere').style.display = 'none';
                        document.getElementById('deletemoymatiere').style.display = 'none';
                        document.getElementById('searchmoymatiere').style.display = 'block';
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('viewcalendriermoymatiere').style.display = 'block';
                        document.getElementById('viewprogrammemoymatiere').style.display = 'none';
                        document.getElementById('viewconsultermoymatiere').style.display = 'none';
                        titre.innerHTML = 'Consulter Moyenne Matière';

                        /***************************
                        seldsearchcalendriermoymatiere
                        *****************************/
                        document.getElementById('seldsearchcalendriermoymatiere').addEventListener('change', seldsearchcalendriermoymatiereChange);
                        async function seldsearchcalendriermoymatiereChange(e){
                            e.preventDefault();

                            var userCodeStr = document.getElementById('userCode').innerText;
                            var statutMoyMatiereStr = 'Oui';
                            var docsSearchMoyMatiere = [];

                            var seldsearchcalendriermoymatiereStr = document.getElementById('seldsearchcalendriermoymatiere').value;
                            if(!seldsearchcalendriermoymatiereStr){
                                alert('Sélectionner un calendrier scolaire avant de continuer');
                                document.getElementById('seldsearchcalendriermoymatiere').value = '';
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendriermoymatiere').style.display = 'block';
                                document.getElementById('viewprogrammemoymatiere').style.display = 'none';
                                document.getElementById('viewconsultermoymatiere').style.display = 'none';
                            }else{
                                try {
                                    let result = await db.createIndex({
                                        index: {
                                            fields: ['moymatierestatut', 'inscritcode', 'anneescolaire']
                                        }
                                    });
                                    result = await db.find({
                                        selector: {
                                            moymatierestatut: statutMoyMatiereStr,
                                            inscritcode: userCodeStr,
                                            anneescolaire: seldsearchcalendriermoymatiereStr
                                        }
                                    });
                                    docsSearchMoyMatiere  = result.docs;
                                    var resultatMoyMatiere = docsSearchMoyMatiere.length;
                                    if(resultatMoyMatiere === 0){
                                        alert("Pas de Moyennes par Matière du "+seldsearchcalendriermoymatiereStr+" Disponibles");
                                        document.getElementById('seldsearchcalendriermoymatiere').value = '';
                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('viewcalendriermoymatiere').style.display = 'block';
                                        document.getElementById('viewprogrammemoymatiere').style.display = 'none';
                                        document.getElementById('viewconsultermoymatiere').style.display = 'none';
                                    }else{
                                        updateSearchMoyMatiereUIWithDocs(docsSearchMoyMatiere);
                                        document.getElementById('searchmatiereprogrammemoymatiere').innerText = seldsearchcalendriermoymatiereStr;
                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                       document.getElementById('viewcalendriermoymatiere').style.display = 'block';
                                       document.getElementById('viewprogrammemoymatiere').style.display = 'block';
                                       document.getElementById('viewconsultermoymatiere').style.display = 'block'; 
                                    }
                                } catch (error) {
                                    alert('Erreur Exécution');
                                }
                            }
                        }
                    }
                } catch (error) {
                    alert('Erreur Exécution'); 
                }
            }
        }
        /**nav previous page button*/
        document.getElementById('prevsearchmoymatiereBtn_1').addEventListener('click', prevsearchmoymatiere1BtnChange);
        async function prevsearchmoymatiere1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'none';
            document.getElementById('moyenne').style.display = 'none';
            document.getElementById('moymatiere').style.display = 'block';
            document.getElementById('crudmoymatiere').style.display = 'block';
            document.getElementById('addmoymatiere').style.display = 'none';
            document.getElementById('editmoymatiere').style.display = 'none';
            document.getElementById('deletemoymatiere').style.display = 'none';
            document.getElementById('searchmoymatiere').style.display = 'none';
            titre.innerHTML = 'Calcul Moyennes Par Matière'; 
        }
        // Adds the document to the HTML First table.
        // If the document already exists in the table, it will be updated.
        function searchProgrammeMoyMatiereTableDocToTable(doc, searchProgrammeMoyMatiereTableBody){
            // Each row of the table has an id attribute set to the doc._id field.
            var tr = searchProgrammeMoyMatiereTableBody.querySelector(`#${doc._id}`);

            if( !tr ) {
                tr = document.createElement('tr');
                tr.id = doc._id;
            }
        
            // Remove any existing table cells.
            while(tr.hasChildNodes()) {
                tr.removeChild(tr.childNodes[0]);
            }

            // Create new table cells for text fields.
            var tdMatiere = document.createElement('td');
            tdMatiere.innerText = doc.matierenom;
            tr.appendChild(tdMatiere);

            // Create new table cells for text fields.
            var tdMoyenne = document.createElement('td');
            tdMoyenne.innerText = doc.moyenneobtenue;
            tr.appendChild(tdMoyenne);

            // Add the row to the top of the table.
            searchProgrammeMoyMatiereTableBody.insertBefore(tr, searchProgrammeMoyMatiereTableBody.nextSibling);
        }
        /**Clear and update the UI to display the docs.*/
        function updateSearchMoyMatiereUIWithDocs(docs) {
            var searchProgrammeMoyMatiereTableBody = document.getElementById('searchProgrammeMoyMatiereTableBody');
            
            // Remove all child nodes from the table
            while(searchProgrammeMoyMatiereTableBody.hasChildNodes()) {
                searchProgrammeMoyMatiereTableBody.removeChild(searchProgrammeMoyMatiereTableBody.childNodes[0]);
            }
            
            // Add the new documents to the table
            for(var i=0; i<docs.length; i++) {
                var doc = docs[i];
                searchProgrammeMoyMatiereTableDocToTable(doc, searchProgrammeMoyMatiereTableBody);
            }
        }
        document.getElementById('consultermoymatiereBtn').addEventListener('click', consultermoymatiereBtnChange);
        async function consultermoymatiereBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'none';
            document.getElementById('moyenne').style.display = 'none';
            document.getElementById('moymatiere').style.display = 'block';
            document.getElementById('crudmoymatiere').style.display = 'block';
            document.getElementById('addmoymatiere').style.display = 'none';
            document.getElementById('editmoymatiere').style.display = 'none';
            document.getElementById('deletemoymatiere').style.display = 'none';
            document.getElementById('searchmoymatiere').style.display = 'none';
            titre.innerHTML = 'Calcul Moyennes Par Matière'; 
        }
    };

    

    /****************
    HELPER FUNCTION
    *****************/
    /**1: Get Current Date in JavaScript */
    function getCurrentDate(){
        // Date object
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1;
        var yyyy = today.getFullYear();
        if(dd<10){
            dd='0'+dd;
        }
        if(mm<10){
            mm='0'+mm;
        }
        // Current Date
        var date = yyyy+'-'+mm+'-'+dd;
        return date;
    }

    window.addEventListener('load', () =>{
        new taskMoyMatiere();
    })
}) ();