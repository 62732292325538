import './pouchconfiguration';

import { 
    KeyboardBackspace,
    Settings,
    NavigateNext, 
} from '@material-ui/icons';

function Configurer() {
    return ( 
        <div id="configurer">
            <KeyboardBackspace style={prevArrows} id="prevconfigurerBtn_1" />
            <br/><br/>
            <div id="configautres" style={scroller}>
                <div>
                    <button id="configetablissementBtn" style={configurationButton}>
                        <Settings style={{float:'left',color:'1a1aff'}} />
                        Configuration Etablissement
                        <NavigateNext style={{float:'right',color:'#1a1aff'}} />
                    </button>
                </div>
                <div>
                    <button id="configclasseBtn" style={configurationButton}>
                        <Settings style={{float:'left',color:'1a1aff'}} />
                        Configuration Classe
                        <NavigateNext style={{float:'right',color:'#1a1aff'}} />
                    </button>
                </div>
                <div>
                    <button id="configsemaineBtn" style={configurationButton}>
                        <Settings style={{float:'left',color:'1a1aff'}} />
                        Configuration Semaine Scolaire
                        <NavigateNext style={{float:'right',color:'#1a1aff'}} />
                    </button>
                </div>
                <div>
                    <button id="configanneeBtn" style={configurationButton}>
                        <Settings style={{float:'left',color:'1a1aff'}} />
                        Configuration Année Scolaire
                        <NavigateNext style={{float:'right',color:'#1a1aff'}} />
                    </button>
                </div>
                <div>
                    <button id="configmatiereBtn" style={configurationButton}>
                        <Settings style={{float:'left',color:'1a1aff'}} />
                        Configuration des Matières
                        <NavigateNext style={{float:'right',color:'#1a1aff'}} />
                    </button>
                </div>
                <br/><br/>
            </div>
        </div>
     );
}

//Inline Styling
const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

const configurationButton = {
    marginTop: '4%',
    flex: 1,
    width: '90%',
    height: 50,
    textAlign: "center",
    alignContent: 'center',
    border: "2px solid #1a1aff",
    fontSize: 18,
    borderRadius: 10,
    color: "#1a1aff",
    //textTransform: "uppercase",
}

export default Configurer;