/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
import PouchDB from 'pouchdb';
import PouchFind from 'pouchdb-find';

(function(){
    var taskRevision = function(){
        /*****************************************************
        ENABLING POUCHDB WITHOUT SYNC TO REMOTE COUCHDB
        ******************************************************/
        //SET UP POUCHDB AND CREATE THE DATABASE 
        const db = new PouchDB('suclu');
        PouchDB.plugin(PouchFind);

        /***************
        crudrevisionBtn
        *****************/
        /**nav previous page button*/
        document.getElementById('prevcrudrevisionBtn_1').addEventListener('click', prevcrudrevision1BtnChange);
        async function prevcrudrevision1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'block';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            titre.innerHTML = 'Exercices et Tâches'; 
        }

        /***************************
        addrevisionBtn
        *****************************/
        document.getElementById('addrevisionBtn').addEventListener('click', addrevisionBtnChange);
        async function addrevisionBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutMatiereStr = 'Oui';
            var docsMatiere = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: ['matierestatut', 'inscritcode']
                    }
                });
                result = await db.find({
                    selector: {
                        matierestatut: statutMatiereStr,
                        inscritcode: userCodeStr
                    }
                });
                docsMatiere  = result.docs;
                var resultatMatiere = docsMatiere.length;
                if(resultatMatiere === 0){
                    alert("Matières Classe non encore enregistrées");
                }else{
                    var divClear;
                    var divModifier;
                    divClear = document.getElementById('addmatiererevision');
                    while(divClear.hasChildNodes()) {
                        divClear.removeChild(divClear.childNodes[0]);
                    }
                    divModifier = document.getElementById('addmatiererevision');
                    //Create and append select list
                    var selectList = document.createElement("select");
                    selectList.id = "seladdmatiererevision";
                    divModifier.appendChild(selectList);
                    //Create and append the options
                    var option_null = document.createElement("option");
                    option_null.value = '';
                    option_null.text = 'Sélectionner Matière';
                    selectList.appendChild(option_null);
                    //Create and append the options
                    for (var i = 0; i<docsMatiere.length; i++){
                        var option = document.createElement("option");
                        option.value = docsMatiere[i].matierenom;
                        option.text = docsMatiere[i].matierenom;
                        selectList.appendChild(option);
                    }

                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'none';
                    document.getElementById('configurer').style.display = 'none';
                    document.getElementById('emploidutemps').style.display = 'none';
                    document.getElementById('exercice').style.display = 'none';
                    document.getElementById('pratique').style.display = 'none';
                    document.getElementById('tache').style.display = 'none';
                    document.getElementById('revision').style.display = 'block';
                    document.getElementById('crudrevision').style.display = 'none';
                    document.getElementById('addrevision').style.display = 'block';
                    document.getElementById('editrevision').style.display = 'none';
                    document.getElementById('deleterevision').style.display = 'none';
                    document.getElementById('searchrevision').style.display = 'none';
                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('savetexterevision').style.display = 'none';
                    document.getElementById('savedaterevision').style.display = 'none';
                    document.getElementById('saverevision').style.display = 'none';
                    titre.innerHTML = 'Enregistrer Révision'; 

                    /***************************
                    seladdmatiererevision
                    *****************************/
                    document.getElementById('seladdmatiererevision').addEventListener('change', seladdmatiererevisionChange);
                    async function seladdmatiererevisionChange(e){
                        e.preventDefault();

                        var seladdmatiererevisionStr = document.getElementById('seladdmatiererevision').value;
                        if(!seladdmatiererevisionStr){
                            alert('Sélectionner une matière avant de continuer');
                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('savetexterevision').style.display = 'none';
                            document.getElementById('savedaterevision').style.display = 'none';
                            document.getElementById('saverevision').style.display = 'none';
                            titre.innerHTML = 'Enregistrer Devoir Révision';
                        }else{
                            document.getElementById('addselectmatiererevision').value = seladdmatiererevisionStr;
                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('savetexterevision').style.display = 'block';
                            document.getElementById('savedaterevision').style.display = 'block';
                            document.getElementById('saverevision').style.display = 'block';
                            titre.innerHTML = 'Enregistrer Révision';
                        }
                    }
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        /**nav previous page button*/
        document.getElementById('prevaddrevisionBtn_1').addEventListener('click', prevaddrevision1BtnChange);
        async function prevaddrevision1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'block';
            document.getElementById('crudrevision').style.display = 'block';
            document.getElementById('addrevision').style.display = 'none';
            document.getElementById('editrevision').style.display = 'none';
            document.getElementById('deleterevision').style.display = 'none';
            document.getElementById('searchrevision').style.display = 'none';
            titre.innerHTML = 'Révisions avant Devoirs '; 
        }

        /***************************
        enregistrerrevisionBtn
        *****************************/
        document.getElementById('enregistrerrevisionBtn').addEventListener('click', enregistrerrevisionBtnChange);
        async function enregistrerrevisionBtnChange(e){
            e.preventDefault();

            var usercodeStr = document.getElementById('userCode').innerText;
            var addselectmatiererevisionStr = document.getElementById('addselectmatiererevision').value;
            var addtexterevisionStr = document.getElementById('addtexterevision').value;
            var adddaterevisionStr = document.getElementById('adddaterevision').value;
            var addeffectuerevisionStr = 'Non';
            var datejourStr = getCurrentDate();
            var addstatutrevisionStr = 'Oui';

            if(!usercodeStr){
                alert("Identifiez-vous avant de continuer");
            }else if(!addselectmatiererevisionStr){
                alert("Sélectionner Matière");
            }else if(!addtexterevisionStr){
                alert("Saisir Libellé Révision");
            }else if(!adddaterevisionStr){
                alert("Saisir Date Devoir");
            }else{
                try {
                    //Create the document _id and _rev.
                    var now = Date.now();
                    var id = 'revision_' + now;
                    // Add the document to the database.
                    var response = await db.put({
                        _id: id,
                        inscritcode: usercodeStr,
                        matierenom: addselectmatiererevisionStr,
                        revisiontexte: addtexterevisionStr,
                        revisionecheance: adddaterevisionStr, //date
                        revisioneffectue: addeffectuerevisionStr,
                        revisiondate: datejourStr,
                        revisionstatut: addstatutrevisionStr,
                    });
                    // check if document inserted and synced
                    var doc = await db.get(response.id);
                    if(doc !== ''){
                        alert('Enregistré. Au Suivant');
                        document.getElementById('addselectmatiererevision').value = '';
                        document.getElementById('addtexterevision').value = '';
                        document.getElementById('adddaterevision').value = '';

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'block';
                        document.getElementById('crudrevision').style.display = 'block';
                        document.getElementById('addrevision').style.display = 'none';
                        document.getElementById('editrevision').style.display = 'none';
                        document.getElementById('deleterevision').style.display = 'none';
                        document.getElementById('searchrevision').style.display = 'none';
                        titre.innerHTML = 'Révisions avant Devoirs '; 
                    }else{
                        alert('Echec Enregistrement Révision Devoir. Reprendre');
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }

        /***************************
        editrevisionBtn
        *****************************/
        document.getElementById('editrevisionBtn').addEventListener('click', editrevisionBtnChange);
        async function editrevisionBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutRevisionStr = 'Oui';
            var effectueRevisionStr = 'Non';
            var docsEditRevision = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: [
                            'revisionstatut', 
                            'inscritcode', 
                            'revisioneffectue'
                        ]
                    }
                });
                result = await db.find({
                    selector: {
                        revisionstatut: statutRevisionStr,
                        inscritcode: userCodeStr, 
                        revisioneffectue: effectueRevisionStr
                    }
                });
                docsEditRevision  = result.docs;
                var resultatEditRevision = docsEditRevision.length;
                if(resultatEditRevision === 0){
                    alert("Revisions Devoirs non encore enregistrés");
                }else{
                    var divClear;
                    var divModifier;
                    divClear = document.getElementById('editmatiererevision');
                    while(divClear.hasChildNodes()) {
                        divClear.removeChild(divClear.childNodes[0]);
                    }
                    divModifier = document.getElementById('editmatiererevision');
                    //Create and append select list
                    var selectList = document.createElement("select");
                    selectList.id = "seleditmatiererevision";
                    divModifier.appendChild(selectList);
                    //Create and append the options
                    var option_null = document.createElement("option");
                    option_null.value = '';
                    option_null.text = 'Sélectionner matière';
                    selectList.appendChild(option_null);
                    //Create and append the options
                    for (var i = 0; i<docsEditRevision.length; i++){
                        var option = document.createElement("option");
                        option.value = docsEditRevision[i]._id;
                        option.text = docsEditRevision[i].matierenom;
                        selectList.appendChild(option);
                    }

                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'none';
                    document.getElementById('configurer').style.display = 'none';
                    document.getElementById('emploidutemps').style.display = 'none';
                    document.getElementById('exercice').style.display = 'none';
                    document.getElementById('pratique').style.display = 'none';
                    document.getElementById('tache').style.display = 'none';
                    document.getElementById('revision').style.display = 'block';
                    document.getElementById('crudrevision').style.display = 'none';
                    document.getElementById('addrevision').style.display = 'none';
                    document.getElementById('editrevision').style.display = 'block';
                    document.getElementById('deleterevision').style.display = 'none';
                    document.getElementById('searchrevision').style.display = 'none';
                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('changetexterevision').style.display = 'none';
                    document.getElementById('changedaterevision').style.display = 'none';
                    document.getElementById('changerevision').style.display = 'none';
                    titre.innerHTML = 'Modifier Révision'; 

                    /***************************
                    seleditmatiererevision
                    *****************************/
                    document.getElementById('seleditmatiererevision').addEventListener('change', seleditmatiererevisionChange);
                    async function seleditmatiererevisionChange(e){
                        e.preventDefault();

                        var userCodeStr = document.getElementById('userCode').innerText;
                        var revisionIdStr,inscritCodeStr,revisionMatiereStr,revisionTexteStr,revisionEcheanceStr,
                        revisionEffectueStr,revisionDateStr,revisionStatutStr = '';

                        var seleditmatiererevisionStr = document.getElementById('seleditmatiererevision').value;
                        if(!seleditmatiererevisionStr){
                            alert('Sélectionner une Matière avant de continuer');
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('changetexterevision').style.display = 'none';
                            document.getElementById('changedaterevision').style.display = 'none';
                            document.getElementById('changerevision').style.display = 'none';
                        }else{
                            var userCodeStr = document.getElementById('userCode').innerText;
                            var statutRevisionStr = 'Oui';
                            var revisionEffectStr = 'Non';
                            var docsEdit = [];
                            try {
                                let result = await db.createIndex({
                                    index: {
                                        fields: [
                                            'revisionstatut', 
                                            'inscritcode',
                                            'revisioneffectue', 
                                            '_id'
                                        ]
                                    }
                                });
                                result = await db.find({
                                    selector: {
                                        revisionstatut: statutRevisionStr,
                                        inscritcode: userCodeStr,
                                        revisioneffectue: revisionEffectStr,
                                        _id: seleditmatiererevisionStr
                                    }
                                });
                                docsEdit  = result.docs;
                                var resultatEdit = docsEdit.length;
                                if(resultatEdit === 0){
                                    document.getElementById('editidrevision').value = '';
                                    document.getElementById('editcodeuserrevision').value = '';
                                    document.getElementById('editselectmatiererevision').value = '';
                                    document.getElementById('edittexterevision').value = '';
                                    document.getElementById('editecheancerevision').value = '';
                                    document.getElementById('editeffectuerevision').value = '';
                                    document.getElementById('editdaterevision').value = '';
                                    document.getElementById('editstatutrevision').value = '';
                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('changetexterevision').style.display = 'none';
                                    document.getElementById('changedaterevision').style.display = 'none';
                                    document.getElementById('changerevision').style.display = 'none';
                                }else{
                                    for(let j=0; j<docsEdit.length; j++){
                                        revisionIdStr = docsEdit[j]._id;
                                        inscritCodeStr = docsEdit[j].inscritcode;
                                        revisionMatiereStr = docsEdit[j].matierenom;
                                        revisionTexteStr = docsEdit[j].revisiontexte;
                                        revisionEcheanceStr = docsEdit[j].revisionecheance;
                                        revisionEffectueStr = docsEdit[j].revisioneffectue;
                                        revisionDateStr = docsEdit[j].revisiondate;
                                        revisionStatutStr = docsEdit[j].revisionstatut;
                                    }

                                    document.getElementById('editidrevision').value = revisionIdStr;
                                    document.getElementById('editcodeuserrevision').value = inscritCodeStr;
                                    document.getElementById('editselectmatiererevision').value = revisionMatiereStr;
                                    document.getElementById('edittexterevision').value = revisionTexteStr;
                                    document.getElementById('editecheancerevision').value = revisionEcheanceStr;
                                    document.getElementById('editeffectuerevision').value = revisionEffectueStr;
                                    document.getElementById('editdaterevision').value = revisionDateStr;
                                    document.getElementById('editstatutrevision').value = revisionStatutStr;
                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('changetexterevision').style.display = 'block';
                                    document.getElementById('changedaterevision').style.display = 'block';
                                    document.getElementById('changerevision').style.display = 'block';
                                }
                            } catch (error) {
                                alert('Erreur Exécution Matières Classe');
                            }
                        }
                    }
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        /**nav previous page button*/
        document.getElementById('preveditrevisionBtn_1').addEventListener('click', preveditrevision1BtnChange);
        async function preveditrevision1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'block';
            document.getElementById('crudrevision').style.display = 'block';
            document.getElementById('addrevision').style.display = 'none';
            document.getElementById('editrevision').style.display = 'none';
            document.getElementById('deleterevision').style.display = 'none';
            document.getElementById('searchrevision').style.display = 'none';
            titre.innerHTML = 'Révisions avant Devoirs'; 
        }
        /***************************
        modifierrevisionBtn
        *****************************/
        document.getElementById('modifierrevisionBtn').addEventListener('click', modifierrevisionBtnChange);
        async function modifierrevisionBtnChange(e){
            e.preventDefault();

            var revisionMatiereStr = document.getElementById('editselectmatiererevision').value;
            var revisionTexteStr = document.getElementById('edittexterevision').value;
            var revisionEcheanceStr = document.getElementById('editecheancerevision').value;

            var revisionIdStr = document.getElementById('editidrevision').value;
            var inscritCodeStr = document.getElementById('editcodeuserrevision').value;
            var revisionEffectueStr = document.getElementById('editeffectuerevision').value;
            var revisionDateStr = document.getElementById('editdaterevision').value;
            var revisionStatutStr = document.getElementById('editstatutrevision').value;

            if(!inscritCodeStr){
                alert("Identifiez-vous avant de continuer");
            }else if(!revisionTexteStr){
                alert("Saisir Libellé Révision");
            }else if(!revisionEcheanceStr){
                alert('Saisir Date Devoir');
            }else{
                try {
                    // Retrieve the document to get the latest revision
                    var id = revisionIdStr;
                    var doc = await db.get(id);
                    // Make changes
                    doc.inscritcode = inscritCodeStr;
                    doc.matierenom = revisionMatiereStr;
                    doc.revisiontexte = revisionTexteStr;
                    doc.revisionecheance = revisionEcheanceStr;
                    doc.revisioneffectue = revisionEffectueStr;
                    doc.revisiondate = revisionDateStr;
                    doc.revisionstatut = revisionStatutStr;
                    // Put the document, saving it as a new revision
                    var response = await db.put(doc);
                    // Get the document we change and update the UI.
                    var docEdit = await db.get(response.id);
                    if(docEdit !== ''){
                        alert("Revision Devoir Modifiée avec Succès");
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'block';
                        document.getElementById('crudrevision').style.display = 'block';
                        document.getElementById('addrevision').style.display = 'none';
                        document.getElementById('editrevision').style.display = 'none';
                        document.getElementById('deleterevision').style.display = 'none';
                        document.getElementById('searchrevision').style.display = 'none';
                        titre.innerHTML = 'Révisions avant Devoirs'; 
                    }else{
                        alert("Erreur Modification. Reprendre");
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'block';
                        document.getElementById('crudrevision').style.display = 'block';
                        document.getElementById('addrevision').style.display = 'none';
                        document.getElementById('editrevision').style.display = 'none';
                        document.getElementById('deleterevision').style.display = 'none';
                        document.getElementById('searchrevision').style.display = 'none';
                        titre.innerHTML = 'Révisions avant Devoirs'; 
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }

        /***************************
        deleterevisionBtn
        *****************************/
        document.getElementById('deleterevisionBtn').addEventListener('click', deleterevisionBtnChange);
        async function deleterevisionBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutRevisionStr = 'Oui';
            var effectueRevisionStr = 'Non';
            var docsDeleteRevision = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: [
                            'revisionstatut', 
                            'inscritcode',
                            'revisioneffectue'
                        ]
                    }
                });
                result = await db.find({
                    selector: {
                        revisionstatut: statutRevisionStr,
                        inscritcode: userCodeStr,
                        revisioneffectue: effectueRevisionStr
                    }
                });
                docsDeleteRevision  = result.docs;
                var resultatDeleteRevision = docsDeleteRevision.length;
                if(resultatDeleteRevision === 0){
                    alert("Devoirs Révisions non encore enregistrés");
                }else{
                    var divClear;
                    var divModifier;
                    divClear = document.getElementById('deletematiererevision');
                    while(divClear.hasChildNodes()) {
                        divClear.removeChild(divClear.childNodes[0]);
                    }
                    divModifier = document.getElementById('deletematiererevision');
                    //Create and append select list
                    var selectList = document.createElement("select");
                    selectList.id = "seldeletematiererevision";
                    divModifier.appendChild(selectList);
                    //Create and append the options
                    var option_null = document.createElement("option");
                    option_null.value = '';
                    option_null.text = 'Sélectionner matière';
                    selectList.appendChild(option_null);
                    //Create and append the options
                    for (var i = 0; i<docsDeleteRevision.length; i++){
                        var option = document.createElement("option");
                        option.value = docsDeleteRevision[i]._id;
                        option.text = docsDeleteRevision[i].matierenom;
                        selectList.appendChild(option);
                    }

                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'none';
                    document.getElementById('configurer').style.display = 'none';
                    document.getElementById('emploidutemps').style.display = 'none';
                    document.getElementById('exercice').style.display = 'none';
                    document.getElementById('pratique').style.display = 'none';
                    document.getElementById('tache').style.display = 'none';
                    document.getElementById('revision').style.display = 'block';
                    document.getElementById('crudrevision').style.display = 'none';
                    document.getElementById('addrevision').style.display = 'none';
                    document.getElementById('editrevision').style.display = 'none';
                    document.getElementById('deleterevision').style.display = 'block';
                    document.getElementById('searchrevision').style.display = 'none';
                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('canceltexterevision').style.display = 'none';
                    document.getElementById('canceldaterevision').style.display = 'none';
                    document.getElementById('cancelrevision').style.display = 'none';
                    titre.innerHTML = 'Supprimer Révision'; 

                    /***************************
                    seldeletematiererevision
                    *****************************/
                    document.getElementById('seldeletematiererevision').addEventListener('change', seldeletematiererevisionChange);
                    async function seldeletematiererevisionChange(e){
                        e.preventDefault();

                        var userCodeStr = document.getElementById('userCode').innerText;
                        var revisionIdStr,revisionTexteStr,revisionEcheanceStr = '';
                        var seldeletematiererevisionStr = document.getElementById('seldeletematiererevision').value;
                        if(!seldeletematiererevisionStr){
                            alert('Sélectionner une Matière avant de continuer');
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('canceltexterevision').style.display = 'none';
                            document.getElementById('canceldaterevision').style.display = 'none';
                            document.getElementById('cancelrevision').style.display = 'none'; 
                        }else{
                            var userCodeStr = document.getElementById('userCode').innerText;
                            var statutRevisionStr = 'Oui';
                            var revisionEffectStr = 'Non';
                            var docsDelete = [];
                            try {
                                let result = await db.createIndex({
                                    index: {
                                        fields: [
                                            'revisionstatut', 
                                            'inscritcode',
                                            'revisioneffectue', 
                                            '_id'
                                        ]
                                    }
                                });
                                result = await db.find({
                                    selector: {
                                        revisionstatut: statutRevisionStr,
                                        inscritcode: userCodeStr,
                                        revisioneffectue: revisionEffectStr,
                                        _id: seldeletematiererevisionStr
                                    }
                                });
                                docsDelete  = result.docs;
                                var resultatDelete = docsDelete.length;
                                if(resultatDelete === 0){
                                    document.getElementById('deleteidrevision').value = '';
                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('canceltexterevision').style.display = 'none';
                                    document.getElementById('canceldaterevision').style.display = 'none';
                                    document.getElementById('cancelrevision').style.display = 'none'; 
                                }else{
                                    for(let j=0; j<docsDelete.length; j++){
                                        revisionIdStr = docsDelete[j]._id;
                                        revisionTexteStr = docsDelete[j].revisiontexte;
                                        revisionEcheanceStr = docsDelete[j].revisionecheance;
                                    }

                                    document.getElementById('deletetexterevision').value = revisionTexteStr;
                                    document.getElementById('deleteecheancerevision').value = revisionEcheanceStr;

                                    document.getElementById('deleteidrevision').value = revisionIdStr;

                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('canceltexterevision').style.display = 'block';
                                    document.getElementById('canceldaterevision').style.display = 'block';
                                    document.getElementById('cancelrevision').style.display = 'block'; 
                                }
                            } catch (error) {
                                alert('Erreur Exécution Matières Classe');
                            }
                        }
                    }
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        /**nav previous page button*/
        document.getElementById('prevdeleterevisionBtn_1').addEventListener('click', prevdeleterevision1BtnChange);
        async function prevdeleterevision1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'block';
            document.getElementById('crudrevision').style.display = 'block';
            document.getElementById('addrevision').style.display = 'none';
            document.getElementById('editrevision').style.display = 'none';
            document.getElementById('deleterevision').style.display = 'none';
            document.getElementById('searchrevision').style.display = 'none';
            titre.innerHTML = 'Révisions avant Devoirs'; 
        }
        /***************************
        supprimerrevisionBtn
        *****************************/
        document.getElementById('supprimerrevisionBtn').addEventListener('click', supprimerrevisionBtnChange);
        async function supprimerrevisionBtnChange(e){
            e.preventDefault();

            var divClearModal = document.getElementById('modaloutrevision');
            let divModal = document.createElement('div');
            divModal.innerHTML =
            `<div class="modal">
                <p>Êtes-vous sûr de Supprimer ?</p>
                <button class="btn btn--alt" id="ouiHandlerRevisionBtn">Oui</button>
                <button class="btn" id="nonHandlerRevisionBtn">Non</button>
            </div>`;
            divClearModal.appendChild(divModal);

            var divClearBackdrop = document.getElementById('backdropoutrevision');
            let divBack = document.createElement('div');
            divBack.innerHTML =
            `<div class="backdrop" id="backdropHandlerRevisionBtn"></div>`;
            divClearBackdrop.appendChild(divBack);

            document.getElementById('ouiHandlerRevisionBtn').addEventListener('click', ouiHandlerRevisionBtnChange);
            async function ouiHandlerRevisionBtnChange(e){
                e.preventDefault();

                var deleteidrevisionStr = document.getElementById('deleteidrevision').value;

                try {
                    //Delete
                    var doc = await db.get(deleteidrevisionStr);
                    var response = await db.remove(doc);
                    if(response.ok){
                        alert('Révision Devoir Supprimée avec Succès');
                        var divClearModal = document.getElementById('modaloutrevision');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutrevision');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'block';
                        document.getElementById('crudrevision').style.display = 'block';
                        document.getElementById('addrevision').style.display = 'none';
                        document.getElementById('editrevision').style.display = 'none';
                        document.getElementById('deleterevision').style.display = 'none';
                        document.getElementById('searchrevision').style.display = 'none';
                        titre.innerHTML = 'Révisions avant Devoirs'; 
                    }else{
                        alert("Impossible Supprimer Révision Devoir");
                        var divClearModal = document.getElementById('modaloutrevision');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutrevision');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'block';
                        document.getElementById('crudrevision').style.display = 'block';
                        document.getElementById('addrevision').style.display = 'none';
                        document.getElementById('editrevision').style.display = 'none';
                        document.getElementById('deleterevision').style.display = 'none';
                        document.getElementById('searchrevision').style.display = 'none';
                        titre.innerHTML = 'Révisions avant Devoirs'; 
                    }
                    
                    
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
            document.getElementById('nonHandlerRevisionBtn').addEventListener('click', nonHandlerRevisionBtnChange);
            async function nonHandlerRevisionBtnChange(e){
                e.preventDefault();

                var divClearModal = document.getElementById('modaloutrevision');
                while(divClearModal.hasChildNodes()) {
                    divClearModal.removeChild(divClearModal.childNodes[0]);
                }

                var divClearBackdrop = document.getElementById('backdropoutrevision');
                while(divClearBackdrop.hasChildNodes()) {
                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                }
            }
            document.getElementById('backdropHandlerRevisionBtn').addEventListener('click', backdropHandlerRevisionBtnChange);
            async function backdropHandlerRevisionBtnChange(e){
                e.preventDefault();

                var divClearModal = document.getElementById('modaloutrevision');
                while(divClearModal.hasChildNodes()) {
                    divClearModal.removeChild(divClearModal.childNodes[0]);
                }

                var divClearBackdrop = document.getElementById('backdropoutrevision');
                while(divClearBackdrop.hasChildNodes()) {
                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                }
            }
        }

        /***************************
        searchrevisionBtn
        *****************************/
        document.getElementById('searchrevisionBtn').addEventListener('click', searchrevisionBtnChange);
        async function searchrevisionBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutRevisionStr = 'Oui';
            var docsSearchRevision = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: ['revisionstatut', 'inscritcode']
                    }
                });
                result = await db.find({
                    selector: {
                        revisionstatut: statutRevisionStr,
                        inscritcode: userCodeStr
                    }
                });
                docsSearchRevision  = result.docs;
                var resultatSearchRevision = docsSearchRevision.length;
                if(resultatSearchRevision === 0){
                    alert("Révision Devoir non encore enregistrée");
                }else{
                    var divClear;
                    var divModifier;
                    divClear = document.getElementById('searchmatiererevision');
                    while(divClear.hasChildNodes()) {
                        divClear.removeChild(divClear.childNodes[0]);
                    }
                    divModifier = document.getElementById('searchmatiererevision');
                    //Create and append select list
                    var selectList = document.createElement("select");
                    selectList.id = "selsearchmatiererevision";
                    divModifier.appendChild(selectList);
                    //Create and append the options
                    var option_null = document.createElement("option");
                    option_null.value = '';
                    option_null.text = 'Sélectionner matière';
                    selectList.appendChild(option_null);
                    //Create and append the options
                    for (var i = 0; i<docsSearchRevision.length; i++){
                        var option = document.createElement("option");
                        option.value = docsSearchRevision[i].matierenom;
                        option.text = docsSearchRevision[i].matierenom;
                        selectList.appendChild(option);
                    }

                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'none';
                    document.getElementById('configurer').style.display = 'none';
                    document.getElementById('emploidutemps').style.display = 'none';
                    document.getElementById('exercice').style.display = 'none';
                    document.getElementById('pratique').style.display = 'none';
                    document.getElementById('tache').style.display = 'none';
                    document.getElementById('revision').style.display = 'block';
                    document.getElementById('crudrevision').style.display = 'none';
                    document.getElementById('addrevision').style.display = 'none';
                    document.getElementById('editrevision').style.display = 'none';
                    document.getElementById('deleterevision').style.display = 'none';
                    document.getElementById('searchrevision').style.display = 'block';
                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('vieweffectuerevision').style.display = 'none';
                    document.getElementById('viewrevision').style.display = 'none';
                    document.getElementById('viewconsulterrevision').style.display = 'none';
                    titre.innerHTML = 'Consulter Révision';

                    /***************************
                    selsearchmatiererevision
                    *****************************/
                    document.getElementById('selsearchmatiererevision').addEventListener('change', selsearchmatiererevisionChange);
                    async function selsearchmatiererevisionChange(e){
                        e.preventDefault();

                        var selsearchmatiererevisionStr = document.getElementById('selsearchmatiererevision').value;
                        if(!selsearchmatiererevisionStr){
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('vieweffectuerevision').style.display = 'none';
                            document.getElementById('viewrevision').style.display = 'none';
                            document.getElementById('viewconsulterrevision').style.display = 'none';
                        }else{
                            document.getElementById('searchmatiererevision').value = selsearchmatiererevisionStr;
                            document.getElementById('viewmatiererevision').innerText = selsearchmatiererevisionStr;
                            document.getElementById('searcheffectuerevision').value = '';
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('vieweffectuerevision').style.display = 'block';
                            document.getElementById('viewrevision').style.display = 'none';
                            document.getElementById('viewconsulterrevision').style.display = 'none';
                        }
                    }
                }
            } catch (error) {
                alert('Erreur Exécution'); 
            }
        }
        /**nav previous page button*/
        document.getElementById('prevsearchrevisionBtn_1').addEventListener('click', prevsearchrevision1BtnChange);
        async function prevsearchrevision1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'block';
            document.getElementById('crudrevision').style.display = 'block';
            document.getElementById('addrevision').style.display = 'none';
            document.getElementById('editrevision').style.display = 'none';
            document.getElementById('deleterevision').style.display = 'none';
            document.getElementById('searchrevision').style.display = 'none';
            titre.innerHTML = 'Révisions avant Devoirs'; 
        }
        /***************************
        searcheffectuerevision
        *****************************/
        document.getElementById('searcheffectuerevision').addEventListener('change', searcheffectuerevisionChange);
        async function searcheffectuerevisionChange(e){
            e.preventDefault();

            var searcheffectuerevisionStr = document.getElementById('searcheffectuerevision').value;
            var searchmatiererevisionStr = document.getElementById('searchmatiererevision').value;
            var userCodeStr = document.getElementById('userCode').innerText;
            var effectOuiStr = 'Oui';
            var effectNonStr = 'Non'; 
            var statutRevisionStr = 'Oui';
            var docsSearchNon = [];
            var docsSearchOui = [];
            if(searcheffectuerevisionStr === 'Non'){
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: [
                                'revisionstatut',
                                'revisioneffectue',
                                'matierenom',
                                'inscritcode'
                            ]
                        }
                    });
                    result = await db.find({
                        selector: {
                            revisionstatut: statutRevisionStr,
                            revisioneffectue: effectNonStr,
                            matierenom: searchmatiererevisionStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsSearchNon  = result.docs;
                    var resultatSearchNon = docsSearchNon.length;
                    if(resultatSearchNon === 0){
                        alert("Pas de révisions Devoirs non Effectuées Disponibles");
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('vieweffectuerevision').style.display = 'block';
                        document.getElementById('viewrevision').style.display = 'none';
                        document.getElementById('viewconsulterrevision').style.display = 'none';
                    }else{
                        updateSearchRevisionUIWithDocs(docsSearchNon);
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('vieweffectuerevision').style.display = 'block';
                        document.getElementById('viewrevision').style.display = 'block';
                        document.getElementById('viewconsulterrevision').style.display = 'block';
                    }
                } catch (error) {
                   alert('Erreur Exécution'); 
                }
            }else if(searcheffectuerevisionStr === 'Oui'){
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: [
                                'revisionstatut',
                                'revisioneffectue',
                                'matierenom',
                                'inscritcode'
                            ]
                        }
                    });
                    result = await db.find({
                        selector: {
                            revisionstatut: statutRevisionStr,
                            revisioneffectue: effectOuiStr,
                            matierenom: searchmatiererevisionStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsSearchOui  = result.docs;
                    var resultatSearchOui = docsSearchOui.length;
                    if(resultatSearchOui === 0){
                        alert("Pas de Révisions Devoirs Effectuées Disponibles");
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('vieweffectuerevision').style.display = 'block';
                        document.getElementById('viewrevision').style.display = 'none';
                        document.getElementById('viewconsulterrevision').style.display = 'none';
                    }else{
                        updateSearchRevisionUIWithDocs(docsSearchOui);
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('vieweffectuerevision').style.display = 'block';
                        document.getElementById('viewrevision').style.display = 'block';
                        document.getElementById('viewconsulterrevision').style.display = 'block';
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }else{
                alert("Selectionner option Oui/Non");
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('vieweffectuerevision').style.display = 'block';
                document.getElementById('viewrevision').style.display = 'none';
                document.getElementById('viewconsulterrevision').style.display = 'none';
            }
        }
        // Adds the document to the HTML First table.
        // If the document already exists in the table, it will be updated.
        function viewSearchRevisionTableDocToTable(doc, viewSearchRevisionTableBody){
            // Each row of the table has an id attribute set to the doc._id field.
            var tr = viewSearchRevisionTableBody.querySelector(`#${doc._id}`);
            var searcheffectuerevisionStr = document.getElementById('searcheffectuerevision').value;

            if( !tr ) {
                tr = document.createElement('tr');
                tr.id = doc._id;
            }
        
            // Remove any existing table cells.
            while(tr.hasChildNodes()) {
                tr.removeChild(tr.childNodes[0]);
            }

            // Create new table cells for text fields.
            var tdEcheance = document.createElement('td');
            var dateEcheance = new Date(doc.revisionecheance);
            tdEcheance.innerText = dateEcheance.toLocaleDateString();
            tr.appendChild(tdEcheance);

            // Create new table cells for text fields.
            var tdEnonce = document.createElement('td');
            tdEnonce.innerText = doc.revisiontexte;
            var br = document.createElement("br");
            tdEnonce.appendChild(br);
            var idRevision = doc._id;
            if(searcheffectuerevisionStr === 'Non'){
                var button = document.createElement("button");
                button.id = "ouirevisionBtn";
                button.type = 'button';
                button.innerHTML = 'Effectué';
                button.onclick = async function(){
                    var inscritCodeStr,revisionMatiereStr,revisionTexteStr,revisionEcheanceStr,
                    revisionDateStr,revisionStatutStr = '';
                    var revisionEffectueStr = 'Oui';
                    var docsRevision = [];
                    try {
                        let result = await db.createIndex({
                            index: {
                                fields: ['_id']
                            }
                        });
                        result = await db.find({
                            selector: {
                                _id: idRevision
                            }
                        });
                        docsRevision = result.docs;
                        var resultatRevision = docsRevision.length;
                        if(resultatRevision === 0){
                            alert("Révision Devoir n'existe pas");
                        }else{
                            for(let i=0; i<docsRevision.length; i++){
                                inscritCodeStr = docsRevision[i].inscritcode;
                                revisionMatiereStr = docsRevision[i].matierenom;
                                revisionTexteStr = docsRevision[i].revisiontexte;
                                revisionEcheanceStr = docsRevision[i].revisionecheance;
                                revisionDateStr = docsRevision[i].revisiondate;
                                revisionStatutStr = docsRevision[i].revisionstatut;
                            }

                            // Retrieve the document to get the latest revision
                            var id = idRevision;
                            var docModifier = await db.get(id);
                            // Make changes
                            docModifier.inscritcode = inscritCodeStr;
                            docModifier.matierenom = revisionMatiereStr;
                            docModifier.revisiontexte = revisionTexteStr;
                            docModifier.revisionecheance = revisionEcheanceStr;
                            docModifier.revisioneffectue = revisionEffectueStr;
                            docModifier.revisiondate = revisionDateStr;
                            docModifier.revisionstatut = revisionStatutStr;
                            // Put the document, saving it as a new revision
                            var response = await db.put(docModifier);
                            // Get the document we change and update the UI.
                            var docEdit = await db.get(response.id);
                            if(docEdit !== ''){
                                document.getElementById('titre').style.display = 'block';
                                var titre = document.getElementById('titre');

                                document.getElementById('demarrage').style.display = 'none';
                                document.getElementById('tableaubord').style.display = 'none';
                                document.getElementById('dashboard').style.display = 'none';
                                document.getElementById('configurations').style.display = 'none';
                                document.getElementById('configurer').style.display = 'none';
                                document.getElementById('emploidutemps').style.display = 'none';
                                document.getElementById('exercice').style.display = 'none';
                                document.getElementById('pratique').style.display = 'none';
                                document.getElementById('tache').style.display = 'none';
                                document.getElementById('revision').style.display = 'block';
                                document.getElementById('crudrevision').style.display = 'block';
                                document.getElementById('addrevision').style.display = 'none';
                                document.getElementById('editrevision').style.display = 'none';
                                document.getElementById('deleterevision').style.display = 'none';
                                document.getElementById('searchrevision').style.display = 'none';
                                titre.innerHTML = 'Révisions avant Devoirs'; 
                            }else{
                                document.getElementById('titre').style.display = 'block';
                                var titre = document.getElementById('titre');

                                document.getElementById('demarrage').style.display = 'none';
                                document.getElementById('tableaubord').style.display = 'none';
                                document.getElementById('dashboard').style.display = 'none';
                                document.getElementById('configurations').style.display = 'none';
                                document.getElementById('configurer').style.display = 'none';
                                document.getElementById('emploidutemps').style.display = 'none';
                                document.getElementById('exercice').style.display = 'none';
                                document.getElementById('pratique').style.display = 'none';
                                document.getElementById('tache').style.display = 'none';
                                document.getElementById('revision').style.display = 'block';
                                document.getElementById('crudrevision').style.display = 'block';
                                document.getElementById('addrevision').style.display = 'none';
                                document.getElementById('editrevision').style.display = 'none';
                                document.getElementById('deleterevision').style.display = 'none';
                                document.getElementById('searchrevision').style.display = 'none';
                                titre.innerHTML = 'Révisions avant Devoirs'; 
                            }
                        }
                    } catch (error) {
                        alert('Erreur Exécution');
                    }
                }
            }else{
                var button = document.createElement("button");
                button.id = "nonrevisionBtn";
                button.type = 'button';
                button.innerHTML = 'Supprimer';
                button.onclick = async function(){
                    var divClearModal = document.getElementById('modaloutsearchrevision');
                    let divModal = document.createElement('div');
                    divModal.innerHTML =
                    `<div class="modal">
                    <p>Êtes-vous sûr de Supprimer Cet Exercice ?</p>
                    <button class="btn btn--alt" id="ouiHandlerSearchRevisionBtn">Oui</button>
                    <button class="btn" id="nonHandlerSearchRevisionBtn">Non</button>
                    </div>`;
                    divClearModal.appendChild(divModal);

                    var divClearBackdrop = document.getElementById('backdropoutsearchrevision');
                    let divBack = document.createElement('div');
                    divBack.innerHTML =
                    `<div class="backdrop" id="backdropHandlerSearchRevisionBtn"></div>`;
                    divClearBackdrop.appendChild(divBack);

                    document.getElementById('ouiHandlerSearchRevisionBtn').addEventListener('click', ouiHandlerSearchRevisionBtnChange);
                    async function ouiHandlerSearchRevisionBtnChange(e){
                        e.preventDefault();

                        var deleteidrevisionStr = idRevision;
                        try {
                            //Delete
                            var doc = await db.get(deleteidrevisionStr);
                            var response = await db.remove(doc);
                            if(response.ok){
                                alert('Révision Devoir Supprimée avec Succès');
                                var divClearModal = document.getElementById('modaloutsearchrevision');
                                while(divClearModal.hasChildNodes()) {
                                    divClearModal.removeChild(divClearModal.childNodes[0]);
                                }

                                var divClearBackdrop = document.getElementById('backdropoutsearchrevision');
                                while(divClearBackdrop.hasChildNodes()) {
                                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                }

                                document.getElementById('titre').style.display = 'block';
                                var titre = document.getElementById('titre');

                                document.getElementById('demarrage').style.display = 'none';
                                document.getElementById('tableaubord').style.display = 'none';
                                document.getElementById('dashboard').style.display = 'none';
                                document.getElementById('configurations').style.display = 'none';
                                document.getElementById('configurer').style.display = 'none';
                                document.getElementById('emploidutemps').style.display = 'none';
                                document.getElementById('exercice').style.display = 'none';
                                document.getElementById('pratique').style.display = 'none';
                                document.getElementById('tache').style.display = 'none';
                                document.getElementById('revision').style.display = 'block';
                                document.getElementById('crudrevision').style.display = 'block';
                                document.getElementById('addrevision').style.display = 'none';
                                document.getElementById('editrevision').style.display = 'none';
                                document.getElementById('deleterevision').style.display = 'none';
                                document.getElementById('searchrevision').style.display = 'none';
                                titre.innerHTML = 'Révisions avant Devoirs'; 
                            }else{
                                alert("Impossible Supprimer Révision Devoir");
                                document.getElementById('titre').style.display = 'block';
                                var titre = document.getElementById('titre');

                                document.getElementById('demarrage').style.display = 'none';
                                document.getElementById('tableaubord').style.display = 'none';
                                document.getElementById('dashboard').style.display = 'none';
                                document.getElementById('configurations').style.display = 'none';
                                document.getElementById('configurer').style.display = 'none';
                                document.getElementById('emploidutemps').style.display = 'none';
                                document.getElementById('exercice').style.display = 'none';
                                document.getElementById('pratique').style.display = 'none';
                                document.getElementById('tache').style.display = 'none';
                                document.getElementById('revision').style.display = 'block';
                                document.getElementById('crudrevision').style.display = 'block';
                                document.getElementById('addrevision').style.display = 'none';
                                document.getElementById('editrevision').style.display = 'none';
                                document.getElementById('deleterevision').style.display = 'none';
                                document.getElementById('searchrevision').style.display = 'none';
                                titre.innerHTML = 'Révisions avant Devoirs'; 
                            }
                        } catch (error) {
                            alert('Erreur Exécution');
                        }
                    }
                    document.getElementById('nonHandlerSearchRevisionBtn').addEventListener('click', nonHandlerSearchRevisionBtnChange);
                    async function nonHandlerSearchRevisionBtnChange(e){
                        e.preventDefault();

                        var divClearModal = document.getElementById('modaloutsearchrevision');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutsearchrevision');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }
                    }
                    document.getElementById('backdropHandlerSearchRevisionBtn').addEventListener('click', backdropHandlerSearchRevisionBtnChange);
                    async function backdropHandlerSearchRevisionBtnChange(e){
                        e.preventDefault();

                        var divClearModal = document.getElementById('modaloutsearchrevision');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutsearchrevision');
                        while(divClearBackdrop.hasChildNodes()) {
                             divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }
                    }
                }
            }
            tdEnonce.appendChild(button);
            tr.appendChild(tdEnonce);

            // Add the row to the top of the table.
            viewSearchRevisionTableBody.insertBefore(tr, viewSearchRevisionTableBody.nextSibling);
        }
        /**Clear and update the UI to display the docs.*/
        function updateSearchRevisionUIWithDocs(docs) {
            var viewSearchRevisionTableBody = document.getElementById('viewSearchRevisionTableBody');
            
            // Remove all child nodes from the table
            while(viewSearchRevisionTableBody.hasChildNodes()) {
                viewSearchRevisionTableBody.removeChild(viewSearchRevisionTableBody.childNodes[0]);
            }
            
            // Add the new documents to the table
            for(var i=0; i<docs.length; i++) {
                var doc = docs[i];
                viewSearchRevisionTableDocToTable(doc, viewSearchRevisionTableBody);
            }
        }
        /***************************
        consulterrevisionBtn
        *****************************/
        document.getElementById('consulterrevisionBtn').addEventListener('click', consulterrevisionBtnChange);
        async function consulterrevisionBtnChange(e){
            e.preventDefault();
            
            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'block';
            document.getElementById('crudrevision').style.display = 'block';
            document.getElementById('addrevision').style.display = 'none';
            document.getElementById('editrevision').style.display = 'none';
            document.getElementById('deleterevision').style.display = 'none';
            document.getElementById('searchrevision').style.display = 'none';
            titre.innerHTML = 'Révisions avant Devoirs'; 
        }
    };

    /****************
    HELPER FUNCTION
    *****************/
    /**1: Get Current Date in JavaScript */
    function getCurrentDate(){
        // Date object
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1;
        var yyyy = today.getFullYear();
        if(dd<10){
            dd='0'+dd;
        }
        if(mm<10){
            mm='0'+mm;
        }
        // Current Date
        var date = yyyy+'-'+mm+'-'+dd;
        return date;
    }

    window.addEventListener('load', () =>{
        new taskRevision();
    })
}) ();