import CrudNotes from "./CrudNotes";
import AddNotes from "./AddNotes";
import EditNotes from "./EditNotes";
import DeleteNotes from "./DeleteNotes";
import SearchNotes from "./SearchNotes";

function Notes() {
    return ( 
        <div id="notes">
            <CrudNotes />
            <AddNotes />
            <EditNotes />
            <DeleteNotes />
            <SearchNotes />
        </div>
    );
}

export default Notes;