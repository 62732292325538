import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './routings'; //routing between pages

import App from './App';
import Accueil from './accueil/Accueil';
import Detail from './details/Detail';
import Demarrage from './demarrage/Demarrage';

//inscription folder
import Inscription from './inscription/Inscription';
//tableaubord folder
import TableauBord from './tableaubord/TableauBord';
//configuration folder
import Configuration from './configuration/Configuration';

//configuration/etablissement folder
import Etablissement from './configuration/etablissement/Etablissement';

//configuration/classe folder
import Classe from './configuration/classe/Classe';

//configuration/semaine folder
import Semaine from './configuration/semaine/Semaine';

//configuration/annee folder
import Annee from './configuration/annee/Annee';

//configuration/matiere folder
import Matiere from './configuration/matiere/Matiere';

//emploidutemps folder
import EmploiDuTemps from './emploidutemps/EmploiDuTemps';

//exercice folder
import Exercice from './exercice/Exercice';

//pratique folder
import Pratique from './exercice/pratique/Pratique';

//tache folder
import Tache from './exercice/tache/Tache';

//revision folder
import Revision from './exercice/revision/Revision';

//recherche folder
import Recherche from './exercice/recherche/Recherche';

//programme folder
import Programme from './programme/Programme';

//notes folder
import Notes from './notes/Notes';

//moyenne folder
import Moyenne from './moyenne/Moyenne';

//moymatiere folder
import MoyMatiere from './moyenne/moymatiere/MoyMatiere';

//moygenerale folder
import MoyGenerale from './moyenne/moygenerale/MoyGenerale';

//moygenerale folder
import MoyAnnuelle from './moyenne/moyannuelle/MoyAnnuelle';

//moteur folder
import Moteur from './moteur/Moteur';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <div>
    <Accueil />
    <Detail />
    <App />
    <Demarrage />
    <Inscription />
    <TableauBord />

    <Configuration />
    <Etablissement />
    <Classe />
    <Semaine />
    <Annee />
    <Matiere />

    <EmploiDuTemps />

    <Exercice />
    <Pratique />
    <Tache />
    <Revision />
    <Recherche />

    <Programme />

    <Notes />

    <Moyenne />
    <MoyMatiere />
    <MoyGenerale />
    <MoyAnnuelle />

    <Moteur />

  </div>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

/***********************************
JavaScript for handling the install
*************************************/
let deferredPrompt;
const addBtn = document.querySelector('#installerBtn');
addBtn.style.display = 'none';

window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
  // Stash the event so it can be triggered later.
  deferredPrompt = e;
  // Update UI to notify the user they can add to home screen
  addBtn.style.display = 'block';
  addBtn.addEventListener('click', (e) => {
    // hide our user interface that shows our A2HS button
    addBtn.style.display = 'none';
    // Show the prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted'){
        console.log('User accepted the A2HS prompt');
      }else{
        console.log('User dismissed the A2HS prompt');
      }
      deferredPrompt = null;
    });
  });
});
