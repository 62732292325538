import CrudMatiere from "./CrudMatiere";
import AddMatiere from "./AddMatiere";
import EditMatiere from "./EditMatiere";
import DeleteMatiere from "./DeleteMatiere";
import SearchMatiere from "./SearchMatiere";

function Matiere() {
    return ( 
        <div id="matiere">
            <CrudMatiere />
            <AddMatiere />
            <EditMatiere />
            <DeleteMatiere />
            <SearchMatiere />
        </div>
    );
}

export default Matiere;