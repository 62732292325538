import './notes.css';
import './pouchnotes';
import DateInput from '../components/DateInput';
import NumberInput from '../components/NumberInput';
import ModifierButton from '../components/ModifierButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function EditNotes() {
    return ( 
        <div id="editnotes">
            <KeyboardBackspace style={prevArrows} id="preveditnotesBtn_1" />
            <br/><br/>
            <form>
                <div id="formeditnotes" style={scroller}>
                    <br/>
                    <div id="changecalendriernotes">
                        <label style={labelStyle}>Sélection Calendrier Scolaire</label>
                        <div id="editcalendriernotes"></div>
                    </div>
                    <br/>
                    <div id="changematierenotes">
                        <label style={labelStyle}>Sélection Matière</label>
                        <div id="editmatierenotes"></div>
                    </div>
                    <br/>
                    <div id="changetypenotes">
                        <label style={labelStyle}>Type Devoir</label>
                        <select id="seledittypenotes">
                            <option value="">Sélectionner</option>
                            <option value='Devoir'>Devoir</option>
                            <option value="Interrogation">Interrogation</option>
                        </select>
                    </div>
                    <br/>
                    <div id="changeprogrammenotes">
                        <label id="editmatiereprogrammenotes" style={labelStyle}>Trimestre+Matière</label>
                        <br/><br/>
                        <table className='thin_table_config'>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Type</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody id='editProgrammeNotesTableBody'></tbody>
                        </table>
                    </div>
                    <br/>
                    <div id="changedatenotes">
                        <label style={labelStyle}>Date Exécution</label>
                        <DateInput idinput ="editecheancenotes" date="date" read="readOnly" />
                    </div>
                    <br/>
                    <div id="changeobtenunotes">
                        <label style={labelStyle}>Note Obtenue</label>
                        <NumberInput idinput ="editobtenuenotes" number="number" />
                    </div>
                    <br/>
                    <div id="changecoefficientnotes">
                        <label style={labelStyle}>Coefficient</label>
                        <NumberInput idinput ="editcoefficientnotes" number="number" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editselectcalendriernotes" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editselectmatierenotes" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editidnotes" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editidreferencenotes" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editcodeusernotes" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editeffectuenotes" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editdatenotes" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editstatutnotes" type="text" />
                    </div>
                    <br/>
                    <div id="changenotes">
                        <ModifierButton 
                            idmodifierbutton="modifiernotesBtn"
                            modifier="Modifier"
                        />
                    </div>
                    <br/><br/>
                </div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default EditNotes;