import CrudSemaine from "./CrudSemaine";
import AddSemaine from "./AddSemaine";
import EditSemaine from "./EditSemaine";
import DeleteSemaine from "./DeleteSemaine";
import SearchSemaine from "./SearchSemaine";

function Semaine() {
    return ( 
       <div id="semaine">
           <CrudSemaine />
           <AddSemaine />
           <EditSemaine />
           <DeleteSemaine />
           <SearchSemaine />
       </div> 
    );
}

export default Semaine;