/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import PouchDB from 'pouchdb';
import PouchFind from 'pouchdb-find';

(function(){
    var taskConfigAnnee = function(){
        /*****************************************************
        ENABLING POUCHDB WITHOUT SYNC TO REMOTE COUCHDB
        ******************************************************/
        //SET UP POUCHDB AND CREATE THE DATABASE 
        const db = new PouchDB('suclu');
        PouchDB.plugin(PouchFind);

        /***************
        crudanneeBtn
        *****************/
        /**nav previous page button*/
        document.getElementById('prevcrudanneeBtn_1').addEventListener('click', prevcrudannee1BtnChange);
        async function prevcrudannee1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'block';
            document.getElementById('configurer').style.display = 'block';
            document.getElementById('annee').style.display = 'none';
            document.getElementById('crudannee').style.display = 'none';
            titre.innerHTML = 'Configuration App'; 
        }

        /***************************
        addanneeBtn
        *****************************/
        var anneeScolaire = ''; //global to assign to seladdannee
        document.getElementById('addanneeBtn').addEventListener('click', addanneeBtnChange);
        async function addanneeBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'block';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('annee').style.display = 'block';
            document.getElementById('crudannee').style.display = 'none';
            document.getElementById('addannee').style.display = 'block';
            document.getElementById('editannee').style.display = 'none';
            document.getElementById('deleteannee').style.display = 'none';
            document.getElementById('searchannee').style.display = 'none';
            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('addselectionannee').style.display = 'block';
            document.getElementById('addselectiontrimestre').style.display = 'none';
            document.getElementById('addselectionsemestre').style.display = 'none';
            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            document.getElementById('adddebutannee').style.display = 'none';
            document.getElementById('addfinannee').style.display = 'none';
            document.getElementById('saveannee').style.display = 'none';
            titre.innerHTML = 'Enregistrer Calendrier Année'; 
        }
        /**nav previous page button*/
        document.getElementById('prevaddanneeBtn_1').addEventListener('click', prevaddannee1BtnChange);
        async function prevaddannee1BtnChange(e){
            e.preventDefault();

            document.getElementById('seladdannee').value = '';
            
            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('etablissement').style.display = 'none';
            document.getElementById('classe').style.display = 'none';
            document.getElementById('semaine').style.display = 'none';
            document.getElementById('annee').style.display = 'block';
            document.getElementById('crudannee').style.display = 'block';
            document.getElementById('addannee').style.display = 'none';
            document.getElementById('editannee').style.display = 'none';
            document.getElementById('deleteannee').style.display = 'none';
            document.getElementById('searchannee').style.display = 'none';
            titre.innerHTML = 'Configuration Calendrier Année';
        }
        /***************************
        seladdannee
        *****************************/
        document.getElementById('seladdannee').addEventListener('change', seladdanneeChange);
        async function seladdanneeChange(e){
            e.preventDefault();

            //document.getElementById('seladdtrimannee').value = '';
            //getElementById('seladdsemannee').value = '';

            var userCodeStr = document.getElementById('userCode').innerText;
            var seladdanneeStr = document.getElementById('seladdannee').value;

            var addstatutanneeStr = 'Oui';
            var anneedivisionStr = '';
            var docsAnnee = [];

            if(!userCodeStr){
                alert('Connectez-vous à votre Compte avant de continuer');
            }else if(!seladdanneeStr){
                alert('Sélectionner une Subdivision Année Scolaire avant de Continuer');
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('addselectionannee').style.display = 'block';
                document.getElementById('addselectiontrimestre').style.display = 'none';
                document.getElementById('addselectionsemestre').style.display = 'none';
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('adddebutannee').style.display = 'none';
                document.getElementById('addfinannee').style.display = 'none';
                document.getElementById('saveannee').style.display = 'none';
            }else{
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: ['anneestatut', 'inscritcode']
                        }
                    });
                    result = await db.find({
                        selector: {
                            anneestatut: addstatutanneeStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsAnnee  = result.docs;
                    var resultatAnnee = docsAnnee.length;
                    if(resultatAnnee === 0){
                        if(seladdanneeStr === 'Trimestre'){
                            document.getElementById('addselectanneedivision').value = seladdanneeStr;
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('addselectionannee').style.display = 'block';
                            document.getElementById('addselectiontrimestre').style.display = 'block';
                            document.getElementById('addselectionsemestre').style.display = 'none';
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('adddebutannee').style.display = 'none';
                            document.getElementById('addfinannee').style.display = 'none';
                            document.getElementById('saveannee').style.display = 'none';
                        }else if(seladdanneeStr === 'Semestre'){
                            document.getElementById('addselectanneedivision').value = seladdanneeStr;
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('addselectionannee').style.display = 'block';
                            document.getElementById('addselectiontrimestre').style.display = 'none';
                            document.getElementById('addselectionsemestre').style.display = 'block';
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('adddebutannee').style.display = 'none';
                            document.getElementById('addfinannee').style.display = 'none';
                            document.getElementById('saveannee').style.display = 'none';
                        }
                    }else{
                        for(let i=0; i<1; i++){
                            anneedivisionStr = docsAnnee[i].anneedivision;
                        }
                        if(anneedivisionStr === seladdanneeStr){
                            if(seladdanneeStr === 'Trimestre'){
                                document.getElementById('addselectanneedivision').value = seladdanneeStr;
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('addselectionannee').style.display = 'block';
                                document.getElementById('addselectiontrimestre').style.display = 'block';
                                document.getElementById('addselectionsemestre').style.display = 'none';
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('adddebutannee').style.display = 'none';
                                document.getElementById('addfinannee').style.display = 'none';
                                document.getElementById('saveannee').style.display = 'none';
                            }else if(seladdanneeStr === 'Semestre'){
                                document.getElementById('addselectanneedivision').value = seladdanneeStr;
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('addselectionannee').style.display = 'block';
                                document.getElementById('addselectiontrimestre').style.display = 'none';
                                document.getElementById('addselectionsemestre').style.display = 'block';
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('adddebutannee').style.display = 'none';
                                document.getElementById('addfinannee').style.display = 'none';
                                document.getElementById('saveannee').style.display = 'none';
                            }
                        }
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }
        /***************************
        seladdtrimannee
        *****************************/
        document.getElementById('seladdtrimannee').addEventListener('change', seladdtrimanneeChange);
        async function seladdtrimanneeChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var seladdanneeStr = document.getElementById('seladdannee').value;

            var addstatutanneeStr = 'Oui';
            var docsTrimestre = [];


            var seladdtrimanneeStr = document.getElementById('seladdtrimannee').value;
            if(!seladdtrimanneeStr){
                alert('Sélectionner un Trimestre avant de Continuer');
                document.getElementById('seladdtrimannee').value = '';
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('addselectionannee').style.display = 'block';
                document.getElementById('addselectiontrimestre').style.display = 'block';
                document.getElementById('addselectionsemestre').style.display = 'none';
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('adddebutannee').style.display = 'none';
                document.getElementById('addfinannee').style.display = 'none';
                document.getElementById('saveannee').style.display = 'none';
            }else{
                //check if this trimestre already exists
                try {
                    let resultTrimestre = await db.createIndex({
                        index: {
                            fields: ['anneestatut', 'inscritcode', 'anneedivision', 'anneescolaire']
                        }
                    });
                    resultTrimestre = await db.find({
                        selector: {
                            anneestatut: addstatutanneeStr,
                            inscritcode: userCodeStr,
                            anneedivision: seladdanneeStr,
                            anneescolaire: seladdtrimanneeStr
                        }
                    });
                    docsTrimestre  = resultTrimestre.docs;
                    var resultatTrimestre = docsTrimestre.length;
                    if(resultatTrimestre === 0){
                        document.getElementById('addselectanneescolaire').value = seladdtrimanneeStr;
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('addselectionannee').style.display = 'block';
                        document.getElementById('addselectiontrimestre').style.display = 'block';
                        document.getElementById('addselectionsemestre').style.display = 'none';
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('adddebutannee').style.display = 'block';
                        document.getElementById('addfinannee').style.display = 'block';
                        document.getElementById('saveannee').style.display = 'block';
                    }else{
                        alert(seladdtrimanneeStr+' déjà Enregistré. Selectionner Trimestre Suivant');
                        document.getElementById('addselectanneescolaire').value = '';
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('addselectionannee').style.display = 'block';
                        document.getElementById('addselectiontrimestre').style.display = 'block';
                        document.getElementById('addselectionsemestre').style.display = 'none';
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('adddebutannee').style.display = 'none';
                        document.getElementById('addfinannee').style.display = 'none';
                        document.getElementById('saveannee').style.display = 'none';
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }
        /***************************
        seladdsemannee
        *****************************/
        document.getElementById('seladdsemannee').addEventListener('change', seladdsemanneeChange);
        async function seladdsemanneeChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var seladdanneeStr = document.getElementById('seladdannee').value;

            var addstatutanneeStr = 'Oui';
            var docsSemestre = [];

            var seladdsemanneeStr = document.getElementById('seladdsemannee').value;
            if(!seladdsemanneeStr){
                alert('Sélectionner un Semestre avant de Continuer');
                document.getElementById('seladdsemannee').value = '';
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('addselectionannee').style.display = 'block';
                document.getElementById('addselectiontrimestre').style.display = 'none';
                document.getElementById('addselectionsemestre').style.display = 'block';
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('adddebutannee').style.display = 'none';
                document.getElementById('addfinannee').style.display = 'none';
                document.getElementById('saveannee').style.display = 'none';
            }else{
                //check if this semestre already exists
                try {
                    let resultSemestre = await db.createIndex({
                        index: {
                            fields: ['anneestatut', 'inscritcode', 'anneedivision', 'anneescolaire']
                        }
                    });
                    resultSemestre = await db.find({
                        selector: {
                            anneestatut: addstatutanneeStr,
                            inscritcode: userCodeStr,
                            anneedivision: seladdanneeStr,
                            anneescolaire: seladdsemanneeStr
                        }
                    });
                    docsSemestre  = resultSemestre.docs;
                    var resultatSemestre = docsSemestre.length;
                    if(resultatSemestre === 0){
                        document.getElementById('addselectanneescolaire').value = seladdsemanneeStr;
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('addselectionannee').style.display = 'block';
                        document.getElementById('addselectiontrimestre').style.display = 'none';
                        document.getElementById('addselectionsemestre').style.display = 'block';
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('adddebutannee').style.display = 'block';
                        document.getElementById('addfinannee').style.display = 'block';
                        document.getElementById('saveannee').style.display = 'block';   
                    }else{
                        alert(seladdsemanneeStr+' déjà Enregistré. Selectionner Semestre Suivant');
                        document.getElementById('addselectanneescolaire').value = '';
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('addselectionannee').style.display = 'block';
                        document.getElementById('addselectiontrimestre').style.display = 'none';
                        document.getElementById('addselectionsemestre').style.display = 'block';
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('adddebutannee').style.display = 'none';
                        document.getElementById('addfinannee').style.display = 'none';
                        document.getElementById('saveannee').style.display = 'none';  
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }
        /***************************
        enregistreranneeBtn
        *****************************/
        document.getElementById('enregistreranneeBtn').addEventListener('click', enregistreranneeBtnChange);
        async function enregistreranneeBtnChange(e){
            e.preventDefault();

            var usercodeStr = document.getElementById('userCode').innerText;
            var addselectanneedivisionStr = document.getElementById('addselectanneedivision').value;
            var addselectanneescolaireStr = document.getElementById('addselectanneescolaire').value;
            var adddatedebutanneeStr = document.getElementById('adddatedebutannee').value;
            var adddatefinanneeStr = document.getElementById('adddatefinannee').value;
            var datejourStr = getCurrentDate();
            var addstatutanneeStr = 'Oui';

            var docsAnnee = [];

            if(!usercodeStr){
                alert("Identifiez-vous avant de continuer");
            }else if(!addselectanneedivisionStr){
                alert("Sélectionner Calendrier Scolaire");
            }else if(!addselectanneescolaireStr){
                alert("Sélectionner Calendrier"); 
            }else if(!adddatedebutanneeStr){
                alert("Sélectionner Début Date");
            }else if(!adddatefinanneeStr){
                alert("Sélectionner Fin Date");
            }else{
                //check if this day has already been registered
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: [
                                'inscritcode', 
                                'anneedivision', 
                                'anneescolaire', 
                                'anneestatut'
                            ]
                        }
                    });
                    result = await db.find({
                        selector: {
                            inscritcode: usercodeStr,
                            anneedivision: addselectanneedivisionStr,
                            anneescolaire: addselectanneescolaireStr,
                            anneestatut: addstatutanneeStr
                        }
                    });
                    docsAnnee  = result.docs;
                    var resultatAnnee = docsAnnee.length;
                    if(resultatAnnee === 0){
                        //Create the document _id and _rev.
                        var now = Date.now();
                        var id = 'annee_' + now;
                        // Add the document to the database.
                        var response = await db.put({
                            _id: id,
                            inscritcode: usercodeStr,
                            anneedivision: addselectanneedivisionStr,
                            anneescolaire: addselectanneescolaireStr,
                            anneedebut: adddatedebutanneeStr,
                            anneefin: adddatefinanneeStr,
                            anneedate: datejourStr,
                            anneestatut: addstatutanneeStr,
                        });
                        // check if document inserted and synced
                        var doc = await db.get(response.id);
                        if(doc !== ''){
                            alert('Enregistré. Sélectionner Suivant');
                            document.getElementById('adddatedebutannee').value = '';
                            document.getElementById('adddatefinannee').value = '';
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('adddebutannee').style.display = 'none';
                            document.getElementById('addfinannee').style.display = 'none';
                            document.getElementById('saveannee').style.display = 'none'; 
                        }else{
                            alert('Echec Enregistrement de ce Jour. Reprendre');
                        }
                    }else{
                        alert('Enregistrement déjà Effectué. Selectionner Calendrier Suivant');
                        document.getElementById('addselectanneescolaire').value = '';
                        document.getElementById('adddatedebutannee').value = '';
                        document.getElementById('adddatefinannee').value = '';
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('adddebutannee').style.display = 'none';
                        document.getElementById('addfinannee').style.display = 'none';
                        document.getElementById('saveannee').style.display = 'none'; 
                    }
                } catch (error) {
                   alert('Erreur Exécution'); 
                }
            }
        }

        /***************************
        editanneeBtn
        *****************************/
        document.getElementById('editanneeBtn').addEventListener('click', editanneeBtnChange);
        async function editanneeBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var editstatutanneeStr = 'Oui';
            var anneedivisionStr = '';

            document.getElementById('seledittrimannee').value = '';
            document.getElementById('seleditsemannee').value = '';

            var docsEditAnnee = [];

            if(!userCodeStr){
                alert('Connectez-vous à votre Compte avant de continuer');
            }else{
                //check if user has alread selected annee scolaire
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: ['anneestatut', 'inscritcode']
                        }
                    });
                    result = await db.find({
                        selector: {
                            anneestatut: editstatutanneeStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsEditAnnee  = result.docs;
                    var resultatEditAnnee = docsEditAnnee.length; 
                    if(resultatEditAnnee === 0){
                        alert('Aucun Calendrier Année Scolaire Enregistré');
                    }else{
                        for(let i=0; i<1; i++){
                            anneedivisionStr = docsEditAnnee[i].anneedivision;
                        }
                        if(anneedivisionStr === 'Trimestre'){
                            anneeScolaire = 'Trimestre';
                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');

                            document.getElementById('demarrage').style.display = 'none';
                            document.getElementById('tableaubord').style.display = 'none';
                            document.getElementById('dashboard').style.display = 'none';
                            document.getElementById('configurations').style.display = 'block';
                            document.getElementById('configurer').style.display = 'none';
                            document.getElementById('annee').style.display = 'block';
                            document.getElementById('crudannee').style.display = 'none';
                            document.getElementById('addannee').style.display = 'none';
                            //hide or show some components
                            document.getElementById('editselectionannee').style.display = 'none';
                            document.getElementById('editselectiontrimestre').style.display = 'block';
                            document.getElementById('editselectionsemestre').style.display = 'none';
                            document.getElementById('editdebutannee').style.display = 'block';
                            document.getElementById('editfinannee').style.display = 'block';
                            document.getElementById('changeannee').style.display = 'block';
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('editannee').style.display = 'block';
                            document.getElementById('deleteannee').style.display = 'none';
                            document.getElementById('searchannee').style.display = 'none';
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('editdebutannee').style.display = 'none';
                            document.getElementById('editfinannee').style.display = 'none';
                            document.getElementById('changeannee').style.display = 'none';
                            titre.innerHTML = 'Modifier Calendrier Année'; 
                        }else{
                            anneeScolaire = 'Semestre';
                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');

                            document.getElementById('demarrage').style.display = 'none';
                            document.getElementById('tableaubord').style.display = 'none';
                            document.getElementById('dashboard').style.display = 'none';
                            document.getElementById('configurations').style.display = 'block';
                            document.getElementById('configurer').style.display = 'none';
                            document.getElementById('annee').style.display = 'block';
                            document.getElementById('crudannee').style.display = 'none';
                            document.getElementById('addannee').style.display = 'none';
                            //hide or show some components
                            document.getElementById('editselectionannee').style.display = 'none';
                            document.getElementById('editselectiontrimestre').style.display = 'none';
                            document.getElementById('editselectionsemestre').style.display = 'block';
                            document.getElementById('editdebutannee').style.display = 'block';
                            document.getElementById('editfinannee').style.display = 'block';
                            document.getElementById('changeannee').style.display = 'block';
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('editannee').style.display = 'block';
                            document.getElementById('deleteannee').style.display = 'none';
                            document.getElementById('searchannee').style.display = 'none';
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('editdebutannee').style.display = 'none';
                            document.getElementById('editfinannee').style.display = 'none';
                            document.getElementById('changeannee').style.display = 'none';
                            titre.innerHTML = 'Modifier Calendrier Année'; 
                        }
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }
        /**nav previous page button*/
        document.getElementById('preveditanneeBtn_1').addEventListener('click', preveditannee1BtnChange);
        async function preveditannee1BtnChange(e){
            e.preventDefault();
            
            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('etablissement').style.display = 'none';
            document.getElementById('classe').style.display = 'none';
            document.getElementById('semaine').style.display = 'none';
            document.getElementById('annee').style.display = 'block';
            document.getElementById('crudannee').style.display = 'block';
            document.getElementById('addannee').style.display = 'none';
            document.getElementById('editannee').style.display = 'none';
            document.getElementById('deleteannee').style.display = 'none';
            document.getElementById('searchannee').style.display = 'none';
            titre.innerHTML = 'Configuration Calendrier Année';
        }
        //seledittrimannee event handler
        document.getElementById('seledittrimannee').addEventListener('change', seledittrimanneeBtnChange);
        async function seledittrimanneeBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var seledittrimanneeStr = document.getElementById('seledittrimannee').value;
            var addstatutanneeStr = 'Oui';
            var anneeIdStr,inscritCodeStr,anneedivisionStr,anneeDebutStr,anneeFinStr,anneeDateStr,anneeStatutStr = '';

            var docsEditAnnee = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: [
                            'anneestatut', 
                            'inscritcode', 
                            'anneedivision', 
                            'anneescolaire'
                        ]
                    }
                });
                result = await db.find({
                    selector: {
                        anneestatut: addstatutanneeStr,
                        inscritcode: userCodeStr, 
                        anneedivision: anneeScolaire, 
                        anneescolaire: seledittrimanneeStr
                    }
                });
                docsEditAnnee  = result.docs;
                var resultatEditAnnee = docsEditAnnee.length; 
                if(resultatEditAnnee === 0){
                    alert('Sélectionner un Trimestre avant de Continuer');
                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('editdebutannee').style.display = 'none';
                    document.getElementById('editfinannee').style.display = 'none';
                    document.getElementById('changeannee').style.display = 'none';
                }else{
                    for(let i=0; i<docsEditAnnee.length; i++){
                        anneeIdStr = docsEditAnnee[i]._id;
                        inscritCodeStr = docsEditAnnee[i].inscritcode;
                        anneedivisionStr = docsEditAnnee[i].anneedivision;
                        anneeDebutStr = docsEditAnnee[i].anneedebut;
                        anneeFinStr = docsEditAnnee[i].anneefin;
                        anneeDateStr = docsEditAnnee[i].anneedate;
                        anneeStatutStr = docsEditAnnee[i].anneestatut;
                    }
                    document.getElementById('editidannee').value = anneeIdStr;
                    document.getElementById('editcodeanneeuser').value = inscritCodeStr;
                    document.getElementById('editanneedivision').value = anneedivisionStr;
                    document.getElementById('editdatedebutannee').value = anneeDebutStr;
                    document.getElementById('editdatefinannee').value = anneeFinStr;
                    document.getElementById('editanneedate').value = anneeDateStr;
                    document.getElementById('editanneestatut').value = anneeStatutStr;
                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('editdebutannee').style.display = 'block';
                    document.getElementById('editfinannee').style.display = 'block';
                    document.getElementById('changeannee').style.display = 'block';
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        //seleditsemannee event handler
        document.getElementById('seleditsemannee').addEventListener('change', seleditsemanneeBtnChange);
        async function seleditsemanneeBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var seleditsemanneeStr = document.getElementById('seleditsemannee').value;
            var addstatutanneeStr = 'Oui';
            var anneeIdStr,inscritCodeStr,anneedivisionStr,anneeDebutStr,anneeFinStr,anneeDateStr,anneeStatutStr = '';

            var docsEditAnnee = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: [
                            'anneestatut', 
                            'inscritcode', 
                            'anneedivision', 
                            'anneescolaire'
                        ]
                    }
                });
                result = await db.find({
                    selector: {
                        anneestatut: addstatutanneeStr,
                        inscritcode: userCodeStr, 
                        anneedivision: anneeScolaire, 
                        anneescolaire: seleditsemanneeStr
                    }
                });
                docsEditAnnee  = result.docs;
                var resultatEditAnnee = docsEditAnnee.length; 
                if(resultatEditAnnee === 0){
                    alert('Sélectionner un Semestre avant de Continuer');
                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('editdebutannee').style.display = 'none';
                    document.getElementById('editfinannee').style.display = 'none';
                    document.getElementById('changeannee').style.display = 'none';
                }else{
                    for(let i=0; i<docsEditAnnee.length; i++){
                        anneeIdStr = docsEditAnnee[i]._id;
                        inscritCodeStr = docsEditAnnee[i].inscritcode;
                        anneedivisionStr = docsEditAnnee[i].anneedivision;
                        anneeDebutStr = docsEditAnnee[i].anneedebut;
                        anneeFinStr = docsEditAnnee[i].anneefin;
                        anneeDateStr = docsEditAnnee[i].anneedate;
                        anneeStatutStr = docsEditAnnee[i].anneestatut;
                    }
                    document.getElementById('editidannee').value = anneeIdStr;
                    document.getElementById('editcodeanneeuser').value = inscritCodeStr;
                    document.getElementById('editanneedivision').value = anneedivisionStr;
                    document.getElementById('editdatedebutannee').value = anneeDebutStr;
                    document.getElementById('editdatefinannee').value = anneeFinStr;
                    document.getElementById('editanneedate').value = anneeDateStr;
                    document.getElementById('editanneestatut').value = anneeStatutStr;
                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('editdebutannee').style.display = 'block';
                    document.getElementById('editfinannee').style.display = 'block';
                    document.getElementById('changeannee').style.display = 'block';
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        /***************************
        modifieranneeBtn
        *****************************/
        document.getElementById('modifieranneeBtn').addEventListener('click', modifieranneeBtnChange);
        async function modifieranneeBtnChange(e){
            e.preventDefault();

            var editSelectAnneeStr = '';
            var anneeScolaireStr = anneeScolaire;
            if(anneeScolaireStr === 'Trimestre'){
                editSelectAnneeStr = document.getElementById('seledittrimannee').value;
            }else{
                editSelectAnneeStr = document.getElementById('seleditsemannee').value;
            }
            var editidanneeStr = document.getElementById('editidannee').value;
            var editcodeanneeuserStr = document.getElementById('editcodeanneeuser').value;
            var editanneedivisionStr = document.getElementById('editanneedivision').value;
            var editdatedebutanneeStr = document.getElementById('editdatedebutannee').value;
            var editdatefinanneeStr = document.getElementById('editdatefinannee').value;
            var editanneedateStr = document.getElementById('editanneedate').value;
            var editanneestatutStr = document.getElementById('editanneestatut').value;


            if(!editcodeanneeuserStr){
                alert("Identifiez-vous avant de continuer");
            }else if(!editSelectAnneeStr){
                alert("Sélectionner Division Calendrier");
            }else if(!editdatedebutanneeStr){
                alert("Sélectionner Début Date");
            }else if(!editdatefinanneeStr){
                alert("Sélectionner Fin Date");
            }else{
                try {
                    // Retrieve the document to get the latest revision
                    var id = editidanneeStr;
                    var doc = await db.get(id);
                    // Make changes
                    doc.inscritcode = editcodeanneeuserStr;
                    doc.anneedivision = editanneedivisionStr;
                    doc.anneescolaire = editSelectAnneeStr;
                    doc.anneedebut = editdatedebutanneeStr;
                    doc.anneefin = editdatefinanneeStr;
                    doc.anneedate = editanneedateStr;
                    doc.anneestatut = editanneestatutStr;
                    // Put the document, saving it as a new revision
                    var response = await db.put(doc);
                    // Get the document we change and update the UI.
                    var docEdit = await db.get(response.id);
                    if(docEdit !== ''){
                        alert("Calendrier Année Scolaire Modifié avec Succès");
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('etablissement').style.display = 'none';
                        document.getElementById('classe').style.display = 'none';
                        document.getElementById('semaine').style.display = 'none';
                        document.getElementById('annee').style.display = 'block';
                        document.getElementById('crudannee').style.display = 'block';
                        document.getElementById('addannee').style.display = 'none';
                        document.getElementById('editannee').style.display = 'none';
                        document.getElementById('deleteannee').style.display = 'none';
                        document.getElementById('searchannee').style.display = 'none';
                        titre.innerHTML = 'Configuration Calendrier Année';
                    }else{
                        alert("Erreur Modification. Reprendre");
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'block';
                        document.getElementById('configurer').style.display = 'block';
                        document.getElementById('annee').style.display = 'none';
                        titre.innerHTML = 'Configuration App';
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }

        /***************************
        deleteanneeBtn
        *****************************/
        document.getElementById('deleteanneeBtn').addEventListener('click', deleteanneeBtnChange);
        async function deleteanneeBtnChange(e){
            e.preventDefault();

            document.getElementById('seldeletetrimannee').value = '';
            document.getElementById('seldeletesemannee').value = '';

            var userCodeStr = document.getElementById('userCode').innerText;
            var deletestatutanneeStr = 'Oui';
            var anneedivisionStr = '';

            var docsDeleteAnnee = [];

            if(!userCodeStr){
                alert('Connectez-vous à votre Compte avant de continuer');
            }else{
                //check if user has alread selected annee scolaire
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: ['anneestatut', 'inscritcode']
                        }
                    });
                    result = await db.find({
                        selector: {
                            anneestatut: deletestatutanneeStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsDeleteAnnee  = result.docs;
                    var resultatDeleteAnnee = docsDeleteAnnee.length;
                    if(resultatDeleteAnnee === 0){
                        alert('Aucun Calendrier Année Scolaire Enregistré');
                    }else{
                        for(let i=0; i<1; i++){
                            anneedivisionStr = docsDeleteAnnee[i].anneedivision;
                        }
                        if(anneedivisionStr === 'Trimestre'){
                            anneeScolaire = 'Trimestre';
                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');

                            document.getElementById('demarrage').style.display = 'none';
                            document.getElementById('tableaubord').style.display = 'none';
                            document.getElementById('dashboard').style.display = 'none';
                            document.getElementById('configurations').style.display = 'block';
                            document.getElementById('configurer').style.display = 'none';
                            document.getElementById('annee').style.display = 'block';
                            document.getElementById('crudannee').style.display = 'none';
                            document.getElementById('addannee').style.display = 'none';
                            //hide or show some components
                            document.getElementById('deleteselectionannee').style.display = 'none';
                            document.getElementById('deleteselectiontrimestre').style.display = 'block';
                            document.getElementById('deleteselectionsemestre').style.display = 'none';
                            document.getElementById('deletedebutannee').style.display = 'block';
                            document.getElementById('deletefinannee').style.display = 'block';
                            document.getElementById('cancelannee').style.display = 'none';
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('editannee').style.display = 'none';
                            document.getElementById('deleteannee').style.display = 'block';
                            document.getElementById('searchannee').style.display = 'none';
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('deletedebutannee').style.display = 'none';
                            document.getElementById('deletefinannee').style.display = 'none';
                            document.getElementById('cancelannee').style.display = 'none';
                            titre.innerHTML = 'Supprimer Calendrier Année'; 
                        }else{
                            anneeScolaire = 'Semestre';
                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');

                            document.getElementById('demarrage').style.display = 'none';
                            document.getElementById('tableaubord').style.display = 'none';
                            document.getElementById('dashboard').style.display = 'none';
                            document.getElementById('configurations').style.display = 'block';
                            document.getElementById('configurer').style.display = 'none';
                            document.getElementById('annee').style.display = 'block';
                            document.getElementById('crudannee').style.display = 'none';
                            document.getElementById('addannee').style.display = 'none';
                            //hide or show some components
                            document.getElementById('deleteselectionannee').style.display = 'none';
                            document.getElementById('deleteselectiontrimestre').style.display = 'none';
                            document.getElementById('deleteselectionsemestre').style.display = 'block';
                            document.getElementById('deletedebutannee').style.display = 'block';
                            document.getElementById('deletefinannee').style.display = 'block';
                            document.getElementById('cancelannee').style.display = 'none';
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('editannee').style.display = 'none';
                            document.getElementById('deleteannee').style.display = 'block';
                            document.getElementById('searchannee').style.display = 'none';
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('deletedebutannee').style.display = 'none';
                            document.getElementById('deletefinannee').style.display = 'none';
                            document.getElementById('cancelannee').style.display = 'none';
                            titre.innerHTML = 'Supprimer Calendrier Année'; 
                        }
                    }
                } catch (error) {
                   alert('Erreur Exécution'); 
                }
            }
        }
        /**nav previous page button*/
        document.getElementById('prevdeleteanneeBtn_1').addEventListener('click', prevdeleteannee1BtnChange);
        async function prevdeleteannee1BtnChange(e){
            e.preventDefault();
            
            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('etablissement').style.display = 'none';
            document.getElementById('classe').style.display = 'none';
            document.getElementById('semaine').style.display = 'none';
            document.getElementById('annee').style.display = 'block';
            document.getElementById('crudannee').style.display = 'block';
            document.getElementById('addannee').style.display = 'none';
            document.getElementById('editannee').style.display = 'none';
            document.getElementById('deleteannee').style.display = 'none';
            document.getElementById('searchannee').style.display = 'none';
            titre.innerHTML = 'Configuration Calendrier Année';
        }
        //seldeletetrimannee event handler
        document.getElementById('seldeletetrimannee').addEventListener('change', seldeletetrimanneeBtnChange);
        async function seldeletetrimanneeBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var seldeletetrimanneeStr = document.getElementById('seldeletetrimannee').value;
            var deletestatutanneeStr = 'Oui';
            var anneeIdStr,anneeDebutStr,anneeFinStr = '';

            var docsDeleteAnnee = [];
            try {
                //check if this trimestre exists in document programme_
                var statutProgrammeStr = 'Oui';
                var docsProgramme = [];
                try {
                    let resultProgramme = await db.createIndex({
                        index: {
                            fields: [
                                'inscritcode', 
                                'anneescolaire', 
                                'programmestatut'
                            ]
                        }
                    });
                    resultProgramme = await db.find({
                        selector: {
                            inscritcode: userCodeStr,
                            anneescolaire: seldeletetrimanneeStr,
                            programmestatut: statutProgrammeStr,
                        }
                    });
                    docsProgramme  = resultProgramme.docs;
                    var resultatProgramme = docsProgramme.length;
                    if(resultatProgramme === 0){
                        let result = await db.createIndex({
                            index: {
                                fields: [
                                    'anneestatut', 
                                    'inscritcode', 
                                    'anneedivision', 
                                    'anneescolaire'
                                ]
                            }
                        });
                        result = await db.find({
                            selector: {
                                anneestatut: deletestatutanneeStr,
                                inscritcode: userCodeStr, 
                                anneedivision: anneeScolaire, 
                                anneescolaire: seldeletetrimanneeStr
                            }
                        });
                        docsDeleteAnnee  = result.docs;
                        var resultatDeleteAnnee = docsDeleteAnnee.length; 
                        if(resultatDeleteAnnee === 0){
                            alert('Ce Calendrier est déjà Supprimé');
                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');
        
                            document.getElementById('demarrage').style.display = 'none';
                            document.getElementById('tableaubord').style.display = 'none';
                            document.getElementById('dashboard').style.display = 'none';
                            document.getElementById('configurations').style.display = 'block';
                            document.getElementById('configurer').style.display = 'block';
                            document.getElementById('annee').style.display = 'none';
                            titre.innerHTML = 'Configuration App';
                        }else{
                            for(let i=0; i<docsDeleteAnnee.length; i++){
                                anneeIdStr = docsDeleteAnnee[i]._id;
                                anneeDebutStr = docsDeleteAnnee[i].anneedebut;
                                anneeFinStr = docsDeleteAnnee[i].anneefin;
                            }
                            document.getElementById('deleteidannee').value = anneeIdStr;
                            document.getElementById('deletedatedebutannee').value = anneeDebutStr;
                            document.getElementById('deletedatefinannee').value = anneeFinStr;
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('deletedebutannee').style.display = 'block';
                            document.getElementById('deletefinannee').style.display = 'block';
                            document.getElementById('cancelannee').style.display = 'block';
                        }
                    }else{
                        alert("Suppression Impossible. Donnée en Exécution.");
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            } catch (error) {
               alert('Erreur Exécution'); 
            }
        }
        //seldeletesemannee event handler
        document.getElementById('seldeletesemannee').addEventListener('change', seldeletesemanneeBtnChange);
        async function seldeletesemanneeBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var seldeletesemanneeStr = document.getElementById('seldeletesemannee').value;
            var deletestatutanneeStr = 'Oui';
            var anneeIdStr,anneeDebutStr,anneeFinStr = '';

            var docsDeleteAnnee = [];
            try {
                //check if this trimestre exists in document programme_
                var statutProgrammeStr = 'Oui';
                var docsProgramme = [];
                try {
                    let resultProgramme = await db.createIndex({
                        index: {
                            fields: [
                                'inscritcode', 
                                'anneescolaire', 
                                'programmestatut'
                            ]
                        }
                    });
                    resultProgramme = await db.find({
                        selector: {
                            inscritcode: userCodeStr,
                            anneescolaire: seldeletesemanneeStr,
                            programmestatut: statutProgrammeStr,
                        }
                    });
                    docsProgramme  = resultProgramme.docs;
                    var resultatProgramme = docsProgramme.length;
                    if(resultatProgramme === 0){
                        let result = await db.createIndex({
                            index: {
                                fields: [
                                    'anneestatut', 
                                    'inscritcode', 
                                    'anneedivision', 
                                    'anneescolaire'
                                ]
                            }
                        });
                        result = await db.find({
                            selector: {
                                anneestatut: deletestatutanneeStr,
                                inscritcode: userCodeStr, 
                                anneedivision: anneeScolaire, 
                                anneescolaire: seldeletesemanneeStr
                            }
                        });
                        docsDeleteAnnee  = result.docs;
                        var resultatDeleteAnnee = docsDeleteAnnee.length;
                        if(resultatDeleteAnnee === 0){
                            alert('Ce Calendrier est déjà Supprimé');
                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');
        
                            document.getElementById('demarrage').style.display = 'none';
                            document.getElementById('tableaubord').style.display = 'none';
                            document.getElementById('dashboard').style.display = 'none';
                            document.getElementById('configurations').style.display = 'block';
                            document.getElementById('configurer').style.display = 'block';
                            document.getElementById('annee').style.display = 'none';
                            titre.innerHTML = 'Configuration App';
                        }else{
                            for(let i=0; i<docsDeleteAnnee.length; i++){
                                anneeIdStr = docsDeleteAnnee[i]._id;
                                anneeDebutStr = docsDeleteAnnee[i].anneedebut;
                                anneeFinStr = docsDeleteAnnee[i].anneefin;
                            }
                            document.getElementById('deleteidannee').value = anneeIdStr;
                            document.getElementById('deletedatedebutannee').value = anneeDebutStr;
                            document.getElementById('deletedatefinannee').value = anneeFinStr;
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('deletedebutannee').style.display = 'block';
                            document.getElementById('deletefinannee').style.display = 'block';
                            document.getElementById('cancelannee').style.display = 'block';
                        } 
                    }else{
                        alert("Suppression Impossible. Donnée en Exécution.");
                    }
                } catch (error) {
                   alert('Erreur Exécution'); 
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        /***************************
        supprimerclasseBtn
        *****************************/
        document.getElementById('supprimeranneeBtn').addEventListener('click', supprimeranneeBtnChange);
        async function supprimeranneeBtnChange(e){
            e.preventDefault();

            var divClearModal = document.getElementById('modaloutannee');
            let divModal = document.createElement('div');
            divModal.innerHTML =
            `<div class="modal">
                <p>Êtes-vous sûr de Supprimer ?</p>
                <button class="btn btn--alt" id="ouiHandlerAnneeBtn">Oui</button>
                <button class="btn" id="nonHandlerAnneeBtn">Non</button>
            </div>`;
            divClearModal.appendChild(divModal);

            var divClearBackdrop = document.getElementById('backdropoutannee');
            let divBack = document.createElement('div');
            divBack.innerHTML =
            `<div class="backdrop" id="backdropHandlerAnneeBtn"></div>`;
            divClearBackdrop.appendChild(divBack);

            document.getElementById('ouiHandlerAnneeBtn').addEventListener('click', ouiHandlerAnneeBtnChange);
            async function ouiHandlerAnneeBtnChange(e){
                e.preventDefault();

                var deleteidanneeStr = document.getElementById('deleteidannee').value;
                if(!deleteidanneeStr){
                    alert('Sélectionner le calendrier à Supprimer');
                }else{
                    try {
                        //Delete
                        var doc = await db.get(deleteidanneeStr);
                        var response = await db.remove(doc);
                        if(response.ok){
                            alert('Ce Calendrier Année Scolaire Supprimé avec Succès');
                            var divClearModal = document.getElementById('modaloutannee');
                            while(divClearModal.hasChildNodes()) {
                                divClearModal.removeChild(divClearModal.childNodes[0]);
                            }
    
                            var divClearBackdrop = document.getElementById('backdropoutannee');
                            while(divClearBackdrop.hasChildNodes()) {
                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                            }
    
                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');

                            document.getElementById('demarrage').style.display = 'none';
                            document.getElementById('tableaubord').style.display = 'none';
                            document.getElementById('dashboard').style.display = 'none';
                            document.getElementById('configurations').style.display = 'none';
                            document.getElementById('configurer').style.display = 'none';
                            document.getElementById('etablissement').style.display = 'none';
                            document.getElementById('classe').style.display = 'none';
                            document.getElementById('semaine').style.display = 'none';
                            document.getElementById('annee').style.display = 'block';
                            document.getElementById('crudannee').style.display = 'block';
                            document.getElementById('addannee').style.display = 'none';
                            document.getElementById('editannee').style.display = 'none';
                            document.getElementById('deleteannee').style.display = 'none';
                            document.getElementById('searchannee').style.display = 'none';
                            titre.innerHTML = 'Configuration Calendrier Année';
                        }else{
                            alert("Impossible Supprimer Ce Calendrier Année Scolaire");
                            var divClearModal = document.getElementById('modaloutannee');
                            while(divClearModal.hasChildNodes()) {
                                divClearModal.removeChild(divClearModal.childNodes[0]);
                            }
    
                            var divClearBackdrop = document.getElementById('backdropoutannee');
                            while(divClearBackdrop.hasChildNodes()) {
                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                            }
    
                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');
    
                            document.getElementById('demarrage').style.display = 'none';
                            document.getElementById('tableaubord').style.display = 'none';
                            document.getElementById('dashboard').style.display = 'none';
                            document.getElementById('configurations').style.display = 'block';
                            document.getElementById('configurer').style.display = 'block';
                            document.getElementById('annee').style.display = 'none';
                            titre.innerHTML = 'Configuration App';
                        }
                    } catch (error) {
                        alert('Erreur Exécution');
                    }
                }
            }
            document.getElementById('nonHandlerAnneeBtn').addEventListener('click', nonHandlerAnneeBtnChange);
            async function nonHandlerAnneeBtnChange(e){
                e.preventDefault();

                var divClearModal = document.getElementById('modaloutannee');
                while(divClearModal.hasChildNodes()) {
                    divClearModal.removeChild(divClearModal.childNodes[0]);
                }

                var divClearBackdrop = document.getElementById('backdropoutannee');
                while(divClearBackdrop.hasChildNodes()) {
                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                }
            }
            document.getElementById('backdropHandlerAnneeBtn').addEventListener('click', backdropHandlerAnneeBtnChange);
            async function backdropHandlerAnneeBtnChange(e){
                e.preventDefault();

                var divClearModal = document.getElementById('modaloutannee');
                while(divClearModal.hasChildNodes()) {
                    divClearModal.removeChild(divClearModal.childNodes[0]);
                }

                var divClearBackdrop = document.getElementById('backdropoutannee');
                while(divClearBackdrop.hasChildNodes()) {
                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                }
            }
        }

        /***************************
        searchanneeBtn
        *****************************/
        document.getElementById('searchanneeBtn').addEventListener('click', searchanneeBtnChange);
        async function searchanneeBtnChange(e){
            e.preventDefault();

            document.getElementById('selsearchtrimannee').value = '';
            document.getElementById('selsearchsemannee').value = '';

            var userCodeStr = document.getElementById('userCode').innerText;
            var searchstatutanneeStr = 'Oui';
            var anneedivisionStr = '';

            var docsSearchAnnee = [];

            if(!userCodeStr){
                alert('Connectez-vous à votre Compte avant de continuer');
            }else{
                //check if user has alread selected annee scolaire
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: ['anneestatut', 'inscritcode']
                        }
                    });
                    result = await db.find({
                        selector: {
                            anneestatut: searchstatutanneeStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsSearchAnnee  = result.docs;
                    var resultatSearchAnnee = docsSearchAnnee.length;
                    if(resultatSearchAnnee === 0){
                        alert('Aucun Calendrier Année Scolaire Enregistré');
                    }else{
                        for(let i=0; i<1; i++){
                            anneedivisionStr = docsSearchAnnee[i].anneedivision;
                        }
                        if(anneedivisionStr === 'Trimestre'){
                            anneeScolaire = 'Trimestre';
                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');

                            document.getElementById('demarrage').style.display = 'none';
                            document.getElementById('tableaubord').style.display = 'none';
                            document.getElementById('dashboard').style.display = 'none';
                            document.getElementById('configurations').style.display = 'block';
                            document.getElementById('configurer').style.display = 'none';
                            document.getElementById('annee').style.display = 'block';
                            document.getElementById('crudannee').style.display = 'none';
                            document.getElementById('addannee').style.display = 'none';
                            //hide or show some components
                            document.getElementById('searchselectionannee').style.display = 'none';
                            document.getElementById('searchselectiontrimestre').style.display = 'block';
                            document.getElementById('searchselectionsemestre').style.display = 'none';
                            document.getElementById('searchdebutannee').style.display = 'none';
                            document.getElementById('searchfinannee').style.display = 'none';
                            document.getElementById('viewconsulterannee').style.display = 'none';
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('editannee').style.display = 'none';
                            document.getElementById('deleteannee').style.display = 'none';
                            document.getElementById('searchannee').style.display = 'block';
                            titre.innerHTML = 'Consulter Calendrier Année'; 
                        }else{
                            anneeScolaire = 'Semestre';
                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');

                            document.getElementById('demarrage').style.display = 'none';
                            document.getElementById('tableaubord').style.display = 'none';
                            document.getElementById('dashboard').style.display = 'none';
                            document.getElementById('configurations').style.display = 'block';
                            document.getElementById('configurer').style.display = 'none';
                            document.getElementById('annee').style.display = 'block';
                            document.getElementById('crudannee').style.display = 'none';
                            document.getElementById('addannee').style.display = 'none';
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('searchselectionannee').style.display = 'none';
                            document.getElementById('searchselectiontrimestre').style.display = 'none';
                            document.getElementById('searchselectionsemestre').style.display = 'block';
                            document.getElementById('searchdebutannee').style.display = 'none';
                            document.getElementById('searchfinannee').style.display = 'none';
                            document.getElementById('viewconsulterannee').style.display = 'none';
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('editannee').style.display = 'none';
                            document.getElementById('deleteannee').style.display = 'none';
                            document.getElementById('searchannee').style.display = 'block';
                            titre.innerHTML = 'Consulter Calendrier Année'; 
                        }
                    }
                } catch (error) {
                   alert('Erreur Exécution'); 
                }
            }
        }
        /**nav previous page button*/
        document.getElementById('prevsearchanneeBtn_1').addEventListener('click', prevsearchannee1BtnChange);
        async function prevsearchannee1BtnChange(e){
            e.preventDefault();
            
            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('etablissement').style.display = 'none';
            document.getElementById('classe').style.display = 'none';
            document.getElementById('semaine').style.display = 'none';
            document.getElementById('annee').style.display = 'block';
            document.getElementById('crudannee').style.display = 'block';
            document.getElementById('addannee').style.display = 'none';
            document.getElementById('editannee').style.display = 'none';
            document.getElementById('deleteannee').style.display = 'none';
            document.getElementById('searchannee').style.display = 'none';
            titre.innerHTML = 'Configuration Calendrier Année';
        }
        //selsearchtrimannee event handler
        document.getElementById('selsearchtrimannee').addEventListener('change', selsearchtrimanneeBtnChange);
        async function selsearchtrimanneeBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var selsearchtrimanneeStr = document.getElementById('selsearchtrimannee').value;
            var searchstatutanneeStr = 'Oui';
            var anneeDebutStr,anneeFinStr = '';

            var docsSearchAnnee = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: [
                            'anneestatut', 
                            'inscritcode', 
                            'anneedivision', 
                            'anneescolaire'
                        ]
                    }
                });
                result = await db.find({
                    selector: {
                        anneestatut: searchstatutanneeStr,
                        inscritcode: userCodeStr, 
                        anneedivision: anneeScolaire, 
                        anneescolaire: selsearchtrimanneeStr
                    }
                });
                docsSearchAnnee  = result.docs;
                var resultatSearchAnnee = docsSearchAnnee.length; 
                if(resultatSearchAnnee === 0){
                    alert("Ce Calendrier n'existe pas");
                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'block';
                    document.getElementById('configurer').style.display = 'block';
                    document.getElementById('annee').style.display = 'none';
                    titre.innerHTML = 'Configuration App';
                }else{
                    for(let i=0; i<docsSearchAnnee.length; i++){
                        anneeDebutStr = docsSearchAnnee[i].anneedebut;
                        anneeFinStr = docsSearchAnnee[i].anneefin;
                    }
                    document.getElementById('searchdatedebutannee').value = anneeDebutStr;
                    document.getElementById('searchdatefinannee').value = anneeFinStr;
                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('searchselectionannee').style.display = 'none';
                    document.getElementById('searchselectiontrimestre').style.display = 'block';
                    document.getElementById('searchselectionsemestre').style.display = 'none';
                    document.getElementById('searchdebutannee').style.display = 'block';
                    document.getElementById('searchfinannee').style.display = 'block';
                    document.getElementById('viewconsulterannee').style.display = 'block';
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        //selsearchsemannee event handler
        document.getElementById('selsearchsemannee').addEventListener('change', selsearchsemanneeBtnChange);
        async function selsearchsemanneeBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var selsearchsemanneeStr = document.getElementById('selsearchsemannee').value;
            var searchstatutanneeStr = 'Oui';
            var anneeDebutStr,anneeFinStr = '';

            var docsSearchAnnee = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: [
                            'anneestatut', 
                            'inscritcode', 
                            'anneedivision', 
                            'anneescolaire'
                        ]
                    }
                });
                result = await db.find({
                    selector: {
                        anneestatut: searchstatutanneeStr,
                        inscritcode: userCodeStr, 
                        anneedivision: anneeScolaire, 
                        anneescolaire: selsearchsemanneeStr
                    }
                });
                docsSearchAnnee  = result.docs;
                var resultatSearchAnnee = docsSearchAnnee.length; 
                if(resultatSearchAnnee === 0){
                    alert("Ce Calendrier n'existe pas");
                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'block';
                    document.getElementById('configurer').style.display = 'block';
                    document.getElementById('annee').style.display = 'none';
                    titre.innerHTML = 'Configuration App';
                }else{
                    for(let i=0; i<docsSearchAnnee.length; i++){
                        anneeDebutStr = docsSearchAnnee[i].anneedebut;
                        anneeFinStr = docsSearchAnnee[i].anneefin;
                    }
                    document.getElementById('searchdatedebutannee').value = anneeDebutStr;
                    document.getElementById('searchdatefinannee').value = anneeFinStr;
                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('searchselectionannee').style.display = 'none';
                    document.getElementById('searchselectiontrimestre').style.display = 'none';
                    document.getElementById('searchselectionsemestre').style.display = 'block';
                    document.getElementById('searchdebutannee').style.display = 'block';
                    document.getElementById('searchfinannee').style.display = 'block';
                    document.getElementById('viewconsulterannee').style.display = 'block';
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        document.getElementById('consulteranneeBtn').addEventListener('click', consulteranneeBtnChange);
        async function consulteranneeBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('etablissement').style.display = 'none';
            document.getElementById('classe').style.display = 'none';
            document.getElementById('semaine').style.display = 'none';
            document.getElementById('annee').style.display = 'block';
            document.getElementById('crudannee').style.display = 'block';
            document.getElementById('addannee').style.display = 'none';
            document.getElementById('editannee').style.display = 'none';
            document.getElementById('deleteannee').style.display = 'none';
            document.getElementById('searchannee').style.display = 'none';
            titre.innerHTML = 'Configuration Calendrier Année';
        }
    };

    /****************
    HELPER FUNCTION
    *****************/
    /**1: Get Current Date in JavaScript */
    function getCurrentDate(){
        // Date object
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1;
        var yyyy = today.getFullYear();
        if(dd<10){
            dd='0'+dd;
        }
        if(mm<10){
            mm='0'+mm;
        }
        // Current Date
        var date = yyyy+'-'+mm+'-'+dd;
        return date;
    }


    window.addEventListener('load', () =>{
        new taskConfigAnnee();
    })
}) ();