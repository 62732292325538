import CrudEmploiDuTemps from "./CrudEmploiDuTemps";
import AddEmploiDuTemps from "./AddEmploiDuTemps";
import EditEmploiDuTemps from "./EditEmploiDuTemps";
import DeleteEmploiDuTemps from "./DeleteEmploiDuTemps";
import SearchEmploiDuTemps from "./SearchEmploiDuTemps";

function EmploiDuTemps() {
    return ( 
        <div id="emploidutemps">
            <CrudEmploiDuTemps />
            <AddEmploiDuTemps />
            <EditEmploiDuTemps />
            <DeleteEmploiDuTemps />
            <SearchEmploiDuTemps />
        </div>
    );
}

export default EmploiDuTemps;