function NumberInput(props) {
    return ( 
        <div>
            <input style={inputNumberStyle} id={props.idinput} type={props.number} placeholder={props.place} readOnly={props.read} />
        </div>
     );
}

const inputNumberStyle = {
    width: '88%',
    height: 40,
    color:"#000000",
    fontSize: 18,
    //fontWeight: "bold",
};

export default NumberInput;