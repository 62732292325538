import '../configuration.css';
import './pouchconfigsemaine';
import TimeInput from '../../components/TimeInput';
import ModifierButton from '../../components/ModifierButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function EditSemaine() {
    return ( 
        <div id="editsemaine">
            <KeyboardBackspace style={prevArrows} id="preveditsemaineBtn_1" />
            <br/><br/>
            <form>
                <div id="formeditsemaine" style={scroller}>
                    <br/>
                    <div>
                    <label style={labelStyle}>Jour Semaine</label>
                        <select id="seleditjoursemaine">
                            <option value=''>Sélection Jour</option>
                            <option value='Lundi'>Lundi</option>
                            <option value='Mardi'>Mardi</option>
                            <option value='Mercredi'>Mercredi</option>
                            <option value='Jeudi'>Jeudi</option>
                            <option value='Vendredi'>Vendredi</option>
                            <option value='Samedi'>Samedi</option>
                        </select>
                    </div>
                    
                    <div id="changeheuredebutsemaine">
                        <label style={labelStyle}>Heure Début Cours</label>
                        <TimeInput idinput ="editheuredebutsemaine" time="time" /> 
                    </div>
                    <br/>
                    <div id="changeheurefinsemaine">
                        <label style={labelStyle}>Heure Fin Cours</label>
                        <TimeInput idinput ="editheurefinsemaine" time="time" /> 
                    </div>
                    <br/>
                    <div style={{display:'none'}}>
                        <input id ="editnumerosemaine" type="number" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editidsemaine" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editcodeusersemaine" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editjoursemaine" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editdatesemaine" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editsemainestatut" type="text" />
                    </div>
                    <div id="changeouisemaine">
                        <ModifierButton 
                            idmodifierbutton="modifierouisemaineBtn"
                            modifier="Jour Classe"
                        />
                    </div>
                    <div id="changenonsemaine">
                        <ModifierButton 
                            idmodifierbutton="modifiernonsemaineBtn"
                            modifier="Non Jour Classe"
                        />
                    </div>
                    <div id="changeheuresemaine">
                        <ModifierButton 
                            idmodifierbutton="modifierheuresemaineBtn"
                            modifier="Heures Classe"
                        />
                    </div>
                    <br/><br/>
                </div>
                <div id="modaloutchangesemaine"></div>
                <div id="backdropoutchangesemaine"></div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default EditSemaine;