/* eslint-disable no-redeclare */
import PouchDB from 'pouchdb';
import PouchFind from 'pouchdb-find';

(function(){
    var taskConfiguration = function(){
        /*****************************************************
        ENABLING POUCHDB WITHOUT SYNC TO REMOTE COUCHDB
        ******************************************************/
        //SET UP POUCHDB AND CREATE THE DATABASE 
        const db = new PouchDB('suclu');
        //const remoteCouch = new PouchDB('http://kouaskon:Mandidjouman1@127.0.0.1:5984/suclu');
        //const remoteCouch = new PouchDB('http://admin:1It8Nn1fkD5F@34.65.78.200:5984/bazali');
        //const remoteCouch = new PouchDB('http://admin:iRvMP4dozSun@34.65.19.157:5984/bazali');
        PouchDB.plugin(PouchFind);

        /***************************
        configetablissementBtn
        *****************************/
        document.getElementById('configetablissementBtn').addEventListener('click', configetablissementBtnChange);
        async function configetablissementBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            //document.getElementById('acceder').style.display = 'block';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'block';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('etablissement').style.display = 'block';
            document.getElementById('crudetablissement').style.display = 'block';
            document.getElementById('addetablissement').style.display = 'none';
            document.getElementById('editetablissement').style.display = 'none';
            document.getElementById('deleteetablissement').style.display = 'none';
            document.getElementById('searchetablissement').style.display = 'none';
            titre.innerHTML = 'Configuration Etablissement'; 
        }
        /**nav previous page button*/
        document.getElementById('prevcrudetablissementBtn_1').addEventListener('click', prevcrudetablissement1BtnChange);
        async function prevcrudetablissement1BtnChange(e){
            e.preventDefault();
            
            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            //document.getElementById('acceder').style.display = 'block';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'block';
            document.getElementById('configurer').style.display = 'block';
            document.getElementById('etablissement').style.display = 'none';
            titre.innerHTML = 'Configuration App';
        }

        /***************************
        addetablissementBtn
        *****************************/
        document.getElementById('addetablissementBtn').addEventListener('click', addetablissementBtnChange);
        async function addetablissementBtnChange(e){
            e.preventDefault();
            
            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            //document.getElementById('acceder').style.display = 'block';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'block';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('etablissement').style.display = 'block';
            document.getElementById('crudetablissement').style.display = 'none';
            document.getElementById('addetablissement').style.display = 'block';
            document.getElementById('editetablissement').style.display = 'none';
            document.getElementById('deleteetablissement').style.display = 'none';
            document.getElementById('searchetablissement').style.display = 'none';
            titre.innerHTML = 'Enregistrer Etablissement';
        }
        /**nav previous page button*/
        document.getElementById('prevaddetablissementBtn_1').addEventListener('click', prevaddetablissement1BtnChange);
        async function prevaddetablissement1BtnChange(e){
            e.preventDefault();
            
            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'block';
            document.getElementById('configurer').style.display = 'block';
            document.getElementById('etablissement').style.display = 'none';
            titre.innerHTML = 'Configuration App';
        }
        /***************************
        enregistreretablissementBtn
        *****************************/
        document.getElementById('enregistreretablissementBtn').addEventListener('click', enregistreretablissementBtnChange);
        async function enregistreretablissementBtnChange(e){
            e.preventDefault();

            var usercodeStr = document.getElementById('userCode').innerText;
            var addnometablissementStr = document.getElementById('addnometablissement').value;
            var addvilleetablissementStr = document.getElementById('addvilleetablissement').value;
            var addadresseetablissementStr = document.getElementById('addadresseetablissement').value;
            var addprincipaletablissementStr = document.getElementById('addprincipaletablissement').value;
            var addcontactetablissementStr = document.getElementById('addcontactetablissement').value;
            var addemailetablissementStr = document.getElementById('addemailetablissement').value;
            var datejourStr = getCurrentDate();
            var addstatutetablissementStr = 'Oui';

            var docsEtablissement = [];

            if(!usercodeStr){
                alert("Identifiez-vous avant de continuer");
            }else if(!addnometablissementStr){
                alert("Entrer Nom Etablissement");
            }else if(!addvilleetablissementStr){
                alert("Entrer Ville");
            }else if(!addadresseetablissementStr){
                alert("Entrer Adresse");
            }else if(!addprincipaletablissementStr){
                alert("Entrer Nom Principal/Proviseur");
            }else{
                //check if etablissement has already been registered
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: ['etablissementstatut', 'inscritcode']
                        }
                    });
                    result = await db.find({
                        selector: {
                            etablissementstatut: addstatutetablissementStr,
                            inscritcode: usercodeStr
                        }
                    });
                    docsEtablissement  = result.docs;
                    var resultatEtablissement = docsEtablissement.length;
                    if(resultatEtablissement === 0){
                        //Create the document _id and _rev.
                        var now = Date.now();
                        var id = 'etablissement_' + now;
                        // Add the document to the database.
                        var response = await db.put({
                            _id: id,
                            inscritcode: usercodeStr,
                            etablissementnom: addnometablissementStr,
                            etablissementville: addvilleetablissementStr,
                            etablissementadresse: addadresseetablissementStr,
                            etablissementprincipal: addprincipaletablissementStr,
                            etablissementcontact: addcontactetablissementStr,
                            etablissementemail: addemailetablissementStr,
                            etablissementdate: datejourStr,
                            etablissementstatut: addstatutetablissementStr,
                        });
                        // check if document inserted and synced
                        var doc = await db.get(response.id);
                        if(doc !== ''){
                            alert('Enregistrement Effectué avec succès.');
                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');

                            document.getElementById('demarrage').style.display = 'none';
                            document.getElementById('tableaubord').style.display = 'none';
                            document.getElementById('dashboard').style.display = 'none';
                            document.getElementById('configurations').style.display = 'block';
                            document.getElementById('configurer').style.display = 'block';
                            document.getElementById('etablissement').style.display = 'none';
                            titre.innerHTML = 'Configuration App';
                        }else{
                            alert('Echec Enregistrement. Reprendre');
                        }
                    }else{
                        alert("Etablissement déjà enregistré");
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }

        /***************************
        editetablissementBtn
        *****************************/
        document.getElementById('editetablissementBtn').addEventListener('click', editetablissementBtnChange);
        async function editetablissementBtnChange(e){
            e.preventDefault();

            var usercodeStr = document.getElementById('userCode').innerText;
            var statutetablissementStr = 'Oui';
            var docsEditEtablissement = [];
            //initialise variables
            var etablissementIdStr,etablissementNomStr,etablissementVilleStr,etablissementAdresseStr,etablissementPrincipalStr,
            etablissementContactStr,etablissementEmailStr,etablissementDateStr,etablissementStatutStr = '';
            var titre = document.getElementById('titre');
            try {
                let result = await db.createIndex({
                    index: {
                        fields: ['etablissementstatut', 'inscritcode']
                    }
                });
                result = await db.find({
                    selector: {
                        etablissementstatut: statutetablissementStr,
                        inscritcode: usercodeStr
                    }
                });
                docsEditEtablissement  = result.docs;
                var resultatEditEtablissement = docsEditEtablissement.length;
                if(resultatEditEtablissement === 0){
                    alert("Etablissement pas enregistré");
                    document.getElementById('titre').style.display = 'block';
                    titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'block';
                    document.getElementById('configurer').style.display = 'block';
                    document.getElementById('etablissement').style.display = 'none';
                    titre.innerHTML = 'Configuration App';
                }else{
                    //retrieve etablissement data
                    for(let i=0; i<docsEditEtablissement.length; i++){
                        etablissementIdStr = docsEditEtablissement[i]._id;
                        etablissementNomStr = docsEditEtablissement[i].etablissementnom;
                        etablissementVilleStr = docsEditEtablissement[i].etablissementville;
                        etablissementAdresseStr = docsEditEtablissement[i].etablissementadresse;
                        etablissementPrincipalStr = docsEditEtablissement[i].etablissementprincipal;
                        etablissementContactStr = docsEditEtablissement[i].etablissementcontact;
                        etablissementEmailStr = docsEditEtablissement[i].etablissementemail;
                        etablissementDateStr = docsEditEtablissement[i].etablissementdate;
                        etablissementStatutStr = docsEditEtablissement[i].etablissementstatut;
                    }
                    document.getElementById('editidetablissement').value = etablissementIdStr;
                    document.getElementById('editnometablissement').value = etablissementNomStr;
                    document.getElementById('editvilleetablissement').value = etablissementVilleStr;
                    document.getElementById('editadresseetablissement').value = etablissementAdresseStr;
                    document.getElementById('editprincipaletablissement').value = etablissementPrincipalStr;
                    document.getElementById('editcontactetablissement').value = etablissementContactStr;
                    document.getElementById('editemailetablissement').value = etablissementEmailStr;
                    document.getElementById('editdateetablissement').value = etablissementDateStr;
                    document.getElementById('editsattutetablissement').value = etablissementStatutStr;

                    document.getElementById('titre').style.display = 'block';
                    titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'block';
                    document.getElementById('configurer').style.display = 'none';
                    document.getElementById('etablissement').style.display = 'block';
                    document.getElementById('crudetablissement').style.display = 'none';
                    document.getElementById('addetablissement').style.display = 'none';
                    document.getElementById('editetablissement').style.display = 'block';
                    document.getElementById('deleteetablissement').style.display = 'none';
                    document.getElementById('searchetablissement').style.display = 'none';
                    titre.innerHTML = 'Modifier Etablissement';
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        /**nav previous page button*/
        document.getElementById('preveditetablissementBtn_1').addEventListener('click', preveditetablissement1BtnChange);
        async function preveditetablissement1BtnChange(e){
            e.preventDefault();
            
            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            //document.getElementById('acceder').style.display = 'block';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'block';
            document.getElementById('configurer').style.display = 'block';
            document.getElementById('etablissement').style.display = 'none';
            titre.innerHTML = 'Configuration App';
        }
        /***************************
        modifieretablissementBtn
        *****************************/
        document.getElementById('modifieretablissementBtn').addEventListener('click', modifieretablissementBtnChange);
        async function modifieretablissementBtnChange(e){
            e.preventDefault();

            var usercodeStr = document.getElementById('userCode').innerText;
            var etablissementIdStr = document.getElementById('editidetablissement').value;
            var etablissementNomStr = document.getElementById('editnometablissement').value;
            var etablissementVilleStr = document.getElementById('editvilleetablissement').value;
            var etablissementAdresseStr = document.getElementById('editadresseetablissement').value;
            var etablissementPrincipalStr = document.getElementById('editprincipaletablissement').value;
            var etablissementContactStr = document.getElementById('editcontactetablissement').value;
            var etablissementEmailStr = document.getElementById('editemailetablissement').value;
            var etablissementDateStr = document.getElementById('editidetablissement').value;
            var etablissementStatutStr = document.getElementById('editsattutetablissement').value;

            if(!usercodeStr){
                alert("Identifiez-vous avant de continuer");
            }else if(!etablissementNomStr){
                alert("Entrer Nom Etablissement");
            }else if(!etablissementVilleStr){
                alert("Entrer Ville");
            }else if(!etablissementAdresseStr){
                alert("Entrer Adresse");
            }else if(!etablissementPrincipalStr){
                alert("Entrer Nom Principal/Proviseur");
            }else{
                try {
                    // Retrieve the document to get the latest revision
                    var id = etablissementIdStr;
                    var doc = await db.get(id);
                    // Make changes
                    doc.inscritcode = usercodeStr;
                    doc.etablissementnom = etablissementNomStr;
                    doc.etablissementville = etablissementVilleStr;
                    doc.etablissementadresse = etablissementAdresseStr;
                    doc.etablissementprincipal = etablissementPrincipalStr;
                    doc.etablissementcontact = etablissementContactStr;
                    doc.etablissementemail = etablissementEmailStr;
                    doc.etablissementdate = etablissementDateStr;
                    doc.etablissementstatut = etablissementStatutStr;
                    // Put the document, saving it as a new revision
                    var response = await db.put(doc);
                    // Get the document we change and update the UI.
                    var docEdit = await db.get(response.id);
                    if(docEdit !== ''){
                        alert("Etablissement Modifié avec Succès");
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'block';
                        document.getElementById('configurer').style.display = 'block';
                        document.getElementById('etablissement').style.display = 'none';
                        titre.innerHTML = 'Configuration App';
                    }else{
                        alert("Erreur Modification. Reprendre");
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'block';
                        document.getElementById('configurer').style.display = 'block';
                        document.getElementById('etablissement').style.display = 'none';
                        titre.innerHTML = 'Configuration App';
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }

        /***************************
        deleteetablissementBtn
        *****************************/
        document.getElementById('deleteetablissementBtn').addEventListener('click', deleteetablissementBtnChange);
        async function deleteetablissementBtnChange(e){
            e.preventDefault();

            var usercodeStr = document.getElementById('userCode').innerText;
            var statutetablissementStr = 'Oui';
            var docsDeleteEtablissement = [];
            //initialise variables
            var etablissementIdStr,etablissementNomStr,etablissementVilleStr,etablissementAdresseStr,etablissementPrincipalStr,
            etablissementContactStr,etablissementEmailStr,etablissementDateStr,etablissementStatutStr = '';
            var titre = document.getElementById('titre');
            try {
                let result = await db.createIndex({
                    index: {
                        fields: ['etablissementstatut', 'inscritcode']
                    }
                });
                result = await db.find({
                    selector: {
                        etablissementstatut: statutetablissementStr,
                        inscritcode: usercodeStr
                    }
                });
                docsDeleteEtablissement  = result.docs;
                var resultatDeleteEtablissement = docsDeleteEtablissement.length;
                if(resultatDeleteEtablissement === 0){
                    alert("Etablissement pas enregistré");
                    document.getElementById('titre').style.display = 'block';
                    titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'block';
                    document.getElementById('configurer').style.display = 'block';
                    document.getElementById('etablissement').style.display = 'none';
                    titre.innerHTML = 'Configuration App';
                }else{
                    //retrieve etablissement data
                    for(let i=0; i<docsDeleteEtablissement.length; i++){
                        etablissementIdStr = docsDeleteEtablissement[i]._id;
                        etablissementNomStr = docsDeleteEtablissement[i].etablissementnom;
                        etablissementVilleStr = docsDeleteEtablissement[i].etablissementville;
                        etablissementAdresseStr = docsDeleteEtablissement[i].etablissementadresse;
                        etablissementPrincipalStr = docsDeleteEtablissement[i].etablissementprincipal;
                        etablissementContactStr = docsDeleteEtablissement[i].etablissementcontact;
                        etablissementEmailStr = docsDeleteEtablissement[i].etablissementemail;
                        etablissementDateStr = docsDeleteEtablissement[i].etablissementdate;
                        etablissementStatutStr = docsDeleteEtablissement[i].etablissementstatut;
                    }
                    document.getElementById('deleteidetablissement').value = etablissementIdStr;
                    document.getElementById('deletenometablissement').value = etablissementNomStr;
                    document.getElementById('deletevilleetablissement').value = etablissementVilleStr;
                    document.getElementById('deleteadresseetablissement').value = etablissementAdresseStr;
                    document.getElementById('deleteprincipaletablissement').value = etablissementPrincipalStr;
                    document.getElementById('deletecontactetablissement').value = etablissementContactStr;
                    document.getElementById('deleteemailetablissement').value = etablissementEmailStr;
                    document.getElementById('deletedateetablissement').value = etablissementDateStr;
                    document.getElementById('deletesattutetablissement').value = etablissementStatutStr;

                    document.getElementById('titre').style.display = 'block';
                    titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'block';
                    document.getElementById('configurer').style.display = 'none';
                    document.getElementById('etablissement').style.display = 'block';
                    document.getElementById('crudetablissement').style.display = 'none';
                    document.getElementById('addetablissement').style.display = 'none';
                    document.getElementById('editetablissement').style.display = 'none';
                    document.getElementById('deleteetablissement').style.display = 'block';
                    document.getElementById('searchetablissement').style.display = 'none';
                    titre.innerHTML = 'Modifier Etablissement';
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        /**nav previous page button*/
        document.getElementById('prevdeleteetablissementBtn_1').addEventListener('click', prevdeleteetablissement1BtnChange);
        async function prevdeleteetablissement1BtnChange(e){
            e.preventDefault();
            
            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'block';
            document.getElementById('configurer').style.display = 'block';
            document.getElementById('etablissement').style.display = 'none';
            titre.innerHTML = 'Configuration App';
        }
        /***************************
        supprimeretablissementBtn
        *****************************/
        document.getElementById('supprimeretablissementBtn').addEventListener('click', supprimeretablissementBtnChange);
        async function supprimeretablissementBtnChange(e){
            e.preventDefault();

            var divClearModal = document.getElementById('modaloutetablissement');
            let divModal = document.createElement('div');
            divModal.innerHTML =
            `<div class="modal">
                <p>Êtes-vous sûr de Supprimer ?</p>
                <button class="btn btn--alt" id="ouiHandlerEtablissementBtn">Oui</button>
                <button class="btn" id="nonHandlerEtablissementBtn">Non</button>
            </div>`;
            divClearModal.appendChild(divModal);

            var divClearBackdrop = document.getElementById('backdropoutetablissement');
            let divBack = document.createElement('div');
            divBack.innerHTML =
            `<div class="backdrop" id="backdropHandlerEtablissementBtn"></div>`;
            divClearBackdrop.appendChild(divBack);

            document.getElementById('ouiHandlerEtablissementBtn').addEventListener('click', ouiHandlerEtablissementBtnChange);
            async function ouiHandlerEtablissementBtnChange(e){
                e.preventDefault();

                var deleteidetablissementStr = document.getElementById('deleteidetablissement').value;

                try {
                    //Delete
                    var doc = await db.get(deleteidetablissementStr);
                    var response = await db.remove(doc);
                    if(response.ok){
                        alert('Etablissement Supprimé avec Succès');
                        var divClearModal = document.getElementById('modaloutetablissement');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutetablissement');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'block';
                        document.getElementById('configurer').style.display = 'block';
                        document.getElementById('etablissement').style.display = 'none';
                        titre.innerHTML = 'Configuration App';
                    }else{
                        alert("Impossible Supprimer Etablissement");
                        var divClearModal = document.getElementById('modaloutetablissement');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutetablissement');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'block';
                        document.getElementById('configurer').style.display = 'block';
                        document.getElementById('etablissement').style.display = 'none';
                        titre.innerHTML = 'Configuration App';
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
            document.getElementById('nonHandlerEtablissementBtn').addEventListener('click', nonHandlerEtablissementBtnChange);
            async function nonHandlerEtablissementBtnChange(e){
                e.preventDefault();

                var divClearModal = document.getElementById('modaloutetablissement');
                while(divClearModal.hasChildNodes()) {
                    divClearModal.removeChild(divClearModal.childNodes[0]);
                }

                var divClearBackdrop = document.getElementById('backdropoutetablissement');
                while(divClearBackdrop.hasChildNodes()) {
                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                }
            }
            document.getElementById('backdropHandlerEtablissementBtn').addEventListener('click', backdropHandlerEtablissementBtnChange);
            async function backdropHandlerEtablissementBtnChange(e){
                e.preventDefault();

                var divClearModal = document.getElementById('modaloutetablissement');
                while(divClearModal.hasChildNodes()) {
                    divClearModal.removeChild(divClearModal.childNodes[0]);
                }

                var divClearBackdrop = document.getElementById('backdropoutetablissement');
                while(divClearBackdrop.hasChildNodes()) {
                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                }
            }
        }

        /***************************
        searchetablissementBtn
        *****************************/
        document.getElementById('searchetablissementBtn').addEventListener('click', searchetablissementBtnChange);
        async function searchetablissementBtnChange(e){
            e.preventDefault();

            var usercodeStr = document.getElementById('userCode').innerText;
            var statutetablissementStr = 'Oui';
            var docsSearchEtablissement = [];
            //initialise variables
            var etablissementNomStr,etablissementVilleStr,etablissementAdresseStr,etablissementPrincipalStr,
            etablissementContactStr,etablissementEmailStr = '';
            var titre = document.getElementById('titre');
            try {
                let result = await db.createIndex({
                    index: {
                        fields: ['etablissementstatut', 'inscritcode']
                    }
                });
                result = await db.find({
                    selector: {
                        etablissementstatut: statutetablissementStr,
                        inscritcode: usercodeStr
                    }
                });
                docsSearchEtablissement  = result.docs;
                var resultatSearchEtablissement = docsSearchEtablissement.length;
                if(resultatSearchEtablissement === 0){
                    alert("Etablissement pas enregistré");
                    document.getElementById('titre').style.display = 'block';
                    titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'block';
                    document.getElementById('configurer').style.display = 'block';
                    document.getElementById('etablissement').style.display = 'none';
                    titre.innerHTML = 'Configuration App';
                }else{
                    //retrieve etablissement data
                    for(let i=0; i<docsSearchEtablissement.length; i++){
                        etablissementNomStr = docsSearchEtablissement[i].etablissementnom;
                        etablissementVilleStr = docsSearchEtablissement[i].etablissementville;
                        etablissementAdresseStr = docsSearchEtablissement[i].etablissementadresse;
                        etablissementPrincipalStr = docsSearchEtablissement[i].etablissementprincipal;
                        etablissementContactStr = docsSearchEtablissement[i].etablissementcontact;
                        etablissementEmailStr = docsSearchEtablissement[i].etablissementemail;
                    }
                    document.getElementById('searchnometablissement').value = etablissementNomStr;
                    document.getElementById('searchvilleetablissement').value = etablissementVilleStr;
                    document.getElementById('searchadresseetablissement').value = etablissementAdresseStr;
                    document.getElementById('searchprincipaletablissement').value = etablissementPrincipalStr;
                    document.getElementById('searchcontactetablissement').value = etablissementContactStr;
                    document.getElementById('searchemailetablissement').value = etablissementEmailStr;

                    document.getElementById('titre').style.display = 'block';
                    titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'block';
                    document.getElementById('configurer').style.display = 'none';
                    document.getElementById('etablissement').style.display = 'block';
                    document.getElementById('crudetablissement').style.display = 'none';
                    document.getElementById('addetablissement').style.display = 'none';
                    document.getElementById('editetablissement').style.display = 'none';
                    document.getElementById('deleteetablissement').style.display = 'none';
                    document.getElementById('searchetablissement').style.display = 'block';
                    titre.innerHTML = 'Consulter Etablissement';
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        /**nav previous page button*/
        document.getElementById('prevsearchetablissementBtn_1').addEventListener('click', prevsearchetablissement1BtnChange);
        async function prevsearchetablissement1BtnChange(e){
            e.preventDefault();
            
            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'block';
            document.getElementById('configurer').style.display = 'block';
            document.getElementById('etablissement').style.display = 'none';
            titre.innerHTML = 'Configuration App';
        }

        /**XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX*/
        /**************
        configclasseBtn
        ***************/
        document.getElementById('configclasseBtn').addEventListener('click', configclasseBtnChange);
        async function configclasseBtnChange(e){
            e.preventDefault();

            //check if Etablissement has been configured before open this action
            var usercodeStr = document.getElementById('userCode').innerText;
            var statutetablissementStr = 'Oui';
            var docsStatut = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: ['etablissementstatut', 'inscritcode']
                    }
                });
                result = await db.find({
                    selector: {
                        etablissementstatut: statutetablissementStr,
                        inscritcode: usercodeStr
                    }
                });
                docsStatut  = result.docs;
                var resultatStatut = docsStatut.length;
                if(resultatStatut === 0){
                    alert('SVP Configurer Etablissement Avant');
                }else{
                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'block';
                    document.getElementById('configurer').style.display = 'none';
                    document.getElementById('etablissement').style.display = 'none';
                    document.getElementById('classe').style.display = 'block';
                    document.getElementById('crudclasse').style.display = 'block';
                    document.getElementById('addclasse').style.display = 'none';
                    document.getElementById('editclasse').style.display = 'none';
                    document.getElementById('deleteclasse').style.display = 'none';
                    document.getElementById('searchclasse').style.display = 'none';
                    titre.innerHTML = 'Configuration Classe'; 
                }
            } catch (error) {
               alert('Erreur Exécution'); 
            }
        }

        /**XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX*/
        /**************
        configsemaineBtn
        ***************/
        document.getElementById('configsemaineBtn').addEventListener('click', configsemaineBtnChange);
        async function configsemaineBtnChange(e){
            e.preventDefault();

            //check if Etablissement has been configured before open this action
            var usercodeStr = document.getElementById('userCode').innerText;
            var statutclasseStr = 'Oui';
            var docsStatut = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: ['classestatut', 'inscritcode']
                    }
                });
                result = await db.find({
                    selector: {
                        classestatut: statutclasseStr,
                        inscritcode: usercodeStr
                    }
                });
                docsStatut  = result.docs;
                var resultatStatut = docsStatut.length;
                if(resultatStatut === 0){
                    alert('SVP Configurer Classe Avant');
                }else{
                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'block';
                    document.getElementById('configurer').style.display = 'none';
                    document.getElementById('etablissement').style.display = 'none';
                    document.getElementById('classe').style.display = 'none';
                    document.getElementById('semaine').style.display = 'block';
                    document.getElementById('crudsemaine').style.display = 'block';
                    document.getElementById('addsemaine').style.display = 'none';
                    document.getElementById('editsemaine').style.display = 'none';
                    document.getElementById('deletesemaine').style.display = 'none';
                    document.getElementById('searchsemaine').style.display = 'none';
                    titre.innerHTML = 'Configuration Jours de Classe'; 
                }
            } catch (error) {
               alert('Erreur Exécution'); 
            }
        }

        /**XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX*/
        /**************
        configanneeBtn
        ***************/
        document.getElementById('configanneeBtn').addEventListener('click', configanneeBtnChange);
        async function configanneeBtnChange(e){
            e.preventDefault();

            //check if Etablissement has been configured before open this action
            var usercodeStr = document.getElementById('userCode').innerText;
            var statutsemaineStr = 'Oui';
            var docsStatut = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: ['semainestatut', 'inscritcode']
                    }
                });
                result = await db.find({
                    selector: {
                        semainestatut: statutsemaineStr,
                        inscritcode: usercodeStr
                    }
                });
                docsStatut  = result.docs;
                var resultatStatut = docsStatut.length;
                if(resultatStatut === 0){
                    alert('SVP Configurer Semaine Scolaire Avant');
                }else{
                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'block';
                    document.getElementById('configurer').style.display = 'none';
                    document.getElementById('etablissement').style.display = 'none';
                    document.getElementById('classe').style.display = 'none';
                    document.getElementById('semaine').style.display = 'none';
                    document.getElementById('annee').style.display = 'block';
                    document.getElementById('crudannee').style.display = 'block';
                    document.getElementById('addannee').style.display = 'none';
                    document.getElementById('editannee').style.display = 'none';
                    document.getElementById('deleteannee').style.display = 'none';
                    document.getElementById('searchannee').style.display = 'none';
                    titre.innerHTML = 'Configuration Calendrier Année';
                }
            } catch (error) {
               alert('Erreur Exécution'); 
            }
        }

        /**XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX*/
        /**************
        configmatiereBtn
        ***************/
        document.getElementById('configmatiereBtn').addEventListener('click', configmatiereBtnChange);
        async function configmatiereBtnChange(e){
            e.preventDefault();

            //check if Etablissement has been configured before open this action
            var usercodeStr = document.getElementById('userCode').innerText;
            var statutanneeStr = 'Oui';
            var docsStatut = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: ['anneestatut', 'inscritcode']
                    }
                });
                result = await db.find({
                    selector: {
                        anneestatut: statutanneeStr,
                        inscritcode: usercodeStr
                    }
                });
                docsStatut  = result.docs;
                var resultatStatut = docsStatut.length;
                if(resultatStatut === 0){
                    alert('SVP Configurer Calendrier Année Scolaire Avant');
                }else{
                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'block';
                    document.getElementById('configurer').style.display = 'none';
                    document.getElementById('etablissement').style.display = 'none';
                    document.getElementById('classe').style.display = 'none';
                    document.getElementById('semaine').style.display = 'none';
                    document.getElementById('annee').style.display = 'none';
                    document.getElementById('matiere').style.display = 'block';
                    document.getElementById('crudmatiere').style.display = 'block';
                    document.getElementById('addmatiere').style.display = 'none';
                    document.getElementById('editmatiere').style.display = 'none';
                    document.getElementById('deletematiere').style.display = 'none';
                    document.getElementById('searchmatiere').style.display = 'none';
                    titre.innerHTML = 'Configuration Matières Classe';
                }
            } catch (error) {
               alert('Erreur Exécution'); 
            }
        }
    };

    /****************
    HELPER FUNCTION
    *****************/
    /**1: Get Current Date in JavaScript */
    function getCurrentDate(){
        // Date object
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1;
        var yyyy = today.getFullYear();
        if(dd<10){
            dd='0'+dd;
        }
        if(mm<10){
            mm='0'+mm;
        }
        // Current Date
        var date = yyyy+'-'+mm+'-'+dd;
        return date;
    }

    window.addEventListener('load', () =>{
        new taskConfiguration();
    })
}) ();