import './moygenerale.css';
import './pouchmoygenerale';
import AjouterButton from '../../components/AjouterButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function AddMoyGenerale() {
    return ( 
        <div id="addmoygenerale">
            <KeyboardBackspace style={prevArrows} id="prevaddmoygeneraleBtn_1" />
            <br/><br/>
            <form>
                <div id="formaddmoygenerale" style={scroller}>
                    <br/>
                    <div id="savecalendriermoygenerale">
                        <label style={labelStyle}>Sélection Calendrier Scolaire</label>
                        <div id="addcalendriermoygenerale"></div>
                    </div>
                    <br/>
                    <div id="saveprogrammemoygenerale">
                        <label id="addmatiereprogrammemoygenerale" style={labelStyle}>Trimestre</label>
                        <br/><br/>
                        <table className='thin_table_config'>
                            <thead>
                                <tr>
                                    <th>Matière</th>
                                    <th>Moyenne</th>
                                    <th>Coeff.</th>
                                </tr>
                            </thead>
                            <tbody id='addProgrammeMoyGeneraleTableBody'></tbody>
                        </table>
                    </div>
                    <br/>
                    <div id="savetotalcumulmoygenerale">
                        <label style={labelStyle}>Total Moyenne Cumulées</label>
                        <div id="addtotalcumulmoygenerale"></div>
                    </div>
                    <div id="savetotalcoefficientmoygenerale">
                        <label style={labelStyle}>Total Coefficients Cumulés</label>
                        <div id="addtotalcoefficientmoygenerale"></div>
                    </div>
                    <div id="saveobtenumoygenerale">
                        <label style={labelStyle}>Moyenne Générale</label>
                        <div style={{fontWeight:'bold',color:'#1a1aff'}} id="addmoygeneraleobtenue"></div>
                    </div>
                    <br/>
                    
                    <div style={{display:'none'}}>
                        <input id ="addselectcalendriermoygenerale" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="addmoyennecumulmoygenerale" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="addcoefficientcumulemoygenerale" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="addmoyennemoygenerale" type="text" />
                    </div>
                    <br/>
                    <div id="savemoygenerale">
                        <AjouterButton 
                            idajouterbutton="enregistrermoygeneraleBtn"
                            ajouter="Enregistrer"
                        />
                    </div>
                    <br/><br/>
                </div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default AddMoyGenerale;