import {NavigateNext, } from '@material-ui/icons';
import wikipedia from './wikipedia_1.png';

function CompoWikipedia(props) {
    return ( 
        <div>
            <button id={props.idaddwikipedia} style={addButton}>
                <img style={{float:'left', height:30, width:40}} src={wikipedia} alt=''/>
                Recherche {props.titre}
                <NavigateNext style={{float:'right',color:'#008000'}} />
            </button>
        </div>
    );
}

const addButton = {
    marginTop: '4%',
    flex: 1,
    width: '90%',
    height: 50,
    textAlign: "center",
    alignContent: 'center',
    border: "2px solid #008000",
    fontSize: 18,
    borderRadius: 10,
    color: "#008000",
    //textTransform: "uppercase",
}

export default CompoWikipedia;