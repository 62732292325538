import '../configuration.css';
import './pouchconfigannee';
import DateInput from '../../components/DateInput';
import SupprimerButton from '../../components/SupprimerButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function DeleteAnnee() {
    return ( 
        <div id="deleteannee">
            <KeyboardBackspace style={prevArrows} id="prevdeleteanneeBtn_1" />
            <br/><br/>
            <form>
                <div id="formdeleteannee" style={scroller}>
                    <br/>
                    <div id="deleteselectionannee">
                        <select id="seldeleteannee">
                            <option value=''>Sélection Année Scolaire</option>
                            <option value='Trimestre'>Trimestrielle</option>
                            <option value='Semestre'>Semestrielle</option>
                        </select>
                    </div>
                    <div id="deleteselectiontrimestre">
                        <select id="seldeletetrimannee">
                            <option value=''>Sélection Trimestre</option>
                            <option value='Trimestre 1'>Trimestre 1</option>
                            <option value='Trimestre 2'>Trimestre 2</option>
                            <option value='Trimestre 3'>Trimestre 3</option>
                        </select>
                    </div>
                    <div id="deleteselectionsemestre">
                        <select id="seldeletesemannee">
                            <option value=''>Sélection Semestre</option>
                            <option value='Semestre 1'>Semestre 1</option>
                            <option value='Semestre 2'>Semestre 2</option>
                        </select>
                    </div>
                    <br/>
                    <div id="deletedebutannee">
                        <label style={labelStyle}>Date Début</label>
                        <DateInput idinput ="deletedatedebutannee" date="date" read="readOnly" /> 
                    </div>
                    <br/>
                    <br/>
                    <div id="deletefinannee">
                        <label style={labelStyle}>Date Fin</label>
                        <DateInput idinput ="deletedatefinannee" date="date" read="readOnly" /> 
                    </div>
                    <br/>
                    <div style={{display:'none'}}>
                        <input id ="deleteidannee" type="text" />
                    </div>
                    <div id="cancelannee">
                        <SupprimerButton 
                        idsupprimerbutton="supprimeranneeBtn"
                        supprimer="Supprimer"
                        />
                    </div>
                    <br/><br/>
                </div>
                <div id="modaloutannee"></div>
                <div id="backdropoutannee"></div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default DeleteAnnee;