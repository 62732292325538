import '../configuration.css';
import './pouchconfigsemaine';
import { KeyboardBackspace, } from '@material-ui/icons';
import AddButton from "../../components/AddButton";
import EditButton from "../../components/EditButton";
import DeleteButton from "../../components/DeleteButton";
import SearchButton from "../../components/SearchButton";

function CrudSemaine() {
    return ( 
        <div id="crudsemaine">
            <KeyboardBackspace style={prevArrows} id="prevcrudsemaineBtn_1" />
            <br/><br/>
            <div id="semainesetup" style={scroller}>
                <AddButton 
                idaddbutton = "addsemaineBtn" 
                titre = "Jours de Classe" />

                <EditButton 
                ideditbutton = "editsemaineBtn" 
                titre = "Jours de Classe" />

                <DeleteButton 
                iddeletebutton = "deletesemaineBtn" 
                titre = "Jours de Classe" />

                <SearchButton 
                idsearchbutton = "searchsemaineBtn" 
                titre = "Jours de Classe" />
            </div>
        </div>
    );
}

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#008000',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default CrudSemaine;