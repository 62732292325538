/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
import PouchDB from 'pouchdb';
import PouchFind from 'pouchdb-find';

(function(){
    var taskMoyGenerale = function(){
        /*****************************************************
        ENABLING POUCHDB WITHOUT SYNC TO REMOTE COUCHDB
        ******************************************************/
        //SET UP POUCHDB AND CREATE THE DATABASE 
        const db = new PouchDB('suclu');
        PouchDB.plugin(PouchFind);

        /***************
        crudmoygeneraleBtn
        *****************/
        /**nav previous page button*/
        document.getElementById('prevcrudmoygeneraleBtn_1').addEventListener('click', prevcrudmoygenerale1BtnChange);
        async function prevcrudmoygenerale1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('moyenne').style.display = 'block';
            document.getElementById('moymatiere').style.display = 'none';
            document.getElementById('moygenerale').style.display = 'none';
            document.getElementById('moyannuelle').style.display = 'none';
            titre.innerHTML = 'Calcul des Moyennes'; 
        }

        /***************************
        addmoygeneraleBtn
        *****************************/
        document.getElementById('addmoygeneraleBtn').addEventListener('click',addmoygeneraleBtnChange);
        async function addmoygeneraleBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutanneeStr = 'Oui';
            //var statutmatiereStr = 'Oui';

            var docsAnnee = [];
            //var docsMatiere = [];

            if(!userCodeStr){
                alert('Connectez-vous à votre Compte avant de continuer');
            }else{
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: ['anneestatut', 'inscritcode']
                        }
                    });
                    result = await db.find({
                        selector: {
                            anneestatut: statutanneeStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsAnnee  = result.docs;
                    var resultatAnnee = docsAnnee.length;
                    if(resultatAnnee === 0){
                        alert('Aucun Calendrier Année Scolaire Enregistré');
                    }else{
                        var divClear;
                        var divModifier;
                        divClear = document.getElementById('addcalendriermoygenerale');
                        while(divClear.hasChildNodes()) {
                            divClear.removeChild(divClear.childNodes[0]);
                        }
                        divModifier = document.getElementById('addcalendriermoygenerale');
                        //Create and append select list
                        var selectList = document.createElement("select");
                        selectList.id = "seladdcalendriermoygenerale";
                        divModifier.appendChild(selectList);
                        //Create and append the options
                        var option_null = document.createElement("option");
                        option_null.value = '';
                        option_null.text = 'Sélectionner Calendrier';
                        selectList.appendChild(option_null);
                        //Create and append the options
                        for (var i = 0; i<docsAnnee.length; i++){
                            var option = document.createElement("option");
                            option.value = docsAnnee[i].anneescolaire;
                            option.text = docsAnnee[i].anneescolaire;
                            selectList.appendChild(option);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'none';
                        document.getElementById('programme').style.display = 'none';
                        document.getElementById('notes').style.display = 'none';
                        document.getElementById('moyenne').style.display = 'none';
                        document.getElementById('moygenerale').style.display = 'block';
                        document.getElementById('crudmoygenerale').style.display = 'none';
                        document.getElementById('addmoygenerale').style.display = 'block';
                        document.getElementById('editmoygenerale').style.display = 'none';
                        document.getElementById('deletemoygenerale').style.display = 'none';
                        document.getElementById('searchmoygenerale').style.display = 'none';
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('savecalendriermoygenerale').style.display = 'block';
                        document.getElementById('saveprogrammemoygenerale').style.display = 'none';
                        document.getElementById('savetotalcumulmoygenerale').style.display = 'none';
                        document.getElementById('savetotalcoefficientmoygenerale').style.display = 'none';
                        document.getElementById('saveobtenumoygenerale').style.display = 'none';
                        document.getElementById('savemoygenerale').style.display = 'none';
                        titre.innerHTML = 'Enregistrer Moyenne Générale';

                        /***************************
                        seladdcalendriermoygenerale
                        *****************************/
                        document.getElementById('seladdcalendriermoygenerale').addEventListener('change', seladdcalendriermoygeneraleChange);
                        async function seladdcalendriermoygeneraleChange(e){
                            e.preventDefault();

                            var userCodeStr = document.getElementById('userCode').innerText;
                            var statutMoyMatiereStr = 'Oui';
                            var docsMoyMatiere = [];

                            var seladdcalendriermoygeneraleStr = document.getElementById('seladdcalendriermoygenerale').value;
                            if(!seladdcalendriermoygeneraleStr){
                                alert('Sélectionner un calendrier scolaire avant de continuer');
                                document.getElementById('seladdcalendriermoygenerale').value = '';
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('savecalendriermoygenerale').style.display = 'block';
                                document.getElementById('saveprogrammemoygenerale').style.display = 'none';
                                document.getElementById('savetotalcumulmoygenerale').style.display = 'none';
                                document.getElementById('savetotalcoefficientmoygenerale').style.display = 'none';
                                document.getElementById('saveobtenumoygenerale').style.display = 'none';
                                document.getElementById('savemoygenerale').style.display = 'none';
                            }else{
                                try {
                                    let resultMoyenne = await db.createIndex({
                                        index: {
                                            fields: [
                                                'anneescolaire',
                                                'moymatierestatut',
                                                'inscritcode'
                                            ]
                                        }
                                    });
                                    resultMoyenne = await db.find({
                                        selector: {
                                            anneescolaire: seladdcalendriermoygeneraleStr,
                                            moymatierestatut: statutMoyMatiereStr,
                                            inscritcode: userCodeStr
                                        }
                                    });
                                    docsMoyMatiere = resultMoyenne.docs;
                                    var resultatMoyMatiere = docsMoyMatiere.length;
                                    if(resultatMoyMatiere === 0){
                                        alert('Aucun Résultat Moyennes par Matière Disponibles');
                                        document.getElementById('seladdcalendriermoygenerale').value = '';
                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('savecalendriermoygenerale').style.display = 'block';
                                        document.getElementById('saveprogrammemoygenerale').style.display = 'none';
                                        document.getElementById('savetotalcumulmoygenerale').style.display = 'none';
                                        document.getElementById('savetotalcoefficientmoygenerale').style.display = 'none';
                                        document.getElementById('saveobtenumoygenerale').style.display = 'none';
                                        document.getElementById('savemoygenerale').style.display = 'none';
                                    }else{
                                        //calcul Total Notes Cumulées
                                        var totalMoyenneCumule = 0;
                                        var totalCoefficientMatiere = 0;
                                        var moyenneGenerale = 0;
                                        for(let i=0; i<docsMoyMatiere.length; i++){
                                            totalMoyenneCumule += parseFloat(docsMoyMatiere[i].moyenneobtenue * docsMoyMatiere[i].matierecoefficient);
                                            totalCoefficientMatiere += parseFloat(docsMoyMatiere[i].matierecoefficient);
                                        }
                                        //calcul Moyenne generale
                                        moyenneGenerale = (totalMoyenneCumule / totalCoefficientMatiere).toFixed(2);
                                        document.getElementById('addtotalcumulmoygenerale').innerText = Math.round(totalMoyenneCumule);
                                        document.getElementById('addtotalcoefficientmoygenerale').innerText = totalCoefficientMatiere;
                                        document.getElementById('addmoygeneraleobtenue').innerText = moyenneGenerale;
                                        //assign data to inputs
                                        document.getElementById('addselectcalendriermoygenerale').value = seladdcalendriermoygeneraleStr;
                                        document.getElementById('addmoyennecumulmoygenerale').value = Math.round(totalMoyenneCumule).toString();
                                        document.getElementById('addcoefficientcumulemoygenerale').value = totalCoefficientMatiere.toString();
                                        document.getElementById('addmoyennemoygenerale').value = moyenneGenerale.toString();

                                        updateAddMoyGeneraleUIWithDocs(docsMoyMatiere);
                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('savecalendriermoygenerale').style.display = 'block';
                                        document.getElementById('saveprogrammemoygenerale').style.display = 'block';
                                        document.getElementById('savetotalcumulmoygenerale').style.display = 'block';
                                        document.getElementById('savetotalcoefficientmoygenerale').style.display = 'block';
                                        document.getElementById('saveobtenumoygenerale').style.display = 'block';
                                        document.getElementById('savemoygenerale').style.display = 'block';
                                    }
                                } catch (error) {
                                    alert('Erreur Exécution');
                                }
                            }
                        }
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }
        /**nav previous page button*/
        document.getElementById('prevaddmoygeneraleBtn_1').addEventListener('click', prevaddmoygenerale1BtnChange);
        async function prevaddmoygenerale1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'none';
            document.getElementById('moyenne').style.display = 'none';
            document.getElementById('moygenerale').style.display = 'block';
            document.getElementById('crudmoygenerale').style.display = 'block';
            document.getElementById('addmoygenerale').style.display = 'none';
            document.getElementById('editmoygenerale').style.display = 'none';
            document.getElementById('deletemoygenerale').style.display = 'none';
            document.getElementById('searchmoygenerale').style.display = 'none';
            titre.innerHTML = 'Calcul Moyenne Générale'; 
        }
        // Adds the document to the HTML First table.
        // If the document already exists in the table, it will be updated.
        function addProgrammeMoyGeneraleTableDocToTable(doc, addProgrammeMoyGeneraleTableBody){
            // Each row of the table has an id attribute set to the doc._id field.
            var tr = addProgrammeMoyGeneraleTableBody.querySelector(`#${doc._id}`);

            

            if( !tr ) {
                tr = document.createElement('tr');
                tr.id = doc._id;
            }
        
            // Remove any existing table cells.
            while(tr.hasChildNodes()) {
                tr.removeChild(tr.childNodes[0]);
            }

            // Create new table cells for text fields.
            var tdMatiere = document.createElement('td');
            tdMatiere.innerText = doc.matierenom;
            tr.appendChild(tdMatiere);

            // Create new table cells for text fields.
            var tdMoyenne = document.createElement('td');
            tdMoyenne.innerText = doc.moyenneobtenue;
            tr.appendChild(tdMoyenne);

            // Create new table cells for text fields.
            var tdCoefficient = document.createElement('td');
            tdCoefficient.innerText = doc.matierecoefficient;
            tr.appendChild(tdCoefficient);

            // Add the row to the top of the table.
            addProgrammeMoyGeneraleTableBody.insertBefore(tr, addProgrammeMoyGeneraleTableBody.nextSibling);
        }
        /**Clear and update the UI to display the docs.*/
        function updateAddMoyGeneraleUIWithDocs(docs) {
            var addProgrammeMoyGeneraleTableBody = document.getElementById('addProgrammeMoyGeneraleTableBody');
            
            // Remove all child nodes from the table
            while(addProgrammeMoyGeneraleTableBody.hasChildNodes()) {
                addProgrammeMoyGeneraleTableBody.removeChild(addProgrammeMoyGeneraleTableBody.childNodes[0]);
            }
            
            // Add the new documents to the table
            for(var i=0; i<docs.length; i++) {
                var doc = docs[i];
                addProgrammeMoyGeneraleTableDocToTable(doc, addProgrammeMoyGeneraleTableBody);
            }
        }
        /***************************
        enregistrermoygeneraleBtn
        *****************************/
        document.getElementById('enregistrermoygeneraleBtn').addEventListener('click', enregistrermoygeneraleBtnChange);
        async function enregistrermoygeneraleBtnChange(e){
            e.preventDefault();

            var usercodeStr = document.getElementById('userCode').innerText;
            var addselectcalendriermoygeneraleStr = document.getElementById('addselectcalendriermoygenerale').value;
            var addmoyennecumulmoygeneraleStr = document.getElementById('addmoyennecumulmoygenerale').value;
            var addcoefficientcumulemoygeneraleStr = document.getElementById('addcoefficientcumulemoygenerale').value;
            var addmoyennemoygeneraleStr = document.getElementById('addmoyennemoygenerale').value;
            var addeffectuemoygeneraleStr = 'Oui';
            var datejourStr = getCurrentDate();
            var addstatutmoygeneraleStr = 'Oui';
            var docsMoyGenerale = [];

            if(!usercodeStr){
                alert("Identifiez-vous à votre Compte avant de continuer");
            }else if(!addselectcalendriermoygeneraleStr){
                alert("Sélectionner Calendrier Scolaire");
            }else if(!addmoyennecumulmoygeneraleStr){
                alert("Reprendre le Calcul Moyenne Générale");
            }else if(!addcoefficientcumulemoygeneraleStr){
                alert("Reprendre le Calcul Moyenne Générale");
            }else if(!addmoyennemoygeneraleStr){
                alert("Reprendre le Calcul Moyenne Générale");
            }else{
                //check if this moyenne generale already exists
                try {
                    let resultMoyGenerale = await db.createIndex({
                        index: {
                            fields: [
                                'inscritcode', 
                                'anneescolaire', 
                                'moygeneraleeffectue',
                                'moygeneralestatut'
                            ]
                        }
                    });
                    resultMoyGenerale = await db.find({
                        selector: {
                            inscritcode: usercodeStr,
                            anneescolaire: addselectcalendriermoygeneraleStr,
                            moygeneraleeffectue: addeffectuemoygeneraleStr,
                            moygeneralestatut: addstatutmoygeneraleStr
                        }
                    });
                    docsMoyGenerale = resultMoyGenerale.docs;
                    var resultatMoyGenerale = docsMoyGenerale.length;
                    if(resultatMoyGenerale === 0){
                        //Insert
                        var now = Date.now();
                        var id = 'moygenerale_' + now;
                        // Add the document to the database.
                        var response = await db.put({
                            _id: id,
                            inscritcode: usercodeStr,
                            anneescolaire: addselectcalendriermoygeneraleStr,
                            moygeneralecumule: addmoyennecumulmoygeneraleStr.toString(),
                            moygeneralecoefficientcumule: addcoefficientcumulemoygeneraleStr.toString(),
                            moygeneraleobtenue: addmoyennemoygeneraleStr.toString(),
                            moygeneraleeffectue: addeffectuemoygeneraleStr,
                            moygeneraledate: datejourStr,
                            moygeneralestatut: addstatutmoygeneraleStr,
                        });
                        // check if document inserted
                        var doc = await db.get(response.id);
                        if(doc !== ''){
                            alert("Enregistrement Moyenne Générale "+addselectcalendriermoygeneraleStr+" effectué avec Succès");
                            document.getElementById('addselectcalendriermoygenerale').value = '';
                            document.getElementById('addmoyennecumulmoygenerale').value = '';
                            document.getElementById('addcoefficientcumulemoygenerale').value = '';
                            document.getElementById('addmoyennemoygenerale').value = '';

                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');

                            document.getElementById('demarrage').style.display = 'none';
                            document.getElementById('tableaubord').style.display = 'none';
                            document.getElementById('dashboard').style.display = 'none';
                            document.getElementById('configurations').style.display = 'none';
                            document.getElementById('configurer').style.display = 'none';
                            document.getElementById('emploidutemps').style.display = 'none';
                            document.getElementById('exercice').style.display = 'none';
                            document.getElementById('pratique').style.display = 'none';
                            document.getElementById('tache').style.display = 'none';
                            document.getElementById('revision').style.display = 'none';
                            document.getElementById('recherche').style.display = 'none';
                            document.getElementById('programme').style.display = 'none';
                            document.getElementById('notes').style.display = 'none';
                            document.getElementById('moyenne').style.display = 'none';
                            document.getElementById('moygenerale').style.display = 'block';
                            document.getElementById('crudmoygenerale').style.display = 'block';
                            document.getElementById('addmoygenerale').style.display = 'none';
                            document.getElementById('editmoygenerale').style.display = 'none';
                            document.getElementById('deletemoygenerale').style.display = 'none';
                            document.getElementById('searchmoygenerale').style.display = 'none';
                            titre.innerHTML = 'Calcul Moyenne Générale'; 
                        }else{
                            alert("Echec Enregistrement Moyenne Générale "+addselectcalendriermoygeneraleStr+". Reprendre");
                            document.getElementById('addselectcalendriermoygenerale').value = '';
                            document.getElementById('addmoyennecumulmoygenerale').value = '';
                            document.getElementById('addcoefficientcumulemoygenerale').value = '';
                            document.getElementById('addmoyennemoygenerale').value = '';

                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');

                            document.getElementById('demarrage').style.display = 'none';
                            document.getElementById('tableaubord').style.display = 'none';
                            document.getElementById('dashboard').style.display = 'none';
                            document.getElementById('configurations').style.display = 'none';
                            document.getElementById('configurer').style.display = 'none';
                            document.getElementById('emploidutemps').style.display = 'none';
                            document.getElementById('exercice').style.display = 'none';
                            document.getElementById('pratique').style.display = 'none';
                            document.getElementById('tache').style.display = 'none';
                            document.getElementById('revision').style.display = 'none';
                            document.getElementById('recherche').style.display = 'none';
                            document.getElementById('programme').style.display = 'none';
                            document.getElementById('notes').style.display = 'none';
                            document.getElementById('moyenne').style.display = 'none';
                            document.getElementById('moygenerale').style.display = 'block';
                            document.getElementById('crudmoygenerale').style.display = 'block';
                            document.getElementById('addmoygenerale').style.display = 'none';
                            document.getElementById('editmoygenerale').style.display = 'none';
                            document.getElementById('deletemoygenerale').style.display = 'none';
                            document.getElementById('searchmoygenerale').style.display = 'none';
                            titre.innerHTML = 'Calcul Moyenne Générale'; 
                        }
                    }else{
                        alert("Moyenne Générale "+addselectcalendriermoygeneraleStr+" déjà Enregistrée. Modification Possible");
                        document.getElementById('addselectcalendriermoygenerale').value = '';
                        document.getElementById('addmoyennecumulmoygenerale').value = '';
                        document.getElementById('addcoefficientcumulemoygenerale').value = '';
                        document.getElementById('addmoyennemoygenerale').value = '';

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'none';
                        document.getElementById('programme').style.display = 'none';
                        document.getElementById('notes').style.display = 'none';
                        document.getElementById('moyenne').style.display = 'none';
                        document.getElementById('moygenerale').style.display = 'block';
                        document.getElementById('crudmoygenerale').style.display = 'block';
                        document.getElementById('addmoygenerale').style.display = 'none';
                        document.getElementById('editmoygenerale').style.display = 'none';
                        document.getElementById('deletemoygenerale').style.display = 'none';
                        document.getElementById('searchmoygenerale').style.display = 'none';
                        titre.innerHTML = 'Calcul Moyenne Générale'; 
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }

        /***************************
        editmoygeneraleBtn
        *****************************/
        document.getElementById('editmoygeneraleBtn').addEventListener('click', editmoygeneraleBtnChange);
        async function editmoygeneraleBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var moygeneraleeffectueStr = 'Oui';
            var statutmoygeneraleStr = 'Oui';

            var docsMoyGenerale = [];

            if(!userCodeStr){
                alert('Connectez-vous à votre Compte avant de continuer');
            }else{
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: ['inscritcode', 'moygeneraleeffectue', 'moygeneralestatut']
                        }
                    });
                    result = await db.find({
                        selector: {
                            inscritcode: userCodeStr,
                            moygeneraleeffectue: moygeneraleeffectueStr,
                            moygeneralestatut: statutmoygeneraleStr
                        }
                    });
                    docsMoyGenerale  = result.docs;
                    var resultatMoyGenerale = docsMoyGenerale.length;
                    if(resultatMoyGenerale === 0){
                        alert('Aucune Moyenne Générale Enregistrée');
                    }else{
                        var divClear;
                        var divModifier;
                        divClear = document.getElementById('editcalendriermoygenerale');
                        while(divClear.hasChildNodes()) {
                            divClear.removeChild(divClear.childNodes[0]);
                        }
                        divModifier = document.getElementById('editcalendriermoygenerale');
                        //Create and append select list
                        var selectList = document.createElement("select");
                        selectList.id = "seleditcalendriermoygenerale";
                        divModifier.appendChild(selectList);
                        //Create and append the options
                        var option_null = document.createElement("option");
                        option_null.value = '';
                        option_null.text = 'Sélectionner Calendrier';
                        selectList.appendChild(option_null);
                        //Create and append the options
                        for (var i = 0; i<docsMoyGenerale.length; i++){
                            var option = document.createElement("option");
                            option.value = docsMoyGenerale[i].anneescolaire;
                            option.text = docsMoyGenerale[i].anneescolaire;
                            selectList.appendChild(option);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'none';
                        document.getElementById('programme').style.display = 'none';
                        document.getElementById('notes').style.display = 'none';
                        document.getElementById('moyenne').style.display = 'none';
                        document.getElementById('moygenerale').style.display = 'block';
                        document.getElementById('crudmoygenerale').style.display = 'none';
                        document.getElementById('addmoygenerale').style.display = 'none';
                        document.getElementById('editmoygenerale').style.display = 'block';
                        document.getElementById('deletemoygenerale').style.display = 'none';
                        document.getElementById('searchmoygenerale').style.display = 'none';
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('changecalendriermoygenerale').style.display = 'block';
                        document.getElementById('changeprogrammemoygenerale').style.display = 'none';
                        document.getElementById('changetotalcumulmoygenerale').style.display = 'none';
                        document.getElementById('changetotalcoefficientmoygenerale').style.display = 'none';
                        document.getElementById('changeobtenumoygenerale').style.display = 'none';
                        document.getElementById('changemoygenerale').style.display = 'none';
                        titre.innerHTML = 'Modifier Moyenne Générale';

                        /***************************
                        seleditcalendriermoygenerale
                        *****************************/
                        document.getElementById('seleditcalendriermoygenerale').addEventListener('change', seleditcalendriermoygeneraleChange);
                        async function seleditcalendriermoygeneraleChange(e){
                            e.preventDefault();

                            var userCodeStr = document.getElementById('userCode').innerText;
                            var statutMoyMatiereStr = 'Oui';
                            var docsMoyMatiere = [];

                            var seleditcalendriermoygeneraleStr = document.getElementById('seleditcalendriermoygenerale').value;
                            if(!seleditcalendriermoygeneraleStr){
                                alert('Sélectionner un calendrier scolaire avant de continuer');
                                document.getElementById('seleditcalendriermoygenerale').value = '';
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('changecalendriermoygenerale').style.display = 'block';
                                document.getElementById('changeprogrammemoygenerale').style.display = 'none';
                                document.getElementById('changetotalcumulmoygenerale').style.display = 'none';
                                document.getElementById('changetotalcoefficientmoygenerale').style.display = 'none';
                                document.getElementById('changeobtenumoygenerale').style.display = 'none';
                                document.getElementById('changemoygenerale').style.display = 'none';
                            }else{
                                try {
                                    let resultMoyenne = await db.createIndex({
                                        index: {
                                            fields: [
                                                'anneescolaire',
                                                'moymatierestatut',
                                                'inscritcode'
                                            ]
                                        }
                                    });
                                    resultMoyenne = await db.find({
                                        selector: {
                                            anneescolaire: seleditcalendriermoygeneraleStr,
                                            moymatierestatut: statutMoyMatiereStr,
                                            inscritcode: userCodeStr
                                        }
                                    });
                                    docsMoyMatiere = resultMoyenne.docs;
                                    
                                    var resultatMoyMatiere = docsMoyMatiere.length;
                                    if(resultatMoyMatiere === 0){
                                        alert('Aucun Résultat Moyennes par Matière Disponibles');
                                        document.getElementById('seleditcalendriermoygenerale').value = '';
                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('changecalendriermoygenerale').style.display = 'block';
                                        document.getElementById('changeprogrammemoygenerale').style.display = 'none';
                                        document.getElementById('changetotalcumulmoygenerale').style.display = 'none';
                                        document.getElementById('changetotalcoefficientmoygenerale').style.display = 'none';
                                        document.getElementById('changeobtenumoygenerale').style.display = 'none';
                                        document.getElementById('changemoygenerale').style.display = 'none';
                                    }else{
                                        var statutmoyenneStr = 'Oui';
                                        var effectuemoyenneStr = 'Oui';
                                        var docsMoyenne = [];
                                        var idMoyenne,anneeScolaireStr,moyGeneraleEffectueStr,
                                        moyGeneraleDateStr,moyGeneraleStatutStr = '';
                                        let resultMoyenne = await db.createIndex({
                                            index: {
                                                fields: [
                                                    'inscritcode',
                                                    'moygeneralestatut',
                                                    'moygeneraleeffectue', 
                                                    'anneescolaire'
                                                ]
                                            }
                                        });
                                        resultMoyenne = await db.find({
                                            selector: {
                                                inscritcode: userCodeStr,
                                                moygeneralestatut: statutmoyenneStr,
                                                moygeneraleeffectue: effectuemoyenneStr,
                                                anneescolaire: seleditcalendriermoygeneraleStr
                                            }
                                        });
                                        docsMoyenne = resultMoyenne.docs;
                                        for(let k=0; k<docsMoyenne.length; k++){
                                            idMoyenne = docsMoyenne[k]._id;
                                            anneeScolaireStr = docsMoyenne[k].anneescolaire;
                                            moyGeneraleEffectueStr = docsMoyenne[k].moygeneraleeffectue;
                                            moyGeneraleDateStr = docsMoyenne[k].moygeneraledate;
                                            moyGeneraleStatutStr = docsMoyenne[k].moygeneralestatut;
                                        }
                                        document.getElementById('editidmoygenerale').value = idMoyenne;
                                        document.getElementById('editselectcalendriermoygenerale').value = anneeScolaireStr;
                                        document.getElementById('editmoygeneraleeffectue').value = moyGeneraleEffectueStr;
                                        document.getElementById('editmoygeneraledate').value = moyGeneraleDateStr;
                                        document.getElementById('editmoygeneralestatut').value = moyGeneraleStatutStr;

                                        //calcul Total Notes Cumulées
                                        var totalMoyenneCumule = 0;
                                        var totalCoefficientMatiere = 0;
                                        var moyenneGenerale = 0;
                                        for(let i=0; i<docsMoyMatiere.length; i++){
                                            totalMoyenneCumule += parseFloat(docsMoyMatiere[i].moyenneobtenue * docsMoyMatiere[i].matierecoefficient);
                                            totalCoefficientMatiere += parseFloat(docsMoyMatiere[i].matierecoefficient);
                                        }
                                        //calcul Moyenne generale
                                        moyenneGenerale = (totalMoyenneCumule / totalCoefficientMatiere).toFixed(2);
                                        document.getElementById('edittotalcumulmoygenerale').innerText = Math.round(totalMoyenneCumule);
                                        document.getElementById('edittotalcoefficientmoygenerale').innerText = totalCoefficientMatiere;
                                        document.getElementById('editmoygeneraleobtenue').innerText = moyenneGenerale;
                                        //assign data to inputs
                                        document.getElementById('editselectcalendriermoygenerale').value = seleditcalendriermoygeneraleStr;
                                        document.getElementById('editmoyennecumulmoygenerale').value = Math.round(totalMoyenneCumule).toString();
                                        document.getElementById('editcoefficientcumulemoygenerale').value = totalCoefficientMatiere.toString();
                                        document.getElementById('editmoyennemoygenerale').value = moyenneGenerale.toString();

                                        updateEditMoyGeneraleUIWithDocs(docsMoyMatiere);
                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('changecalendriermoygenerale').style.display = 'block';
                                        document.getElementById('changeprogrammemoygenerale').style.display = 'block';
                                        document.getElementById('changetotalcumulmoygenerale').style.display = 'block';
                                        document.getElementById('changetotalcoefficientmoygenerale').style.display = 'block';
                                        document.getElementById('changeobtenumoygenerale').style.display = 'block';
                                        document.getElementById('changemoygenerale').style.display = 'block';
                                    }
                                } catch (error) {
                                    alert('Erreur Exécution');
                                }
                            }
                        }
                    }
                } catch (error) {
                   alert('Erreur Exécution'); 
                }
            }
        }
        /**nav previous page button*/
        document.getElementById('preveditmoygeneraleBtn_1').addEventListener('click', preveditmoygenerale1BtnChange);
        async function preveditmoygenerale1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'none';
            document.getElementById('moyenne').style.display = 'none';
            document.getElementById('moygenerale').style.display = 'block';
            document.getElementById('crudmoygenerale').style.display = 'block';
            document.getElementById('addmoygenerale').style.display = 'none';
            document.getElementById('editmoygenerale').style.display = 'none';
            document.getElementById('deletemoygenerale').style.display = 'none';
            document.getElementById('searchmoygenerale').style.display = 'none';
            titre.innerHTML = 'Calcul Moyenne Générale'; 
        }
        // Adds the document to the HTML First table.
        // If the document already exists in the table, it will be updated.
        function editProgrammeMoyGeneraleTableDocToTable(doc, editProgrammeMoyGeneraleTableBody){
            // Each row of the table has an id attribute set to the doc._id field.
            var tr = editProgrammeMoyGeneraleTableBody.querySelector(`#${doc._id}`);

            

            if( !tr ) {
                tr = document.createElement('tr');
                tr.id = doc._id;
            }
        
            // Remove any existing table cells.
            while(tr.hasChildNodes()) {
                tr.removeChild(tr.childNodes[0]);
            }

            // Create new table cells for text fields.
            var tdMatiere = document.createElement('td');
            tdMatiere.innerText = doc.matierenom;
            tr.appendChild(tdMatiere);

            // Create new table cells for text fields.
            var tdMoyenne = document.createElement('td');
            tdMoyenne.innerText = doc.moyenneobtenue;
            tr.appendChild(tdMoyenne);

            // Create new table cells for text fields.
            var tdCoefficient = document.createElement('td');
            tdCoefficient.innerText = doc.matierecoefficient;
            tr.appendChild(tdCoefficient);

            // Add the row to the top of the table.
            editProgrammeMoyGeneraleTableBody.insertBefore(tr, editProgrammeMoyGeneraleTableBody.nextSibling);
        }
        /**Clear and update the UI to display the docs.*/
        function updateEditMoyGeneraleUIWithDocs(docs) {
            var editProgrammeMoyGeneraleTableBody = document.getElementById('editProgrammeMoyGeneraleTableBody');
            
            // Remove all child nodes from the table
            while(editProgrammeMoyGeneraleTableBody.hasChildNodes()) {
                editProgrammeMoyGeneraleTableBody.removeChild(editProgrammeMoyGeneraleTableBody.childNodes[0]);
            }
            
            // Add the new documents to the table
            for(var i=0; i<docs.length; i++) {
                var doc = docs[i];
                editProgrammeMoyGeneraleTableDocToTable(doc, editProgrammeMoyGeneraleTableBody);
            }
        }
        /***************************
        modifiermoygeneraleBtn
        *****************************/
        document.getElementById('modifiermoygeneraleBtn').addEventListener('click', modifiermoygeneraleBtnChange);
        async function modifiermoygeneraleBtnChange(e){
            e.preventDefault();

            var usercodeStr = document.getElementById('userCode').innerText;
            var editidmoygeneraleStr = document.getElementById('editidmoygenerale').value;
            var editselectcalendriermoygeneraleStr = document.getElementById('editselectcalendriermoygenerale').value;
            var editmoyennecumulmoygeneraleStr = document.getElementById('editmoyennecumulmoygenerale').value;
            var editcoefficientcumulemoygeneraleStr = document.getElementById('editcoefficientcumulemoygenerale').value;
            var editmoyennemoygeneraleStr = document.getElementById('editmoyennemoygenerale').value;
            var editmoygeneraleeffectueStr = document.getElementById('editmoygeneraleeffectue').value;
            var datejourStr = document.getElementById('editmoygeneraledate').value;
            var editmoygeneralestatutStr = document.getElementById('editmoygeneralestatut').value;
            var docsMoyGenerale = [];
            

            if(!usercodeStr){
                alert("Identifiez-vous avant de continuer");
            }else{
                try {
                    //check if this moyenne matiere already exists
                    let resultMoyGenerale = await db.createIndex({
                        index: {
                            fields: [
                                '_id',
                                'inscritcode',
                                'anneescolaire',
                                'moygeneralestatut'
                            ]
                        }
                    });
                    resultMoyGenerale = await db.find({
                        selector: {
                            _id: editidmoygeneraleStr,
                            inscritcode: usercodeStr,
                            anneescolaire: editselectcalendriermoygeneraleStr,
                            moygeneralestatut: editmoygeneralestatutStr
                        }
                    });
                    docsMoyGenerale = resultMoyGenerale.docs;
                    var resultatMoyGenerale = docsMoyGenerale.length;
                    if(resultatMoyGenerale === 0){
                        alert("Moyenne Générale "+editselectcalendriermoygeneraleStr+" non encore enregistrée");
                    }else{
                        var moyenneObtenue = 0;
                        for(let i=0; i<docsMoyGenerale.length; i++){
                            moyenneObtenue = parseFloat(docsMoyGenerale[i].moygeneraleobtenue);
                        }
                        if(moyenneObtenue === parseFloat(editmoyennemoygeneraleStr)){
                            alert("Moyenne Générale "+editselectcalendriermoygeneraleStr+" Modifiée avec Succès");
                            document.getElementById('editidmoygenerale').value = '';
                            document.getElementById('editselectcalendriermoygenerale').value = '';
                            document.getElementById('editmoyennecumulmoygenerale').value = '';
                            document.getElementById('editcoefficientcumulemoygenerale').value = '';
                            document.getElementById('editmoyennemoygenerale').value = '';
                            document.getElementById('editmoygeneraleeffectue').value = '';
                            document.getElementById('editmoygeneraledate').value = '';
                            document.getElementById('editmoygeneralestatut').value = '';

                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');

                            document.getElementById('demarrage').style.display = 'none';
                            document.getElementById('tableaubord').style.display = 'none';
                            document.getElementById('dashboard').style.display = 'none';
                            document.getElementById('configurations').style.display = 'none';
                            document.getElementById('configurer').style.display = 'none';
                            document.getElementById('emploidutemps').style.display = 'none';
                            document.getElementById('exercice').style.display = 'none';
                            document.getElementById('pratique').style.display = 'none';
                            document.getElementById('tache').style.display = 'none';
                            document.getElementById('revision').style.display = 'none';
                            document.getElementById('recherche').style.display = 'none';
                            document.getElementById('programme').style.display = 'none';
                            document.getElementById('notes').style.display = 'none';
                            document.getElementById('moyenne').style.display = 'none';
                            document.getElementById('moygenerale').style.display = 'block';
                            document.getElementById('crudmoygenerale').style.display = 'block';
                            document.getElementById('addmoygenerale').style.display = 'none';
                            document.getElementById('editmoygenerale').style.display = 'none';
                            document.getElementById('deletemoygenerale').style.display = 'none';
                            document.getElementById('searchmoygenerale').style.display = 'none';
                            titre.innerHTML = 'Calcul Moyenne Générale';
                        }else{
                            // Retrieve the document to get the latest revision
                            var idModifier = editidmoygeneraleStr;
                            var docModifier = await db.get(idModifier);
                            // Make changes
                            docModifier.inscritcode = usercodeStr;
                            docModifier.anneescolaire = editselectcalendriermoygeneraleStr;
                            docModifier.moygeneralecumule = editmoyennecumulmoygeneraleStr;
                            docModifier.moygeneralecoefficientcumule = editcoefficientcumulemoygeneraleStr;
                            docModifier.moygeneraleobtenue = editmoyennemoygeneraleStr;
                            docModifier.moygeneraleeffectue = editmoygeneraleeffectueStr;
                            docModifier.moygeneraledate = datejourStr;
                            docModifier.moygeneralestatut = editmoygeneralestatutStr;

                            // Put the document, saving it as a new revision
                            var responseModifier = await db.put(docModifier);
                            // Get the document we change and update the UI.
                            var docEdit = await db.get(responseModifier.id);
                            if(docEdit !== ''){
                                alert("Moyenne Générale "+editselectcalendriermoygeneraleStr+" Modifiée avec Succès. Mettre à jour Moyenne Annuelle si déjà Enregistrée.");
                                document.getElementById('editidmoygenerale').value = '';
                                document.getElementById('editselectcalendriermoygenerale').value = '';
                                document.getElementById('editmoyennecumulmoygenerale').value = '';
                                document.getElementById('editcoefficientcumulemoygenerale').value = '';
                                document.getElementById('editmoyennemoygenerale').value = '';
                                document.getElementById('editmoygeneraleeffectue').value = '';
                                document.getElementById('editmoygeneraledate').value = '';
                                document.getElementById('editmoygeneralestatut').value = '';

                                document.getElementById('titre').style.display = 'block';
                                var titre = document.getElementById('titre');

                                document.getElementById('demarrage').style.display = 'none';
                                document.getElementById('tableaubord').style.display = 'none';
                                document.getElementById('dashboard').style.display = 'none';
                                document.getElementById('configurations').style.display = 'none';
                                document.getElementById('configurer').style.display = 'none';
                                document.getElementById('emploidutemps').style.display = 'none';
                                document.getElementById('exercice').style.display = 'none';
                                document.getElementById('pratique').style.display = 'none';
                                document.getElementById('tache').style.display = 'none';
                                document.getElementById('revision').style.display = 'none';
                                document.getElementById('recherche').style.display = 'none';
                                document.getElementById('programme').style.display = 'none';
                                document.getElementById('notes').style.display = 'none';
                                document.getElementById('moyenne').style.display = 'none';
                                document.getElementById('moygenerale').style.display = 'block';
                                document.getElementById('crudmoygenerale').style.display = 'block';
                                document.getElementById('addmoygenerale').style.display = 'none';
                                document.getElementById('editmoygenerale').style.display = 'none';
                                document.getElementById('deletemoygenerale').style.display = 'none';
                                document.getElementById('searchmoygenerale').style.display = 'none';
                                titre.innerHTML = 'Calcul Moyenne Générale';
                            }else{
                                alert("Echec Modification Moyenne Générale "+editselectcalendriermoygeneraleStr+". Reprendre");
                                document.getElementById('editidmoygenerale').value = '';
                                document.getElementById('editselectcalendriermoygenerale').value = '';
                                document.getElementById('editmoyennecumulmoygenerale').value = '';
                                document.getElementById('editcoefficientcumulemoygenerale').value = '';
                                document.getElementById('editmoyennemoygenerale').value = '';
                                document.getElementById('editmoygeneraleeffectue').value = '';
                                document.getElementById('editmoygeneraledate').value = '';
                                document.getElementById('editmoygeneralestatut').value = '';

                                document.getElementById('titre').style.display = 'block';
                                var titre = document.getElementById('titre');

                                document.getElementById('demarrage').style.display = 'none';
                                document.getElementById('tableaubord').style.display = 'none';
                                document.getElementById('dashboard').style.display = 'none';
                                document.getElementById('configurations').style.display = 'none';
                                document.getElementById('configurer').style.display = 'none';
                                document.getElementById('emploidutemps').style.display = 'none';
                                document.getElementById('exercice').style.display = 'none';
                                document.getElementById('pratique').style.display = 'none';
                                document.getElementById('tache').style.display = 'none';
                                document.getElementById('revision').style.display = 'none';
                                document.getElementById('recherche').style.display = 'none';
                                document.getElementById('programme').style.display = 'none';
                                document.getElementById('notes').style.display = 'none';
                                document.getElementById('moyenne').style.display = 'none';
                                document.getElementById('moygenerale').style.display = 'block';
                                document.getElementById('crudmoygenerale').style.display = 'block';
                                document.getElementById('addmoygenerale').style.display = 'none';
                                document.getElementById('editmoygenerale').style.display = 'none';
                                document.getElementById('deletemoygenerale').style.display = 'none';
                                document.getElementById('searchmoygenerale').style.display = 'none';
                                titre.innerHTML = 'Calcul Moyenne Générale';
                            }
                        }
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }

        /***************************
        deletemoygeneraleBtn
        *****************************/
        document.getElementById('deletemoygeneraleBtn').addEventListener('click', deletemoygeneraleBtnChange);
        async function deletemoygeneraleBtnChange(e){
            e.preventDefault();

            alert("Impossible Supprimer une Moyenne Générale Trimestrielle/Semestrielle une fois Enregistrée");
        }

        /***************************
        searchmoygeneraleBtn
        *****************************/
        document.getElementById('searchmoygeneraleBtn').addEventListener('click', searchmoygeneraleBtnChange);
        async function searchmoygeneraleBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var moygeneraleeffectueStr = 'Oui';
            var statutmoygeneraleStr = 'Oui';

            var docsMoyGenerale = [];

            if(!userCodeStr){
                alert('Connectez-vous à votre Compte avant de continuer');
            }else{
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: ['inscritcode', 'moygeneraleeffectue', 'moygeneralestatut']
                        }
                    });
                    result = await db.find({
                        selector: {
                            inscritcode: userCodeStr,
                            moygeneraleeffectue: moygeneraleeffectueStr,
                            moygeneralestatut: statutmoygeneraleStr
                        }
                    });
                    docsMoyGenerale  = result.docs;
                    var resultatMoyGenerale = docsMoyGenerale.length;
                    if(resultatMoyGenerale === 0){
                        alert('Aucune Moyenne Générale Enregistrée');
                    }else{
                        var divClear;
                        var divModifier;
                        divClear = document.getElementById('searchcalendriermoygenerale');
                        while(divClear.hasChildNodes()) {
                            divClear.removeChild(divClear.childNodes[0]);
                        }
                        divModifier = document.getElementById('searchcalendriermoygenerale');
                        //Create and append select list
                        var selectList = document.createElement("select");
                        selectList.id = "selsearchcalendriermoygenerale";
                        divModifier.appendChild(selectList);
                        //Create and append the options null
                        var option_null = document.createElement("option");
                        option_null.value = '';
                        option_null.text = 'Sélectionner Calendrier';
                        selectList.appendChild(option_null);
                        //Create and append the options Tous
                        var option_tous = document.createElement("option");
                        option_tous.value = 'Tous';
                        option_tous.text = 'Tous';
                        selectList.appendChild(option_tous);
                        //Create and append the options
                        for (var i = 0; i<docsMoyGenerale.length; i++){
                            var option = document.createElement("option");
                            option.value = docsMoyGenerale[i].anneescolaire;
                            option.text = docsMoyGenerale[i].anneescolaire;
                            selectList.appendChild(option);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'none';
                        document.getElementById('programme').style.display = 'none';
                        document.getElementById('notes').style.display = 'none';
                        document.getElementById('moyenne').style.display = 'none';
                        document.getElementById('moygenerale').style.display = 'block';
                        document.getElementById('crudmoygenerale').style.display = 'none';
                        document.getElementById('addmoygenerale').style.display = 'none';
                        document.getElementById('editmoygenerale').style.display = 'none';
                        document.getElementById('deletemoygenerale').style.display = 'none';
                        document.getElementById('searchmoygenerale').style.display = 'block';
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('viewcalendriermoygenerale').style.display = 'block';
                        document.getElementById('viewprogrammemoygenerale').style.display = 'none';
                        document.getElementById('viewconsultermoygenerale').style.display = 'none';
                        titre.innerHTML = 'Consulter Moyenne Générale';

                        /***************************
                        selsearchcalendriermoygenerale
                        *****************************/
                        document.getElementById('selsearchcalendriermoygenerale').addEventListener('change', selsearchcalendriermoygeneraleChange);
                        async function selsearchcalendriermoygeneraleChange(e){
                            e.preventDefault();

                            var userCodeStr = document.getElementById('userCode').innerText;
                            var statutMoyGeneraleStr = 'Oui';
                            var effectueMoyGeneraleStr = 'Oui';
                            var docsMoyGeneraleTous = [];
                            var docsMoyGenerale = [];

                            var selsearchcalendriermoygeneraleStr = document.getElementById('selsearchcalendriermoygenerale').value;
                            if(!selsearchcalendriermoygeneraleStr){
                                alert('Sélectionner un calendrier scolaire avant de continuer');
                                document.getElementById('selsearchcalendriermoygenerale').value = '';
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendriermoygenerale').style.display = 'block';
                                document.getElementById('viewprogrammemoygenerale').style.display = 'none';
                                document.getElementById('viewconsultermoygenerale').style.display = 'none';
                            }else{
                                if(selsearchcalendriermoygeneraleStr === 'Tous'){
                                    try {
                                        let resultMoyenneTous = await db.createIndex({
                                            index: {
                                                fields: [
                                                    'moygeneraleeffectue',
                                                    'moygeneralestatut',
                                                    'inscritcode'
                                                ]
                                            }
                                        });
                                        resultMoyenneTous = await db.find({
                                            selector: {
                                                moygeneraleeffectue: effectueMoyGeneraleStr,
                                                moygeneralestatut: statutMoyGeneraleStr,
                                                inscritcode: userCodeStr
                                            }
                                        });
                                        docsMoyGeneraleTous = resultMoyenneTous.docs;
                                        var resultatMoyGeneraleTous = docsMoyGeneraleTous.length;
                                        if(resultatMoyGeneraleTous === 0){
                                            alert('Aucun Résultat Moyennes Générales Disponibles');
                                            document.getElementById('selsearchcalendriermoygenerale').value = '';
                                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                            document.getElementById('viewcalendriermoygenerale').style.display = 'block';
                                            document.getElementById('viewprogrammemoygenerale').style.display = 'none';
                                            document.getElementById('viewconsultermoygenerale').style.display = 'none';
                                        }else{
                                            updateSearchMoyGeneraleUIWithDocs(docsMoyGeneraleTous);
                                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                            document.getElementById('viewcalendriermoygenerale').style.display = 'block';
                                            document.getElementById('viewprogrammemoygenerale').style.display = 'block';
                                            document.getElementById('viewconsultermoygenerale').style.display = 'block';
                                        }
                                    } catch (error) {
                                        alert('Erreur Exécution');
                                    }
                                }else{
                                    try {
                                        let resultMoyenne = await db.createIndex({
                                            index: {
                                                fields: [
                                                    'anneescolaire',
                                                    'moygeneraleeffectue',
                                                    'moygeneralestatut',
                                                    'inscritcode'
                                                ]
                                            }
                                        });
                                        resultMoyenne = await db.find({
                                            selector: {
                                                anneescolaire: selsearchcalendriermoygeneraleStr,
                                                moygeneraleeffectue: effectueMoyGeneraleStr,
                                                moygeneralestatut: statutMoyGeneraleStr,
                                                inscritcode: userCodeStr
                                            }
                                        });
                                        docsMoyGenerale = resultMoyenne.docs;
                                        var resultatMoyGenerale = docsMoyGenerale.length;
                                        if(resultatMoyGenerale === 0){
                                            alert('Aucun Résultat Moyennes Générales Disponibles');
                                            document.getElementById('selsearchcalendriermoygenerale').value = '';
                                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                            document.getElementById('viewcalendriermoygenerale').style.display = 'block';
                                            document.getElementById('viewprogrammemoygenerale').style.display = 'none';
                                            document.getElementById('viewconsultermoygenerale').style.display = 'none';
                                        }else{
                                            updateSearchMoyGeneraleUIWithDocs(docsMoyGenerale);
                                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                            document.getElementById('viewcalendriermoygenerale').style.display = 'block';
                                            document.getElementById('viewprogrammemoygenerale').style.display = 'block';
                                            document.getElementById('viewconsultermoygenerale').style.display = 'block';
                                        }
                                    } catch (error) {
                                        alert('Erreur Exécution');
                                    }
                                }
                            }
                        }
                    }
                } catch (error) {
                   alert('Erreur Exécution'); 
                }
            }
        }
        // Adds the document to the HTML First table.
        // If the document already exists in the table, it will be updated.
        function searchProgrammeMoyGeneraleTableDocToTable(doc, searchProgrammeMoyGeneraleTableBody){
            // Each row of the table has an id attribute set to the doc._id field.
            var tr = searchProgrammeMoyGeneraleTableBody.querySelector(`#${doc._id}`);

            

            if( !tr ) {
                tr = document.createElement('tr');
                tr.id = doc._id;
            }
        
            // Remove any existing table cells.
            while(tr.hasChildNodes()) {
                tr.removeChild(tr.childNodes[0]);
            }

            // Create new table cells for text fields.
            var tdTrimestre = document.createElement('td');
            tdTrimestre.innerText = doc.anneescolaire;
            tr.appendChild(tdTrimestre);

            // Create new table cells for text fields.
            var tdMoyenne = document.createElement('td');
            tdMoyenne.innerText = doc.moygeneraleobtenue;
            tr.appendChild(tdMoyenne);


            // Add the row to the top of the table.
            searchProgrammeMoyGeneraleTableBody.insertBefore(tr, searchProgrammeMoyGeneraleTableBody.nextSibling);
        }
        /**Clear and update the UI to display the docs.*/
        function updateSearchMoyGeneraleUIWithDocs(docs) {
            var searchProgrammeMoyGeneraleTableBody = document.getElementById('searchProgrammeMoyGeneraleTableBody');
            
            // Remove all child nodes from the table
            while(searchProgrammeMoyGeneraleTableBody.hasChildNodes()) {
                searchProgrammeMoyGeneraleTableBody.removeChild(searchProgrammeMoyGeneraleTableBody.childNodes[0]);
            }
            
            // Add the new documents to the table
            for(var i=0; i<docs.length; i++) {
                var doc = docs[i];
                searchProgrammeMoyGeneraleTableDocToTable(doc, searchProgrammeMoyGeneraleTableBody);
            }
        }
        document.getElementById('consultermoygeneraleBtn').addEventListener('click', consultermoygeneraleBtnChange);
        async function consultermoygeneraleBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'none';
            document.getElementById('moyenne').style.display = 'none';
            document.getElementById('moygenerale').style.display = 'block';
            document.getElementById('crudmoygenerale').style.display = 'block';
            document.getElementById('addmoygenerale').style.display = 'none';
            document.getElementById('editmoygenerale').style.display = 'none';
            document.getElementById('deletemoygenerale').style.display = 'none';
            document.getElementById('searchmoygenerale').style.display = 'none';
            titre.innerHTML = 'Calcul Moyenne Générale'; 
        }

        /**nav previous page button*/
        document.getElementById('prevsearchmoygeneraleBtn_1').addEventListener('click', prevsearchmoygenerale1BtnChange);
        async function prevsearchmoygenerale1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            document.getElementById('notes').style.display = 'none';
            document.getElementById('moyenne').style.display = 'none';
            document.getElementById('moygenerale').style.display = 'block';
            document.getElementById('crudmoygenerale').style.display = 'block';
            document.getElementById('addmoygenerale').style.display = 'none';
            document.getElementById('editmoygenerale').style.display = 'none';
            document.getElementById('deletemoygenerale').style.display = 'none';
            document.getElementById('searchmoygenerale').style.display = 'none';
            titre.innerHTML = 'Calcul Moyenne Générale'; 
        }
    }

    /****************
    HELPER FUNCTION
    *****************/
    /**1: Get Current Date in JavaScript */
    function getCurrentDate(){
        // Date object
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1;
        var yyyy = today.getFullYear();
        if(dd<10){
            dd='0'+dd;
        }
        if(mm<10){
            mm='0'+mm;
        }
        // Current Date
        var date = yyyy+'-'+mm+'-'+dd;
        return date;
    }

    window.addEventListener('load', () =>{
        new taskMoyGenerale();
    })
}) ();