import './moymatiere.css';
import './pouchmoymatiere';
import AjouterButton from '../../components/AjouterButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function AddMoyMatiere() {
    return ( 
        <div id="addmoymatiere">
            <KeyboardBackspace style={prevArrows} id="prevaddmoymatiereBtn_1" />
            <br/><br/>
            <form>
                <div id="formaddmoymatiere" style={scroller}>
                    <br/>
                    <div id="savecalendriermoymatiere">
                        <label style={labelStyle}>Sélection Calendrier Scolaire</label>
                        <div id="addcalendriermoymatiere"></div>
                    </div>
                    <br/>
                    <div id="savematieremoymatiere">
                        <label style={labelStyle}>Sélection Matière</label>
                        <div id="addmatieremoymatiere"></div>
                    </div>
                    <br/>
                    <div id="saveprogrammemoymatiere">
                        <label id="addmatiereprogrammemoymatiere" style={labelStyle}>Trimestre+Matière</label>
                        <br/><br/>
                        <table className='thin_table_config'>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Type</th>
                                    <th>Note</th>
                                    <th>Coeff</th>
                                </tr>
                            </thead>
                            <tbody id='addProgrammeMoyMatiereTableBody'></tbody>
                        </table>
                    </div>
                    <br/>
                    <div id="savetotalcumulmoymatiere">
                        <label style={labelStyle}>Total Notes Cumulées</label>
                        <div id="addtotalcumulmoymatiere"></div>
                    </div>
                    <div id="savetotalcoefficientmoymatiere">
                        <label style={labelStyle}>Total Coefficients Cumulés</label>
                        <div id="addtotalcoefficientmoymatiere"></div>
                    </div>
                    <div id="saveobtenumoymatiere">
                        <label style={labelStyle}>Moyenne Obtenue</label>
                        <div style={{fontWeight:'bold',color:'#1a1aff'}} id="addobtenuemoymatiere"></div>
                    </div>
                    <br/>
                    
                    <div style={{display:'none'}}>
                        <input id ="addselectcalendriermoymatiere" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="addselectmatieremoymatiere" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="addcoefficientmoymatiere" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="addnotecumulmoymatiere" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="addcoefficientcumulemoymatiere" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="addmoyennemoymatiere" type="text" />
                    </div>
                    <br/>
                    <div id="savemoymatiere">
                        <AjouterButton 
                            idajouterbutton="enregistrermoymatiereBtn"
                            ajouter="Enregistrer"
                        />
                    </div>
                    <br/><br/>
                </div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default AddMoyMatiere;