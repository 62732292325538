import CrudAnnee from "./CrudAnnee";
import AddAnnee from "./AddAnnee";
import EditAnnee from "./EditAnnee";
import DeleteAnnee from "./DeleteAnnee";
import SearchAnnee from "./SearchAnnee";

function Annee() {
    return ( 
        <div id="annee">
            <CrudAnnee />
            <AddAnnee />
            <EditAnnee />
            <DeleteAnnee />
            <SearchAnnee />
        </div>
    );
}

export default Annee;