import './pratique.css';
import './pouchpratique';
import ConsulterButton from '../../components/ConsulterButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function SearchPratique() {
    return ( 
        <div id="searchpratique">
            <KeyboardBackspace style={prevArrows} id="prevsearchpratiqueBtn_1" />
            <br/><br/>
            <form>
                <div id="formsearchpratique" style={scroller}>
                    <br/>
                    <div>
                        <label style={labelStyle}>Sélection matière</label>
                        <div id="searchmatierepratique"></div>
                    </div>
                    <br/>
                    <div id="vieweffectue">
                        <label style={labelStyle}>Exercice Effectué</label>
                        <select id="searcheffectue">
                            <option value="">Sélectionner</option>
                            <option value='Oui'>Oui</option>
                            <option value="Non">Non</option>
                        </select>
                    </div>
                    <br/>
                    <div id="viewpratique">
                        <label id="viewmatierepratique" style={labelStyle}>Matière</label>
                        <br/><br/>
                        <table className='thin_table_config'>
                            <thead>
                                <tr>
                                    <th>Echéance</th>
                                    <th>Enoncé</th>
                                </tr>
                            </thead>
                            <tbody id='viewSearchPratiueTableBody'></tbody>
                        </table>
                    </div>
                    <br/>
                    <div id="viewconsulterpratique">
                        <ConsulterButton 
                        idconsulterbutton="consulterpratiqueBtn"
                        consulter="OK"
                        />
                    </div>
                    <br/><br/>
                </div>
                <div id="modaloutsearchpratique"></div>
                <div id="backdropoutsearchpratique"></div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default SearchPratique;