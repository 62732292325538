import '../configuration.css';
import './pouchconfigclasse';
import TextInput from '../../components/TextInput';
import SupprimerButton from '../../components/SupprimerButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function DeleteClasse() {
    return ( 
        <div id="deleteclasse">
            <KeyboardBackspace style={prevArrows} id="prevdeleteclasseBtn_1" />
            <br/><br/>
            <form>
                <br/>
                <div id="formdeleteclasse" style={scroller}>
                    <br/>
                    <div>
                        <label style={labelStyle}>Classe</label>
                        <TextInput idinput ="deletenomclasse" texte="text" read="readOnly" />
                    </div>
                    <br/>
                    <div>
                        <label style={labelStyle}>Numéro</label>
                        <TextInput idinput ="deletenumeroclasse" texte="text" read="readOnly" />
                    </div>
                    <br/>
                    <div>
                        <label style={labelStyle}>Nom Professeur Principal</label>
                        <TextInput idinput ="deleteprofprincipalclasse" texte="text" read="readOnly"/>
                    </div>
                    <br/>
                    <div style={{display:'none'}}>
                        <input id ="deleteidclasse" type="text" />
                    </div>
                    <SupprimerButton 
                    idsupprimerbutton="supprimerclasseBtn"
                    supprimer="Supprimer"
                    />
                    <br/><br/>
                </div>
                <div id="modaloutclasse"></div>
                <div id="backdropoutclasse"></div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default DeleteClasse;