function TimeInput(props) {
    return ( 
        <div>
            <input style={inputTimeStyle} id={props.idinput} type={props.time} readOnly={props.read} />
        </div>
    );
}

const inputTimeStyle = {
    width: '88%',
    height: 40,
    color:"#000000",
    fontSize: 18,
    //fontWeight: "bold",
};

export default TimeInput;