import CrudTache from "./CrudTache";
import AddTache from "./AddTache";
import EditTache from "./EditTache";
import DeleteTache from "./DeleteTache";
import SearchTache from "./SearchTache";

function Tache() {
    return ( 
        <div id="tache">
            <CrudTache />
            <AddTache />
            <EditTache />
            <DeleteTache />
            <SearchTache />
        </div>
    );
}

export default Tache;