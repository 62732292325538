import Nav from './Nav';

function Accueil() {
    return ( 
        <div>
            <Nav />
        </div>
    );
}

export default Accueil;