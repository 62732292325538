import './detail.css';

function DetailUser() {
    return ( 
        <div id="detailuser">
            <label className="details" id="detailprenoms">Prenoms</label><br/><br/>
            <label className='detailtitre' id="titre">Accueil</label><br/>
        </div>
    );
}

export default DetailUser;