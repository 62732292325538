/* eslint-disable no-redeclare */
import PouchDB from 'pouchdb';
import PouchFind from 'pouchdb-find';

(function(){
    var taskRecherche = function(){
        /*****************************************************
        ENABLING POUCHDB WITHOUT SYNC TO REMOTE COUCHDB
        ******************************************************/
        //SET UP POUCHDB AND CREATE THE DATABASE 
        const db = new PouchDB('suclu');
        PouchDB.plugin(PouchFind);

        /***************
        crudrechercheBtn
        *****************/
        /**nav previous page button*/
        document.getElementById('prevcrudrechercheBtn_1').addEventListener('click', prevcrudrecherche1BtnChange);
        async function prevcrudrecherche1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'block';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'none';
            titre.innerHTML = 'Exercices et Tâches'; 
        }

        /***************************
        addrechercheBtn
        *****************************/
        document.getElementById('addrechercheBtn').addEventListener('click', addrechercheBtnChange);
        async function addrechercheBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutMatiereStr = 'Oui';
            var docsMatiere = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: ['matierestatut', 'inscritcode']
                    }
                });
                result = await db.find({
                    selector: {
                        matierestatut: statutMatiereStr,
                        inscritcode: userCodeStr
                    }
                });
                docsMatiere  = result.docs;
                var resultatMatiere = docsMatiere.length;
                if(resultatMatiere === 0){
                    alert("Matières Classe non encore enregistrées");
                }else{
                    var divClear;
                    var divModifier;
                    divClear = document.getElementById('addmatiererecherche');
                    while(divClear.hasChildNodes()) {
                        divClear.removeChild(divClear.childNodes[0]);
                    }
                    divModifier = document.getElementById('addmatiererecherche');
                    //Create and append select list
                    var selectList = document.createElement("select");
                    selectList.id = "seladdmatiererecherche";
                    divModifier.appendChild(selectList);
                    //Create and append the options
                    var option_null = document.createElement("option");
                    option_null.value = '';
                    option_null.text = 'Sélectionner Matière';
                    selectList.appendChild(option_null);
                    //Create and append the options
                    for (var i = 0; i<docsMatiere.length; i++){
                        var option = document.createElement("option");
                        option.value = docsMatiere[i].matierenom;
                        option.text = docsMatiere[i].matierenom;
                        selectList.appendChild(option);
                    }

                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'none';
                    document.getElementById('configurer').style.display = 'none';
                    document.getElementById('emploidutemps').style.display = 'none';
                    document.getElementById('exercice').style.display = 'none';
                    document.getElementById('pratique').style.display = 'none';
                    document.getElementById('tache').style.display = 'none';
                    document.getElementById('revision').style.display = 'none';
                    document.getElementById('recherche').style.display = 'block';
                    document.getElementById('crudrecherche').style.display = 'none';
                    document.getElementById('addrecherche').style.display = 'block';
                    document.getElementById('editrecherche').style.display = 'none';
                    document.getElementById('deleterecherche').style.display = 'none';
                    document.getElementById('searchrecherche').style.display = 'none';
                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('savetexterecherche').style.display = 'none';
                    document.getElementById('savedaterecherche').style.display = 'none';
                    document.getElementById('saverecherche').style.display = 'none';
                    titre.innerHTML = 'Enregistrer Recherche';

                    /***************************
                    seladdmatiererecherche
                    *****************************/
                    document.getElementById('seladdmatiererecherche').addEventListener('change', seladdmatiererechercheChange);
                    async function seladdmatiererechercheChange(e){
                        e.preventDefault();

                        var seladdmatiererechercheStr = document.getElementById('seladdmatiererecherche').value;
                        if(!seladdmatiererechercheStr){
                            alert('Sélectionner une matière avant de continuer');
                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('savetexterecherche').style.display = 'none';
                            document.getElementById('savedaterecherche').style.display = 'none';
                            document.getElementById('saverecherche').style.display = 'none';
                            titre.innerHTML = 'Enregistrer Recherche';
                        }else{
                            document.getElementById('addselectmatiererecherche').value = seladdmatiererechercheStr;
                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('savetexterecherche').style.display = 'block';
                            document.getElementById('savedaterecherche').style.display = 'block';
                            document.getElementById('saverecherche').style.display = 'block';
                            titre.innerHTML = 'Enregistrer Recherche';
                        }
                    }
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        /**nav previous page button*/
        document.getElementById('prevaddrechercheBtn_1').addEventListener('click', prevaddrecherche1BtnChange);
        async function prevaddrecherche1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'block';
            document.getElementById('crudrecherche').style.display = 'block';
            document.getElementById('addrecherche').style.display = 'none';
            document.getElementById('editrecherche').style.display = 'none';
            document.getElementById('deleterecherche').style.display = 'none';
            document.getElementById('searchrecherche').style.display = 'none';
            titre.innerHTML = 'Tâches Recherches'; 
        }
        /***************************
        enregistrerrechercheBtn
        *****************************/
        document.getElementById('enregistrerrechercheBtn').addEventListener('click', enregistrerrechercheBtnChange);
        async function enregistrerrechercheBtnChange(e){
            e.preventDefault();

            var usercodeStr = document.getElementById('userCode').innerText;
            var addselectmatiererechercheStr = document.getElementById('addselectmatiererecherche').value;
            var addtexterechercheStr = document.getElementById('addtexterecherche').value;
            var adddaterechercheStr = document.getElementById('adddaterecherche').value;
            var addeffectuerechercheStr = 'Non';
            var datejourStr = getCurrentDate();
            var addstatutrechercheStr = 'Oui';

            if(!usercodeStr){
                alert("Identifiez-vous avant de continuer");
            }else if(!addselectmatiererechercheStr){
                alert("Sélectionner Matière");
            }else if(!addtexterechercheStr){
                alert("Saisir Libellé Recherche");
            }else if(!adddaterechercheStr){
                alert("Saisir Date Devoir");
            }else{
                try {
                    //Create the document _id and _rev.
                    var now = Date.now();
                    var id = 'recherche_' + now;
                    // Add the document to the database.
                    var response = await db.put({
                        _id: id,
                        inscritcode: usercodeStr,
                        matierenom: addselectmatiererechercheStr,
                        recherchetexte: addtexterechercheStr,
                        rechercheecheance: adddaterechercheStr, //date
                        rechercheeffectue: addeffectuerechercheStr,
                        recherchedate: datejourStr,
                        recherchestatut: addstatutrechercheStr,
                    });
                    // check if document inserted and synced
                    var doc = await db.get(response.id);
                    if(doc !== ''){
                        alert('Enregistré. Au Suivant');
                        document.getElementById('addselectmatiererecherche').value = '';
                        document.getElementById('addtexterecherche').value = '';
                        document.getElementById('adddaterecherche').value = '';

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'block';
                        document.getElementById('crudrecherche').style.display = 'block';
                        document.getElementById('addrecherche').style.display = 'none';
                        document.getElementById('editrecherche').style.display = 'none';
                        document.getElementById('deleterecherche').style.display = 'none';
                        document.getElementById('searchrecherche').style.display = 'none';
                        titre.innerHTML = 'Tâches Recherches'; 
                    }else{
                        alert('Echec Enregistrement Recherche. Reprendre');
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }

        /***************************
        editrechercheBtn
        *****************************/
        document.getElementById('editrechercheBtn').addEventListener('click', editrechercheBtnChange);
        async function editrechercheBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutRechercheStr = 'Oui';
            var effectueRechercheStr = 'Non';
            var docsEditRecherche = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: [
                            'recherchestatut', 
                            'inscritcode', 
                            'rechercheeffectue'
                        ]
                    }
                });
                result = await db.find({
                    selector: {
                        recherchestatut: statutRechercheStr,
                        inscritcode: userCodeStr, 
                        rechercheeffectue: effectueRechercheStr
                    }
                });
                docsEditRecherche  = result.docs;
                var resultatEditRecherche = docsEditRecherche.length;
                if(resultatEditRecherche === 0){
                    alert("Tâches Recherches non encore enregistrées");
                }else{
                    var divClear;
                    var divModifier;
                    divClear = document.getElementById('editmatiererecherche');
                    while(divClear.hasChildNodes()) {
                        divClear.removeChild(divClear.childNodes[0]);
                    }
                    divModifier = document.getElementById('editmatiererecherche');
                    //Create and append select list
                    var selectList = document.createElement("select");
                    selectList.id = "seleditmatiererecherche";
                    divModifier.appendChild(selectList);
                    //Create and append the options
                    var option_null = document.createElement("option");
                    option_null.value = '';
                    option_null.text = 'Sélectionner matière';
                    selectList.appendChild(option_null);
                    //Create and append the options
                    for (var i = 0; i<docsEditRecherche.length; i++){
                        var option = document.createElement("option");
                        option.value = docsEditRecherche[i]._id;
                        option.text = docsEditRecherche[i].matierenom;
                        selectList.appendChild(option);
                    }

                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'none';
                    document.getElementById('configurer').style.display = 'none';
                    document.getElementById('emploidutemps').style.display = 'none';
                    document.getElementById('exercice').style.display = 'none';
                    document.getElementById('pratique').style.display = 'none';
                    document.getElementById('tache').style.display = 'none';
                    document.getElementById('revision').style.display = 'none';
                    document.getElementById('recherche').style.display = 'block';
                    document.getElementById('crudrecherche').style.display = 'none';
                    document.getElementById('addrecherche').style.display = 'none';
                    document.getElementById('editrecherche').style.display = 'block';
                    document.getElementById('deleterecherche').style.display = 'none';
                    document.getElementById('searchrecherche').style.display = 'none';
                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('changetexterecherche').style.display = 'none';
                    document.getElementById('changedaterecherche').style.display = 'none';
                    document.getElementById('changerecherche').style.display = 'none';
                    titre.innerHTML = 'Modifier Recherche';

                    /***************************
                    seleditmatiererecherche
                    *****************************/
                    document.getElementById('seleditmatiererecherche').addEventListener('change', seleditmatiererechercheChange);
                    async function seleditmatiererechercheChange(e){
                        e.preventDefault();

                        var userCodeStr = document.getElementById('userCode').innerText;
                        var rechercheIdStr,inscritCodeStr,rechercheMatiereStr,rechercheTexteStr,rechercheEcheanceStr,
                        rechercheEffectueStr,rechercheDateStr,rechercheStatutStr = '';

                        var seleditmatiererechercheStr = document.getElementById('seleditmatiererecherche').value;
                        if(!seleditmatiererechercheStr){
                            alert('Sélectionner une Matière avant de continuer');
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('changetexterecherche').style.display = 'none';
                            document.getElementById('changedaterecherche').style.display = 'none';
                            document.getElementById('changerecherche').style.display = 'none';
                        }else{
                            var userCodeStr = document.getElementById('userCode').innerText;
                            var statutRechercheStr = 'Oui';
                            var rechercheEffectStr = 'Non';
                            var docsEdit = [];
                            try {
                                let result = await db.createIndex({
                                    index: {
                                        fields: [
                                            'recherchestatut', 
                                            'inscritcode',
                                            'rechercheeffectue', 
                                            '_id'
                                        ]
                                    }
                                });
                                result = await db.find({
                                    selector: {
                                        recherchestatut: statutRechercheStr,
                                        inscritcode: userCodeStr,
                                        rechercheeffectue: rechercheEffectStr,
                                        _id: seleditmatiererechercheStr
                                    }
                                });
                                docsEdit  = result.docs;
                                var resultatEdit = docsEdit.length;
                                if(resultatEdit === 0){
                                    document.getElementById('editidrecherche').value = '';
                                    document.getElementById('editcodeuserrecherche').value = '';
                                    document.getElementById('editselectmatiererecherche').value = '';
                                    document.getElementById('edittexterecherche').value = '';
                                    document.getElementById('editecheancerecherche').value = '';
                                    document.getElementById('editeffectuerecherche').value = '';
                                    document.getElementById('editdaterecherche').value = '';
                                    document.getElementById('editstatutrecherche').value = '';
                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('changetexterecherche').style.display = 'none';
                                    document.getElementById('changedaterecherche').style.display = 'none';
                                    document.getElementById('changerecherche').style.display = 'none';
                                }else{
                                    for(let j=0; j<docsEdit.length; j++){
                                        rechercheIdStr = docsEdit[j]._id;
                                        inscritCodeStr = docsEdit[j].inscritcode;
                                        rechercheMatiereStr = docsEdit[j].matierenom;
                                        rechercheTexteStr = docsEdit[j].recherchetexte;
                                        rechercheEcheanceStr = docsEdit[j].rechercheecheance;
                                        rechercheEffectueStr = docsEdit[j].rechercheeffectue;
                                        rechercheDateStr = docsEdit[j].recherchedate;
                                        rechercheStatutStr = docsEdit[j].recherchestatut;
                                    }

                                    document.getElementById('editidrecherche').value = rechercheIdStr;
                                    document.getElementById('editcodeuserrecherche').value = inscritCodeStr;
                                    document.getElementById('editselectmatiererecherche').value = rechercheMatiereStr;
                                    document.getElementById('edittexterecherche').value = rechercheTexteStr;
                                    document.getElementById('editecheancerecherche').value = rechercheEcheanceStr;
                                    document.getElementById('editeffectuerecherche').value = rechercheEffectueStr;
                                    document.getElementById('editdaterecherche').value = rechercheDateStr;
                                    document.getElementById('editstatutrecherche').value = rechercheStatutStr;
                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('changetexterecherche').style.display = 'block';
                                    document.getElementById('changedaterecherche').style.display = 'block';
                                    document.getElementById('changerecherche').style.display = 'block';
                                }
                            } catch (error) {
                                alert('Erreur Exécution Matières Classe');
                            }
                        }
                    }
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        /**nav previous page button*/
        document.getElementById('preveditrechercheBtn_1').addEventListener('click', preveditrecherche1BtnChange);
        async function preveditrecherche1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'block';
            document.getElementById('crudrecherche').style.display = 'block';
            document.getElementById('addrecherche').style.display = 'none';
            document.getElementById('editrecherche').style.display = 'none';
            document.getElementById('deleterecherche').style.display = 'none';
            document.getElementById('searchrecherche').style.display = 'none';
            titre.innerHTML = 'Tâches Recherches'; 
        }
        /***************************
        modifierrechercheBtn
        *****************************/
        document.getElementById('modifierrechercheBtn').addEventListener('click', modifierrechercheBtnChange);
        async function modifierrechercheBtnChange(e){
            e.preventDefault();

            var rechercheMatiereStr = document.getElementById('editselectmatiererecherche').value;
            var rechercheTexteStr = document.getElementById('edittexterecherche').value;
            var rechercheEcheanceStr = document.getElementById('editecheancerecherche').value;

            var rechercheIdStr = document.getElementById('editidrecherche').value;
            var inscritCodeStr = document.getElementById('editcodeuserrecherche').value;
            var rechercheEffectueStr = document.getElementById('editeffectuerecherche').value;
            var rechercheDateStr = document.getElementById('editdaterecherche').value;
            var rechercheStatutStr = document.getElementById('editstatutrecherche').value;

            if(!inscritCodeStr){
                alert("Identifiez-vous avant de continuer");
            }else if(!rechercheTexteStr){
                alert("Saisir Libellé Recherche");
            }else if(!rechercheEcheanceStr){
                alert('Saisir Date Devoir');
            }else{
                try {
                    // Retrieve the document to get the latest revision
                    var id = rechercheIdStr;
                    var doc = await db.get(id);
                    // Make changes
                    doc.inscritcode = inscritCodeStr;
                    doc.matierenom = rechercheMatiereStr;
                    doc.recherchetexte = rechercheTexteStr;
                    doc.rechercheecheance = rechercheEcheanceStr;
                    doc.rechercheeffectue = rechercheEffectueStr;
                    doc.recherchedate = rechercheDateStr;
                    doc.recherchestatut = rechercheStatutStr;
                    // Put the document, saving it as a new revision
                    var response = await db.put(doc);
                    // Get the document we change and update the UI.
                    var docEdit = await db.get(response.id);
                    if(docEdit !== ''){
                        alert("Recherche Modifiée avec Succès");
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'block';
                        document.getElementById('crudrecherche').style.display = 'block';
                        document.getElementById('addrecherche').style.display = 'none';
                        document.getElementById('editrecherche').style.display = 'none';
                        document.getElementById('deleterecherche').style.display = 'none';
                        document.getElementById('searchrecherche').style.display = 'none';
                        titre.innerHTML = 'Tâches Recherches'; 
                    }else{
                        alert("Erreur Modification. Reprendre");
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'block';
                        document.getElementById('crudrecherche').style.display = 'block';
                        document.getElementById('addrecherche').style.display = 'none';
                        document.getElementById('editrecherche').style.display = 'none';
                        document.getElementById('deleterecherche').style.display = 'none';
                        document.getElementById('searchrecherche').style.display = 'none';
                        titre.innerHTML = 'Tâches Recherches'; 
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }

        /***************************
        deleterechercheBtn
        *****************************/
        document.getElementById('deleterechercheBtn').addEventListener('click', deleterechercheBtnChange);
        async function deleterechercheBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutRechercheStr = 'Oui';
            var effectueRechercheStr = 'Non';
            var docsDeleteRecherche = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: [
                            'recherchestatut', 
                            'inscritcode',
                            'rechercheeffectue'
                        ]
                    }
                });
                result = await db.find({
                    selector: {
                        recherchestatut: statutRechercheStr,
                        inscritcode: userCodeStr,
                        rechercheeffectue: effectueRechercheStr
                    }
                });
                docsDeleteRecherche  = result.docs;
                var resultatDeleteRecherche = docsDeleteRecherche.length;
                if(resultatDeleteRecherche === 0){
                    alert("Tâches Recherches non encore enregistrées");
                }else{
                    var divClear;
                    var divModifier;
                    divClear = document.getElementById('deletematiererecherche');
                    while(divClear.hasChildNodes()) {
                        divClear.removeChild(divClear.childNodes[0]);
                    }
                    divModifier = document.getElementById('deletematiererecherche');
                    //Create and append select list
                    var selectList = document.createElement("select");
                    selectList.id = "seldeletematiererecherche";
                    divModifier.appendChild(selectList);
                    //Create and append the options
                    var option_null = document.createElement("option");
                    option_null.value = '';
                    option_null.text = 'Sélectionner matière';
                    selectList.appendChild(option_null);
                    //Create and append the options
                    for (var i = 0; i<docsDeleteRecherche.length; i++){
                        var option = document.createElement("option");
                        option.value = docsDeleteRecherche[i]._id;
                        option.text = docsDeleteRecherche[i].matierenom;
                        selectList.appendChild(option);
                    }

                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'none';
                    document.getElementById('configurer').style.display = 'none';
                    document.getElementById('emploidutemps').style.display = 'none';
                    document.getElementById('exercice').style.display = 'none';
                    document.getElementById('pratique').style.display = 'none';
                    document.getElementById('tache').style.display = 'none';
                    document.getElementById('revision').style.display = 'none';
                    document.getElementById('recherche').style.display = 'block';
                    document.getElementById('crudrecherche').style.display = 'none';
                    document.getElementById('addrecherche').style.display = 'none';
                    document.getElementById('editrecherche').style.display = 'none';
                    document.getElementById('deleterecherche').style.display = 'block';
                    document.getElementById('searchrecherche').style.display = 'none';
                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('canceltexterecherche').style.display = 'none';
                    document.getElementById('canceldaterecherche').style.display = 'none';
                    document.getElementById('cancelrecherche').style.display = 'none';
                    titre.innerHTML = 'Supprimer Recherche';

                    /***************************
                    seldeletematiererecherche
                    *****************************/
                    document.getElementById('seldeletematiererecherche').addEventListener('change', seldeletematiererechercheChange);
                    async function seldeletematiererechercheChange(e){
                        e.preventDefault();

                        var userCodeStr = document.getElementById('userCode').innerText;
                        var rechercheIdStr,rechercheTexteStr,rechercheEcheanceStr = '';
                        var seldeletematiererechercheStr = document.getElementById('seldeletematiererecherche').value;
                        if(!seldeletematiererechercheStr){
                            alert('Sélectionner une Matière avant de continuer');
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('canceltexterecherche').style.display = 'none';
                            document.getElementById('canceldaterecherche').style.display = 'none';
                            document.getElementById('cancelrecherche').style.display = 'none'; 
                        }else{
                            var userCodeStr = document.getElementById('userCode').innerText;
                            var statutRechercheStr = 'Oui';
                            var rechercheEffectStr = 'Non';
                            var docsDelete = [];
                            try {
                                let result = await db.createIndex({
                                    index: {
                                        fields: [
                                            'recherchestatut', 
                                            'inscritcode',
                                            'rechercheeffectue', 
                                            '_id'
                                        ]
                                    }
                                });
                                result = await db.find({
                                    selector: {
                                        recherchestatut: statutRechercheStr,
                                        inscritcode: userCodeStr,
                                        rechercheeffectue: rechercheEffectStr,
                                        _id: seldeletematiererechercheStr
                                    }
                                });
                                docsDelete  = result.docs;
                                var resultatDelete = docsDelete.length;
                                if(resultatDelete === 0){
                                    document.getElementById('deleteidrecherche').value = '';
                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('canceltexterecherche').style.display = 'none';
                                    document.getElementById('canceldaterecherche').style.display = 'none';
                                    document.getElementById('cancelrecherche').style.display = 'none'; 
                                }else{
                                    for(let j=0; j<docsDelete.length; j++){
                                        rechercheIdStr = docsDelete[j]._id;
                                        rechercheTexteStr = docsDelete[j].recherchetexte;
                                        rechercheEcheanceStr = docsDelete[j].rechercheecheance;
                                    }

                                    document.getElementById('deletetexterecherche').value = rechercheTexteStr;
                                    document.getElementById('deleteecheancerecherche').value = rechercheEcheanceStr;

                                    document.getElementById('deleteidrecherche').value = rechercheIdStr;

                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('canceltexterecherche').style.display = 'block';
                                    document.getElementById('canceldaterecherche').style.display = 'block';
                                    document.getElementById('cancelrecherche').style.display = 'block'; 
                                }
                            } catch (error) {
                                alert('Erreur Exécution Matières Classe');
                            }
                        }
                    }
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        /**nav previous page button*/
        document.getElementById('prevdeleterechercheBtn_1').addEventListener('click', prevdeleterecherche1BtnChange);
        async function prevdeleterecherche1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'block';
            document.getElementById('crudrecherche').style.display = 'block';
            document.getElementById('addrecherche').style.display = 'none';
            document.getElementById('editrecherche').style.display = 'none';
            document.getElementById('deleterecherche').style.display = 'none';
            document.getElementById('searchrecherche').style.display = 'none';
            titre.innerHTML = 'Tâches Recherches'; 
        }
        /***************************
        supprimerrechercheBtn
        *****************************/
        document.getElementById('supprimerrechercheBtn').addEventListener('click', supprimerrechercheBtnChange);
        async function supprimerrechercheBtnChange(e){
            e.preventDefault();

            var divClearModal = document.getElementById('modaloutrecherche');
            let divModal = document.createElement('div');
            divModal.innerHTML =
            `<div class="modal">
                <p>Êtes-vous sûr de Supprimer ?</p>
                <button class="btn btn--alt" id="ouiHandlerRechercheBtn">Oui</button>
                <button class="btn" id="nonHandlerRechercheBtn">Non</button>
            </div>`;
            divClearModal.appendChild(divModal);

            var divClearBackdrop = document.getElementById('backdropoutrecherche');
            let divBack = document.createElement('div');
            divBack.innerHTML =
            `<div class="backdrop" id="backdropHandlerRechercheBtn"></div>`;
            divClearBackdrop.appendChild(divBack);

            document.getElementById('ouiHandlerRechercheBtn').addEventListener('click', ouiHandlerRechercheBtnChange);
            async function ouiHandlerRechercheBtnChange(e){
                e.preventDefault();

                var deleteidrechercheStr = document.getElementById('deleteidrecherche').value;

                try {
                    //Delete
                    var doc = await db.get(deleteidrechercheStr);
                    var response = await db.remove(doc);
                    if(response.ok){
                        alert('Recherche Supprimée avec Succès');
                        var divClearModal = document.getElementById('modaloutrecherche');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutrecherche');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'block';
                        document.getElementById('crudrecherche').style.display = 'block';
                        document.getElementById('addrecherche').style.display = 'none';
                        document.getElementById('editrecherche').style.display = 'none';
                        document.getElementById('deleterecherche').style.display = 'none';
                        document.getElementById('searchrecherche').style.display = 'none';
                        titre.innerHTML = 'Tâches Recherches'; 
                    }else{
                        alert("Impossible Supprimer Recherche");
                        var divClearModal = document.getElementById('modaloutrecherche');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutrecherche');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'block';
                        document.getElementById('crudrecherche').style.display = 'block';
                        document.getElementById('addrecherche').style.display = 'none';
                        document.getElementById('editrecherche').style.display = 'none';
                        document.getElementById('deleterecherche').style.display = 'none';
                        document.getElementById('searchrecherche').style.display = 'none';
                        titre.innerHTML = 'Tâches Recherches';
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
            document.getElementById('nonHandlerRechercheBtn').addEventListener('click', nonHandlerRechercheBtnChange);
            async function nonHandlerRechercheBtnChange(e){
                e.preventDefault();

                var divClearModal = document.getElementById('modaloutrecherche');
                while(divClearModal.hasChildNodes()) {
                    divClearModal.removeChild(divClearModal.childNodes[0]);
                }

                var divClearBackdrop = document.getElementById('backdropoutrecherche');
                while(divClearBackdrop.hasChildNodes()) {
                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                }
            }
            document.getElementById('backdropHandlerRechercheBtn').addEventListener('click', backdropHandlerRechercheBtnChange);
            async function backdropHandlerRechercheBtnChange(e){
                e.preventDefault();

                var divClearModal = document.getElementById('modaloutrecherche');
                while(divClearModal.hasChildNodes()) {
                    divClearModal.removeChild(divClearModal.childNodes[0]);
                }

                var divClearBackdrop = document.getElementById('backdropoutrecherche');
                while(divClearBackdrop.hasChildNodes()) {
                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                }
            }
        }

        /***************************
        searchrechercheBtn
        *****************************/
        document.getElementById('searchrechercheBtn').addEventListener('click', searchrechercheBtnChange);
        async function searchrechercheBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutRechercheStr = 'Oui';
            var docsSearchRecherche = [];
            try {
                let result = await db.createIndex({
                    index: {
                        fields: ['recherchestatut', 'inscritcode']
                    }
                });
                result = await db.find({
                    selector: {
                        recherchestatut: statutRechercheStr,
                        inscritcode: userCodeStr
                    }
                });
                docsSearchRecherche  = result.docs;
                var resultatSearchRecherche = docsSearchRecherche.length;
                if(resultatSearchRecherche === 0){
                    alert("Recherche non encore enregistrée");
                }else{
                    var divClear;
                    var divModifier;
                    divClear = document.getElementById('searchmatiererecherche');
                    while(divClear.hasChildNodes()) {
                        divClear.removeChild(divClear.childNodes[0]);
                    }
                    divModifier = document.getElementById('searchmatiererecherche');
                    //Create and append select list
                    var selectList = document.createElement("select");
                    selectList.id = "selsearchmatiererecherche";
                    divModifier.appendChild(selectList);
                    //Create and append the options
                    var option_null = document.createElement("option");
                    option_null.value = '';
                    option_null.text = 'Sélectionner matière';
                    selectList.appendChild(option_null);
                    //Create and append the options
                    for (var i = 0; i<docsSearchRecherche.length; i++){
                        var option = document.createElement("option");
                        option.value = docsSearchRecherche[i].matierenom;
                        option.text = docsSearchRecherche[i].matierenom;
                        selectList.appendChild(option);
                    }

                    document.getElementById('titre').style.display = 'block';
                    var titre = document.getElementById('titre');

                    document.getElementById('demarrage').style.display = 'none';
                    document.getElementById('tableaubord').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'none';
                    document.getElementById('configurations').style.display = 'none';
                    document.getElementById('configurer').style.display = 'none';
                    document.getElementById('emploidutemps').style.display = 'none';
                    document.getElementById('exercice').style.display = 'none';
                    document.getElementById('pratique').style.display = 'none';
                    document.getElementById('tache').style.display = 'none';
                    document.getElementById('revision').style.display = 'none';
                    document.getElementById('recherche').style.display = 'block';
                    document.getElementById('crudrecherche').style.display = 'none';
                    document.getElementById('addrecherche').style.display = 'none';
                    document.getElementById('editrecherche').style.display = 'none';
                    document.getElementById('deleterecherche').style.display = 'none';
                    document.getElementById('searchrecherche').style.display = 'block';
                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    document.getElementById('vieweffectuerecherche').style.display = 'none';
                    document.getElementById('viewrecherche').style.display = 'none';
                    document.getElementById('viewconsulterrecherche').style.display = 'none';
                    titre.innerHTML = 'Consulter Recherche';

                    /***************************
                    selsearchmatiererecherche
                    *****************************/
                    document.getElementById('selsearchmatiererecherche').addEventListener('change', selsearchmatiererechercheChange);
                    async function selsearchmatiererechercheChange(e){
                        e.preventDefault();

                        var selsearchmatiererechercheStr = document.getElementById('selsearchmatiererecherche').value;
                        if(!selsearchmatiererechercheStr){
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('vieweffectuerecherche').style.display = 'none';
                            document.getElementById('viewrecherche').style.display = 'none';
                            document.getElementById('viewconsulterrecherche').style.display = 'none';
                        }else{
                            document.getElementById('searchmatiererecherche').value = selsearchmatiererechercheStr;
                            document.getElementById('viewmatiererecherche').innerText = selsearchmatiererechercheStr;
                            document.getElementById('searcheffectuerecherche').value = '';
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('vieweffectuerecherche').style.display = 'block';
                            document.getElementById('viewrecherche').style.display = 'none';
                            document.getElementById('viewconsulterrecherche').style.display = 'none';
                        }
                    }
                }
            } catch (error) {
                alert('Erreur Exécution');
            }
        }
        /**nav previous page button*/
        document.getElementById('prevsearchrechercheBtn_1').addEventListener('click', prevsearchrecherche1BtnChange);
        async function prevsearchrecherche1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'block';
            document.getElementById('crudrecherche').style.display = 'block';
            document.getElementById('addrecherche').style.display = 'none';
            document.getElementById('editrecherche').style.display = 'none';
            document.getElementById('deleterecherche').style.display = 'none';
            document.getElementById('searchrecherche').style.display = 'none';
            titre.innerHTML = 'Tâches Recherches'; 
        }
        /***************************
        searcheffectuerecherche
        *****************************/
        document.getElementById('searcheffectuerecherche').addEventListener('change', searcheffectuerechercheChange);
        async function searcheffectuerechercheChange(e){
            e.preventDefault();

            var searcheffectuerechercheStr = document.getElementById('searcheffectuerecherche').value;
            var searchmatiererechercheStr = document.getElementById('searchmatiererecherche').value;
            var userCodeStr = document.getElementById('userCode').innerText;
            var effectOuiStr = 'Oui';
            var effectNonStr = 'Non'; 
            var statutRechercheStr = 'Oui';
            var docsSearchNon = [];
            var docsSearchOui = [];
            if(searcheffectuerechercheStr === 'Non'){
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: [
                                'recherchestatut',
                                'rechercheeffectue',
                                'matierenom',
                                'inscritcode'
                            ]
                        }
                    });
                    result = await db.find({
                        selector: {
                            recherchestatut: statutRechercheStr,
                            rechercheeffectue: effectNonStr,
                            matierenom: searchmatiererechercheStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsSearchNon  = result.docs;
                    var resultatSearchNon = docsSearchNon.length;
                    if(resultatSearchNon === 0){
                        alert("Pas de recherches non Effectuées Disponibles");
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('vieweffectuerecherche').style.display = 'block';
                        document.getElementById('viewrecherche').style.display = 'none';
                        document.getElementById('viewconsulterrecherche').style.display = 'none';
                    }else{
                        updateSearchRechercheUIWithDocs(docsSearchNon);
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('vieweffectuerecherche').style.display = 'block';
                        document.getElementById('viewrecherche').style.display = 'block';
                        document.getElementById('viewconsulterrecherche').style.display = 'block';
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }else if(searcheffectuerechercheStr === 'Oui'){
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: [
                                'recherchestatut',
                                'rechercheeffectue',
                                'matierenom',
                                'inscritcode'
                            ]
                        }
                    });
                    result = await db.find({
                        selector: {
                            recherchestatut: statutRechercheStr,
                            rechercheeffectue: effectOuiStr,
                            matierenom: searchmatiererechercheStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsSearchOui  = result.docs;
                    var resultatSearchOui = docsSearchOui.length;
                    if(resultatSearchOui === 0){
                        alert("Pas de Recherche Effectuées Disponibles");
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('vieweffectuerecherche').style.display = 'block';
                        document.getElementById('viewrecherche').style.display = 'none';
                        document.getElementById('viewconsulterrecherche').style.display = 'none';
                    }else{
                        updateSearchRechercheUIWithDocs(docsSearchOui);
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('vieweffectuerecherche').style.display = 'block';
                        document.getElementById('viewrecherche').style.display = 'block';
                        document.getElementById('viewconsulterrecherche').style.display = 'block';
                    }
                } catch (error) {
                   alert('Erreur Exécution'); 
                }
            }else{
                alert("Selectionner option Oui/Non");
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('vieweffectuerecherche').style.display = 'block';
                document.getElementById('viewrecherche').style.display = 'none';
                document.getElementById('viewconsulterrecherche').style.display = 'none';
            }
        }
        // Adds the document to the HTML First table.
        // If the document already exists in the table, it will be updated.
        function viewSearchRechercheTableDocToTable(doc, viewSearchRechercheTableBody){
            // Each row of the table has an id attribute set to the doc._id field.
            var tr = viewSearchRechercheTableBody.querySelector(`#${doc._id}`);
            var searcheffectuerechercheStr = document.getElementById('searcheffectuerecherche').value;

            if( !tr ) {
                tr = document.createElement('tr');
                tr.id = doc._id;
            }
        
            // Remove any existing table cells.
            while(tr.hasChildNodes()) {
                tr.removeChild(tr.childNodes[0]);
            }

            // Create new table cells for text fields.
            var tdEcheance = document.createElement('td');
            var dateEcheance = new Date(doc.rechercheecheance);
            tdEcheance.innerText = dateEcheance.toLocaleDateString();
            tr.appendChild(tdEcheance);

            // Create new table cells for text fields.
            var tdEnonce = document.createElement('td');
            tdEnonce.innerText = doc.recherchetexte;
            var br = document.createElement("br");
            tdEnonce.appendChild(br);
            var idRecherche = doc._id;
            if(searcheffectuerechercheStr === 'Non'){
                var button = document.createElement("button");
                button.id = "ouirechercheBtn";
                button.type = 'button';
                button.innerHTML = 'Effectué';
                button.onclick = async function(){
                    var inscritCodeStr,rechercheMatiereStr,rechercheTexteStr,rechercheEcheanceStr,
                    rechercheDateStr,rechercheStatutStr = '';
                    var rechercheEffectueStr = 'Oui';
                    var docsRecherche = [];
                    try {
                        let result = await db.createIndex({
                            index: {
                                fields: ['_id']
                            }
                        });
                        result = await db.find({
                            selector: {
                                _id: idRecherche
                            }
                        });
                        docsRecherche = result.docs;
                        var resultatRecherche = docsRecherche.length;
                        if(resultatRecherche === 0){
                            alert("Recherche n'existe pas");
                        }else{
                            for(let i=0; i<docsRecherche.length; i++){
                                inscritCodeStr = docsRecherche[i].inscritcode;
                                rechercheMatiereStr = docsRecherche[i].matierenom;
                                rechercheTexteStr = docsRecherche[i].recherchetexte;
                                rechercheEcheanceStr = docsRecherche[i].rechercheecheance;
                                rechercheDateStr = docsRecherche[i].recherchedate;
                                rechercheStatutStr = docsRecherche[i].recherchestatut;
                            }

                            // Retrieve the document to get the latest revision
                            var id = idRecherche;
                            var docModifier = await db.get(id);
                            // Make changes
                            docModifier.inscritcode = inscritCodeStr;
                            docModifier.matierenom = rechercheMatiereStr;
                            docModifier.recherchetexte = rechercheTexteStr;
                            docModifier.rechercheecheance = rechercheEcheanceStr;
                            docModifier.rechercheeffectue = rechercheEffectueStr;
                            docModifier.recherchedate = rechercheDateStr;
                            docModifier.recherchestatut = rechercheStatutStr;
                            // Put the document, saving it as a new revision
                            var response = await db.put(docModifier);
                            // Get the document we change and update the UI.
                            var docEdit = await db.get(response.id);
                            if(docEdit !== ''){
                                document.getElementById('titre').style.display = 'block';
                                var titre = document.getElementById('titre');

                                document.getElementById('demarrage').style.display = 'none';
                                document.getElementById('tableaubord').style.display = 'none';
                                document.getElementById('dashboard').style.display = 'none';
                                document.getElementById('configurations').style.display = 'none';
                                document.getElementById('configurer').style.display = 'none';
                                document.getElementById('emploidutemps').style.display = 'none';
                                document.getElementById('exercice').style.display = 'none';
                                document.getElementById('pratique').style.display = 'none';
                                document.getElementById('tache').style.display = 'none';
                                document.getElementById('revision').style.display = 'none';
                                document.getElementById('recherche').style.display = 'block';
                                document.getElementById('crudrecherche').style.display = 'block';
                                document.getElementById('addrecherche').style.display = 'none';
                                document.getElementById('editrecherche').style.display = 'none';
                                document.getElementById('deleterecherche').style.display = 'none';
                                document.getElementById('searchrecherche').style.display = 'none';
                                titre.innerHTML = 'Tâches Recherches';
                            }else{
                                document.getElementById('titre').style.display = 'block';
                                var titre = document.getElementById('titre');

                                document.getElementById('demarrage').style.display = 'none';
                                document.getElementById('tableaubord').style.display = 'none';
                                document.getElementById('dashboard').style.display = 'none';
                                document.getElementById('configurations').style.display = 'none';
                                document.getElementById('configurer').style.display = 'none';
                                document.getElementById('emploidutemps').style.display = 'none';
                                document.getElementById('exercice').style.display = 'none';
                                document.getElementById('pratique').style.display = 'none';
                                document.getElementById('tache').style.display = 'none';
                                document.getElementById('revision').style.display = 'none';
                                document.getElementById('recherche').style.display = 'block';
                                document.getElementById('crudrecherche').style.display = 'block';
                                document.getElementById('addrecherche').style.display = 'none';
                                document.getElementById('editrecherche').style.display = 'none';
                                document.getElementById('deleterecherche').style.display = 'none';
                                document.getElementById('searchrecherche').style.display = 'none';
                                titre.innerHTML = 'Tâches Recherches';
                            }
                        }
                    } catch (error) {
                        alert('Erreur Exécution');
                    }
                }
            }else{
                var button = document.createElement("button");
                button.id = "nonrechercheBtn";
                button.type = 'button';
                button.innerHTML = 'Supprimer';
                button.onclick = async function(){
                    var divClearModal = document.getElementById('modaloutsearchrecherche');
                    let divModal = document.createElement('div');
                    divModal.innerHTML =
                    `<div class="modal">
                    <p>Êtes-vous sûr de Supprimer Cet Exercice ?</p>
                    <button class="btn btn--alt" id="ouiHandlerSearchRechercheBtn">Oui</button>
                    <button class="btn" id="nonHandlerSearchRechercheBtn">Non</button>
                    </div>`;
                    divClearModal.appendChild(divModal);

                    var divClearBackdrop = document.getElementById('backdropoutsearchrecherche');
                    let divBack = document.createElement('div');
                    divBack.innerHTML =
                    `<div class="backdrop" id="backdropHandlerSearchRechercheBtn"></div>`;
                    divClearBackdrop.appendChild(divBack);

                    document.getElementById('ouiHandlerSearchRechercheBtn').addEventListener('click', ouiHandlerSearchRechercheBtnChange);
                    async function ouiHandlerSearchRechercheBtnChange(e){
                        e.preventDefault();

                        var deleteidrechercheStr = idRecherche;
                        try {
                            //Delete
                            var doc = await db.get(deleteidrechercheStr);
                            var response = await db.remove(doc);
                            if(response.ok){
                                alert('Recherche Supprimée avec Succès');
                                var divClearModal = document.getElementById('modaloutsearchrecherche');
                                while(divClearModal.hasChildNodes()) {
                                    divClearModal.removeChild(divClearModal.childNodes[0]);
                                }

                                var divClearBackdrop = document.getElementById('backdropoutsearchrecherche');
                                while(divClearBackdrop.hasChildNodes()) {
                                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                }

                                document.getElementById('titre').style.display = 'block';
                                var titre = document.getElementById('titre');

                                document.getElementById('demarrage').style.display = 'none';
                                document.getElementById('tableaubord').style.display = 'none';
                                document.getElementById('dashboard').style.display = 'none';
                                document.getElementById('configurations').style.display = 'none';
                                document.getElementById('configurer').style.display = 'none';
                                document.getElementById('emploidutemps').style.display = 'none';
                                document.getElementById('exercice').style.display = 'none';
                                document.getElementById('pratique').style.display = 'none';
                                document.getElementById('tache').style.display = 'none';
                                document.getElementById('revision').style.display = 'none';
                                document.getElementById('recherche').style.display = 'block';
                                document.getElementById('crudrecherche').style.display = 'block';
                                document.getElementById('addrecherche').style.display = 'none';
                                document.getElementById('editrecherche').style.display = 'none';
                                document.getElementById('deleterecherche').style.display = 'none';
                                document.getElementById('searchrecherche').style.display = 'none';
                                titre.innerHTML = 'Tâches Recherches';
                            }else{
                                alert("Impossible Supprimer Recherche");
                                document.getElementById('titre').style.display = 'block';
                                var titre = document.getElementById('titre');

                                document.getElementById('demarrage').style.display = 'none';
                                document.getElementById('tableaubord').style.display = 'none';
                                document.getElementById('dashboard').style.display = 'none';
                                document.getElementById('configurations').style.display = 'none';
                                document.getElementById('configurer').style.display = 'none';
                                document.getElementById('emploidutemps').style.display = 'none';
                                document.getElementById('exercice').style.display = 'none';
                                document.getElementById('pratique').style.display = 'none';
                                document.getElementById('tache').style.display = 'none';
                                document.getElementById('revision').style.display = 'none';
                                document.getElementById('recherche').style.display = 'block';
                                document.getElementById('crudrecherche').style.display = 'block';
                                document.getElementById('addrecherche').style.display = 'none';
                                document.getElementById('editrecherche').style.display = 'none';
                                document.getElementById('deleterecherche').style.display = 'none';
                                document.getElementById('searchrecherche').style.display = 'none';
                                titre.innerHTML = 'Tâches Recherches';
                            }
                        } catch (error) {
                            alert('Erreur Exécution');
                        }
                    }
                    document.getElementById('nonHandlerSearchRechercheBtn').addEventListener('click', nonHandlerSearchRechercheBtnChange);
                    async function nonHandlerSearchRechercheBtnChange(e){
                        e.preventDefault();

                        var divClearModal = document.getElementById('modaloutsearchrecherche');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutsearchrecherche');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }
                    }
                    document.getElementById('backdropHandlerSearchRechercheBtn').addEventListener('click', backdropHandlerSearchRechercheBtnChange);
                    async function backdropHandlerSearchRechercheBtnChange(e){
                        e.preventDefault();

                        var divClearModal = document.getElementById('modaloutsearchrecherche');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutsearchrecherche');
                        while(divClearBackdrop.hasChildNodes()) {
                             divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }
                    }
                }
            }
            tdEnonce.appendChild(button);
            tr.appendChild(tdEnonce);

            // Add the row to the top of the table.
            viewSearchRechercheTableBody.insertBefore(tr, viewSearchRechercheTableBody.nextSibling);
        }
        /**Clear and update the UI to display the docs.*/
        function updateSearchRechercheUIWithDocs(docs) {
            var viewSearchRechercheTableBody = document.getElementById('viewSearchRechercheTableBody');
            
            // Remove all child nodes from the table
            while(viewSearchRechercheTableBody.hasChildNodes()) {
                viewSearchRechercheTableBody.removeChild(viewSearchRechercheTableBody.childNodes[0]);
            }
            
            // Add the new documents to the table
            for(var i=0; i<docs.length; i++) {
                var doc = docs[i];
                viewSearchRechercheTableDocToTable(doc, viewSearchRechercheTableBody);
            }
        }
        /***************************
        consulterrechercheBtn
        *****************************/
        document.getElementById('consulterrechercheBtn').addEventListener('click', consulterrechercheBtnChange);
        async function consulterrechercheBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'block';
            document.getElementById('crudrecherche').style.display = 'block';
            document.getElementById('addrecherche').style.display = 'none';
            document.getElementById('editrecherche').style.display = 'none';
            document.getElementById('deleterecherche').style.display = 'none';
            document.getElementById('searchrecherche').style.display = 'none';
            titre.innerHTML = 'Tâches Recherches';
        }
    };

    /****************
    HELPER FUNCTION
    *****************/
    /**1: Get Current Date in JavaScript */
    function getCurrentDate(){
        // Date object
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1;
        var yyyy = today.getFullYear();
        if(dd<10){
            dd='0'+dd;
        }
        if(mm<10){
            mm='0'+mm;
        }
        // Current Date
        var date = yyyy+'-'+mm+'-'+dd;
        return date;
    }

    window.addEventListener('load', () =>{
        new taskRecherche();
    })
}) ();