import './notes.css';
import './pouchnotes';
import DateInput from '../components/DateInput';
import NumberInput from '../components/NumberInput';
import SupprimerButton from '../components/SupprimerButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function DeleteNotes() {
    return ( 
        <div id="deletenotes">
            <KeyboardBackspace style={prevArrows} id="prevdeletenotesBtn_1" />
            <br/><br/>
            <form>
                <div id="formdeletenotes" style={scroller}>
                    <br/>
                    <div id="cancelcalendriernotes">
                        <label style={labelStyle}>Sélection Calendrier Scolaire</label>
                        <div id="deletecalendriernotes"></div>
                    </div>
                    <br/>
                    <div id="cancelmatierenotes">
                        <label style={labelStyle}>Sélection Matière</label>
                        <div id="deletematierenotes"></div>
                    </div>
                    <br/>
                    <div id="canceltypenotes">
                        <label style={labelStyle}>Type Devoir</label>
                        <select id="seldeletetypenotes">
                            <option value="">Sélectionner</option>
                            <option value='Devoir'>Devoir</option>
                            <option value="Interrogation">Interrogation</option>
                        </select>
                    </div>
                    <br/>
                    <div id="cancelprogrammenotes">
                        <label id="deletematiereprogrammenotes" style={labelStyle}>Trimestre+Matière</label>
                        <br/><br/>
                        <table className='thin_table_config'>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Type</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody id='deleteProgrammeNotesTableBody'></tbody>
                        </table>
                    </div>
                    <br/>
                    <div id="canceldatenotes">
                        <label style={labelStyle}>Date Exécution</label>
                        <DateInput idinput ="deleteecheancenotes" date="date" read="readOnly" />
                    </div>
                    <br/>
                    <div id="cancelobtenunotes">
                        <label style={labelStyle}>Note Obtenue</label>
                        <NumberInput idinput ="deleteobtenuenotes" number="number" read="readOnly" />
                    </div>
                    <br/>
                    <div id="cancelcoefficientnotes">
                        <label style={labelStyle}>Coefficient</label>
                        <NumberInput idinput ="deletecoefficientnotes" number="number" read="readOnly" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deleteselectcalendriernotes" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deleteselectmatierenotes" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deleteidnotes" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deleteidreferencenotes" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deletecodeusernotes" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deleteeffectuenotes" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deletedatenotes" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deletestatutnotes" type="text" />
                    </div>
                    <br/>
                    <div id="cancelnotes">
                        <SupprimerButton 
                            idsupprimerbutton="supprimernotesBtn"
                            supprimer="Supprimer"
                        />
                    </div>
                    <br/><br/>
                </div>
                <div id="modaloutnotes"></div>
                <div id="backdropoutnotes"></div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default DeleteNotes;