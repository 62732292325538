import './inscription.css';

import '../demarrage/pouchdemarrer';
import { KeyboardBackspace, SettingsBackupRestore, } from '@material-ui/icons';

function PasswordOublie() {
    return ( 
        <div id="passwordoublie">
            <br/>
            <KeyboardBackspace style={prevArrows} id="prevpasswordoublieBtn_1" />
            <br/><br/>
            <form>
                <div id="formpasswordoublie">
                    <div>
                        <input id ="nomoublie" type="text" placeholder="Entrer votre nom" />
                    </div>
                    <br/>
                    <div>
                        <input id ="prenomoublie" type="text" placeholder="Entrer vos prenoms" />
                    </div>
                    <br/>
                    <div>
                        <button id="confirmeroublieBtn" style={connectionFormButtonStyle}>
                            <SettingsBackupRestore />
                            Confirmer
                        </button>
                    </div>
                    <br/>
                    <label>Pas de compte? </label><label style={{color:'#1a1aff'}} id="inscrireoublieBtn">S'inscrire</label>
                </div>
            </form>
        </div>
    );
}

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const connectionFormButtonStyle = {
    marginTop: '4%',
    flex: 1,
    width: 200,
    height: 50,
    textAlign: "center",
    alignContent: 'center',
    backgroundColor: "#008000", border: 1, solid: "#008000",
    fontSize: 18,
    borderRadius: 15,
    color: "#ffffff",
    /*textTransform: "uppercase",*/
};

export default PasswordOublie;