/* eslint-disable no-redeclare */
import PouchDB from 'pouchdb';
import PouchFind from 'pouchdb-find';

(function(){
    var taskProgramme = function(){
        /*****************************************************
        ENABLING POUCHDB WITHOUT SYNC TO REMOTE COUCHDB
        ******************************************************/
        //SET UP POUCHDB AND CREATE THE DATABASE 
        const db = new PouchDB('suclu');
        PouchDB.plugin(PouchFind);

        /***************
        crudprogrammeBtn
        *****************/
        /**nav previous page button*/
        document.getElementById('prevcrudprogrammeBtn_1').addEventListener('click', prevcrudprogramme1BtnChange);
        async function prevcrudprogramme1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'block';
            document.getElementById('dashboard').style.display = 'block';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('programme').style.display = 'none';
            titre.innerHTML = 'Tableau de Bord';
        }

        /***************************
        addprogrammeBtn
        *****************************/
        document.getElementById('addprogrammeBtn').addEventListener('click', addprogrammeBtnChange);
        async function addprogrammeBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutanneeStr = 'Oui';
            var statutmatiereStr = 'Oui';

            var docsAnnee = [];
            var docsMatiere = [];

            if(!userCodeStr){
                alert('Connectez-vous à votre Compte avant de continuer');
            }else{
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: ['anneestatut', 'inscritcode']
                        }
                    });
                    result = await db.find({
                        selector: {
                            anneestatut: statutanneeStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsAnnee  = result.docs;
                    var resultatAnnee = docsAnnee.length;
                    if(resultatAnnee === 0){
                        alert('Aucun Calendrier Année Scolaire Enregistré');
                    }else{
                        var divClear;
                        var divModifier;
                        divClear = document.getElementById('addcalendrierprogramme');
                        while(divClear.hasChildNodes()) {
                            divClear.removeChild(divClear.childNodes[0]);
                        }
                        divModifier = document.getElementById('addcalendrierprogramme');
                        //Create and append select list
                        var selectList = document.createElement("select");
                        selectList.id = "seladdcalendrierprogramme";
                        divModifier.appendChild(selectList);
                        //Create and append the options
                        var option_null = document.createElement("option");
                        option_null.value = '';
                        option_null.text = 'Sélectionner Calendrier';
                        selectList.appendChild(option_null);
                        //Create and append the options
                        for (var i = 0; i<docsAnnee.length; i++){
                            var option = document.createElement("option");
                            option.value = docsAnnee[i].anneescolaire;
                            option.text = docsAnnee[i].anneescolaire;
                            selectList.appendChild(option);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'none';
                        document.getElementById('programme').style.display = 'block';
                        document.getElementById('crudprogramme').style.display = 'none';
                        document.getElementById('addprogramme').style.display = 'block';
                        document.getElementById('editprogramme').style.display = 'none';
                        document.getElementById('deleteprogramme').style.display = 'none';
                        document.getElementById('searchprogramme').style.display = 'none';
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('savecalendrierprogramme').style.display = 'block';
                        document.getElementById('savematiereprogramme').style.display = 'none';
                        document.getElementById('savetypeprogramme').style.display = 'none';
                        document.getElementById('savedateprogramme').style.display = 'none';
                        document.getElementById('saveprogramme').style.display = 'none';
                        titre.innerHTML = 'Enregistrer Programme Devoir';

                        /***************************
                        seladdcalendrierprogramme
                        *****************************/
                        document.getElementById('seladdcalendrierprogramme').addEventListener('change', seladdcalendrierprogrammeChange);
                        async function seladdcalendrierprogrammeChange(e){
                            e.preventDefault();

                            var seladdcalendrierprogrammeStr = document.getElementById('seladdcalendrierprogramme').value;
                            if(!seladdcalendrierprogrammeStr){
                                alert('Sélectionner un calendrier scolaire avant de continuer');
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('savecalendrierprogramme').style.display = 'block';
                                document.getElementById('savematiereprogramme').style.display = 'none';
                                document.getElementById('savetypeprogramme').style.display = 'none';
                                document.getElementById('savedateprogramme').style.display = 'none';
                                document.getElementById('saveprogramme').style.display = 'none';
                            }else{
                                document.getElementById('addselectcalendrierprogramme').value = seladdcalendrierprogrammeStr;
                                try {
                                    let result = await db.createIndex({
                                        index: {
                                            fields: ['matierestatut', 'inscritcode']
                                        }
                                    });
                                    result = await db.find({
                                        selector: {
                                            matierestatut: statutmatiereStr,
                                            inscritcode: userCodeStr
                                        }
                                    });
                                    docsMatiere  = result.docs;
                                    var resultatMatiere = docsMatiere.length;
                                    if(resultatMatiere === 0){
                                        alert("Matières Classe non encore enregistrées");
                                    }else{
                                        var divClear;
                                        var divModifier;
                                        divClear = document.getElementById('addmatiereprogramme');
                                        while(divClear.hasChildNodes()) {
                                            divClear.removeChild(divClear.childNodes[0]);
                                        }
                                        divModifier = document.getElementById('addmatiereprogramme');
                                        //Create and append select list
                                        var selectList = document.createElement("select");
                                        selectList.id = "seladdmatiereprogramme";
                                        divModifier.appendChild(selectList);
                                        //Create and append the options
                                        var option_null = document.createElement("option");
                                        option_null.value = '';
                                        option_null.text = 'Sélectionner Matière';
                                        selectList.appendChild(option_null);
                                        //Create and append the options
                                        for (var i = 0; i<docsMatiere.length; i++){
                                            var option = document.createElement("option");
                                            option.value = docsMatiere[i].matierenom;
                                            option.text = docsMatiere[i].matierenom;
                                            selectList.appendChild(option);
                                        }

                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('savecalendrierprogramme').style.display = 'block';
                                        document.getElementById('savematiereprogramme').style.display = 'block';
                                        document.getElementById('savetypeprogramme').style.display = 'none';
                                        document.getElementById('savedateprogramme').style.display = 'none';
                                        document.getElementById('saveprogramme').style.display = 'none';

                                        /***************************
                                        seladdmatiereprogramme
                                        *****************************/
                                        document.getElementById('seladdmatiereprogramme').addEventListener('change', seladdmatiereprogrammeChange);
                                        async function seladdmatiereprogrammeChange(e){
                                            e.preventDefault();

                                            document.getElementById('seladdtypeprogramme').value = '';

                                            var seladdmatiereprogrammeStr = document.getElementById('seladdmatiereprogramme').value;
                                            if(!seladdmatiereprogrammeStr){
                                                alert('Sélectionner une matière avant de continuer');
                                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                                document.getElementById('savecalendrierprogramme').style.display = 'block';
                                                document.getElementById('savematiereprogramme').style.display = 'block';
                                                document.getElementById('savetypeprogramme').style.display = 'none';
                                                document.getElementById('savedateprogramme').style.display = 'none';
                                                document.getElementById('saveprogramme').style.display = 'none';
                                            }else{
                                                document.getElementById('addselectmatiereprogramme').value = seladdmatiereprogrammeStr;
                                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                                document.getElementById('savecalendrierprogramme').style.display = 'block';
                                                document.getElementById('savematiereprogramme').style.display = 'block';
                                                document.getElementById('savetypeprogramme').style.display = 'block';
                                                document.getElementById('savedateprogramme').style.display = 'none';
                                                document.getElementById('saveprogramme').style.display = 'none';
                                            }
                                        }
                                    }
                                } catch (error) {
                                    alert('Erreur Exécution Matières');
                                }
                            }
                        }
                    } 
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }
        /**nav previous page button*/
        document.getElementById('prevaddprogrammeBtn_1').addEventListener('click', prevaddprogramme1BtnChange);
        async function prevaddprogramme1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'none';
            document.getElementById('programme').style.display = 'block';
            document.getElementById('crudprogramme').style.display = 'block';
            document.getElementById('addprogramme').style.display = 'none';
            document.getElementById('editprogramme').style.display = 'none';
            document.getElementById('deleteprogramme').style.display = 'none';
            document.getElementById('searchprogramme').style.display = 'none';
            titre.innerHTML = 'Programme des Devoirs'; 
        }
        /***************************
        seladdtypeprogramme
        *****************************/
        document.getElementById('seladdtypeprogramme').addEventListener('change', seladdtypeprogrammeChange);
        async function seladdtypeprogrammeChange(e){
            e.preventDefault();

            document.getElementById('adddateprogramme').value = '';

            var seladdtypeprogrammeStr = document.getElementById('seladdtypeprogramme').value;
            if(!seladdtypeprogrammeStr){
                alert('Sélectionner un type de devoir avant de continuer');
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('savecalendrierprogramme').style.display = 'block';
                document.getElementById('savematiereprogramme').style.display = 'block';
                document.getElementById('savetypeprogramme').style.display = 'block';
                document.getElementById('savedateprogramme').style.display = 'none';
                document.getElementById('saveprogramme').style.display = 'none';
            }else{
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('savecalendrierprogramme').style.display = 'block';
                document.getElementById('savematiereprogramme').style.display = 'block';
                document.getElementById('savetypeprogramme').style.display = 'block';
                document.getElementById('savedateprogramme').style.display = 'block';
                document.getElementById('saveprogramme').style.display = 'block';
            }
        }

        /***************************
        enregistrerprogrammeBtn
        *****************************/
        document.getElementById('enregistrerprogrammeBtn').addEventListener('click', enregistrerprogrammeBtnChange);
        async function enregistrerprogrammeBtnChange(e){
            e.preventDefault();

            var usercodeStr = document.getElementById('userCode').innerText;
            var addselectcalendrierprogrammeStr = document.getElementById('addselectcalendrierprogramme').value;
            var addselectmatiereprogrammeStr = document.getElementById('addselectmatiereprogramme').value;
            var seladdtypeprogrammeStr = document.getElementById('seladdtypeprogramme').value;
            var adddateprogrammeStr = document.getElementById('adddateprogramme').value;
            var addeffectuereprogrammeStr = 'Non';
            var datejourStr = getCurrentDate();
            var addstatutprogrammeStr = 'Oui';
            var statutanneeStr = 'Oui';
            var docsAnnee = [];



            if(!usercodeStr){
                alert("Identifiez-vous avant de continuer");
            }else if(!addselectcalendrierprogrammeStr){
                alert("Sélectionner Calendrier Scolaire");
            }else if(!addselectmatiereprogrammeStr){
                alert("Sélectionner Matière");
            }else if(!seladdtypeprogrammeStr){
                alert("Sélectionner Type Devoir");
            }else if(!adddateprogrammeStr){
                alert("Saisir Date Devoir");
            }else{
                try {
                    //check if date between start and end date anneescolaire
                    let result = await db.createIndex({
                        index: {
                            fields: ['anneestatut', 'inscritcode', 'anneescolaire']
                        }
                    });
                    result = await db.find({
                        selector: {
                            anneestatut: statutanneeStr,
                            inscritcode: usercodeStr,
                            anneescolaire: addselectcalendrierprogrammeStr
                        }
                    });
                    docsAnnee  = result.docs;
                    var resultatAnnee = docsAnnee.length;
                    if(resultatAnnee === 0){
                        alert('Résultat Indisponible');
                    }else{
                        var anneeDebutStr, anneeFinStr = '';
                        //retrieve start and end date
                        for(let i=0; i<docsAnnee.length; i++){
                            anneeDebutStr = docsAnnee[i].anneedebut;
                            anneeFinStr = docsAnnee[i].anneefin;
                        }

                        var g1 = new Date(anneeDebutStr).getTime();
                        var g2 = new Date(anneeFinStr).getTime();
                        var g3 = new Date(adddateprogrammeStr).getTime();
                        if(g3 >= g1){
                            if(g3 <= g2){
                                //Create the document _id and _rev.
                                var now = Date.now();
                                var id = 'programme_' + now; 
                                // Add the document to the database.
                                var response = await db.put({
                                    _id: id,
                                    inscritcode: usercodeStr,
                                    anneescolaire: addselectcalendrierprogrammeStr,
                                    matierenom: addselectmatiereprogrammeStr,
                                    programmedevoir: seladdtypeprogrammeStr,
                                    programmeecheance: adddateprogrammeStr, //date
                                    programmeeffectue: addeffectuereprogrammeStr,
                                    programmedate: datejourStr,
                                    programmestatut: addstatutprogrammeStr,
                                });
                                // check if document inserted and synced
                                var doc = await db.get(response.id);
                                if(doc !== ''){
                                    alert('Enregistré. Programme Devoir Matière Suivante');

                                    document.getElementById('seladdtypeprogramme').value = '';
                                    document.getElementById('adddateprogramme').value = '';
                                    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                    document.getElementById('savecalendrierprogramme').style.display = 'block';
                                    document.getElementById('savematiereprogramme').style.display = 'block';
                                    document.getElementById('savetypeprogramme').style.display = 'block';
                                    document.getElementById('savedateprogramme').style.display = 'none';
                                    document.getElementById('saveprogramme').style.display = 'none';
                                }else{
                                    alert('Echec Enregistrement Programme Devoir. Reprendre');
                                    document.getElementById('titre').style.display = 'block';
                                    var titre = document.getElementById('titre');

                                    document.getElementById('demarrage').style.display = 'none';
                                    document.getElementById('tableaubord').style.display = 'none';
                                    document.getElementById('dashboard').style.display = 'none';
                                    document.getElementById('configurations').style.display = 'none';
                                    document.getElementById('configurer').style.display = 'none';
                                    document.getElementById('emploidutemps').style.display = 'none';
                                    document.getElementById('exercice').style.display = 'none';
                                    document.getElementById('pratique').style.display = 'none';
                                    document.getElementById('tache').style.display = 'none';
                                    document.getElementById('revision').style.display = 'none';
                                    document.getElementById('recherche').style.display = 'none';
                                    document.getElementById('programme').style.display = 'block';
                                    document.getElementById('crudprogramme').style.display = 'block';
                                    document.getElementById('addprogramme').style.display = 'none';
                                    document.getElementById('editprogramme').style.display = 'none';
                                    document.getElementById('deleteprogramme').style.display = 'none';
                                    document.getElementById('searchprogramme').style.display = 'none';
                                    titre.innerHTML = 'Programme des Devoirs';
                                }
                            }else{
                                alert("Erreur. Date Devoir Postérieure à Fin "+addselectcalendrierprogrammeStr)
                            }
                        }else{
                            alert("Erreur. Date Devoir antérieure à Debut "+addselectcalendrierprogrammeStr);
                        }
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }

        /***************************
        editprogrammeBtn
        *****************************/
        document.getElementById('editprogrammeBtn').addEventListener('click', editprogrammeBtnChange);
        async function editprogrammeBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutanneeStr = 'Oui';
            var statutmatiereStr = 'Oui';

            var docsAnnee = [];
            var docsMatiere = [];

            if(!userCodeStr){
                alert('Connectez-vous à votre Compte avant de continuer');
            }else{
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: ['anneestatut', 'inscritcode']
                        }
                    });
                    result = await db.find({
                        selector: {
                            anneestatut: statutanneeStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsAnnee  = result.docs;
                    var resultatAnnee = docsAnnee.length;
                    if(resultatAnnee === 0){
                        alert('Aucun Calendrier Année Scolaire Enregistré');
                    }else{
                        var divClear;
                        var divModifier;
                        divClear = document.getElementById('editcalendrierprogramme');
                        while(divClear.hasChildNodes()) {
                            divClear.removeChild(divClear.childNodes[0]);
                        }
                        divModifier = document.getElementById('editcalendrierprogramme');
                        //Create and append select list
                        var selectList = document.createElement("select");
                        selectList.id = "seleditcalendrierprogramme";
                        divModifier.appendChild(selectList);
                        //Create and append the options
                        var option_null = document.createElement("option");
                        option_null.value = '';
                        option_null.text = 'Sélectionner Calendrier';
                        selectList.appendChild(option_null);
                        //Create and append the options
                        for (var i = 0; i<docsAnnee.length; i++){
                            var option = document.createElement("option");
                            option.value = docsAnnee[i].anneescolaire;
                            option.text = docsAnnee[i].anneescolaire;
                            selectList.appendChild(option);
                        }
    
                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');
    
                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'none';
                        document.getElementById('programme').style.display = 'block';
                        document.getElementById('crudprogramme').style.display = 'none';
                        document.getElementById('addprogramme').style.display = 'none';
                        document.getElementById('editprogramme').style.display = 'block';
                        document.getElementById('deleteprogramme').style.display = 'none';
                        document.getElementById('searchprogramme').style.display = 'none';
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('changecalendrierprogramme').style.display = 'block';
                        document.getElementById('changematiereprogramme').style.display = 'none';
                        document.getElementById('changetypeprogramme').style.display = 'none';
                        document.getElementById('changeseldateprogramme').style.display = 'none';
                        document.getElementById('changedateprogramme').style.display = 'none';
                        document.getElementById('changeprogramme').style.display = 'none';
                        titre.innerHTML = 'Modifier Programme Devoir';
    
                        /***************************
                        seleditcalendrierprogramme
                        *****************************/
                        document.getElementById('seleditcalendrierprogramme').addEventListener('change', seleditcalendrierprogrammeChange);
                        async function seleditcalendrierprogrammeChange(e){
                            e.preventDefault();

                            var seleditcalendrierprogrammeStr = document.getElementById('seleditcalendrierprogramme').value;
                            if(!seleditcalendrierprogrammeStr){
                                alert('Sélectionner un calendrier scolaire avant de continuer');
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('changecalendrierprogramme').style.display = 'block';
                                document.getElementById('changematiereprogramme').style.display = 'none';
                                document.getElementById('changetypeprogramme').style.display = 'none';
                                document.getElementById('changeseldateprogramme').style.display = 'none';
                                document.getElementById('changedateprogramme').style.display = 'none';
                                document.getElementById('changeprogramme').style.display = 'none';
                            }else{
                                document.getElementById('editselectcalendrierprogramme').value = seleditcalendrierprogrammeStr;
                                try {
                                    let result = await db.createIndex({
                                        index: {
                                            fields: ['matierestatut', 'inscritcode']
                                        }
                                    });
                                    result = await db.find({
                                        selector: {
                                            matierestatut: statutmatiereStr,
                                            inscritcode: userCodeStr
                                        }
                                    });
                                    docsMatiere  = result.docs;
                                    var resultatMatiere = docsMatiere.length;
                                    if(resultatMatiere === 0){
                                        alert("Matières Classe non encore enregistrées");
                                    }else{
                                        var divClear;
                                        var divModifier;
                                        divClear = document.getElementById('editmatiereprogramme');
                                        while(divClear.hasChildNodes()) {
                                            divClear.removeChild(divClear.childNodes[0]);
                                        }
                                        divModifier = document.getElementById('editmatiereprogramme');
                                        //Create and append select list
                                        var selectList = document.createElement("select");
                                        selectList.id = "seleditmatiereprogramme";
                                        divModifier.appendChild(selectList);
                                        //Create and append the options
                                        var option_null = document.createElement("option");
                                        option_null.value = '';
                                        option_null.text = 'Sélectionner Matière';
                                        selectList.appendChild(option_null);
                                        //Create and append the options
                                        for (var i = 0; i<docsMatiere.length; i++){
                                            var option = document.createElement("option");
                                            option.value = docsMatiere[i].matierenom;
                                            option.text = docsMatiere[i].matierenom;
                                            selectList.appendChild(option);
                                        }

                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('changecalendrierprogramme').style.display = 'block';
                                        document.getElementById('changematiereprogramme').style.display = 'block';
                                        document.getElementById('changetypeprogramme').style.display = 'none';
                                        document.getElementById('changeseldateprogramme').style.display = 'none';
                                        document.getElementById('changedateprogramme').style.display = 'none';
                                        document.getElementById('changeprogramme').style.display = 'none';

                                        /***************************
                                        seleditmatiereprogramme
                                        *****************************/
                                        document.getElementById('seleditmatiereprogramme').addEventListener('change', seleditmatiereprogrammeChange);
                                        async function seleditmatiereprogrammeChange(e){
                                            e.preventDefault();

                                            document.getElementById('seledittypeprogramme').value = '';

                                            var seleditmatiereprogrammeStr = document.getElementById('seleditmatiereprogramme').value;
                                            if(!seleditmatiereprogrammeStr){
                                                alert('Sélectionner une matière avant de continuer');
                                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                                document.getElementById('changecalendrierprogramme').style.display = 'block';
                                                document.getElementById('changematiereprogramme').style.display = 'block';
                                                document.getElementById('changetypeprogramme').style.display = 'none';
                                                document.getElementById('changeseldateprogramme').style.display = 'none';
                                                document.getElementById('changedateprogramme').style.display = 'none';
                                                document.getElementById('changeprogramme').style.display = 'none';
                                            }else{
                                                document.getElementById('editselectmatiereprogramme').value = seleditmatiereprogrammeStr;
                                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                                document.getElementById('changecalendrierprogramme').style.display = 'block';
                                                document.getElementById('changematiereprogramme').style.display = 'block';
                                                document.getElementById('changetypeprogramme').style.display = 'block';
                                                document.getElementById('changeseldateprogramme').style.display = 'none';
                                                document.getElementById('changedateprogramme').style.display = 'none';
                                                document.getElementById('changeprogramme').style.display = 'none';
                                            }
                                        }
                                    }
                                } catch (error) {
                                    alert('Erreur Exécution Matières');
                                }
                            }
                        }
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }
        /**nav previous page button*/
        document.getElementById('preveditprogrammeBtn_1').addEventListener('click', preveditprogramme1BtnChange);
        async function preveditprogramme1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'none';
            document.getElementById('programme').style.display = 'block';
            document.getElementById('crudprogramme').style.display = 'block';
            document.getElementById('addprogramme').style.display = 'none';
            document.getElementById('editprogramme').style.display = 'none';
            document.getElementById('deleteprogramme').style.display = 'none';
            document.getElementById('searchprogramme').style.display = 'none';
            titre.innerHTML = 'Programme des Devoirs'; 
        }
        /***************************
        seledittypeprogramme
        *****************************/
        document.getElementById('seledittypeprogramme').addEventListener('change', seledittypeprogrammeChange);
        async function seledittypeprogrammeChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutprogrammeStr = 'Oui';
            var effectueprogrammeStr = 'Non';
            var docsEdit = [];

            document.getElementById('editdateprogramme').value = '';

            var programmeIdStr,inscritCodeStr,programmeEcheanceStr,programmeEffectueStr,programmeDateStr,
            programmeStatutStr = '';

            var editselectcalendrierprogrammeStr = document.getElementById('editselectcalendrierprogramme').value;
            var editselectmatiereprogrammeStr = document.getElementById('editselectmatiereprogramme').value;
            var seledittypeprogrammeStr = document.getElementById('seledittypeprogramme').value;
            if(!seledittypeprogrammeStr){
                alert('Sélectionner un type de devoir avant de continuer');
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('changecalendrierprogramme').style.display = 'block';
                document.getElementById('changematiereprogramme').style.display = 'block';
                document.getElementById('changetypeprogramme').style.display = 'block';
                document.getElementById('changeseldateprogramme').style.display = 'none';
                document.getElementById('changedateprogramme').style.display = 'none';
                document.getElementById('changeprogramme').style.display = 'none';
            }else{
                if(!userCodeStr){
                    alert('Connectez-vous à votre Compte avant de continuer');
                }else{
                    try {
                        let result = await db.createIndex({
                            index: {
                                fields: [
                                    'programmestatut',
                                    'programmeeffectue', 
                                    'inscritcode', 
                                    'anneescolaire', 
                                    'matierenom', 
                                    'programmedevoir'
                                ]
                            }
                        });
                        result = await db.find({
                            selector: {
                                programmestatut: statutprogrammeStr,
                                programmeeffectue: effectueprogrammeStr, 
                                inscritcode: userCodeStr,
                                anneescolaire: editselectcalendrierprogrammeStr,
                                matierenom: editselectmatiereprogrammeStr,
                                programmedevoir: seledittypeprogrammeStr
                            }
                        });
                        docsEdit  = result.docs;
                        var resultatEdit = docsEdit.length; 
                        if(resultatEdit === 0){
                            alert('Aucun Résultat Disponible');
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('changecalendrierprogramme').style.display = 'block';
                            document.getElementById('changematiereprogramme').style.display = 'block';
                            document.getElementById('changetypeprogramme').style.display = 'block';
                            document.getElementById('changeseldateprogramme').style.display = 'none';
                            document.getElementById('changedateprogramme').style.display = 'none';
                            document.getElementById('changeprogramme').style.display = 'none';
                        }else{
                            var divClear;
                            var divModifier;
                            divClear = document.getElementById('editseldateprogramme');
                            while(divClear.hasChildNodes()) {
                                divClear.removeChild(divClear.childNodes[0]);
                            }
                            divModifier = document.getElementById('editseldateprogramme');
                            //Create and append select list
                            var selectList = document.createElement("select");
                            selectList.id = "seleditdateprogramme";
                            divModifier.appendChild(selectList);
                            //Create and append the options
                            var option_null = document.createElement("option");
                            option_null.value = '';
                            option_null.text = 'Sélectionner Date';
                            selectList.appendChild(option_null);
                            //Create and append the options
                            for (var i = 0; i<docsEdit.length; i++){
                                var option = document.createElement("option");
                                option.value = docsEdit[i]._id;
                                option.text = new Date(docsEdit[i].programmeecheance).toLocaleDateString();
                                selectList.appendChild(option);
                            }

                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('changecalendrierprogramme').style.display = 'block';
                            document.getElementById('changematiereprogramme').style.display = 'block';
                            document.getElementById('changetypeprogramme').style.display = 'block';
                            document.getElementById('changeseldateprogramme').style.display = 'block';
                            document.getElementById('changedateprogramme').style.display = 'none';
                            document.getElementById('changeprogramme').style.display = 'none';

                            /***************************
                            seleditdateprogramme
                            *****************************/
                            document.getElementById('seleditdateprogramme').addEventListener('change', seleditdateprogrammeChange);
                            async function seleditdateprogrammeChange(e){
                                e.preventDefault();

                                var docsEditProgramme = [];

                                var seleditdateprogrammeStr = document.getElementById('seleditdateprogramme').value;
                                try {
                                    let result = await db.createIndex({
                                        index: {
                                            fields: ['_id']
                                        }
                                    });
                                    result = await db.find({
                                        selector: {
                                            _id: seleditdateprogrammeStr
                                        }
                                    });
                                    docsEditProgramme  = result.docs;
                                    var resultatEditProgramme = docsEditProgramme.length;
                                    if(resultatEditProgramme === 0){
                                        alert("Résultat Indisponible");
                                    }else{
                                        for(let j=0; j<docsEditProgramme.length; j++){
                                            programmeIdStr = docsEditProgramme[j]._id;
                                            inscritCodeStr = docsEditProgramme[j].inscritcode;
                                            programmeEcheanceStr = docsEditProgramme[j].programmeecheance;
                                            programmeEffectueStr = docsEditProgramme[j].programmeeffectue;
                                            programmeDateStr = docsEditProgramme[j].programmedate;
                                            programmeStatutStr = docsEditProgramme[j].programmestatut;
                                        }

                                        document.getElementById('editidprogramme').value = programmeIdStr;
                                        document.getElementById('editcodeuserprogramme').value = inscritCodeStr;
                                        document.getElementById('editdateecheanceprogramme').value = programmeEcheanceStr;
                                        document.getElementById('editeffectueprogramme').value = programmeEffectueStr;
                                        document.getElementById('editdateprogramme').value = programmeDateStr;
                                        document.getElementById('editstatutprogramme').value = programmeStatutStr;

                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('changecalendrierprogramme').style.display = 'block';
                                        document.getElementById('changematiereprogramme').style.display = 'block';
                                        document.getElementById('changetypeprogramme').style.display = 'block';
                                        document.getElementById('changeseldateprogramme').style.display = 'none';
                                        document.getElementById('changedateprogramme').style.display = 'block';
                                        document.getElementById('changeprogramme').style.display = 'block';
                                    }
                                } catch (error) {
                                    alert('Erreur Exécution');
                                }

                            }
                        }
                    } catch (error) {
                        alert('Erreur Exécution');
                    }
                }
            }
        }
        /***************************
        modifierprogrammeBtn
        *****************************/
        document.getElementById('modifierprogrammeBtn').addEventListener('click', modifierprogrammeBtnChange);
        async function modifierprogrammeBtnChange(e){
            e.preventDefault();

            var editselectcalendrierprogrammeStr = document.getElementById('editselectcalendrierprogramme').value;
            var editselectmatiereprogrammeStr = document.getElementById('editselectmatiereprogramme').value;
            var seledittypeprogrammeStr = document.getElementById('seledittypeprogramme').value;
            var editdateecheanceprogrammeStr = document.getElementById('editdateecheanceprogramme').value;

            var editidprogrammeStr = document.getElementById('editidprogramme').value;
            var editcodeuserprogrammeStr = document.getElementById('editcodeuserprogramme').value;
            var editeffectueprogrammeStr = document.getElementById('editeffectueprogramme').value;
            var editdateprogrammeStr = document.getElementById('editdateprogramme').value;
            var editstatutprogrammeStr = document.getElementById('editstatutprogramme').value;
            if(!editcodeuserprogrammeStr){
                alert("Identifiez-vous avant de continuer");
            }else if(!editdateecheanceprogrammeStr){
                alert('Saisir Date Devoir');
            }else{
                try {
                    // Retrieve the document to get the latest revision
                    var id = editidprogrammeStr;
                    var doc = await db.get(id);
                    // Make changes
                    doc.inscritcode = editcodeuserprogrammeStr;
                    doc.anneescolaire = editselectcalendrierprogrammeStr;
                    doc.matierenom = editselectmatiereprogrammeStr;
                    doc.programmedevoir = seledittypeprogrammeStr;
                    doc.programmeecheance = editdateecheanceprogrammeStr;
                    doc.programmeeffectue = editeffectueprogrammeStr;
                    doc.programmedate = editdateprogrammeStr;
                    doc.programmestatut = editstatutprogrammeStr;
                    // Put the document, saving it as a new revision
                    var response = await db.put(doc);
                    // Get the document we change and update the UI.
                    var docEdit = await db.get(response.id);
                    if(docEdit !== ''){
                        alert("Programme Devoir Modifié avec Succès. Mettre à jour cette date dans la section note");
                        document.getElementById('editselectcalendrierprogramme').value = '';
                        document.getElementById('editselectmatiereprogramme').value = '';
                        document.getElementById('seledittypeprogramme').value = '';
                        document.getElementById('editdateecheanceprogramme').value = '';
                        document.getElementById('editidprogramme').value = '';
                        document.getElementById('editcodeuserprogramme').value = '';
                        document.getElementById('editeffectueprogramme').value = '';
                        document.getElementById('editdateprogramme').value = '';
                        document.getElementById('editstatutprogramme').value = '';

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'none';
                        document.getElementById('programme').style.display = 'block';
                        document.getElementById('crudprogramme').style.display = 'block';
                        document.getElementById('addprogramme').style.display = 'none';
                        document.getElementById('editprogramme').style.display = 'none';
                        document.getElementById('deleteprogramme').style.display = 'none';
                        document.getElementById('searchprogramme').style.display = 'none';
                        titre.innerHTML = 'Programme des Devoirs'; 
                    }else{
                        alert("Erreur Modification. Reprendre");
                        document.getElementById('editselectcalendrierprogramme').value = '';
                        document.getElementById('editselectmatiereprogramme').value = '';
                        document.getElementById('seledittypeprogramme').value = '';
                        document.getElementById('editdateecheanceprogramme').value = '';
                        document.getElementById('editidprogramme').value = '';
                        document.getElementById('editcodeuserprogramme').value = '';
                        document.getElementById('editeffectueprogramme').value = '';
                        document.getElementById('editdateprogramme').value = '';
                        document.getElementById('editstatutprogramme').value = '';

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'none';
                        document.getElementById('programme').style.display = 'block';
                        document.getElementById('crudprogramme').style.display = 'block';
                        document.getElementById('addprogramme').style.display = 'none';
                        document.getElementById('editprogramme').style.display = 'none';
                        document.getElementById('deleteprogramme').style.display = 'none';
                        document.getElementById('searchprogramme').style.display = 'none';
                        titre.innerHTML = 'Programme des Devoirs'; 
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }

        /***************************
        deleteprogrammeBtn
        *****************************/
        document.getElementById('deleteprogrammeBtn').addEventListener('click', deleteprogrammeBtnChange);
        async function deleteprogrammeBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutanneeStr = 'Oui';
            var statutmatiereStr = 'Oui';

            var docsAnnee = [];
            var docsMatiere = [];

            if(!userCodeStr){
                alert('Connectez-vous à votre Compte avant de continuer');
            }else{
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: ['anneestatut', 'inscritcode']
                        }
                    });
                    result = await db.find({
                        selector: {
                            anneestatut: statutanneeStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsAnnee  = result.docs;
                    var resultatAnnee = docsAnnee.length;
                    if(resultatAnnee === 0){
                        alert('Aucun Calendrier Année Scolaire Enregistré');
                    }else{
                        var divClear;
                        var divModifier;
                        divClear = document.getElementById('deletecalendrierprogramme');
                        while(divClear.hasChildNodes()) {
                            divClear.removeChild(divClear.childNodes[0]);
                        }
                        divModifier = document.getElementById('deletecalendrierprogramme');
                        //Create and append select list
                        var selectList = document.createElement("select");
                        selectList.id = "seldeletecalendrierprogramme";
                        divModifier.appendChild(selectList);
                        //Create and append the options
                        var option_null = document.createElement("option");
                        option_null.value = '';
                        option_null.text = 'Sélectionner Calendrier';
                        selectList.appendChild(option_null);
                        //Create and append the options
                        for (var i = 0; i<docsAnnee.length; i++){
                            var option = document.createElement("option");
                            option.value = docsAnnee[i].anneescolaire;
                            option.text = docsAnnee[i].anneescolaire;
                            selectList.appendChild(option);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');
    
                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'none';
                        document.getElementById('programme').style.display = 'block';
                        document.getElementById('crudprogramme').style.display = 'none';
                        document.getElementById('addprogramme').style.display = 'none';
                        document.getElementById('editprogramme').style.display = 'none';
                        document.getElementById('deleteprogramme').style.display = 'block';
                        document.getElementById('searchprogramme').style.display = 'none';
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('cancelcalendrierprogramme').style.display = 'block';
                        document.getElementById('cancelmatiereprogramme').style.display = 'none';
                        document.getElementById('canceltypeprogramme').style.display = 'none';
                        document.getElementById('cancelseldateprogramme').style.display = 'none';
                        document.getElementById('canceldateprogramme').style.display = 'none';
                        document.getElementById('cancelprogramme').style.display = 'none';
                        titre.innerHTML = 'Supprimer Programme Devoir';

                        /***************************
                        seldeletecalendrierprogramme
                        *****************************/
                        document.getElementById('seldeletecalendrierprogramme').addEventListener('change', seldeletecalendrierprogrammeChange);
                        async function seldeletecalendrierprogrammeChange(e){
                            e.preventDefault();

                            var seldeletecalendrierprogrammeStr = document.getElementById('seldeletecalendrierprogramme').value;
                            if(!seldeletecalendrierprogrammeStr){
                                alert('Sélectionner un calendrier scolaire avant de continuer');
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('cancelcalendrierprogramme').style.display = 'block';
                                document.getElementById('cancelmatiereprogramme').style.display = 'none';
                                document.getElementById('canceltypeprogramme').style.display = 'none';
                                document.getElementById('cancelseldateprogramme').style.display = 'none';
                                document.getElementById('canceldateprogramme').style.display = 'none';
                                document.getElementById('cancelprogramme').style.display = 'none';
                            }else{
                                document.getElementById('deleteselectcalendrierprogramme').value = seldeletecalendrierprogrammeStr;
                                try {
                                    let result = await db.createIndex({
                                        index: {
                                            fields: ['matierestatut', 'inscritcode']
                                        }
                                    });
                                    result = await db.find({
                                        selector: {
                                            matierestatut: statutmatiereStr,
                                            inscritcode: userCodeStr
                                        }
                                    });
                                    docsMatiere  = result.docs;
                                    var resultatMatiere = docsMatiere.length;
                                    if(resultatMatiere === 0){
                                        alert("Matières Classe non encore enregistrées");
                                    }else{
                                        var divClear;
                                        var divModifier;
                                        divClear = document.getElementById('deletematiereprogramme');
                                        while(divClear.hasChildNodes()) {
                                            divClear.removeChild(divClear.childNodes[0]);
                                        }
                                        divModifier = document.getElementById('deletematiereprogramme');
                                        //Create and append select list
                                        var selectList = document.createElement("select");
                                        selectList.id = "seldeletematiereprogramme";
                                        divModifier.appendChild(selectList);
                                        //Create and append the options
                                        var option_null = document.createElement("option");
                                        option_null.value = '';
                                        option_null.text = 'Sélectionner Matière';
                                        selectList.appendChild(option_null);
                                        //Create and append the options
                                        for (var i = 0; i<docsMatiere.length; i++){
                                            var option = document.createElement("option");
                                            option.value = docsMatiere[i].matierenom;
                                            option.text = docsMatiere[i].matierenom;
                                            selectList.appendChild(option);
                                        }

                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('cancelcalendrierprogramme').style.display = 'block';
                                        document.getElementById('cancelmatiereprogramme').style.display = 'block';
                                        document.getElementById('canceltypeprogramme').style.display = 'none';
                                        document.getElementById('cancelseldateprogramme').style.display = 'none';
                                        document.getElementById('canceldateprogramme').style.display = 'none';
                                        document.getElementById('cancelprogramme').style.display = 'none';

                                        /***************************
                                        seldeletematiereprogramme
                                        *****************************/
                                        document.getElementById('seldeletematiereprogramme').addEventListener('change', seldeletematiereprogrammeChange);
                                        async function seldeletematiereprogrammeChange(e){
                                            e.preventDefault();

                                            document.getElementById('seldeletetypeprogramme').value = '';

                                            var seldeletematiereprogrammeStr = document.getElementById('seldeletematiereprogramme').value;
                                            if(!seldeletematiereprogrammeStr){
                                                alert('Sélectionner une matière avant de continuer');
                                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                                document.getElementById('cancelcalendrierprogramme').style.display = 'block';
                                                document.getElementById('cancelmatiereprogramme').style.display = 'block';
                                                document.getElementById('canceltypeprogramme').style.display = 'none';
                                                document.getElementById('cancelseldateprogramme').style.display = 'none';
                                                document.getElementById('canceldateprogramme').style.display = 'none';
                                                document.getElementById('cancelprogramme').style.display = 'none';
                                            }else{
                                                document.getElementById('deleteselectmatiereprogramme').value = seldeletematiereprogrammeStr;
                                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                                document.getElementById('cancelcalendrierprogramme').style.display = 'block';
                                                document.getElementById('cancelmatiereprogramme').style.display = 'block';
                                                document.getElementById('canceltypeprogramme').style.display = 'block';
                                                document.getElementById('cancelseldateprogramme').style.display = 'none';
                                                document.getElementById('canceldateprogramme').style.display = 'none';
                                                document.getElementById('cancelprogramme').style.display = 'none';
                                            }
                                        }
                                    }
                                } catch (error) {
                                    alert('Erreur Exécution Matières');
                                }
                            }
                        }
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }
        /**nav previous page button*/
        document.getElementById('prevdeleteprogrammeBtn_1').addEventListener('click', prevdeleteprogramme1BtnChange);
        async function prevdeleteprogramme1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'none';
            document.getElementById('programme').style.display = 'block';
            document.getElementById('crudprogramme').style.display = 'block';
            document.getElementById('addprogramme').style.display = 'none';
            document.getElementById('editprogramme').style.display = 'none';
            document.getElementById('deleteprogramme').style.display = 'none';
            document.getElementById('searchprogramme').style.display = 'none';
            titre.innerHTML = 'Programme des Devoirs'; 
        }
        /***************************
        seldeletetypeprogramme
        *****************************/
        document.getElementById('seldeletetypeprogramme').addEventListener('change', seldeletetypeprogrammeChange);
        async function seldeletetypeprogrammeChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutprogrammeStr = 'Oui';
            var effectueprogrammeStr = 'Non';
            var docsDelete = [];

            document.getElementById('deletedateecheanceprogramme').value = '';

            var programmeIdStr,programmeEcheanceStr = '';

            var deleteselectcalendrierprogrammeStr = document.getElementById('deleteselectcalendrierprogramme').value;
            var deleteselectmatiereprogrammeStr = document.getElementById('deleteselectmatiereprogramme').value;
            var seldeletetypeprogrammeStr = document.getElementById('seldeletetypeprogramme').value;
            if(!seldeletetypeprogrammeStr){
                alert('Sélectionner un type de devoir avant de continuer');
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('cancelcalendrierprogramme').style.display = 'block';
                document.getElementById('cancelmatiereprogramme').style.display = 'block';
                document.getElementById('canceltypeprogramme').style.display = 'block';
                document.getElementById('cancelseldateprogramme').style.display = 'none';
                document.getElementById('canceldateprogramme').style.display = 'none';
                document.getElementById('cancelprogramme').style.display = 'none';
            }else{
                if(!userCodeStr){
                    alert('Connectez-vous à votre Compte avant de continuer');
                }else{
                    try {
                        let result = await db.createIndex({
                            index: {
                                fields: [
                                    'programmestatut',
                                    'programmeeffectue', 
                                    'inscritcode', 
                                    'anneescolaire', 
                                    'matierenom', 
                                    'programmedevoir'
                                ]
                            }
                        });
                        result = await db.find({
                            selector: {
                                programmestatut: statutprogrammeStr,
                                programmeeffectue: effectueprogrammeStr, 
                                inscritcode: userCodeStr,
                                anneescolaire: deleteselectcalendrierprogrammeStr,
                                matierenom: deleteselectmatiereprogrammeStr,
                                programmedevoir: seldeletetypeprogrammeStr
                            }
                        });
                        docsDelete  = result.docs;
                        var resultatDelete = docsDelete.length;
                        if(resultatDelete === 0){
                            alert('Aucun Résultat Disponible');
                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('cancelcalendrierprogramme').style.display = 'block';
                            document.getElementById('cancelmatiereprogramme').style.display = 'block';
                            document.getElementById('canceltypeprogramme').style.display = 'block';
                            document.getElementById('cancelseldateprogramme').style.display = 'none';
                            document.getElementById('canceldateprogramme').style.display = 'none';
                            document.getElementById('cancelprogramme').style.display = 'none';
                        }else{
                            var divClear;
                            var divModifier;
                            divClear = document.getElementById('deleteseldateprogramme');
                            while(divClear.hasChildNodes()) {
                                divClear.removeChild(divClear.childNodes[0]);
                            }
                            divModifier = document.getElementById('deleteseldateprogramme');
                            //Create and append select list
                            var selectList = document.createElement("select");
                            selectList.id = "seldeletedateprogramme";
                            divModifier.appendChild(selectList);
                            //Create and append the options
                            var option_null = document.createElement("option");
                            option_null.value = '';
                            option_null.text = 'Sélectionner Date';
                            selectList.appendChild(option_null);
                            //Create and append the options
                            for (var i = 0; i<docsDelete.length; i++){
                                var option = document.createElement("option");
                                option.value = docsDelete[i]._id;
                                option.text = new Date(docsDelete[i].programmeecheance).toLocaleDateString();
                                selectList.appendChild(option);
                            }

                            //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('cancelcalendrierprogramme').style.display = 'block';
                            document.getElementById('cancelmatiereprogramme').style.display = 'block';
                            document.getElementById('canceltypeprogramme').style.display = 'block';
                            document.getElementById('cancelseldateprogramme').style.display = 'block';
                            document.getElementById('canceldateprogramme').style.display = 'none';
                            document.getElementById('cancelprogramme').style.display = 'none';

                            /***************************
                            seldeletedateprogramme
                            *****************************/
                            document.getElementById('seldeletedateprogramme').addEventListener('change', seldeletedateprogrammeChange);
                            async function seldeletedateprogrammeChange(e){
                                e.preventDefault();

                                var docsDeleteProgramme = [];

                                var seldeletedateprogrammeStr = document.getElementById('seldeletedateprogramme').value;
                                try {
                                    let result = await db.createIndex({
                                        index: {
                                            fields: ['_id']
                                        }
                                    });
                                    result = await db.find({
                                        selector: {
                                            _id: seldeletedateprogrammeStr
                                        }
                                    });
                                    docsDeleteProgramme  = result.docs;
                                    var resultatDeleteProgramme = docsDeleteProgramme.length;
                                    if(resultatDeleteProgramme === 0){
                                        alert("Résultat Indisponible");
                                    }else{
                                        for(let j=0; j<docsDeleteProgramme.length; j++){
                                            programmeIdStr = docsDeleteProgramme[j]._id;
                                            programmeEcheanceStr = docsDeleteProgramme[j].programmeecheance;
                                        }

                                        document.getElementById('deleteidprogramme').value = programmeIdStr;
                                        document.getElementById('deletedateecheanceprogramme').value = programmeEcheanceStr;

                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('cancelcalendrierprogramme').style.display = 'block';
                                        document.getElementById('cancelmatiereprogramme').style.display = 'block';
                                        document.getElementById('canceltypeprogramme').style.display = 'block';
                                        document.getElementById('cancelseldateprogramme').style.display = 'none';
                                        document.getElementById('canceldateprogramme').style.display = 'block';
                                        document.getElementById('cancelprogramme').style.display = 'block';
                                    }
                                } catch (error) {
                                    alert('Erreur Exécution');
                                }
                            }
                        } 
                    } catch (error) {
                        alert('Erreur Exécution');
                    }
                }
            }
        }
        /***************************
        supprimerprogrammeBtn
        *****************************/
        document.getElementById('supprimerprogrammeBtn').addEventListener('click', supprimerprogrammeBtnChange);
        async function supprimerprogrammeBtnChange(e){
            e.preventDefault();

            var divClearModal = document.getElementById('modaloutprogramme');
            let divModal = document.createElement('div');
            divModal.innerHTML =
            `<div class="modal">
                <p>Êtes-vous sûr de Supprimer ?</p>
                <button class="btn btn--alt" id="ouiHandlerProgrammeBtn">Oui</button>
                <button class="btn" id="nonHandlerProgrammeBtn">Non</button>
            </div>`;
            divClearModal.appendChild(divModal);

            var divClearBackdrop = document.getElementById('backdropoutprogramme');
            let divBack = document.createElement('div');
            divBack.innerHTML =
            `<div class="backdrop" id="backdropHandlerProgrammeBtn"></div>`;
            divClearBackdrop.appendChild(divBack);

            document.getElementById('ouiHandlerProgrammeBtn').addEventListener('click', ouiHandlerProgrammeBtnChange);
            async function ouiHandlerProgrammeBtnChange(e){
                e.preventDefault();

                var deleteidprogrammeStr = document.getElementById('deleteidprogramme').value;
                var deleteDateEcheanceProgrammeStr = document.getElementById('deletedateecheanceprogramme').value;
                var noteEffectueStr = 'Oui';
                var noteStatutStr = 'Oui';
                var docsNote = [];
                try {
                    //check if this date already exists in document note_
                    let resultNote = await db.createIndex({
                        index: {
                            fields: [
                                'noteecheance', 
                                'noteeffectue',
                                'notestatut'
                            ]
                        }
                    });
                    resultNote = await db.find({
                        selector: {
                            noteecheance: deleteDateEcheanceProgrammeStr, 
                            noteeffectue: noteEffectueStr,
                            notestatut: noteStatutStr
                        }
                    });
                    docsNote = resultNote.docs;
                    var resultatNote = docsNote.length;
                    if(resultatNote === 0){
                        //Delete
                        var doc = await db.get(deleteidprogrammeStr);
                        var response = await db.remove(doc);
                        if(response.ok){
                            alert('Devoir Programmé Supprimé avec Succès');
                            var divClearModal = document.getElementById('modaloutprogramme');
                            while(divClearModal.hasChildNodes()) {
                                divClearModal.removeChild(divClearModal.childNodes[0]);
                            }

                            var divClearBackdrop = document.getElementById('backdropoutprogramme');
                            while(divClearBackdrop.hasChildNodes()) {
                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                            }
                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');

                            document.getElementById('demarrage').style.display = 'none';
                            document.getElementById('tableaubord').style.display = 'none';
                            document.getElementById('dashboard').style.display = 'none';
                            document.getElementById('configurations').style.display = 'none';
                            document.getElementById('configurer').style.display = 'none';
                            document.getElementById('emploidutemps').style.display = 'none';
                            document.getElementById('exercice').style.display = 'none';
                            document.getElementById('pratique').style.display = 'none';
                            document.getElementById('tache').style.display = 'none';
                            document.getElementById('revision').style.display = 'none';
                            document.getElementById('recherche').style.display = 'none';
                            document.getElementById('programme').style.display = 'block';
                            document.getElementById('crudprogramme').style.display = 'block';
                            document.getElementById('addprogramme').style.display = 'none';
                            document.getElementById('editprogramme').style.display = 'none';
                            document.getElementById('deleteprogramme').style.display = 'none';
                            document.getElementById('searchprogramme').style.display = 'none';
                            titre.innerHTML = 'Programme des Devoirs';
                        }else{
                            alert("Erreur. Echec Suppression");
                            var divClearModal = document.getElementById('modaloutprogramme');
                            while(divClearModal.hasChildNodes()) {
                                divClearModal.removeChild(divClearModal.childNodes[0]);
                            }

                            var divClearBackdrop = document.getElementById('backdropoutprogramme');
                            while(divClearBackdrop.hasChildNodes()) {
                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                            }

                            document.getElementById('titre').style.display = 'block';
                            var titre = document.getElementById('titre');

                            document.getElementById('demarrage').style.display = 'none';
                            document.getElementById('tableaubord').style.display = 'none';
                            document.getElementById('dashboard').style.display = 'none';
                            document.getElementById('configurations').style.display = 'none';
                            document.getElementById('configurer').style.display = 'none';
                            document.getElementById('emploidutemps').style.display = 'none';
                            document.getElementById('exercice').style.display = 'none';
                            document.getElementById('pratique').style.display = 'none';
                            document.getElementById('tache').style.display = 'none';
                            document.getElementById('revision').style.display = 'none';
                            document.getElementById('recherche').style.display = 'none';
                            document.getElementById('programme').style.display = 'block';
                            document.getElementById('crudprogramme').style.display = 'block';
                            document.getElementById('addprogramme').style.display = 'none';
                            document.getElementById('editprogramme').style.display = 'none';
                            document.getElementById('deleteprogramme').style.display = 'none';
                            document.getElementById('searchprogramme').style.display = 'none';
                            titre.innerHTML = 'Programme des Devoirs'; 
                        }
                    }else{
                        alert('Suppression Impossible. Devoir déjà Noté.');
                        var divClearModal = document.getElementById('modaloutprogramme');
                        while(divClearModal.hasChildNodes()) {
                            divClearModal.removeChild(divClearModal.childNodes[0]);
                        }

                        var divClearBackdrop = document.getElementById('backdropoutprogramme');
                        while(divClearBackdrop.hasChildNodes()) {
                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');

                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'none';
                        document.getElementById('programme').style.display = 'block';
                        document.getElementById('crudprogramme').style.display = 'block';
                        document.getElementById('addprogramme').style.display = 'none';
                        document.getElementById('editprogramme').style.display = 'none';
                        document.getElementById('deleteprogramme').style.display = 'none';
                        document.getElementById('searchprogramme').style.display = 'none';
                        titre.innerHTML = 'Programme des Devoirs'; 
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
            document.getElementById('nonHandlerProgrammeBtn').addEventListener('click', nonHandlerProgrammeBtnChange);
            async function nonHandlerProgrammeBtnChange(e){
                e.preventDefault();

                var divClearModal = document.getElementById('modaloutprogramme');
                while(divClearModal.hasChildNodes()) {
                    divClearModal.removeChild(divClearModal.childNodes[0]);
                }

                var divClearBackdrop = document.getElementById('backdropoutprogramme');
                while(divClearBackdrop.hasChildNodes()) {
                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                }
            }
            document.getElementById('backdropHandlerProgrammeBtn').addEventListener('click', backdropHandlerProgrammeBtnChange);
            async function backdropHandlerProgrammeBtnChange(e){
                e.preventDefault();

                var divClearModal = document.getElementById('modaloutprogramme');
                while(divClearModal.hasChildNodes()) {
                    divClearModal.removeChild(divClearModal.childNodes[0]);
                }

                var divClearBackdrop = document.getElementById('backdropoutprogramme');
                while(divClearBackdrop.hasChildNodes()) {
                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                }
            }
        }

        /***************************
        searchprogrammeBtn
        *****************************/
        document.getElementById('searchprogrammeBtn').addEventListener('click', searchprogrammeBtnChange);
        async function searchprogrammeBtnChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var statutanneeStr = 'Oui';
            var statutmatiereStr = 'Oui';

            var docsAnnee = [];
            var docsMatiere = [];

            if(!userCodeStr){
                alert('Connectez-vous à votre Compte avant de continuer');
            }else{
                try {
                    let result = await db.createIndex({
                        index: {
                            fields: ['anneestatut', 'inscritcode']
                        }
                    });
                    result = await db.find({
                        selector: {
                            anneestatut: statutanneeStr,
                            inscritcode: userCodeStr
                        }
                    });
                    docsAnnee  = result.docs;
                    var resultatAnnee = docsAnnee.length;
                    if(resultatAnnee === 0){
                        alert('Aucun Calendrier Année Scolaire Enregistré');
                    }else{
                        var divClear;
                        var divModifier;
                        divClear = document.getElementById('searchcalendrierprogramme');
                        while(divClear.hasChildNodes()) {
                            divClear.removeChild(divClear.childNodes[0]);
                        }
                        divModifier = document.getElementById('searchcalendrierprogramme');
                        //Create and append select list
                        var selectList = document.createElement("select");
                        selectList.id = "selsearchcalendrierprogramme";
                        divModifier.appendChild(selectList);
                        //Create and append the options
                        var option_null = document.createElement("option");
                        option_null.value = '';
                        option_null.text = 'Sélectionner Calendrier';
                        selectList.appendChild(option_null);
                        //Create and append the options
                        for (var i = 0; i<docsAnnee.length; i++){
                            var option = document.createElement("option");
                            option.value = docsAnnee[i].anneescolaire;
                            option.text = docsAnnee[i].anneescolaire;
                            selectList.appendChild(option);
                        }

                        document.getElementById('titre').style.display = 'block';
                        var titre = document.getElementById('titre');
    
                        document.getElementById('demarrage').style.display = 'none';
                        document.getElementById('tableaubord').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'none';
                        document.getElementById('configurations').style.display = 'none';
                        document.getElementById('configurer').style.display = 'none';
                        document.getElementById('emploidutemps').style.display = 'none';
                        document.getElementById('exercice').style.display = 'none';
                        document.getElementById('pratique').style.display = 'none';
                        document.getElementById('tache').style.display = 'none';
                        document.getElementById('revision').style.display = 'none';
                        document.getElementById('recherche').style.display = 'none';
                        document.getElementById('programme').style.display = 'block';
                        document.getElementById('crudprogramme').style.display = 'none';
                        document.getElementById('addprogramme').style.display = 'none';
                        document.getElementById('editprogramme').style.display = 'none';
                        document.getElementById('deleteprogramme').style.display = 'none';
                        document.getElementById('searchprogramme').style.display = 'block';
                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                        document.getElementById('viewcalendrierprogramme').style.display = 'block';
                        document.getElementById('viewmatiereprogramme').style.display = 'none';
                        document.getElementById('viewtypeprogramme').style.display = 'none';
                        document.getElementById('vieweffectueprogramme').style.display = 'none';
                        document.getElementById('viewprogramme').style.display = 'none';
                        document.getElementById('viewconsulterprogramme').style.display = 'none';
                        titre.innerHTML = 'Consulter Programme Devoir';

                        /***************************
                        selsearchcalendrierprogramme
                        *****************************/
                        document.getElementById('selsearchcalendrierprogramme').addEventListener('change', selsearchcalendrierprogrammeChange);
                        async function selsearchcalendrierprogrammeChange(e){
                            e.preventDefault();

                            var selsearchcalendrierprogrammeStr = document.getElementById('selsearchcalendrierprogramme').value;
                            if(!selsearchcalendrierprogrammeStr){
                                alert('Sélectionner un calendrier scolaire avant de continuer');
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendrierprogramme').style.display = 'block';
                                document.getElementById('viewmatiereprogramme').style.display = 'none';
                                document.getElementById('viewtypeprogramme').style.display = 'none';
                                document.getElementById('vieweffectueprogramme').style.display = 'none';
                                document.getElementById('viewprogramme').style.display = 'none';
                                document.getElementById('viewconsulterprogramme').style.display = 'none';
                            }else{
                                document.getElementById('searchselectcalendrierprogramme').value = selsearchcalendrierprogrammeStr;
                                try {
                                    let result = await db.createIndex({
                                        index: {
                                            fields: ['matierestatut', 'inscritcode']
                                        }
                                    });
                                    result = await db.find({
                                        selector: {
                                            matierestatut: statutmatiereStr,
                                            inscritcode: userCodeStr
                                        }
                                    });
                                    docsMatiere  = result.docs;
                                    var resultatMatiere = docsMatiere.length;
                                    if(resultatMatiere === 0){
                                        alert("Matières Classe non encore enregistrées");
                                    }else{
                                        var divClear;
                                        var divModifier;
                                        divClear = document.getElementById('searchmatiereprogramme');
                                        while(divClear.hasChildNodes()) {
                                            divClear.removeChild(divClear.childNodes[0]);
                                        }
                                        divModifier = document.getElementById('searchmatiereprogramme');
                                        //Create and append select list
                                        var selectList = document.createElement("select");
                                        selectList.id = "selsearchmatiereprogramme";
                                        divModifier.appendChild(selectList);
                                        //Create and append the options
                                        var option_null = document.createElement("option");
                                        option_null.value = '';
                                        option_null.text = 'Sélectionner Matière';
                                        selectList.appendChild(option_null);
                                        //Create and append the options
                                        for (var i = 0; i<docsMatiere.length; i++){
                                            var option = document.createElement("option");
                                            option.value = docsMatiere[i].matierenom;
                                            option.text = docsMatiere[i].matierenom;
                                            selectList.appendChild(option);
                                        }

                                        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        document.getElementById('viewcalendrierprogramme').style.display = 'block';
                                        document.getElementById('viewmatiereprogramme').style.display = 'block';
                                        document.getElementById('viewtypeprogramme').style.display = 'none';
                                        document.getElementById('vieweffectueprogramme').style.display = 'none';
                                        document.getElementById('viewprogramme').style.display = 'none';
                                        document.getElementById('viewconsulterprogramme').style.display = 'none';

                                        /***************************
                                        selsearchmatiereprogramme
                                        *****************************/
                                        document.getElementById('selsearchmatiereprogramme').addEventListener('change', selsearchmatiereprogrammeChange);
                                        async function selsearchmatiereprogrammeChange(e){
                                            e.preventDefault();

                                            document.getElementById('selsearchtypeprogramme').value = '';

                                            var selsearchmatiereprogrammeStr = document.getElementById('selsearchmatiereprogramme').value;
                                            if(!selsearchmatiereprogrammeStr){
                                                alert('Sélectionner une matière avant de continuer');
                                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                                document.getElementById('viewcalendrierprogramme').style.display = 'block';
                                                document.getElementById('viewmatiereprogramme').style.display = 'block';
                                                document.getElementById('viewtypeprogramme').style.display = 'none';
                                                document.getElementById('vieweffectueprogramme').style.display = 'none';
                                                document.getElementById('viewprogramme').style.display = 'none';
                                                document.getElementById('viewconsulterprogramme').style.display = 'none';
                                            }else{
                                                document.getElementById('searchselectmatiereprogramme').value = selsearchmatiereprogrammeStr;
                                                document.getElementById('viewsearchmatiereprogramme').innerText = selsearchcalendrierprogrammeStr+' '+selsearchmatiereprogrammeStr;
                                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                                document.getElementById('viewcalendrierprogramme').style.display = 'block';
                                                document.getElementById('viewmatiereprogramme').style.display = 'block';
                                                document.getElementById('viewtypeprogramme').style.display = 'block';
                                                document.getElementById('vieweffectueprogramme').style.display = 'none';
                                                document.getElementById('viewprogramme').style.display = 'none';
                                                document.getElementById('viewconsulterprogramme').style.display = 'none';
                                            }
                                        }
                                    }
                                } catch (error) {
                                    alert('Erreur Exécution Matières');
                                }
                            }
                        }
                    }
                } catch (error) {
                    alert('Erreur Exécution');
                }
            }
        }
        /**nav previous page button*/
        document.getElementById('prevsearchprogrammeBtn_1').addEventListener('click', prevsearchprogramme1BtnChange);
        async function prevsearchprogramme1BtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'none';
            document.getElementById('programme').style.display = 'block';
            document.getElementById('crudprogramme').style.display = 'block';
            document.getElementById('addprogramme').style.display = 'none';
            document.getElementById('editprogramme').style.display = 'none';
            document.getElementById('deleteprogramme').style.display = 'none';
            document.getElementById('searchprogramme').style.display = 'none';
            titre.innerHTML = 'Programme des Devoirs'; 
        }
        /***************************
        selsearchtypeprogramme
        *****************************/
        document.getElementById('selsearchtypeprogramme').addEventListener('change', selsearchtypeprogrammeChange);
        async function selsearchtypeprogrammeChange(e){
            e.preventDefault();

            document.getElementById('searcheffectueprogramme').value = '';

            var selsearchtypeprogrammeStr = document.getElementById('selsearchtypeprogramme').value;
            if(!selsearchtypeprogrammeStr){
                alert('Sélectionner Type Devoir avant de continuer');
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('viewcalendrierprogramme').style.display = 'block';
                document.getElementById('viewmatiereprogramme').style.display = 'block';
                document.getElementById('viewtypeprogramme').style.display = 'block';
                document.getElementById('vieweffectueprogramme').style.display = 'none';
                document.getElementById('viewprogramme').style.display = 'none';
                document.getElementById('viewconsulterprogramme').style.display = 'none';
            }else{
                document.getElementById('searchselecttypeprogramme').value = selsearchtypeprogrammeStr;
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('viewcalendrierprogramme').style.display = 'block';
                document.getElementById('viewmatiereprogramme').style.display = 'block';
                document.getElementById('viewtypeprogramme').style.display = 'block';
                document.getElementById('vieweffectueprogramme').style.display = 'block';
                document.getElementById('viewprogramme').style.display = 'none';
                document.getElementById('viewconsulterprogramme').style.display = 'none';
            }
        }
        /***************************
        searcheffectueprogramme
        *****************************/
        document.getElementById('searcheffectueprogramme').addEventListener('change', searcheffectueprogrammeChange);
        async function searcheffectueprogrammeChange(e){
            e.preventDefault();

            var userCodeStr = document.getElementById('userCode').innerText;
            var searchselectcalendrierprogrammeStr = document.getElementById('searchselectcalendrierprogramme').value;
            var searchselectmatiereprogrammeStr = document.getElementById('searchselectmatiereprogramme').value;
            var searchselecttypeprogrammeStr = document.getElementById('searchselecttypeprogramme').value;
            var searcheffectueprogrammeStr = document.getElementById('searcheffectueprogramme').value;
            var typeDevoirStr = 'Devoir';
            var typeInterrogationStr = 'Interrogation';
            var effectOuiStr = 'Oui';
            var effectNonStr = 'Non';
            var statutProgrammeStr = 'Oui';
            var docsSearchDevNon = [];
            var docsSearchDevOui = [];
            var docsSearchInterroNon = [];
            var docsSearchInterroOui = [];
            var docsSearchTousNon = [];
            var docsSearchTousOui = [];
            var docsSearchDevTous = [];
            var docsSearchDevTout = [];
            var docsSearchInterroTout = [];

            if(!searcheffectueprogrammeStr){
                alert('Sélectionner un type de Devoir Effectué avant de continuer');
                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                document.getElementById('viewcalendrierprogramme').style.display = 'block';
                document.getElementById('viewmatiereprogramme').style.display = 'block';
                document.getElementById('viewtypeprogramme').style.display = 'block';
                document.getElementById('vieweffectueprogramme').style.display = 'block';
                document.getElementById('viewprogramme').style.display = 'none';
                document.getElementById('viewconsulterprogramme').style.display = 'none';
            }else{
                if(searchselecttypeprogrammeStr === 'Devoir'){

                    if(searcheffectueprogrammeStr === 'Oui'){
                        try {
                            let resultDevOui = await db.createIndex({
                                index: {
                                    fields: [
                                        'anneescolaire',
                                        'matierenom',
                                        'programmedevoir',
                                        'programmeeffectue',
                                        'programmestatut',
                                        'inscritcode'
                                    ]
                                }
                            });
                            resultDevOui = await db.find({
                                selector: {
                                    anneescolaire: searchselectcalendrierprogrammeStr,
                                    matierenom: searchselectmatiereprogrammeStr,
                                    programmedevoir: typeDevoirStr,
                                    programmeeffectue: effectOuiStr,
                                    programmestatut: statutProgrammeStr,
                                    inscritcode: userCodeStr
                                }
                            });
                            docsSearchDevOui = resultDevOui.docs;
                            var resultatSearchDevOui = docsSearchDevOui.length;
                            if(resultatSearchDevOui === 0){
                                alert('Aucun Résultat Disponible');
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendrierprogramme').style.display = 'block';
                                document.getElementById('viewmatiereprogramme').style.display = 'block';
                                document.getElementById('viewtypeprogramme').style.display = 'block';
                                document.getElementById('vieweffectueprogramme').style.display = 'block';
                                document.getElementById('viewprogramme').style.display = 'none';
                                document.getElementById('viewconsulterprogramme').style.display = 'none';
                            }else{
                                updateSearchProgrammeUIWithDocs(docsSearchDevOui);
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendrierprogramme').style.display = 'block';
                                document.getElementById('viewmatiereprogramme').style.display = 'block';
                                document.getElementById('viewtypeprogramme').style.display = 'block';
                                document.getElementById('vieweffectueprogramme').style.display = 'block';
                                document.getElementById('viewprogramme').style.display = 'block';
                                document.getElementById('viewconsulterprogramme').style.display = 'block';
                            }
                        } catch (error) {
                            alert('Erreur Exécution');
                        }
                    }else if(searcheffectueprogrammeStr === 'Non'){
                        try {
                            let resultDevNon = await db.createIndex({
                                index: {
                                    fields: [
                                        'anneescolaire',
                                        'matierenom',
                                        'programmedevoir',
                                        'programmeeffectue',
                                        'programmestatut',
                                        'inscritcode'
                                    ]
                                }
                            });
                            resultDevNon = await db.find({
                                selector: {
                                    anneescolaire: searchselectcalendrierprogrammeStr,
                                    matierenom: searchselectmatiereprogrammeStr,
                                    programmedevoir: typeDevoirStr,
                                    programmeeffectue: effectNonStr,
                                    programmestatut: statutProgrammeStr,
                                    inscritcode: userCodeStr
                                }
                            });
                            docsSearchDevNon = resultDevNon.docs;
                            var resultatSearchDevNon = docsSearchDevNon.length;
                            if(resultatSearchDevNon === 0){
                                alert('Aucun Résultat Disponible');
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendrierprogramme').style.display = 'block';
                                document.getElementById('viewmatiereprogramme').style.display = 'block';
                                document.getElementById('viewtypeprogramme').style.display = 'block';
                                document.getElementById('vieweffectueprogramme').style.display = 'block';
                                document.getElementById('viewprogramme').style.display = 'none';
                                document.getElementById('viewconsulterprogramme').style.display = 'none';
                            }else{
                                updateSearchProgrammeUIWithDocs(docsSearchDevNon);
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendrierprogramme').style.display = 'block';
                                document.getElementById('viewmatiereprogramme').style.display = 'block';
                                document.getElementById('viewtypeprogramme').style.display = 'block';
                                document.getElementById('vieweffectueprogramme').style.display = 'block';
                                document.getElementById('viewprogramme').style.display = 'block';
                                document.getElementById('viewconsulterprogramme').style.display = 'block';
                            }
                        } catch (error) {
                            alert('Erreur Exécution');
                        }
                    }else if(searcheffectueprogrammeStr === 'Tout'){
                        try {
                            let resultDevTout = await db.createIndex({
                                index: {
                                    fields: [
                                        'anneescolaire',
                                        'matierenom',
                                        'programmedevoir',
                                        'programmestatut',
                                        'inscritcode'
                                    ]
                                }
                            });
                            resultDevTout = await db.find({
                                selector: {
                                    anneescolaire: searchselectcalendrierprogrammeStr,
                                    matierenom: searchselectmatiereprogrammeStr,
                                    programmedevoir: typeDevoirStr,
                                    programmestatut: statutProgrammeStr,
                                    inscritcode: userCodeStr
                                }
                            });
                            docsSearchDevTout = resultDevTout.docs;
                            var resultatSearchDevTout = docsSearchDevTout.length;
                            if(resultatSearchDevTout === 0){
                                alert('Aucun Résultat Disponible');
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendrierprogramme').style.display = 'block';
                                document.getElementById('viewmatiereprogramme').style.display = 'block';
                                document.getElementById('viewtypeprogramme').style.display = 'block';
                                document.getElementById('vieweffectueprogramme').style.display = 'block';
                                document.getElementById('viewprogramme').style.display = 'none';
                                document.getElementById('viewconsulterprogramme').style.display = 'none';
                            }else{
                                updateSearchProgrammeUIWithDocs(docsSearchDevTout);
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendrierprogramme').style.display = 'block';
                                document.getElementById('viewmatiereprogramme').style.display = 'block';
                                document.getElementById('viewtypeprogramme').style.display = 'block';
                                document.getElementById('vieweffectueprogramme').style.display = 'block';
                                document.getElementById('viewprogramme').style.display = 'block';
                                document.getElementById('viewconsulterprogramme').style.display = 'block';
                            }
                        } catch (error) {
                            alert('Erreur Exécution');
                        }
                    }

                }else if(searchselecttypeprogrammeStr === 'Interrogation'){

                    if(searcheffectueprogrammeStr === 'Oui'){
                        try {
                            let resultInterroOui = await db.createIndex({
                                index: {
                                    fields: [
                                        'anneescolaire',
                                        'matierenom',
                                        'programmedevoir',
                                        'programmeeffectue',
                                        'programmestatut',
                                        'inscritcode'
                                    ]
                                }
                            });
                            resultInterroOui = await db.find({
                                selector: {
                                    anneescolaire: searchselectcalendrierprogrammeStr,
                                    matierenom: searchselectmatiereprogrammeStr,
                                    programmedevoir: typeInterrogationStr,
                                    programmeeffectue: effectOuiStr,
                                    programmestatut: statutProgrammeStr,
                                    inscritcode: userCodeStr
                                }
                            });
                            docsSearchInterroOui = resultInterroOui.docs;
                            var resultatSearchInterroOui = docsSearchInterroOui.length;
                            if(resultatSearchInterroOui === 0){
                                alert('Aucun Résultat Disponible');
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendrierprogramme').style.display = 'block';
                                document.getElementById('viewmatiereprogramme').style.display = 'block';
                                document.getElementById('viewtypeprogramme').style.display = 'block';
                                document.getElementById('vieweffectueprogramme').style.display = 'block';
                                document.getElementById('viewprogramme').style.display = 'none';
                                document.getElementById('viewconsulterprogramme').style.display = 'none';
                            }else{
                                updateSearchProgrammeUIWithDocs(docsSearchInterroOui);
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendrierprogramme').style.display = 'block';
                                document.getElementById('viewmatiereprogramme').style.display = 'block';
                                document.getElementById('viewtypeprogramme').style.display = 'block';
                                document.getElementById('vieweffectueprogramme').style.display = 'block';
                                document.getElementById('viewprogramme').style.display = 'block';
                                document.getElementById('viewconsulterprogramme').style.display = 'block';
                            }
                        } catch (error) {
                            alert('Erreur Exécution');
                        }
                    }else if(searcheffectueprogrammeStr === 'Non'){
                        try {
                            let resultInterroNon = await db.createIndex({
                                index: {
                                    fields: [
                                        'anneescolaire',
                                        'matierenom',
                                        'programmedevoir',
                                        'programmeeffectue',
                                        'programmestatut',
                                        'inscritcode'
                                    ]
                                }
                            });
                            resultInterroNon = await db.find({
                                selector: {
                                    anneescolaire: searchselectcalendrierprogrammeStr,
                                    matierenom: searchselectmatiereprogrammeStr,
                                    programmedevoir: typeInterrogationStr,
                                    programmeeffectue: effectNonStr,
                                    programmestatut: statutProgrammeStr,
                                    inscritcode: userCodeStr
                                }
                            });
                            docsSearchInterroNon = resultInterroNon.docs;
                            var resultatSearchInterroNon = docsSearchInterroNon.length;
                            if(resultatSearchInterroNon === 0){
                                alert('Aucun Résultat Disponible');
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendrierprogramme').style.display = 'block';
                                document.getElementById('viewmatiereprogramme').style.display = 'block';
                                document.getElementById('viewtypeprogramme').style.display = 'block';
                                document.getElementById('vieweffectueprogramme').style.display = 'block';
                                document.getElementById('viewprogramme').style.display = 'none';
                                document.getElementById('viewconsulterprogramme').style.display = 'none';
                            }else{
                                updateSearchProgrammeUIWithDocs(docsSearchInterroNon);
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendrierprogramme').style.display = 'block';
                                document.getElementById('viewmatiereprogramme').style.display = 'block';
                                document.getElementById('viewtypeprogramme').style.display = 'block';
                                document.getElementById('vieweffectueprogramme').style.display = 'block';
                                document.getElementById('viewprogramme').style.display = 'block';
                                document.getElementById('viewconsulterprogramme').style.display = 'block';
                            }
                        } catch (error) {
                            alert('Erreur Exécution');
                        }
                    }else if(searcheffectueprogrammeStr === 'Tout'){
                        try {
                            let resultInterroTout = await db.createIndex({
                                index: {
                                    fields: [
                                        'anneescolaire',
                                        'matierenom',
                                        'programmedevoir',
                                        'programmestatut',
                                        'inscritcode'
                                    ]
                                }
                            });
                            resultInterroTout = await db.find({
                                selector: {
                                    anneescolaire: searchselectcalendrierprogrammeStr,
                                    matierenom: searchselectmatiereprogrammeStr,
                                    programmedevoir: typeInterrogationStr,
                                    programmestatut: statutProgrammeStr,
                                    inscritcode: userCodeStr
                                }
                            });
                            docsSearchInterroTout = resultInterroTout.docs;
                            var resultatSearchInterroTout = docsSearchInterroTout.length;
                            if(resultatSearchInterroTout === 0){
                                alert('Aucun Résultat Disponible');
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendrierprogramme').style.display = 'block';
                                document.getElementById('viewmatiereprogramme').style.display = 'block';
                                document.getElementById('viewtypeprogramme').style.display = 'block';
                                document.getElementById('vieweffectueprogramme').style.display = 'block';
                                document.getElementById('viewprogramme').style.display = 'none';
                                document.getElementById('viewconsulterprogramme').style.display = 'none';
                            }else{
                                updateSearchProgrammeUIWithDocs(docsSearchInterroTout);
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendrierprogramme').style.display = 'block';
                                document.getElementById('viewmatiereprogramme').style.display = 'block';
                                document.getElementById('viewtypeprogramme').style.display = 'block';
                                document.getElementById('vieweffectueprogramme').style.display = 'block';
                                document.getElementById('viewprogramme').style.display = 'block';
                                document.getElementById('viewconsulterprogramme').style.display = 'block';
                            }
                        } catch (error) {
                            alert('Erreur Exécution');
                        }
                    }

                }else if(searchselecttypeprogrammeStr === 'Tous'){

                    if(searcheffectueprogrammeStr === 'Oui'){
                        try {
                            let resultDevTousOui = await db.createIndex({
                                index: {
                                    fields: [
                                        'anneescolaire',
                                        'matierenom',
                                        'programmeeffectue',
                                        'programmestatut',
                                        'inscritcode'
                                    ]
                                }
                            });
                            resultDevTousOui = await db.find({
                                selector: {
                                    anneescolaire: searchselectcalendrierprogrammeStr,
                                    matierenom: searchselectmatiereprogrammeStr,
                                    programmeeffectue: effectOuiStr,
                                    programmestatut: statutProgrammeStr,
                                    inscritcode: userCodeStr
                                }
                            });
                            docsSearchTousOui = resultDevTousOui.docs;
                            var resultatSearchTousOui = docsSearchTousOui.length;
                            if(resultatSearchTousOui === 0){
                                alert('Aucun Résultat Disponible');
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendrierprogramme').style.display = 'block';
                                document.getElementById('viewmatiereprogramme').style.display = 'block';
                                document.getElementById('viewtypeprogramme').style.display = 'block';
                                document.getElementById('vieweffectueprogramme').style.display = 'block';
                                document.getElementById('viewprogramme').style.display = 'none';
                                document.getElementById('viewconsulterprogramme').style.display = 'none';
                            }else{
                                updateSearchProgrammeUIWithDocs(docsSearchTousOui);
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendrierprogramme').style.display = 'block';
                                document.getElementById('viewmatiereprogramme').style.display = 'block';
                                document.getElementById('viewtypeprogramme').style.display = 'block';
                                document.getElementById('vieweffectueprogramme').style.display = 'block';
                                document.getElementById('viewprogramme').style.display = 'block';
                                document.getElementById('viewconsulterprogramme').style.display = 'block';
                            }
                        } catch (error) {
                            alert('Erreur Exécution');
                        }
                    }else if(searcheffectueprogrammeStr === 'Non'){
                        try {
                            let resultDevTousNon = await db.createIndex({
                                index: {
                                    fields: [
                                        'anneescolaire',
                                        'matierenom',
                                        'programmeeffectue',
                                        'programmestatut',
                                        'inscritcode'
                                    ]
                                }
                            });
                            resultDevTousNon = await db.find({
                                selector: {
                                    anneescolaire: searchselectcalendrierprogrammeStr,
                                    matierenom: searchselectmatiereprogrammeStr,
                                    programmeeffectue: effectNonStr,
                                    programmestatut: statutProgrammeStr,
                                    inscritcode: userCodeStr
                                }
                            });
                            docsSearchTousNon = resultDevTousNon.docs;
                            var resultatSearchTousNon = docsSearchTousNon.length;
                            if(resultatSearchTousNon === 0){
                                alert('Aucun Résultat Disponible');
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendrierprogramme').style.display = 'block';
                                document.getElementById('viewmatiereprogramme').style.display = 'block';
                                document.getElementById('viewtypeprogramme').style.display = 'block';
                                document.getElementById('vieweffectueprogramme').style.display = 'block';
                                document.getElementById('viewprogramme').style.display = 'none';
                                document.getElementById('viewconsulterprogramme').style.display = 'none';
                            }else{
                                updateSearchProgrammeUIWithDocs(docsSearchTousNon);
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendrierprogramme').style.display = 'block';
                                document.getElementById('viewmatiereprogramme').style.display = 'block';
                                document.getElementById('viewtypeprogramme').style.display = 'block';
                                document.getElementById('vieweffectueprogramme').style.display = 'block';
                                document.getElementById('viewprogramme').style.display = 'block';
                                document.getElementById('viewconsulterprogramme').style.display = 'block';
                            }
                        } catch (error) {
                            alert('Erreur Exécution');
                        }
                    }else if(searcheffectueprogrammeStr === 'Tout'){
                        try {
                            let resultDevTous = await db.createIndex({
                                index: {
                                    fields: [
                                        'anneescolaire',
                                        'matierenom',
                                        'programmestatut',
                                        'inscritcode'
                                    ]
                                }
                            });
                            resultDevTous = await db.find({
                                selector: {
                                    anneescolaire: searchselectcalendrierprogrammeStr,
                                    matierenom: searchselectmatiereprogrammeStr,
                                    programmestatut: statutProgrammeStr,
                                    inscritcode: userCodeStr
                                }
                            });
                            docsSearchDevTous = resultDevTous.docs;
                            var resultatSearchDevTous = docsSearchDevTous.length;
                            if(resultatSearchDevTous === 0){
                                alert('Aucun Résultat Disponible');
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendrierprogramme').style.display = 'block';
                                document.getElementById('viewmatiereprogramme').style.display = 'block';
                                document.getElementById('viewtypeprogramme').style.display = 'block';
                                document.getElementById('vieweffectueprogramme').style.display = 'block';
                                document.getElementById('viewprogramme').style.display = 'none';
                                document.getElementById('viewconsulterprogramme').style.display = 'none';
                            }else{
                                updateSearchProgrammeUIWithDocs(docsSearchDevTous);
                                //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                document.getElementById('viewcalendrierprogramme').style.display = 'block';
                                document.getElementById('viewmatiereprogramme').style.display = 'block';
                                document.getElementById('viewtypeprogramme').style.display = 'block';
                                document.getElementById('vieweffectueprogramme').style.display = 'block';
                                document.getElementById('viewprogramme').style.display = 'block';
                                document.getElementById('viewconsulterprogramme').style.display = 'block';
                            }
                        } catch (error) {
                            alert('Erreur Exécution');
                        }
                    }
                }
            }
        }
        // Adds the document to the HTML First table.
        // If the document already exists in the table, it will be updated.
        function viewSearchProgrammeTableDocToTable(doc, viewSearchProgrammeTableBody){
            // Each row of the table has an id attribute set to the doc._id field.
            var tr = viewSearchProgrammeTableBody.querySelector(`#${doc._id}`);

            if( !tr ) {
                tr = document.createElement('tr');
                tr.id = doc._id;
            }
        
            // Remove any existing table cells.
            while(tr.hasChildNodes()) {
                tr.removeChild(tr.childNodes[0]);
            }

            // Create new table cells for text fields.
            var tdEcheance = document.createElement('td');
            var dateEcheance = new Date(doc.programmeecheance);
            tdEcheance.innerText = dateEcheance.toLocaleDateString();
            tr.appendChild(tdEcheance);

            // Create new table cells for text fields.
            var tdType = document.createElement('td');
            tdType.innerText = doc.programmedevoir;
            tr.appendChild(tdType);

            // Create new table cells for text fields.
            var tdAction = document.createElement('td');
            //tdAction.innerText = doc.programmeeffectue;
            var effectue = doc.programmeeffectue;
            var idProgramme = doc._id;
            if(effectue === 'Non'){
                var button = document.createElement("button");
                button.id = "ouiprogrammeBtn";
                button.type = 'button';
                button.innerHTML = 'Effectué';
                button.onclick = async function(){
                    var inscritCodeStr,programmeScolaireStr,programmeMatiereStr,programmeDevoirStr,
                    programmeEcheanceStr,programmeDateStr,programmeStatutStr = '';
                    var programmeEffectueStr = 'Oui';
                    var docsProgramme = [];
                    try {
                        let result = await db.createIndex({
                            index: {
                                fields: ['_id']
                            }
                        });
                        result = await db.find({
                            selector: {
                                _id: idProgramme
                            }
                        });
                        docsProgramme = result.docs;
                        var resultatProgramme = docsProgramme.length;
                        if(resultatProgramme === 0){
                            alert("Devoir n'existe pas");
                        }else{
                            for(let i=0; i<docsProgramme.length; i++){
                                inscritCodeStr = docsProgramme[i].inscritcode;
                                programmeScolaireStr = docsProgramme[i].anneescolaire;
                                programmeMatiereStr = docsProgramme[i].matierenom;
                                programmeDevoirStr = docsProgramme[i].programmedevoir;
                                programmeEcheanceStr = docsProgramme[i].programmeecheance;
                                programmeDateStr = docsProgramme[i].programmedate;
                                programmeStatutStr = docsProgramme[i].programmestatut;
                            }

                            // Retrieve the document to get the latest revision
                            var id = idProgramme;
                            var docModifier = await db.get(id);
                            // Make changes
                            docModifier.inscritcode = inscritCodeStr;
                            docModifier.anneescolaire = programmeScolaireStr;
                            docModifier.matierenom = programmeMatiereStr;
                            docModifier.programmedevoir = programmeDevoirStr;
                            docModifier.programmeecheance = programmeEcheanceStr;
                            docModifier.programmeeffectue = programmeEffectueStr;
                            docModifier.programmedate = programmeDateStr;
                            docModifier.programmestatut = programmeStatutStr;
                            // Put the document, saving it as a new revision
                            var response = await db.put(docModifier);
                            // Get the document we change and update the UI.
                            var docEdit = await db.get(response.id);
                            if(docEdit !== ''){
                                alert("Devoir Effectué Confirmé");
                                document.getElementById('titre').style.display = 'block';
                                var titre = document.getElementById('titre');

                                document.getElementById('demarrage').style.display = 'none';
                                document.getElementById('tableaubord').style.display = 'none';
                                document.getElementById('dashboard').style.display = 'none';
                                document.getElementById('configurations').style.display = 'none';
                                document.getElementById('configurer').style.display = 'none';
                                document.getElementById('emploidutemps').style.display = 'none';
                                document.getElementById('exercice').style.display = 'none';
                                document.getElementById('pratique').style.display = 'none';
                                document.getElementById('tache').style.display = 'none';
                                document.getElementById('revision').style.display = 'none';
                                document.getElementById('recherche').style.display = 'none';
                                document.getElementById('programme').style.display = 'block';
                                document.getElementById('crudprogramme').style.display = 'block';
                                document.getElementById('addprogramme').style.display = 'none';
                                document.getElementById('editprogramme').style.display = 'none';
                                document.getElementById('deleteprogramme').style.display = 'none';
                                document.getElementById('searchprogramme').style.display = 'none';
                                titre.innerHTML = 'Programme des Devoirs'; 
                            }else{
                                alert("Echec Confirmation Devoir Effectué");
                                document.getElementById('titre').style.display = 'block';
                                var titre = document.getElementById('titre');

                                document.getElementById('demarrage').style.display = 'none';
                                document.getElementById('tableaubord').style.display = 'none';
                                document.getElementById('dashboard').style.display = 'none';
                                document.getElementById('configurations').style.display = 'none';
                                document.getElementById('configurer').style.display = 'none';
                                document.getElementById('emploidutemps').style.display = 'none';
                                document.getElementById('exercice').style.display = 'none';
                                document.getElementById('pratique').style.display = 'none';
                                document.getElementById('tache').style.display = 'none';
                                document.getElementById('revision').style.display = 'none';
                                document.getElementById('recherche').style.display = 'none';
                                document.getElementById('programme').style.display = 'block';
                                document.getElementById('crudprogramme').style.display = 'block';
                                document.getElementById('addprogramme').style.display = 'none';
                                document.getElementById('editprogramme').style.display = 'none';
                                document.getElementById('deleteprogramme').style.display = 'none';
                                document.getElementById('searchprogramme').style.display = 'none';
                                titre.innerHTML = 'Programme des Devoirs';
                            }
                        }
                    } catch (error) {
                        alert('Erreur Exécution');
                    }
                }
            }else{
                var button = document.createElement("button");
                button.id = "nonprogrammeBtn";
                button.type = 'button';
                button.innerHTML = 'Supprimer';
                button.onclick = async function(){
                    //check if note obtenu of this devoir has been inserted
                    var userCodeStr = document.getElementById('userCode').innerText;
                    var noteStatutStr = 'Oui';
                    var docsNotes = [];
                    try {
                        let result = await db.createIndex({
                            index: {
                                fields: ['notestatut', 'inscritcode', 'notereference']
                            }
                        });
                        result = await db.find({
                            selector: {
                                notestatut: noteStatutStr,
                                inscritcode: userCodeStr,
                                notereference: idProgramme
                            }
                        });
                        docsNotes  = result.docs;
                        var resultatNotes = docsNotes.length;
                        if(resultatNotes === 0){
                            alert("Enregistrer Note Obtenue du Devoir Avant de Supprimer");
                        }else{
                            var divClearModal = document.getElementById('modaloutsearchprogramme');
                            let divModal = document.createElement('div');
                            divModal.innerHTML =
                            `<div class="modal">
                            <p>Êtes-vous sûr de Supprimer Ce Devoir ?</p>
                            <button class="btn btn--alt" id="ouiHandlerSearchProgrammeBtn">Oui</button>
                            <button class="btn" id="nonHandlerSearchProgrammeBtn">Non</button>
                            </div>`;
                            divClearModal.appendChild(divModal);

                            var divClearBackdrop = document.getElementById('backdropoutsearchprogramme');
                            let divBack = document.createElement('div');
                            divBack.innerHTML =
                            `<div class="backdrop" id="backdropHandlerSearchProgrammeBtn"></div>`;
                            divClearBackdrop.appendChild(divBack);

                            document.getElementById('ouiHandlerSearchProgrammeBtn').addEventListener('click', ouiHandlerSearchProgrammeBtnChange);
                            async function ouiHandlerSearchProgrammeBtnChange(e){
                                e.preventDefault();

                                var deleteidprogrammeStr = idProgramme;
                                try {
                                    //Delete
                                    var doc = await db.get(deleteidprogrammeStr);
                                    var response = await db.remove(doc);
                                    if(response.ok){
                                        alert('Devoir Supprimé avec Succès');
                                        var divClearModal = document.getElementById('modaloutsearchprogramme');
                                        while(divClearModal.hasChildNodes()) {
                                            divClearModal.removeChild(divClearModal.childNodes[0]);
                                        }

                                        var divClearBackdrop = document.getElementById('backdropoutsearchprogramme');
                                        while(divClearBackdrop.hasChildNodes()) {
                                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                        }

                                        document.getElementById('titre').style.display = 'block';
                                        var titre = document.getElementById('titre');

                                        document.getElementById('demarrage').style.display = 'none';
                                        document.getElementById('tableaubord').style.display = 'none';
                                        document.getElementById('dashboard').style.display = 'none';
                                        document.getElementById('configurations').style.display = 'none';
                                        document.getElementById('configurer').style.display = 'none';
                                        document.getElementById('emploidutemps').style.display = 'none';
                                        document.getElementById('exercice').style.display = 'none';
                                        document.getElementById('pratique').style.display = 'none';
                                        document.getElementById('tache').style.display = 'none';
                                        document.getElementById('revision').style.display = 'none';
                                        document.getElementById('recherche').style.display = 'none';
                                        document.getElementById('programme').style.display = 'block';
                                        document.getElementById('crudprogramme').style.display = 'block';
                                        document.getElementById('addprogramme').style.display = 'none';
                                        document.getElementById('editprogramme').style.display = 'none';
                                        document.getElementById('deleteprogramme').style.display = 'none';
                                        document.getElementById('searchprogramme').style.display = 'none';
                                        titre.innerHTML = 'Programme des Devoirs';
                                    }else{
                                        alert("Impossible Supprimer Devoir");
                                        document.getElementById('titre').style.display = 'block';
                                        var titre = document.getElementById('titre');

                                        document.getElementById('demarrage').style.display = 'none';
                                        document.getElementById('tableaubord').style.display = 'none';
                                        document.getElementById('dashboard').style.display = 'none';
                                        document.getElementById('configurations').style.display = 'none';
                                        document.getElementById('configurer').style.display = 'none';
                                        document.getElementById('emploidutemps').style.display = 'none';
                                        document.getElementById('exercice').style.display = 'none';
                                        document.getElementById('pratique').style.display = 'none';
                                        document.getElementById('tache').style.display = 'none';
                                        document.getElementById('revision').style.display = 'none';
                                        document.getElementById('recherche').style.display = 'none';
                                        document.getElementById('programme').style.display = 'block';
                                        document.getElementById('crudprogramme').style.display = 'block';
                                        document.getElementById('addprogramme').style.display = 'none';
                                        document.getElementById('editprogramme').style.display = 'none';
                                        document.getElementById('deleteprogramme').style.display = 'none';
                                        document.getElementById('searchprogramme').style.display = 'none';
                                        titre.innerHTML = 'Programme des Devoirs';
                                    }
                                } catch (error) {
                                    alert('Erreur Exécution');
                                }
                            }
                            document.getElementById('nonHandlerSearchProgrammeBtn').addEventListener('click', nonHandlerSearchProgrammeBtnChange);
                            async function nonHandlerSearchProgrammeBtnChange(e){
                                e.preventDefault();

                                var divClearModal = document.getElementById('modaloutsearchprogramme');
                                while(divClearModal.hasChildNodes()) {
                                    divClearModal.removeChild(divClearModal.childNodes[0]);
                                }

                                var divClearBackdrop = document.getElementById('backdropoutsearchprogramme');
                                while(divClearBackdrop.hasChildNodes()) {
                                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                }
                            }
                            document.getElementById('backdropHandlerSearchProgrammeBtn').addEventListener('click', backdropHandlerSearchProgrammeBtnChange);
                            async function backdropHandlerSearchProgrammeBtnChange(e){
                                e.preventDefault();

                                var divClearModal = document.getElementById('modaloutsearchprogramme');
                                while(divClearModal.hasChildNodes()) {
                                    divClearModal.removeChild(divClearModal.childNodes[0]);
                                }

                                var divClearBackdrop = document.getElementById('backdropoutsearchprogramme');
                                while(divClearBackdrop.hasChildNodes()) {
                                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                }
                            }
                        }
                    } catch (error) {
                        alert('Erreur Exécution');
                    }
                }
            }
            tdAction.appendChild(button);
            tr.appendChild(tdAction);

            // Add the row to the top of the table.
            viewSearchProgrammeTableBody.insertBefore(tr, viewSearchProgrammeTableBody.nextSibling);
        }
        /**Clear and update the UI to display the docs.*/
        function updateSearchProgrammeUIWithDocs(docs) {
            var viewSearchProgrammeTableBody = document.getElementById('viewSearchProgrammeTableBody');
            
            // Remove all child nodes from the table
            while(viewSearchProgrammeTableBody.hasChildNodes()) {
                viewSearchProgrammeTableBody.removeChild(viewSearchProgrammeTableBody.childNodes[0]);
            }
            
            // Add the new documents to the table
            for(var i=0; i<docs.length; i++) {
                var doc = docs[i];
                viewSearchProgrammeTableDocToTable(doc, viewSearchProgrammeTableBody);
            }
        }
        /***************************
        consulterprogrammeBtn
        *****************************/
        document.getElementById('consulterprogrammeBtn').addEventListener('click', consulterprogrammeBtnChange);
        async function consulterprogrammeBtnChange(e){
            e.preventDefault();

            document.getElementById('titre').style.display = 'block';
            var titre = document.getElementById('titre');

            document.getElementById('demarrage').style.display = 'none';
            document.getElementById('tableaubord').style.display = 'none';
            document.getElementById('dashboard').style.display = 'none';
            document.getElementById('configurations').style.display = 'none';
            document.getElementById('configurer').style.display = 'none';
            document.getElementById('emploidutemps').style.display = 'none';
            document.getElementById('exercice').style.display = 'none';
            document.getElementById('pratique').style.display = 'none';
            document.getElementById('tache').style.display = 'none';
            document.getElementById('revision').style.display = 'none';
            document.getElementById('recherche').style.display = 'none';
            document.getElementById('programme').style.display = 'block';
            document.getElementById('crudprogramme').style.display = 'block';
            document.getElementById('addprogramme').style.display = 'none';
            document.getElementById('editprogramme').style.display = 'none';
            document.getElementById('deleteprogramme').style.display = 'none';
            document.getElementById('searchprogramme').style.display = 'none';
            titre.innerHTML = 'Programme des Devoirs'; 
        }
    };

    /****************
    HELPER FUNCTION
    *****************/
    /**1: Get Current Date in JavaScript */
    function getCurrentDate(){
        // Date object
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1;
        var yyyy = today.getFullYear();
        if(dd<10){
            dd='0'+dd;
        }
        if(mm<10){
            mm='0'+mm;
        }
        // Current Date
        var date = yyyy+'-'+mm+'-'+dd;
        return date;
    }

    window.addEventListener('load', () =>{
        new taskProgramme();
    })
}) ();