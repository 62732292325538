import CrudMoyAnnuelle from './CrudMoyAnnuelle';
import AddMoyAnnuelle from './AddMoyAnnuelle';
import EditMoyAnnuelle from './EditMoyAnnuelle';
import DeleteMoyAnnuelle from './DeleteMoyAnnuelle';
import SearchMoyAnnuelle from './SearchMoyAnnuelle';

function MoyAnnuelle() {
    return ( 
        <div id="moyannuelle">
            <CrudMoyAnnuelle />
            <AddMoyAnnuelle />
            <EditMoyAnnuelle />
            <DeleteMoyAnnuelle />
            <SearchMoyAnnuelle />
        </div>
    );
}

export default MoyAnnuelle;