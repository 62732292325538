import '../configuration.css';
import './pouchconfigsemaine';
import TimeInput from '../../components/TimeInput';
import SupprimerButton from '../../components/SupprimerButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function DeleteSemaine() {
    return ( 
        <div id="deletesemaine">
            <KeyboardBackspace style={prevArrows} id="prevdeletesemaineBtn_1" />
            <br/><br/>
            <form>
                <div id="formdeletesemaine" style={scroller}>
                    <br/>
                    <div>
                    <label style={labelStyle}>Jour Semaine</label>
                        <select id="seldeletejoursemaine">
                            <option value=''>Sélection Jour</option>
                            <option value='Lundi'>Lundi</option>
                            <option value='Mardi'>Mardi</option>
                            <option value='Mercredi'>Mercredi</option>
                            <option value='Jeudi'>Jeudi</option>
                            <option value='Vendredi'>Vendredi</option>
                            <option value='Samedi'>Samedi</option>
                        </select>
                    </div>
                    <br/>
                    <div id="cancelheuredebutsemaine">
                        <label style={labelStyle}>Heure Début Cours</label>
                        <TimeInput idinput ="deleteheuredebutsemaine" time="time" read="readOnly" /> 
                    </div>
                    <br/>
                    <div id="cancelheurefinsemaine">
                        <label style={labelStyle}>Heure Fin Cours</label>
                        <TimeInput idinput ="deleteheurefinsemaine" time="time" read="readOnly" /> 
                    </div>
                    <br/>
                    <div style={{display:'none'}}>
                        <input id ="deletenumerosemaine" type="number" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deleteidsemaine" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deletecodeusersemaine" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deletejoursemaine" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deletedatesemaine" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="deletesemainestatut" type="text" />
                    </div>
                    <div id="cancelsemaine">
                        <SupprimerButton 
                            idsupprimerbutton="supprimersemaineBtn"
                            supprimer="Supprimer"
                        />
                    </div>
                    <br/><br/>
                </div>
                <div id="modaloutcancelsemaine"></div>
                <div id="backdropoutcancelsemaine"></div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default DeleteSemaine;