import './moyannuelle.css';
import './pouchmoyannuelle';
import ConsulterButton from '../../components/ConsulterButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function SearchMoyAnnuelle() {
    return ( 
        <div id="searchmoyannuelle">
            <KeyboardBackspace style={prevArrows} id="prevsearchmoyannuelleBtn_1" />
            <br/><br/>
            <form>
                <div id="formsearchmoyannuelle" style={scroller}>
                    <br/>
                    <div id="viewcalendriermoyannuelle">
                        <label style={labelStyle}>Sélection Calendrier Scolaire</label>
                        <div id="searchcalendriermoyannuelle"></div>
                    </div>
                    <br/>
                    <div id="viewprogrammemoyannuelle">
                        <table className='thin_table_config'>
                            <thead>
                                <tr>
                                    <th>Calendrier</th>
                                    <th>Moyenne Annuelle</th>
                                </tr>
                            </thead>
                            <tbody id='searchProgrammeMoyAnnuelleTableBody'></tbody>
                        </table>
                    </div>
                    <br/>
                    <div id="viewconsultermoyannuelle">
                        <ConsulterButton 
                            idconsulterbutton="consultermoyannuelleBtn"
                            consulter="OK"
                        />
                    </div>
                    <br/><br/>
                </div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default SearchMoyAnnuelle;