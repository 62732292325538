import './pouchexercice';

import { 
    KeyboardBackspace,
    Assignment,
    NavigateNext, 
} from '@material-ui/icons';

function ExoTache() {
    return ( 
        <div id="exotache">
            <KeyboardBackspace style={prevArrows} id="prevexotacheBtn_1" />
            <br/><br/>
            <div id="configexercice" style={scroller}>
                <div>
                    <button id="exercicepratiqueBtn" style={exerciceButton}>
                        <Assignment style={{float:'left',color:'1a1aff'}} />
                        Exercices Pratiques
                        <NavigateNext style={{float:'right',color:'#1a1aff'}} />
                    </button>
                </div>
                <div>
                    <button id="tachepersonnelleBtn" style={exerciceButton}>
                        <Assignment style={{float:'left',color:'1a1aff'}} />
                        Tâches Personnelles
                        <NavigateNext style={{float:'right',color:'#1a1aff'}} />
                    </button>
                </div>
                <div>
                    <button id="revisiondevoirBtn" style={exerciceButton}>
                        <Assignment style={{float:'left',color:'1a1aff'}} />
                        Devoirs Révisions
                        <NavigateNext style={{float:'right',color:'#1a1aff'}} />
                    </button>
                </div>
                <div>
                    <button id="recherchetacheBtn" style={exerciceButton}>
                        <Assignment style={{float:'left',color:'1a1aff'}} />
                        Tâches Recherches
                        <NavigateNext style={{float:'right',color:'#1a1aff'}} />
                    </button>
                </div>
                <br/><br/>
            </div>
        </div>
    );
}

//Inline Styling
const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

const exerciceButton = {
    marginTop: '4%',
    flex: 1,
    width: '90%',
    height: 50,
    textAlign: "center",
    alignContent: 'center',
    border: "2px solid #1a1aff",
    fontSize: 18,
    borderRadius: 10,
    color: "#1a1aff",
    //textTransform: "uppercase",
}

export default ExoTache;