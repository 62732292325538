import '../configuration.css';
import './pouchconfigclasse';
import TextInput from '../../components/TextInput';
//import ConsulterButton from '../../components/ConsulterButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function SearchClasse() {
    return ( 
        <div id="searchclasse">
            <KeyboardBackspace style={prevArrows} id="prevsearchclasseBtn_1" />
            <br/><br/>
            <form>
                <div id="formsearchclasse" style={scroller}>
                    <br/>
                    <div>
                        <label style={labelStyle}>Classe</label>
                        <TextInput idinput ="searchnomclasse" texte="text" read="readOnly" />
                    </div>
                    <br/>
                    <div>
                        <label style={labelStyle}>Numéro</label>
                        <TextInput idinput ="searchnumeroclasse" texte="text" read="readOnly" />
                    </div>
                    <br/>
                    <div>
                        <label style={labelStyle}>Professeur Principal</label>
                        <TextInput idinput ="searchprofprincipalclasse" texte="text" read="readOnly"/> 
                    </div>
                </div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default SearchClasse;