import PasswordOublie from "./PasswordOublie";
import Inscrire from "./Inscrire";

function Inscription() {
    return ( 
        <div id="inscription">
            <PasswordOublie />
            <Inscrire />
        </div>
     );
}

export default Inscription;