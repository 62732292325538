import './moygenerale.css';
import './pouchmoygenerale';
import ModifierButton from '../../components/ModifierButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function EditMoyGenerale() {
    return ( 
        <div id="editmoygenerale">
            <KeyboardBackspace style={prevArrows} id="preveditmoygeneraleBtn_1" />
            <br/><br/>
            <form>
                <div id="formeditmoygenerale" style={scroller}>
                    <br/>
                    <div id="changecalendriermoygenerale">
                        <label style={labelStyle}>Sélection Calendrier Scolaire</label>
                        <div id="editcalendriermoygenerale"></div>
                    </div>
                    <br/>
                    <div id="changeprogrammemoygenerale">
                        <label id="editmatiereprogrammemoygenerale" style={labelStyle}>Trimestre</label>
                        <br/><br/>
                        <table className='thin_table_config'>
                            <thead>
                                <tr>
                                    <th>Matière</th>
                                    <th>Moyenne</th>
                                    <th>Coeff.</th>
                                </tr>
                            </thead>
                            <tbody id='editProgrammeMoyGeneraleTableBody'></tbody>
                        </table>
                    </div>
                    <br/>
                    <div id="changetotalcumulmoygenerale">
                        <label style={labelStyle}>Total Moyennes Cumulées</label>
                        <div id="edittotalcumulmoygenerale"></div>
                    </div>
                    <div id="changetotalcoefficientmoygenerale">
                        <label style={labelStyle}>Total Coefficients Cumulés</label>
                        <div id="edittotalcoefficientmoygenerale"></div>
                    </div>
                    <div id="changeobtenumoygenerale">
                        <label style={labelStyle}>Moyenne Générale</label>
                        <div style={{fontWeight:'bold',color:'#1a1aff'}} id="editmoygeneraleobtenue"></div>
                    </div>
                    <br/>
                    <div style={{display:'none'}}>
                        <input id ="editidmoygenerale" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editselectcalendriermoygenerale" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editmoyennecumulmoygenerale" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editcoefficientcumulemoygenerale" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editmoyennemoygenerale" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editmoygeneraleeffectue" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editmoygeneraledate" type="date" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editmoygeneralestatut" type="text" />
                    </div>
                    <br/>
                    <div id="changemoygenerale">
                        <ModifierButton 
                            idmodifierbutton="modifiermoygeneraleBtn"
                            modifier="Modifier"
                        />
                    </div>
                    <br/><br/>
                </div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default EditMoyGenerale;