import CrudRevision from "./CrudRevision";
import AddRevision from "./AddRevision";
import EditRevision from "./EditRevision";
import DeleteRevision from "./DeleteRevision";
import SearchRevision from "./SearchRevision";

function Revision() {
    return ( 
        <div id="revision">
            <CrudRevision />
            <AddRevision />
            <EditRevision />
            <DeleteRevision />
            <SearchRevision />
        </div>
    );
}

export default Revision;