import './emploidutemps.css';
import './pouchemploidutemps';
import TimeInput from '../components/TimeInput';
import ModifierButton from '../components/ModifierButton';
import { KeyboardBackspace, } from '@material-ui/icons';

function EditEmploiDuTemps() {
    return ( 
        <div id="editemploidutemps">
            <KeyboardBackspace style={prevArrows} id="preveditemploidutempsBtn_1" />
            <br/><br/>
            <form>
                <div id="formeditemploidutemps" style={scroller}>
                <br/>
                    <div>
                        <label style={labelStyle}>Sélection Jour</label>
                        <div id="editjouremploidutemps"></div>
                    </div>
                    <br/>
                    <div id="changematieres">
                        <label style={labelStyle}>Sélection Matière</label>
                        <div id="editmatiereemploidutemps"></div>
                    </div>
                    <br/>
                    <div id="changeheure">
                        <label style={labelStyle}>Heure</label>
                        <TimeInput idinput ="editheureemploidutemps" time="time" />
                    </div>
                    <br/>
                    <div style={{display:'none'}}>
                        <input id ="editidemploi" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editcodeuseremploi" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editjouremploi" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editmatiereemploi" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editdateemploi" type="text" />
                    </div>
                    <div style={{display:'none'}}>
                        <input id ="editstatutemploi" type="text" />
                    </div>
                    <div id="changeemploidutemps">
                        <ModifierButton 
                        idmodifierbutton="modifieremploidutempsBtn"
                        modifier="Modifier"
                    />
                    </div>
                    <br/><br/>
                </div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '50%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default EditEmploiDuTemps;