import '../configuration.css';
import '../pouchconfiguration';
import TextInput from '../../components/TextInput';
import { KeyboardBackspace, } from '@material-ui/icons';

function SearchEtablissement() {
    return ( 
        <div id="searchetablissement">
            <KeyboardBackspace style={prevArrows} id="prevsearchetablissementBtn_1" />
            <br/><br/>
            <form>
                <div id="formsearchetablissement" style={scroller}>
                    <br/>
                    <div>
                        <label style={labelStyle}>Nom Etablissement</label>
                        <TextInput idinput ="searchnometablissement" texte="text" read="readOnly" />
                    </div>
                    <br/>
                    <div>
                        <label style={labelStyle}>Ville</label>
                        <TextInput idinput ="searchvilleetablissement" texte="text" read="readOnly" />
                    </div>
                    <br/>
                    <div>
                        <label style={labelStyle}>Adresse</label>
                        <TextInput idinput ="searchadresseetablissement" texte="text" read="readOnly" />
                    </div>
                    <br/>
                    <div>
                        <label style={labelStyle}>Nom Principal/Proviseur</label>
                        <TextInput idinput ="searchprincipaletablissement" texte="text" read="readOnly" />
                    </div>
                    <br/>
                    <div>
                        <label style={labelStyle}>Téléphone</label>
                        <TextInput idinput ="searchcontactetablissement" texte="text" read="readOnly" />
                    </div>
                    <br/>
                    <div>
                        <label style={labelStyle}>E-mail</label>
                        <TextInput idinput ="searchemailetablissement" texte="text" read="readOnly" />
                    </div>
                    <br/><br/>
                </div>
            </form>
        </div>
    );
}

const labelStyle = {
    width: '90%',
    height: 30,
    color:"#000000",
    fontSize: 17,
    fontWeight: "bold",
};

const prevArrows = {
    float: 'left',
    margin: 10,
    color: '#1a1aff',
    fontWeight: 'bold',
}

const scroller = {
    width: '100%',
    height: '300px',
    overflowY: 'scroll',
    scrollbarColor: 'rebeccapurple green',
    scrollbarWidth: 'thin',
    backgroundColor: '#eafafa',
    borderRadius: 10,
}

export default SearchEtablissement;